import { FC } from 'react';

import { CustomTypography } from 'components';

import CornerBox from '../CornerBox';

import { TLandingContentElementProps } from './types';
import styles from './LandingContentElement.module.scss';

const LandingContentElement: FC<TLandingContentElementProps> = ({
  userImg,
  content,
  subContent,
}) => (
  <div className={styles.container}>
    <div className={styles.container__content}>
      <div className={styles.container__main}>
        <CustomTypography className={styles.container__main__title}>{content}</CustomTypography>
        <CornerBox>
          <CustomTypography className={styles.container__main__subtitle}>
            {subContent}
          </CustomTypography>
        </CornerBox>
      </div>

      <div className={styles.container__img}>
        <img src={userImg} alt='img' />
      </div>
    </div>
  </div>
);

export default LandingContentElement;
