import { FC } from 'react';
import { Box } from '@mui/material';

import styles from './EmployeeStatus.module.scss';
import { StatusEnums, type TStatusInfoProps } from './types';

const getStatusColor = (status: StatusEnums) => {
  switch (status) {
    case StatusEnums.PENDING:
    case StatusEnums.ACCEPTED:
    case StatusEnums.ACTIVE:
      return '#0D7D0B';
    case StatusEnums.REVOKED:
    case StatusEnums.EXPIRED:
    case StatusEnums.INACTIVE:
      return '#667085';
    default:
      return '#C20808';
  }
};

const EmployeeStatus: FC<TStatusInfoProps> = ({ status, title }) => (
  <Box className={styles.container}>
    <Box sx={{ background: getStatusColor(status) }} className={styles.container__box} />
    <p className={styles.container__text}>{title}</p>
  </Box>
);

export default EmployeeStatus;
