import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { Navigate, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { AxiosError } from 'axios';

import { Routes } from 'types';
import { LogoIcon } from 'assets/icons';
import { authApi, orgranizationsApi } from 'api';
import { TokenProvider } from 'store/slices/authSlice/types';
import { CustomTypography, OrganizationLogo } from 'components';
import { BrowserStorageKeys, BrowserStorageService } from 'services';
import { TAllOrganizationsReturnType, TOrganization } from 'store/slices/organizationsSlice/types';

import styles from './OrganizationsList.module.scss';

const OrganizationsList = () => {
  const navigate = useNavigate();

  const tokenInLocalStorage = BrowserStorageService.get(BrowserStorageKeys.AccessToken);
  const fromProcess = BrowserStorageService.get(BrowserStorageKeys.InProcessForm);

  const [data, setData] = useState<TOrganization[]>([]);

  useEffect(() => {
    if (!fromProcess) {
      BrowserStorageService.set(BrowserStorageKeys.HaveCreatedOrganization, 'true');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const googleToken = BrowserStorageService.get(BrowserStorageKeys.GoogleToken, {
    session: true,
  });

  const getAllOrganizations = async () => {
    try {
      const response = await orgranizationsApi.getUserAllOrganizationsRequest();

      const receivedData = response?.data as TAllOrganizationsReturnType;

      const filteredData = receivedData?.data?.filter(
        (organization) => organization?.resource_status !== 'pending',
      );

      setData(filteredData);
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  };

  useEffect(() => {
    getAllOrganizations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loginDependentInOrganization = async (organization: TOrganization) => {
    if (organization?.resource_status === 'created') {
      try {
        const sendedData = new FormData();

        sendedData.append('oauth_token', String(googleToken));
        sendedData.append('oauth_provider', TokenProvider.GOOGLE);
        sendedData.append('org_id', String(organization?.id));

        const response = await authApi.signInOauthRequest(sendedData);
        const accessToken = response?.data?.token?.access_token;

        if (accessToken) {
          BrowserStorageService.set(BrowserStorageKeys.AccessToken, JSON.stringify(accessToken));

          BrowserStorageService.set(
            BrowserStorageKeys.RefreshToken,
            JSON.stringify(response?.data?.token?.refresh_token),
          );
        }

        if (response) {
          navigate(Routes.ActivityFeed);

          BrowserStorageService.set(
            BrowserStorageKeys.CurrentOrganizationId,
            JSON.stringify(organization?.id),
          );
          BrowserStorageService.remove(BrowserStorageKeys.InProcessForm);
          BrowserStorageService.remove(BrowserStorageKeys.HaveCreatedOrganization);
        }
      } catch (error) {
        const Error = error as AxiosError;
        throw Error;
      }
    }
  };

  const organizationItems = data?.map((organization) => {
    const cardClasses = classNames(styles.container__main__content__card, {
      [styles.container__main__content__card_disable]: organization?.resource_status === 'creating',
    });

    return (
      <div
        title={
          organization?.resource_status === 'creating'
            ? `${organization?.name} is in creating process please wait`
            : undefined
        }
        className={cardClasses}
        key={organization?.id}
        onClick={() => loginDependentInOrganization(organization)}
      >
        <OrganizationLogo fromList image={organization?.media_url_id as string} />
        <div className={styles.container__main__content__card__content} title={organization?.name}>
          {organization?.name}
        </div>
      </div>
    );
  });

  if (tokenInLocalStorage) {
    return <Navigate replace={true} to={Routes.ActivityFeed} />;
  }

  if (!tokenInLocalStorage && fromProcess === 'true') {
    return <Navigate replace={true} to={Routes.ProcessForm} />;
  }

  return (
    <Box className={styles.container}>
      <LogoIcon />

      <Box className={styles.container__main}>
        <CustomTypography className={styles.container__title}>
          Select the organization to login
        </CustomTypography>
        <Box className={styles.container__main__content}>{organizationItems}</Box>
      </Box>
    </Box>
  );
};

export default OrganizationsList;
