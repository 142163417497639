import { FC } from 'react';

import { useWindowSize } from 'hooks';
import { DrawerLayout, MyChatBot } from 'components';

import { breakPoints } from '../../../../constants';

import type { TCreateAiDrawerProps } from './types';

const AiDrawer: FC<TCreateAiDrawerProps> = ({ open, handleClose }) => {
  const { width } = useWindowSize();

  const drawerWidth =
    Number(width) >= breakPoints.TABLET ? 422 : Number(width) >= breakPoints.TABLET_M ? 400 : 375;

  const onDrawerClose = () => {
    handleClose();
  };

  const onDrawerCloseReset = async () => {
    handleClose();
  };

  return (
    <>
      <DrawerLayout
        open={open}
        padding='40px'
        inCenter={false}
        width={drawerWidth}
        onClose={onDrawerClose}
        headerTitle='AI Agent'
        onCloseReset={onDrawerCloseReset}
      >
        <MyChatBot />
      </DrawerLayout>
    </>
  );
};

export default AiDrawer;
