import { ChangeEvent, FC, useEffect, useState } from 'react';
import { Box } from '@mui/material';

import { AddIconLive } from 'assets/icons';
import { useAppDispatch, useAppSelector } from 'hooks';
import { changePreviousLives, changePreviousLivesLimit } from 'store/slices/liveStreamSlice';
import { CustomTypography, DynamicPaginationControl, EmptyTitle, PastStreamCard } from 'components';
import {
  userCreatedStreamsSelector,
  userCreatedStreamsLoadSelector,
  userCreatedStreamsLimitSelector,
  userCreatedStreamsOffsetSelector,
} from 'store/slices/liveStreamSlice/selector';
import { getUserCreatedCompletedLiveStreams } from 'store/slices/liveStreamSlice/thunks';

import { TPastStreamsProps } from './types';
import styles from './LiveStream.module.scss';

const PastStreams: FC<TPastStreamsProps> = ({ handleOpenDrawer }) => {
  const dispatch = useAppDispatch();

  const previousStreams = useAppSelector(userCreatedStreamsSelector);
  const previousStreamsLimit = useAppSelector(userCreatedStreamsLimitSelector);
  const previousStreamsOffset = useAppSelector(userCreatedStreamsOffsetSelector);
  const previousStreamsLoad = useAppSelector(userCreatedStreamsLoadSelector);

  const [currentPage, setCurrentPage] = useState<number>(0);

  const goToPreviousPage = () => setCurrentPage(0);

  const previousStreamsElements = previousStreams?.data?.map((stream) => (
    <PastStreamCard goToPreviousPage={goToPreviousPage} stream={stream} key={stream?.id} />
  ));

  useEffect(() => {
    dispatch(
      getUserCreatedCompletedLiveStreams({
        status: 'completed',
        user_created: false,
        limit: previousStreamsLimit,
        offset: previousStreamsOffset,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previousStreamsLimit, previousStreamsOffset]);

  const handlePageChange = (_event: unknown, newPage: number) => {
    setCurrentPage(newPage);
    dispatch(changePreviousLives(newPage + 1));
  };

  const handleRowsPerPageChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCurrentPage(0);
    handlePageChange(null, 0);
    dispatch(changePreviousLivesLimit(event.target.value));
  };

  return (
    <Box className={styles.container__invitation}>
      <Box className={styles.container__invitation__box} onClick={handleOpenDrawer}>
        <AddIconLive />
        <CustomTypography className={styles.container__invitation__box__text}>
          Invite to Live Stream
        </CustomTypography>
      </Box>

      <Box className={styles.container__invitation__previews}>
        <CustomTypography className={styles.container__invitation__previews__title}>
          Saved Videos
        </CustomTypography>
      </Box>

      {previousStreams?.total_count === 0 ? (
        <EmptyTitle loadingDone={previousStreamsLoad} title="You don't have previous streams " />
      ) : (
        <Box className={styles.container__invitation__videos}>{previousStreamsElements}</Box>
      )}
      {!!previousStreams?.total_count && (
        <Box className={styles.container__footer}>
          <DynamicPaginationControl
            page={currentPage}
            onPageChange={handlePageChange}
            rowsPerPage={previousStreamsLimit}
            count={previousStreams?.total_count}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        </Box>
      )}
    </Box>
  );
};

export default PastStreams;
