import { type FC, useEffect, useState, useRef } from 'react';
import { Box } from '@mui/material';
import classNames from 'classnames';

import { ArrowDown, PlusIcon } from 'assets/icons';
import { Button, CustomTypography } from 'components';
import { useAppDispatch, useAppSelector, useOnClickOutside } from 'hooks';
import { TVendor } from 'store/slices/inventorySlice/types';
import { getVendorList } from 'store/slices/inventorySlice/thunks';
import { vendorListSelector } from 'store/slices/inventorySlice/selectors';

import styles from './SelectVendorDropDown.module.scss';

import type { TSelectedVendorDropDownProps } from './types';

const SelectVendorDropDown: FC<TSelectedVendorDropDownProps> = ({
  selectedVendor,
  setSelectedVendor,
  openVendorNotListed,
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const dispatch = useAppDispatch();

  const vendorList = useAppSelector(vendorListSelector);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleClick = () => setIsOpen(!isOpen);

  const closeDropDown = () => setIsOpen(false);

  const changeSelectedVendor = (el: TVendor) => {
    setSelectedVendor({ id: el.id, name: el.name });
    setIsOpen(false);
  };

  useEffect(() => {
    dispatch(getVendorList({ limit: 10, offset: 0 }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderVendorList = vendorList?.data?.map((el) => (
    <Box
      className={styles.container__dropdown__item}
      key={el.id}
      onClick={() => changeSelectedVendor(el)}
    >
      {el.name}
    </Box>
  ));

  useOnClickOutside(ref, closeDropDown);

  return (
    <>
      <Box className={styles.container} ref={ref}>
        <Box className={styles.container__select} onClick={handleClick}>
          <CustomTypography className={styles.container__select__text}>
            {selectedVendor?.name ? selectedVendor?.name : 'Select Vendor:*'}
          </CustomTypography>
          <ArrowDown
            className={classNames(styles.container__select__icon, {
              [styles.container__select__icon__active]: isOpen,
            })}
          />
        </Box>
        {isOpen && (
          <Box className={styles.container__dropdown}>
            {renderVendorList}

            <Box className={styles.container__dropdown__new}>
              <Button
                className={styles.container__add}
                startIcon={<PlusIcon width={20} height={20} />}
                onClick={openVendorNotListed}
              >
                Add Vendor
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default SelectVendorDropDown;
