export enum EmptyTitles {
  Catalogs = 'No catalogs added yet',
  Inventories = 'No inventories added yet',
  Purchase_Requisitions = 'No purchase requisitions added yet',
  Invoices = 'No invoices added yet',
  Transactions = 'No transaction yet',
  Catalog_Versions = 'No catalog versions added yet',
  Catalog_Discounts = 'No catalog discounts added yet',
  WorkOrder_Task = 'No tasks added yet',
  Catalog_Payment_Schedules = 'No catalog payment schedules added yet',
  Inventory_Usage = 'No inventory usage added yet',
  Estimates = 'No estimates added yet',
  Estimate_Items = 'No estimate items added yet',
  Customers = 'No customers added yet',
  Document_Items = 'No document items added yet',
  Documents = 'No documents added yet',
  Document_Content = 'No document content added yet',
  Purchase_Orders = 'No purchasing orders added yet',
  Purchase_Order_Items = 'No purchasing order items added yet',
  Vendors = 'No vendors added yet',
  Templates = 'No Templates added yet',
  Purchase_Requisition_Items = 'No purchase requisition items added yet',
  Invitations = 'No invitations added yet',
  Employees = 'No employees added yet',
  WorkOrder = 'No Work Orders added yet',
}
