import { LinearProgress } from '@mui/material';
import { FC } from 'react';

import { BorderSquareIcon, DocIcon, ImageFileIcon, PdfIcon, WebpIcon } from 'assets/icons';
import { formatBytes } from 'utils';

import styles from './FileUploadView.module.scss';
import { LinearProgressStyles } from './styles';

import type { TFileUploadView } from './types';

const FileUploadView: FC<TFileUploadView> = ({
  onRemove,
  fileSize,
  fileName,
  fileType,
  blockMaxWidth,
  contentMaxWidth,
  fileExist = false,
}) => {
  const uploadedFileSize = formatBytes(fileSize as number);

  const iconRenderer = () => {
    if (fileType === 'pdf') {
      return <PdfIcon />;
    } else if (fileType === 'doc') {
      return <DocIcon />;
    } else if (fileType === 'png') {
      return <ImageFileIcon />;
    } else if (fileType === 'jpeg') {
      return <ImageFileIcon />;
    } else if (fileType === 'jpg') {
      return <ImageFileIcon />;
    } else if (fileType === 'svg') {
      return <ImageFileIcon />;
    } else if (fileType === 'webp') {
      return <WebpIcon />;
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.container__info}>
        <div className={styles.container__info_start} style={{ maxWidth: contentMaxWidth }}>
          <div className={styles.container__info_icon}>{iconRenderer()}</div>
          <div className={styles.container__info_start_block} style={{ maxWidth: blockMaxWidth }}>
            <p className={styles.container__info_start_title}>{fileName}</p>
            <p className={styles.container__info_start_block_child}>
              {uploadedFileSize} . Just Now
            </p>
          </div>
        </div>
        <div className={styles.container__info_close} role='button' onClick={onRemove}>
          <BorderSquareIcon />
          <p className={styles.container__info_close_end}>100%</p>
        </div>
      </div>
      <div className={styles.container__progress}>
        <LinearProgress
          sx={LinearProgressStyles}
          variant='determinate'
          value={fileExist ? 100 : 10}
        />
      </div>
    </div>
  );
};

export default FileUploadView;
