import { FC, useEffect } from 'react';
import { Box } from '@mui/material';
import { Controller, useFieldArray } from 'react-hook-form';

import { PlusFilledIcon } from 'assets/icons';
import { CustomTypography } from 'components/shared';
import { useAppDispatch } from 'hooks';
import { removeFromItem } from 'store/slices/optionsSlice';

import styles from './PurchaseOrderItems.module.scss';
import PurchaseOrderFieldItem from './PurchaseOrderFieldItem';

import type { TPurchaseOrderFieldItems } from './types';

const PurchaseOrderItems: FC<TPurchaseOrderFieldItems> = ({
  errors,
  control,
  inEditMode,
  itemsFromWatch,
  setDescriptionValue,
  sectionName = 'items',
}) => {
  const dispatch = useAppDispatch();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'items',
  });

  useEffect(() => {
    if (fields.length === 0) {
      handleAppendSection();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields]);

  const handleAppendSection = () => {
    append({
      price: '',
      itemName: '',
      quantity: '',
      id: Date.now(),
      description: '',
    });
  };

  const removeUserInfo = (index: number) => {
    remove(index);
    dispatch(removeFromItem(index));
  };

  const invitedUserItems = fields.map((item, idx) => (
    <Controller
      key={idx}
      control={control}
      name={`items[${idx}]` as 'items'}
      render={() => (
        <PurchaseOrderFieldItem
          idx={idx}
          item={item}
          control={control}
          sectionName={sectionName}
          errors={errors?.items?.[idx]}
          itemsFromWatch={itemsFromWatch}
          setDescriptionValue={setDescriptionValue}
          removeUserInfo={() => removeUserInfo(idx)}
          plusIcon={
            <PlusFilledIcon
              width={24}
              height={24}
              role='button'
              onClick={handleAppendSection}
              className={styles.container__plus}
            />
          }
        />
      )}
    />
  ));

  return (
    <Box className={styles.container}>
      {invitedUserItems}
      {!inEditMode && (
        <Box className={styles.container__plus} onClick={handleAppendSection}>
          <PlusFilledIcon
            width={24}
            height={24}
            role='button'
            className={styles.container__plus__icon}
          />
          <CustomTypography className={styles.container__plus__text}>Add An item</CustomTypography>
        </Box>
      )}
    </Box>
  );
};

export default PurchaseOrderItems;
