import moment from 'moment';
import 'moment-timezone';

const timeDiffer = (
  date: string,
  postTimeZone = 'UTC',
  localTimeZone = moment.tz.guess(),
): string => {
  const postTime = moment.utc(date).tz(postTimeZone);
  const localPostTime = postTime.clone().tz(localTimeZone);
  const currentTime = moment().tz(localTimeZone);

  const minutesAgo = currentTime.diff(localPostTime, 'minutes');
  const hoursAgo = Math.floor(minutesAgo / 60);
  const daysAgo = Math.floor(hoursAgo / 24);

  if (minutesAgo < 1) {
    return 'just now';
  } else if (minutesAgo < 60) {
    return `${minutesAgo} minute${minutesAgo > 1 ? 's' : ''} ago`;
  } else if (hoursAgo < 24) {
    return `${hoursAgo} hour${hoursAgo > 1 ? 's' : ''} ago`;
  } else if (daysAgo === 1) {
    return '1 day ago';
  } else if (daysAgo < 7) {
    return `${daysAgo} days ago`;
  } else {
    const weeksAgo = Math.floor(daysAgo / 7);
    return `${weeksAgo} week${weeksAgo > 1 ? 's' : ''} ago`;
  }
};

export default timeDiffer;
