import { TPart } from 'store/slices/inventorySlice/types';

import { getValue } from '../getValue';

const inventoryFormValuesGenerator = (inEditMode: boolean, itemData: TPart) => {
  return {
    qty: getValue(inEditMode, itemData?.qty),
    sku: getValue(inEditMode, itemData?.sku),
    name: getValue(inEditMode, itemData?.name),
    brand: getValue(inEditMode, itemData?.brand),
    cost: getValue(inEditMode, Number(itemData?.cost)),
    item_type: getValue(inEditMode, itemData?.item_type),
    description: getValue(inEditMode, itemData?.description),
    manufacturer: getValue(inEditMode, itemData?.manufacturer),
    item_location: getValue(inEditMode, itemData?.item_location),
    preferred_vendor: inEditMode
      ? {
          name: itemData?.preferred_vendor,
        }
      : { name: '' },
  };
};

export default inventoryFormValuesGenerator;
