import { FC, useState } from 'react';
import { Box } from '@mui/material';
import { isMobileSafari } from 'react-device-detect';

import { BorderSquareIcon } from 'assets/icons';
import { BlackBackgroundImg } from 'assets/images';
import { CustomTypography, ModalLayout } from 'components';
import { StreamVideoNotes, StreamVideoPlayer } from 'components';
import { useWindowSize } from 'hooks';
import breakPoints from 'constants/BreakPoints';

import styles from './PlayerView.module.scss';

import type { TPlayerViewProps } from './types';

const PlayerView: FC<TPlayerViewProps> = ({
  isOpen,
  onClose,
  stream,
  poster,
  mediaSrc,
  isMembersOpened,
  openDetailsDrawer,
}) => {
  const { width } = useWindowSize();

  const videoFromStream = stream?.media?.find(
    (media) => media?.file_type === 'mp4' || media?.file_type === 'webm',
  );

  const haveVideo = videoFromStream?.media_url_id;

  const [isStarted, setIsStarted] = useState<boolean>(false);

  const timedOnClose = () => {
    setIsStarted(true);

    setTimeout(() => {
      onClose();
      setIsStarted(false);
    }, 1000);
  };

  const drawerWidth = Number(width) >= breakPoints.TABLET ? 500 : 320;

  return (
    <ModalLayout
      borderRadius='10px'
      lowPriority={isMembersOpened}
      width={drawerWidth}
      isClosed={isStarted}
      isOpen={isOpen}
      onClose={timedOnClose}
    >
      <Box className={styles.container}>
        <Box className={styles.container__header}>
          <CustomTypography className={styles.container__header__title}>
            Past Stream Details
          </CustomTypography>
          <BorderSquareIcon width={24} height={24} onClick={timedOnClose} />
        </Box>
        <Box className={styles.container__content}>
          {haveVideo ? (
            isMobileSafari ? (
              <video
                controls
                playsInline
                src={mediaSrc}
                poster={poster || BlackBackgroundImg}
                style={{ height: '200px', objectFit: 'cover' }}
              />
            ) : (
              <StreamVideoPlayer onClose={onClose} mediaSrc={mediaSrc} stream={stream} />
            )
          ) : (
            <div className={styles.container__empty}>{`Stream doesn't have recording`}</div>
          )}
          <StreamVideoNotes stream={stream} id={stream?.id} openDetailsDrawer={openDetailsDrawer} />
        </Box>
      </Box>
    </ModalLayout>
  );
};

export default PlayerView;
