import { useController } from 'react-hook-form';

import { TableCell } from 'components/shared';
import {
  ControlledAutocomplete,
  ControlledFormPicker,
  ControlledInput,
  ControlledSelect,
} from 'components';
import { getNestedError } from 'utils/getNestedError';
import { Colors } from 'types';

import styles from './TableControlledRowItem.module.scss';

import type { TTableControlledRowItemProps } from './types';

const TableControlledRowItem = <T,>({
  name,
  value,
  label,
  control,
  keyName,
  required,
  Component,
  placeholder,
  borderColor,
  options = [],
  inputOptions,
  inputClassName,
  disabled = false,
  inputOptionsName,
  isExisted = false,
  enableSpaceKeyDown,
  selectDefaultValue,
  variant = 'standard',
  isChangeable = false,
  componentType = 'text',
  inputContainerClassName,
  isSingleControlled = false,
}: TTableControlledRowItemProps<T>) => {
  const {
    field: { value: fieldValue },
    formState: { errors },
  } = useController({ name, control });

  const specificError = getNestedError(errors, name);
  const specificErrorMessage = specificError?.[keyName as string]?.message;

  const currentSelectedValue = fieldValue?.[keyName as any];

  return (
    <>
      {isExisted ? (
        Component || <TableCell style={{ padding: 0 }} title={value as string} />
      ) : (
        <>
          {componentType === 'text' ? (
            <ControlledInput
              name={name}
              borderRadius='0'
              variant={variant}
              control={control}
              disabled={disabled}
              required={required}
              error={!!specificError}
              backgroundColor={Colors.WHITE}
              enableSpaceKeyDown={enableSpaceKeyDown}
              placeholder={placeholder}
              helperText={specificErrorMessage || specificError?.message}
            />
          ) : componentType === 'select' ? (
            <ControlledSelect
              name={name}
              label={label}
              haveLabel={false}
              options={options}
              control={control}
              required={required}
              padding='10px 5px 10px'
              backgroundColor={Colors.WHITE}
              errors={!!specificError}
              defaultValue={selectDefaultValue}
              labelClasses={styles.container__select__label}
            />
          ) : componentType === 'picker' ? (
            <ControlledFormPicker
              padding='0 8px'
              label=''
              name={name}
              control={control}
              error={specificError}
              backgroundColor={Colors.WHITE}
              className={styles.container__picker}
              helperText={specificErrorMessage || specificError?.message}
            />
          ) : (
            <>
              {isChangeable && currentSelectedValue ? (
                <TableCell title={currentSelectedValue} />
              ) : (
                <ControlledAutocomplete
                  name={name}
                  control={control}
                  variant={variant}
                  disabled={disabled}
                  readonly={disabled}
                  required={required}
                  backgroundColor={Colors.WHITE}
                  borderColor={borderColor}
                  placeholder={placeholder}
                  options={inputOptions || []}
                  inputClassName={inputClassName}
                  isSingleControlled={isSingleControlled}
                  optionsName={inputOptionsName as string}
                  errors={!!specificError?.[keyName as any]}
                  inputContainerClassName={inputContainerClassName}
                  helperText={specificErrorMessage || specificError?.message}
                />
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default TableControlledRowItem;
