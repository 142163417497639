import { FC, useEffect } from 'react';

import { TChangeInputEvent } from 'types/global/events';

import DynamicPaginationControl from '../DynamicPaginationControl';

import type { TCustomTablePaginationProps } from './types';

const CustomTablePagination: FC<TCustomTablePaginationProps> = ({
  page,
  count,
  rowsPerPage,
  onPageChange,
  onRowsPerPageChange,
}) => {
  const handleChangePage = (_event: unknown, newPage: number) => {
    onPageChange(newPage);
  };

  const handleChangeRowsPerPage = (event: TChangeInputEvent) => {
    onRowsPerPageChange(+event.target.value);
  };

  useEffect(() => {
    if (!count && page) {
      onPageChange(page - 1);
      handleChangePage(null, page - 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count]);

  return (
    <>
      {!!count && (
        <DynamicPaginationControl
          count={count}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </>
  );
};

export default CustomTablePagination;
