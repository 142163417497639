import { TPaymentMethod } from 'types/global/paymentMethods';

const updateBillingDetailsFormValuesGenerator = (currentPaymentMethod: TPaymentMethod) => ({
  name: currentPaymentMethod?.billing_details?.name || '',
  address: currentPaymentMethod?.billing_details?.address?.line1 || '',
  country:
    currentPaymentMethod?.billing_details?.address?.country ||
    currentPaymentMethod?.card?.country ||
    '',
  state: currentPaymentMethod?.billing_details?.address?.state || '',
  townAndCity: currentPaymentMethod?.billing_details?.address?.city || '',
  postalCode: currentPaymentMethod?.billing_details?.address?.postal_code || '',
});

export default updateBillingDetailsFormValuesGenerator;
