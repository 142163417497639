import { FC, useState, KeyboardEvent } from 'react';
import { Controller } from 'react-hook-form';

import { CustomTextField } from 'components/shared';

import { TNumberControlledInputProps } from './types';

const NumberControlledInput: FC<TNumberControlledInputProps> = ({
  control,
  name,
  label,
  error,
  readOnly,
  required,
  maxWidth,
  padding,
  placeholder,
  borderColor,
  borderRadius,
  helperText,
  backgroundColor,
  labelColor,
  disabled = false,
  fontFamily = 'CircularStdLight',
}) => {
  const [isBackspaceClick, setIsBackSpaceClick] = useState<boolean>(false);

  const formatPhoneNumber = (phoneNumber: string) => {
    if (isBackspaceClick) {
      return phoneNumber;
    } else {
      phoneNumber = phoneNumber.replace(/\D/g, '');
      phoneNumber = phoneNumber.slice(0, 10);

      let formattedNumber = '';
      for (let i = 0; i < phoneNumber.length; i++) {
        if (i === 0) formattedNumber += `(${phoneNumber[i]}`;
        else if (i === 2) formattedNumber += `${phoneNumber[i]}) `;
        else if (i === 5) formattedNumber += `${phoneNumber[i]}-`;
        else formattedNumber += phoneNumber[i];
      }

      return formattedNumber;
    }
  };

  return (
    <Controller
      name={name}
      defaultValue=''
      control={control}
      render={({ field, formState: { errors } }) => (
        <CustomTextField
          {...field}
          disabled={disabled}
          padding={padding}
          readonly={readOnly}
          required={required}
          labelColor={labelColor}
          fontFamily={fontFamily}
          borderColor={borderColor}
          borderRadius={borderRadius}
          backGroundColor={backgroundColor}
          helperText={helperText || (errors[name]?.message as string)}
          onKeyDown={(event: KeyboardEvent<HTMLInputElement>) => {
            const isBackspace = event.key === 'Backspace';
            setIsBackSpaceClick(isBackspace);
          }}
          onChange={(e) => {
            const formattedValue = formatPhoneNumber(e.target.value);
            field.onChange(formattedValue);
          }}
          label={label}
          maxWidth={maxWidth}
          error={!!error || !!errors[name]}
          placeholder={placeholder}
        />
      )}
    />
  );
};

export default NumberControlledInput;
