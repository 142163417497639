export enum DocumentsSectionsItems {
  DOCUMENTS = 'documents',
  BOM_ESTIMATE = 'estimate',
  AGREEMENTS = 'agreements',
  PURCHASING_ORDERS = 'purchasing orders',
  WORK_ORDERS = 'work orders',
}

export type TDocumentsSectionsItems = {
  id: number;
  content: JSX.Element | string;
  title: DocumentsSectionsItems;
};
