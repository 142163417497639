import { BaseSyntheticEvent, SyntheticEvent, useState } from 'react';
import { Box, Menu, MenuItem } from '@mui/material';

import { EditIconOutLined, TrashIcon, WhiteMoreIcon } from 'assets/icons';

import { sxMenuStyles } from './styles';

import type { TPortalDropDownProps } from './types';

const PortalDropDown = <T,>({
  options,
  firstIcon,
  selectedId,
  secondIcon,
  getSelectedId,
  selectedItem,
  onFirstAction,
  onSecondAction,
  getSelectedItem,
  withIcons = true,
  withHover = true,
  forTwoActions = true,
  getDropDownState,
  minHeight = '48px',
  firstTitle = 'Edit',
  titlePadding = '3px',
  secondTitle = 'Remove',
  title = <WhiteMoreIcon />,
}: TPortalDropDownProps<T>) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const elements = options?.map((element) => {
    const handleElementClick = (event: React.SyntheticEvent<HTMLLIElement>) => {
      event.stopPropagation();
      element?.action();
      handleClose();
    };

    return (
      <MenuItem disabled={element?.disabled} key={element?.id} onClick={handleElementClick}>
        {element?.position !== 'end' && element?.icon}
        <p>{element?.name}</p>
        {element?.position === 'end' && element?.icon}
      </MenuItem>
    );
  });

  const handleClose = () => {
    setAnchorEl(null);
    getDropDownState?.(false);
  };

  const handleSecondAction = () => {
    onSecondAction?.();
    handleClose();
  };

  const handleFirstAction = () => {
    onFirstAction?.();
    handleClose();
  };

  const handleMoreClick = (event: BaseSyntheticEvent) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    getDropDownState?.(true);

    if (selectedId) {
      getSelectedId?.(selectedId);
    }

    if (selectedItem) {
      getSelectedItem?.(selectedItem);
    }
  };

  return (
    <>
      <Box
        sx={{
          padding: titlePadding,
          '&:hover': withHover ? { background: 'transparent' } : null,
          cursor: 'pointer',
        }}
        onClick={(event: SyntheticEvent<HTMLDivElement>) => handleMoreClick(event)}
      >
        {title}
      </Box>

      <Menu
        anchorEl={anchorEl}
        disableAutoFocusItem
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{
          ...sxMenuStyles,
          '& .MuiMenuItem-root': { minHeight },
        }}
      >
        {options?.length ? (
          elements
        ) : (
          <div>
            {forTwoActions && (
              <MenuItem onClick={handleFirstAction}>
                {withIcons && (firstIcon || <EditIconOutLined />)}
                <p>{firstTitle}</p>
              </MenuItem>
            )}
            <MenuItem onClick={handleSecondAction}>
              {withIcons && (secondIcon || <TrashIcon />)}
              <p>{secondTitle}</p>
            </MenuItem>
          </div>
        )}
      </Menu>
    </>
  );
};

export default PortalDropDown;
