import { createSlice } from '@reduxjs/toolkit';

import { TInitialState } from './types';
import { bookDemoSkyHawk } from './thunks';

const initialState: TInitialState = {
  isBookingLoad: false,
};

const landingSlice = createSlice({
  name: 'landingSlice',
  reducers: {},
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(bookDemoSkyHawk.pending, (state) => {
        state.isBookingLoad = true;
      })
      .addCase(bookDemoSkyHawk.fulfilled, (state) => {
        state.isBookingLoad = false;
      })

      .addCase(bookDemoSkyHawk.rejected, (state) => {
        state.isBookingLoad = false;
      });
  },
});

export default landingSlice.reducer;
