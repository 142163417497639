export type TInitialState = {
  allPartsAsc: boolean;
  allPartsLimit: number;
  allVendorsAsc: boolean;
  allPartsOffset: number;
  allVendorsLimit: number;
  allVendorsOffset: number;
  allPartsLoading: boolean;
  allVendorsLoading: boolean;
  currentPartByIdLoad: boolean;
  currentPartById: TPart | null;
  allParts: TAllPartsReturnType;
  currentVendor: TVendor | null;
  partHistoryDataLoading: boolean;
  allVendors: TAllVendorsReturnType;
  allExcelLoading: boolean;
  allExcel: TAllExcelReturnType;
  allExcelLimit: number;
  allExcelOffset: number;
  excelListLoading: boolean;
  allExcelAsc: boolean;
  allExcelError: Error | null | string;
  openedSnapshotDataLoading: boolean;
  allPartsError: Error | null | string;
  foundedVendors: TAllVendorsReturnType;
  allVendorsError: Error | null | string;
  partHistoryData: TPartHistoryReturnType;
  currentPartByIdError: Error | null | string;
  partHistoryDataError: Error | string | null;
  openedSnapshotDataError: string | null | Error;
  openedSnapshotData: TPartSnapshotOpenedReturnType;
  currentExcel: TExcel | null;
};

export enum PartsSortBy {
  ID = 'id',
  QTY = 'qty',
  SKU = 'sku',
  NAME = 'name',
  BRAND = 'brand',
  PART_NO = 'part_no',
  CREATED_AT = 'created_at',
  UPDATED_AT = 'updated_at',
  DESCRIPTION = 'description',
  MANUFACTURER = 'manufacturer',
  ITEM_LOCATION = 'item_location',
}

export type TAllPartsRequest = {
  asc: boolean;
  limit: number;
  offset: number;
  sort_by: PartsSortBy | null;
};

export type TPart = {
  id: number;
  qty: string;
  sku: string;
  cost: number;
  name: string;
  brand: string;
  part_no: string;
  description: string;
  item_location: string;
  manufacturer: string;
  item_type: string;
  created_at: string | Date;
  created_by: number | null;
  updated_at: string | Date;
  preferred_vendor_id: number;
  image_url_id: null | string;
  preferred_vendor_name: string;
  preferred_vendor: string;
} & Partial<{
  in_catalog: boolean;
  in_requisition: boolean;
  in_purchase_order: boolean;
}>;

export type TAllPartsReturnType = {
  data: TPart[];
  total_count: number;
};

export type TVendor = {
  id: number;
  zip: string;
  city: string;
  name: string;
  email: string;
  phone: string;
  state: string;
  address: string;
  created_by: number;
  updated_at: string;
  vendor_link: string;
  contact_person: string;
  created_at: string | Date;
};

export type TExcel = {
  id: number;
  media_url_id: File;
  name: string;
  version_tag: string;
  description: string;
  created_by: number;
  created_at: string;
  updated_at: string | Date;
  creator_name: string;
  default_template: boolean;
};

export type TVendorUpdate = {
  number: string;
  name: string;
  phone: string;
  contact_person: string;
  address: string;
  vendor_link: string;
  email: string;
  id: number;
};

export type TAllVendorsReturnType = {
  data: TVendor[];
  total_count: number;
};

export type TAllExcelReturnType = {
  data: TExcel[];
  total_count: number;
};

export type TGetVendorListParams = Partial<{
  limit: number;
  offset: number;
  asc: boolean;
  sort_by: GetVendorListParamsSort | null;
}>;

export type TGetExcelListParams = Partial<{
  limit: number;
  offset: number;
  asc: boolean;
  sort_by: GetExcelListParamsSort | null;
}>;

export enum GetVendorListParamsSort {
  ID = 'id',
  ZIP = 'zip',
  NAME = 'name',
  CITY = 'city',
  STATE = 'state',
  EMAIL = 'email',
  ADDRESS = 'address',
  CREATED_AT = 'created_at',
  UPDATED_AT = 'updated_at',
}
export enum GetExcelListParamsSort {
  ID = 'id',
  NAME = 'name',
  MEDIA_URL_ID = 'media_url_id',
  CREATED_BY = 'created_by',
  VERSION_TAG = 'version_tag',
  DESCRIPTION = 'description',
  CREATED_AT = 'created_at',
  UPDATED_AT = 'updated_at',
}
export type TPartSnapshotOpened = {
  id: number;
  qty: number;
  sku: string;
  name: string;
  cost: string;
  brand: string;
  status: string;
  part_id: number;
  change_desc: null;
  created_by: number;
  updated_by: number;
  image_url_id: string;
  manufacturer: string;
  item_location: string;
  part_no: null | number;
  preferred_vendor: string;
  created_at: string | Date;
  updated_at: string | Date;
  approved_by: number | null;
  description: null | string;
  preferred_vendor_id: null | number;
};

export type TPartSnapshotOpenedReturnType = {
  data: TPartSnapshotOpened[];
  total_count: number;
};

export type TPartHistory = {
  id: number;
  qty: number;
  sku: string;
  cost: number;
  name: string;
  status: string;
  part_id: number;
  created_by: number;
  updated_by: number;
  change_desc: string;
  approved_by: number;
  brand: string | null;
  manufacturer: string;
  item_location: string;
  part_no: number | null;
  preferred_vendor: string;
  created_at: string | Date;
  updated_at: string | Date;
  description: string | null;
  image_url_id: string | null;
  preferred_vendor_id: number | null;
};

export type TPartHistoryReturnType = {
  data: TPartHistory[];
  total_count: number;
};
