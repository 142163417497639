import { GridColDef } from '@mui/x-data-grid';

import { FontNames, Routes } from 'types';
import HeaderTitle from 'components/tables/EmployeeTable/HeaderTitle';

export const taskColumns: GridColDef[] = [
  {
    field: 'Name',
    headerName: 'Tech Name',
    flex: 0.7,

    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return (
        <HeaderTitle
          withSortIcons={false}
          title={headerName}
          style={{ padding: 0 }}
          fontFamily={FontNames.CIRCULAR_BOLD}
        />
      );
    },
  },
  {
    field: 'Description',
    headerName: 'Description',
    flex: 1,

    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return (
        <HeaderTitle
          withSortIcons={false}
          title={headerName}
          style={{ padding: 0 }}
          fontFamily={FontNames.CIRCULAR_BOLD}
        />
      );
    },
  },
  {
    field: 'Start Time',
    headerName: 'Start Time',
    flex: 0.5,

    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle withSortIcons={false} title={headerName} style={{ padding: 0 }} />;
    },
  },
  {
    field: 'start',
    headerName: 'Start Date',
    flex: 0.6,

    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle withSortIcons={false} title={headerName} style={{ padding: 0 }} />;
    },
  },
  {
    field: 'end',
    headerName: 'End Date',
    flex: 0.6,

    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle withSortIcons={false} title={headerName} style={{ padding: 0 }} />;
    },
  },

  {
    field: 'status',
    headerName: 'Daily Hours',
    flex: 0.45,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle withSortIcons={false} title={headerName} style={{ padding: 0 }} />;
    },
  },
  {
    field: 'options',
    headerName: '',
    width: 80,
  },
];

export const breadCrumbOptions = [
  { id: 1, label: 'Workflow', goTo: Routes.BomEstimate },
  { id: 2, label: 'Work Order', goTo: Routes.WorkOrder },
  { id: 2, label: 'Create', goTo: Routes.CreateWorkOrder },
];
