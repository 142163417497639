import { FC } from 'react';
import { Box } from '@mui/material';

import styles from './StatusInfo.module.scss';
import { StatusEnums, type TStatusInfoProps } from './types';

const StatusInfo: FC<TStatusInfoProps> = ({ status, title, color }) => (
  <Box className={styles.container}>
    <Box
      sx={{
        background:
          status === StatusEnums.ACTIVE ||
          status === StatusEnums.CONFIRMED ||
          status === StatusEnums.APPROVED ||
          status === StatusEnums.COMPLETE
            ? '#0D7D0B'
            : status === StatusEnums.PENDING ||
              status === StatusEnums.DRAFT ||
              status === StatusEnums.INACTIVE
            ? '#282828'
            : status === StatusEnums.PAID
            ? '#0D7D0B'
            : status === StatusEnums.SUBMITTED
            ? '#0D7D0B'
            : '#C20808',
      }}
      className={styles.container__box}
    />
    <p
      className={styles.container__text}
      style={{
        color: color || '#667085',
      }}
    >
      {title === StatusEnums.SENT_TO_CUSTOMER ? 'Sent To Customer' : title}
    </p>
  </Box>
);

export default StatusInfo;
