import { GreenCheckIcon, TrashIcon } from 'assets/icons';

type TData = {
  id: number;
  name: string;
  checkIcon: React.ReactNode;
  questionIcon: React.ReactNode;
};

export const data: TData[] = [
  {
    id: 0,
    name: 'Name Of Feature',
    questionIcon: <TrashIcon />,
    checkIcon: <GreenCheckIcon />,
  },
  {
    id: 1,
    name: 'Name Of Feature',
    questionIcon: <TrashIcon />,
    checkIcon: <GreenCheckIcon />,
  },
  {
    id: 2,
    name: 'Name Of Feature',
    questionIcon: <TrashIcon />,
    checkIcon: <GreenCheckIcon />,
  },
  {
    id: 3,
    name: 'Name Of Feature',
    questionIcon: <TrashIcon />,
    checkIcon: <GreenCheckIcon />,
  },
  {
    id: 4,
    name: 'Name Of Feature',
    questionIcon: <TrashIcon />,
    checkIcon: <GreenCheckIcon />,
  },
  {
    id: 5,
    name: 'Name Of Feature',
    questionIcon: <TrashIcon />,
    checkIcon: <GreenCheckIcon />,
  },
];

export const timeOptions = [
  {
    id: 1,
    title: 'Monthly',
  },
  {
    id: 1,
    title: 'Yearly',
  },
];
