import { useEffect, type FC, BaseSyntheticEvent } from 'react';
import classNames from 'classnames';
import { Box } from '@mui/material';
import { useLocation } from 'react-router-dom';

import { findCustomer } from 'store/slices/searchSlice/thunks';
import { useAppDispatch, useAppSelector, useInput } from 'hooks';
import { foundedCustomerSelector } from 'store/slices/searchSlice/selectors';
import { ControlledAutocomplete, ControlledNumberAutocomplete } from 'components';
import { Colors } from 'types';

import { TCustomerDefaultValues } from '../types';
import styles from '../InfoForms.module.scss';
import { TState } from '../types';

const CustomerInfoForm: FC<TState<TCustomerDefaultValues>> = ({
  control,
  errors,
  values,
  setValues,
  isInEditMode,
  chooseChangedReason,
}) => {
  const { pathname } = useLocation();

  const dispatch = useAppDispatch();
  const allCustomers = useAppSelector(foundedCustomerSelector);

  const isInGeneratePage = pathname.includes('generate');
  const { value: nameValue, debouncedValue: nameDebouncedValue } = useInput();
  const { value: phoneValue, debouncedValue: phoneDebouncedValue } = useInput();
  const { value: addressValue, debouncedValue: addressDebouncedValue } = useInput();
  const { value: emailValue, debouncedValue: emailDebouncedValue } = useInput();
  const { value: cityValue, debouncedValue: cityDebouncedValue } = useInput();
  const { value: zipValue, debouncedValue: zipDebouncedValue } = useInput();
  const { value: stateValue, debouncedValue: stateDebouncedValue } = useInput();

  const changeValue = (event: BaseSyntheticEvent, title: string) => {
    setValues?.({
      ...values,
      customer_info: { ...values.customer_info, [title]: event.target.value },
    });
  };

  const debouncedValues =
    nameDebouncedValue ||
    phoneDebouncedValue ||
    addressDebouncedValue ||
    emailDebouncedValue ||
    cityDebouncedValue ||
    zipDebouncedValue ||
    stateDebouncedValue;

  const getCustomersDependsOfValue = () => {
    switch (true) {
      case nameDebouncedValue.trim().length > 0:
        dispatch(findCustomer({ query: nameDebouncedValue }));
        break;
      case phoneDebouncedValue.trim().length > 0:
        dispatch(findCustomer({ query: phoneDebouncedValue }));
        break;
      case addressDebouncedValue.trim().length > 0:
        dispatch(findCustomer({ query: addressDebouncedValue }));
        break;
      case emailDebouncedValue.trim().length > 0:
        dispatch(findCustomer({ query: emailDebouncedValue }));
        break;
      case cityDebouncedValue.trim().length > 0:
        dispatch(findCustomer({ query: cityDebouncedValue }));
        break;
      case zipDebouncedValue.trim().length > 0:
        dispatch(findCustomer({ query: zipDebouncedValue }));
        break;
      case stateDebouncedValue.trim().length > 0:
        dispatch(findCustomer({ query: stateDebouncedValue }));
        break;
    }
  };

  useEffect(() => {
    getCustomersDependsOfValue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValues]);

  const customersOptionsComplete = allCustomers?.data?.map((customer) => ({
    full_name: customer?.name,
    email: customer?.email,
    phone_number: customer?.phone_number,
    address: customer?.address,
    city: customer?.city,
    state: customer?.state,
    zip: customer?.zip,
    customer_id: customer?.id,
    bill_to: customer?.billing?.[0]?.bill_to,
    bill_phone_number: customer?.billing?.[0]?.phone_number,
    bill_address: customer?.billing?.[0]?.address,
    bill_city: customer?.billing?.[0]?.city,
    bill_state: customer?.billing?.[0]?.state,
    bill_zip: customer?.billing?.[0]?.zip,
  }));

  return (
    <Box
      className={classNames(styles.container, {
        [styles.container__generate]: isInGeneratePage,
      })}
    >
      <ControlledAutocomplete
        label='Full name:'
        control={control}
        name='from_customer'
        inputValue={nameValue}
        optionsName='full_name'
        borderColor={Colors.SOFT_SILVER}
        borderRadius='5px'
        backgroundColor={Colors.FROST_WHITE}
        readonly={!isInEditMode}
        placeholder='Full name:*'
        errors={errors?.customer}
        options={customersOptionsComplete}
        handleInputChange={(event) => changeValue(event, 'full_name')}
        chooseChangedReason={(reason) => chooseChangedReason?.(reason, 'full_name')}
      />
      <ControlledAutocomplete
        label='Email:'
        control={control}
        optionsName='email'
        name='from_customer'
        placeholder='Email:*'
        borderColor={Colors.SOFT_SILVER}
        borderRadius='5px'
        backgroundColor={Colors.FROST_WHITE}
        inputValue={emailValue}
        readonly={!isInEditMode}
        errors={errors?.customer_info}
        options={customersOptionsComplete}
        handleInputChange={(event) => changeValue(event, 'email')}
        chooseChangedReason={(reason) => chooseChangedReason?.(reason, 'email')}
      />
      <ControlledNumberAutocomplete
        control={control}
        borderRadius='5px'
        name='from_customer'
        borderColor={Colors.SOFT_SILVER}
        label='Phone number:'
        errors={!!errors?.phone}
        inputValue={phoneValue}
        readonly={!isInEditMode}
        backgroundColor={Colors.FROST_WHITE}
        optionsName='phone_number'
        placeholder='Phone number:*'
        options={customersOptionsComplete}
        handleInputChange={(event) => changeValue(event, 'phone_number')}
        chooseChangedReason={chooseChangedReason}
      />
      <ControlledAutocomplete
        label='Address:'
        control={control}
        borderRadius='5px'
        name='from_customer'
        borderColor={Colors.SOFT_SILVER}
        optionsName='address'
        placeholder='Address:*'
        errors={errors?.address}
        readonly={!isInEditMode}
        backgroundColor={Colors.FROST_WHITE}
        inputValue={addressValue}
        options={customersOptionsComplete}
        handleInputChange={(event) => changeValue(event, 'address')}
        chooseChangedReason={(reason) => chooseChangedReason?.(reason, 'address')}
      />
      <ControlledAutocomplete
        label='City:'
        control={control}
        optionsName='city'
        borderRadius='5px'
        name='from_customer'
        placeholder='City:*'
        borderColor={Colors.SOFT_SILVER}
        errors={errors?.city}
        inputValue={cityValue}
        readonly={!isInEditMode}
        backgroundColor={Colors.FROST_WHITE}
        options={customersOptionsComplete}
        handleInputChange={(event) => changeValue(event, 'city')}
        chooseChangedReason={(reason) => chooseChangedReason?.(reason, 'city')}
      />
      <ControlledAutocomplete
        label='State:'
        control={control}
        borderRadius='5px'
        optionsName='state'
        name='from_customer'
        placeholder='State:*'
        borderColor={Colors.SOFT_SILVER}
        errors={errors?.state}
        inputValue={stateValue}
        readonly={!isInEditMode}
        backgroundColor={Colors.FROST_WHITE}
        options={customersOptionsComplete}
        handleInputChange={(event) => changeValue(event, 'state')}
        chooseChangedReason={(reason) => chooseChangedReason?.(reason, 'state')}
      />
      <ControlledAutocomplete
        label='Zip:'
        optionsName='zip'
        control={control}
        placeholder='Zip:*'
        borderRadius='5px'
        name='from_customer'
        errors={errors?.zip}
        borderColor={Colors.SOFT_SILVER}
        inputValue={zipValue}
        readonly={!isInEditMode}
        backgroundColor={Colors.FROST_WHITE}
        options={customersOptionsComplete}
        handleInputChange={(event) => changeValue(event, 'zip')}
        chooseChangedReason={(reason) => chooseChangedReason?.(reason, 'zip')}
      />
    </Box>
  );
};

export default CustomerInfoForm;
