import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import dataStructure from 'constants/options';

import { TActivityReturnType, TInitialState } from './types';
import {
  createActivity,
  creteNotice,
  getAllNotices,
  getAllActivities,
  getAllHidedPosts,
  getUsersActivities,
  getFilterActivities,
  postCommentCreate,
  getAllPostLikedUsers,
  getGenAiResponse,
} from './thunks';

const initialState: TInitialState = {
  genAiResponse: '',
  noticesLimit: 9,
  noticesOffset: 0,
  noticesError: null,
  noticesLoading: false,
  allActivitiesLimit: 9,
  activitiesError: null,
  notices: dataStructure,
  allActivitiesOffset: 0,
  refetchFiltering: false,
  usersActivitiesLimit: 9,
  usersActivitiesOffset: 0,
  activitiesLoading: false,
  activities: dataStructure,
  usersActivitiesError: null,
  postCommentCreateLoad: false,
  usersActivitiesLoading: false,
  postAllComments: dataStructure,
  usersActivities: dataStructure,
  hiddenActivityPosts: dataStructure,
  postLikedAllUsersData: dataStructure,
};

const activitiesSlice = createSlice({
  name: 'activitiesSlice',
  reducers: {
    setPage(state, action) {
      state.allActivitiesOffset = (action.payload - 1) * state.allActivitiesLimit;
    },

    refetchFiltering: (state) => {
      state.refetchFiltering = !state.refetchFiltering;
    },

    setUsersActivitiesPage(state, action) {
      state.usersActivitiesOffset = (action.payload - 1) * state.usersActivitiesLimit;
    },

    changeNoticePage(state, action) {
      state.noticesOffset = action.payload;
    },
  },
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(createActivity.pending, (state) => {
        // state.activitiesLoading = true;
        state.activitiesError = null;
      })
      .addCase(createActivity.fulfilled, (state, action) => {
        state.activitiesError = null;
        state.activities = action.payload;
      })
      .addCase(getAllActivities.fulfilled, (state, action: PayloadAction<TActivityReturnType>) => {
        state.activities = action.payload;
        state.activitiesLoading = false;
        state.activitiesError = null;
      })
      .addCase(getAllActivities.pending, (state) => {
        state.activitiesLoading = true;
        state.activitiesError = null;
      })
      .addCase(getAllActivities.rejected, (state, action) => {
        state.activitiesLoading = false;
        state.activitiesError = action.payload as string;
      })
      .addCase(
        getFilterActivities.fulfilled,
        (state, action: PayloadAction<TActivityReturnType>) => {
          state.activities = action.payload;
          // state.activitiesLoading = false;
          state.activitiesError = null;
        },
      )
      .addCase(createActivity.rejected, (state, action) => {
        state.activitiesError = action.payload as string;
      })
      .addCase(getAllNotices.fulfilled, (state, action) => {
        state.notices = action.payload;
        state.noticesLoading = false;
        state.noticesError = null;
      })
      .addCase(getAllNotices.pending, (state) => {
        state.noticesLoading = true;
        state.noticesError = null;
      })
      .addCase(getAllNotices.rejected, (state, action) => {
        state.noticesLoading = false;
        state.noticesError = action.payload as string;
      })
      .addCase(getAllHidedPosts.fulfilled, (state, action) => {
        state.hiddenActivityPosts = action.payload;
        // state.activitiesLoading = false;
      })

      .addCase(getUsersActivities.pending, (state) => {
        state.usersActivitiesLoading = true;
        state.usersActivitiesError = null;
      })
      .addCase(getUsersActivities.fulfilled, (state, action) => {
        state.usersActivitiesLoading = false;
        state.usersActivitiesError = null;
        state.usersActivities = action.payload;
      })
      .addCase(getUsersActivities.rejected, (state) => {
        state.usersActivitiesLoading = false;
        state.usersActivitiesError = null;
      })
      .addCase(creteNotice.fulfilled, (state, action) => {
        state.notices = {
          ...state.notices,
          data: [...(state.notices.data || []), action.payload],
        };
        state.noticesLoading = false;
        state.noticesError = null;
      })
      .addCase(postCommentCreate.pending, (state) => {
        state.postCommentCreateLoad = true;
      })
      .addCase(postCommentCreate.fulfilled, (state) => {
        state.postCommentCreateLoad = false;
      })
      .addCase(postCommentCreate.rejected, (state) => {
        state.postCommentCreateLoad = false;
      })
      .addCase(getAllPostLikedUsers.fulfilled, (state, action) => {
        state.postLikedAllUsersData = action?.payload;
      })
      .addCase(getGenAiResponse.fulfilled, (state, action) => {
        state.genAiResponse = action?.payload as string;
      });
  },
});

export const { setUsersActivitiesPage, changeNoticePage, refetchFiltering } =
  activitiesSlice.actions;

export default activitiesSlice.reducer;
