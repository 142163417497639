import { FC } from 'react';
import { Box } from '@mui/material';
import classNames from 'classnames';

import { TInvoiceStatus } from './types';
import styles from './InvoiceStatus.module.scss';

const InvoiceStatus: FC<TInvoiceStatus> = ({ status = 'paid' }) => {
  const statusTitle = status === 'paid' ? 'Completed' : 'Canceled';

  const statusClasses = classNames(styles.container);

  const statusCircleClasses = classNames(styles.container__circle, {
    [styles.container__circle_cancel]: status === 'uncollectible',
  });

  return (
    <Box className={statusClasses}>
      <Box className={statusCircleClasses} />
      {statusTitle}
    </Box>
  );
};

export default InvoiceStatus;
