import { noop } from 'lodash';
import { isSafari } from 'react-device-detect';

import { LogoIcon } from 'assets/icons';
import { ModalLayout } from 'components/shared';
import useBrowserVersion from 'hooks/useBrowserVersion';

import styles from './UpdateVersionPopup.module.scss';

const UpdateVersionPopup = () => {
  const { isVersionLow } = useBrowserVersion();

  if (!isSafari) {
    return null;
  }

  return (
    <ModalLayout isOpen={isVersionLow || false} onClose={noop}>
      <div className={styles.container}>
        <div className={styles.container__header}>
          <LogoIcon />
        </div>

        <h2>
          To ensure the best experience, we recommend updating your Safari version to 16 or higher.
        </h2>
      </div>
    </ModalLayout>
  );
};

export default UpdateVersionPopup;
