import React, { FC, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import classNames from 'classnames';

import { CustomTypography } from 'components';

import styles from './ContentOptions.module.scss';
import { TContentOptionsProps } from './types';

const ContentOptions: FC<TContentOptionsProps> = ({ title, firstTab, secondTab, getActiveTab }) => {
  const tabItems = [
    { id: 1, title: firstTab },
    { id: 2, title: secondTab },
  ];

  const [activeTab, setActiveTab] = useState<string>(firstTab);

  useEffect(() => {
    getActiveTab(activeTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  const changeActiveTitle = (title: string) => setActiveTab(title);

  const tabItemsRendered = tabItems?.map((tab) => {
    const tabItemClasses = classNames(styles.container__content__item, {
      [styles.container__content__item_active]: tab?.title === activeTab,
    });

    return (
      <Box
        key={tab?.id}
        role='button'
        className={tabItemClasses}
        onClick={() => changeActiveTitle(tab?.title)}
      >
        {tab?.title}
      </Box>
    );
  });

  return (
    <Box className={styles.container}>
      <CustomTypography className={styles.container__title}>{title}</CustomTypography>
      <Box className={styles.container__content}>{tabItemsRendered}</Box>
    </Box>
  );
};

export default ContentOptions;
