import { FC } from 'react';
import { Box } from '@mui/material';
import { useFieldArray } from 'react-hook-form';
import { GridColDef } from '@mui/x-data-grid';
import { v4 } from 'uuid';

import CustomDataGrid from 'components/views/CustomDataGrid';
import {
  Button,
  CustomSkeleton,
  PortalDropDown,
  ScrollLayout,
  StatusInfo,
  TableControlledRowItem,
} from 'components';
import { Colors, FontNames } from 'types';
import { StatusEnums } from 'components/shared/StatusInfo/types';
import { FilledPlusIcon, MoreIcon, TrashIcon } from 'assets/icons';
import { useAppDispatch } from 'hooks';
import { removeCouponById } from 'store/slices/discountSlice/thunks';
import HeaderTitle from 'components/tables/EmployeeTable/HeaderTitle';
import { EmptyTitles } from 'constants/EmptyTitles';

import SectionHead from '../../SectionHead';
import { discountPercentageOptions, statusOptions } from '../../../Discount/utils';

import { TDiscountSectionProps } from './types';

const DiscountSection: FC<TDiscountSectionProps> = ({
  control,
  errors,
  isLoading = false,
  getValues,
}) => {
  const dispatch = useAppDispatch();

  const { append, remove } = useFieldArray({ control, name: 'discounts' });

  const handleAddCouponClick = () => {
    append({
      coupon: {
        id: v4(),
        end_date: new Date(),
        coupon_code: '',
        start_date: new Date(),
        discount_percent: 0,
        coupon_status: StatusEnums.INACTIVE,
      },
    });
  };

  const handleRemoveDiscount = (idx: number, id: number) => {
    if (id) {
      dispatch(removeCouponById(id));
    }

    remove(idx);
  };

  const getIndexByRow = (arr: any[], id: string | number) => {
    return arr ? arr.findIndex((r) => r.coupon?.id === id) : -1;
  };

  const columns: GridColDef[] = [
    {
      field: 'code',
      headerName: 'Coupon Code',
      flex: 1,

      renderHeader: (params) => {
        const headerName = params?.colDef?.headerName || '';
        return (
          <HeaderTitle
            withSortIcons={false}
            title={headerName}
            style={{ padding: 0 }}
            fontFamily={FontNames.CIRCULAR_BOLD}
          />
        );
      },
      renderCell: (params) => {
        const index = getIndexByRow(getValues('discounts'), params.id);

        if (index === -1) {
          return null;
        }

        return (
          <TableControlledRowItem
            placeholder='Code'
            control={control}
            isExisted={false}
            name={`discounts.${[index]}.coupon.coupon_code`}
            error={!!errors?.discounts?.[index]?.coupon?.coupon_code}
          />
        );
      },
    },

    {
      field: 'discount',
      headerName: 'Discount',
      flex: 0.8,

      renderHeader: (params) => {
        const headerName = params?.colDef?.headerName || '';
        return <HeaderTitle withSortIcons={false} title={headerName} style={{ padding: 0 }} />;
      },
      renderCell: (params) => {
        const index = getIndexByRow(getValues('discounts'), params.id);

        if (index === -1) {
          return null;
        }

        return (
          <TableControlledRowItem
            control={control}
            isExisted={false}
            placeholder='Discount'
            componentType='select'
            options={discountPercentageOptions}
            name={`discounts.${[index]}.coupon.discount_percent`}
            error={!!errors?.discounts?.[index]?.coupon?.discount_percent}
          />
        );
      },
    },
    {
      field: 'start',
      headerName: 'Start Date',
      flex: 1,

      renderHeader: (params) => {
        const headerName = params?.colDef?.headerName || '';
        return <HeaderTitle withSortIcons={false} title={headerName} style={{ padding: 0 }} />;
      },
      renderCell: (params) => {
        const index = getIndexByRow(getValues('discounts'), params.id);

        if (index === -1) {
          return null;
        }

        return (
          <TableControlledRowItem
            control={control}
            componentType='picker'
            placeholder='Start Date'
            isExisted={false}
            name={`discounts.${[index]}.coupon.start_date`}
            error={!!errors?.discounts?.[index]?.coupon?.start_date}
          />
        );
      },
    },
    {
      field: 'end',
      headerName: 'End Date',
      flex: 1,

      renderHeader: (params) => {
        const headerName = params?.colDef?.headerName || '';
        return <HeaderTitle withSortIcons={false} title={headerName} style={{ padding: 0 }} />;
      },
      renderCell: (params) => {
        const index = getIndexByRow(getValues('discounts'), params.id);

        if (index === -1) {
          return null;
        }

        return (
          <TableControlledRowItem
            control={control}
            isExisted={false}
            componentType='picker'
            placeholder='End Date'
            name={`discounts.${[index]}.coupon.end_date`}
            error={!!errors?.discounts?.[index]?.coupon?.end_date}
          />
        );
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      renderHeader: (params) => {
        const headerName = params?.colDef?.headerName || '';
        return <HeaderTitle withSortIcons={false} title={headerName} style={{ padding: 0 }} />;
      },
      renderCell: (params) => {
        const index = getIndexByRow(getValues('discounts'), params.id);

        if (index === -1) {
          return null;
        }

        return (
          <TableControlledRowItem
            control={control}
            componentType='select'
            placeholder='End Date'
            isExisted={false}
            options={statusOptions}
            selectDefaultValue='Status'
            name={`discounts.${[index]}.coupon.coupon_status`}
            error={!!errors?.discounts?.[index]?.coupon?.coupon_status}
            Component={<StatusInfo title={'A'} status={'A' as StatusEnums} />}
          />
        );
      },
    },
    {
      field: 'options',
      headerName: '',
      width: 80,
      renderCell: (params) => {
        const index = getIndexByRow(getValues('discounts'), params.id);

        if (index === -1) {
          return null;
        }

        return (
          <PortalDropDown
            title={<MoreIcon />}
            options={[
              {
                id: 1,
                name: 'Delete',
                action: () => handleRemoveDiscount(index, Number(params.id)),
                icon: <TrashIcon />,
              },
            ]}
          />
        );
      },
    },
  ];

  const renderColumns: GridColDef[] = columns.map((column) => ({
    ...column,
    sortable: false,
    hideSortIcons: true,
    disableColumnMenu: true,
    disableColumnSelector: true,
  }));

  const cloned = getValues('discounts')?.map(({ coupon }) => ({
    id: coupon?.id,
    code: coupon?.coupon_code,
    discounts: coupon?.discount_percent,
    start: coupon?.start_date,
    end: coupon?.end_date,
    status: coupon?.coupon_status,
  }));

  return (
    <Box>
      <SectionHead
        title='Discounts'
        subtitle={`(These discount codes can be applied to linked estimates via the estimate screen.)`}
      />
      <Box>
        {isLoading ? (
          <CustomSkeleton width='100%' height={80} />
        ) : (
          <ScrollLayout>
            <CustomDataGrid
              rowHeight={70}
              columns={renderColumns}
              rows={cloned || []}
              getRowId={(row) => row?.id}
              emptyTitle={EmptyTitles.Catalog_Discounts}
            />
          </ScrollLayout>
        )}
      </Box>
      <Box>
        <Button
          color={Colors.BLACK}
          isUppercase={false}
          startIcon={<FilledPlusIcon />}
          fontFamily={FontNames.CIRCULAR_REG}
          onClick={handleAddCouponClick}
        >
          Add New Discount
        </Button>
      </Box>
    </Box>
  );
};

export default DiscountSection;
