import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { transactionApi } from 'api';

export const getTransactions = createAsyncThunk('transactionSlice/getTransactions', async () => {
  try {
    const response = await transactionApi.getAllTransactionRequest();
    return response.data;
  } catch (error) {
    const Error = error as AxiosError;
    throw Error;
  }
});
