import {
  FifthUserImg,
  FirstUserImg,
  FourthUserImg,
  SecondUserImg,
  SixthUserImg,
  ThirdUserImg,
} from 'assets/images';

export const talkingUsers = [
  {
    id: 3,
    userImg: ThirdUserImg,
    title: 'streamlined my operations...',
    top: 90,
    right: -60,
  },
  {
    id: 2,
    userImg: SecondUserImg,
    title: 'game changer for my business...',
    top: 90,
    right: -60,
  },
  {
    id: 5,
    userImg: FifthUserImg,
    title: 'making more money...',
    top: 70,
    right: -60,

    objectFit: 'fill',
  },
  {
    id: 6,
    userImg: SixthUserImg,
    title: 'SwiftTask has streamlined tasks...',
    top: 83,
    right: -29,
  },
  {
    id: 1,
    userImg: FirstUserImg,
    title: 'It has allowed me to focus...',
    position: 'right',
    top: 120,
    left: -50,
  },
  {
    id: 4,
    userImg: FourthUserImg,
    title: 'Fantastic it is...',
    position: 'right',
    top: 145,
    left: -20,
  },
];
