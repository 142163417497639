import { GridColDef } from '@mui/x-data-grid';

import { FontNames } from 'types';
import { TOption } from 'components/shared/Select/types';
import HeaderTitle from 'components/tables/EmployeeTable/HeaderTitle';

export const columns: GridColDef[] = [
  {
    field: 'code',
    headerName: 'Coupon Code',
    flex: 1,

    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return (
        <HeaderTitle
          title={headerName}
          style={{ padding: 0 }}
          fontFamily={FontNames.CIRCULAR_BOLD}
        />
      );
    },
    renderCell: (params) => {
      return params?.value;
    },
  },

  {
    field: 'discount',
    headerName: 'Discount',
    flex: 1,

    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle title={headerName} style={{ padding: 0 }} />;
    },
    renderCell: (params) => {
      return params?.value;
    },
  },
  {
    field: 'start',
    headerName: 'Start Date',
    flex: 1,

    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle title={headerName} style={{ padding: 0 }} />;
    },
    renderCell: (params) => {
      return params?.value;
    },
  },
  {
    field: 'end',
    headerName: 'End Date',
    flex: 1,

    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle title={headerName} style={{ padding: 0 }} />;
    },
    renderCell: (params) => {
      return params?.value;
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 1,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle title={headerName} style={{ padding: 0 }} />;
    },
    renderCell: (params) => {
      return params.value;
    },
  },
  {
    field: 'options',
    headerName: '',
    width: 80,
    renderCell: (params) => {
      return params.value;
    },
  },
];

export const rowsList = [
  {
    id: 1,
    code: 'ABG-100',
    discount: '10%',
    start: '2023-10-01',
    end: '2023-10-01',
    status: 'active',
    hasBefore: true,
  },
  {
    id: 2,
    code: 'ABG-100',
    discount: '10%',
    start: '2023-10-01',
    end: '2023-10-01',
    status: 'inactive',
    hasBefore: true,
  },
  {
    id: 3,
    code: 'ABG-100',
    discount: '10%',
    start: '2023-10-01',
    end: '2023-10-01',
    status: 'active',
    hasBefore: true,
  },
];

export const statusOptions: TOption[] = [
  { id: 1, label: 'active', value: 'active' },
  { id: 2, label: 'inactive', value: 'inactive' },
];

export const discountPercentageOptions = Array.from({ length: 101 }).map((_, idx) => ({
  id: idx,
  label: `${idx}%`,
  value: idx,
}));
