import { TOption } from 'components/shared/Select/types';
import { Colors, FontNames } from 'types';

import { TBillingFieldDataItem } from './types';

export const customerData: TOption[] = [
  { id: 1, value: 'organization', label: 'Organization' },
  { id: 2, value: 'individual', label: 'Individual' },
];

export const filterNestedObjects = (inputArray: TBillingFieldDataItem[]) => {
  return inputArray?.map((item) => {
    const nestedValues = Object.values(item);
    const filteredValues = nestedValues.filter((nestedItem) => Object.keys(nestedItem).length > 0);
    const combinedObject = Object.assign({}, ...filteredValues);
    return combinedObject;
  });
};

export const billingAppendOptions = {
  address: '',
  bill_to: '',
  city: '',
  email: '',
  id: '',
  phone_number: '',
  state: '',
  zip: '',
};

export const contactAppendOptions = {
  address: '',
  city: '',
  created_at: '',
  email: '',
  id: '',
  name: '',
  phone_number_1: '',
  phone_number_2: '',
  state: '',
  updated_at: '',
  zip: '',
};

export const formData = [
  {
    type: 'ControlledSelect',
    name: 'customer.type',
    borderRadius: '8px',
    options: customerData,
    backgroundColor: '#FCFCFC',
    border: '1px solid #EDEFF1',
    defaultValue: 'Customer Type:*',
    fontFamily: FontNames.CIRCULAR_REG,
    labelColor: Colors.ENIGMATIC_MIDNIGHT,
  },
  {
    type: 'ControlledInput',
    required: true,
    name: 'customer.name',
    label: 'Name:',
    borderRadius: '8px',
    borderColor: '#EDEFF1',
    backgroundColor: '#FCFCFC',
    fontFamily: FontNames.CIRCULAR_REG,
    placeholder: 'Name Of Company:',
    labelColor: Colors.ENIGMATIC_MIDNIGHT,
  },
  {
    type: 'ControlledInput',
    required: false,
    borderRadius: '8px',
    label: 'Company Link:',
    borderColor: '#EDEFF1',
    backgroundColor: '#FCFCFC',
    name: 'customer.website_url',
    placeholder: 'Company Link:',
    fontFamily: FontNames.CIRCULAR_REG,
    labelColor: Colors.ENIGMATIC_MIDNIGHT,
  },
  {
    type: 'ControlledInput',
    required: true,
    label: 'Email:',
    borderRadius: '8px',
    placeholder: 'Email:',
    borderColor: '#EDEFF1',
    name: 'customer.email',
    backgroundColor: '#FCFCFC',
    fontFamily: FontNames.CIRCULAR_REG,
    labelColor: Colors.ENIGMATIC_MIDNIGHT,
  },
  {
    type: 'NumberControlledInput',
    required: true,
    label: 'Phone:',
    borderColor: '#EDEFF1',
    backgroundColor: '#FCFCFC',
    placeholder: 'Phone Number:*',
    borderRadius: '8px',
    fontFamily: FontNames.CIRCULAR_REG,
    name: 'customer.phone_number',
    labelColor: Colors.ENIGMATIC_MIDNIGHT,
  },

  {
    type: 'ControlledInput',
    required: true,
    label: 'Address:',
    borderRadius: '8px',
    borderColor: '#EDEFF1',
    placeholder: 'Address:',
    name: 'customer.address',
    backgroundColor: '#FCFCFC',
    fontFamily: FontNames.CIRCULAR_REG,
    labelColor: Colors.ENIGMATIC_MIDNIGHT,
  },
  {
    type: 'ControlledInput',
    label: 'City:',
    required: true,
    placeholder: 'City:',
    borderRadius: '8px',
    borderColor: '#EDEFF1',
    name: 'customer.city',
    backgroundColor: '#FCFCFC',
    fontFamily: FontNames.CIRCULAR_REG,
    labelColor: Colors.ENIGMATIC_MIDNIGHT,
  },
  {
    type: 'ControlledInput',
    label: 'State:',
    required: true,
    placeholder: 'State:',
    backgroundColor: '#FCFCFC',
    borderColor: '#EDEFF1',
    borderRadius: '8px',
    name: 'customer.state',
    fontFamily: FontNames.CIRCULAR_REG,
    labelColor: Colors.ENIGMATIC_MIDNIGHT,
  },
  {
    type: 'ControlledInput',
    label: 'Zip:',
    required: true,
    placeholder: 'Zip:',
    backgroundColor: '#FCFCFC',
    borderRadius: '8px',
    name: 'customer.zip',
    borderColor: '#EDEFF1',
    fontFamily: FontNames.CIRCULAR_REG,
    labelColor: Colors.ENIGMATIC_MIDNIGHT,
  },
];
