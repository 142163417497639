import { FC, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';

import { AddIcon } from 'assets/icons';
import { useAppDispatch, useAppSelector } from 'hooks';
import { setPage } from 'store/slices/estimatesSlice';
import { Button, CustomBreadcrumbs, CustomTypography } from 'components';
import CreateEstimateDrawer from 'components/views/Drawers/CreateEstimateDrawer';
import { userPermissionsSelector } from 'store/slices/authSlice/selectors';
import { PermissionMessages } from 'constants/PermissionMessages';
import { Colors } from 'types';

import { breadCrumbOptions } from './utils';
import { TBomEstimateHeaderProps } from './types';
import styles from './BomEstimateHeader.module.scss';

const BomEstimateHeader: FC<TBomEstimateHeaderProps> = ({ totalRows }) => {
  const { state } = useLocation();
  const dispatch = useAppDispatch();

  const {
    isAccessToEstimatesEditor,
    isAccessToCatalogsEditor,
    isAccessToInventoryEditor,
    isAccessToCustomerEditor,
  } = useAppSelector(userPermissionsSelector);

  const haveAccess =
    isAccessToEstimatesEditor &&
    isAccessToCatalogsEditor &&
    isAccessToInventoryEditor &&
    isAccessToCustomerEditor;

  const [currentPage, setCurrentPage] = useState<number>(0);

  const comingFromCustomer = state?.isOpenCreateDrawer;

  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(comingFromCustomer || false);

  const openDrawer = () => setIsDrawerOpen(true);
  const closeDrawer = () => setIsDrawerOpen(false);

  const handlePageChange = (_event: unknown, newPage: number) => {
    setCurrentPage(newPage);
    dispatch(setPage(newPage + 1));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  useEffect(() => {
    if (!totalRows && currentPage) {
      setCurrentPage(currentPage - 1);
      handlePageChange(null, currentPage - 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalRows]);

  const renderClasses = classNames(styles.container__actions, {
    [styles.container__actions__between]: totalRows > 0,
  });

  return (
    <Box className={styles.container}>
      <CustomBreadcrumbs options={breadCrumbOptions} />
      <Box className={styles.container__content}>
        <Box className={styles.container__info}>
          <CustomTypography className={styles.container__info__title}>Estimates</CustomTypography>
        </Box>
        <>
          <Box className={renderClasses}>
            <div />
            <Button
              color='white'
              onClick={openDrawer}
              backgroundColor={Colors.SAPPHIRE}
              disabled={!haveAccess}
              className={styles.container__actions__create}
              startIcon={<AddIcon width={24} height={24} />}
              tooltipMessage={!haveAccess ? PermissionMessages.EstimateCreationMessage : undefined}
            >
              Create Estimates
            </Button>
          </Box>
          <CreateEstimateDrawer open={isDrawerOpen} handleClose={closeDrawer} />
        </>
      </Box>
    </Box>
  );
};

export default BomEstimateHeader;
