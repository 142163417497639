import { createSlice } from '@reduxjs/toolkit';

import dataStructure from 'constants/options';

import { getRfqs } from './thunks';

import type { TInitialState } from './types';

const initialState: TInitialState = {
  allRfqsLimit: 10,
  allRfqsOffset: 0,
  allRfqs: dataStructure,
  allRfqsError: null,
  allRfqsLoading: false,
};

const rfqsSlice = createSlice({
  name: 'requisitionSlice',
  reducers: {
    setPage(state, action) {
      state.allRfqsOffset = (action.payload - 1) * state.allRfqsLimit;
    },
  },
  initialState,
  extraReducers: (builder) => {
    builder

      .addCase(getRfqs.pending, (state) => {
        state.allRfqsLoading = true;
        state.allRfqsError = null;
      })
      .addCase(getRfqs.fulfilled, (state, action) => {
        state.allRfqsLoading = false;
        state.allRfqs = action.payload;
      })
      .addCase(getRfqs.rejected, (state, action) => {
        state.allRfqsLoading = false;
        state.allRfqsError = action.error.message as string;
      });
  },
});

export default rfqsSlice.reducer;

export const { setPage } = rfqsSlice.actions;
