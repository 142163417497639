import { useState, forwardRef, ForwardedRef, useMemo } from 'react';
import classNames from 'classnames';
import { InputLabel } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { useTheme } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { useAppDispatch } from 'hooks';
import { Chip, EmptyTitle, ErrorMessage } from 'components';
import { deleteJobType } from 'store/slices/agreementSlice/thunks';
import { ArrowDown, CloseIcon, SquareDeleteIcon } from 'assets/icons';
import { Colors, FontNames } from 'types';

import styles from './Select.module.scss';
import { MenuProps, getStyles, sx } from './styles';

import type { TSelectProps } from './types';

const SelectComponent = forwardRef(
  <T,>(
    {
      icon,
      value,
      label,
      onClear,
      options,
      onChange,
      disabled,
      maxWidth,
      labelColor,
      padding,
      fontFamily,
      helperText,
      lineHeight,
      defaultValue,
      labelClasses,
      error = false,
      required = false,
      borderRadius = '5px',
      multiple = false,
      setJobTypesState,
      withTags = false,
      showLabel = false,
      withLabel = true,
      withClear = false,
      backgroundColor = '',
      isWithoutIcon = false,
    }: TSelectProps<T>,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const dispatch = useAppDispatch();

    const theme = useTheme();

    const [selectValue, setSelectValue] = useState<string[] | number[]>([]);

    const showClear = withClear && (!!value || selectValue?.length > 0);

    const handleChange = (event: SelectChangeEvent<typeof selectValue>) => {
      const { value } = event.target;
      setSelectValue(typeof value === 'string' ? value.split(',') : value);
      setJobTypesState && setJobTypesState(value as number[]);
    };

    const handleClear = () => {
      setSelectValue([]);
      if (typeof onChange === 'function') {
        onClear?.();
      }
    };

    const handleChipDelete = (value: string) => {
      dispatch(deleteJobType(Number(value)));
      const filteredOptions = options?.filter((option) => option.value !== value);
      setSelectValue(filteredOptions as string[]);
    };

    const renderMenuItemValue = (label: string, color: string, value: string) => {
      if (withTags) {
        return (
          <Chip
            color={color}
            deleteIcon={<SquareDeleteIcon />}
            label={label}
            onDelete={() => handleChipDelete(value)}
          />
        );
      }
      return label;
    };

    const haveLabel = withLabel ? label || defaultValue : false;

    const labelClassName = classNames(styles.container__label, labelClasses);

    const IconComponent = useMemo(() => icon as React.FC<React.SVGProps<SVGSVGElement>>, [icon]);

    return (
      <FormControl
        error={error}
        sx={{
          m: 0,
          width: '100%',
          maxWidth: maxWidth,
          fontFamily: `${fontFamily} !important`,

          '& .MuiFormLabel-root': {
            fontFamily: `${fontFamily || FontNames.CIRCULAR_REG} !important`,
          },
        }}
        ref={ref}
      >
        {haveLabel && (
          <InputLabel
            style={{
              color: error ? Colors.CRIMSON_RED : labelColor,
            }}
            className={labelClassName}
            id='demo-simple-select-label'
          >
            {haveLabel}
          </InputLabel>
        )}
        {helperText && error && <ErrorMessage message={helperText} />}

        <Select
          required={required}
          disabled={disabled}
          className={styles.container__select}
          sx={{
            ...sx,
            fontFamily,
            lineHeight: `${lineHeight} !important`,
            '& .MuiSelect-select': {
              padding,
              display: 'flex',
              alignItems: 'center',
            },

            '&:hover fieldset': {
              borderColor: '#2067dd !important',
            },

            fieldset: {
              border: error ? '1px solid red !important' : '1px solid #EDEFF1',
              borderWidth: '1px !important',
            },
          }}
          multiple={multiple}
          MenuProps={MenuProps}
          id='demo-simple-select'
          value={value}
          onChange={!multiple ? onChange : handleChange}
          IconComponent={(props) => (
            <>
              {!isWithoutIcon && (
                <>
                  {showClear && <CloseIcon className={styles.clearIcon} onClick={handleClear} />}
                  <ArrowDown {...props} />
                </>
              )}
            </>
          )}
          label={label}
          labelId='demo-simple-select-label'
          style={{ background: backgroundColor, borderRadius: borderRadius }}
        >
          {options?.length ? (
            options.map(({ value, label, color }) => (
              <MenuItem
                key={value}
                value={value}
                className={styles.container__select__item}
                sx={getStyles(value as string, selectValue as string[], theme)}
              >
                {icon && <IconComponent style={{ marginRight: '8px' }} />}
                {showLabel
                  ? label
                  : renderMenuItemValue(label as string, String(color), String(value))}
              </MenuItem>
            ))
          ) : (
            <EmptyTitle title='No Options' />
          )}
        </Select>
      </FormControl>
    );
  },
);

export default SelectComponent;
