import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

import { subscriptionsApi } from 'api';
import {
  AccountInformationUpdateToastMessages,
  BillingUpdateToastMessages,
  SubscriptionUpdateToastMessages,
} from 'constants/ToastMessages';
import { RootState } from 'types';
import { BrowserStorageKeys, BrowserStorageService } from 'services';

import {
  TCreateSubscription,
  TCreatePaymentIntent,
  TUpdateBillingDetails,
  TUserSubscriptionTypes,
  UserSubscriptionStatus,
  TSetDefaultPaymentMethod,
  TUpdateSubscriptionParams,
  TUpdateSubscriptionResponse,
} from './types';

export const createSubscription = createAsyncThunk(
  'createSubscription',
  async (options: TCreateSubscription, { dispatch, getState }) => {
    const {
      subscriptions: { freeSubscriptionTrial },
    } = getState() as RootState;

    const sendedValues = freeSubscriptionTrial
      ? {
          ...options,
          free: freeSubscriptionTrial,
        }
      : {
          ...options,
        };

    try {
      const response = await subscriptionsApi.createSubscriptionRequest(sendedValues);

      dispatch(
        getUserSubscriptions({
          org_id: options?.org_id,
          subscription_status: UserSubscriptionStatus.ALL,
        }),
      );

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      throw Error;
    }
  },
);

export const createStripeAccount = createAsyncThunk(
  'createStripeAccount',
  async (options: FormData) => {
    const start = toast.loading(AccountInformationUpdateToastMessages.ACCOUNT_UPDATE_START);
    try {
      const response = await subscriptionsApi.CreateStripeAccountRequest(options);
      toast.update(start, {
        render: AccountInformationUpdateToastMessages.ACCOUNT_UPDATE_SUCCESS,
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });
      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      toast.update(start, {
        render: AccountInformationUpdateToastMessages.ACCOUNT_UPDATE_FAILURE,
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });
      throw Error;
    }
  },
);

export const createPaymentIntent = createAsyncThunk(
  'createPaymentIntent',
  async (options: TCreatePaymentIntent) => {
    try {
      const response = await subscriptionsApi.createPaymentIntentRequest(options);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      throw Error;
    }
  },
);

export const getUserSubscriptions = createAsyncThunk(
  'subscriptionsSlice/getUserSubscriptions',
  async (options: TUserSubscriptionTypes) => {
    try {
      const response = await subscriptionsApi.getUserSubscriptionsRequest(options);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      throw Error;
    }
  },
);

export const getUserSubscriptionsTrailing = createAsyncThunk(
  'subscriptionsSlice/getUserSubscriptionsTrailing',
  async () => {
    const currentOrganization = BrowserStorageService.get(BrowserStorageKeys.CurrentOrganizationId);

    try {
      const response = await subscriptionsApi.getUserSubscriptionsRequest({
        org_id: Number(currentOrganization),
        subscription_status: UserSubscriptionStatus.TRIALING,
      });

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const getPaymentMethods = createAsyncThunk(
  'subscriptionsSlice/getPaymentMethods',
  async (org_id: number) => {
    try {
      const response = await subscriptionsApi.getPaymentMethodsRequest(org_id);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      throw Error;
    }
  },
);

export const getDefaultPaymentMethod = createAsyncThunk(
  'subscriptionsSlice/getDefaultPaymentMethod',
  async (org_id: number) => {
    try {
      const response = await subscriptionsApi.getDefaultPaymentMethodsRequest(org_id);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      throw Error;
    }
  },
);

export const updateBillingDetails = createAsyncThunk(
  'subscriptionsSlice/updateBillingDetails',
  async (options: TUpdateBillingDetails, { dispatch }) => {
    const id = toast.loading(BillingUpdateToastMessages.BILLING_UPDATE_START);

    try {
      const response = await subscriptionsApi.updateBillingDetailsRequest(options);
      dispatch(getPaymentMethods(options?.org_id));
      dispatch(getDefaultPaymentMethod(options?.org_id));

      toast.update(id, {
        render: BillingUpdateToastMessages.BILLING_UPDATE_SUCCESS,
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      toast.update(id, {
        render: BillingUpdateToastMessages.BILLING_UPDATE_FAILURE,
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });

      throw Error;
    }
  },
);

export const getInvoiceHistory = createAsyncThunk(
  'subscriptionsSlice/getInvoiceHistory',
  async (org_id: number) => {
    try {
      const response = await subscriptionsApi.getInvoiceHistoryRequest(org_id);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      throw Error;
    }
  },
);

export const setDefaultPaymentMethod = createAsyncThunk(
  'subscriptionsSlice/setDefaultPaymentMethod',
  async (options: TSetDefaultPaymentMethod) => {
    try {
      const response = await subscriptionsApi.setDefaultPaymentMethodRequest(options);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      throw Error;
    }
  },
);

export const updateSubscription = createAsyncThunk(
  'subscriptionsSlice/updateSubscription',
  async ({ toast: message, ...rest }: TUpdateSubscriptionParams) => {
    try {
      const response = await subscriptionsApi.updateSubscriptionRequest(rest);
      const receivedData = response.data as TUpdateSubscriptionResponse;
      if (message && receivedData?.type === 'updated_subscription') {
        toast.update(message, {
          render: SubscriptionUpdateToastMessages.SUBSCRIPTION_UPDATE_SUCCESS,
          type: 'success',
          isLoading: false,
          autoClose: 3000,
        });
      }

      return response.data as TUpdateSubscriptionResponse;
    } catch (error) {
      const Error = error as AxiosError;

      if (message) {
        toast.update(message, {
          render: SubscriptionUpdateToastMessages.SUBSCRIPTION_UPDATE_FAILURE,
          type: 'error',
          isLoading: false,
          autoClose: 3000,
        });
      }

      throw Error;
    }
  },
);
