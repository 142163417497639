import { client } from 'api/client';
import { endpoints } from 'api/endpoints';
import {
  TCreateContactData,
  TCreateCustomerWholeData,
  TCreateNewBillingInfoData,
  TCustomersParams,
  TUpdateBillingInfoData,
  TUpdateCustomerContact,
  TUpdateCustomerData,
} from 'store/slices/customersSlices/types';

export const getAllCustomersRequest = (options: TCustomersParams) =>
  client.get(endpoints.CustomerService.getAllCustomers(), { params: { ...options } });

export const createAllCustomersRequest = (body: TCreateCustomerWholeData) => {
  return client.post(endpoints.CustomerService.createCustomer(), body);
};

export const updateCustomerRequest = (customer: TUpdateCustomerData) =>
  client.put(endpoints.CustomerService.updateCustomer(), { customer });

export const getCustomerByIdRequest = (id: number) =>
  client.get(endpoints.CustomerService.getCustomerById(id));

export const deleteCustomerByIdRequest = (id: number) =>
  client.delete(endpoints.CustomerService.deleteCustomerById(id));

export const getContactsByCustomerIdRequest = (id: number) =>
  client.post(endpoints.CustomerService.getContactsByCustomerId(id));

export const updateCustomerContactRequest = (contact: TUpdateCustomerContact) =>
  client.put(endpoints.CustomerService.updateCustomerContact(), { contact: contact });

export const createCustomerContactRequest = (body: TCreateContactData) => {
  return client.post(endpoints.CustomerService.createCustomerContact(), { new_contact: body });
};

export const getCustomerContactByIdRequest = (id: number) => {
  return client.get(endpoints.CustomerService.getContactsByCustomerId(id));
};

export const deleteCustomerContactByIdRequest = (id: number) => {
  return client.delete(endpoints.CustomerService.deleteCustomerContact(id));
};

export const updateBillingInfoRequest = (options: TUpdateBillingInfoData) => {
  return client.put(endpoints.CustomerService.updateBillingInfo(), { billing_info: options });
};

export const createBillingInfoRequest = (options: TCreateNewBillingInfoData) => {
  return client.post(endpoints.CustomerService.createBillingInfo(), { new_billing: options });
};

export const getBillingInfoByIdRequest = (id: number) => {
  return client.get(endpoints.CustomerService.getBillingInfoById(id));
};

export const removeBillingInfoByIdRequest = (id: number) => {
  return client.delete(endpoints.CustomerService.removeBillingInfoById(id));
};
