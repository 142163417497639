import { useEffect, type FC, useState, BaseSyntheticEvent, ChangeEvent, useCallback } from 'react';
import { GridColDef } from '@mui/x-data-grid';
import { Navigate, useNavigate } from 'react-router-dom';
import { Box, IconButton, Menu, MenuItem } from '@mui/material';
import classNames from 'classnames';

import { useAppDispatch, useAppSelector, useRefreshClearState } from 'hooks';
import {
  allRequisitionsList,
  allRequisitionsLimitSelector,
  allRequisitionsOffsetSelector,
  allRequisitionsAscSelector,
  allRequisitionsLoadingSelector,
} from 'store/slices/requisitionSlices/selectors';
import { convertToMMDDYYYY } from 'utils/formatDate';
import {
  StatusInfo,
  DeletePopup,
  ScrollLayout,
  CustomDataGrid,
  CreateRequisitionDrawer,
  DynamicPaginationControl,
} from 'components';
import { sxMenuStyles } from 'containers/Catalog/styles';
import { setLimit, setPage } from 'store/slices/requisitionSlices';
import { MoreIcon, CloneIcon, TrashIcon } from 'assets/icons';
import {
  userAllRolesLoadingSelector,
  userAllRolesSelector,
  userPermissionsSelector,
} from 'store/slices/authSlice/selectors';
import { generateInventoryItemNumber } from 'utils/generateInventoryItemNumber';
import {
  clonePurchaseRequisition,
  deletePurchaseRequisition,
  getRequisitions,
} from 'store/slices/requisitionSlices/thunks';
import { StatusEnums } from 'components/shared/StatusInfo/types';
import { SortByEnums, type TRequisition } from 'store/slices/requisitionSlices/types';
import { EmptyTitles } from 'constants/EmptyTitles';
import { Colors, Routes } from 'types';

import { requisitionColumns } from './utils';
import PurchaseRequisitionHeader from './PurchaseRequisitionHeader';
import styles from './PurchaseRequisition.module.scss';

const PurchaseRequisition: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { isAccessToInventoryEditor, isAccessToInventoryViewer } =
    useAppSelector(userPermissionsSelector);

  const userRolesLoading = useAppSelector(userAllRolesLoadingSelector);

  const haveAccessToInventories = isAccessToInventoryEditor || isAccessToInventoryViewer;

  useRefreshClearState();

  const userRoles = useAppSelector(userAllRolesSelector);
  const requisitions = useAppSelector(allRequisitionsList);
  const requisitionsAsc = useAppSelector(allRequisitionsAscSelector);
  const requisitionsLimit = useAppSelector(allRequisitionsLimitSelector);
  const requisitionsOffset = useAppSelector(allRequisitionsOffsetSelector);
  const requisitionsLoading = useAppSelector(allRequisitionsLoadingSelector);

  const filteredRoles = userRoles.map((role) => role.role_id);

  const isViewer =
    !filteredRoles.includes(16) && !filteredRoles.includes(5) && !filteredRoles.includes(3);

  const renderedRequisitions = requisitions;

  const [anchorEl, setAnchorEl] = useState(null);
  const [currentRequisition, setCurrentRequisition] = useState<number>();
  const [currentItemId, setCurrentItemId] = useState<number | string | null>(null);
  const [inEditMode, setInEditMode] = useState<boolean>(false);
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const [newSortBy, setNewSortBy] = useState<SortByEnums | null>(null);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
    setAnchorEl(null);
  };

  const closeDrawer = () => {
    inEditMode && setInEditMode(false);
    setCurrentItemId(null);
  };

  const handleMoreClick = (event: BaseSyntheticEvent, id?: number) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setCurrentRequisition(id);
    id && setCurrentItemId(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setCurrentItemId(null);
  };

  const onCloneRequisition = () => {
    if (currentRequisition) {
      dispatch(clonePurchaseRequisition(currentRequisition));
      handleClose();
    }
  };

  const deleteRequisition = () => {
    if (currentRequisition) {
      dispatch(deletePurchaseRequisition(currentRequisition));
      handleClose();
      togglePopup();
    }
  };

  useEffect(() => {
    dispatch(getRequisitions({ limit: requisitionsLimit, offset: requisitionsOffset }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requisitionsLimit, requisitionsOffset]);

  const renderColumns: GridColDef[] = requisitionColumns.map((column) => {
    const isColumnSorted =
      column.field === 'number' ? newSortBy === SortByEnums.ID : newSortBy === column.field;

    const headerClasses = classNames(
      { [styles.activeSortHeader]: !requisitionsAsc },
      {
        [styles.activeSortHeader_asc]: requisitionsAsc,
      },
    );

    return {
      ...column,
      sortable: false,
      hideSortIcons: true,
      disableColumnMenu: true,
      disableColumnSelector: true,
      headerClassName: isColumnSorted ? headerClasses : '',
    };
  });

  const handleColumnHeaderClick = (column: GridColDef) => {
    const sortValue = column.field === 'number' ? SortByEnums.ID : (column.field as SortByEnums);
    setNewSortBy(sortValue);

    dispatch(
      getRequisitions({
        sort_by: sortValue,
        asc: requisitionsAsc,
        limit: requisitionsLimit,
        offset: requisitionsOffset,
      }),
    );
  };
  const handleClick = (id: number | string) => {
    setCurrentItemId(id);
    setAnchorEl(null);
    navigate(`/product-info/inventory/requisition/${id}`);
  };

  const renderRows = renderedRequisitions?.data?.map((el: TRequisition) => {
    const renderNumber = generateInventoryItemNumber(Number(el?.id));

    return {
      id: el.id,
      uuid: el.uuid,
      number: <a className={styles.container__link}>{`REQ-${renderNumber}`}</a>,
      no_of_items: el.no_of_items || '--',
      quantity: el?.quantity,
      created_by: el?.created_by_name,
      created_at: convertToMMDDYYYY(String(el?.created_at)),
      status: (
        <StatusInfo
          color={Colors.MIDNIGHT_BLACKISH}
          title={el?.status as string}
          status={el?.status as StatusEnums}
        />
      ),
      options: !isViewer && (
        <IconButton
          onClick={(event) => handleMoreClick(event, el?.id)}
          className={styles.container__icon}
        >
          <MoreIcon />
        </IconButton>
      ),
    };
  });

  const renderMenuClasses = sxMenuStyles;
  const totalRows = renderedRequisitions?.total_count;
  const [currentPage, setCurrentPage] = useState<number>(0);

  const handlePageChange = useCallback((_event: unknown, newPage: number) => {
    setCurrentPage(newPage);
    dispatch(setPage(newPage + 1));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRowsPerPageChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCurrentPage(0);
    dispatch(setLimit(event.target.value));
    handlePageChange(null, 0);
  };

  if (!haveAccessToInventories && !userRolesLoading) {
    return <Navigate replace to={Routes.ActivityFeed} />;
  }

  return (
    <Box className={styles.container}>
      <PurchaseRequisitionHeader renderedRequisitions={renderedRequisitions} />
      <Box className={styles.container__selection}></Box>

      <>
        <ScrollLayout>
          <CustomDataGrid
            rowHeight={44}
            headerHeight={44}
            rows={renderRows}
            rowSelection={true}
            columns={renderColumns}
            isLoading={requisitionsLoading}
            emptyTitle={EmptyTitles.Purchase_Requisitions}
            onRowClick={(params) => handleClick(params?.row?.uuid)}
            onColumnHeaderClick={handleColumnHeaderClick}
          />
        </ScrollLayout>
        {!!renderedRequisitions?.data?.length && (
          <DynamicPaginationControl
            count={totalRows}
            page={currentPage}
            onPageChange={handlePageChange}
            rowsPerPage={requisitionsLimit}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        )}

        <Menu
          sx={renderMenuClasses}
          anchorEl={anchorEl}
          onClose={handleClose}
          open={Boolean(anchorEl)}
        >
          <Box>
            <MenuItem onClick={onCloneRequisition}>
              <CloneIcon />
              Clone
            </MenuItem>

            <MenuItem onClick={togglePopup}>
              <TrashIcon />
              Delete
            </MenuItem>
          </Box>
        </Menu>
      </>

      <CreateRequisitionDrawer
        inEditMode
        open={inEditMode}
        handleClose={closeDrawer}
        setRequisitionId={setCurrentItemId}
        requisitionId={currentItemId as number}
      />

      <DeletePopup
        withBody
        isOpen={isPopupOpen}
        onClose={togglePopup}
        title='Delete Confirmation'
        onDelete={deleteRequisition}
        body='This will delete the requisition. Please click on Confirm or Cancel".'
      />
    </Box>
  );
};

export default PurchaseRequisition;
