import { TSettingItemProps } from 'components/views/SettingsItem/types';

export enum NotificationSettings {
  EMAIL = 'Email Notification',
  PHONE = 'Sms Notification',
  // NOTIFICATIONS = 'In-App Notification',
}

const mockDataSettings: TSettingItemProps[] = [
  {
    id: 1,
    title: NotificationSettings.EMAIL,
    subSettings: ['Receive Email Notification When Alert Gets Triggered', 'Mail Will Be Sent To '],
  },
  {
    id: 2,
    title: NotificationSettings.PHONE,
    subSettings: ['Receive SMS Notification When Alert Gets Triggered', 'SMS Will Be Sent To '],
  },
  // {
  //   id: 3,
  //   title: NotificationSettings.NOTIFICATIONS,
  //   subSettings: ['Get All The Alert Notification In The Notification Page'],
  // },
];

export default mockDataSettings;
