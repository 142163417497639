import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

import {
  TCreateNotice,
  TUpdateNotice,
  TPostLikeReturn,
  TUpdateActivity,
  TLikePostComment,
  TUpdatePostComment,
  TPostCommentCreate,
  TCreateActivityFlag,
  TPostCommentLikeReturn,
  TUsersActivitiesRequest,
} from 'store/slices/activitiesSlices/types';
import { activitiesApi } from 'api';
import {
  NoticeToastMessages,
  ActivityFeedToastMessages,
  ReportSendingToastMessages,
} from 'constants/ToastMessages';
import { RootState } from 'types';
import dataStructure from 'constants/options';
import { ThunkAPI } from 'types/global/thunkApi';
import { capitalizeFirstLetter } from 'utils';
import { BrowserStorageKeys, BrowserStorageService } from 'services';

import { TParams } from './types';

import { changeNoticePage } from '.';

export const createActivity = createAsyncThunk(
  'activitiesSlice/createActivity',
  async (options: FormData, { dispatch }) => {
    const id = toast.loading(ActivityFeedToastMessages.POST_CREATION);

    try {
      const response = await activitiesApi.createActivitiesRequest(options);
      dispatch(getAllActivities({ limit: 100, offset: 0 }));

      toast.update(id, {
        render: ActivityFeedToastMessages.POST_CREATION_SUCCESS,
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });
      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      toast.update(id, {
        render: ActivityFeedToastMessages.POST_CREATION_FAILURE,
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });

      throw Error;
    }
  },
);

export const updateActivity = createAsyncThunk(
  'activitiesSlice/updateActivity',
  async (options: TUpdateActivity, { dispatch, getState }) => {
    const {
      auth: { userData },
    } = getState() as RootState;

    const id = toast.loading(ActivityFeedToastMessages.POST_EDIT_START);

    try {
      const response = await activitiesApi.updateActivity(options);
      await dispatch(getAllActivities({ limit: 100, offset: 0 }));
      dispatch(getUsersActivities({ user_id: userData?.id as number, limit: 100, offset: 0 }));

      toast.update(id, {
        render: ActivityFeedToastMessages.POST_EDIT_SUCCESS,
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });
      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      toast.update(id, {
        render: ActivityFeedToastMessages.POST_EDIT_FAILURE,
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });

      throw Error;
    }
  },
);

export const deleteActivity = createAsyncThunk(
  'activitiesSlice/deleteActivity',
  async (activity_feed_id: number, { dispatch, getState }) => {
    const {
      auth: { userData },
    } = getState() as RootState;

    const id = toast.loading(ActivityFeedToastMessages.POST_REMOVAL_START);

    try {
      const response = await activitiesApi.deleteActivity(activity_feed_id);
      dispatch(getAllActivities({ limit: 100, offset: 0 }));
      dispatch(getUsersActivities({ user_id: userData?.id as number, limit: 100, offset: 0 }));

      toast.update(id, {
        render: ActivityFeedToastMessages.POST_REMOVAL_SUCCESS,
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      toast.update(id, {
        render: ActivityFeedToastMessages.POST_REMOVAL_FAILURE,
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });

      throw Error;
    }
  },
);

export const getAllActivities = createAsyncThunk(
  'activitiesSlice/getAllActivities',
  async (params: TParams) => {
    const sortedTeamIds = BrowserStorageService.get(BrowserStorageKeys.sortingTeamIds, {
      session: true,
    });

    const sortedTeamSource = BrowserStorageService.get(BrowserStorageKeys.sortingSource, {
      session: true,
    });

    try {
      const sendedParams = sortedTeamSource
        ? { ...params, source: 'team', team_ids: sortedTeamIds, notification: false }
        : params;

      const response = await activitiesApi.getAllActivitiesRequest(sendedParams as any);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      throw Error;
    }
  },
);

export const getFilterActivities = createAsyncThunk(
  'activitiesSlice/getFilterActivities',
  async (id: number) => {
    try {
      const response = await activitiesApi.getFilterActivitiesRequest(id);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      throw Error;
    }
  },
);

export const getUsersActivities = createAsyncThunk(
  'activitiesSlice/getUsersActivities',
  async (options: TUsersActivitiesRequest, { getState }) => {
    const {
      activities: { usersActivitiesLimit, usersActivitiesOffset },
    } = getState() as RootState;

    const {
      limit = usersActivitiesLimit,
      offset = usersActivitiesOffset,
      ...restOptions
    } = options;

    try {
      const response = await activitiesApi.getActivitiesByUserIdRequest({
        limit,
        offset,
        ...restOptions,
      });

      if (response?.data?.length === 0) {
        return dataStructure;
      }

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      throw Error;
    }
  },
);

export const likeActivityPost = createAsyncThunk<TPostLikeReturn, number | TLikePostComment>(
  'activitiesSlice/likeActivityPost',
  async (id: number | TLikePostComment, { dispatch }) => {
    try {
      const response = await activitiesApi.likeActivityPostRequest(id as number);

      dispatch(getAllActivities({ limit: 100, offset: 0 }));

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      throw Error;
    }
  },
);

export const removeActivityPostLike = createAsyncThunk<TPostLikeReturn, number, ThunkAPI>(
  'activitiesSlice/removeActivityPostLike',
  async (id: number, { dispatch }) => {
    try {
      const response = await activitiesApi.removeActivityLikeRequest(id);
      dispatch(getAllActivities({ limit: 100, offset: 0 }));

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      throw Error;
    }
  },
);

export const postCommentCreate = createAsyncThunk(
  'activitiesSlice/postCommentCreate',
  async (data: TPostCommentCreate) => {
    try {
      const response = await activitiesApi.createPostCommentRequest(data);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      throw Error;
    }
  },
);

export const likePostComment = createAsyncThunk<
  TPostCommentLikeReturn,
  TLikePostComment | number,
  ThunkAPI
>('activitiesSlice/likePostComment', async (options: TLikePostComment | number) => {
  try {
    const response = await activitiesApi.likeActivityPostCommentRequest(
      options as TLikePostComment,
    );

    return response.data;
  } catch (error) {
    const Error = error as AxiosError;

    throw Error;
  }
});

export const unlikePostComment = createAsyncThunk<TPostCommentLikeReturn, number, ThunkAPI>(
  'activitiesSlice/unlikePostComment',
  async (id: number) => {
    try {
      const response = await activitiesApi.removeActivityCommentLikeRequest(id);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      throw Error;
    }
  },
);

export const removeActivityPostComment = createAsyncThunk(
  'activitiesSlice/removeActivityPostComment',
  async (id: number) => {
    try {
      const response = await activitiesApi.removeActivityCommentRequest(id);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      throw Error;
    }
  },
);

export const getAllNotices = createAsyncThunk(
  'activitiesSlice/getAllNotices',
  async (params: TParams) => {
    try {
      const response = await activitiesApi.getAllNotices(params);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      throw Error;
    }
  },
);

export const creteNotice = createAsyncThunk(
  'activitiesSlice/creteNotice',
  async (options: TCreateNotice, { dispatch }) => {
    const id = toast.loading(NoticeToastMessages.NOTICE_CREATION);
    try {
      const response = await activitiesApi.createNotice(options);

      toast.update(id, {
        render: NoticeToastMessages.NOTICE_CREATION_SUCCESS,
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });
      dispatch(getAllNotices({ offset: 0, limit: 9 }));
      dispatch(changeNoticePage(0));
      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      toast.update(id, {
        render: NoticeToastMessages.NOTICE_CREATION_FAILURE,
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });

      throw Error;
    }
  },
);

export const removeNotice = createAsyncThunk(
  'activitiesSlice/removeNotice',
  async (notice_id: number, { dispatch }) => {
    const id = toast.loading(NoticeToastMessages.NOTICE_REMOVAL_START);

    try {
      const response = await activitiesApi.removeNoticeById(notice_id);
      dispatch(getAllNotices({ limit: 9, offset: 0 }));
      dispatch(changeNoticePage(0));

      toast.update(id, {
        render: NoticeToastMessages.NOTICE_REMOVAL_SUCCESS,
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      toast.update(id, {
        render: NoticeToastMessages.NOTICE_REMOVAL_FAILURE,
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });

      throw Error;
    }
  },
);

export const updateNotice = createAsyncThunk(
  'activitiesSlice/updateNotice',
  async (options: TUpdateNotice, { dispatch }) => {
    const id = toast.loading(NoticeToastMessages.NOTICE_EDIT_START);

    try {
      const response = await activitiesApi.updateNotice(options);
      dispatch(getAllNotices({ limit: 9, offset: 0 }));
      dispatch(changeNoticePage(0));

      toast.update(id, {
        render: NoticeToastMessages.NOTICE_EDIT_SUCCESS,
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      toast.update(id, {
        render: NoticeToastMessages.NOTICE_EDIT_FAILURE,
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });

      throw Error;
    }
  },
);

export const updatePostComment = createAsyncThunk(
  'activitiesSlice/updatePostComment',
  async (options: TUpdatePostComment) => {
    try {
      const response = await activitiesApi.updatePostCommentRequest(options);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      throw Error;
    }
  },
);

export const createActivityFlag = createAsyncThunk(
  'activitiesSlice/createActivityFlag',
  async (options: TCreateActivityFlag) => {
    const start = toast.loading(ReportSendingToastMessages.REPORT_SENDING_START);
    try {
      const response = await activitiesApi.createActivityFlagRequest(options);

      toast.update(start, {
        render: ReportSendingToastMessages.REPORT_SENDING_SUCCESS,
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      const haveErrorMessage =
        !!Error.response &&
        Error.response?.status === 409 &&
        typeof Error?.response?.data === 'string' &&
        !!Error?.response?.data?.length;

      const message = haveErrorMessage
        ? capitalizeFirstLetter(Error?.response?.data as string)
        : ReportSendingToastMessages.REPORT_SENDING_FAILURE;

      toast.update(start, {
        render: message,
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });

      throw Error;
    }
  },
);

export const hideActivityPost = createAsyncThunk(
  'activitiesSlice/hideActivityPost',
  async (id: number, { dispatch }) => {
    try {
      const response = await activitiesApi.hideActivityFeedRequest(id);
      dispatch(getAllHidedPosts());
      dispatch(getAllActivities({ limit: 100, offset: 0 }));

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      throw Error;
    }
  },
);

export const unHideActivityPost = createAsyncThunk(
  'activitiesSlice/unHideActivityPost',
  async (id: number, { dispatch }) => {
    try {
      const response = await activitiesApi.unhideActivityFeedRequest(id);
      dispatch(getAllHidedPosts());
      dispatch(getAllActivities({ limit: 100, offset: 0 }));

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      throw Error;
    }
  },
);

export const getAllHidedPosts = createAsyncThunk('activitiesSlice/getAllHidedPosts', async () => {
  try {
    const response = await activitiesApi.getAllHiddenActivityFeedRequest();

    return response.data;
  } catch (error) {
    const Error = error as AxiosError;

    throw Error;
  }
});

export const getAllPostLikedUsers = createAsyncThunk(
  'activitiesSlice/getAllPostLikedUsers',
  async (id: number) => {
    try {
      const response = await activitiesApi.getActivityFeedPostLikesRequest(id);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      throw Error;
    }
  },
);

export const getGenAiResponse = createAsyncThunk(
  'activitiesSlice/genAiResponse',
  async (query: string) => {
    try {
      const response = await activitiesApi.genAiQuery(query);
      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      throw Error;
    }
  },
);
