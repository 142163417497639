import { useEffect, useState, useCallback } from 'react';
import classNames from 'classnames';
import { Box } from '@mui/material';

import { getAllActivities } from 'store/thunks';
import { activitiesSliceSelector } from 'store/slices/activitiesSlices/selector';
import { useActivityList, useAppDispatch, useAppSelector, useWindowSize } from 'hooks';

import { breakPoints, LIMIT, OFFSET } from '../../constants';

import PostsAndNotices from './PostsAndNotices';
import styles from './ActivityFeed.module.scss';
import TabsSection from './TabsSection/TabsSection';

const ActivityFeed = () => {
  const dispatch = useAppDispatch();

  const { activities: activityData } = useAppSelector(activitiesSliceSelector);

  const { getNewPostsList, setCurrentList, currentList, currentListLoad } = useActivityList();

  const [offset, setOffset] = useState<number>(OFFSET + LIMIT);

  const { width } = useWindowSize();

  const showTablets = Number(width) <= breakPoints.TABLET_L;

  const containerClasses = classNames(styles.container, { [styles.container_tab]: showTablets });

  const changePage = useCallback(async () => {
    await getNewPostsList(LIMIT, offset).then(() => setOffset((prev) => prev + LIMIT));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset]);

  useEffect(() => {
    dispatch(getAllActivities({ offset: 0, limit: LIMIT }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCurrentList(activityData?.data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activityData]);

  return (
    <Box className={containerClasses}>
      {showTablets ? (
        <TabsSection
          changePage={changePage}
          currentList={currentList}
          loadingState={currentListLoad}
          currentListLoad={currentListLoad}
        />
      ) : (
        <PostsAndNotices
          changePage={changePage}
          currentList={currentList}
          loadingState={currentListLoad}
          currentListLoad={currentListLoad}
        />
      )}
    </Box>
  );
};

export default ActivityFeed;
