import { FC, useState } from 'react';
import { Stack } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { AddIcon } from 'assets/icons';
import { inviteUserSchema } from 'constants/Schemas';
import { Button, DrawerLayout, InviteUsers } from 'components';
import { BrowserStorageKeys, BrowserStorageService } from 'services';
import { allRolesSelector } from 'store/slices/rolesSlice/selectors';
import { useAppDispatch, useAppSelector, useWindowSize } from 'hooks';
import { TCreateInvitation } from 'store/slices/invitationSlice/types';
import { createInvitation } from 'store/slices/invitationSlice/thunks';
import {
  getInvitationByOrgId,
  getOrganizationMembers,
} from 'store/slices/organizationsSlice/thunks';
import { Colors, type TFormSubmit } from 'types';

import { breakPoints } from '../../../../constants';

import styles from './InviteUsersDrawer.module.scss';

import type { TInvitationsForm, TInviteUsersDrawerProps, TInvitedUser } from './types';

const InviteUsersDrawer: FC<TInviteUsersDrawerProps> = ({ open, handleClose }) => {
  const dispatch = useAppDispatch();

  const allRoles = useAppSelector(allRolesSelector);
  const currentOrganization = BrowserStorageService.get(BrowserStorageKeys.CurrentOrganizationId);

  const [isInvitingYourself, setIsInvitingYourself] = useState<boolean>(false);

  const { width } = useWindowSize();

  const drawerWidth =
    Number(width) <= breakPoints.TABLET_L && Number(width) > breakPoints?.TABLET_M
      ? 576
      : Number(width) <= breakPoints.TABLET_M && Number(width) >= breakPoints.MOBILE
      ? 458
      : Number(width) <= breakPoints.MOBILE
      ? 375
      : 975;

  const foundedRole = (name: string) =>
    allRoles.find((role) => {
      if (role.value === name) {
        return role;
      }
    });

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    control: inviteUsersForm,
    watch,
    reset,
  } = useForm<TInvitationsForm>({
    resolver: yupResolver(inviteUserSchema),
  });

  const state = watch();

  const isValid = state?.invitedUsers?.some(
    (item) =>
      !item?.email?.trim()?.length || !item?.fullName?.trim()?.length || !item?.role?.length,
  );

  const handleSubmitForm: TFormSubmit = async (data) => {
    const sendedData: TCreateInvitation[] = data.invitedUsers.map((item: TInvitedUser) => ({
      // team_id: null,
      email: item.email,
      full_name: item.fullName,
      org_id: Number(currentOrganization) as number,
      role_id: foundedRole(item?.role)?.id,
    }));

    const response = await dispatch(createInvitation(sendedData));

    dispatch(getOrganizationMembers({ org_id: Number(currentOrganization) }));
    dispatch(getInvitationByOrgId({ org_id: Number(currentOrganization) }));

    if (response?.meta?.requestStatus === 'fulfilled') {
      handleCloseClear();
      reset();
    }
  };

  const handleCloseClear = () => {
    reset({
      invitedUsers: [],
    });
    handleClose();
  };

  const isDisabled = isValid || isInvitingYourself;

  return (
    <DrawerLayout
      open={open}
      padding='40px'
      inCenter={false}
      width={drawerWidth}
      onClose={handleClose}
      headerTitle='Invite User'
      onCloseReset={handleCloseClear}
    >
      <form onSubmit={handleSubmit(handleSubmitForm)} className={styles.container}>
        <InviteUsers
          withOutTitle
          errors={errors}
          control={inviteUsersForm}
          setIsInvitingYourself={setIsInvitingYourself}
        />
        <Stack alignItems='flex-end' width='100%' className={styles.container__submit}>
          <Button
            type='submit'
            maxWidth='160px'
            minWidth='131px'
            padding='12px 16px'
            variant='contained'
            disableColor={Colors.WHITE}
            disabled={isDisabled || isSubmitting}
            className={styles.container__submit__item}
          >
            <AddIcon width={18} height={18} className={styles.container__submit__item__icon} />
            Send Invite
          </Button>
        </Stack>
      </form>
    </DrawerLayout>
  );
};

export default InviteUsersDrawer;
