import { Box } from '@mui/material';
import { FC } from 'react';

import { CustomTypography } from 'components';

import styles from './SectionHead.module.scss';
import { TSectionHeadProps } from './types';

const SectionHead: FC<TSectionHeadProps> = ({ title, subtitle, withUnderlinedTitle = false }) => (
  <Box className={styles.container}>
    <CustomTypography
      className={styles.container__title}
      style={{
        textDecoration: withUnderlinedTitle ? 'underline' : 'unset',
        textUnderlineOffset: '5px',
      }}
    >
      {title}
    </CustomTypography>
    <CustomTypography className={styles.container__subtitle}>{subtitle}</CustomTypography>
  </Box>
);

export default SectionHead;
