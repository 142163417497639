import * as yup from 'yup';

const numberRegex = /^\d+$/;

export const schema = yup.object().shape({
  description: yup
    .string()
    .test('has-length', 'Description must have a required length', function (value) {
      const { path, createError } = this;

      if (value === undefined || (value && value.trim().length > 0) || value === '') {
        return true;
      } else {
        return createError({
          path,
          message: 'Description must have a required length',
        });
      }
    })
    .nullable(),

  items: yup.array().of(
    yup.object().shape({
      price: yup
        .string()
        .required('Price is required')
        .matches(numberRegex, 'Price must be a number'),
      id: yup.number().notRequired(),
      description: yup.string().trim().notRequired(),
      itemName: yup.string().trim().required('Name is required'),
      quantity: yup
        .string()
        .required('Quantity is required')
        .matches(numberRegex, 'Quantity must be a number'),
    }),
  ),
});
