import { GridColDef } from '@mui/x-data-grid';

import { CustomTypography, TableCell } from 'components';
import HeaderTitle from 'components/tables/EmployeeTable/HeaderTitle';
import { Colors, Routes } from 'types';

export const columns: GridColDef[] = [
  {
    flex: 0.8,
    field: 'number',
    headerName: 'WO No',
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle style={{ paddingLeft: '0' }} title={headerName} />;
    },

    renderCell: (params) => {
      return (
        <TableCell
          title={
            <CustomTypography
              fontFamily='CircularStdRegular'
              fontSize='14px'
              color={Colors.SAPPHIRE}
              outlined
            >
              {params?.value}
            </CustomTypography>
          }
        />
      );
    },
  },
  {
    flex: 0.8,
    field: 'customer_name',
    headerName: 'Customer',
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle style={{ paddingLeft: '0' }} title={headerName} />;
    },

    renderCell: (params) => {
      return (
        <TableCell
          title={
            <CustomTypography
              fontFamily='CircularStdRegular'
              fontSize='14px'
              color={Colors.SAPPHIRE}
              outlined
            >
              {params?.value}
            </CustomTypography>
          }
        />
      );
    },
  },
  {
    flex: 1,
    field: 'estimate_name',
    headerName: 'Estimate Name',
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle style={{ paddingLeft: '0' }} title={headerName} />;
    },

    renderCell: (params) => {
      return (
        <TableCell
          title={
            <CustomTypography
              fontFamily='CircularStdRegular'
              fontSize='14px'
              color={Colors.SAPPHIRE}
              outlined
            >
              {params?.value}
            </CustomTypography>
          }
        />
      );
    },
  },
  {
    flex: 1,
    field: 'supervisor_name',
    headerName: 'Supervisor Name',
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle style={{ paddingLeft: '0' }} title={headerName} />;
    },
    renderCell: (params) => {
      return <TableCell title={params?.value} />;
    },
  },
  {
    field: 'start_date',
    headerName: 'Start Date',
    flex: 0.8,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle style={{ paddingLeft: '0' }} title={headerName} />;
    },
    renderCell: (params) => {
      return <TableCell title={params?.value} />;
    },
  },
  {
    field: 'end_date',
    headerName: 'End Date',
    flex: 0.8,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle style={{ paddingLeft: '0' }} title={headerName} />;
    },
    renderCell: (params) => {
      return <TableCell title={params?.value} />;
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 1,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle style={{ paddingLeft: '0' }} title={headerName} />;
    },
    renderCell: (params) => {
      return <TableCell title={params?.value} />;
    },
  },

  {
    field: 'options',
    headerName: '',
    width: 80,
    renderCell: (params) => {
      return params.value;
    },
  },
];

export const breadCrumbsOptions = [
  { id: 1, label: 'Workflow', goTo: Routes.WorkOrder },
  { id: 2, label: 'Work Orders', goTo: Routes.WorkOrder },
];
