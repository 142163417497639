import { FC } from 'react';

import { EditIconOutLined, MoreIcon, TrashIcon } from 'assets/icons';
import { PortalDropDown } from 'components';

import type { TOptionsDropDown } from './types';

const OptionsDropDown: FC<TOptionsDropDown> = ({ handleEdit, handleRemove }) => {
  const options = [
    { id: 1, action: handleEdit, icon: <EditIconOutLined />, name: 'Edit' },
    { id: 2, action: handleRemove, icon: <TrashIcon />, name: 'Delete' },
  ];

  return <PortalDropDown title={<MoreIcon />} options={options} />;
};

export default OptionsDropDown;
