import { FC } from 'react';
import classNames from 'classnames';

import { TButtonLoaderProps } from './types';

import './ButtonLoader.scss';

const ButtonLoader: FC<TButtonLoaderProps> = ({ color = 'blue' }) => {
  const loaderColors = classNames('lds-dual-ring', { ['lds-dual-ring_red']: color === 'red' });

  return <div className={loaderColors}></div>;
};

export default ButtonLoader;
