import type { RootState } from 'types';

export const allTransactionsList = (state: RootState) => state.transactions.allTransactions;

export const allTransactionLoadingSelector = (state: RootState) =>
  state.transactions.allTransactionsLoading;

export const allTransactionsErrorSelector = (state: RootState) =>
  state.transactions.allTransactionsError;

export const allTransactionsLimitSelector = (state: RootState) =>
  state.transactions.allTransactionsLimit;

export const allTransactionsAscSelector = (state: RootState) =>
  state.transactions.allTransactionsAsc;

export const allTransactionsOffsetSelector = (state: RootState) =>
  state.transactions.allTransactionsOffset;
