import { useEffect, useState } from 'react';
import moment from 'moment';
import { Modal } from '@mui/material';
import ReactQuill, { Quill } from 'react-quill';
import { animated, useSpring } from 'react-spring';
import { isEqual } from 'lodash';

import { useAppDispatch, useHtmlLength } from 'hooks';
import { Colors, FontNames } from 'types';
import { TChangeInputEvent } from 'types/global/events';
import { setModalState } from 'store/slices/optionsSlice';
import { BorderSquareIcon, ToolTipArrowIcon } from 'assets/icons';
import { TCreateNotice } from 'store/slices/activitiesSlices/types';
import { creteNotice, updateNotice } from 'store/slices/activitiesSlices/thunks';

import { Button, CustomTextField } from '..';
import 'react-quill/dist/quill.snow.css';

import './styles.scss';
import { TNoticeModal } from './types';
import styles from './CreateNoticeModal.module.scss';
import { parseHTML } from './utils';

const modules = {
  toolbar: [
    ['bold', 'italic', 'underline'],
    ['link'],
    [
      {
        color: ['#000000'],
      },
    ],
  ],
};

const formats = ['bold', 'italic', 'underline', 'link'];

const icons = ReactQuill.Quill.import('ui/icons');
icons[
  'link'
] = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M21.9378 5.07001C21.5197 4.65049 21.023 4.31762 20.476 4.0905C19.929 3.86337 19.3426 3.74646 18.7503 3.74646C18.158 3.74646 17.5716 3.86337 17.0246 4.0905C16.4776 4.31762 15.9809 4.65049 15.5628 5.07001L16.6278 6.13501C16.907 5.85579 17.2385 5.63429 17.6033 5.48318C17.9682 5.33206 18.3592 5.25429 18.7541 5.25429C19.1489 5.25429 19.54 5.33206 19.9048 5.48318C20.2696 5.63429 20.6011 5.85579 20.8803 6.13501C21.1595 6.41423 21.381 6.74572 21.5321 7.11054C21.6833 7.47536 21.761 7.86638 21.761 8.26126C21.761 8.65614 21.6833 9.04715 21.5321 9.41198C21.381 9.7768 21.1595 10.1083 20.8803 10.3875L14.8803 16.3875C14.3174 16.9514 13.5535 17.2686 12.7567 17.2693C11.9599 17.27 11.1955 16.9542 10.6316 16.3913C10.0676 15.8283 9.75044 15.0645 9.74974 14.2677C9.74903 13.4709 10.0649 12.7064 10.6278 12.1425L11.6853 11.0775L10.6278 10.0125L9.56281 11.0775C9.14329 11.4956 8.81042 11.9923 8.5833 12.5393C8.35617 13.0863 8.23926 13.6727 8.23926 14.265C8.23926 14.8573 8.35617 15.4437 8.5833 15.9907C8.81042 16.5377 9.14329 17.0344 9.56281 17.4525C10.4114 18.2903 11.5579 18.7569 12.7503 18.75C13.3448 18.7525 13.934 18.6371 14.4837 18.4105C15.0333 18.1839 15.5327 17.8507 15.9528 17.43L21.9528 11.43C22.7935 10.5843 23.2642 9.43941 23.2614 8.2469C23.2585 7.0544 22.7825 5.91177 21.9378 5.07001Z" fill="black"/>
<path d="M3.14235 18.615C2.8623 18.3362 2.64007 18.0049 2.48843 17.64C2.3368 17.2751 2.25873 16.8839 2.25873 16.4887C2.25873 16.0936 2.3368 15.7024 2.48843 15.3375C2.64007 14.9726 2.8623 14.6412 3.14235 14.3625L9.14235 8.36249C9.42111 8.08243 9.75245 7.86021 10.1173 7.70857C10.4822 7.55693 10.8735 7.47887 11.2686 7.47887C11.6637 7.47887 12.055 7.55693 12.4199 7.70857C12.7848 7.86021 13.1161 8.08243 13.3949 8.36249C13.6731 8.64344 13.8922 8.97739 14.0391 9.34455C14.1859 9.71171 14.2576 10.1046 14.2499 10.5C14.2521 10.8966 14.1758 11.2897 14.0251 11.6566C13.8745 12.0235 13.6527 12.3569 13.3724 12.6375L11.7824 14.25L12.8474 15.315L14.4374 13.725C15.2837 12.8786 15.7592 11.7307 15.7592 10.5337C15.7592 9.33679 15.2837 8.18886 14.4374 7.34249C13.591 6.49612 12.4431 6.02063 11.2461 6.02063C10.0492 6.02063 8.90123 6.49612 8.05485 7.34249L2.05486 13.3425C1.63421 13.7607 1.30039 14.2579 1.0726 14.8056C0.844807 15.3533 0.727539 15.9406 0.727539 16.5337C0.727539 17.1269 0.844807 17.7142 1.0726 18.2619C1.30039 18.8096 1.63421 19.3068 2.05486 19.725C2.90897 20.5563 4.05803 21.0149 5.24986 21C6.45218 21.0011 7.60614 20.5266 8.45986 19.68L7.39485 18.615C7.1161 18.895 6.78476 19.1173 6.41988 19.2689C6.05499 19.4205 5.66375 19.4986 5.26861 19.4986C4.87347 19.4986 4.48222 19.4205 4.11733 19.2689C3.75245 19.1173 3.42111 18.895 3.14235 18.615Z" fill="black"/>
</svg>
`;

const CreateNoticeModal: React.FC<TNoticeModal> = ({ open, value, onClose, submitBtnText }) => {
  const dispatch = useAppDispatch();

  const [inputValue, setInputValue] = useState<string>(value?.body || '');

  const [noticeTitle, setNoticeTitle] = useState<string>(value?.title || '');
  const [isLinkValueChanged, setIsLinkValueChanged] = useState<boolean>(true);

  const previousLinkValues = value?.body ? parseHTML(value?.body) : [];

  const handleEditorChange = (content: string, delta: any, source: string, editor: any) => {
    if (source === 'user') {
      const links = editor
        .getContents()
        .ops.filter((op: any) => op.attributes && op.attributes.link);

      if (value?.body || value?.title) {
        if (isEqual(previousLinkValues, links)) {
          setIsLinkValueChanged(true);
        } else {
          setIsLinkValueChanged(false);
        }
      }

      setInputValue(content);
    }
  };

  const [isStarted, setIsStarted] = useState<boolean>(false);

  const timedOnClose = () => {
    setIsStarted(true);

    setTimeout(() => {
      onClose();
      setIsStarted(false);
    }, 1000);
  };

  const springProps = useSpring({
    delay: 200,
    opacity: open ? 1 : 0,
    top: open ? '50%' : '-5%',
  });

  const onCloseProps = useSpring({
    delay: 200,
    opacity: !isStarted ? 1 : 0,
    top: !isStarted ? '50%' : '-5%',
  });

  const { content, isValid } = useHtmlLength(inputValue);
  const { content: bodyContent } = useHtmlLength(value?.body || '<p></p>');

  const isDisabledWithDesc = noticeTitle?.trim()?.length === 0 || !isValid;

  const finalResult = content?.length > 0 ? isDisabledWithDesc : !noticeTitle?.trim();

  const isDisabledEdit =
    value?.title?.trim() === noticeTitle?.trim() &&
    content?.trim() === bodyContent?.trim() &&
    isLinkValueChanged;

  const Link = Quill.import('formats/link');
  Link.PROTOCOL_WHITELIST = ['http', 'https', 'mailto', 'tel', 'radar', 'rdar', 'smb', 'sms'];

  class CustomLinkSanitizer extends Link {
    static sanitize(url: string) {
      // Run default sanitize method from Quill
      const sanitizedUrl = super.sanitize(url);

      // Not whitelisted URL based on protocol so, let's return `blank`
      if (!sanitizedUrl || sanitizedUrl === 'about:blank') return sanitizedUrl;

      // Verify if the URL already have a whitelisted protocol
      const hasWhitelistedProtocol = this.PROTOCOL_WHITELIST.some(function (protocol: any) {
        return sanitizedUrl.startsWith(protocol);
      });

      if (hasWhitelistedProtocol) return sanitizedUrl;

      // if not, then append only 'http' to not to be a relative URL
      return `http://${sanitizedUrl}`;
    }
  }

  Quill.register(CustomLinkSanitizer, true);

  const handleCloseWithClear = () => {
    setInputValue('');
    setNoticeTitle('');
    timedOnClose();
  };

  const handleDispatch = () => {
    if (noticeTitle?.trim()) {
      const notice: TCreateNotice = {
        notice: {
          title: noticeTitle,
          body: inputValue,
        },
      };

      if (value?.title?.trim()) {
        dispatch(updateNotice({ id: value.id, ...notice.notice }));
      } else {
        dispatch(creteNotice(notice));
      }
      timedOnClose();
    } else {
      setShowTitleTooltip(true);
    }

    setNoticeTitle('');
    setInputValue('');
  };

  const currentTime = moment().format('MMMM D, YYYY [at] h:mmA');

  const [showTitleTooltip, setShowTitleTooltip] = useState(false);

  const handleNoticeTitleChange = (event: TChangeInputEvent) => {
    setNoticeTitle(event.target.value);
    setShowTitleTooltip(false); // Hide the tooltip when the user starts typing
  };

  const handleTitleFieldFocus = () => {
    setShowTitleTooltip(false);
  };

  const handleTitleFieldBlur = () => {
    if (!noticeTitle.trim()) {
      setShowTitleTooltip(true);
    }
  };

  useEffect(() => {
    dispatch(setModalState(open));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Modal open={open} onClose={timedOnClose}>
      <animated.div className={styles.container} style={isStarted ? onCloseProps : springProps}>
        <div className={styles.container_header}>
          <p>Submit a Notice</p>
          <BorderSquareIcon width={24} height={24} onClick={handleCloseWithClear} />
        </div>
        <div style={{ position: 'relative' }}>
          <p className={styles.container__time}>{currentTime}</p>
          <div className={styles.container_title}>
            <CustomTextField
              value={noticeTitle}
              label='Give Your Notice A Title:*'
              onFocus={handleTitleFieldFocus}
              onBlur={handleTitleFieldBlur}
              placeholder='Give Your Notice A Title:*'
              onChange={handleNoticeTitleChange}
            />
            {showTitleTooltip && (
              <div className={styles.container_title__warning}>
                Title is required
                <ToolTipArrowIcon className={styles.container_title__warning__icon} />
              </div>
            )}
          </div>
          <div className={styles.container_content}>
            <ReactQuill
              formats={formats}
              modules={modules}
              theme='snow'
              placeholder='Type something...(2 or moore words)'
              value={inputValue}
              onChange={handleEditorChange}
            />
          </div>
          <div className={styles.container_footer}>
            <Button
              onClick={timedOnClose}
              isUppercase={false}
              borderRadius='4px'
              color={Colors.SAPPHIRE}
              backgroundColor={Colors.PASTEL_SKY}
              fontFamily={FontNames.CIRCULAR_MEDIUM}
              className={styles.container_footer_submit}
            >
              Cancel
            </Button>
            <Button
              color={Colors.WHITE}
              borderRadius='4px'
              isUppercase={false}
              onClick={handleDispatch}
              backgroundColor={Colors.SAPPHIRE}
              fontFamily={FontNames.CIRCULAR_MEDIUM}
              className={styles.container_footer_submit}
              disabled={value?.title?.trim()?.length ? isDisabledEdit : finalResult}
            >
              {submitBtnText}
            </Button>
          </div>
        </div>
      </animated.div>
    </Modal>
  );
};

export default CreateNoticeModal;
