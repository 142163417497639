import React, { Fragment, Suspense, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import localforage from 'localforage';
import { Swiper } from 'swiper/react';
import { SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, EffectFade, Autoplay } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { BookDemoModal, RevealBlock } from 'components';
import FromLeftLine from 'components/views/AnimatedLines/FromTopToBottom/FromLeftLine';
import FromRightLine from 'components/views/AnimatedLines/FromTopToBottom/FromRightLine';
import FromTopToBottom from 'components/views/AnimatedLines/FromTopToBottom/FromTopToBottom';
import { Colors } from 'types';

import styles from './Landing.module.scss';
import LandingContentElement from './LandingContentElement';
import { featuresData, landingBackgroundImages } from './constants';

const LandingSection = React.lazy(() => import('./LandingSection'));

const Landing = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const renderedContainerRef = useRef<HTMLDivElement | null>(null);

  const startBooking = () => setIsOpen(true);
  const endBooking = () => setIsOpen(false);

  const renderContent = featuresData.map((section, idx) => {
    const isOdd = idx % 2 === 1;

    const renderClasses = classNames(styles.container_features_block_wrapper, {
      [styles.container_features_block_wrapper_reversed]: isOdd,
    });

    return (
      <Fragment key={idx}>
        {idx !== 0 && (
          <div className={styles.container_features_block_wrapper__connector}>
            {isOdd ? <FromRightLine /> : <FromLeftLine />}
          </div>
        )}
        {idx !== 0 && (
          <div className={styles.container_features_block_wrapper__connector_single}>
            <FromTopToBottom />
          </div>
        )}

        <LandingSection
          learnMoreClick={startBooking}
          renderClasses={renderClasses}
          sectionData={section}
        />
      </Fragment>
    );
  });

  useEffect(() => {
    localStorage.clear();
    sessionStorage.clear();
    localforage.clear();
  }, []);

  const pagination = {
    clickable: true,
    renderBullet: function (index: number, className: string) {
      return '<span class="' + className + '">' + '</span>';
    },
    horizontalClass: styles.container__content__slider__pagination,
  };

  const images = landingBackgroundImages.map(({ ...rest }, idx) => (
    <SwiperSlide key={idx}>
      <LandingContentElement {...rest} />
    </SwiperSlide>
  ));

  return (
    <>
      <div className={styles.container}>
        <div className={styles.container__content}>
          <RevealBlock
            renderedContentRef={renderedContainerRef}
            variant={{ visible: { opacity: 1, y: 0 }, hidden: { opacity: 0, y: -100 } }}
          >
            <Swiper
              autoplay={{
                delay: 8000,
                disableOnInteraction: false,
              }}
              className={styles.container__content__slider}
              style={{
                '--swiper-pagination-color': `${Colors.BLACK}`,
                '--swiper-pagination-bullet-inactive-opacity': '1',
                '--swiper-pagination-bullet-size': '8px',
                '--swiper-pagination-bullet-horizontal-gap': '8px',
                '--swiper-pagination-bullet-inactive-color': `${Colors.LIGHT_GRAYISH_SILVER}`,
              }}
              navigation={false}
              keyboard={true}
              mousewheel={false}
              pagination={pagination}
              watchslidesvisibility='true'
              modules={[Navigation, Pagination, Scrollbar, A11y, EffectFade, Autoplay]}
            >
              {images}
            </Swiper>
          </RevealBlock>
        </div>

        <div className={styles.container_features}>
          <Suspense fallback={<div>Loading...</div>}>{renderContent}</Suspense>
        </div>
      </div>
      <BookDemoModal isOpen={isOpen} onClose={endBooking} />
    </>
  );
};
export default Landing;
