import { BaseSyntheticEvent, FC, useEffect } from 'react';
import { Box } from '@mui/material';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';

import { findCustomer } from 'store/slices/searchSlice/thunks';
import { useAppDispatch, useAppSelector, useInput } from 'hooks';
import { foundedCustomerSelector } from 'store/slices/searchSlice/selectors';
import { ControlledAutocomplete, ControlledNumberAutocomplete } from 'components';
import { Colors } from 'types';

import { TCustomerDefaultValues } from '../types';
import styles from '../InfoForms.module.scss';

import type { TState } from '../types';

const BillingInfoForm: FC<TState<TCustomerDefaultValues>> = ({
  errors,
  values,
  control,
  setValues,
  isInEditMode,
  chooseChangedReason,
}) => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  const isInGeneratePage = pathname.includes('generate');

  const allCustomers = useAppSelector(foundedCustomerSelector);

  const changeValue = (event: BaseSyntheticEvent, title: string) => {
    setValues?.({
      ...values,
      bill_to: { ...values.bill_to, [title]: event.target.value },
    });
  };

  const { value: billValue, debouncedValue: billValueDebouncedValue } = useInput();
  const { value: phoneValue, debouncedValue: phoneValueDebouncedValue } = useInput();
  const { value: addressValue, debouncedValue: addressValueDebouncedValue } = useInput();
  const { value: cityValue, debouncedValue: cityValueDebouncedValue } = useInput();
  const { value: zipValue, debouncedValue: zipValueDebouncedValue } = useInput();
  const { value: stateValue, debouncedValue: stateValueDebouncedValue } = useInput();

  const debouncedValues =
    billValueDebouncedValue ||
    phoneValueDebouncedValue ||
    addressValueDebouncedValue ||
    cityValueDebouncedValue ||
    zipValueDebouncedValue ||
    stateValueDebouncedValue;

  const getCustomersDependsOfValue = () => {
    switch (true) {
      case billValueDebouncedValue.trim().length > 0:
        dispatch(findCustomer({ query: billValueDebouncedValue }));
        break;
      case phoneValueDebouncedValue.trim().length > 0:
        dispatch(findCustomer({ query: phoneValueDebouncedValue }));
        break;
      case addressValueDebouncedValue.trim().length > 0:
        dispatch(findCustomer({ query: addressValueDebouncedValue }));
        break;
      case cityValueDebouncedValue.trim().length > 0:
        dispatch(findCustomer({ query: cityValueDebouncedValue }));
        break;
      case zipValueDebouncedValue.trim().length > 0:
        dispatch(findCustomer({ query: zipValueDebouncedValue }));
        break;
      case stateValueDebouncedValue.trim().length > 0:
        dispatch(findCustomer({ query: stateValueDebouncedValue }));
        break;
    }
  };

  useEffect(() => {
    getCustomersDependsOfValue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValues]);

  const customersOptionsComplete = allCustomers?.data?.map((customer) => ({
    full_name: customer?.name,
    email: customer?.email,
    phone_number: customer?.phone_number,
    address: customer?.address,
    city: customer?.city,
    state: customer?.state,
    zip: customer?.zip,
    customer_id: customer?.id,
    bill_to: customer?.billing?.[0]?.bill_to,
    bill_phone_number: customer?.billing?.[0]?.phone_number,
    bill_address: customer?.billing?.[0]?.address,
    bill_city: customer?.billing?.[0]?.city,
    bill_state: customer?.billing?.[0]?.state,
    bill_zip: customer?.billing?.[0]?.zip,
  }));
  return (
    <Box
      className={classNames(styles.container, {
        [styles.container__generate]: isInGeneratePage,
      })}
    >
      <ControlledAutocomplete
        label='Bill to:'
        control={control}
        name='from_customer'
        optionsName='bill_to'
        inputValue={billValue}
        placeholder='Bill to:*'
        borderColor={Colors.SOFT_SILVER}
        borderRadius='5px'
        backgroundColor={Colors.FROST_WHITE}
        errors={errors?.bill_to}
        readonly={!isInEditMode}
        options={customersOptionsComplete}
        handleInputChange={(event) => changeValue(event, 'bill_to')}
        chooseChangedReason={(reason) => chooseChangedReason?.(reason, 'bill_to')}
      />

      <ControlledNumberAutocomplete
        control={control}
        name='from_customer'
        label='Phone number:'
        inputValue={phoneValue}
        readonly={!isInEditMode}
        borderColor={Colors.SOFT_SILVER}
        borderRadius='5px'
        backgroundColor={Colors.FROST_WHITE}
        placeholder='Phone number:*'
        errors={errors?.phone_number}
        optionsName='bill_phone_number'
        options={customersOptionsComplete}
        handleInputChange={(event) => changeValue(event, 'phone_number')}
        chooseChangedReason={chooseChangedReason}
      />

      <ControlledAutocomplete
        label='Address:'
        control={control}
        name='from_customer'
        placeholder='Address:*'
        errors={errors?.address}
        readonly={!isInEditMode}
        inputValue={addressValue}
        borderColor={Colors.SOFT_SILVER}
        borderRadius='5px'
        backgroundColor={Colors.FROST_WHITE}
        optionsName='bill_address'
        options={customersOptionsComplete}
        handleInputChange={(event) => changeValue(event, 'address')}
        chooseChangedReason={(reason) => chooseChangedReason?.(reason, 'address')}
      />

      <ControlledAutocomplete
        label='City:'
        control={control}
        placeholder='City:*'
        name='from_customer'
        errors={errors?.city}
        inputValue={cityValue}
        borderColor={Colors.SOFT_SILVER}
        borderRadius='5px'
        backgroundColor={Colors.FROST_WHITE}
        optionsName='bill_city'
        readonly={!isInEditMode}
        options={customersOptionsComplete}
        handleInputChange={(event) => changeValue(event, 'city')}
        chooseChangedReason={(reason) => chooseChangedReason?.(reason, 'city')}
      />

      <ControlledAutocomplete
        label='State:'
        control={control}
        name='from_customer'
        placeholder='State:*'
        errors={errors?.state}
        inputValue={stateValue}
        borderColor={Colors.SOFT_SILVER}
        borderRadius='5px'
        backgroundColor={Colors.FROST_WHITE}
        optionsName='bill_state'
        readonly={!isInEditMode}
        options={customersOptionsComplete}
        handleInputChange={(event) => changeValue(event, 'state')}
        chooseChangedReason={(reason) => chooseChangedReason?.(reason, 'state')}
      />

      <ControlledAutocomplete
        label='Zip:'
        control={control}
        placeholder='Zip:*'
        errors={errors?.zip}
        name='from_customer'
        inputValue={zipValue}
        borderColor={Colors.SOFT_SILVER}
        borderRadius='5px'
        backgroundColor={Colors.FROST_WHITE}
        optionsName='bill_zip'
        readonly={!isInEditMode}
        options={customersOptionsComplete}
        handleInputChange={(event) => changeValue(event, 'zip')}
        chooseChangedReason={(reason) => chooseChangedReason?.(reason, 'zip')}
      />
    </Box>
  );
};

export default BillingInfoForm;
