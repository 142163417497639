import DOMPurify from 'dompurify';
import { useCallback, useEffect, useState } from 'react';

function isOnlyParagraphBreak(html: string): boolean {
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = html;

  const isMatch =
    tempDiv.children.length === 1 &&
    tempDiv.children[0].tagName.toLowerCase() === 'p' &&
    tempDiv.children[0].children.length === 1 &&
    tempDiv.children[0].children[0].tagName.toLowerCase() === 'br';

  return isMatch;
}

const useHtmlLength = (source: string | Node) => {
  const [isValid, setIsValid] = useState<boolean>(false);
  const [isMatching, setIsMatching] = useState<boolean>(false);

  const [content, setContent] = useState<string>('');

  const checkHtmlLength = useCallback(() => {
    const sanitizedHTML = DOMPurify.sanitize(source);

    const isMatchingPattern = isOnlyParagraphBreak(sanitizedHTML);

    if (isMatchingPattern) {
      setIsMatching(true);
      setContent('');
      // eslint-disable-next-line react-hooks/exhaustive-deps
      source = '';
      setIsValid(false);
    } else {
      const textContent = new DOMParser().parseFromString(sanitizedHTML, 'text/html').body
        .textContent;

      setIsMatching(false);

      if (textContent) {
        setContent(textContent);
      }

      if (!textContent?.trim()?.length) {
        setIsValid(false);
      } else {
        setIsValid(true);
      }
    }
  }, [source]);

  useEffect(() => {
    checkHtmlLength();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [source]);

  return {
    isValid,
    content,
    isMatching,
    checkHtmlLength,
  };
};

export default useHtmlLength;
