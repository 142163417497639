import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

import { liveStreamApi } from 'api';
import {
  LIVE_STREAM_NOTE,
  LiveStreamMessages,
  LIVE_STREAM_INVITATION,
  LiveStreamEndToastMessages,
  LiveStreamRemoveToastMessages,
} from 'constants/ToastMessages';
import dataStructure from 'constants/options';
import { capitalizeFirstLetter } from 'utils';
import { RootState, TGetWithParams } from 'types';

import {
  TCreateLiveStream,
  TRemoveLiveStream,
  TUpdateLiveStream,
  TInviteMemberOptions,
  TCreateLiveStreamNote,
  TGetLiveStreamDetails,
  TGetLiveStreamMessages,
  TUpdateLastReadMessage,
  TInviteExternalUserStream,
  TUpdateChatMessageOptions,
  TGetCompletedPreviousStreams,
  TRemoveStreamOptions,
} from './types';

export const getUserCreatedCompletedLiveStreams = createAsyncThunk(
  'liveStreamSlice/getUserCreatedCompletedLiveStreams',
  async (options: TGetCompletedPreviousStreams, { getState }) => {
    const {
      livestream: { userCreatedStreamsLimit, userCreatedStreamsOffset },
    } = getState() as RootState;

    const {
      limit = userCreatedStreamsLimit,
      offset = userCreatedStreamsOffset,
      ...rest
    } = options ?? {};

    try {
      const response = await liveStreamApi.getUserCreatedCompletedLiveStreamsRequest({
        limit,
        offset,
        ...rest,
      });

      if (Array.isArray(response?.data) && response?.data?.length === 0) {
        return dataStructure;
      }

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const createLiveStream = createAsyncThunk(
  'liveStreamSlice/createLiveStream',
  async (options: TCreateLiveStream) => {
    const liveStreamToast = toast.loading(LiveStreamMessages.LIVESTREAM_CREATION);

    try {
      const response = await liveStreamApi.createLiveStreamRequest(options);

      toast.update(liveStreamToast, {
        render: LiveStreamMessages.LIVESTREAM_CREATION_SUCCESS,
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      const haveErrorMessage =
        !!Error.response &&
        Error.response?.status === 409 &&
        typeof Error?.response?.data === 'string' &&
        !!Error?.response?.data?.length;

      const message = haveErrorMessage
        ? capitalizeFirstLetter(Error?.response?.data as string)
        : LiveStreamMessages.LIVESTREAM_CREATION_FAILURE;

      toast.update(liveStreamToast, {
        render: message,
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });

      throw Error;
    }
  },
);

export const getLiveStreamsById = createAsyncThunk(
  'liveStreamSlice/getLiveStreamsById',
  async (id: number) => {
    try {
      const response = await liveStreamApi.getLiveStreamByIdRequest(id);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const createLiveStreamMessage = createAsyncThunk(
  'liveStreamSlice/createLiveStreamMessage',
  async (body: FormData) => {
    try {
      const response = await liveStreamApi.createLiveStreamMessageRequest(body);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const deleteLiveStream = createAsyncThunk(
  'liveStreamSlice/deleteLiveStream',
  async ({ id, fromJoinPage = false }: TRemoveStreamOptions, { dispatch }) => {
    let start;

    if (!fromJoinPage) {
      start = toast.loading(LiveStreamRemoveToastMessages.STREAM_REMOVE_START);
    } else {
      start = toast.loading(LiveStreamEndToastMessages.STREAM_END_START);
    }

    try {
      const response = await liveStreamApi.deleteLiveStreamRequest(id);

      if (!fromJoinPage) {
        toast.update(start, {
          render: LiveStreamRemoveToastMessages.STREAM_REMOVE_SUCCESS,
          type: 'success',
          isLoading: false,
          autoClose: 3000,
        });
      } else {
        toast.update(start, {
          render: LiveStreamEndToastMessages.STREAM_END_SUCCESS,
          type: 'success',
          isLoading: false,
          autoClose: 3000,
        });
      }

      dispatch(getMyCurrentStream());

      dispatch(
        getUserCreatedCompletedLiveStreams({
          status: 'completed',
          user_created: false,
        }),
      );

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      if (!fromJoinPage) {
        toast.update(start, {
          render: LiveStreamRemoveToastMessages.STREAM_REMOVE_FAILURE,
          type: 'error',
          isLoading: false,
          autoClose: 3000,
        });
      } else {
        toast.update(start, {
          render: LiveStreamEndToastMessages.STREAM_END_FAILURE,
          type: 'error',
          isLoading: false,
          autoClose: 3000,
        });
      }

      throw Error;
    }
  },
);

export const endLiveStream = createAsyncThunk(
  'liveStreamSlice/endLiveStream',
  async (id: number, { dispatch }) => {
    const start = toast.loading(LiveStreamEndToastMessages.STREAM_END_START);

    try {
      const response = await liveStreamApi.endStreamRequest(id);

      toast.update(start, {
        render: LiveStreamEndToastMessages.STREAM_END_SUCCESS,
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });

      dispatch(getMyCurrentStream());

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      toast.update(start, {
        render: LiveStreamEndToastMessages.STREAM_END_FAILURE,
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });

      throw Error;
    }
  },
);

export const getLiveStreamMessages = createAsyncThunk(
  'liveStreamSlice/getLiveStreamMessages',
  async (options: TGetLiveStreamMessages) => {
    try {
      const response = await liveStreamApi.getLiveStreamMessagesRequest(options);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const removeLiveStreamMessage = createAsyncThunk(
  'liveStreamSlice/removeLiveStreamMessage',
  async (id: number) => {
    try {
      const response = await liveStreamApi.deleteLiveStreamMessageRequest(id);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const updateLiveStreamMessage = createAsyncThunk(
  'liveStreamSlice/updateLivestreamMessageRequest',
  async (options: TUpdateChatMessageOptions) => {
    try {
      const response = await liveStreamApi.updateLivestreamMessageRequest(options);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const getLiveStreamDetails = createAsyncThunk(
  'liveStreamSlice/getLiveStreamDetails',
  async (options: TGetLiveStreamDetails) => {
    try {
      const response = await liveStreamApi.getLiveStreamDetailsRequest(options);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const updateLiveStream = createAsyncThunk(
  'liveStreamSlice/updateLiveStream',
  async (options: TUpdateLiveStream) => {
    try {
      const response = await liveStreamApi.updateLiveStreamRequest(options);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const inviteMember = createAsyncThunk(
  'liveStreamSlice/inviteMember',
  async (options: TInviteMemberOptions) => {
    try {
      const response = await liveStreamApi.inviteMemberRequest(
        options.livestream_id,
        options.user_id,
      );

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const inviteExternalMember = createAsyncThunk(
  'liveStreamSlice/inviteExternalMember',
  async (options: TInviteExternalUserStream) => {
    const start = toast.loading(LIVE_STREAM_INVITATION.INVITATION_SEND_START);
    try {
      const response = await liveStreamApi.inviteExternalMemberRequest(options);

      toast.update(start, {
        render: LIVE_STREAM_INVITATION.INVITATION_SEND_SUCCESS,
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      toast.update(start, {
        render: LIVE_STREAM_INVITATION.INVITATION_SEND_FAIL,
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });

      throw Error;
    }
  },
);

export const joinLivestream = createAsyncThunk(
  'liveStreamSlice/joinLivestream',
  async (livestream_id: number) => {
    try {
      const response = await liveStreamApi.joinLivestreamRequest(livestream_id);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const createNoteForStream = createAsyncThunk(
  'liveStreamSlice/createNoteForStreamRequest',
  async (data: TCreateLiveStreamNote) => {
    const start = toast.loading(LIVE_STREAM_NOTE.LIVE_STREAM_NOTE_SEND_START);
    try {
      const response = await liveStreamApi.createNoteForStreamRequest(data);

      toast.update(start, {
        render: LIVE_STREAM_NOTE.LIVE_STREAM_NOTE_SEND_SUCCESS,
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      toast.update(start, {
        render: LIVE_STREAM_NOTE.LIVE_STREAM_NOTE_SEND_FAIL,
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });

      throw Error;
    }
  },
);

export const updateLastRead = createAsyncThunk(
  'liveStreamSlice/updateLastRead',
  async (options: TUpdateLastReadMessage) => {
    try {
      const response = await liveStreamApi.updateLastReadRequest(options);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const getExternalLivestream = createAsyncThunk(
  'liveStreamSlice/getExternalLivestream',
  async (token: string) => {
    try {
      const response = await liveStreamApi.getExternalLivestreamRequest(token);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const leaveLivestream = createAsyncThunk(
  'liveStreamSlice/leaveLivestream',
  async (livestream_id: number) => {
    try {
      const response = await liveStreamApi.leaveLivestreamRequest(livestream_id);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const removeMemberFromLiveStream = createAsyncThunk(
  'liveStreamSlice/removeMemberFromLiveStream',
  async (options: TRemoveLiveStream) => {
    try {
      const response = await liveStreamApi.removeMemberFromLiveStreamRequest(
        options.livestream_id,
        options.member_id,
      );

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const removeExternalMemberLivestream = createAsyncThunk(
  'liveStreamSlice/removeExternalMemberLivestream',
  async (token: string) => {
    try {
      const response = await liveStreamApi.removeExternalMemberLivestreamRequest(token);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const getMyCurrentStream = createAsyncThunk(
  'liveStreamSlice/getMyCurrentStream',
  async () => {
    try {
      const response = await liveStreamApi.getMyCurrentStreamRequest();

      if (Array.isArray(response?.data) && !response?.data?.length) {
        return null;
      }

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const getLivestreamMessageById = createAsyncThunk(
  'liveStreamSlice/getLivestreamMessageById',
  async (id: number) => {
    try {
      const response = await liveStreamApi.getLivestreamMessageByIdRequest(id);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const uploadMediaToStream = createAsyncThunk(
  'liveStreamSlice/uploadMediaToStream',
  async (options: FormData) => {
    try {
      const response = await liveStreamApi.uploadMediaToStreamRequest(options);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      throw Error;
    }
  },
);

export const getLiveStreamNotices = createAsyncThunk(
  'liveStreamSlice/getLivestreamNotices',
  async (options: TGetWithParams<{ id: number }, 'id'>, { getState }) => {
    const {
      livestream: { liveStreamNoticesLimit, liveStreamNoticesOffset },
    } = getState() as RootState;

    const { limit = liveStreamNoticesLimit, offset = liveStreamNoticesOffset, ...rest } = options;

    try {
      const response = await liveStreamApi.getStreamAllNotesRequest({ limit, offset, ...rest });

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const addNoteToStream = createAsyncThunk(
  'liveStreamSlice/addNoteToStream',
  async (data: FormData) => {
    try {
      const response = await liveStreamApi.createStreamNoteRequest(data);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const getStreamWithUUID = createAsyncThunk(
  'liveStreamSlice/getStreamWithUUID',
  async (id: string) => {
    try {
      const response = await liveStreamApi.getStreamByUUIDRequest(id);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);
