import { type FC, useState } from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { Box } from '@mui/material';

import { Button, CustomBreadcrumbs } from 'components';
import { Colors, FontNames } from 'types';
import { TVendorFormValues } from 'containers/Inventory/ExcelList/AddEstimateViaTemplate/types';

import { breadCrumbOptions } from './utils';
import styles from './BomEstimate.module.scss';

const UpdateBomEstimateCostForm: FC = () => {
  const handleCreateData: SubmitHandler<FieldValues> = async () => {};
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<TVendorFormValues>();
  const [value, setValue] = useState(0); // Initial value
  const [valueA, setValueA] = useState(0); // Initial value
  const [valueB, setValueB] = useState(0); // Initial value
  const [valueC, setValueC] = useState(0); // Initial value
  const [valueD, setValueD] = useState(0); // Initial value
  const minValue = 0; // Minimum value
  const maxValue = 100; // Maximum value

  // Function to handle changes to the input value
  const handleChangeServices = (event: { target: { value: string } }) => {
    let newValue = parseInt(event.target.value, 10);
    // Ensure newValue is within the range
    if (newValue < minValue) {
      newValue = minValue;
    } else if (newValue > maxValue) {
      newValue = maxValue;
    }
    setValue(newValue);
  };
  const handleChangeEquipment = (event: { target: { value: string } }) => {
    let newValue = parseInt(event.target.value, 10);
    // Ensure newValue is within the range
    if (newValue < minValue) {
      newValue = minValue;
    } else if (newValue > maxValue) {
      newValue = maxValue;
    }
    setValueA(newValue);
  };
  const handleChangeYearly = (event: { target: { value: string } }) => {
    let newValue = parseInt(event.target.value, 10);
    // Ensure newValue is within the range
    if (newValue < minValue) {
      newValue = minValue;
    } else if (newValue > maxValue) {
      newValue = maxValue;
    }
    setValueB(newValue);
  };
  const handleChangeMonthly = (event: { target: { value: string } }) => {
    let newValue = parseInt(event.target.value, 10);
    // Ensure newValue is within the range
    if (newValue < minValue) {
      newValue = minValue;
    } else if (newValue > maxValue) {
      newValue = maxValue;
    }
    setValueC(newValue);
  };
  const handleChangeGPM = (event: { target: { value: string } }) => {
    let newValue = parseInt(event.target.value, 10);
    // Ensure newValue is within the range
    if (newValue < minValue) {
      newValue = minValue;
    } else if (newValue > maxValue) {
      newValue = maxValue;
    }
    setValueD(newValue);
  };

  return (
    <div className={styles.pageContainer}>
      {/* Header */}
      <CustomBreadcrumbs options={breadCrumbOptions} />
      <header className={styles.header}>
        <h6>
          <strong>Cost Margins</strong>
        </h6>
      </header>
      <div>
        <h5>
          <strong>Services subtotal: </strong>
          <input
            type='number'
            value={value}
            onChange={handleChangeServices}
            min={minValue}
            max={maxValue}
          />{' '}
          %
        </h5>

        <h5>
          <strong>Equipment subtotal: </strong>
          <input
            type='number'
            value={valueA}
            onChange={handleChangeEquipment}
            min={minValue}
            max={maxValue}
          />{' '}
          %
        </h5>
        <h5>
          <strong>Yearly Product Support subtotal: </strong>
          <input
            type='number'
            value={valueB}
            onChange={handleChangeYearly}
            min={minValue}
            max={maxValue}
          />{' '}
          %
        </h5>
        <h5>
          <strong>Monthly contract term MRC subtotal: </strong>
          <input
            type='number'
            value={valueC}
            onChange={handleChangeMonthly}
            min={minValue}
            max={maxValue}
          />{' '}
          %
        </h5>
        <h5>
          <strong>GPM Services - Small (Under 200 APs): </strong>
          <input
            type='number'
            value={valueD}
            onChange={handleChangeGPM}
            min={minValue}
            max={maxValue}
          />{' '}
          %
        </h5>
      </div>
      {/* Form Content */}
      <form onSubmit={handleSubmit(handleCreateData)} className={styles.container}>
        <Box className={styles.container__footer}>
          <Button
            type='submit'
            color='white'
            maxWidth='180px'
            minWidth='68px'
            padding='11px 8px'
            borderRadius='5px'
            backgroundColor={Colors.SAPPHIRE}
            fontFamily={FontNames.CIRCULAR_BOLD_MAX}
            disabled={isSubmitting}
            className={styles.container__content__submit}
          >
            Apply
          </Button>
        </Box>
      </form>
    </div>
  );
};

export default UpdateBomEstimateCostForm;
