import { FC } from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';

import { DefaultUncheckedIcon } from 'assets/icons';

import { TCustomCheckboxProps } from './types';

const CustomCheckbox: FC<TCustomCheckboxProps> = ({
  label,
  checkedIcon,
  checked,
  onChecked,
  icon = <DefaultUncheckedIcon />,
}) => {
  return (
    <FormControlLabel
      sx={{
        '& .MuiButtonBase-root': {
          padding: '8px',
        },
        '& .MuiTypography-root': {
          color: '#CCC',
          fontSize: '12px',
          letterSpacing: '0.048px',
          fontFamily: 'CircularStdRegular',
        },
      }}
      control={
        <Checkbox checked={checked} onChange={onChecked} icon={icon} checkedIcon={checkedIcon} />
      }
      label={label}
    />
  );
};

export default CustomCheckbox;
