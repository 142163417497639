import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { formatSnakeCase } from 'utils/formatSnakeCase';

const allRolesDataSelector = (state: RootState) => state.roles.allRoles;

export const allRolesSelector = createSelector([allRolesDataSelector], (allRoles) =>
  allRoles.map((role) => ({
    value: formatSnakeCase(role.role),
    label: role.role,
    id: role.id,
  })),
);
