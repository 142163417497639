import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

import { invoiceApi } from 'api';
import { capitalizeFirstLetter } from 'utils';
import dataStructure from 'constants/options';
import { InvoicesToastMessages } from 'constants/ToastMessages';

import type { RootState } from 'types';
import type {
  TCreateInvoice,
  TCreateItem,
  TInvoicesParams,
  TUpdateInvoice,
  TUpdateItem,
} from './types';

export const getAllInvoices = createAsyncThunk(
  'invoicesSlice/getAllInvoices',
  async (options: TInvoicesParams) => {
    try {
      const response = await invoiceApi.getAllInvoicesRequest(options);

      if (Array.isArray(response?.data) && response?.data?.length === 0) {
        return dataStructure;
      }

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const createInvoice = createAsyncThunk(
  'invoicesSlice/createInvoice',
  async (body: TCreateInvoice, { dispatch, getState }) => {
    const start = toast.loading(InvoicesToastMessages.INVOICE_CREATION);

    const {
      invoices: { invoiceLimit, invoiceOffset },
    } = getState() as RootState;

    try {
      const response = await invoiceApi.createInvoiceRequest(body);

      dispatch(getAllInvoices({ limit: invoiceLimit, offset: invoiceOffset }));

      toast.update(start, {
        render: InvoicesToastMessages.INVOICE_CREATION_SUCCESS,
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      toast.update(start, {
        render: InvoicesToastMessages.INVOICE_CREATION_FAILURE,
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });

      throw Error;
    }
  },
);

export const deleteInvoice = createAsyncThunk(
  'invoicesSlice/deleteInvoice',
  async (id: number, { dispatch, getState }) => {
    const start = toast.loading(InvoicesToastMessages.INVOICE_REMOVAL_START);

    const {
      invoices: { invoiceLimit, invoiceOffset },
    } = getState() as RootState;

    try {
      const response = await invoiceApi.deleteInvoiceIdRequest(id);

      dispatch(getAllInvoices({ limit: invoiceLimit, offset: invoiceOffset }));

      toast.update(start, {
        render: InvoicesToastMessages.INVOICE_REMOVAL_SUCCESS,
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      toast.update(start, {
        render: InvoicesToastMessages.INVOICE_REMOVAL_FAILURE,
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });

      throw Error;
    }
  },
);

export const getInvoiceById = createAsyncThunk(
  'invoicesSlice/getInvoiceById',
  async (id: number) => {
    try {
      const response = await invoiceApi.getInvoiceByIdRequest(id);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const updateInvoice = createAsyncThunk(
  'invoicesSlice/updateInvoice',
  async (body: TUpdateInvoice, { dispatch, getState }) => {
    const start = toast.loading(InvoicesToastMessages.INVOICE_EDIT_START);

    const {
      invoices: { invoiceLimit, invoiceOffset },
    } = getState() as RootState;

    try {
      const response = await invoiceApi.updateInvoiceRequest(body);

      dispatch(getAllInvoices({ limit: invoiceLimit, offset: invoiceOffset }));
      dispatch(getInvoiceById(body.id));
      dispatch(getAllInvoiceItems(body.id));

      toast.update(start, {
        render: InvoicesToastMessages.INVOICE_EDIT_SUCCESS,
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      toast.update(start, {
        render: InvoicesToastMessages.INVOICE_EDIT_FAILURE,
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });

      throw Error;
    }
  },
);

export const getAllInvoiceItems = createAsyncThunk(
  'invoicesSlice/getAllInvoiceItems',
  async (id: number) => {
    try {
      const response = await invoiceApi.getAllItemsByInvoiceIdRequest(id);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const createInvoiceItem = createAsyncThunk(
  'invoicesSlice/createInvoiceItem',
  async (body: TCreateItem, { dispatch }) => {
    try {
      const response = await invoiceApi.createInvoiceItemRequest(body);

      dispatch(getInvoiceById(body.invoice_id as number));

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      throw Error;
    }
  },
);

export const deleteInvoiceItem = createAsyncThunk(
  'invoicesSlice/deleteInvoiceItem',
  async (id: number) => {
    try {
      const response = await invoiceApi.deleteInvoiceItemIdRequest(id);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      throw Error;
    }
  },
);

export const publishInvoiceItem = createAsyncThunk(
  'invoicesSlice/deleteInvoiceItem',
  async (id: number, { dispatch, getState }) => {
    const {
      invoices: { invoiceLimit, invoiceOffset },
    } = getState() as RootState;

    const start = toast.loading(InvoicesToastMessages.INVOICE_PUBLISH_START);

    try {
      const response = await invoiceApi.publishInvoiceItemIdRequest(id);

      toast.update(start, {
        render: capitalizeFirstLetter(response?.data?.status),
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });

      dispatch(getAllInvoices({ limit: invoiceLimit, offset: invoiceOffset }));
      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      toast.update(start, {
        render: InvoicesToastMessages.INVOICE_PUBLISH_FAILURE,
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });
      throw Error;
    }
  },
);

export const getInvoiceItemById = createAsyncThunk(
  'invoicesSlice/getInvoiceItemById',
  async (id: number) => {
    try {
      const response = await invoiceApi.getInvoiceItemByIdRequest(id);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const updateInvoiceItem = createAsyncThunk(
  'invoicesSlice/updateInvoiceItem',
  async (body: TUpdateItem) => {
    try {
      const response = await invoiceApi.updateInvoiceItemRequest(body);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);
