import { TOption } from 'components/shared/Select/types';

export const countries: TOption[] = [
  { id: 1, value: 'US', label: 'USA' },
  { id: 2, value: 'CA', label: 'Canada' },
];

export const canadaStates: TOption[] = [
  { id: 1, value: 'AB', label: 'Alberta' },
  { id: 2, value: 'BC', label: 'British Columbia' },
  { id: 3, value: 'MB', label: 'Manitoba' },
  { id: 4, value: 'NL', label: 'Newfoundland and Labrador' },
  { id: 5, value: 'NS', label: 'Nova Scotia' },
  { id: 6, value: 'ON', label: 'Ontario' },
  { id: 7, value: 'PE', label: 'Prince Edward Island' },
  { id: 8, value: 'QC', label: 'Quebec' },
];

export const stateOptions: TOption[] = [
  { id: 1, value: 'AL', label: 'Alabama' },
  { id: 2, value: 'AK', label: 'Alaska' },
  { id: 3, value: 'AZ', label: 'Arizona' },
  { id: 4, value: 'AR', label: 'Arkansas' },
  { id: 5, value: 'CA', label: 'California' },
  { id: 6, value: 'CO', label: 'Colorado' },
  { id: 7, value: 'CT', label: 'Connecticut' },
  { id: 8, value: 'DE', label: 'Delaware' },
  { id: 9, value: 'FL', label: 'Florida' },
  { id: 10, value: 'GA', label: 'Georgia' },
  { id: 11, value: 'HI', label: 'Hawaii' },
  { id: 12, value: 'ID', label: 'Idaho' },
  { id: 13, value: 'IL', label: 'Illinois' },
  { id: 14, value: 'IN', label: 'Indiana' },
  { id: 15, value: 'IA', label: 'Iowa' },
  { id: 16, value: 'KS', label: 'Kansas' },
  { id: 17, value: 'KY', label: 'Kentucky' },
  { id: 18, value: 'LA', label: 'Louisiana' },
  { id: 19, value: 'ME', label: 'Maine' },
  { id: 20, value: 'MD', label: 'Maryland' },
  { id: 21, value: 'MA', label: 'Massachusetts' },
  { id: 22, value: 'MI', label: 'Michigan' },
  { id: 23, value: 'MN', label: 'Minnesota' },
  { id: 24, value: 'MS', label: 'Mississippi' },
  { id: 25, value: 'MO', label: 'Missouri' },
  { id: 26, value: 'MT', label: 'Montana' },
  { id: 27, value: 'NE', label: 'Nebraska' },
  { id: 28, value: 'NV', label: 'Nevada' },
  { id: 29, value: 'NH', label: 'New Hampshire' },
  { id: 30, value: 'NJ', label: 'New Jersey' },
  { id: 31, value: 'NM', label: 'New Mexico' },
  { id: 32, value: 'NY', label: 'New York' },
  { id: 33, value: 'NC', label: 'North Carolina' },
  { id: 34, value: 'ND', label: 'North Dakota' },
  { id: 35, value: 'OH', label: 'Ohio' },
  { id: 36, value: 'OK', label: 'Oklahoma' },
  { id: 37, value: 'OR', label: 'Oregon' },
  { id: 38, value: 'PA', label: 'Pennsylvania' },
  { id: 39, value: 'RI', label: 'Rhode Island' },
  { id: 40, value: 'SC', label: 'South Carolina' },
  { id: 41, value: 'SD', label: 'South Dakota' },
  { id: 42, value: 'TN', label: 'Tennessee' },
  { id: 43, value: 'TX', label: 'Texas' },
  { id: 44, value: 'UT', label: 'Utah' },
  { id: 45, value: 'VT', label: 'Vermont' },
  { id: 46, value: 'VA', label: 'Virginia' },
  { id: 47, value: 'WA', label: 'Washington' },
  { id: 48, value: 'WV', label: 'West Virginia' },
  { id: 49, value: 'WI', label: 'Wisconsin' },
  { id: 50, value: 'WY', label: 'Wyoming' },
];

export const TimeOptions: TOption[] = [
  { id: 1, value: '06:00', label: '6:00 AM' },
  { id: 2, value: '06:30', label: '6:30 AM' },
  { id: 3, value: '07:00', label: '7:00 AM' },
  { id: 4, value: '07:30', label: '7:30 AM' },
  { id: 5, value: '08:00', label: '8:00 AM' },
  { id: 6, value: '08:30', label: '8:30 AM' },
  { id: 7, value: '09:00', label: '9:00 AM' },
  { id: 8, value: '09:30', label: '9:30 AM' },
  { id: 9, value: '10:00', label: '10:00 AM' },
  { id: 10, value: '10:30', label: '10:30 AM' },
  { id: 11, value: '11:00', label: '11:00 AM' },
  { id: 12, value: '11:30', label: '11:30 AM' },
  { id: 13, value: '12:00', label: '12:00 PM' },
  { id: 14, value: '12:30', label: '12:30 PM' },
  { id: 15, value: '13:00', label: '1:00 PM' },
  { id: 16, value: '13:30', label: '1:30 PM' },
  { id: 17, value: '14:00', label: '2:00 PM' },
  { id: 18, value: '14:30', label: '2:30 PM' },
  { id: 19, value: '15:00', label: '3:00 PM' },
  { id: 20, value: '15:30', label: '3:30 PM' },
  { id: 21, value: '16:00', label: '4:00 PM' },
  { id: 22, value: '16:30', label: '4:30 PM' },
  { id: 23, value: '17:00', label: '5:00 PM' },
  { id: 24, value: '17:30', label: '5:30 PM' },
  { id: 25, value: '18:00', label: '6:00 PM' },
  { id: 26, value: '18:30', label: '6:30 PM' },
  { id: 27, value: '19:00', label: '7:00 PM' },
  { id: 28, value: '19:30', label: '7:30 PM' },
  { id: 29, value: '20:00', label: '8:00 PM' },
  { id: 30, value: '20:30', label: '8:30 PM' },
  { id: 31, value: '21:00', label: '9:00 PM' },
  { id: 32, value: '21:30', label: '9:30 PM' },
  { id: 33, value: '22:00', label: '10:00 PM' },
  { id: 34, value: '22:30', label: '10:30 PM' },
  { id: 35, value: '23:00', label: '11:00 PM' },
  { id: 36, value: '23:30', label: '11:30 PM' },
  { id: 37, value: '00:00', label: '12:00 AM' },
  { id: 38, value: '00:30', label: '12:30 AM' },
  { id: 39, value: '01:00', label: '1:00 AM' },
  { id: 40, value: '01:30', label: '1:30 AM' },
  { id: 41, value: '02:00', label: '2:00 AM' },
  { id: 42, value: '02:30', label: '2:30 AM' },
  { id: 43, value: '03:00', label: '3:00 AM' },
  { id: 44, value: '03:30', label: '3:30 AM' },
  { id: 45, value: '04:00', label: '4:00 AM' },
  { id: 46, value: '04:30', label: '4:30 AM' },
  { id: 47, value: '05:00', label: '5:00 AM' },
  { id: 48, value: '05:30', label: '5:30 AM' },
];

export const HourOptions: TOption[] = [
  { id: 1, value: 1, label: '1' },
  { id: 2, value: 2, label: '2' },
  { id: 3, value: 3, label: '3' },
  { id: 4, value: 4, label: '4' },
  { id: 5, value: 5, label: '5' },
  { id: 6, value: 6, label: '6' },
  { id: 7, value: 7, label: '7' },
  { id: 8, value: 8, label: '8' },
  { id: 9, value: 9, label: '9' },
  { id: 10, value: 10, label: '10' },
  { id: 11, value: 11, label: '11' },
  { id: 12, value: 12, label: '12' },
];
