import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

import { RootState } from 'types';
import { agreementApi } from 'api';
import dataStructure from 'constants/options';
import { capitalizeFirstLetter } from 'utils/capitalizeText';
import { AgreementToastMessages } from 'constants/ToastMessages';

import { getEstimateById, getEstimates } from '../estimatesSlice/thunks';

import {
  TAgreementCreateBody,
  TAgreementParams,
  TCreateJobTypeBody,
  TJobTypesParams,
  TUpdateAgreementBody,
  TUpdateJobTypeBody,
} from './types';

export const getAllAgreement = createAsyncThunk(
  'agreementSlice/getAllAgreement',
  async (options: TAgreementParams) => {
    try {
      const response = await agreementApi.getAllAgreementsRequest(options);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const createAgreement = createAsyncThunk(
  'agreementSlice/createAgreement',
  async (body: TAgreementCreateBody, { dispatch }) => {
    const start = toast.loading(AgreementToastMessages.AGREEMENT_CREATION);
    try {
      const response = await agreementApi.createAgreementRequest(body);

      dispatch(getAllAgreement({ limit: 10, offset: 0 }));

      toast.update(start, {
        render: AgreementToastMessages.AGREEMENT_CREATION_SUCCESS,
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      toast.update(start, {
        render: AgreementToastMessages.AGREEMENT_CREATION_FAILURE,
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });

      throw Error;
    }
  },
);

export const deleteAgreement = createAsyncThunk(
  'agreementSlice/deleteAgreement',
  async (id: number, { dispatch }) => {
    const start = toast.loading(AgreementToastMessages.AGREEMENT_REMOVAL_START);
    try {
      const response = await agreementApi.deleteAgreementByIdRequest(id);

      dispatch(getAllAgreement({ limit: 10, offset: 0 }));

      toast.update(start, {
        render: AgreementToastMessages.AGREEMENT_REMOVAL_SUCCESS,
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      toast.update(start, {
        render: AgreementToastMessages.AGREEMENT_REMOVAL_FAILURE,
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });

      throw Error;
    }
  },
);

export const getAgreementById = createAsyncThunk(
  'agreementSlice/getAgreementById',
  async (id: number) => {
    try {
      const response = await agreementApi.getAgreementByIdRequest(id);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const updateAgreement = createAsyncThunk(
  'agreementSlice/updateAgreement',
  async (body: TUpdateAgreementBody, { dispatch }) => {
    const start = toast.loading(AgreementToastMessages.AGREEMENT_EDIT_START);

    try {
      const response = await agreementApi.updateAgreementRequest(body);

      dispatch(getAllAgreement({ limit: 10, offset: 0 }));
      dispatch(getAgreementById(body.id));

      toast.update(start, {
        render: AgreementToastMessages.AGREEMENT_EDIT_SUCCESS,
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      toast.update(start, {
        render: AgreementToastMessages.AGREEMENT_EDIT_FAILURE,
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });

      throw Error;
    }
  },
);

export const getAllJobTypes = createAsyncThunk(
  'agreementSlice/getAllJobTypes',
  async (options: TJobTypesParams) => {
    try {
      const response = await agreementApi.getJobTypesRequest(options);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      if (Error.response?.status === 404) {
        return dataStructure;
      } else {
        throw Error;
      }
    }
  },
);

export const createJobType = createAsyncThunk(
  'agreementSlice/createJobType',
  async (body: TCreateJobTypeBody, { dispatch }) => {
    try {
      const response = await agreementApi.createJobTypeRequest(body);

      dispatch(getAllJobTypes({ limit: 10, offset: 0 }));

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      throw Error;
    }
  },
);

export const deleteJobType = createAsyncThunk(
  'agreementSlice/deleteJobType',
  async (id: number, { dispatch }) => {
    try {
      const response = await agreementApi.deleteJobTypeByIdRequest(id);

      dispatch(getAllJobTypes({ limit: 10, offset: 0 }));

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      throw Error;
    }
  },
);

export const getJobTypeById = createAsyncThunk(
  'agreementSlice/getJobTypeById',
  async (id: number) => {
    try {
      const response = await agreementApi.getJobTypeByIdRequest(id);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const updateJobType = createAsyncThunk(
  'agreementSlice/updateJobType',
  async (body: TUpdateJobTypeBody, { dispatch }) => {
    try {
      const response = await agreementApi.updateJobTypeRequest(body);

      dispatch(getAllJobTypes({ limit: 10, offset: 0 }));

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const sendAgreementToCustomer = createAsyncThunk(
  'agreementSlice/sendAgreementToCustomer',
  async (agreement_id: number, { dispatch, getState }) => {
    const start = toast.loading(AgreementToastMessages.AGREEMENT_ITEM_PUBLISH_START);

    const {
      estimates: { allEstimatesLimit, allEstimatesOffset },
    } = getState() as RootState;

    try {
      const response = await agreementApi.sendAgreementToCustomerRequest(agreement_id);

      toast.update(start, {
        render: capitalizeFirstLetter(response?.data?.status),
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });

      dispatch(getEstimateById(agreement_id));
      dispatch(getEstimates({ limit: allEstimatesLimit, offset: allEstimatesOffset }));
      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      toast.update(start, {
        render: AgreementToastMessages.AGREEMENT_SENDCUSTOMER_FAILED,
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });

      throw Error;
    }
  },
);
