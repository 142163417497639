import { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { Box } from '@mui/material';
import { toast } from 'react-toastify';
import localforage from 'localforage';
import { yupResolver } from '@hookform/resolvers/yup';
import { Navigate, useNavigate } from 'react-router-dom';
import { StripeCardElementChangeEvent } from '@stripe/stripe-js';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';

import { Routes } from 'types';
import { ProcessBackIcon } from 'assets/icons';
import { Button, HorizontalStepper } from 'components';
import { TUserProfile } from 'store/slices/authSlice/types';
import { createOrganizationFormValuesGenerator } from 'utils';
import { RootObject, generatePlan } from 'utils/generatePlan';
import { userDataSelector } from 'store/slices/authSlice/selectors';
import { allRolesSelector } from 'store/slices/rolesSlice/selectors';
import { TCreateInvitation } from 'store/slices/invitationSlice/types';
import { createInvitation } from 'store/slices/invitationSlice/thunks';
import { TInvitedUserElement } from 'components/views/InviteUsers/types';
import { TCreateSubscription } from 'store/slices/subscriptionSlice/types';
import { createOrganizationSelector } from 'store/slices/organizationsSlice/selectors';
import {
  inviteUserSchema,
  organizationCreate,
  paymentSchema,
  paymentOnboardingSchema,
} from 'constants/Schemas';
import { BrowserStorageKeys, BrowserStorageService, LocalForageService } from 'services';
import {
  useWindowSize,
  useAppDispatch,
  useAppSelector,
  useStripePayment,
  useUserOrganizationsList,
} from 'hooks';
import {
  CreateAccountForm,
  InviteUsers,
  Payment,
  SubscriptionPlanBoxes,
  PaymentOnboarding,
} from 'components';
import { TInvitedUser } from 'components/views/Drawers/InviteUsersDrawer/types';
import useOrganizeProcess from 'hooks/useOrganizeProcess';
import { LocalForageKeys } from 'services/localForage/types';
import { startFreeSubscription } from 'store/slices/subscriptionSlice';
import { createStripeAccount } from 'store/slices/subscriptionSlice/thunks';

import { breakPoints, processFormValues } from '../../constants';

import { ProcessFormNames, TOrganizationForm, TSendDuration, ConvertedData } from './types';
import styles from './ProcessForm.module.scss';

import type { TOrganization } from 'store/slices/organizationsSlice/types';

const ProcessForm = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { width } = useWindowSize();

  const haveActiveOrganization = BrowserStorageService.get(
    BrowserStorageKeys.HaveCreatedOrganization,
  );

  const [agreement, setAgreement] = useState(false);
  const [timeStamp, setTimeStamp] = useState<string>('');
  const [ip, setIp] = useState<string>('');

  const handleClick: SubmitHandler<FieldValues> = () => {
    setAgreement(!agreement);
    setTimeStamp(String(Math.floor(Date.now() / 1000)));

    fetchIpAddress().then((ip) => {
      setIp(ip);
    });
  };

  const fetchIpAddress = async () => {
    try {
      const response = await fetch('https://api.ipify.org?format=json');
      const data = await response.json();
      return data.ip;
    } catch (error) {
      return null;
    }
  };

  const email = BrowserStorageService.get(BrowserStorageKeys.CurrentUserEmail, { session: true });

  useEffect(() => {
    if (!haveActiveOrganization) {
      BrowserStorageService.set(BrowserStorageKeys.InProcessForm, 'true');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tokenInLocalStorage = BrowserStorageService.get(BrowserStorageKeys.AccessToken);

  const { getAllOrganizations } = useUserOrganizationsList();

  useEffect(() => {
    getAllOrganizations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const userInfo = useAppSelector(userDataSelector);
  const currentOrganization = useAppSelector(createOrganizationSelector);

  const [companyLogo, setCompanyLogo] = useState<File | null>(null);
  const [documentFront, setDocumentFront] = useState<File | null>(null);
  const [documentLast, setDocuementLast] = useState<File | null>(null);

  const [activeCardForSend, setActiveCardForSend] = useState<TSendDuration>({
    title: 'basic',
    duration: 'Monthly',
    amount: 0,
  });

  const [typeOfBusinessValue, setTypeOfBusinessValue] = useState<string>(
    currentOrganization?.business_type || '',
  );

  const allRoles = useAppSelector(allRolesSelector);

  const organizationIdFromStorage = BrowserStorageService.get(
    BrowserStorageKeys.CurrentOrganizationId,
  );

  const amountFromStorage = BrowserStorageService.get(BrowserStorageKeys.CurrentAmount, {
    session: true,
  });

  const [isCardValid, setIsCardValid] = useState<boolean>(false);

  const activeExistedvalue = BrowserStorageService.get(BrowserStorageKeys.ProcessFormValue, {
    session: true,
  });

  const token = BrowserStorageService.get(BrowserStorageKeys.AccessToken);

  const isFirstTimeUser = BrowserStorageService.get(BrowserStorageKeys.IsFirstTimeUser, {
    session: true,
  });

  const [activeStep, setActiveStep] = useState<number>(
    Number(activeExistedvalue) || processFormValues[0]?.id,
  );

  const [activeStepName, setActiveStepName] = useState<ProcessFormNames>(
    ProcessFormNames.PROCESSFORM,
  );

  const [skipped, setSkipped] = useState(new Set<number>());

  const activeStatName = useMemo(
    () => processFormValues.find((name) => name?.id === activeStep),
    [activeStep],
  );

  const buttonClasses = classNames(styles.container__footer__next);

  const getCompanyLogoImg = (file: File | null) => setCompanyLogo(file);
  const getDocumentFront = (file: File | null) => setDocumentFront(file);
  const getDocumentLast = (file: File | null) => setDocuementLast(file);
  const getTypeOfBusinessValue = (type: string) => setTypeOfBusinessValue(type);

  useEffect(() => {
    if (activeStatName) {
      setActiveStepName(activeStatName.title);
    }
  }, [activeExistedvalue, activeStatName, activeStep]);

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  useEffect(() => {
    if (activeStep === processFormValues.length) {
      navigate(Routes.SignUpSuccessPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep]);

  useEffect(() => {
    token && isFirstTimeUser === 'false' && navigate(Routes.Landing);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const handleSkipPaymentOnboarding = () => {
    setActiveStep(activeStep + 1);
  };

  const handleSkip = () => {
    // console.log("processFormValues.length",processFormValues.length);
    // console.log('at start of skip',activeStep)
    // setActiveStep(5);
    // console.log("ActiveStep",activeStep)
    // setSkipped((prevSkipped) => {
    //   const newSkipped = new Set(prevSkipped.values());
    //   newSkipped.add(activeStep);
    //   return newSkipped;
    // });
    // console.log('after skip',activeStep)

    BrowserStorageService.remove(BrowserStorageKeys.subscriptionClientSecret);
    localStorage.clear();
    sessionStorage.clear();
    localforage.clear();
    toast.success('You will receive an authentication email shortly');
    navigate(Routes.SignUpSuccessPage);
  };

  const handleBack = () => {
    if (activeStep === 0) {
      localforage.clear();
      localStorage.clear();
      sessionStorage.clear();
      navigate(Routes.Landing);
    }

    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleCardChange = (event: StripeCardElementChangeEvent) =>
    setIsCardValid(!!event.complete);

  const {
    handleSubmit: organizationSubmit,
    control: organizationControl,
    reset,
    getValues,
    formState: { isDirty, errors: organizationError, isSubmitting: organizationSubmitting },
  } = useForm<TOrganizationForm>({
    resolver: yupResolver(organizationCreate),
    values: createOrganizationFormValuesGenerator({
      currentOrganization: currentOrganization as TOrganization,
      userInfo: userInfo as TUserProfile,
    }),
    mode: 'all',
  });

  const resetTypeOfBusiness = () => {
    reset({ ...getValues(), typeOfBusiness: '' });
  };

  const organizationStepNext = isDirty && !!typeOfBusinessValue?.trim()?.length;

  const {
    control: inviteUsersForm,
    handleSubmit: inviteUsersSubmit,
    formState: { errors, isSubmitting: inviteUsersSubmitting },
    watch: inviteUsersWatch,
  } = useForm({
    resolver: yupResolver(inviteUserSchema),
  });

  const state = inviteUsersWatch();

  const isValid = state?.invitedUsers?.some(
    (item: TInvitedUser) => !item?.email?.length || !item?.fullName?.length || !item?.role?.length,
  );

  const {
    handleSubmit: paymentSubmit,
    control: paymentControl,
    watch,
    formState: { errors: paymentError, isValid: paymentValid, isSubmitting: paymentSubmitting },
  } = useForm({
    resolver: activeStepName === ProcessFormNames.PAYMENT ? yupResolver(paymentSchema) : undefined,
    mode: 'all',
  });

  const {
    handleSubmit: PaymentOnboardingSubmit,
    control: PaymentOnboardingControl,
    formState: { errors: PaymentOnboardingError },
  } = useForm({
    resolver: yupResolver(paymentOnboardingSchema),
    mode: 'all',
  });

  const sendedData = watch();

  const { organizeAction, stripeSecret } = useOrganizeProcess();

  const { handleSubmit: paySubmit } = useStripePayment({
    clientSecret: stripeSecret as string,

    name: sendedData?.name,
    phone: sendedData?.number,
    street: sendedData?.address,
    state: sendedData?.state,
    postal_code: sendedData?.zip,
    orgId: Number(organizationIdFromStorage),
    amount: activeCardForSend.amount || Number(amountFromStorage),
  });

  const disableButtonCondition =
    activeStepName === ProcessFormNames.PROCESSFORM
      ? !organizationStepNext || organizationSubmitting
      : activeStepName === ProcessFormNames.SUBSCRIPTIONPLAN
      ? activeCardForSend?.title === 'basic'
      : activeStepName === ProcessFormNames.PAYMENT
      ? (!isCardValid && activeStepName === ProcessFormNames.PAYMENT) ||
        paymentValid === false ||
        paymentSubmitting
      : activeStepName === ProcessFormNames.PAYMENT_ONBOARDING
      ? !agreement
      : activeStepName === ProcessFormNames.INVITEUSERS
      ? isValid || inviteUsersSubmitting
      : false;

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    BrowserStorageService.set(BrowserStorageKeys.ProcessFormValue, String(activeStep + 1), {
      session: true,
    });
    setSkipped(newSkipped);
  };

  const foundedRole = (name: string) =>
    allRoles.find((role) => {
      if (role.value === name) {
        return role;
      }
    });

  const callSubscriptionWithFree = async () => {
    const newSubscription: TCreateSubscription = {
      org_id: Number(currentOrganization?.id) || Number(organizationIdFromStorage),
      subscription: generatePlan(activeCardForSend as RootObject),
    };

    const subscriptionData = await LocalForageService.get(
      LocalForageKeys.ProcessFormSecondStepData,
    );

    if (!subscriptionData) {
      await LocalForageService.set(LocalForageKeys.ProcessFormSecondStepData, newSubscription);
    } else {
      await LocalForageService.update(LocalForageKeys.ProcessFormSecondStepData, newSubscription);
    }

    dispatch(startFreeSubscription());

    handleNext();
  };
  const handleFormChange: SubmitHandler<FieldValues> = async (data) => {
    if (activeStepName === ProcessFormNames.PROCESSFORM) {
      const object = {
        data,
        ['userInfo.email']: JSON.parse(email as string),
        companyLogo,
      };

      const organizationData = await LocalForageService.get(
        LocalForageKeys.ProcessFormFirstStepData,
      );

      if (!organizationData) {
        await LocalForageService.set(LocalForageKeys.ProcessFormFirstStepData, object);
        BrowserStorageService.set(BrowserStorageKeys.CurrentIndustry, data?.industryVertical, {
          session: true,
        });
      } else {
        await LocalForageService.update(LocalForageKeys.ProcessFormFirstStepData, object);
        BrowserStorageService.update(BrowserStorageKeys.CurrentIndustry, data?.industryVertical, {
          session: true,
        });
      }

      handleNext();
    } else if (activeStepName === ProcessFormNames.SUBSCRIPTIONPLAN) {
      const newSubscription: TCreateSubscription = {
        org_id: Number(currentOrganization?.id) || Number(organizationIdFromStorage),
        subscription: generatePlan(activeCardForSend as RootObject),
      };

      const subscriptionData = await LocalForageService.get(
        LocalForageKeys.ProcessFormSecondStepData,
      );

      if (!subscriptionData) {
        await LocalForageService.set(LocalForageKeys.ProcessFormSecondStepData, newSubscription);
      } else {
        await LocalForageService.update(LocalForageKeys.ProcessFormSecondStepData, newSubscription);
      }

      handleNext();
    } else if (activeStepName === ProcessFormNames.PAYMENT) {
      const { secret, orgId, subscriptionSecret, subscriptionId } = await organizeAction();

      if (secret) {
        await paySubmit(secret as string, orgId, handleNext, subscriptionSecret, subscriptionId);
      }

      reset({
        organization: '',
        industryVertical: '',
      });
    } else if (activeStepName === ProcessFormNames.PAYMENT_ONBOARDING) {
      const StripeData: ConvertedData = {
        org_id: currentOrganization?.id || Number(organizationIdFromStorage),
        account_details: {
          email: data.account_email,
        },
        business_profile: {
          merchant_category_code: data.mcc,
          website_url: data.url,
          business_type: data.business_type,
        },
        company_details: {
          name: data.company_name,
          city: data.company_city,
          line1: data.company_line1,
          line2: data.company_line2,
          postal_code: data.company_zip,
          state: data.company_state,
          phone_number: data.company_phone_number,
          tax_id: data.tax_id,
        },
        owner_details: {
          first_name: data.owner_firstname,
          last_name: data.owner_lastname,
          email: data.account_email,
          id_number: data.owner_id_number,
          dob: data.owner_dob,
          phone_number: data.owner_phone_number,
          city: data.owner_city,
          line1: data.owner_line1,
          line2: data.owner_line2,
          state: data.owner_state,
          postal_code: data.owner_zip,
        },
        representative_details: {
          first_name: data.representative_firstname,
          last_name: data.representative_lastname,
          city: data.representative_city,
          line1: data.representative_line1,
          line2: data.representative_line2,
          postal_code: data.representative_zip,
          state: data.representative_state,
          email: data.representative_email,
          dob: data.representative_dob,
          phone: data.representative_number,
          ssn: data.ssn_last4,
          id_number: data.representative_id_number,
        },
        bank_account_details: {
          account_number: data.acc_number,
          account_holder_name: data.acc_holder_name,
          account_holder_type: data.acc_holder_type,
          routing_number: data.routing_number,
        },
        tos_acceptance: {
          ip: ip,
          date: timeStamp,
        },
      };
      if (StripeData) {
        const formData = new FormData();
        if (documentFront !== null) {
          formData.append('representative_id_front_image', documentFront);
        }
        if (documentLast !== null) {
          formData.append('representative_id_back_image', documentLast);
        }
        formData.append('account_details', JSON.stringify(StripeData));
        await dispatch(createStripeAccount(formData));
      }
      handleNext();
    } else if (activeStepName === ProcessFormNames.INVITEUSERS) {
      const sendedData: TCreateInvitation[] = data?.invitedUsers?.map(
        (item: TInvitedUserElement) => ({
          team_id: null,
          email: item.email,
          full_name: item.fullName,
          org_id: currentOrganization?.id || Number(organizationIdFromStorage),
          role_id: Number(foundedRole(item.role)?.id),
        }),
      );

      const response = await dispatch(createInvitation(sendedData));

      if (response.meta.requestStatus === 'fulfilled') {
        navigate(Routes.Landing);
        BrowserStorageService.remove(BrowserStorageKeys.subscriptionClientSecret);
        localStorage.clear();
        sessionStorage.clear();
        localforage.clear();

        toast.success('You will receive authentication email shortly');
      }
    } else {
      handleNext();
    }
  };

  const submitTypes =
    activeStepName === ProcessFormNames.PROCESSFORM
      ? organizationSubmit(handleFormChange)
      : activeStepName === ProcessFormNames.INVITEUSERS
      ? inviteUsersSubmit(handleFormChange)
      : activeStepName === ProcessFormNames.PAYMENT_ONBOARDING
      ? PaymentOnboardingSubmit(handleFormChange)
      : paymentSubmit(handleFormChange);

  if (tokenInLocalStorage) {
    return <Navigate replace={true} to={Routes.ActivityFeed} />;
  }

  if (!tokenInLocalStorage && haveActiveOrganization === 'true') {
    return <Navigate replace={true} to={Routes.OrganizationsList} />;
  }

  const latestProcess =
    activeStep !== processFormValues.length && activeStepName === ProcessFormNames.INVITEUSERS;

  const prevText = activeStepName === ProcessFormNames.PROCESSFORM ? 'Cancel' : 'Previous';

  return (
    <form className={styles.container} onSubmit={submitTypes}>
      <HorizontalStepper
        activeStep={activeStep}
        isStepSkipped={isStepSkipped}
        stepsOptions={processFormValues}
      />
      {activeStep !== processFormValues.length &&
        activeStepName === ProcessFormNames.INVITEUSERS &&
        Number(width) <= breakPoints.TABLET_L && (
          <Button
            startIcon={<ProcessBackIcon />}
            disabled={activeStep <= 0}
            onClick={handleBack}
            className={styles.container__preview}
            textClassName={styles.container__text}
          >
            Prev
          </Button>
        )}
      <Box className={styles.container__content}>
        {activeStepName === ProcessFormNames.PROCESSFORM ? (
          <CreateAccountForm
            resetField={resetTypeOfBusiness}
            errors={organizationError}
            getFile={getCompanyLogoImg}
            control={organizationControl}
            getTypeOfBusinessValue={getTypeOfBusinessValue}
          />
        ) : activeStepName === ProcessFormNames.SUBSCRIPTIONPLAN ? (
          <SubscriptionPlanBoxes
            withTitle
            className={styles.container__cards}
            activeCardForSend={activeCardForSend}
            setActiveCardForSend={setActiveCardForSend}
            callSubscriptionWithFree={callSubscriptionWithFree}
          />
        ) : activeStepName === ProcessFormNames.PAYMENT ? (
          <Payment
            control={paymentControl}
            errors={paymentError}
            handleCardValid={handleCardChange}
          />
        ) : activeStepName === ProcessFormNames.PAYMENT_ONBOARDING ? (
          <PaymentOnboarding
            control={PaymentOnboardingControl}
            errors={PaymentOnboardingError}
            handleClick={handleClick}
            handleSkip={handleSkipPaymentOnboarding}
            getFileFront={getDocumentFront}
            getFileLast={getDocumentLast}
          />
        ) : (
          <InviteUsers control={inviteUsersForm} errors={errors} />
        )}
      </Box>
      <Box className={styles.container__footer}>
        {!latestProcess && (
          <Button
            maxWidth='88px'
            borderRadius='4px'
            padding='12px 16px'
            isUppercase={false}
            onClick={handleBack}
            className={classNames(styles.container__footer__back, {
              [styles.container__footer__back_invite]:
                activeStepName === ProcessFormNames.INVITEUSERS,
            })}
            textClassName={styles.container__footer__text}
          >
            {prevText}
          </Button>
        )}

        {latestProcess && (
          <Button
            maxWidth='78px'
            minWidth='78px'
            borderRadius='4px'
            padding='12px 16px'
            isUppercase={false}
            onClick={handleSkip}
            className={classNames(styles.container__footer__back)}
            textClassName={styles.container__footer__text}
            disabled={activeStep === processFormValues.length}
          >
            Skip
          </Button>
        )}
        <Button
          type='submit'
          maxWidth='78px'
          minWidth='78px'
          borderRadius='4px'
          padding='12px 16px'
          isUppercase={false}
          className={buttonClasses}
          // disabled={disableButtonCondition}
          textClassName={styles.container__footer__text}
        >
          Next
        </Button>
      </Box>
    </form>
  );
};

export default ProcessForm;
