import { useEffect } from 'react';
import { Box } from '@mui/material';
import classNames from 'classnames';

import { useAppDispatch } from 'hooks';
import { CustomTypography, EmployeeDropdown } from 'components';
import { getUsersByRole } from 'store/slices/authSlice/authThunks';

import styles from './PermissionsItem.module.scss';
import { TPermissionItem } from './types';

const PermissionsItem: React.FC<TPermissionItem> = ({ role, idx }) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (role?.id) {
      dispatch(getUsersByRole(role?.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role?.id]);

  const filteredRole =
    role?.role === 'Allow access to Documentation and Reports'
      ? 'Allow access to Documentation'
      : role?.role;

  const containerClasses = classNames(styles.container, {
    [styles.container__even]: idx % 2 === 0,
  });

  return (
    <Box className={containerClasses}>
      <div className={styles.container__content}>
        <CustomTypography className={styles.container__content__title} fontWeight={500}>
          {filteredRole}
        </CustomTypography>
      </div>
      <EmployeeDropdown role={role} />
    </Box>
  );
};

export default PermissionsItem;
