import { FC, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';

import { DocumentIcon } from 'assets/icons';
import { getDocumentTypes } from 'store/thunks';
import { convertToMMDDYYYY } from 'utils/formatDate';
import { useAppDispatch, useAppSelector } from 'hooks';
import { DocumentCard, DocumentsHeader } from 'containers';
import {
  documentTypesSelector,
  documentTypesLimitSelector,
  documentTypesOffsetSelector,
} from 'store/slices/documentsSlice/selectors';
import { EmptyTitles } from 'constants/EmptyTitles';
import { SortByEnums } from 'store/slices/documentsSlice/types';
import { BrowserStorageKeys, BrowserStorageService } from 'services';
import { CustomDataGrid, EmptyTitle, ScrollLayout } from 'components';
import {
  userAllRolesLoadingSelector,
  userPermissionsSelector,
} from 'store/slices/authSlice/selectors';
import { Routes } from 'types';

import { breadCrumbOptions, documentTypes, documentsColumns, matchDocumentTypes } from './utils';
import styles from './DocumentItem.module.scss';

import type { TGeneratedDocument } from './types';

const documentsView = BrowserStorageService.get(BrowserStorageKeys.DocumentsView, {
  session: true,
});

const DocumentItem: FC = () => {
  const { name, id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { isAccessToDocumentationAndReports } = useAppSelector(userPermissionsSelector);
  const rolesLoading = useAppSelector(userAllRolesLoadingSelector);

  const documents = useAppSelector(documentTypesSelector);
  const documentsLimit = useAppSelector(documentTypesLimitSelector);
  const documentsOffset = useAppSelector(documentTypesOffsetSelector);

  const documentsViewValue = documentsView ? JSON.parse(documentsView) : false;

  const [asc, setAsc] = useState<boolean>(false);
  const [newSortBy, setNewSortBy] = useState<string | null>(null);
  const [isViewInGrid, setIsViewInGrid] = useState<boolean>(documentsViewValue);

  useEffect(() => {
    if (documentsViewValue) {
      setIsViewInGrid(documentsViewValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderOptions = breadCrumbOptions(name as string);

  const handleRowClick = (section: string) => navigate(`/documents/${name}/${id}/${section}`);

  const handleColumnHeaderClick = (column: GridColDef) => {
    setNewSortBy(column.field);
    setAsc((prev) => !prev);
  };

  const matchedDocuments = matchDocumentTypes({ documents, documentTypes });

  const renderRows = matchedDocuments?.map((element: TGeneratedDocument, idx: number) => {
    return {
      id: idx,
      name: element.label,
      documentName: (
        <div className={styles.container__name}>
          <DocumentIcon width={24} height={24} />
          <a>{element.label}</a>
        </div>
      ),
      last_modified: element?.last_modified
        ? convertToMMDDYYYY(element?.last_modified as string)
        : '--',
      size: `${Math.ceil(element.size / 1024)} KB` || '0 KB',
      isMatch: element.matched,
      section: element.value,
    };
  });

  const gridOnRowClick = ({ row }: any) => {
    if (row.isMatch) {
      handleRowClick(row.section);
    }
  };

  const renderColumns: GridColDef[] = documentsColumns?.map((column) => {
    const isColumnSorted = newSortBy === column.field;

    const headerClasses = classNames(
      { [styles.activeSortHeader]: !asc },
      {
        [styles.activeSortHeader_asc]: asc,
      },
    );

    return {
      ...column,
      hideSortIcons: true,
      disableColumnMenu: true,
      disableColumnSelector: true,
      sortable: true,
      headerClassName: isColumnSorted ? headerClasses : '',
    };
  });

  const renderDocumentsCards = renderRows?.map((document, idx) => (
    <DocumentCard
      id={id}
      key={idx}
      itemName={name}
      document={document.name}
      section={document.section}
    />
  ));

  useEffect(() => {
    dispatch(
      getDocumentTypes({
        id: id as string,
        options: {
          direction: 'ASC',
          limit: documentsLimit,
          offset: documentsOffset,
          order_by: SortByEnums.SIZE,
        },
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isAccessToDocumentationAndReports && !rolesLoading) {
    return <Navigate replace to={Routes.ActivityFeed} />;
  }

  return (
    <Box className={styles.container}>
      <DocumentsHeader
        breadCrumbOptions={renderOptions}
        isViewInGrid={isViewInGrid}
        setIsViewInGrid={setIsViewInGrid}
      />

      {isViewInGrid ? (
        renderDocumentsCards?.length ? (
          <Box className={styles.container__cards}>
            <Box className={styles.container__cards__content}>{renderDocumentsCards}</Box>
          </Box>
        ) : (
          <EmptyTitle title='No Documents order documents added yet' />
        )
      ) : (
        <ScrollLayout>
          <CustomDataGrid
            rowHeight={77}
            headerHeight={52}
            rows={renderRows}
            columns={renderColumns}
            onRowClick={gridOnRowClick}
            emptyTitle={EmptyTitles.Document_Items}
            onColumnHeaderClick={handleColumnHeaderClick}
            sortModel={[{ field: newSortBy || 'size', sort: asc ? 'asc' : 'desc' }]}
          />
        </ScrollLayout>
      )}
    </Box>
  );
};

export default DocumentItem;
