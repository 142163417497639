export type ThemeMode = 'light';
export type ThemeColors = PaletteColors;

export const colorPalette = {
  c0: '#0091FF',
};

export type Theme = {
  breakpoints: {
    wide: number;
    wideL: number;
    tablet: number;
    mobile: number;
    tabletM: number;
    tabletL: number;
    desktop: number;
  };
  colors: {
    [key in ThemeMode]: ThemeColors;
  };
  colorPalette: Record<string, string>;
};

export type CurrentTheme = Omit<Theme, 'colors'> & { colors: ThemeColors };

type PaletteColors = {
  white: string;
  black: string;
  gray80: string;
  blue100: string;
  blue200: string;
  gray100: string;
  sapphire: string;
  pastelSky: string;
  paleSilver: string;
  primaryBlue: string;
  secondaryBlue: string;
  deepCharcoalBlue: string;
};

const defaultColors = Object.freeze<PaletteColors>({
  black: '#000',
  gray80: '#CCC',
  white: '#FFFFFF',
  blue100: '#2067DD',
  gray100: '#E9E9EA',
  blue200: '#D2E1F9',
  sapphire: '#2067DD',
  pastelSky: '#D2E1F9',
  paleSilver: '#e6e6e6',
  primaryBlue: '#2067DD',
  secondaryBlue: '##D2E1F9',
  deepCharcoalBlue: '#222222B2',
});

const withSpecialColors = (palette: PaletteColors): ThemeColors => {
  return {
    ...palette,
  };
};

export const CustomTheme = Object.freeze<Theme>({
  breakpoints: {
    wide: Number(1200),
    wideL: Number(1440),
    mobile: Number(375),
    tablet: Number(768),
    tabletM: Number(576),
    tabletL: Number(992),
    desktop: Number(1024),
  },

  colors: {
    light: withSpecialColors(defaultColors),
  },

  colorPalette,
});
