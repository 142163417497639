import { FC, useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';

import { useImageLoading } from 'hooks';

import type { TImgWithDefault } from './types';

const ImgWithDefault: FC<TImgWithDefault> = ({
  src,
  style,
  fallback,
  className,
  imgStyles,
  containerClassName,
}) => {
  const [imgSrc, setImgSrc] = useState(src);

  const { imageLoaded, imageError } = useImageLoading({ imageUrl: src });

  useEffect(() => {
    if (imageError) {
      setImgSrc(fallback);
    }
  }, [fallback, imageError]);

  useEffect(() => {
    setImgSrc(src);
  }, [src]);

  const handleImageError = () => {
    setImgSrc(fallback);
  };

  return (
    <div
      style={{ ...style, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      className={containerClassName}
    >
      {!imageLoaded && <CircularProgress sx={{ width: '5px', height: '5px' }} />}
      <img
        style={{ ...imgStyles, display: !imageLoaded ? 'none' : 'flex', height: '100%' }}
        src={imgSrc}
        className={className}
        onError={handleImageError}
      />
    </div>
  );
};

export default ImgWithDefault;
