import { Checkbox, CheckboxProps, FormControlLabel } from '@mui/material';

import { CheckboxChecked } from 'assets/icons';

import { sx } from './styles';

type TCustomCheckboxProps = CheckboxProps & { label: string };

const CustomCheckbox: React.FC<TCustomCheckboxProps> = ({ label, ...props }) => (
  <FormControlLabel
    sx={sx}
    label={label}
    control={<Checkbox checkedIcon={<CheckboxChecked />} {...props} sx={{ color: '#CCCCCC' }} />}
  />
);

export default CustomCheckbox;
