import { FC, useMemo } from 'react';
import { Box } from '@mui/material';
import classNames from 'classnames';

import { Button, CustomTypography } from 'components';
import { BorderSquareIcon } from 'assets/icons';
import { Colors } from 'types';

import styles from './DrawerHeader.module.scss';

import type { TDrawerHeaderProps } from './types';

const DrawerHeader: FC<TDrawerHeaderProps> = ({
  title,
  onClose,
  subtitle,
  titleClassName,
  inCenter = true,
  subtitleClassName,

  inEditMode = false,
  headerContainerClassName,
  icon = BorderSquareIcon,
}) => {
  const titleClasses = classNames(styles.container__title, titleClassName);
  const titleClassesSecond = classNames(styles.container__head__title, titleClassName);
  const subtitleClasses = classNames(styles.container__subtitle, subtitleClassName);
  const containerClassName = classNames(styles.container, headerContainerClassName);

  const IconComponent = useMemo(() => icon as React.FC<React.SVGProps<SVGSVGElement>>, [icon]);

  return (
    <Box className={containerClassName}>
      {inCenter && <div />}
      {!subtitle ? (
        <Box className={titleClasses}> {title}</Box>
      ) : (
        <Box className={styles.container__head}>
          <Box className={titleClassesSecond}> {title}</Box>
          <CustomTypography color={Colors.MIDNIGHT_CHARCOAL} className={subtitleClasses}>
            {subtitle}
          </CustomTypography>
        </Box>
      )}

      {onClose && !inEditMode ? (
        <IconComponent
          className={styles.container__close}
          width={24}
          height={24}
          onClick={onClose}
        />
      ) : (
        <Box className={styles.container__options}>
          <Button
            variant='text'
            color={Colors.LIGHT_GRAY}
            className={styles.container__options}
            onClick={onClose}
          >
            CANCEL
          </Button>
          <Button className={styles.container__options} backgroundColor={Colors.PALE_BLUE}>
            SAVE
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default DrawerHeader;
