import { Box, Checkbox } from '@mui/material';
import { FC } from 'react';

import { CheckedIcon, UncheckedIcon } from 'assets/icons';
import { CustomTypography } from 'components';

import styles from '../ShareDropDown.module.scss';

import { TSharedCatalogUserItemProps } from './types';

const SharedCatalogUserItem: FC<TSharedCatalogUserItemProps> = ({
  item,
  selectedUsersList,
  handleCheckboxChange,
}) => {
  return (
    <Box className={styles.container__content__list__user}>
      <Checkbox
        icon={<UncheckedIcon />}
        checkedIcon={<CheckedIcon />}
        onChange={() => handleCheckboxChange(item)}
        checked={selectedUsersList?.includes(item?.user_id as number)}
      />
      <CustomTypography className={styles.container__content__list__user__name}>
        {item?.name}
      </CustomTypography>
    </Box>
  );
};

export default SharedCatalogUserItem;
