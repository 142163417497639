export const sxStyles = {
  '& .MuiOutlinedInput-root': {
    padding: '10px 16px !important',
  },

  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },

  '& .MuiChip-root': {
    '& > svg': {
      minWidth: '16px',
      maxWidth: '16px',
    },
  },
};
