import { combineReducers } from 'redux';

import authSlice from './authSlice';
import rfqsSlice from './rfqsSlice';
import rolesSlice from './rolesSlice';
import teamsSlice from './teamsSlice';
import searchSlice from './searchSlice';
import catalogSlice from './catalogSlice';
import landingSlice from './landingSlice';
import optionsSlice from './optionsSlice';
import invoicesSlice from './invoicesSlice';
import discountSlice from './discountSlice';
import documentsSlice from './documentsSlice';
import estimatesSlice from './estimatesSlice';
import agreementSlice from './agreementSlice';
import inventorySlice from './inventorySlice';
import workOrderSlice from './workOrderSlice';
import customersSlice from './customersSlices';
import invitationSlice from './invitationSlice';
import liveStreamSlice from './liveStreamSlice';
import activitiesSlices from './activitiesSlices';
import subscriptionSlice from './subscriptionSlice';
import requisitionSlices from './requisitionSlices';
import organizationsSlice from './organizationsSlice';
import purchaseOrderSlice from './purchaseOrderSlice';
import transactionSlice from './transactionSlice';
import scheduleSlice from './scheduleSlice';

export const rootReducer = combineReducers({
  auth: authSlice,
  rfqs: rfqsSlice,
  roles: rolesSlice,
  teams: teamsSlice,
  search: searchSlice,
  landing: landingSlice,
  catalog: catalogSlice,
  options: optionsSlice,
  invoices: invoicesSlice,
  discounts: discountSlice,
  scheduling: scheduleSlice,
  inventory: inventorySlice,
  estimates: estimatesSlice,
  agreement: agreementSlice,
  customers: customersSlice,
  documents: documentsSlice,
  workorders: workOrderSlice,
  livestream: liveStreamSlice,
  activities: activitiesSlices,
  invitations: invitationSlice,
  requisition: requisitionSlices,
  subscriptions: subscriptionSlice,
  organizations: organizationsSlice,
  purchaseOrder: purchaseOrderSlice,
  transactions: transactionSlice,
});
