export enum LocalForageKeys {
  Mode = 'mode',
  Role = 'role',
  Scroll = 'scroll',
  Language = 'i18nextLng',
  GoogleToken = 'googleToken',
  AccessToken = 'accessToken',
  CurrentAmount = 'currentAmount',
  RefreshToken = 'refreshToken',
  RemoteStream = 'remoteStream',
  LocalStream = 'localStream',
  CapturedThumb = 'capturedThumb',
  InProcessForm = 'inProcessForm',
  IsInvitedUser = 'isInvitedUser',
  SubscriptionId = 'subscriptionId',
  IsFirstTimeUser = 'isFirstTimeUser',
  StreamVideoFiles = 'streamVideoFiles',
  InvitationToken = 'invitationToken',
  CurrentUserEmail = 'CurrentUserEmail',
  ProcessFormValue = 'processFormValue',
  StripeClientSecret = 'stripeClientSecret',
  ActiveSettingsTitle = 'activeSettingsTitle',
  currentLiveStreamId = 'currentLiveStreamId',
  CurrentOrganizationId = 'currentOrganizationId',
  currentLiveStreamToken = 'currentLiveStreamToken',
  HaveCreatedOrganization = 'haveCreatedOrganization',
  InvitedOrganizationName = 'invitedOrganizationName',
  subscriptionClientSecret = 'subscriptionClientSecret',
  CurrentCreatedCatalogImg = 'CurrentCreatedCatalogImg',
  ProcessFormFirstStepData = 'ProcessFormFirstStepData',
  ProcessFormSecondStepData = 'ProcessFormSecondStepData',
  CurrentCreatedInventoryImg = 'CurrentCreatedInventoryImg',
}

export type BrowserStorageOptions = {
  session: boolean;
};
