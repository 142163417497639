const addressGenerator = (
  address: string | null,
  city: string | null,
  state: string | null,
  postalCode: string | null,
) => {
  postalCode = postalCode || '';
  city = city || '';
  state = state || '';
  address = address || '';

  let addressString = '';

  if (address) {
    addressString += addressString ? `, ${address}` : address;
  }

  if (city) {
    addressString += addressString ? `, ${city}` : city;
  }

  if (state) {
    addressString += addressString ? `, ${state}` : state;
  }

  if (postalCode) {
    addressString += addressString ? `, ${postalCode}` : postalCode;
  }

  return addressString;
};

export default addressGenerator;
