import ErrorIcon from '@mui/icons-material/Error';
import { FC } from 'react';

import { CustomizedTooltip } from 'components';
import { capitalizeFirstLetter } from 'utils/capitalizeText';

import styles from './ErrorMessage.module.scss';

import type { TErrorMessageProps } from './types';

const ErrorMessage: FC<TErrorMessageProps> = ({ message, style }) => (
  <CustomizedTooltip title={capitalizeFirstLetter(message || '')}>
    <div className={styles.error} style={style}>
      <ErrorIcon sx={{ width: '15px', height: '15px' }} />
    </div>
  </CustomizedTooltip>
);

export default ErrorMessage;
