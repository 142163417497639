import { useEffect } from 'react';

const usePageReloadHandler = (callback?: () => void) => {
  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      // Call the provided callback before the page is reloaded
      callback?.();

      // Standard-compliant browsers
      if ('Notification' in window && Notification.permission === 'default') {
        Notification.requestPermission().then((permission) => {
          if (permission === 'granted') {
            new Notification('Are you sure you want to leave?', {
              body: 'Changes you made may not be saved.',
            });
          }
        });
      }

      // For some older browsers
      const message = 'Are you sure you want to leave?';
      event.returnValue = message; // Standard for most browsers
      return message; // For some older browsers
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [callback]);
};

export default usePageReloadHandler;
