import { RootState } from 'types';

// Inventory
export const foundedInventoriesSelector = (state: RootState) => state.search.foundedInventories;

export const foundedInventoriesLoadSelector = (state: RootState) =>
  state.search.foundedInventoriesLoading;

export const foundedInventoriesErrorSelector = (state: RootState) =>
  state.search.foundedInventoriesError;

// Catalog
export const searchSliceSelector = (state: RootState) => state.search;

export const foundedCatalogsSelector = (state: RootState) => state.search.foundedCatalogs;

export const foundedCatalogsLoadSelector = (state: RootState) =>
  state.search.foundedCatalogsLoading;

export const foundedCatalogsErrorSelector = (state: RootState) => state.search.foundedCatalogsError;

// Estimates
export const foundedEstimatesSelector = (state: RootState) => state.search.foundedEstimates;

export const foundedEstimatesLoadSelector = (state: RootState) =>
  state.search.foundedEstimatesLoading;

export const foundedEstimatesErrorSelector = (state: RootState) =>
  state.search.foundedEstimatesError;

// PurchaseRequisitions
export const foundedPurchaseRequisitionsSelector = (state: RootState) =>
  state.search.foundedPurchaseRequisitions;

export const foundedPurchaseRequisitionsLoadingLoadSelector = (state: RootState) =>
  state.search.foundedPurchaseRequisitionsLoading;

export const foundedPurchaseRequisitionsErrorSelector = (state: RootState) =>
  state.search.foundedPurchaseRequisitionsError;

// PurchaseOrder
export const foundedPurchaseOrderSelector = (state: RootState) => state.search.foundedPurchaseOrder;

export const foundedPurchaseOrderLoadingLoadSelector = (state: RootState) =>
  state.search.foundedPurchaseOrderLoading;

export const foundedPurchaseOrderErrorSelector = (state: RootState) =>
  state.search.foundedPurchaseOrderError;

// Agreement
export const foundedAgreementSelector = (state: RootState) => state.search.foundedAgreement;

export const foundedAgreementLoadingLoadSelector = (state: RootState) =>
  state.search.foundedAgreementLoading;

export const foundedAgreementErrorSelector = (state: RootState) =>
  state.search.foundedAgreementError;

export const foundedWorkOrderLoadingLoadSelector = (state: RootState) =>
  state.search.foundedWorkOrderLoading;

export const foundedWorkOrderErrorSelector = (state: RootState) =>
  state.search.foundedWorkOrderError;

// Customer
export const foundedCustomerSelector = (state: RootState) => state.search.foundedCustomer;

export const foundedCustomerLoadingLoadSelector = (state: RootState) =>
  state.search.foundedCustomerLoading;

export const foundedCustomerErrorSelector = (state: RootState) => state.search.foundedCustomerError;

// Invoices
export const foundedInvoicesSelector = (state: RootState) => state.search.foundedInvoices;

export const foundedInvoicesLoadingLoadSelector = (state: RootState) =>
  state.search.foundedInvoicesLoading;

export const foundedInvoicesErrorSelector = (state: RootState) => state.search.foundedInvoicesError;

// Users
export const foundedUsersSelector = (state: RootState) => state.search.foundedUsers;

export const foundedUsersLoadingLoadSelector = (state: RootState) =>
  state.search.foundedUsersLoading;

export const foundedUsersErrorSelector = (state: RootState) => state.search.foundedUsersError;
