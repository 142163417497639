import { Routes } from 'types';

import { TBreadCrumpStepsOptions } from './types';

const breadCrumpSteps: TBreadCrumpStepsOptions = {
  inventory: [
    { id: 1, goTo: Routes.Home, label: 'All Invetory' },
    // { id: 2, goTo: Routes.Error, label: 'View RFQs' },
  ],
  purchase: [
    { id: 1, goTo: Routes.Home, label: 'All purchasing orders' },
    { id: 2, goTo: Routes.Error, label: 'Purchase order name' },
  ],
};

export default breadCrumpSteps;
