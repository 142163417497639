import { useEffect, useRef, useState } from 'react';
import { v4 } from 'uuid';
import moment from 'moment';
import { Box } from '@mui/material';
import ReactQuill, { Quill } from 'react-quill';

import { Colors } from 'types';
import { ScreenVoice } from 'assets/audio';
import { useAppDispatch, useAppSelector } from 'hooks';
import { captureScreenshot } from 'containers/LiveStream/utils';
import { BorderSquareIcon, CameraBlueIcon } from 'assets/icons';
import { userDataSelector } from 'store/slices/authSlice/selectors';
import StreamNote from 'components/views/StreamVideoNotes/StreamNote/StreamNote';
import { addNoteToStream, getLiveStreamNotices } from 'store/slices/liveStreamSlice/thunks';
import { liveStreamSelector, myCurrentStreamSelector } from 'store/slices/liveStreamSlice/selector';

import { Button } from '..';
import 'react-quill/dist/quill.snow.css';

import { TAddNoticeModal, TFileResponse } from './types';
import './styles.scss';
import styles from './CreateLiveAddNoteModal.module.scss';
import { formats, modules } from './CreateLiveAddNoteModal.utils';

const CreateLiveAddNoteModal: React.FC<TAddNoticeModal> = ({
  onClose,
  contentRef,
  submitBtnText,
  cancelBtnText,
}) => {
  const dispatch = useAppDispatch();
  const userInfo = useAppSelector(userDataSelector);
  const currentStream = useAppSelector(myCurrentStreamSelector);
  const { liveStreamNotices } = useAppSelector(liveStreamSelector);
  const [capturedFiles, setCapturedFiles] = useState<TFileResponse[]>([]);

  const audioRef = useRef<HTMLAudioElement | null>(null);

  const myId = currentStream?.livestream?.members?.find(
    (member) => member?.user_id === userInfo?.id,
  );

  const [inputValue, setInputValue] = useState<string>('');

  const isDisabled = !inputValue?.trim()?.length || inputValue === '<p><br></p>';

  const currentTime = moment().format('MMMM D, YYYY [at] h:mmA');

  const Link = Quill.import('formats/link');
  // Override the existing property on the Quill global object and add custom protocols
  Link.PROTOCOL_WHITELIST = ['http', 'https', 'mailto', 'tel', 'radar', 'rdar', 'smb', 'sms'];

  class CustomLinkSanitizer extends Link {
    static sanitize(url: string) {
      const sanitizedUrl = super.sanitize(url);

      if (!sanitizedUrl || sanitizedUrl === 'about:blank') return sanitizedUrl;

      const hasWhitelistedProtocol = this.PROTOCOL_WHITELIST.some(function (protocol: any) {
        return sanitizedUrl.startsWith(protocol);
      });

      if (hasWhitelistedProtocol) return sanitizedUrl;

      return `http://${sanitizedUrl}`;
    }
  }

  Quill.register(CustomLinkSanitizer, true);

  useEffect(() => {
    dispatch(getLiveStreamNotices({ id: currentStream?.livestream?.id as number }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStream]);

  const handleAddNote = async () => {
    if (inputValue?.trim()?.length && currentStream?.livestream?.id) {
      const newData = new FormData();
      newData.append('content', inputValue);
      newData.append('member_id', String(myId?.id));
      newData.append('livestream_id', String(currentStream?.livestream?.id));

      if (capturedFiles) {
        capturedFiles?.forEach(({ file }) => newData.append('medias', file as File));
      }

      await dispatch(addNoteToStream(newData));

      await dispatch(getLiveStreamNotices({ id: currentStream?.livestream?.id as number }));

      setInputValue('');
      setCapturedFiles([]);
    }
  };

  const noticeItem = liveStreamNotices?.data?.map((note, idx) => {
    const isLast = idx === liveStreamNotices?.data?.length - 1;

    return <StreamNote isLast={isLast} key={note?.id} note={note} />;
  });

  const handleCaptureScreen = async () => {
    audioRef?.current?.play();
    const response = await captureScreenshot(contentRef);

    const newFile = {
      id: v4(),
      file: response,
    };

    if (response) {
      setCapturedFiles([...capturedFiles, newFile]);
    }
  };

  const imgFiles = capturedFiles?.map(({ id, file }) => (
    <img src={URL.createObjectURL(file as File)} key={id} />
  ));

  return (
    <div className={styles.container}>
      <Box className={styles.container__head}>
        <Box className={styles.container__head__content}>
          <p className={styles.container__time}>{currentTime}</p>
        </Box>
        <BorderSquareIcon className={styles.container__close} onClick={onClose} />
      </Box>
      <Box className={styles.container__wrapper}>
        <Box className={styles.container__messages}>{noticeItem}</Box>
      </Box>
      <div className={styles.container__main}>
        <div className={styles.container_content}>
          <ReactQuill
            theme='snow'
            formats={formats}
            modules={modules}
            value={inputValue}
            onChange={setInputValue}
            className='container__quill'
            placeholder='Type something...'
          />
          <audio style={{ visibility: 'hidden' }} ref={audioRef} src={ScreenVoice}></audio>
        </div>
        <div className={styles.container__images}>{imgFiles}</div>
        <div className={styles.container_footer}>
          <Button
            color={Colors.SAPPHIRE}
            borderRadius='5px'
            isUppercase={false}
            backgroundColor={Colors.PALE_BLUE}
            onClick={handleCaptureScreen}
            startIcon={<CameraBlueIcon />}
            className={styles.container_footer_submit_cam}
          >
            Capture Screen
          </Button>
          <div className={styles.container_footer__next}>
            <Button
              className={styles.container_footer__next__cancel}
              onClick={onClose}
              borderRadius='5px'
              color={Colors.GRAY}
            >
              {cancelBtnText}
            </Button>

            <Button
              color={Colors.SAPPHIRE}
              borderRadius='5px'
              disabled={isDisabled}
              onClick={handleAddNote}
              backgroundColor={Colors.PALE_BLUE}
              className={styles.container_footer_submit}
            >
              {submitBtnText}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateLiveAddNoteModal;
