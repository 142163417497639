export type TPurchaseOrdersParams = {
  limit: number;
  offset: number;
} & Partial<{
  sort_by: PurchaseOrderSortBy | null;
  asc: boolean;
}>;

export enum PurchaseOrderSortBy {
  ID = 'id',
  STATUS = 'status',
  VENDOR = 'vendor',
  TOTAL = 'grand_total',
  CREATED = 'created_by_name',
}

export type TInitialState = {
  purchaseOrderAsc: boolean;
  allPurchaseOrdersLimit: number;
  allPurchaseOrdersOffset: number;
  purchaseOrderItemsLoad: boolean;
  allPurchaseOrdersLoading: boolean;
  purchaseOrderDataByIdLoad: boolean;
  allPurchaseOrders: TPurchaseOrdersReturnType;
  purchaseOrderItems: TPurchaseOrderReturnType;
  allPurchaseOrdersError: null | string | Error;
  purchaseOrderItemsError: null | string | Error;
  purchaseOrderDataById: TPurchaseOrderData | null;
  purchaseOrderDataByIdError: null | string | Error;
};

export type TPurchaseOrdersReturnType = {
  data: TPurchaseOrder[];
  total_count: number;
};

export type TPurchaseOrder = {
  id: number;
  uuid: string;
  vendor: string;
  vendor_id: number;
  updated_at: string;
  created_at: string;
  created_by: number;
  description: string;
  created_by_name: string;
  grand_total: number | null;
  status: 'pending' | 'confirmed' | 'cancelled' | 'submitted';
};

export type TPurchaseOrderItem = {
  id: number;
  name: string;
  part_id: number;
  quantity: number;
  unit_price: number;
  description: string;
  image_url_id: string;
  updated_by_name: string;
  created_at: Date | string;
  updated_at: Date | string;
};

export type TPurchaseOrderReturnType = {
  total_count: number;
  data: TPurchaseOrderItem[];
};

export type TUpdatePurchaseOrderItem = {
  id: number;
  quantity: number;
  unit_price: number;
  description: string;
};

export type TUpdatePurchaseOrderItemReturnType = {
  id: number;
  fromEditOrder?: boolean;
  options: TUpdatePurchaseOrderItem;
};

export type TPurchaseOrderData = {
  id: number;
  uuid: string;
  vendor_id: number;
  created_by: number;
  description: string;
  grand_total: number;
  vendor_dtl: TVendorDTL;
  created_at: Date | string;
  updated_at: Date | string;
  items: TPurchaseOrderReturnType;
  status: 'draft' | 'submitted' | 'recieved';
};

export type TVendorDTL = {
  id: number;
  name: string;
  phone: string;
  email: string;
  address: string;
  created_at: string;
  created_by: number;
  updated_at: string;
  vendor_link: string;
  number: number | null;
  contact_person: string;
};

export type TPurchaseOrderCreateBody = {
  vendor_id: number;
  description: string | null;
  items: TPurchaseOrderCreateBodyItems;
};

export type TPurchaseOrderCreateBodyItems = {
  part_id: number;
  quantity: number;
  unit_price: number;
};

export type TPurchaseOrderItemEdit = {
  id: number;
  vendor_id: number;
  description: string;
  status: 'draft' | 'recieved' | 'submitted';
};

export type TPurchaseOrderItemEditReturnType = {
  id: number;
  options: TPurchaseOrderItemEdit;
};

export type TOrderAction = {
  id: number;
} & Partial<{
  uuid: string;
}>;
