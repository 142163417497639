import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  CatalogToastMessages,
  InventoryItemToastMessages,
  VendorToastMessages,
} from 'constants/ToastMessages';
import { inventoryApi, catalogApi } from 'api';
import dataStructure from 'constants/options';
import { BrowserStorageKeys, BrowserStorageService } from 'services';

import { TSearchParams } from '../searchSlice/types';

import {
  PartsSortBy,
  type TVendor,
  type TVendorUpdate,
  type TAllPartsRequest,
  type TGetVendorListParams,
  TGetExcelListParams,
} from './types';

import type { RootState } from 'types';

export const getAllParts = createAsyncThunk(
  'inventorySlice/getAllParts',
  async (options: TAllPartsRequest | undefined, { getState }) => {
    const sortByFromStorage = BrowserStorageService.get(BrowserStorageKeys.InventorySortBy, {
      session: true,
    });

    const sortByAscFromStorage = BrowserStorageService.get(BrowserStorageKeys.InventorySortByAsc, {
      session: true,
    });

    const {
      inventory: { allPartsLimit, allPartsOffset },
    } = getState() as RootState;

    const isCheck = sortByFromStorage && JSON.parse(sortByFromStorage) === '__check__';

    const sortByParam = sortByFromStorage
      ? JSON.parse(sortByFromStorage)
      : options?.sort_by
      ? JSON.parse(options?.sort_by as string)
      : PartsSortBy.NAME;

    const ascParam = sortByAscFromStorage
      ? JSON.parse(sortByAscFromStorage)
      : options?.asc || false;

    const {
      limit = allPartsLimit,
      asc = ascParam,
      offset = allPartsOffset,
      sort_by = isCheck ? PartsSortBy.NAME : sortByParam || PartsSortBy.NAME,
    } = options ?? {};

    try {
      const response = await inventoryApi.getAllPartsRequest({
        limit,
        offset,
        sort_by,
        asc,
      });

      if (Array.isArray(response.data)) {
        return dataStructure;
      }

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const getPartById = createAsyncThunk('inventorySlice/getPartById', async (id: number) => {
  try {
    const response = await inventoryApi.getPartByPartIdRequest(id);

    return response.data;
  } catch (error) {
    const Error = error as AxiosError;
    throw Error;
  }
});

export const getOpenedSnapshotData = createAsyncThunk(
  'inventorySlice/getOpenedSnapshotData',
  async (id: number) => {
    try {
      const response = await inventoryApi.getSnapShotByPartIdOpenedRequest(id);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const getPartHistory = createAsyncThunk(
  'inventorySlice/getPartHistory',
  async (id: number) => {
    try {
      const response = await inventoryApi.getPartsHistoryByPartIdRequest(id);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const updatePartBySnapShotId = createAsyncThunk(
  'inventorySlice/updatePartBySnapShotId',
  async (id: number, { dispatch, getState }) => {
    const {
      inventory: { currentPartById },
    } = getState() as RootState;
    try {
      const response = await inventoryApi.updatePartBySnapShotIdRequest(id);

      toast.success(InventoryItemToastMessages.INVENTORY_ITEM_EDIT_APPROVED);

      dispatch(getAllParts());
      dispatch(getPartHistory(currentPartById?.id as number));
      dispatch(getOpenedSnapshotData(currentPartById?.id as number));

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const getSnapShotDecline = createAsyncThunk(
  'inventorySlice/getSnapShotDecline',
  async (id: number, { dispatch, getState }) => {
    const {
      inventory: { currentPartById, allPartsLimit, allPartsOffset },
    } = getState() as RootState;

    try {
      const response = await inventoryApi.getSnapShotDeclineRequest(id);

      toast.success(InventoryItemToastMessages.INVENTORY_ITEM_EDIT_DECLINED);

      dispatch(
        getAllParts({
          limit: allPartsLimit,
          offset: allPartsOffset,
          asc: true,
          sort_by: PartsSortBy.NAME,
        }),
      );
      dispatch(getPartHistory(currentPartById?.id as number));
      dispatch(getOpenedSnapshotData(currentPartById?.id as number));

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const removePartById = createAsyncThunk(
  'inventorySlice/removePartById',
  async (id: number[], { dispatch, getState }) => {
    const start = toast.loading(InventoryItemToastMessages.INVENTORY_ITEM_REMOVAL_START);

    const {
      inventory: { allPartsLimit },
    } = getState() as RootState;

    try {
      const response = await inventoryApi.removePartByIdRequest(id);

      toast.update(start, {
        render: InventoryItemToastMessages.INVENTORY_ITEM_REMOVAL_SUCCESS,
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });

      dispatch(
        getAllParts({
          limit: allPartsLimit,
          offset: 0,
          asc: true,
          sort_by: PartsSortBy.NAME,
        }),
      );

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      toast.update(start, {
        render:
          String(Error?.response?.data) ||
          InventoryItemToastMessages.INVENTORY_ITEM_CREATION_FAILURE,
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });

      throw Error;
    }
  },
);

export const createPart = createAsyncThunk(
  'inventorySlice/createPart',
  async (body: FormData, { dispatch, getState }) => {
    const start = toast.loading(InventoryItemToastMessages.INVENTORY_ITEM_CREATION);
    const {
      inventory: { allPartsLimit, allPartsOffset },
    } = getState() as RootState;

    try {
      const response = await inventoryApi.createPartRequest(body);

      dispatch(
        getAllParts({
          limit: allPartsLimit,
          offset: allPartsOffset,
          asc: true,
          sort_by: PartsSortBy.NAME,
        }),
      );

      toast.update(start, {
        render: InventoryItemToastMessages.INVENTORY_ITEM_CREATION_SUCCESS,
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      toast.update(start, {
        render:
          String(Error?.response?.data) ||
          InventoryItemToastMessages.INVENTORY_ITEM_CREATION_FAILURE,
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });

      throw Error;
    }
  },
);

export const getExcelList = createAsyncThunk(
  'catalogSlice/PricingTemplates',
  async (options: TGetExcelListParams | undefined, { getState }) => {
    const {
      inventory: { allExcelLimit, allExcelOffset },
    } = getState() as RootState;

    const { limit = allExcelLimit, offset = allExcelOffset, ...otherOptions } = options ?? {};

    try {
      const mergedOptions = { ...otherOptions, limit, offset };

      const response = await catalogApi.getAllExcelListRequest(mergedOptions);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const getVendorList = createAsyncThunk(
  'inventorySlice/getVendorList',
  async (options: TGetVendorListParams | undefined, { getState }) => {
    const {
      inventory: { allVendorsLimit, allVendorsOffset },
    } = getState() as RootState;

    const { limit = allVendorsLimit, offset = allVendorsOffset, ...otherOptions } = options ?? {};

    try {
      const mergedOptions = { ...otherOptions, limit, offset };

      const response = await inventoryApi.getAllVendorListRequest(mergedOptions);

      if (Array.isArray(response.data) && response.data.length === 0) {
        {
          return dataStructure;
        }
      }

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const getExcelById = createAsyncThunk('inventorySlice/getVendorById', async (id: number) => {
  try {
    const response = await inventoryApi.getExcelByIdRequest(id);

    return response.data;
  } catch (error) {
    const Error = error as AxiosError;
    throw Error;
  }
});

export const removeExcelById = createAsyncThunk(
  'catalogSlice/DeletePricingTemplateById',
  async (id: number, { dispatch }) => {
    const start = toast.loading(CatalogToastMessages.EXCEL_REMOVAL_START);
    try {
      const response = await inventoryApi.removeExcelByIdRequest(id);

      dispatch(getExcelList());

      toast.update(start, {
        render: CatalogToastMessages.EXCEL_REMOVAL_SUCCESS,
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      toast.update(start, {
        render: CatalogToastMessages.EXCEL_REMOVAL_FAILURE,
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });

      throw Error;
    }
  },
);

export const setDefaultExcelById = createAsyncThunk(
  'catalogSlice/DeletePricingTemplateById',
  async (id: number, { dispatch }) => {
    const start = toast.loading(CatalogToastMessages.EXCEL_REMOVAL_START);
    try {
      const response = await inventoryApi.setDefaultExcelByIdRequest(id);

      dispatch(getExcelList());

      toast.update(start, {
        render: CatalogToastMessages.DEFAULT_EXCEL_CREATION_SUCCESS,
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      toast.update(start, {
        render: CatalogToastMessages.DEFAULT_EXCEL_CREATION_FAILURE,
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });

      throw Error;
    }
  },
);

export const getVendorById = createAsyncThunk(
  'inventorySlice/getVendorById',
  async (id: number) => {
    try {
      const response = await inventoryApi.getVendorByIdRequest(id);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const findVendor = createAsyncThunk(
  'inventorySlice/findVendor',
  async (options: TSearchParams, { getState }) => {
    const {
      inventory: { allVendorsLimit, allVendorsOffset },
    } = getState() as RootState;

    const { limit = allVendorsLimit, offset = allVendorsOffset, query } = options ?? {};

    try {
      const response = await inventoryApi.findVendorRequest({ limit, offset, query });

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const updateVendor = createAsyncThunk(
  'inventorySlice/updateVendor',
  async (options: TVendorUpdate, { dispatch }) => {
    const start = toast.loading(VendorToastMessages.VENDOR_EDIT_START);
    try {
      const response = await inventoryApi.updateVendorRequest(options);

      toast.update(start, {
        render: VendorToastMessages.VENDOR_EDIT_SUCCESS,
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });

      dispatch(getVendorList());

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      toast.update(start, {
        render: VendorToastMessages.VENDOR_EDIT_FAILURE,
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });

      throw Error;
    }
  },
);

export const removeVendorById = createAsyncThunk(
  'inventorySlice/removeVendorById',
  async (id: number, { dispatch }) => {
    const start = toast.loading(VendorToastMessages.VENDOR_REMOVAL_START);
    try {
      const response = await inventoryApi.removeVendorByIdRequest(id);

      dispatch(getVendorList());

      toast.update(start, {
        render: VendorToastMessages.VENDOR_REMOVAL_SUCCESS,
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      toast.update(start, {
        render: VendorToastMessages.VENDOR_REMOVAL_FAILURE,
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });

      throw Error;
    }
  },
);

export const createVendor = createAsyncThunk(
  'inventorySlice/createVendor',
  async (body: TVendor, { dispatch }) => {
    const start = toast.loading(VendorToastMessages.VENDOR_CREATION);

    try {
      const response = await inventoryApi.createVendorRequest(body);

      dispatch(getVendorList());

      toast.update(start, {
        render: VendorToastMessages.VENDOR_CREATION_SUCCESS,
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      toast.update(start, {
        render: VendorToastMessages.VENDOR_CREATION_FAILURE,
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });

      throw Error;
    }
  },
);

export const createTemplate = createAsyncThunk(
  'inventorySlice/createVendor',
  async (data: FormData, { dispatch }) => {
    const start = toast.loading(CatalogToastMessages.EXCEL_CREATION);

    try {
      const response = await inventoryApi.createExcelRequest(data);

      dispatch(getExcelList());

      toast.update(start, {
        render: CatalogToastMessages.EXCEL_CREATION_SUCCESS,
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      toast.update(start, {
        render: CatalogToastMessages.EXCEL_CREATION_FAILURE,
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });

      throw Error;
    }
  },
);

export const createPartSnapShot = createAsyncThunk(
  'inventorySlice/createPartSnapShot',
  async (body: FormData, { dispatch }) => {
    const start = toast.loading(InventoryItemToastMessages.INVENTORY_ITEM_EDIT_START);

    try {
      const response = await inventoryApi.createPartSnapShotRequest(body);

      await dispatch(getAllParts());

      toast.update(start, {
        render: InventoryItemToastMessages.INVENTORY_ITEM_EDIT_SUCCESS,
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      toast.update(start, {
        render:
          String(Error?.response?.data) || InventoryItemToastMessages.INVENTORY_ITEM_EDIT_FAILURE,
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });

      throw Error;
    }
  },
);
