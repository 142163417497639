import { isEqual } from 'lodash';

type TCheckDataValueChangesParams<T> = {
  data: T[];
  watched: T[];
  itemName: string;
  checkedName: string;
};

const checkDataValueChanges = <K, T extends Record<string, K>>({
  data,
  watched,
  itemName,
  checkedName,
}: TCheckDataValueChangesParams<T>) => {
  const filteredWatched = watched?.filter((item) => !!item?.[itemName]) as T[];

  const filledData = data?.map((item) => ({ ...item, [itemName]: item?.[checkedName] })) as T[];

  const changedValues: T[] = [];

  for (let i = 0; i < filledData?.length; i++) {
    if (filledData?.[i]?.[itemName] === filteredWatched?.[i]?.[itemName]) {
      const differently = !isEqual(filledData[i], filteredWatched[i]);

      if (differently) {
        changedValues.push(filteredWatched[i]);
      }
    }
  }

  return changedValues;
};

export default checkDataValueChanges;
