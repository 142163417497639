import { type FC, useEffect, useState, useCallback, ChangeEvent } from 'react';
import { Box, IconButton } from '@mui/material';
import { GridColDef, GridEventListener } from '@mui/x-data-grid';
import { Navigate, useNavigate } from 'react-router-dom';
import classNames from 'classnames';

import { useAppDispatch, useAppSelector } from 'hooks';
import {
  allPurchaseOrdersSelector,
  allPurchaseOrdersLimitSelector,
  allPurchaseOrdersOffsetSelector,
  allPurchaseOrderAscSelector,
  allPurchaseOrdersLoadingSelector,
} from 'store/slices/purchaseOrderSlice/selectors';
import { setLimit, setPage } from 'store/slices/purchaseOrderSlice';
import { StatusEnums } from 'components/shared/StatusInfo/types';
import { generateInventoryItemNumber } from 'utils/generateInventoryItemNumber';
import { DownloadSquareIcon, EditIconOutLined, MoreIcon, TrashIcon } from 'assets/icons';
import {
  deletePurchaseOrder,
  getPurchaseOrders,
  receivePurchaseOrderItem,
  submitPurchaseOrderItem,
} from 'store/slices/purchaseOrderSlice/thunks';
import {
  StatusInfo,
  DeletePopup,
  ScrollLayout,
  CustomDataGrid,
  PurchaseOrderVendorDrawer,
  DynamicPaginationControl,
  PortalDropDown,
} from 'components';
import {
  userAllRolesLoadingSelector,
  userAllRolesSelector,
  userPermissionsSelector,
} from 'store/slices/authSlice/selectors';
import { PurchaseOrderSortBy } from 'store/slices/purchaseOrderSlice/types';
import { EmptyTitles } from 'constants/EmptyTitles';
import { Routes } from 'types';

import { columns } from './utils';
import styles from './PurchaseOrder.module.scss';
import PurchaseOrderHeader from './PurchaseOrderHeader';
import CreatePurchaserOrderDrawer from './CreatePurchaserOrderDrawer';

const PurchaseOrder: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { isAccessToInventoryEditor, isAccessToInventoryViewer } =
    useAppSelector(userPermissionsSelector);

  const userRolesLoading = useAppSelector(userAllRolesLoadingSelector);

  const haveAccessToPO = isAccessToInventoryEditor || isAccessToInventoryViewer;

  const userRoles = useAppSelector(userAllRolesSelector);
  const purchaseOrders = useAppSelector(allPurchaseOrdersSelector);
  const purchaseOrderAsc = useAppSelector(allPurchaseOrderAscSelector);
  const purchaseOrdersLimit = useAppSelector(allPurchaseOrdersLimitSelector);
  const purchaseOrdersOffset = useAppSelector(allPurchaseOrdersOffsetSelector);
  const purchaseOrdersLoading = useAppSelector(allPurchaseOrdersLoadingSelector);

  const [status, setStatus] = useState<string>('');
  const [currentPage, setCurrentPage] = useState(0);
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [currentOrder, setCurrentOrder] = useState<number | null>(null);
  const [isEditDrawerOpen, setIsEditDrawerOpen] = useState<boolean>(false);
  const [newSortBy, setNewSortBy] = useState<PurchaseOrderSortBy | null>(null);

  const handleClick = () => setIsDrawerOpen(true);
  const handleCloseDrawer = () => setIsDrawerOpen(false);

  const handleCloseEditDrawer = () => setIsEditDrawerOpen(false);

  const [isVendorDrawerOpen, setIsVendorDrawerOpen] = useState<boolean>(false);
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);

  const togglePopup = (actionStatus: string) => {
    setIsPopupOpen(!isPopupOpen);
    setStatus(actionStatus);
  };

  const openVendorDrawer = () => setIsVendorDrawerOpen(true);
  const closeVendorListedDrawer = () => setIsVendorDrawerOpen(false);

  const renderedOrders = purchaseOrders;

  const filteredRoles = userRoles.map((role) => role.role_id);

  const isViewer =
    !filteredRoles.includes(16) && !filteredRoles.includes(5) && !filteredRoles.includes(3);

  const handlePageChange = useCallback((_event: unknown, newPage: number) => {
    setCurrentPage(newPage);
    dispatch(setPage(newPage + 1));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goToRowPage = (id: string) => {
    navigate(`/product-info/inventory/purchase-order/${id}`);
  };

  const handleEvent: GridEventListener<'rowClick'> = (params) => {
    goToRowPage(params?.row?.uuid as string);
  };

  useEffect(() => {
    dispatch(
      getPurchaseOrders({
        limit: purchaseOrdersLimit,
        offset: purchaseOrdersOffset,
        sort_by: PurchaseOrderSortBy.ID,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [purchaseOrdersOffset, purchaseOrdersLimit]);

  const deleteOrder = async () => {
    if (currentOrder) {
      await dispatch(deletePurchaseOrder(currentOrder));
    }
  };

  const receivePO = () => {
    dispatch(receivePurchaseOrderItem({ id: Number(currentOrder) }));
  };

  const submitPO = () => {
    dispatch(submitPurchaseOrderItem({ id: Number(currentOrder) }));
  };

  const openConfirmation = () => {
    if (status === 'submit') {
      submitPO();
    } else if (status === 'delete') {
      deleteOrder();
    } else {
      receivePO();
    }
    togglePopup('');
  };

  const handleRowsPerPageChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    handlePageChange(null, 0);
    dispatch(setLimit(event.target.value));
  };

  const dropdownItems = [
    {
      id: 1,
      icon: <EditIconOutLined />,
      name: 'Edit',
      action: () => setIsEditDrawerOpen(true),
    },
    {
      id: 2,
      icon: <TrashIcon />,
      name: 'Delete',
      action: () => togglePopup('delete'),
    },
    {
      id: 3,
      icon: <DownloadSquareIcon className={styles.container__receive} />,
      name: 'Receive PO',
      action: () => togglePopup('receive'),
    },
    {
      id: 4,
      icon: <DownloadSquareIcon className={styles.container__send} />,
      name: 'Submit To Vendor',
      action: () => togglePopup('submit'),
    },
  ];

  const getDropdownItems = (status: string) => {
    const items = dropdownItems.filter((item) => {
      if (item.name === 'Receive PO') {
        return status !== 'draft' && status !== 'recieved';
      }

      if (item.name === 'Edit') {
        return status !== 'recieved';
      }

      return true;
    });

    return items;
  };

  const renderRows = renderedOrders?.data?.map((row) => {
    const renderNumber = generateInventoryItemNumber(Number(row.id));

    const renderCost = row.grand_total ? ` $${row.grand_total}` : 0;

    return {
      id: row.id,
      uuid: row.uuid,
      number: <a>{`PO-${renderNumber}`}</a>,
      vendor: row.vendor,
      grand_total: `${renderCost}`,
      created_by_name: row.created_by_name,
      status: <StatusInfo status={row.status as StatusEnums} title={row.status} />,
      options: !isViewer && (
        <PortalDropDown
          getSelectedId={setCurrentOrder}
          selectedId={row.id as number}
          options={getDropdownItems(row.status)}
          title={
            <IconButton className={styles.container__icon}>
              <MoreIcon />
            </IconButton>
          }
        />
      ),
    };
  });

  const handleColumnHeaderClick = (column: GridColDef) => {
    const purchaseOrderSort =
      column.field === 'number' ? PurchaseOrderSortBy.ID : (column.field as PurchaseOrderSortBy);

    setNewSortBy(purchaseOrderSort);

    dispatch(
      getPurchaseOrders({
        asc: purchaseOrderAsc,
        limit: purchaseOrdersLimit,
        sort_by: purchaseOrderSort,
        offset: purchaseOrdersOffset,
      }),
    );
  };
  const renderColumns: GridColDef[] = columns.map((column) => {
    const isColumnSorted =
      column.field === 'number' ? newSortBy === PurchaseOrderSortBy.ID : newSortBy === column.field;

    const headerClasses = classNames(
      { [styles.activeSortHeader]: !purchaseOrderAsc },
      {
        [styles.activeSortHeader_asc]: purchaseOrderAsc,
      },
    );

    return {
      ...column,
      sortable: false,
      hideSortIcons: true,
      disableColumnMenu: true,
      disableColumnSelector: true,
      headerClassName: isColumnSorted ? headerClasses : '',
    };
  });

  useEffect(() => {
    if (!purchaseOrders.total_count && currentPage) {
      setCurrentPage(currentPage - 1);
      handlePageChange(null, currentPage - 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [purchaseOrders.total_count]);

  const renderPopupTitle =
    `${status} Confirmation`.charAt(0).toUpperCase() + `${status} Confirmation`.slice(1);

  if (!haveAccessToPO && !userRolesLoading) {
    return <Navigate replace to={Routes.ActivityFeed} />;
  }

  return (
    <>
      <Box className={styles.container}>
        <PurchaseOrderHeader handleClick={handleClick} />

        <>
          <ScrollLayout>
            <CustomDataGrid
              rowHeight={64}
              headerHeight={44}
              rows={renderRows}
              columns={renderColumns}
              onRowClick={handleEvent}
              isLoading={purchaseOrdersLoading}
              linkFontFamily='CircularStdBold'
              emptyTitle={EmptyTitles.Purchase_Orders}
              onColumnHeaderClick={handleColumnHeaderClick}
            />
          </ScrollLayout>
        </>
        {!!purchaseOrders?.total_count && (
          <Box className={styles.container__footer}>
            <DynamicPaginationControl
              page={currentPage}
              onPageChange={handlePageChange}
              rowsPerPage={purchaseOrdersLimit}
              count={purchaseOrders.total_count}
              onRowsPerPageChange={handleRowsPerPageChange}
            />
          </Box>
        )}
      </Box>
      <CreatePurchaserOrderDrawer
        openVendorDrawer={openVendorDrawer}
        isOpen={isDrawerOpen}
        onClose={handleCloseDrawer}
      />
      <CreatePurchaserOrderDrawer
        inEditMode
        isOpen={isEditDrawerOpen}
        selectedOrderId={currentOrder}
        onClose={handleCloseEditDrawer}
        openVendorDrawer={openVendorDrawer}
      />
      <PurchaseOrderVendorDrawer open={isVendorDrawerOpen} handleClose={closeVendorListedDrawer} />
      <DeletePopup
        withBody
        isOpen={isPopupOpen}
        title={renderPopupTitle}
        onDelete={openConfirmation}
        onClose={() => togglePopup('')}
        body={`Please confirm to ${status} the purchase order.`}
      />
    </>
  );
};

export default PurchaseOrder;
