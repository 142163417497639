import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { authApi } from 'api';
import { BrowserStorageKeys, BrowserStorageService } from 'services';
import {
  ResendEmailToastMessages,
  PermissionUpdateToastMessages,
  DeleteInvitedUserToastMessages,
  InvitedUserUpdateToastMessages,
  ProfileInformationUpdateToastMessages,
} from 'constants/ToastMessages';
import { capitalizeFirstLetter } from 'utils';

import { getInvitedUsers } from '../organizationsSlice/thunks';

import {
  TSearch,
  TNewRole,
  TUpdateUser,
  TNewUserRole,
  TUpdateUserSettings,
  TUpdateInvitationStatus,
} from './types';

import type { RootState } from 'types';

export const signUp = createAsyncThunk('auth/signUp', async (email: string) => {
  try {
    const response = await authApi.signUpRequest(email);

    return response.data;
  } catch (error) {
    const Error = error as AxiosError;
    throw Error;
  }
});

export const signInAuth = createAsyncThunk('auth/signIn', async (email: string) => {
  try {
    const response = await authApi.signInRequest(email);

    return response.data;
  } catch (error) {
    const Error = error as AxiosError;
    throw Error;
  }
});

export const loginOAuth = createAsyncThunk(
  'auth/loginOAuth',
  async (params: FormData, thunkApi) => {
    try {
      const response = await authApi.signInOauthRequest(params);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      const haveErrorMessage =
        !!Error.response &&
        typeof Error?.response?.data === 'string' &&
        !!Error?.response?.data?.length;

      toast.error(
        haveErrorMessage
          ? capitalizeFirstLetter(Error?.response?.data as string)
          : 'Sign up unsuccessful. Please check your information and try again later.',
      );

      throw thunkApi.rejectWithValue({
        error: Error.response?.data,
      });
    }
  },
);

export const refreshToken = createAsyncThunk('authSlice/refreshToken', async () => {
  try {
    const response = await authApi.refreshTokenRequest();

    return response.data;
  } catch (error) {
    const Error = error as AxiosError;
    throw Error;
  }
});

export const getNewToken = createAsyncThunk('auth/getNewToken', async () => {
  try {
    const response = await authApi.refreshTokenRequest();

    BrowserStorageService.update(BrowserStorageKeys.AccessToken, JSON.stringify(response.data));

    BrowserStorageService.update(BrowserStorageKeys.AccessToken, JSON.stringify(response.data), {
      session: true,
    });

    return response.data.access_token || response.data;
  } catch (error) {
    const Error = error as AxiosError;
    throw Error;
  }
});

export const getCurrentUser = createAsyncThunk('auth/getUser', async (_, { dispatch }) => {
  try {
    const response = await authApi.getUserByTokenRequest();

    if (response) {
      dispatch(getCurrentUserById(response?.data?.id));
    }

    return response.data;
  } catch (error) {
    const Error = error as AxiosError;

    throw Error;
  }
});

export const getUserById = createAsyncThunk('auth/getUserById', async (id: number) => {
  try {
    const response = await authApi.getUserByIdRequest(id);

    return response.data;
  } catch (error) {
    const Error = error as AxiosError;
    throw Error;
  }
});

export const getCurrentUserById = createAsyncThunk(
  'auth/getCurrentUserById',
  async (id: number) => {
    try {
      const response = await authApi.getUserByIdRequest(id);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const getUserPermissions = createAsyncThunk('auth/getUserPermissions', async () => {
  try {
    const response = await authApi.getUserPermissionsRequest();
    return response.data;
  } catch (error) {
    const Error = error as AxiosError;
    throw Error;
  }
});

export const searchUserByQuery = createAsyncThunk(
  'auth/searchUserByQuery',
  async (params: TSearch) => {
    try {
      const response = await authApi.searchUserByQueryRequest(params);
      return response.data;
    } catch (error) {
      return {
        data: [],
        total_count: 0,
      };
    }
  },
);

export const updateUser = createAsyncThunk('auth/updateUser', async (options: TUpdateUser) => {
  const start = toast.loading(ProfileInformationUpdateToastMessages.PROFILE_UPDATE_START);

  try {
    const response = await authApi.updateUserRequest(options);

    toast.update(start, {
      render: ProfileInformationUpdateToastMessages.PROFILE_UPDATE_SUCCESS,
      type: 'success',
      isLoading: false,
      autoClose: 3000,
    });

    return response.data;
  } catch (error) {
    const Error = error as AxiosError;

    toast.update(start, {
      render: ProfileInformationUpdateToastMessages.PROFILE_UPDATE_FAILURE,
      type: 'error',
      isLoading: false,
      autoClose: 3000,
    });

    throw Error;
  }
});

export const updateImage = createAsyncThunk('auth/updateImage', async (profile_image: FormData) => {
  try {
    const response = await authApi.updateUserImage(profile_image);

    return response.data;
  } catch (error) {
    const Error = error as AxiosError;
    throw Error;
  }
});

export const getUserSettings = createAsyncThunk('auth/getUserSettings', async () => {
  try {
    const response = await authApi.getSettingsRequest();

    return response.data;
  } catch (error) {
    const Error = error as AxiosError;
    throw Error;
  }
});

export const updateUserSettings = createAsyncThunk(
  'auth/updateUserSettings',
  async (params: TUpdateUserSettings) => {
    try {
      const response = await authApi.updatetUserSettingsRequest(params);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const createRole = createAsyncThunk('auth/createRole', async (role: TNewRole) => {
  try {
    const response = await authApi.createRoleRequest(role);

    return response.data;
  } catch (error) {
    const Error = error as AxiosError;
    throw Error;
  }
});

export const getRoleById = createAsyncThunk('auth/getRoleById', async (id: number) => {
  try {
    const response = await authApi.getRoleByIdRequest(id);
    return response.data;
  } catch (error) {
    const Error = error as AxiosError;
    throw Error;
  }
});

export const createUserRole = createAsyncThunk(
  'auth/createRole',
  async (role: TNewUserRole[], { dispatch }) => {
    const start = toast.loading(PermissionUpdateToastMessages.PERMISSION_START);

    try {
      const response = await authApi.createUserRoleRequest(role);

      toast.update(start, {
        render: PermissionUpdateToastMessages.PERMISSION_CREATE,
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });

      dispatch(getAllUserRoles());

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      toast.update(start, {
        render: PermissionUpdateToastMessages.PERMISSION_FAILURE,
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });

      throw Error;
    }
  },
);

export const getAllUserRoles = createAsyncThunk('auth/getAllUserRoles', async (_, { dispatch }) => {
  try {
    const response = await authApi.getAllUserRolesRequest();

    await dispatch(getNewToken());
    return response.data;
  } catch (error) {
    const Error = error as AxiosError;
    throw Error;
  }
});

export const deleteUserRole = createAsyncThunk(
  'auth/deleteUserRole',
  async (user_role_ids: number[], { dispatch }) => {
    const start = toast.loading(PermissionUpdateToastMessages.PERMISSION_REMOVE);
    try {
      const response = await authApi.deleteUserRoleRequest(user_role_ids);
      toast.update(start, {
        render: PermissionUpdateToastMessages.PERMISSION_REMOVE_SUCCESS,
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });

      dispatch(getAllUserRoles());

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      toast.update(start, {
        render: PermissionUpdateToastMessages.PERMISSION_REMOVE_FAILURE,
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });

      throw Error;
    }
  },
);

export const getUsersByRole = createAsyncThunk('auth/getUsersByRole', async (id: number) => {
  try {
    const response = await authApi.getUsersByRoleRequest(id);
    return response.data;
  } catch (error) {
    return [];
  }
});

export const getInvitationById = createAsyncThunk('auth/getInvitationById', async (id: number) => {
  try {
    const response = await authApi.getInvitationByIdRequest(id);

    return response.data;
  } catch (error) {
    const Error = error as AxiosError;
    throw Error;
  }
});

export const updateInvitationStatus = createAsyncThunk(
  'auth/updateInvitationStatus',
  async (params: TUpdateInvitationStatus, { dispatch, getState }) => {
    const {
      organizations: { pendingUsersLimit, pendingUsersOffset },
    } = getState() as RootState;

    const start = toast.loading(InvitedUserUpdateToastMessages.USER_UPDATE_START);

    try {
      const response = await authApi.updatetInvitationStatusRequest(params);

      toast.update(start, {
        render: InvitedUserUpdateToastMessages.USER_UPDATE_SUCCESS,
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });

      dispatch(getInvitedUsers({ limit: pendingUsersLimit, offset: pendingUsersOffset }));

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      toast.update(start, {
        render: InvitedUserUpdateToastMessages.USER_UPDATE_FAILURE,
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });
      throw Error;
    }
  },
);

export const resendInvitationToUser = createAsyncThunk(
  'auth/resendInvitationToUser',
  async (id: number, { dispatch }) => {
    const start = toast.loading(ResendEmailToastMessages.RESEND_START);

    try {
      const response = await authApi.resendInvitationToUserRequest(id);

      toast.update(start, {
        render: response.data.status,
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });

      dispatch(getInvitedUsers());

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      toast.update(start, {
        render: ResendEmailToastMessages.RESEND_FAILURE,
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });
      throw Error;
    }
  },
);

export const deleteInvitedUser = createAsyncThunk(
  'auth/deleteInvitedUser',
  async (id: number, { dispatch, getState }) => {
    const start = toast.loading(DeleteInvitedUserToastMessages.USER_DELETE_START);

    const {
      organizations: { pendingUsersLimit, pendingUsersOffset },
    } = getState() as RootState;

    try {
      const response = await authApi.deleteInvitedUserRequest(id);

      toast.update(start, {
        render: DeleteInvitedUserToastMessages.USER_DELETE_SUCCESS,
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });
      dispatch(getInvitedUsers({ limit: pendingUsersLimit, offset: pendingUsersOffset }));

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      toast.update(start, {
        render: DeleteInvitedUserToastMessages.USER_DELETE_FAILURE,
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });
      throw Error;
    }
  },
);
