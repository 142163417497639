import { useState, useRef, FC, useEffect } from 'react';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { Container, Stack } from '@mui/material';

import { ImageBlank } from 'assets/images';
import { FontNames } from 'types';
import { sizeLimits } from 'constants/SizeLimits';
import { TChangeInputEvent } from 'types/global/events';
import { UploadMessages } from 'constants/ToastMessages';
import { UploadSquareIcon, RepeatIcon, TrashIcon } from 'assets/icons';

import FileUploadView from '../FileUploadView';
import LazyImage from '../LazyImage';

import { containerStyle } from './constant';
import styles from './CompanyFileInput.module.scss';

import type { TCompanyFileInputProps } from './types';
const CompanyExcelFileInput: FC<TCompanyFileInputProps> = ({
  imgSrc,
  getFile,
  getImgSrc,
  borderColor,
  borderStyle,
  borderRadius,
  forLogo = false,
  blockMaxWidth,
  backgroundColor,
  setIsDirty,
  reversed = false,
  getSetIsRemovedState,
  htmlFor = 'upload-image',
  replaceClasses,
  maxWidth = '493px',
  containerClassName,
  withBottomBlock = true,
  containerBoxClassName,
  icon = <UploadSquareIcon />,
  containerMaxWidth = '100%',
  title = 'Upload Company Logo',
  fontFamily = FontNames.CIRCULAR_LIGHT,
}) => {
  const [imageUrl, setImageUrl] = useState<string | null>(imgSrc as string);
  const [file, setFile] = useState<File | null>(null);

  useEffect(() => {
    if (imgSrc) {
      setImageUrl(imgSrc);
    } else {
      setImageUrl(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imgSrc]);

  const companyFileInputRef = useRef<HTMLInputElement>(null);

  const containerClasses = classNames(styles.container, containerClassName);
  const containerBoxClasses = classNames(styles.container__box, containerBoxClassName);
  const replaceClassName = classNames(styles.container__replace, replaceClasses);

  const handleRemove = () => {
    setImageUrl(null);
    setFile(null);
    getFile?.(null);
    setIsDirty?.(true);
  };

  const handleFileUpload = (event: TChangeInputEvent) => {
    const file = event?.target?.files?.[0];
    if (forLogo && file && file.size > sizeLimits.logo) {
      toast.info(UploadMessages.LOGO_SIZE_EXCEEDED);
    } else {
      const reader = new FileReader();

      reader.onloadend = () => {
        const result = reader.result as string;
        setImageUrl(result);
        getImgSrc?.(result);
      };

      if (file) {
        reader.readAsDataURL(file);

        setFile(file);

        getFile?.(file);
        getSetIsRemovedState?.(false);
        setIsDirty?.(true);
      }
    }
  };

  const handleRemoveImage = () => {
    setImageUrl(null);
    setFile(null);
    getFile?.(null);
    getImgSrc?.(null);
    setIsDirty?.(true);

    if (imgSrc) {
      getSetIsRemovedState?.(true);
    }
  };

  const handleReplaceImage = () => {
    if (companyFileInputRef.current) {
      companyFileInputRef.current.click();
    }
  };

  return (
    <Container sx={{ ...containerStyle, maxWidth: containerMaxWidth }}>
      {!imageUrl ? (
        <Stack width='100%' maxWidth={maxWidth}>
          <label htmlFor={htmlFor}>
            <div
              className={containerClasses}
              style={{
                flexDirection: reversed ? 'column-reverse' : 'column',
                backgroundColor: backgroundColor,
                borderColor: borderColor,
                borderRadius,
                borderStyle,
              }}
            >
              <div className={styles.container__icon}>{icon}</div>
              <p className={styles.container__title} style={{ fontFamily }}>
                {title}
              </p>
              <input
                id={htmlFor}
                hidden
                accept='.xlsx,.csv'
                type='file'
                onChange={handleFileUpload}
              />
            </div>
          </label>
        </Stack>
      ) : (
        <>
          <div className={containerBoxClasses}>
            <LazyImage
              fallback={ImageBlank}
              src={imageUrl as string}
              alt='Uploaded Image'
              height={300}
              width={416}
            />

            {imageUrl && (
              <>
                <div className={styles.container__filled}>
                  <div className={styles.container__close} onClick={handleRemoveImage}>
                    <TrashIcon />
                  </div>
                </div>
                <div className={replaceClassName}>
                  <div onClick={handleReplaceImage} className={styles.container__replace_box}>
                    <RepeatIcon />
                    <p>Replace File</p>
                  </div>
                </div>
              </>
            )}
          </div>
          {withBottomBlock && file && (
            <div className={styles.container_upload}>
              <FileUploadView
                fileName={file?.name}
                fileSize={file?.size}
                fileExist={!!file}
                onRemove={handleRemove}
                blockMaxWidth={blockMaxWidth}
                fileType={file.type.split('/')[1]}
              />
            </div>
          )}
        </>
      )}

      <input
        ref={companyFileInputRef}
        hidden
        accept='.xlsx,.csv'
        type='file'
        onChange={handleFileUpload}
      />
    </Container>
  );
};

export default CompanyExcelFileInput;
