import { GridColDef } from '@mui/x-data-grid';

import { TableCell } from 'components';
import HeaderTitle from 'components/tables/EmployeeTable/HeaderTitle';

const extractNumberFromChildren = (children: string) => {
  const matches = children.match(/\d+/);
  return matches ? parseInt(matches[0], 10) : null;
};

export const requisitionColumns: GridColDef[] = [
  {
    field: 'number',
    headerName: 'Requisition No',
    flex: 1.2,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle style={{ paddingLeft: '0' }} title={headerName} />;
    },
    renderCell: (params) => {
      return <TableCell style={{ paddingLeft: '0', color: '#2067DD' }} title={params?.value} />;
    },

    sortComparator: (v1, v2) => {
      const number1 = extractNumberFromChildren(v1.props.children) || 0;
      const number2 = extractNumberFromChildren(v2.props.children) || 0;
      return number1 - number2;
    },
  },
  {
    field: 'no_of_items',
    headerName: 'Number Of Items',
    flex: 1.2,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle style={{ paddingLeft: '0' }} title={headerName} />;
    },
    renderCell: (params) => {
      return <TableCell style={{ paddingLeft: '0' }} title={params?.value} />;
    },
  },
  {
    field: 'quantity',
    headerName: 'Quantity',
    flex: 0.8,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle style={{ paddingLeft: '0' }} title={headerName} />;
    },
    renderCell: (params) => {
      return <TableCell style={{ paddingLeft: '0' }} title={params?.value} />;
    },
  },
  {
    field: 'created_by',
    headerName: 'Created by',
    flex: 1,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle style={{ paddingLeft: '0' }} title={headerName} />;
    },
    renderCell: (params) => {
      return <TableCell style={{ paddingLeft: '0' }} title={params?.value} />;
    },
  },
  {
    field: 'created_at',
    headerName: 'Date Requested',
    flex: 1.1,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle style={{ paddingLeft: '0' }} title={headerName} />;
    },
    renderCell: (params) => {
      return <TableCell style={{ paddingLeft: '0' }} title={params?.value} />;
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 0.7,
    renderCell: (params) => {
      return params.value;
    },
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle style={{ paddingLeft: '0' }} title={headerName} />;
    },
    sortComparator: (v1, v2) => {
      const name1 = v1.props.status || '';
      const name2 = v2.props.status || '';
      return name1.localeCompare(name2);
    },
  },
  {
    field: 'options',
    headerName: '',
    width: 44,
    renderCell: (params) => {
      return params.value;
    },
  },
];
