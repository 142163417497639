/* eslint-disable no-console */
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { AxiosError } from 'axios';
import classNames from 'classnames';

import { useAppSelector } from 'hooks';
import { streamByMembers } from 'utils/streamWithMembers';
import { BrowserStorageKeys, BrowserStorageService } from 'services';
import { myCurrentStreamSelector } from 'store/slices/liveStreamSlice/selector';

import StreamLayout from '../StreamLayout';

import styles from './Stream.module.scss';
import StreamElement from './StreamElement/StreamElement';

import type { TCurrentStream, TRemoteMemberInfo, TStreamProps } from './types';

const Stream: FC<TStreamProps> = ({
  contentRef,
  endStream,
  mediaStreamRef,
  webSocketRef,
  localVideoRef,
  peerConnectionRef,
  getCurrentStreamData,
}) => {
  const myCurrentStream = useAppSelector(myCurrentStreamSelector);
  const remoteVideosRef = useRef<HTMLDivElement | null>(null);
  const remoteOtherVideosRef = useRef<HTMLDivElement | null>(null);

  const [upcomingStreams, setUpcomingStreams] = useState<MediaStream[] | []>([]);
  const [startEndingStream, setStartEndingStream] = useState<boolean>(false);

  useEffect(() => {
    if (startEndingStream) {
      endStream();

      if (mediaStreamRef.current) {
        mediaStreamRef.current.getTracks().forEach((track) => {
          track.stop();
        });
        mediaStreamRef.current = null;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startEndingStream]);

  const remoteUsers = useRef<TRemoteMemberInfo[]>();

  const sortedMembers = myCurrentStream?.livestream?.members?.slice().sort((a, b) => {
    if (a?.created_at && b?.created_at) {
      return new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
    } else {
      return 0;
    }
  });

  const currentStream = useMemo(() => {
    return streamByMembers(remoteUsers?.current as TRemoteMemberInfo[], upcomingStreams)?.find(
      (stream) => {
        if (Number(stream?.member_id) === Number(sortedMembers?.at(1)?.id)) {
          if (mediaStreamRef.current && stream.stream) {
            const tracks = [...stream.stream.getTracks(), ...mediaStreamRef.current.getTracks()];
            const combined = new MediaStream(tracks);
            getCurrentStreamData(combined);
          }
          return stream;
        }
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remoteUsers, upcomingStreams]);

  const currentLiveStreamToken = BrowserStorageService.get(
    BrowserStorageKeys.currentLiveStreamToken,
  );

  useEffect(() => {
    const initializePeerConnection = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: false,
          audio: true,
        });

        mediaStreamRef.current = stream;

        peerConnectionRef.current = new RTCPeerConnection();
        const pc = peerConnectionRef.current;

        pc.ontrack = function (event) {
          if (event.track.kind === 'audio') {
            return;
          }
          if (event.track.kind === 'video') {
            setUpcomingStreams((prev) => {
              const streamId = event.streams[0].id;
              const isStreamInList = prev.some((stream) => stream.id === streamId);
              if (isStreamInList) {
                return prev.map((stream) => (stream.id === streamId ? event.streams[0] : stream));
              } else {
                return [...prev, event.streams[0]];
              }
            });
          }
          const videoContainer = document.createElement('div');
          videoContainer.className = 'video-container';
          const el = document.createElement(event.track.kind) as HTMLVideoElement;
          el.srcObject = event.streams[0];
          el.autoplay = true;
          el.controls = true;
          videoContainer.appendChild(el);
          if (remoteVideosRef?.current?.firstChild) {
            remoteOtherVideosRef.current?.appendChild(videoContainer);
          } else {
            remoteVideosRef.current?.appendChild(videoContainer);
          }
          event.track.onmute = function () {
            el.play();
          };
          event.streams[0].onremovetrack = () => {
            if (videoContainer.parentNode) {
              videoContainer.parentNode.removeChild(videoContainer);
            }
          };
        };

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        if (localVideoRef.current) {
          localVideoRef.current.srcObject = stream;
        }
        stream.getTracks().forEach((track) => {
          pc.addTrack(track, stream);
        });
        pc.onconnectionstatechange = () => {};
        pc.onicecandidate = (e) => {
          if (!e.candidate) {
            return;
          }
          webSocketRef.current?.send(
            JSON.stringify({
              event: 'candidate',
              data: JSON.stringify(e.candidate),
            }),
          );
        };
      } catch (error) {
        const Error = error as AxiosError;

        throw Error;
      }
    };

    initializePeerConnection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    webSocketRef.current = new WebSocket(
      `${process.env.REACT_APP_SOCKET_BASE_URL}/websocket?token=${
        currentLiveStreamToken || myCurrentStream?.token
      }`,
    );

    webSocketRef.current.onclose = function () {};

    webSocketRef.current.onmessage = function (evt) {
      const msg = JSON.parse(evt.data);
      if (!msg) {
        return console.error('Failed to parse message');
      }

      switch (msg.event) {
        case 'offer':
          if (!JSON.parse(msg.data)) {
            console.error('Failed to parse offer');
            break;
          }
          peerConnectionRef.current?.setRemoteDescription(JSON.parse(msg.data));
          peerConnectionRef.current?.createAnswer().then((answer) => {
            peerConnectionRef.current?.setLocalDescription(answer);
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            webSocketRef.current!.send(
              JSON.stringify({
                event: 'answer',
                data: JSON.stringify(answer),
              }),
            );
          });
          break;

        case 'candidate':
          if (!JSON.parse(msg.data)) {
            console.error('Failed to parse candidate');
            break;
          }
          peerConnectionRef.current?.addIceCandidate(JSON.parse(msg.data)).catch((error) => {
            console.error('Error adding ICE Candidate:', error);
          });
          break;

        case 'user_info':
          if (!JSON.parse(msg.data)) {
            console.error('Failed to parse user_info');
            break;
          }

          remoteUsers.current = Object.keys(JSON.parse(msg.data)).map((streamId) => ({
            stream_id: streamId,
            ...JSON.parse(msg.data)[streamId],
          }));

          break;

        case 'disconnected-user':
          break;

        case 'stream-ended':
          setStartEndingStream(true);
          webSocketRef.current?.close();
          peerConnectionRef.current?.close();
          break;

        default:
          console.error('Unknown event:', msg.event);
          break;
      }
    };

    webSocketRef.current.onerror = function (evt: Event) {
      if (evt instanceof ErrorEvent) {
        console.error('WebSocket error: ' + evt.error);
      } else {
        console.error('WebSocket error: ' + evt);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const containerClasses = classNames(styles.container, {
    [styles.container_none]: !currentStream,
  });

  return (
    <div className={containerClasses}>
      <video muted autoPlay ref={localVideoRef} style={{ display: 'none' }} />
      <StreamLayout>
        <StreamElement
          contentRef={contentRef}
          stream={currentStream?.stream as any}
          currentStream={currentStream as TCurrentStream}
        />
      </StreamLayout>
    </div>
  );
};

export default Stream;
