type MediaPromises = Record<string, () => Promise<any>>;

const mediaPromises: MediaPromises = {};
let isExecutingQueue = false;

const executeNextPromise = () => {
  const keys = Object.keys(mediaPromises);
  if (keys.length === 0 || isExecutingQueue) {
    return;
  }

  isExecutingQueue = true;
  const key = keys[0];
  const promiseCallback = mediaPromises[key];
  delete mediaPromises[key];

  promiseCallback()
    .catch((error) => {
      // Handle error as needed
      // eslint-disable-next-line no-console
      console.error('Error executing media promise:', error);
    })
    .finally(() => {
      isExecutingQueue = false;
      executeNextPromise();
    });
};

const addMediaPromise = (key: string, promiseCallback: () => Promise<any>) => {
  mediaPromises[key] = promiseCallback;
  executeNextPromise();
};

const removeMediaPromise = (key: string) => {
  delete mediaPromises[key];
};

const cancelMediaPromises = () => {
  Object.keys(mediaPromises).forEach((key) => {
    delete mediaPromises[key];
  });
  isExecutingQueue = false;
};

export { addMediaPromise, removeMediaPromise, cancelMediaPromises };
