import { createSlice } from '@reduxjs/toolkit';

import { getTransactions } from './thunks';

import type { TInitialState } from './types';

const initialState: TInitialState = {
  allTransactionsAsc: true,
  allTransactionsLimit: 5,
  allTransactionsOffset: 0,
  allTransactionsLoading: false,
  allTransactionsError: null,
  allTransactions: [],
};

const transactionSlice = createSlice({
  name: 'transactionSlice',
  reducers: {
    setPage(state, action) {
      state.allTransactionsOffset = (action.payload - 1) * state.allTransactionsLimit;
    },
    setLimit(state, action) {
      state.allTransactionsLimit = action.payload;
    },
  },
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getTransactions.pending, (state) => {
        state.allTransactionsLoading = true;
        state.allTransactionsError = null;
      })
      .addCase(getTransactions.fulfilled, (state, action) => {
        state.allTransactionsLoading = false;
        state.allTransactions = action.payload;
        state.allTransactionsAsc = !state.allTransactionsAsc;
      })
      .addCase(getTransactions.rejected, (state, action) => {
        state.allTransactionsLoading = false;
        state.allTransactionsError = action.error.message as string;
      });
  },
});

export default transactionSlice.reducer;

export const { setPage, setLimit } = transactionSlice.actions;
