import { useEffect, FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AxiosError } from 'axios';

import { useAppDispatch } from 'hooks';
import { getEstimateByUUID } from 'store/slices/estimatesSlice/thunks';
import {
  GeneralInfo,
  BuildingConstruction,
  WifiCoverage,
  Equipment,
  NetworkConfig,
  AdditionConfig,
} from 'assets/icons';

import styles from './BomEstimate.module.scss';
import BomEstimateHeader from './BomEstimateHeader';

const BomEstimateviatemplateView: FC = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const [myestimatepayload, setmyEstimatePayload] = useState<{ [key: string]: any }>({});

  useEffect(() => {
    const fetchData = async (id: string) => {
      try {
        const response = await dispatch(getEstimateByUUID(id));
        const data = response.payload;
        setmyEstimatePayload(data);
      } catch (error) {
        const Error = error as AxiosError;
        throw Error;
      }
    };
    if (id) {
      fetchData(id);
    }
  }, [id, dispatch]);

  const getIconComponent = (input: any) => {
    let iconComponent = null;
    switch (input) {
      case 'GENERAL INFO':
        iconComponent = <GeneralInfo className={styles.icon} />;
        break;
      case 'BUILDING CONSTRUCTION':
        iconComponent = <BuildingConstruction className={styles.icon} />;
        break;
      case 'WIFI COVERAGE':
        iconComponent = <WifiCoverage className={styles.icon} />;
        break;
      case 'EQUIPMENT CABLING':
        iconComponent = <Equipment className={styles.icon} />;
        break;
      case 'NETWORK CONFIGURATION':
        iconComponent = <NetworkConfig className={styles.icon} />;
        break;
      case 'ADDITIONAL INFO':
        iconComponent = <AdditionConfig className={styles.icon} />;
        break;
      default:
        // If no match, set a default icon or leave it as null
        iconComponent = null;
        break;
    }
    return iconComponent;
  };

  // Define the order in which keys should appear
  const keyOrder = [
    'GENERAL INFO',
    'BUILDING CONSTRUCTION',
    'WIFI COVERAGE',
    'EQUIPMENT CABLING',
    'NETWORK CONFIGURATION',
    'ADDITIONAL INFO',
  ];

  return (
    <div className={styles.pageContainer}>
      <BomEstimateHeader uuid={String(id)} />
      <header className={styles.header}>
        <h6>
          <strong>
            <b>{myestimatepayload['estimate_name']}</b>
          </strong>
        </h6>
        <h5>
          <strong>
            Version: <b>{myestimatepayload['estimate_version_tag']}</b>
          </strong>
        </h5>
        <h5>
          <strong>
            Created: <b>{myestimatepayload['created_on']}</b>
          </strong>
        </h5>
        <h5>
          <strong>
            Estimate Date: <b>{myestimatepayload['created_on']}</b>
          </strong>
        </h5>
        <h5>
          Status:
          <select style={{ width: '90px' }}>
            <option value='Assessment'> Assessment</option>
            <option value='Proposal'> Proposal</option>
            <option value='Closed Won'> Closed Won</option>
            <option value='Closed Lost'> Closed Lost</option>
          </select>
        </h5>
        <h5>
          Closing Probablity:
          <select style={{ width: '90px' }}>
            <option value='25% - Low'> 25% - Low</option>
            <option value='50% - Medium'> 50% - Medium</option>
            <option value='75% - High'> 75% - High</option>
            <option value='100% - Guaranteed'> 100% - Guaranteed</option>
          </select>
        </h5>
      </header>
      {keyOrder.map((key) => {
        const value = myestimatepayload[key];
        if (typeof value === 'object') {
          return (
            <div key={key} className={styles.box}>
              {getIconComponent(key)}
              <h1 className={styles.container__content__item}>{key}</h1>
              <ul>
                {Object.entries<any>(value).map(([subKey, subValue]) => (
                  <span key={subKey} className={styles.container__content__item}>
                    {subKey.replace(/\?/g, '')}: <b>{subValue}</b>
                  </span>
                ))}
              </ul>
            </div>
          );
        }
        return null;
      })}
    </div>
  );
};

export default BomEstimateviatemplateView;
