import { ChangeEvent, FC, useState } from 'react';
import { Controller, useController } from 'react-hook-form';
import classNames from 'classnames';
import { Stack } from '@mui/material';

import { TrashIcon, UploadSquareIcon } from 'assets/icons';
import { ErrorMessage } from 'components/shared';

import { TFileUploadProps } from './types';
import styles from './FileUpload.module.scss';

const FileUploadController: FC<TFileUploadProps> = ({
  control,
  name,
  title = 'Choose',
  getFile,
  htmlFor = 'file',
}) => {
  const {
    field: { onChange, value },

    fieldState: { error },
  } = useController({ control, name });

  const [file, setFile] = useState<File | null>(null);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.[0]) {
      setFile(event.target.files?.[0]);
      getFile?.(event.target.files?.[0]);
    }
  };

  const removeFile = (event: React.SyntheticEvent<SVGElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const inputElement = document.getElementById(htmlFor) as HTMLInputElement;
    if (inputElement) {
      inputElement.value = '';
    }

    setFile(null);
    getFile?.(null);
    onChange('');
  };

  const containerClasses = classNames(styles.container, { [styles.container__error]: !!error });
  const requiredIcon = classNames(styles.container__required, {
    [styles.container__required__error]: !!error,
  });

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <label htmlFor={htmlFor} className={containerClasses}>
          <p className={requiredIcon}>*</p>
          <UploadSquareIcon />

          {!file && !value ? (
            <div className={styles.container__file__replace}>
              {title}
              {error?.message && <ErrorMessage message={error?.message} />}
            </div>
          ) : (
            <div className={styles.container__file}>
              <Stack gap='5px' direction='row' alignItems='center'>
                <p className={styles.container__file__name}>{file?.name || value}</p>
                <TrashIcon onClick={removeFile} />
              </Stack>

              <Stack gap='5px' direction='row' alignItems='center'>
                <p className={styles.container__file__replace}>Replace file</p>
                <div />
              </Stack>
            </div>
          )}
          <input
            id={htmlFor}
            type='file'
            hidden
            onChange={(e) => {
              field.onChange(e);
              handleChange(e);
            }}
            accept='.docx,.pdf'
          />
        </label>
      )}
    />
  );
};

export default FileUploadController;
