import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { SubmitHandler, useForm, FieldValues } from 'react-hook-form';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

import {
  CustomBreadcrumbs,
  CustomTypography,
  ControlledInput,
  Button,
  AddTask,
  ControlledFormPicker,
  ControlledAutocomplete,
} from 'components';
import { TOption } from 'components/shared/Select/types';
import { Colors, FontNames, Routes } from 'types';
import { useAppDispatch, useAppSelector } from 'hooks';
import { currentCustomerSelector } from 'store/slices/customersSlices/selectors';
import { getCustomerById, getEstimateById, getOrganizationMembers } from 'store/thunks';
import { CreateWorkOrder, CreateWorkOrderTasks } from 'store/slices/workOrderSlice/thunks';
import { TTaskCreateBody, TWorkOrderBody } from 'store/slices/workOrderSlice/types';
import { BrowserStorageKeys, BrowserStorageService } from 'services';
import { ArrowDown } from 'assets/icons';
import { estimateItemSelector } from 'store/slices/estimatesSlice/selectors';
import {
  userAllRolesLoadingSelector,
  userPermissionsSelector,
} from 'store/slices/authSlice/selectors';

import styles from './AddWorkOrderDrawer.module.scss';
import { breadCrumbOptions } from './utils';

const currentOrganization = BrowserStorageService.get(BrowserStorageKeys.CurrentOrganizationId);

const CreateWorkOrderForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;
  const WorkOrderID = state?.WorkOrderID;
  const StartWODate = state?.StartWODate;

  const currentCustomer = useAppSelector(currentCustomerSelector);
  const currentEstimate = useAppSelector(estimateItemSelector);
  const { isAccessToCreateWorkOrder } = useAppSelector(userPermissionsSelector);
  const userAllRolesLoading = useAppSelector(userAllRolesLoadingSelector);

  const dispatch = useAppDispatch();

  const [employeeOptions, setEmployeeOptions] = useState<TOption[]>([]);
  const [managerOptions, setManagerOptions] = useState<TOption[]>([]);

  const getMembers = async () => {
    const response = await dispatch(
      getOrganizationMembers({ org_id: Number(currentOrganization), limit: 100 }),
    );

    if (response.payload) {
      const { members } = response.payload;
      const employeeOptions = members
        .filter(
          (member: any) => member.designation === 'employee' || member.designation === 'owner',
        )
        .map((employee: any) => ({
          id: employee.user_id,
          value: employee.name,
          label: employee.name,
        }));

      const managerOptions = members
        .filter((member: any) => member.designation === 'manager' || member.designation === 'owner')
        .map((manager: any) => ({
          id: manager.user_id,
          value: manager.name,
          label: manager.name,
        }));

      setEmployeeOptions(employeeOptions);
      setManagerOptions(managerOptions);
    }
  };

  useEffect(() => {
    getMembers();
  });

  const {
    control,
    handleSubmit,
    getValues,
    reset,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<any>();

  const watchResult = watch();

  const { wo_end_date, wo_start_date, Supervisor } = watchResult;

  const isFormValid =
    Number(wo_end_date?.toString().length) &&
    Number(wo_start_date?.toString().length) &&
    Supervisor?.id;

  const handleCreateData: SubmitHandler<FieldValues> = async (data) => {
    if (currentCustomer?.id) {
      const WO_Data: TWorkOrderBody = {
        customer_id: currentCustomer.id,
        supervisor_id: data?.Supervisor?.id,
        start_date: new Date(data?.wo_start_date).toISOString().split('T')[0],
        end_date: new Date(data?.wo_end_date).toISOString().split('T')[0],
        notes: data?.notes,
        estimate_id: WorkOrderID || 1,
      };

      if (WO_Data) {
        const response = await dispatch(CreateWorkOrder(WO_Data));
        if (response) {
          const tasks_data: TTaskCreateBody[] = data?.tasks?.map(
            (task: {
              task: {
                daily_hours: any;
                end_date: any;
                start_date: any;
                task_description: any;
                tech_name: any;
                start_time: any;
                sequence: any;
              };
            }) => ({
              start_time: task.task.start_time,
              technician_id: task.task.tech_name.id,
              description: task.task.task_description,
              start_date: new Date(task.task.start_date).toISOString().split('T')[0],
              end_date: new Date(task.task.end_date).toISOString().split('T')[0],
              daily_hours: task.task.daily_hours,
              work_order_id: response.payload.id,
              sequence: task.task.sequence,
            }),
          );
          if (tasks_data) {
            await dispatch(CreateWorkOrderTasks(tasks_data));
          }
          reset();
          navigate(`/work-flow/work-order`);
        }
      }
    }
  };

  useEffect(() => {
    dispatch(getEstimateById(WorkOrderID))
      .then(() => {
        if (currentEstimate?.customer_id) {
          dispatch(getCustomerById(currentEstimate.customer_id));
        }
      })
      .catch(() => {
        // Handle error if getEstimateById fails
      });
  }, [dispatch, WorkOrderID, currentEstimate?.customer_id]);

  if (!isAccessToCreateWorkOrder && !userAllRolesLoading) {
    return <Navigate replace to={Routes.ActivityFeed} />;
  }

  return (
    <form onSubmit={handleSubmit(handleCreateData)} className={styles.container}>
      <Box className={styles.container}>
        <CustomBreadcrumbs options={breadCrumbOptions} />
        <Box className={styles.container__head}>
          <CustomTypography className={styles.container__head__title}>
            Create Work Order {currentEstimate?.customer_id}
          </CustomTypography>
        </Box>
        <Box>
          <CustomTypography className={styles.container__head__body}>
            {currentCustomer?.address}, {currentCustomer?.city}
          </CustomTypography>
          <CustomTypography className={styles.container__head__body}>
            {currentCustomer?.state} {currentCustomer?.zip}
          </CustomTypography>
        </Box>
        <Box className={styles.estimateBox}>
          <CustomTypography className={styles.estimateText}>
            Estimate: {'Estimate Name'}
          </CustomTypography>
        </Box>
        <Box className={styles.customerInfoContainer}>
          <Box className={styles.customerInfoBox}>
            <CustomTypography className={styles.customerText}>
              Customer Name: {currentCustomer?.name}
            </CustomTypography>
          </Box>
          <Box className={styles.customerInfoBox}>
            <CustomTypography className={styles.customerText}>
              Customer Phone: {currentCustomer?.phone_number}
            </CustomTypography>
          </Box>
        </Box>
        <Box className={styles.customerInfoContainer}>
          <Box className={styles.customerInfoBox}>
            <CustomTypography className={styles.customerText}>
              Customer Email: {currentCustomer?.email}
            </CustomTypography>
          </Box>
          <Box className={styles.customerInfoBox}>
            <CustomTypography className={styles.customerText}>
              Customer Address: {currentCustomer?.address}, {currentCustomer?.city},{' '}
              {currentCustomer?.state} {currentCustomer?.zip}
            </CustomTypography>
          </Box>
        </Box>
        <Box className={styles.card__items}>
          <ControlledFormPicker
            defaultValue={StartWODate}
            padding='0 8px'
            label='WO Start Date'
            name='wo_start_date'
            control={control}
            // error={specificError}
            backgroundColor={Colors.WHITE}
            className={styles.container__picker}
            // helperText={specificErrorMessage || specificError?.message}
          />
          <ControlledFormPicker
            // value='12/10/2025'
            padding='0 8px'
            label='WO End Date'
            name='wo_end_date'
            control={control}
            // error={specificError}
            backgroundColor={Colors.WHITE}
            className={styles.container__picker}
            // helperText={specificErrorMessage || specificError?.message}
          />
        </Box>
        <Box className={styles.container__content}>
          <div className={styles.container__content__item}>
            <ControlledInput
              name='notes'
              label={'Notes'}
              control={control}
              // maxWidth='1000px'
              rows={3}
              required={false}
              borderColor={Colors.SOFT_SILVER}
              backgroundColor={Colors.FROST_WHITE}
              // fontFamily={FontNames.CIRCULAR_REG}
              placeholder={'Notes'}
              borderRadius='5px'
              // labelColor={Colors.ENIGMATIC_MIDNIGHT}
              // error={errors[name as keyof TWorkOrderFormValues]}
              // helperText={errors[name as keyof TWorkOrderFormValues]?.message}
            />
          </div>
        </Box>
        <Box className={styles.container__content}>
          <div className={styles.container__content__subitem}>
            <ControlledAutocomplete
              name='Supervisor'
              label='Supervisor'
              isFromWorkOrder={true}
              hoverColor='transparent'
              backgroundColor={Colors.LIGHT_SILVER}
              borderColor={Colors.SOFT_SILVER}
              inputIcon={<ArrowDown width={24} height={24} />}
              control={control}
              required={false}
              optionsName='label'
              placeholder={'Supervisor'}
              errors={null}
              fontFamily='CircularStdRegular'
              options={managerOptions || []}
            />
          </div>
        </Box>
        <Box>
          <AddTask
            errors={errors}
            control={control}
            getValues={getValues}
            employees={employeeOptions}
            // isLoading={generalLoading}
          />
        </Box>

        <Box className={styles.container__footer}>
          <Button
            type='submit'
            color='white'
            maxWidth='180px'
            minWidth='68px'
            padding='11px 8px'
            borderRadius='5px'
            backgroundColor={Colors.SAPPHIRE}
            fontFamily={FontNames.CIRCULAR_BOLD_MAX}
            disabled={!isFormValid || isSubmitting}
            className={styles.container__content__submit}
          >
            Create
          </Button>
        </Box>
      </Box>
    </form>
  );
};

export default CreateWorkOrderForm;
