import { FC, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { Box, FormHelperText } from '@mui/material';

import { ArrowDown } from 'assets/icons';
import { CustomTypography } from 'components';
import { useAppDispatch, useAppSelector, useOnClickOutside } from 'hooks';
import { excelListSelector, excelListLoadingSelector } from 'store/slices/inventorySlice/selectors';
import { getExcelList } from 'store/thunks';
import { GetExcelListParamsSort } from 'store/slices/inventorySlice/types';

import styles from './SelectCatalogDropDown.module.scss';

import type { TExcel, TSelectExcelDropDownProps } from './types';

const SelectCatalogDropDown: FC<TSelectExcelDropDownProps> = ({
  helperText,
  currentTemplate,
  setCurrentTemplate,
}) => {
  const dispatch = useAppDispatch();

  const ref = useRef<HTMLDivElement | null>(null);
  const allExcelLoad = useAppSelector(excelListLoadingSelector);
  const allExcel = useAppSelector(excelListSelector);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  // useEffect(() => {
  //   dispatch(getExcelList({ limit: 50, offset: 0, sort_by: GetExcelListParamsSort.NAME, asc: true }));
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    const fetchExcelList = async () => {
      await dispatch(
        getExcelList({ limit: 50, offset: 0, sort_by: GetExcelListParamsSort.NAME, asc: true }),
      );
      // Check if there is a default template and set it as the current template
      const defaultTemplate = allExcel?.data?.find((template) => template.default_template);
      if (defaultTemplate) {
        setCurrentTemplate({
          id: defaultTemplate.id,
          name: defaultTemplate.name,
        });
      }
    };

    fetchExcelList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = () => allExcel?.total_count !== 0 && setIsOpen(!isOpen);

  const changeCurrentCatalog = async ({ id, name }: TExcel) => {
    setCurrentTemplate({
      id,
      name,
    });
    setIsOpen(!isOpen);
  };

  const renderItems = allExcel?.data?.map((item) => {
    return (
      <Box
        key={item.id}
        style={{
          pointerEvents: 'unset',
          opacity: 1,
        }}
        className={styles.container__dropdown__item}
        onClick={() =>
          changeCurrentCatalog({
            id: item.id,
            name: item.name,
          })
        }
      >
        {item.name}
      </Box>
    );
  });

  useOnClickOutside(ref, () => setIsOpen(false));

  const textClasses = classNames(styles.container__select__text, {
    [styles.container__select__text_disable]: !currentTemplate?.name,
  });

  return (
    <Box className={styles.container} ref={ref}>
      <Box className={styles.container__select} onClick={handleClick}>
        <CustomTypography className={textClasses}>
          {currentTemplate?.name ? currentTemplate?.name : 'Select Template:*'}
        </CustomTypography>
        <ArrowDown
          className={classNames(styles.container__select__icon, {
            [styles.container__select__icon__active]: isOpen,
          })}
        />
      </Box>
      {!allExcelLoad && helperText && (
        <FormHelperText sx={{ color: 'red', marginTop: '3px', marginLeft: '14px !important' }}>
          {helperText}
        </FormHelperText>
      )}
      {isOpen && <Box className={styles.container__dropdown}>{renderItems}</Box>}
    </Box>
  );
};

export default SelectCatalogDropDown;
