import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';

import { useAppDispatch, useAppSelector } from 'hooks';
import { getInvoiceHistory, getUserSubscriptions } from 'store/slices/subscriptionSlice/thunks';
import {
  ScrollLayout,
  InvoiceStatus,
  CustomSkeleton,
  CustomTypography,
  CustomTablePagination,
  CustomDataGrid,
} from 'components';
import {
  invoicesHistorySelector,
  invoicesHistoryLoaderSelector,
  subscriptionsSelector,
} from 'store/slices/subscriptionSlice/selectors';
import { BrowserStorageKeys, BrowserStorageService } from 'services';
import { EmptyTitles } from 'constants/EmptyTitles';
import { UserSubscriptionStatus } from 'store/slices/subscriptionSlice/types';

import { columns } from './options';
import { sxStyles } from './styles';
import CardUsed from './CardUsed/CardUsed';
import styles from './InvoiceTable.module.scss';
import PlanInvoiceDate from './PlanInvoiceDate/PlanInvoiceDate';

import type { TCustomTablePaginationProps } from 'components/shared/CustomTablePagination/types';

const InvoiceTable = () => {
  const dispatch = useAppDispatch();

  const currentOrganization = BrowserStorageService.get(BrowserStorageKeys.CurrentOrganizationId);

  const invoiceHistory = useAppSelector(invoicesHistorySelector);
  const invoiceHistoryLoader = useAppSelector(invoicesHistoryLoaderSelector);
  const { userSubscriptionsError } = useAppSelector(subscriptionsSelector);

  useEffect(() => {
    if (currentOrganization) {
      dispatch(
        getUserSubscriptions({
          org_id: Number(currentOrganization),
          subscription_status: UserSubscriptionStatus.ACTIVE,
        }),
      );

      dispatch(getInvoiceHistory(Number(currentOrganization)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrganization]);

  const columnsWithoutSorting: GridColDef[] = columns?.map((column) => ({
    ...column,
    sortable: false,
    hideSortIcons: true,
    disableColumnMenu: true,
    disableColumnSelector: true,
  }));

  const rows = invoiceHistory?.data
    ?.filter((item) => item?.payment_method?.card_type)
    ?.map((item) => ({
      plan: (
        <PlanInvoiceDate
          planeName={item?.lines?.data?.[0]?.description}
          paidDate={item?.status_transitions?.paid_at}
        />
      ),
      state: <InvoiceStatus status={item?.status} />,
      card: (
        <CardUsed
          cardName={item?.payment_method?.card_type}
          last$Numbers={item?.payment_method?.last4}
        />
      ),
      id: item.id || 1,
    }));

  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);

  const handleChangePage: TCustomTablePaginationProps['onPageChange'] = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage: TCustomTablePaginationProps['onRowsPerPageChange'] = (
    newRowsPerPage,
  ) => {
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  };

  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const paginatedRows = rows?.slice(startIndex, endIndex);

  return (
    <Box className={styles.container}>
      <CustomTypography className={styles.container__title}>Invoices</CustomTypography>
      {invoiceHistoryLoader ? (
        <CustomSkeleton width='100%' height={200} />
      ) : (
        <>
          {rows && (
            <ScrollLayout>
              <CustomDataGrid
                rowHeight={63}
                sx={sxStyles}
                emptyTitle={EmptyTitles.Invoices}
                rows={userSubscriptionsError ? [] : paginatedRows || []}
                columns={columnsWithoutSorting}
              />
            </ScrollLayout>
          )}

          <CustomTablePagination
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            count={(paginatedRows?.length as number) || 0}
          />
        </>
      )}
    </Box>
  );
};

export default InvoiceTable;
