import { TUserProfile } from 'store/slices/authSlice/types';

import { TProfileFormValues } from './types';

const profileFormGenerator = (userInfo: TUserProfile): TProfileFormValues => ({
  email: userInfo?.email || '',
  fullName: userInfo?.name || '',
  mobilePhone: userInfo?.mobile_number || '',
  workPhone: userInfo?.office_number || '',
  address: userInfo?.address || '',
  city: userInfo?.city || '',
  state: userInfo?.state || '',
  country: userInfo?.country || '',
  zipcode: userInfo?.zip_code || '',
});

export default profileFormGenerator;
