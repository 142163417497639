import { FC } from 'react';
import { Box } from '@mui/material';

import { ImageBlank } from 'assets/images';
import { generateInventoryItemNumber } from 'utils/generateInventoryItemNumber';
import { CustomTypography, DrawerLayout, LazyImage, TextFrame } from 'components';
import { useAppSelector, useWindowSize } from 'hooks';
import { currentPartByIdSelector } from 'store/slices/inventorySlice/selectors';
import { Colors, FontNames, ImgUrlFolders } from 'types';
import PreloadedImg from 'components/views/PreloadedImg';

import { breakPoints } from '../../../../constants';

import styles from './ItemVersionInformation.module.scss';

import type { TItemVersionInformationProps } from './types';

const ItemVersionInformation: FC<TItemVersionInformationProps> = ({ open, onClose, history }) => {
  const { width } = useWindowSize();
  const currentItem = useAppSelector(currentPartByIdSelector);

  const currentItemData = history;

  const renderNumber = generateInventoryItemNumber(currentItem?.id as number);
  const renderVersionNumber = generateInventoryItemNumber(history?.id as number);

  const filteredKeys = [
    'manufacturer',
    'cost',
    'qty',
    'item_location',
    'brand',
    'preferred_vendor',
  ];

  const newArray = filteredKeys.map((key, index) => {
    let label = key;
    let value: string | number | Date | boolean =
      currentItemData?.[key as keyof typeof currentItemData] || '';

    if (key === 'qty') {
      label = 'quantity';
    }

    if (key === 'item_location') {
      label = 'item location';
    }

    if (key === 'preferred_vendor') {
      label = 'preferred vendor';
    }

    if (key === 'vendor') {
      value = String(currentItemData?.preferred_vendor);
    }

    return {
      label,
      value,
      id: index,
    };
  });

  const renderedOptions = newArray?.map((item) => {
    return (
      <Box className={styles.container__content__main} key={item?.id}>
        <CustomTypography className={styles.container__content__main__title}>
          {item?.label}
        </CustomTypography>

        <CustomTypography className={styles.container__content__main__subtitle}>
          {(!!item?.value && String(item?.value)) || '--'}
        </CustomTypography>
      </Box>
    );
  });
  const drawerWidth = Number(width) >= breakPoints.MOBILE ? 472 : 375;

  return (
    <DrawerLayout open={open} onClose={onClose} headerTitle='' width={drawerWidth}>
      <Box className={styles.container}>
        <Box className={styles.container__head}>
          <Box className={styles.container__head__left}>
            <CustomTypography className={styles.container__head__left__title}>
              Item Version Information
            </CustomTypography>
            <CustomTypography className={styles.container__head__left__subtitle}>
              <span className={styles.container__head__left__subtitle__title}>Item Number:</span>
              <span className={styles.container__head__left__subtitle__name}>
                INV-{renderNumber}
              </span>
            </CustomTypography>
            <CustomTypography className={styles.container__head__left__subtitle}>
              <span className={styles.container__head__left__subtitle__title}>Version Number:</span>
              <span className={styles.container__head__left__subtitle__name}>
                {renderVersionNumber}
              </span>
            </CustomTypography>
          </Box>
        </Box>
        <Box className={styles.container__content}>
          <Box className={styles.container__content__head}>
            <CustomTypography className={styles.container__content__head__item}>
              Property
            </CustomTypography>
            <CustomTypography className={styles.container__content__head__item}>
              Value
            </CustomTypography>
          </Box>
          {renderedOptions}
        </Box>

        <Box className={styles.container__description}>
          <Box className={styles.container__description__img}>
            <PreloadedImg
              height={205}
              folderName={ImgUrlFolders.INVENTORY}
              imgId={history?.image_url_id as string}
            />
            <LazyImage height={205} fallback={ImageBlank} />
          </Box>
          <CustomTypography className={styles.container__description__title}>
            Description
          </CustomTypography>

          {currentItemData?.description && currentItemData?.description !== 'null' ? (
            <TextFrame
              padding='12px'
              borderRadius='5px'
              border='0.5px solid #ccc'
              backgroundColor={Colors.PLATINUM_GRAY}
              textColor={Colors.SHADOWED_SLATE}
              fontFamily={FontNames.CIRCULAR_REG}
              content={currentItemData?.description}
            />
          ) : (
            'Items does not have description'
          )}
        </Box>
      </Box>
    </DrawerLayout>
  );
};

export default ItemVersionInformation;
