import { OutlinedInput } from '@mui/material';

import { TEditor } from './types';
import styles from './CreateNoticeModal.module.scss';

const Editor: React.FC<TEditor> = ({
  ref,
  height,
  value,
  padding,
  onChange,
  placeholder,
  outlined = true,
}) => (
  <OutlinedInput
    inputRef={ref}
    id='create_post'
    value={value}
    onChange={onChange}
    fullWidth
    autoFocus
    multiline
    placeholder={placeholder}
    className={styles.textarea}
    inputProps={{
      style: {
        height: height || '252px',
        overflow: 'auto',
        scrollbarWidth: 'none',
        msOverflowStyle: 'none',
      },
    }}
    sx={{
      display: 'block',
      padding,
      '& .MuiOutlinedInput-notchedOutline': {
        border: outlined ? '' : 'none',
      },
    }}
  />
);

export default Editor;
