import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';

import { TEmoji, TEmojiPicker } from './types';

const EmojiPicker: React.FC<TEmojiPicker> = ({ onEmojiSelect, inputElement }) => {
  const handleEmojiSelect = (emoji: TEmoji) => {
    onEmojiSelect?.(emoji, inputElement as HTMLInputElement);
  };

  return (
    <Picker
      set='native'
      data={data}
      maxFrequentRows={1}
      showPreview={false}
      showSkinTones={false}
      previewPosition='none'
      onEmojiSelect={handleEmojiSelect}
    />
  );
};

export default EmojiPicker;
