import { useEffect, useState } from 'react';

const useVideoTimeUpdate = (videoRef: React.RefObject<HTMLVideoElement>) => {
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [duration, setDuration] = useState<number>(0);
  const [isLoadedMetadata, setIsLoadedMetadata] = useState<boolean>(false);

  useEffect(() => {
    const videoElement = videoRef.current;

    const handleTimeUpdate = () => {
      setCurrentTime(videoElement?.currentTime || 0);
    };

    const handleLoadedMetadata = () => {
      setIsLoadedMetadata(true);
      setDuration(videoElement?.duration || 0);
      setIsLoadedMetadata(false);
    };

    if (videoElement) {
      videoElement.addEventListener('timeupdate', handleTimeUpdate);
      videoElement.addEventListener('loadedmetadata', handleLoadedMetadata);

      // Initial update
      setCurrentTime(videoElement.currentTime);
      setDuration(videoElement.duration);

      // Cleanup event listeners when the component unmounts
      return () => {
        videoElement.removeEventListener('timeupdate', handleTimeUpdate);
        videoElement.removeEventListener('loadedmetadata', handleLoadedMetadata);
      };
    }
  }, [videoRef]);

  return { currentTime, duration, isLoadedMetadata };
};

export default useVideoTimeUpdate;
