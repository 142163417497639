import { Box } from '@mui/material';
import { FC } from 'react';

import SectionHead from 'components/views/Drawers/CatalogCreateConfigure/SectionHead';
import { useAppSelector } from 'hooks';
import { currentCatalogByIdSelector } from 'store/slices/catalogSlice/selectors';
import { CustomSkeleton, CustomTypography } from 'components';

import styles from './GeneralInformationView.module.scss';
import { TGeneralInformationViewProps } from './types';

const GeneralInformationView: FC<TGeneralInformationViewProps> = ({ isLoading }) => {
  const currentCatalog = useAppSelector(currentCatalogByIdSelector);

  const renderedInfoItems = currentCatalog?.general_info?.map((item) => (
    <Box key={item?.id} className={styles.container__content__main}>
      <CustomTypography className={styles.container__content__main__item} title={item?.name}>
        Name:{item?.name}
      </CustomTypography>
      <span className={styles.container__content__main__item_detector}> {':'}</span>

      <CustomTypography className={styles.container__content__main__item} title={item?.value}>
        Value:{item?.value}
      </CustomTypography>
      <span className={styles.container__content__main__item_detector}> {':'}</span>
      <CustomTypography className={styles.container__content__main__item} title={item?.value}>
        Type:{item?.type}
      </CustomTypography>
      {currentCatalog?.general_info?.length > 1 && (
        <span className={styles.container__content__main__item_detector}> {','}</span>
      )}
    </Box>
  ));

  return (
    <Box className={styles.container}>
      <SectionHead
        withUnderlinedTitle
        title='General Information:'
        subtitle={`(The general information fields appear on the estimate screen to capture custom information.)`}
      />
      {isLoading ? (
        <CustomSkeleton height={50} width='100%' />
      ) : (
        <Box className={styles.container__content}>{renderedInfoItems}</Box>
      )}
    </Box>
  );
};

export default GeneralInformationView;
