import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { AxiosCacheInstance, buildMemoryStorage, setupCache } from 'axios-cache-interceptor';
import { endpoints } from 'api/endpoints';
import {
  TSearch,
  TNewRole,
  TPagination,
  TUpdateUser,
  TNewUserRole,
  TUpdateUserSettings,
  TUpdateInvitationStatus,
} from 'store/slices/authSlice/types';
import { BrowserStorageKeys, BrowserStorageService } from 'services';
import { TAuthHeader } from 'api/types';
import store from 'store';
import { getNewToken } from 'store/slices/authSlice/authThunks';
import localforage from 'localforage';


// const backendbaseURL = process.env.BACKEND_APP_BASE_URL;
const backendbaseURL = "https://microservices-30393916755.us-central1.run.app/api";

console.log('backend URL');
console.log(backendbaseURL);

const client: AxiosInstance = axios.create({
  baseURL: backendbaseURL,
});

client.interceptors.request.use((config: any) => {
  const token =
    BrowserStorageService.get(BrowserStorageKeys.AccessToken) ||
    BrowserStorageService.get(BrowserStorageKeys.AccessToken, { session: true });

  if (token) {
    config.headers = {
      ...config.headers,
      ...({
        Authorization: `Bearer ${JSON.parse(token)}`,
      } as TAuthHeader),
    };
  }
  return config;
});

client.interceptors.response.use((response: AxiosResponse) => {
  return response;
});

client.interceptors.response.use(null, async (error) => {
  const errorStatus = error.response.status === 403 || error.response.status === 401;
  if (error.response && errorStatus) {
    try {
      const response: any = await store.dispatch(getNewToken());

      const errorMessageExist =
        (response?.error && response?.error?.message?.includes('401')) ||
        response?.error?.message?.includes('500') ||
        response?.error?.message?.includes('400');
      response?.error?.message?.includes("could not connect to organization's resource");

      const inInvitedUsersPage = window.location.pathname?.includes('sign-up');

      if (errorMessageExist && !inInvitedUsersPage) {
        localStorage.clear();
        sessionStorage.clear();
        localforage.clear();
        window.location.href = '/';
      }

      const token =
        BrowserStorageService.get(BrowserStorageKeys.AccessToken) ||
        BrowserStorageService.get(BrowserStorageKeys.AccessToken, { session: true });

      if (token) {
        const config = error.config;
        config.headers.Authorization = `Bearer ${JSON.parse(token)}`;
        return axios.request(config);
      }
    } catch (error) {
      const Error = error as AxiosError;

      throw Error;
    }
  }
  return Promise.reject(error);
});

export const signInRequest = (email: string) =>
  client.post(endpoints.AuthService.signIn(), null, { params: { email } });

export const signUpRequest = (email: string) =>
  client.post(endpoints.AuthService.signUp(), null, { params: { email } });

export const signInOauthRequest = (params: FormData) => {
  return client.post(endpoints.AuthService.signInOAuth(), params);
};

export const refreshTokenRequest = () => {
  const refreshToken =
    BrowserStorageService.get(BrowserStorageKeys.RefreshToken) ||
    BrowserStorageService.get(BrowserStorageKeys.RefreshToken, { session: true });

  const config = {
    headers: {
      Authorization: `Bearer ${JSON.parse(String(refreshToken))}`,
    },
  };

  return axios.get(
    `https://microservices-30393916755.us-central1.run.app/api${endpoints.AuthService.refreshToken()}`,
    config,
  );
};

export const getUserByTokenRequest = () => client.get(endpoints.AuthService.getUserByToken());

export const getUserByIdRequest = (id: number) =>
  client.get(endpoints.AuthService.getUserById(), { params: { id } });

export const getUserPermissionsRequest = () =>
  client.get(endpoints.AuthService.getUserPermissions());

export const searchUserByQueryRequest = (params: TSearch) =>
  client.get(endpoints.AuthService.searchUserByQuery(params.query), { params });

export const updateUserRequest = (params: TUpdateUser) =>
  client.put(endpoints.AuthService.updateUser(), { ...params });

export const updateUserImage = (profile_image: FormData) => {
  const options = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };
  return client.put(endpoints.AuthService.updateImage(), profile_image, options);
};

export const getSettingsRequest = () => client.get(endpoints.AuthService.getUserSettings());

export const updatetUserSettingsRequest = (options: TUpdateUserSettings) =>
  client.put(endpoints.AuthService.updateUserSettings(), { ...options });

export const getAllRolesRequest = (params: TPagination) =>
  client.get(endpoints.AuthService.getAllRoles(), { params });

export const createRoleRequest = (role: TNewRole) =>
  client.post(endpoints.AuthService.createRole(), {
    new_role: role,
  });

export const getRoleByIdRequest = (id: number) => client.get(endpoints.AuthService.getRoleById(id));

export const createUserRoleRequest = (role: TNewUserRole[]) =>
  client.post(endpoints.AuthService.createUserRole(), {
    new_user_roles: role,
  });

export const getAllUserRolesRequest = () => client.get(endpoints.AuthService.getAllUserRoles());

export const deleteUserRoleRequest = (user_role_ids: number[]) =>
  client.delete(endpoints.AuthService.deleteUserRole(), { data: user_role_ids });

export const getInvitationByIdRequest = (id: number) =>
  client.get(endpoints.AuthService.getInvitationById(id));

export const updatetInvitationStatusRequest = (params: TUpdateInvitationStatus) =>
  client.put(endpoints.AuthService.updateInvitationStatus(params.id, params.status), {
    ...params,
  });

export const getUsersByRoleRequest = (role_id: number) =>
  client.get(endpoints.AuthService.getUsersByRole(), { params: { role_id } });

export const resendInvitationToUserRequest = (id: number) =>
  client.post(endpoints.AuthService.resendInvitationToUser(id));

export const deleteInvitedUserRequest = (id: number) =>
  client.delete(endpoints.AuthService.deleteInvitedUser(id));
