import { FC, useEffect } from 'react';
import { IconButton, TablePagination } from '@mui/material';

import { useWindowSize } from 'hooks';
import { ArrowDown, PaginationStepperIcon } from 'assets/icons';

import { breakPoints } from '../../../constants';

import { sxStyles } from './styles';

import type { TDynamicPaginationControlProps } from './types';

const CustomSelectIcon = (props: any) => {
  return <ArrowDown width={16} height={16} {...props} />;
};

const DynamicPaginationControl: FC<TDynamicPaginationControlProps> = ({
  count,
  page,
  rowsPerPage,
  onPageChange,
  showButtons = true,
  isDisabled = false,
  onRowsPerPageChange,
  labelRowsPerPage = 'Show rows:',
  ...rest
}) => {
  const { width } = useWindowSize();

  const options = [5, 10, 25, 50, 100];

  useEffect(() => {
    onPageChange(null, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TablePagination
      {...rest}
      sx={sxStyles}
      page={page}
      count={count}
      component='div'
      rowsPerPage={rowsPerPage}
      onPageChange={onPageChange}
      rowsPerPageOptions={options}
      labelRowsPerPage={Number(width) >= breakPoints.TABLET ? labelRowsPerPage : 'Rows'}
      onRowsPerPageChange={onRowsPerPageChange}
      labelDisplayedRows={({ from, to, count }) =>
        `${from}-${to} of ${count} ${Number(width) >= breakPoints.TABLET ? 'rows' : ''}`
      }
      nextIconButtonProps={{ style: { display: showButtons ? 'flex' : 'none' } }}
      backIconButtonProps={{ style: { display: showButtons ? 'flex' : 'none' } }}
      ActionsComponent={() => (
        <>
          <IconButton
            onClick={(event) => onPageChange(event, page - 1)}
            disabled={page === 0 || isDisabled}
            sx={{ transform: 'rotate(180deg)' }}
          >
            <PaginationStepperIcon width={33} height={30} />
          </IconButton>

          <IconButton
            onClick={(event) => onPageChange(event, page + 1)}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1 || isDisabled}
          >
            <PaginationStepperIcon width={33} height={30} />
          </IconButton>
        </>
      )}
      SelectProps={{
        disabled: isDisabled,
        IconComponent: CustomSelectIcon,
      }}
    />
  );
};

export default DynamicPaginationControl;
