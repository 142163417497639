import { Box } from '@mui/material';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { BorderSquareIcon } from 'assets/icons';
import { Button } from 'components';
import { Colors, Routes } from 'types';

import styles from './PurchaseBox.module.scss';
import { TPurchaseBoxProps } from './types';

const PurchaseBox: FC<TPurchaseBoxProps> = ({
  onClose,
  withSubmit = true,
  content,
  title,
  isDisableButton = false,
}) => {
  const navigate = useNavigate();

  const createNewRequisition = () =>
    navigate(Routes.Requisition, {
      state: {
        isOpen: true,
      },
    });

  const renderedContent = content
    ? content
    : `Lorem ipsum dolor sit amet consectetur. Tempor Tempor elementum diam et facilisi faucibus
  sit proin nascetur Tempor elementum.`;

  const renderedTitle = title ? title : `  Want To Request For Items Not On The List?`;

  return (
    <Box className={styles.container}>
      <Box className={styles.container__head}>
        <Box className={styles.container__head__text}>{renderedTitle}</Box>
        <BorderSquareIcon onClick={onClose} />
      </Box>
      <Box className={styles.container__content}>
        <Box className={styles.container__content__text}>{renderedContent}</Box>
      </Box>
      {withSubmit && (
        <Box className={styles.container__footer}>
          <Button
            disabled={isDisableButton}
            borderRadius='5px'
            maxWidth='105px'
            padding='12.5px 0'
            color={Colors.WHITE}
            onClick={createNewRequisition}
            backgroundColor={Colors.SAPPHIRE}
          >
            SEND REQUEST
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default PurchaseBox;
