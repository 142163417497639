import { FC } from 'react';
import moment from 'moment';
import { Box } from '@mui/material';

import { CustomTypography } from 'components';

import styles from './PlanInvoiceDate.module.scss';

import type { TPlanInvoiceDate } from './types';

const PlanInvoiceDate: FC<TPlanInvoiceDate> = ({ planeName, paidDate }) => {
  const paidDateFormat = moment.unix(paidDate).format('MMM D, YYYY');

  const title = planeName?.includes('Business')
    ? 'Business'
    : planeName?.includes('Pro')
    ? 'Pro'
    : 'Enterprise';

  return (
    <Box className={styles.container}>
      <CustomTypography className={styles.container__title}>{title} - </CustomTypography>
      <CustomTypography className={styles.container__subtitle}>{paidDateFormat}</CustomTypography>
    </Box>
  );
};

export default PlanInvoiceDate;
