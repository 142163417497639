import type { TCreateCatalogVersionItem } from '../catalogSlice/types';
import type { TBillTo, TGeneralInfo } from 'components/shared/EstimateForms/types';

export type TInitialState = {
  discount: TDiscount;
  allEstimatesAsc: boolean;
  allEstimatesLimit: number;
  allEstimatesOffset: number;
  allEstimatesLoading: boolean;
  estimateItemLoading: boolean;
  estimateItem: TEstimate | null;
  allEstimates: TEstimateReturnType;
  discountCouponError: null | string;
  estimateSectionItemsLoader: boolean;
  allEstimatesFromTemplateLimit: number;
  allEstimatesFromTemplateOffset: number;
  estimateItemError: null | string | Error;
  allEstimatesError: null | string | Error;
};

export type TDiscount = {
  discount: number;
  inputValue: string;
};

export type TEstimate = {
  id: number;
  zip: string;
  city: string;
  name: string;
  uuid: string;
  state: string;
  phone: string;
  status: string;
  address: string;
  bill_to: TBillTo;
  catalog_id: number;
  version_no: string;
  created_at: string;
  updated_at: string;
  updated_by: number;
  created_by: number;
  customer_id: number;
  sub_total: number;
  total_tax: number;
  total_price: number;
  customer_name: string;
  discount_value: number;
  created_by_name: string;
  general_info: TGeneralInfo;
  discount: TEstimateDiscount[];
  catalog_versions_id: number;
  sections: TEstimateSection[];
  customer_info: TCustomerInfo;
  estimate_media_url_id: string;
  agreement_media_url_id: string;
  estimate_last_send: null | string;
  agreement_last_send: null | string;
};

export type TEstimateDiscount = {
  catalog_id: number;
  coupon_code: string;
  discount_percent: string;
  start_date: string;
  end_date: string;
  coupon_status: string;
  created_at: string;
  updated_at: string;
  id: number;
  created_by: number;
};

export type TCustomerInfo = {
  zip: string;
  city: string;
  email: string;
  state: string;
  address: string;
  full_name: string;
  phone_number: string;
};

export type TEstimateSection = {
  id: number;
  table_nm: string;
  estimate_id: number;
  section_uuid: string;
  section_name: string;
  profit_margin: number;
  created_at: null | string;
  updated_at: null | string;
  catalog_versions_id: number;
  items: TEstimateSectionItem[];
};

export type TEstimateSectionItem = {
  id: number;
  price: string;
  vendor: string;
  amount: string;
  part_name: string;
  description: string;
  section_uuid: string;
  qty_required: string;
  manufacturer: string;
  part_id: string | number;
  image_url_id: string | number;
} & Partial<{
  sectionId: number;
  uniqueId: TEstimateSectionItem;
}>;

export type TEstimateReturnType = {
  data: TEstimate[];
  total_count: number;
};

export enum SortByEnums {
  ID = 'id',
  NAME = 'name',
  CREATED = 'created_at',
  UPDATED = 'updated_at',
}

export type TEstimateOptions = {
  limit: number;
  offset: number;
} & Partial<{
  asc: boolean;
  sort_by: SortByEnums | null;
}>;

export type TEstimateFromTemplateOptions = {
  limit: number;
  offset: number;
};

export type TEstimateBody = {
  name: string;
  version_no: string | null;
  customer_id: null | number;
  section_uuids: string[] | null;
  catalog_versions_id: number | null;
};

export type TSendedEstimateFromTemplateData = {
  estimate_template_id: number;
  body: TEstimateFromTemplateBody;
};

export type TUpdateEstimateFromTemplateData = {
  estimate_template_id: number;
  uuid: string;
  body: TEstimateFromTemplateBody;
};

export type TEstimateFromTemplateBody = {
  name: string | null;
  description: string | null;
  customer_id: number | null;
  data: Record<string, Record<string, string | number | boolean | null>>;
};

export type TElementsOptions = {
  id: number | null;
};

export type TEstimateSectionOptions = {
  estimate_id: number;
  estimate_section_id: number;
};

export type TEstimateSectionItemOptions = {
  item_id: number;
  estimate_id: number;
  estimate_section_id: number;
};

export type TEstimateSectionItemWithBody = {
  estimate_id: number;
  estimate_section_id: number;
  body: TCreateCatalogVersionItem;
};

export type TEditEstimate = {
  id: number;
  body: TEstimate;
};

export type TEditEstimateSectionById = {
  price: number;
  item_id: number;
  quantity: number;
  part_name: string;
  estimate_id: number;
  description: string;
  estimate_section_id: number;
};

export type TGetEstimateSectionItems = {
  estimate_id: number;
  estimate_section_id: number;
};

export type TEstimateSectionItemsData = {
  id: number;
  price: string;
  vendor: string;
  part_id: string;
  part_name: string;
  description: string;
  section_uuid: string;
  manufacturer: string;
  image_url_id: string;
  qty_required: string;
};

export type TEstimateSectionItemsDataAll = {
  id: number;
  table_nm: string;
  estimate_id: number;
  section_uuid: string;
  section_name: string;
  profit_margin: number;
  catalog_versions_id: number;
  created_at: null | string | Date;
  updated_at: null | string | Date;
  items: TEstimateSectionItemsData[];
};

export type TSendedEstimateSectionData = {
  section_uuid: string;
  estimate_id: number;
  profit_margin: number;
  catalog_versions_id: number;
};

export type TSendedEstimateSectionDataOptions = {
  id: number;
  body: TSendedEstimateSectionData;
};

export type TEditProfitMarginData = {
  estimate_id: number;
  estimate_section_id: number;
  body: TEditProfitMarginDataBody;
};

export type TEditProfitMarginDataBody = {
  id: number;
  profit_margin: string | number;
};

export type TEstimateEdit = {
  id: number;
  zip: string;
  name: string;
  city: string;
  phone: string;
  state: string;
  status: string;
  address: string;
  version_no: string;
  customer_id: number;
  bill_to: TBillToEdit;
  general_info: TGeneralInfoEdit;
  customer_info: TCustomerInfo;
};

export type TGeneralInfoEdit = {
  site_name: string;
  site_email: string;
  site_phone: string;
  close_date: string;
  site_address: string;
  operate_date: string;
  contract_term: string;
  closing_probability: string;
};

export type TBillToEdit = {
  zip: string;
  city: string;
  state: string;
  address: string;
  bill_to: string;
  phone_number: string;
};

export type TEstimateEditOptions = {
  id: number;
  body: TEstimateEdit;
};
