import { useEffect, useState } from 'react';
import { CircularProgress, Stack } from '@mui/material';

import { useAppDispatch } from 'hooks';
import { getAllUsers } from 'store/thunks';
import { getAllRoles } from 'store/slices/rolesSlice/thunks';
import PermissionsItem from 'components/views/PermissionsItem';
import { getUserPermissions } from 'store/slices/authSlice/authThunks';
import { TPreDefinedRoles } from 'components/views/PermissionsItem/types';

import styles from './Permissions.module.scss';

const Permissions = () => {
  const dispatch = useAppDispatch();

  const [preDefinedRoles, setPreDefinedRoles] = useState<TPreDefinedRoles[]>([]);

  const getPreDefinedRoles = async () => {
    const roles = await dispatch(getAllRoles('Pre-defined')).unwrap();
    setPreDefinedRoles(roles as TPreDefinedRoles[]);
  };

  const getPermissions = async () => {
    await dispatch(getUserPermissions());
  };

  useEffect(() => {
    dispatch(getAllUsers({ limit: 50, offset: 0 }));

    getPreDefinedRoles();
    getPermissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const permissionItems = preDefinedRoles?.map((role, idx) => {
    return <PermissionsItem key={`${role.id}/${idx}`} role={role} idx={idx} />;
  });

  return (
    <div className={styles.container}>
      {preDefinedRoles.length ? (
        permissionItems
      ) : (
        <Stack alignItems='center'>
          <CircularProgress />
        </Stack>
      )}
    </div>
  );
};

export default Permissions;
