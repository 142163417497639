import { FC, useEffect, useState } from 'react';
import { Box, Stack } from '@mui/material';

import { AppDispatch } from 'types';
import { CustomTypography, FocusedSimpleButton } from 'components';
import { useAppDispatch, usePostLikedUsers } from 'hooks';
import { LikeActiveIcon, LikeDisableIcon } from 'assets/icons';

import PostLikesView from '../PostLikesView';

import styles from './PostLikeToggle.module.scss';

import type { TPostLikeToggle } from './types';
import type {
  TLikePostComment,
  TPostLikedUserDataReturnTypeNew,
} from 'store/slices/activitiesSlices/types';

const PostLikeToggle: FC<TPostLikeToggle> = ({
  id,
  postId,
  isLiked,
  forPosts = false,
  likeRequest,
  unLikeRequest,
  addLikesCount,
  activityId,
  getPostComments,
  decreaseLikesCount,
}) => {
  const dispatch = useAppDispatch();

  const [liked, setLiked] = useState<boolean>(isLiked);

  const { data, getPostLikedUsersData } = usePostLikedUsers(id as number);

  const textColor = liked ? '#2067DD' : '#667085';

  useEffect(() => {
    setLiked(isLiked);
  }, [isLiked]);

  const likePost = async () => {
    if (!liked) {
      setLiked(true);
      if (postId) {
        const sendedParams: TLikePostComment = {
          parent_feed_id: postId,
          comment_id: id,
        };

        addLikesCount?.();
        await dispatch(likeRequest(sendedParams) as AppDispatch);

        getPostComments?.();
      } else {
        await dispatch(likeRequest(id) as AppDispatch);
        getPostLikedUsersData();
      }
    } else {
      decreaseLikesCount?.();
      await dispatch(unLikeRequest(id) as AppDispatch);
      getPostComments?.();
      if (activityId) {
        getPostLikedUsersData();
      }

      setLiked(false);
    }
  };

  return (
    <Stack gap='5px'>
      <Box className={styles.container}>
        <FocusedSimpleButton className={styles.container__content} onClick={likePost}>
          <CustomTypography className={styles.container__title} color={textColor}>
            Like
          </CustomTypography>
          {liked ? <LikeActiveIcon /> : <LikeDisableIcon />}
        </FocusedSimpleButton>
        {forPosts && activityId && <PostLikesView data={data as TPostLikedUserDataReturnTypeNew} />}
      </Box>
    </Stack>
  );
};

export default PostLikeToggle;
