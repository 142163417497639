import { useState } from 'react';
import { Controller } from 'react-hook-form';
import { Autocomplete, AutocompleteInputChangeReason } from '@mui/material';

import { useNumberFormatInput } from 'hooks';
import { CustomTextField } from 'components';

import type { TControlledAutocompleteProps } from './types';
import type { TChangeInputEvent } from 'types/global/events';

const ControlledNumberAutocomplete = <T,>({
  name,
  label,
  control,
  options,
  multiline,
  inputName,
  helperText,
  placeholder,
  optionsName,
  borderColor,
  borderRadius,
  backgroundColor,
  required = true,
  handleInputChange,
  chooseChangedReason,
  fontFamily = 'CircularStdRegular',
}: TControlledAutocompleteProps<T>) => {
  const [formatInputValue, setInputValue] = useState<string>('');

  const { onKeyDown, formatPhoneNumber, isValid } = useNumberFormatInput(formatInputValue);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, ...field } }) => {
        return (
          <Autocomplete
            freeSolo
            inputValue={formatInputValue}
            popupIcon={false}
            clearIcon={false}
            sx={{
              fontFamily,
              minWidth: '100%',
              '& .MuiFormLabel-root': {
                fontFamily: fontFamily,
              },
            }}
            multiple={false}
            onChange={(_, data) => {
              if (data) {
                onChange(data || '');
              }
            }}
            onInputChange={(
              event: React.SyntheticEvent,
              value: string,
              reason: AutocompleteInputChangeReason,
            ) => {
              const newValue = formatPhoneNumber(value);
              setInputValue(newValue);
              chooseChangedReason?.(reason, 'phone');
            }}
            options={options || []}
            {...field}
            readOnly={false}
            getOptionLabel={(options) => (options[optionsName] ? options[optionsName] : '')}
            renderInput={(params) => (
              <CustomTextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  onChange: (event: TChangeInputEvent) => {
                    handleInputChange?.(event);
                  },
                }}
                label={label}
                withHelperText
                name={inputName}
                onKeyDown={onKeyDown}
                value={formatInputValue}
                required={required}
                multiline={multiline}
                fontFamily={fontFamily}
                helperText={helperText}
                placeholder={placeholder}
                borderColor={borderColor}
                borderRadius={borderRadius}
                backGroundColor={backgroundColor}
                onChange={params?.inputProps?.onChange}
                error={formatInputValue?.length ? !isValid : false}
              />
            )}
          />
        );
      }}
    />
  );
};

export default ControlledNumberAutocomplete;
