import { TLinkValues } from './types';

export const parseHTML = (htmlContent: string): TLinkValues[] => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlContent, 'text/html');
  const links = doc.querySelectorAll('a');

  const linksArray = Array.from(links).map((link) => {
    return {
      attributes: { link: link.getAttribute('href') },
      insert: link?.textContent?.trim() || '',
    };
  });

  return linksArray as TLinkValues[];
};
