import { Box } from '@mui/material';
import { FC } from 'react';

import CustomersViewInfo from '../CustomersViewInfo';

import styles from './BillingInformationView.module.scss';

import type { TBillingInformationViewProps } from './types';

const BillingInformationView: FC<TBillingInformationViewProps> = ({ billing }) => (
  <Box className={styles.container}>
    <Box className={styles.container__item}>
      <CustomersViewInfo title='Bill To' subtitle={billing?.bill_to || '--'} />
      <CustomersViewInfo title='Email' subtitle={billing?.email || '--'} />
    </Box>
    <Box className={styles.container__item}>
      <CustomersViewInfo title='Phone Number' subtitle={billing?.phone_number || '--'} />
      <CustomersViewInfo title='State' subtitle={billing?.state || '--'} />
    </Box>
    <Box className={styles.container__item}>
      <CustomersViewInfo title='Address' subtitle={billing?.address || '--'} />
      <CustomersViewInfo title='Zip' subtitle={billing?.zip || '--'} />
    </Box>
    <Box className={styles.container__item}>
      <CustomersViewInfo title='City' subtitle={billing?.city || '--'} />
    </Box>
  </Box>
);

export default BillingInformationView;
