import { useState, useEffect } from 'react';
import { isMobile, isTablet, isIOS } from 'react-device-detect';

const useMobileDetect = () => {
  const [isMobileDevice, setIsMobileDevice] = useState(false);
  const [isTabletDevice, setIsTabletDevice] = useState(false);
  const [isIOSDevice, setIsIOSDevice] = useState(false);

  useEffect(() => {
    setIsMobileDevice(isMobile);
    setIsTabletDevice(isTablet);
    setIsIOSDevice(isIOS);
  }, []);

  return {
    isMobile: isMobileDevice,
    isTablet: isTabletDevice,
    isDesktop: !(isMobileDevice || isTabletDevice),
    isIos: isIOSDevice,
  };
};

export { useMobileDetect };
