import type { RootState } from 'types';

export const liveStreamSelector = (state: RootState) => state.livestream;

export const allLiveStreamSelector = (state: RootState) => state.livestream.allLiveStream;

export const myCurrentStreamSelector = (state: RootState) => state.livestream.myCurrentStream;

export const myCurrentStreamLoadSelector = (state: RootState) =>
  state.livestream.myCurrentStreamLoad;

export const myCurrentStreamErrorSelector = (state: RootState) =>
  state.livestream.myCurrentStreamError;

export const allLiveStreamLoadingSelector = (state: RootState) =>
  state.livestream.allLiveStreamLoading;

export const allLiveStreamErrorSelector = (state: RootState) => state.livestream.allLiveStreamError;

export const allLiveStreamLimitSelector = (state: RootState) => state.livestream.allLiveStreamLimit;

export const allLiveStreamOffsetSelector = (state: RootState) =>
  state.livestream.allLiveStreamOffset;

export const liveStreamByIdSelector = (state: RootState) => state.livestream.liveStreamById;

export const liveStreamByIdLoadingSelector = (state: RootState) =>
  state.livestream.liveStreamByIdLoading;

export const liveStreamByIdErrorSelector = (state: RootState) =>
  state.livestream.liveStreamByIdError;

export const liveStreamMessagesSelector = (state: RootState) => state.livestream.liveStreamMessages;

// Details
export const currentStreamDetailsSelector = (state: RootState) =>
  state.livestream.currentStreamDetails;

export const currentStreamDetailsErrorSelector = (state: RootState) =>
  state.livestream.currentStreamDetailsError;

export const currentStreamDetailsLimitSelector = (state: RootState) =>
  state.livestream.currentStreamDetailsLimit;

export const currentStreamDetailsLoadSelector = (state: RootState) =>
  state.livestream.currentStreamDetailsLoad;

export const currentStreamDetailsOffsetSelector = (state: RootState) =>
  state.livestream.currentStreamDetailsOffset;

// Previous streams

export const userCreatedStreamsSelector = (state: RootState) => state.livestream.userCreatedStreams;

export const userCreatedStreamsErrorSelector = (state: RootState) =>
  state.livestream.userCreatedStreamsError;

export const userCreatedStreamsLimitSelector = (state: RootState) =>
  state.livestream.userCreatedStreamsLimit;

export const userCreatedStreamsLoadSelector = (state: RootState) =>
  state.livestream.userCreatedStreamsLoad;

export const userCreatedStreamsOffsetSelector = (state: RootState) =>
  state.livestream.userCreatedStreamsOffset;
