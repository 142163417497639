import { FC } from 'react';
import { Box } from '@mui/material';

import { useWindowSize } from 'hooks';
import { CustomTypography, CustomizedAvatar, DrawerLayout } from 'components/shared';

import { breakPoints } from '../../../../constants';

import styles from './LiveStreamDetailsDrawer.module.scss';

import type { TLiveStreamDetailsDrawerProps } from './types';

const LiveStreamDetailsDrawer: FC<TLiveStreamDetailsDrawerProps> = ({
  open,
  handleClose,
  members,
}) => {
  const { width } = useWindowSize();

  const renderUsers = members?.map((member, index: number) => (
    <div key={index} style={{ display: 'flex', flexDirection: 'column', gap: 24 }}>
      <Box className={styles.container__users}>
        <CustomizedAvatar withBorder alt='Remy Sharp' src={member.profile_image_url_id} />
        <CustomTypography className={styles.container__users__name}>
          {member.external_name}
        </CustomTypography>
      </Box>
    </div>
  ));

  const drawerWidth =
    Number(width) <= breakPoints.TABLET_L && Number(width) > breakPoints?.TABLET_M
      ? 468
      : Number(width) <= breakPoints.TABLET_M && Number(width) >= breakPoints.MOBILE
      ? 350
      : Number(width) <= breakPoints.MOBILE
      ? 375
      : 576;

  return (
    <DrawerLayout
      padding='0'
      open={open}
      width={drawerWidth}
      onClose={handleClose}
      titleClassName={styles.container__header__title}
      headerTitle={`Attendees (${renderUsers?.length})`}
      headerContainerClassName={styles.container__header}
    >
      <Box className={styles.container}>{renderUsers}</Box>
    </DrawerLayout>
  );
};

export default LiveStreamDetailsDrawer;
