/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { BaseSyntheticEvent, FC, useRef, useState } from 'react';
import classNames from 'classnames';
import usePortal from 'react-useportal';
import { Menu } from '@mui/material';

import { Colors } from 'types';
import { SmileIcon } from 'assets/icons';
import { useOnClickOutside } from 'hooks';
import { CustomTextField, EmojiPicker } from 'components';

import styles from './CommentForm.module.scss';
import { menuStyles } from './styles';

import type { TCommentFormProps } from './types';

const CommentForm: FC<TCommentFormProps> = ({
  id,
  className,
  inputRef,
  addComment,
  commentInput,
  defaultValue,
  autoFocus,
  placeholder,
  inputClassName,
  handleEmojiSelect,
  inEditMode = false,
  forComments = false,
  forSettings = false,
  handleInputChange,
}) => {
  const formClasses = classNames(styles.container, className);
  const inputClasses = classNames(styles.container__input, inputClassName);
  const emojiPickerRef = useRef<any>(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const menuRef = useRef<HTMLDivElement | null>(null);

  // const portalContainerRef = useRef<any>(null);

  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const elementValue = useRef<HTMLInputElement | null>(null);

  const handleEndAdornmentClick = (event: BaseSyntheticEvent) => {
    if (id) {
      const inputElement = document.getElementById(id) as HTMLInputElement;

      inputElement.focus();
      elementValue.current = inputElement;
    }

    if (!forComments) {
      setShowEmojiPicker(!showEmojiPicker);
    } else {
      setAnchorEl(event.currentTarget);
      event.stopPropagation();
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const hidEmojiPicker = () => {
    setShowEmojiPicker(false);
  };

  useOnClickOutside(emojiPickerRef, hidEmojiPicker);

  const handleAddComment = async (event: React.SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (isSubmitting) {
      return;
    }

    setIsSubmitting(true);

    try {
      await addComment(event);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error adding comment:', error);
    } finally {
      setIsSubmitting(false);
      setShowEmojiPicker(false);
      handleClose();
    }
  };

  const emojiPickerStyles = classNames(styles.container__emoji, {
    [styles.container__emoji_settings]: forSettings,
    [styles.container__emoji_comments]: forComments,
  });

  return (
    <>
      <div ref={emojiPickerRef} className={styles.container}>
        <form onSubmit={handleAddComment} className={formClasses}>
          <CustomTextField
            id={id}
            inputRef={inputRef}
            borderRadius='10px'
            focused={inEditMode}
            borderWidth='0.5px'
            autoFocus={autoFocus}
            value={commentInput}
            borderColor={Colors.SOFT_SILVER}
            className={inputClasses}
            padding='12px'
            rightIcon={<SmileIcon />}
            defaultValue={defaultValue}
            onChange={handleInputChange}
            backGroundColor={Colors.FROST_BLUE}
            labelColor={Colors.ENIGMATIC_MIDNIGHT}
            placeholder={placeholder || 'Write a comment'}
            onEndAdornmentClick={handleEndAdornmentClick}
          />
        </form>
        {forComments ? (
          <Menu
            ref={menuRef}
            sx={menuStyles}
            anchorEl={anchorEl}
            onClose={handleClose}
            open={Boolean(anchorEl)}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          >
            <div className={emojiPickerStyles}>
              <EmojiPicker onEmojiSelect={handleEmojiSelect} inputElement={elementValue.current} />
            </div>
          </Menu>
        ) : (
          <>
            {showEmojiPicker && (
              <div className={emojiPickerStyles}>
                <EmojiPicker
                  onEmojiSelect={handleEmojiSelect}
                  inputElement={elementValue.current}
                />
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default CommentForm;
