import { FC } from 'react';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import {
  documentTypes,
  matchDocumentTypes,
} from 'containers/Documents/Contents/DocumentItem/utils';
import { useAppSelector } from 'hooks';
import { DocumentCardIcon } from 'assets/icons';
import { documentTypesSelector } from 'store/slices/documentsSlice/selectors';

import { TDocumentCard } from './types';
import styles from './DocumentCard.module.scss';

const DocumentCard: FC<TDocumentCard> = ({ document, id, section, itemName }) => {
  const navigate = useNavigate();
  const documents = useAppSelector(documentTypesSelector);

  const matchedDocuments = matchDocumentTypes({ documents, documentTypes });

  const filteredDocuments = matchedDocuments.filter((el) => el.matched);

  const handleClick = () => {
    const isClickable = filteredDocuments.find((el) => el.label === document);
    if (section) {
      if (isClickable) {
        navigate(`/documents/${itemName}/${id}${`/${section}`}`);
      }
    } else {
      navigate(`/documents/${itemName}/${id}`);
    }
  };

  return (
    <Box className={styles.container} onClick={handleClick} role='button'>
      <Box className={styles.container__content}>
        <DocumentCardIcon width={39} height={30} className={styles.container__content__file} />
        <div className={styles.container__content__link}>{document}</div>
      </Box>
    </Box>
  );
};

export default DocumentCard;
