import { GridColDef } from '@mui/x-data-grid';

import { TableCell } from 'components';
import HeaderTitle from 'components/tables/EmployeeTable/HeaderTitle';

export const columns: GridColDef[] = [
  {
    field: 'itemNumber',
    headerName: 'Item #',
    flex: 1,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle title={headerName} style={{ paddingLeft: 0 }} />;
    },
    renderCell: (params) => {
      return <TableCell style={{ paddingLeft: '0' }} title={params?.value} />;
    },
  },
  {
    field: 'description',
    headerName: 'Description',
    flex: 1.5,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle title={headerName} style={{ paddingLeft: 0 }} />;
    },
    renderCell: (params) => {
      return <TableCell style={{ paddingLeft: '0' }} title={params?.value} />;
    },
  },
  {
    field: 'quantity',
    headerName: 'Qty',
    flex: 1,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle title={headerName} style={{ paddingLeft: 0 }} />;
    },
    renderCell: (params) => {
      return <TableCell style={{ paddingLeft: '0' }} title={params?.value} />;
    },
  },
  {
    field: 'unit_cost',
    headerName: 'Unit Price',
    flex: 1,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle title={headerName} style={{ paddingLeft: 0 }} />;
    },
    renderCell: (params) => {
      return <TableCell style={{ paddingLeft: '0' }} title={params?.value} />;
    },
  },

  {
    field: 'amount',
    headerName: 'Amount',
    flex: 1,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle title={headerName} style={{ paddingLeft: 0 }} />;
    },
    renderCell: (params) => {
      return (
        <TableCell
          style={{ paddingLeft: '0', width: '100%', maxWidth: '100%' }}
          title={params?.value}
        />
      );
    },
  },
];
