import { FC } from 'react';

import { AddIcon } from 'assets/icons';
import { Button, CustomTypography } from 'components';
import { PermissionMessages } from 'constants/PermissionMessages';
import { Colors } from 'types';

import styles from '../Team.module.scss';

import type { TTeamHeadProps } from './types';

const TeamHead: FC<TTeamHeadProps> = ({ disabled, onClick }) => (
  <div className={styles.container_header}>
    <div className={styles.container_header__content}>
      <CustomTypography className={styles.container_header_title}>Teams</CustomTypography>
      <Button
        color='white'
        onClick={onClick}
        disabled={disabled}
        startIcon={<AddIcon />}
        backgroundColor={Colors.SAPPHIRE}
        className={styles.container_header__content_button}
        textClassName={styles.container_header__content_button_text}
        tooltipMessage={disabled ? PermissionMessages.TeamCreationMessage : undefined}
      >
        Create Team
      </Button>
    </div>
  </div>
);

export default TeamHead;
