import React, { useRef, useState } from 'react';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';

import { useAppSelector, useOnClickOutside } from 'hooks';
import { ArrowBoldDown, GlobusIcon, TeamIcon } from 'assets/icons';
import { allTeamsSelector } from 'store/slices/teamsSlice/selectors';
import { arrowStyle } from 'components/dropdowns/EmployeeDropdown/styles';

import CustomTypography from '../Typography';

import styles from './Audience.module.scss';
import { sxStyles } from './styles';

import type { TAudience } from './types';
import type { TChangeInputEvent } from 'types/global/events';

const Audience: React.FC<TAudience> = ({
  selectedAudience,
  onChange,
  getActiveTeamId,
  currentOrganization,
}) => {
  const userTeams = useAppSelector(allTeamsSelector);

  const dropdownRef = useRef<null>(null);

  const [expanded, setExpanded] = useState(false);

  const handleAudienceChange = (event: TChangeInputEvent, id: number) => {
    onChange(event.target.value);

    getActiveTeamId(id);
  };

  const closeDropdown = () => {
    setExpanded(false);
  };

  const toggleArrow = () => {
    setExpanded(!expanded);
  };

  useOnClickOutside(dropdownRef, closeDropdown);

  const arrowClasses = arrowStyle(expanded);

  const teamRadios = userTeams?.data?.map((team) => {
    return (
      <div className={styles.container_content_absolute_content_item} key={team?.id}>
        <FormControlLabel
          labelPlacement='start'
          sx={sxStyles}
          control={
            <Radio
              value={team?.name}
              checked={selectedAudience === team?.name}
              onChange={(event: TChangeInputEvent) => handleAudienceChange(event, team?.id)}
            />
          }
          label={
            <div className={styles.container_content_absolute_content_item__flex}>
              <TeamIcon width={14} height={14} />

              <CustomTypography>{team?.name}</CustomTypography>
            </div>
          }
        />
      </div>
    );
  });

  return (
    <div ref={dropdownRef} className={styles.container}>
      <div className={styles.container_content} onClick={toggleArrow}>
        <CustomTypography className={styles.container_content__to}>submit to:</CustomTypography>
        {selectedAudience === currentOrganization?.name ? (
          <GlobusIcon />
        ) : (
          <TeamIcon width={14} height={14} />
        )}
        <CustomTypography>{selectedAudience}</CustomTypography>
        {!!userTeams?.data?.length && <ArrowBoldDown style={arrowClasses} width={12} height={12} />}
      </div>
      {expanded && (
        <div className={styles.container_content_absolute}>
          <div className={styles.container_content_absolute_content}>
            <div className={styles.container_content_absolute_content_item}>
              <FormControlLabel
                labelPlacement='start'
                sx={{
                  ...sxStyles,

                  '& .MuiButtonBase-root': {
                    width: '18px',
                    height: '18px',
                    padding: 0,
                  },
                }}
                control={
                  <Radio
                    checked={selectedAudience === currentOrganization?.name}
                    value={currentOrganization?.name}
                    onChange={(event: TChangeInputEvent) => handleAudienceChange(event, 0)}
                  />
                }
                label={
                  <div className={styles.container_content_absolute_content_item__flex}>
                    <GlobusIcon />
                    <CustomTypography role='div'>{currentOrganization?.name}</CustomTypography>
                  </div>
                }
              />
            </div>

            {!!userTeams?.data?.length && (
              <FormControl
                sx={{
                  width: '100%',
                }}
              >
                <RadioGroup>{teamRadios}</RadioGroup>
              </FormControl>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Audience;
