import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

import { workordersApi } from 'api';
import { BrowserStorageKeys, BrowserStorageService } from 'services';
import { RootState } from 'types';
import dataStructure from 'constants/options';
import { WorkOrderToastMessages } from 'constants/ToastMessages';

import {
  TAllTaskRequestoptions,
  TAllWorkOrderRequest,
  TTaskCreateBody,
  TTaskUpdateBody,
  TWorkOrderBody,
  TWorkOrderUpdate,
  WorkOrdersSortBy,
} from './types';

export const getWorkOrderById = createAsyncThunk(
  'workOrderSlice/getWorkOrderById',
  async (id: number) => {
    try {
      const response = await workordersApi.getWorkOrderById(id);
      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const getAllWorkOrders = createAsyncThunk(
  'workOrderSlice/getAllWorkOrders',
  async (options: TAllWorkOrderRequest | undefined, { getState }) => {
    const sortByFromStorage = BrowserStorageService.get(BrowserStorageKeys.WorkOrdersSortBy, {
      session: true,
    });

    const sortByAscFromStorage = BrowserStorageService.get(BrowserStorageKeys.WorkOrdersSortBy, {
      session: true,
    });

    const {
      workorders: { allWorkOrdersLimit, allWorkOrdersOffset },
    } = getState() as RootState;

    const isCheck = sortByFromStorage && JSON.parse(sortByFromStorage) === '__check__';

    const sortByParam = sortByFromStorage
      ? JSON.parse(sortByFromStorage)
      : options?.sort_by
      ? JSON.parse(options?.sort_by as string)
      : WorkOrdersSortBy.ID;

    const ascParam = sortByAscFromStorage
      ? JSON.parse(sortByAscFromStorage)
      : options?.asc || false;

    const {
      limit = allWorkOrdersLimit,
      asc = ascParam,
      offset = allWorkOrdersOffset,
      sort_by = isCheck ? WorkOrdersSortBy.ID : sortByParam || WorkOrdersSortBy.ID,
    } = options ?? {};

    try {
      const response = await workordersApi.getAllWorkOrders({
        limit,
        offset,
        sort_by,
        asc,
      });
      if (Array.isArray(response.data)) {
        return dataStructure;
      }
      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const getAllTasksById = createAsyncThunk(
  'workOrderSlice/getAllTasksById',
  async ({ id, options }: { id: number; options: TAllTaskRequestoptions }) => {
    try {
      const response = await workordersApi.getWorkOrderTasks(id, options);
      if (Array.isArray(response.data)) {
        return dataStructure;
      }
      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const getAllTasksByIdForUpdate = createAsyncThunk(
  'workOrderSlice/getAllTasksByIdForUpdate',
  async (id: number) => {
    try {
      const response = await workordersApi.getWorkOrderTasksForUpdate(id);
      if (Array.isArray(response.data)) {
        return dataStructure;
      }
      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const CreateWorkOrder = createAsyncThunk(
  'workOrderSlice/createWorkOrder',
  async (body: TWorkOrderBody, { dispatch, getState }) => {
    const start = toast.loading(WorkOrderToastMessages.WORK_ORDER_CREATE_START);
    const {
      workorders: { allWorkOrdersLimit, allWorkOrdersOffset },
    } = getState() as RootState;
    try {
      const response = await workordersApi.createWorkOrder(body);
      dispatch(getAllWorkOrders({ limit: allWorkOrdersLimit, offset: allWorkOrdersOffset }));

      toast.update(start, {
        render: WorkOrderToastMessages.WORK_ORDER_CREATION_SUCCESS,
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      toast.update(start, {
        render: WorkOrderToastMessages.WORK_ORDER_CREATION_FAILURE,
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });
      throw Error;
    }
  },
);

export const removeWorkOrderById = createAsyncThunk(
  'workOrderSlice/createWorkOrder',
  async (id: number, { dispatch, getState }) => {
    const start = toast.loading(WorkOrderToastMessages.WORK_ORDER_ARCHIVE_START);
    const {
      workorders: { allWorkOrdersLimit, allWorkOrdersOffset },
    } = getState() as RootState;
    try {
      const response = await workordersApi.deleteWorkOrder(id);

      dispatch(getAllWorkOrders({ limit: allWorkOrdersLimit, offset: allWorkOrdersOffset }));

      toast.update(start, {
        render: WorkOrderToastMessages.WORK_ORDER_ARCHIVE_SUCCESS,
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      toast.update(start, {
        render: WorkOrderToastMessages.WORK_ORDER_ARCHIVE_FAILURE,
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });

      throw Error;
    }
  },
);

export const deleteTaskById = createAsyncThunk(
  'workOrderSlice/deleteTaskById',
  async (id: number) => {
    const start = toast.loading(WorkOrderToastMessages.TASK_DELETE_START);

    try {
      const response = await workordersApi.deleteWorkOrderTaskById(id);

      toast.update(start, {
        render: WorkOrderToastMessages.TASK_DELETE_SUCCESS,
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      toast.update(start, {
        render: WorkOrderToastMessages.TASK_DELETE_FAILURE,
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });

      throw Error;
    }
  },
);

export const CreateWorkOrderTasks = createAsyncThunk(
  'workOrderSlice/createWorkOrderTasks',
  async (body: TTaskCreateBody[]) => {
    try {
      const response = await workordersApi.createWorkOrderTask(body);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      throw Error;
    }
  },
);

export const updateTask = createAsyncThunk(
  'workOrderSlice/updateTask',
  async (body: TTaskUpdateBody[]) => {
    try {
      const response = await workordersApi.updateWorkOrderTasks(body);
      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const updateWorkOrder = createAsyncThunk(
  'workOrderSlice/updateWorkorder',
  async (body: TWorkOrderUpdate) => {
    const start = toast.loading(WorkOrderToastMessages.WORK_ORDER_UPDATE_START);
    try {
      const response = await workordersApi.updateWorkOrder(body);

      toast.update(start, {
        render: WorkOrderToastMessages.WORK_ORDER_UPDATE_SUCCESS,
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      toast.update(start, {
        render: WorkOrderToastMessages.WORK_ORDER_UPDATE_FAILURE,
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });
      throw Error;
    }
  },
);

export const getTaskById = createAsyncThunk('workOrderSlice/getTaskById', async (id: number) => {
  try {
    const response = await workordersApi.getWorkOrderTaskById(id);
    return response.data;
  } catch (error) {
    const Error = error as AxiosError;
    throw Error;
  }
});
