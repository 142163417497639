import { client } from 'api/client';
import { endpoints } from 'api/endpoints';
import {
  TAllTaskRequestoptions,
  TAllWorkOrderRequest,
  TTaskCreateBody,
  TTaskUpdateBody,
  TWorkOrderBody,
  TWorkOrderUpdate,
} from 'store/slices/workOrderSlice/types';

export const getAllWorkOrders = (options: TAllWorkOrderRequest) => {
  return client.get(endpoints.WorkOrderServices.getAllWorkOrders(), { params: { ...options } });
};

export const updateWorkOrder = (body: TWorkOrderUpdate) => {
  return client.put(endpoints.WorkOrderServices.updateWorkOrder(), body);
};

export const createWorkOrder = (body: TWorkOrderBody) => {
  return client.post(endpoints.WorkOrderServices.createWorkOrder(), body);
};

export const createWorkOrderAssignee = () => {
  return client.post(endpoints.WorkOrderServices.createWorkOrderAssignee());
};

export const getWorkOrderById = (work_order_id: number) => {
  return client.get(endpoints.WorkOrderServices.getWorkOrderById(work_order_id));
};

export const deleteWorkOrder = (work_order_id: number) => {
  return client.delete(endpoints.WorkOrderServices.deleteWorkOrder(work_order_id), {
    params: { work_order_id: work_order_id },
  });
};

export const deleteWorkOrderAssignee = (id: number) => {
  return client.delete(endpoints.WorkOrderServices.deleteWorkOrderAssignee(id));
};

export const createWorkOrderJobType = () => {
  return client.post(endpoints.WorkOrderServices.createWorkOrderJobType());
};

export const deleteWorkOrderJobType = (id: number) => {
  return client.delete(endpoints.WorkOrderServices.deleteWorkOrderJobType(id));
};

export const getWorkOrderTasks = (id: number, options: TAllTaskRequestoptions) => {
  return client.get(endpoints.WorkOrderServices.getWorkOrderTasks(id), { params: { ...options } });
};

export const updateWorkOrderTasks = (body: TTaskUpdateBody[]) => {
  return client.put(endpoints.WorkOrderServices.updateWorkOrderTasks(), body);
};

export const createWorkOrderTask = (body: TTaskCreateBody[]) => {
  return client.post(endpoints.WorkOrderServices.createWorkOrderTask(), body);
};

export const getWorkOrderTaskById = (task_id: number) => {
  return client.get(endpoints.WorkOrderServices.getWorkOrderTaskById(task_id));
};

export const deleteWorkOrderTaskById = (task_id: number) => {
  return client.delete(endpoints.WorkOrderServices.deleteWorkOrderTaskById(task_id));
};

export const getWorkOrderTasksForUpdate = (id: number) => {
  return client.get(endpoints.WorkOrderServices.getWorkOrderTasks(id));
};
