import { RootState } from 'types';

export const catalogsSliceSelector = (state: RootState) => state.catalog;
export const catalogListSelector = (state: RootState) => state.catalog.catalogList;

export const catalogVersionsByIdSelector = (state: RootState) =>
  state.catalog.currentCatalogVersions;

export const catalogListLoadingSelector = (state: RootState) => state.catalog.catalogListLoading;

export const catalogListLimitSelector = (state: RootState) => state.catalog.catalogLimit;

export const catalogListOffsetSelector = (state: RootState) => state.catalog.catalogOffset;

export const currentCatalogByIdSelector = (state: RootState) => state.catalog.currentCatalogById;

export const currentCatalogByIdLoadSelector = (state: RootState) =>
  state.catalog.currentCatalogByIdLoad;

export const currentCatalogVersionsSelector = (state: RootState) =>
  state.catalog.currentCatalogVersions;

export const currentSectionVersionsSelector = (state: RootState) =>
  state.catalog.currentSectionVersions;

export const currentSectionVersionsLoadSelector = (state: RootState) =>
  state.catalog.currentSectionVersionsLoad;

export const currentSectionVersionsErrorSelector = (state: RootState) =>
  state.catalog.currentSectionVersionsError;

export const itemUsageDataSelector = (state: RootState) => state.catalog.itemUsageData;

export const itemUsageDataErrorSelector = (state: RootState) => state.catalog.itemUsageDataError;

export const itemUsageDataLoadSelector = (state: RootState) => state.catalog.itemUsageDataLoad;

export const itemUsageDataLimitSelector = (state: RootState) => state.catalog.itemUsageDataLimit;

export const itemUsageDataOffsetSelector = (state: RootState) => state.catalog.itemUsageDataOffset;

export const sharedCatalogDataSelector = (state: RootState) => state.catalog.sharedCatalogById;
