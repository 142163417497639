import { FC, useEffect } from 'react';
import { Box } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';

import { convertToMMDDYYYY, formatDate } from 'utils/formatDate';
import { getInvoiceById } from 'store/slices/invoicesSlice/thunks';
import { useAppDispatch, useAppSelector, useWindowSize } from 'hooks';
import { getCustomerById } from 'store/slices/customersSlices/thunks';
import {
  ScrollLayout,
  DrawerLayout,
  CustomDataGrid,
  CustomTypography,
  OrganizationLogo,
} from 'components';
import { generateInventoryItemNumber } from 'utils/generateInventoryItemNumber';
import { currentCustomerSelector } from 'store/slices/customersSlices/selectors';
import { currentInvoiceByIdSelector } from 'store/slices/invoicesSlice/selectors';
import { organizationByIdSelector } from 'store/slices/organizationsSlice/selectors';
import { EmptyTitles } from 'constants/EmptyTitles';

import { breakPoints } from '../../../constants';

import { columns } from './utils';
import { TInvoiceViewDrawerProps } from './types';
import styles from './InvoiceViewDrawer.module.scss';

const InvoiceViewDrawer: FC<TInvoiceViewDrawerProps> = ({ open, handleClose, id }) => {
  const dispatch = useAppDispatch();
  const { width } = useWindowSize();

  const currentInvoice = useAppSelector(currentInvoiceByIdSelector);
  const currentCustomer = useAppSelector(currentCustomerSelector);

  const organizationData = useAppSelector(organizationByIdSelector);

  useEffect(() => {
    if (id) {
      const getInvoice = async () => {
        await dispatch(getInvoiceById(Number(id)));
      };

      getInvoice();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (currentInvoice?.customer_id) {
      dispatch(getCustomerById(currentInvoice?.customer_id as number));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentInvoice?.customer_id, currentInvoice?.id]);

  const renderId = generateInventoryItemNumber(currentInvoice?.id as number);
  const renderDate = currentInvoice?.created_at
    ? formatDate(currentInvoice?.created_at as string)
    : '';

  const renderColumns: GridColDef[] = columns.map((column) => ({
    ...column,
    hideSortIcons: true,
    disableColumnMenu: true,
    disableColumnSelector: true,
  }));

  const renderRows = currentInvoice?.items?.map((el) => {
    return {
      id: el.id,
      name: el.part_name || '--',
      price: `$${el.unit_price}` || '--',
      qty: el.quantity || '--',
      amount: `$${el?.amount}.00` || '--',
    };
  });

  const rows = renderRows?.length ? renderRows : [];

  const drawerWidth =
    Number(width) >= breakPoints.WIDE
      ? 915
      : Number(width) <= breakPoints.WIDE && Number(width) >= breakPoints.TABLET_L
      ? 910
      : Number(width) <= breakPoints.TABLET_L && Number(width) >= breakPoints.TABLET
      ? 760
      : Number(width) <= breakPoints.TABLET && Number(width) >= breakPoints.TABLET_M
      ? 500
      : 375;

  return (
    <DrawerLayout
      open={open}
      onClose={handleClose}
      headerTitle=''
      width={drawerWidth}
      padding='24px'
    >
      <Box className={styles.container}>
        <Box className={styles.container__header}>
          <Box className={styles.container__header__info}>
            <Box className={styles.container__header__info__title}>
              Invoice
              <span className={styles.container__header__info__title__number}>#{renderId}</span>
            </Box>
            <CustomTypography className={styles.container__header__info__date}>
              {renderDate}
            </CustomTypography>
          </Box>
          <OrganizationLogo maxWidth='100px' minWidth='100px' />
        </Box>
        <Box className={styles.container__info}>
          <Box className={styles.container__info__item}>
            <CustomTypography className={styles.container__info__item__title}>
              {organizationData?.name}
            </CustomTypography>
            <Box className={styles.container__info__item__subtitle}>
              <CustomTypography>{organizationData?.address}</CustomTypography>
              <CustomTypography>{organizationData?.zip}</CustomTypography>
            </Box>
            <CustomTypography className={styles.container__info__item__subtitle}>
              {organizationData?.phone}
            </CustomTypography>
          </Box>
          <Box className={styles.container__info__item}>
            <CustomTypography className={styles.container__info__item__title}>
              {currentCustomer?.name}
            </CustomTypography>
            <Box className={styles.container__info__item__subtitle}>
              <CustomTypography>{currentCustomer?.address}</CustomTypography>
              <CustomTypography>{currentCustomer?.zip}</CustomTypography>
            </Box>

            <CustomTypography className={styles.container__info__item__subtitle}>
              {currentCustomer?.phone_number}
            </CustomTypography>
          </Box>
        </Box>
        <ScrollLayout>
          <CustomDataGrid
            rowHeight={45}
            rows={rows}
            headerHeight={60}
            borderStyle='dashed'
            showCellVerticalBorder
            columns={renderColumns}
            emptyTitle={EmptyTitles.Invoices}
          />
        </ScrollLayout>
        <Box className={styles.container__count}>
          <Box className={styles.container__count__item}>
            <CustomTypography className={styles.container__count__item__title}>
              Subtotal:
            </CustomTypography>
            <CustomTypography className={styles.container__count__item__subtitle}>{`$${
              currentInvoice?.sub_total || '00'
            }.00`}</CustomTypography>
          </Box>
          <Box className={styles.container__count__item}>
            <CustomTypography className={styles.container__count__item__title}>
              Tax:
            </CustomTypography>
            <CustomTypography className={styles.container__count__item__subtitle}>{`$${
              currentInvoice?.tax || '00'
            }.00`}</CustomTypography>
          </Box>
          <Box className={styles.container__count__item}>
            <CustomTypography className={styles.container__count__item__title}>
              Discount:
            </CustomTypography>
            <CustomTypography className={styles.container__count__item__subtitle}>
              {`$${currentInvoice?.discount || '00'}.00`}
            </CustomTypography>
          </Box>
          <Box className={styles.container__count__item}>
            <CustomTypography className={styles.container__count__item__title}>
              Shipping Fees:
            </CustomTypography>
            <CustomTypography className={styles.container__count__item__subtitle}>
              {`$${currentInvoice?.shipping_fees || '00'}.00`}
            </CustomTypography>
          </Box>
        </Box>
        <Box className={styles.container__footer}>
          <Box className={styles.container__footer__details}>
            <Box className={styles.container__footer__details__left}>
              <Box className={styles.container__footer__details__left__dates}>
                <CustomTypography className={styles.container__footer__details__left__date}>
                  Date Due:
                </CustomTypography>
                <CustomTypography className={styles.container__footer__details__left__date}>
                  {convertToMMDDYYYY(currentInvoice?.due_date as string)}
                </CustomTypography>
              </Box>
            </Box>
            <Box className={styles.container__footer__details__right}>
              <CustomTypography className={styles.container__footer__details__right__title}>
                Total Amount
              </CustomTypography>
              <CustomTypography className={styles.container__footer__details__right__total}>
                ${' '}
                <span className={styles.container__footer__details__right__total__count}>
                  {currentInvoice?.total_amount}.00
                </span>
              </CustomTypography>
            </Box>
          </Box>
          <Box className={styles.container__footer__notes}>
            <CustomTypography className={styles.container__footer__notes__title}>
              NOTES
            </CustomTypography>
            <CustomTypography className={styles.container__footer__notes__subtitle}>
              {currentInvoice?.notes}
            </CustomTypography>
          </Box>
        </Box>
      </Box>
    </DrawerLayout>
  );
};

export default InvoiceViewDrawer;
