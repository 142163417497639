import { Colors } from 'types';

export const sxStyles = {
  '& .MuiInputBase-root': {
    margin: '0 20px 0 8px',
    borderRadius: '4px',
    background: '#EBEBEB',
    cursor: 'pointer !important',
  },

  '& .Mui-disabled': {
    opacity: '0.6',
  },

  '& .MuiTablePagination-toolbar': {
    padding: '0',
  },

  '& .MuiInputBase-input': {
    paddingTop: '7px !important',
    paddingBottom: '7px !important',
    paddingLeft: '0 !important',
    fontSize: '14px',
    fontFamily: 'CircularStdRegular',
    fontWeight: 400,
    lineHeight: 'normal',
    letterSpacing: '0.014px',
    display: 'flex',
    justifyContent: 'center',

    '&:focus': {
      background: 'transparent !important',
    },
  },

  '& .MuiTablePagination-select': {
    paddingLeft: '10px !important',
  },

  '& .MuiTablePagination-displayedRows': {
    color: '#000',
    fontFamily: 'CircularStdRegular',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: 'normal',
    letterSpacing: '0.014px',
  },

  '& .MuiTablePagination-actions': {
    display: 'flex',
    gap: '16px',

    '& .MuiButtonBase-root': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '4px',
      backgroundColor: `${Colors.SAPPHIRE}`,

      path: {
        width: '20px',
        height: '20px',
        fill: 'white',
      },
    },
  },

  '& .MuiTablePagination-selectLabel': {
    color: '#000',
    fontFamily: 'CircularStdRegular',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: 'normal',
    letterSpacing: '0.014px',
  },

  '& .MuiSelect-icon': {
    width: '16px',
    height: '16px',
    margin: '0 5px 0 4px',
    top: 'calc(50% - 8px) !important',
  },
};
