export const sxStyles = (fontFamily: string) => ({
  width: '100%',

  '& .MuiFormLabel-root': {
    fontFamily: `${fontFamily} !important`,
  },

  '& .MuiAutocomplete-input': {
    fontFamily: `${fontFamily} !important`,
  },

  '& .MuiAutocomplete-endAdornment': {
    right: '16px !important',
  },

  ' .MuiAutocomplete-popupIndicatorOpen': {
    transform: 'rotateX(0deg)',

    'svg:last-child': {
      transform: 'rotateX(180deg)',
    },
  },

  '.MuiTouchRipple-root': {
    display: 'none',
  },
});
