import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';

type ApiResponse<T> = {
  data: {
    data: T[];
  };
};

type TApiFunction<T> = {
  (params: { limit: number; offset: number }): Promise<ApiResponse<T>>;
};

type TUseFetcherResult<T> = {
  getNewData: (limit: number, offset: number) => Promise<void>;
  data: T[];
  isLoad: boolean;
  error: null | AxiosError;
};

const useFetcher = <T>(
  limit: number,
  offset: number,
  initialData: T[],
  api: TApiFunction<T>,
): TUseFetcherResult<T> => {
  const [data, setData] = useState<T[]>(initialData);
  const [isLoad, setIsLoad] = useState<boolean>(false);
  const [error, setError] = useState<null | AxiosError>(null);

  useEffect(() => {
    setData(initialData);
  }, [initialData]);

  const getNewData = async (limit: number, offset: number) => {
    try {
      setIsLoad(true);
      const response: ApiResponse<T> = await api({ limit, offset });

      if (response?.data && Array.isArray(response?.data?.data) && !!response?.data?.data?.length) {
        setData((prevData) =>
          Array.isArray(prevData) ? [...prevData, ...(response?.data?.data as T[])] : [],
        );
      }
    } catch (error) {
      const Error = error as AxiosError;
      setError(Error);
      throw Error;
    } finally {
      setIsLoad(false);
    }
  };

  return {
    getNewData,
    data,
    isLoad,
    error,
  };
};

export default useFetcher;
