import { Box } from '@mui/material';
import { Circle, ColorResult } from '@uiw/react-color';
import { FC, useEffect, useState } from 'react';
import classNames from 'classnames';

import { useAppDispatch } from 'hooks';
import { createTags, updateTags } from 'store/slices/teamsSlice/thunks';
import { ArrowDown, PaletteIcon } from 'assets/icons';
import {
  createJobType,
  getAgreementById,
  getAllJobTypes,
  getJobTypeById,
  updateJobType,
} from 'store/slices/agreementSlice/thunks';
import { TChangeInputEvent } from 'types/global/events';
import { Colors } from 'types';

import CustomTypography from '../Typography';
import CustomTextField from '../TextField';
import { Button } from '..';

import styles from './CreateNewActivity.module.scss';
import { TCreateNewActivityProps } from './types';

const CreateNewActivity: FC<TCreateNewActivityProps> = ({
  title,
  className,
  setAddMore,
  setAddTag,
  agreementId,
  forAgreement,
  currentTypeId,
  currentTypeTitle,
  currentTypeColor,
}) => {
  const dispatch = useAppDispatch();

  const [hex, setHex] = useState<ColorResult | string>(currentTypeColor || '');
  const [tagName, setTagName] = useState<string>(currentTypeTitle || '');
  const [expanded, setExpanded] = useState<boolean>(false);

  const arrowClasses = classNames(styles.palette__arrow, {
    [styles.palette__arrow__expanded]: expanded,
  });

  const handleChange = (event: TChangeInputEvent) => {
    setTagName(event.target.value.trim());
  };
  const handleColorChange = (event: TChangeInputEvent) => {
    setHex(event.target.value);
  };

  const openPalette = () => {
    setExpanded(!expanded);
  };

  const onColorChange = (color: ColorResult) => {
    setHex(color.hex);
    setExpanded(false);
  };
  const handleSubmit = async () => {
    if (title === 'Create Tag') {
      dispatch(createTags({ name: tagName, color: hex }));
    } else if (title === 'Create Job Type') {
      dispatch(createJobType({ title: tagName, color: hex }));
    } else if (title === 'Update Job Type') {
      await dispatch(updateJobType({ id: currentTypeId as number, title: tagName, color: hex }));

      if (forAgreement) {
        dispatch(getAgreementById(agreementId as number));
      }
    } else if (title === 'Update Activity') {
      await dispatch(updateTags({ id: currentTypeId as number, name: tagName, color: hex }));
    }
    setAddMore && setAddMore(false);
    setAddTag && setAddTag(false);
  };

  useEffect(() => {
    dispatch(getJobTypeById(Number(currentTypeId)));
    dispatch(getAllJobTypes({ limit: 50, offset: 0 }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTypeId]);

  const paletteClasses = classNames(styles.palette, className);

  return (
    <Box className={paletteClasses}>
      <CustomTypography className={styles.palette__title}>{title}</CustomTypography>

      <CustomTextField
        required
        value={tagName}
        borderRadius='8px'
        borderColor={Colors.SOFT_SILVER}
        placeholder={`Title:*`}
        onChange={handleChange}
        className={styles.palette__input}
        backGroundColor={Colors.LIGHT_SILVER}
      />
      <CustomTextField
        required
        borderRadius='5px'
        placeholder='#0000ff'
        value={hex as string}
        backGroundColor={Colors.PLATINUM_GRAY}
        onChange={handleColorChange}
        className={styles.palette__input}
        leftIcon={
          <Box className={styles.palette__color}>
            <PaletteIcon />
          </Box>
        }
        rightIcon={<ArrowDown className={arrowClasses} onClick={openPalette} />}
      />
      {expanded && (
        <Circle
          colors={[
            '#d32f2f',
            '#c2185b',
            '#7b1fa2',
            '#00b0ff',
            '#00e5ff',
            '#1de9b6',
            '#ffeb3b',
            '#ffc107',
            '#ff9800',
            '#e64a19',
            '#5d4037',
            '#616161',
            '#bdbdbd',
            '#607d8b',
            '#944BBB',
            '#BA4A4A',
            '#531CB3',
            '#BA804A',
            '#CC92C2',
            '#F07BDC',
            '#7BF0F0',
            '#7FF07B',
            '#0E8E09',
            '#8E5B09',
          ]}
          color={hex as string}
          onChange={(color) => onColorChange(color)}
          className={styles.palette__circle}
        />
      )}

      <Box className={styles.palette__button}>
        <Button
          type='button'
          minWidth='65px'
          maxWidth='65px'
          variant='contained'
          onClick={handleSubmit}
          disabled={!tagName || !hex}
          className={styles.palette__button__item}
        >
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default CreateNewActivity;
