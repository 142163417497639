import { createContext, PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react';
import {
  Experimental_CssVarsProvider as MuiThemeProvider,
  StyledEngineProvider as MuiStyledEngineProvider,
  useColorScheme,
} from '@mui/material/styles';

import { CurrentTheme, CustomTheme, ThemeMode } from './CustomTheme';
import {
  createThemeStyles as createCustomTheme,
  setThemeModeAttribute as setCustomThemeMode,
} from './CustomThemeUtils';
import { convertToMuiMode, createMuiTheme } from './MuiThemeUtils';

const muiTheme = createMuiTheme(CustomTheme);

const getCurrentCustomTheme = (mode: ThemeMode): CurrentTheme => {
  return { ...CustomTheme, colors: CustomTheme.colors[mode] };
};

const CustomThemeProvider = ({
  defaultMode = 'light',
  children,
}: PropsWithChildren<{ defaultMode?: ThemeMode }>) => {
  return (
    <MuiStyledEngineProvider injectFirst>
      <MuiThemeProvider theme={muiTheme} defaultMode={convertToMuiMode(defaultMode) ?? undefined}>
        <CustomThemeProviderInternal defaultMode={defaultMode}>
          {children}
        </CustomThemeProviderInternal>
      </MuiThemeProvider>
    </MuiStyledEngineProvider>
  );
};
export default CustomThemeProvider;

const CustomThemeProviderInternal = ({
  defaultMode = 'light',
  children,
}: PropsWithChildren<{ defaultMode?: ThemeMode }>) => {
  const [mode, setMode] = useState<ThemeMode>(defaultMode);
  const { setMode: setMuiMode } = useColorScheme();

  const themeContext = useMemo(() => {
    return {
      mode,
      theme: getCurrentCustomTheme(mode),
      setMode: (newMode: ThemeMode) => {
        if (newMode === mode) {
          return;
        }
        setCustomThemeMode(newMode);
        setMuiMode(convertToMuiMode(newMode));
        setMode(newMode);
      },
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  useEffect(() => {
    createCustomTheme(CustomTheme);
    setCustomThemeMode(mode);
    setMuiMode(convertToMuiMode(mode));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <CustomThemeContext.Provider value={themeContext}>{children}</CustomThemeContext.Provider>;
};

type CustomThemeContextType = {
  mode: ThemeMode;
  theme: CurrentTheme;
  setMode: (newMode: ThemeMode) => void;
};

const CustomThemeContext = createContext<CustomThemeContextType | null>(null);

export const useCustomTheme = () => {
  const themeContext = useContext(CustomThemeContext);
  if (!themeContext) {
    throw new Error('`useTheme` must be called under <CustomThemeProvider />');
  }
  return themeContext.theme;
};

export const useCustomThemeMode = () => {
  const themeContext = useContext(CustomThemeContext);
  if (!themeContext) {
    throw new Error('`useCustomThemeMode` must be called under <CustomThemeProvider />');
  }
  return { mode: themeContext.mode, setMode: themeContext.setMode } as const;
};
