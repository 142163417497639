import { Navigate } from 'react-router-dom';

import { Routes } from 'types';
import { useAppSelector } from 'hooks';
import { BrowserStorageKeys, BrowserStorageService } from 'services';
import { userFirstPaymentMethodSelector } from 'store/slices/subscriptionSlice/selectors';

const PublicRoute = ({ children }: any) => {
  const token = BrowserStorageService.get(BrowserStorageKeys.AccessToken);

  const isFirstTimeUser = BrowserStorageService.get(BrowserStorageKeys.IsFirstTimeUser, {
    session: true,
  });

  const paymentExist = useAppSelector(userFirstPaymentMethodSelector);

  if (token && isFirstTimeUser === 'false' && paymentExist) {
    return <Navigate to={Routes.ActivityFeed} />;
  } else if (token && isFirstTimeUser === 'true') {
    return <Navigate to={Routes.ProcessForm} />;
  }

  return children;
};

export default PublicRoute;
