import { FC } from 'react';

import { Colors, FontNames } from 'types';

import { TContactLinkProps } from './types';

const ContactLink: FC<TContactLinkProps> = ({ className, to, value, tagName, onClick }) => {
  if (tagName === 'link') {
    return (
      <a
        rel='noreferrer'
        className={className}
        href={`${to}:${value}`}
        style={{ maxWidth: 'fit-content' }}
        onClick={(event) => event.stopPropagation()}
        onMouseDown={(event) => event.stopPropagation()}
        onTouchStart={(event) => event.stopPropagation()}
      >
        {value}
      </a>
    );
  } else {
    return (
      <div
        className={className}
        onClick={onClick}
        style={{
          cursor: 'pointer',
          maxWidth: 'fit-content',
          textDecoration: 'underline',
          color: Colors.SAPPHIRE,
          textUnderlineOffset: '3px',
          fontFamily: FontNames.CIRCULAR_REG,
        }}
        onMouseDown={(event) => event.stopPropagation()}
        onTouchStart={(event) => event.stopPropagation()}
      >
        {value}
      </div>
    );
  }
};

export default ContactLink;
