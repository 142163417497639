import { GridColDef } from '@mui/x-data-grid';

import { TableCell } from 'components';
import HeaderTitle from 'components/tables/EmployeeTable/HeaderTitle';

export const transactionColumns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'Transaction Id',
    flex: 1,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle style={{ paddingLeft: '0' }} title={headerName} />;
    },
    renderCell: (params) => {
      return <TableCell style={{ paddingLeft: '0', color: '#2067DD' }} title={params?.value} />;
    },
  },
  {
    field: 'customer_name',
    headerName: 'Customer',
    flex: 0.8,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle style={{ paddingLeft: '0' }} title={headerName} />;
    },
    renderCell: (params) => {
      return <TableCell style={{ paddingLeft: '0' }} title={params?.value} />;
    },
  },
  {
    field: 'amount',
    headerName: 'Amount',
    flex: 0.8,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle style={{ paddingLeft: '0' }} title={headerName} />;
    },
    renderCell: (params) => {
      return <TableCell style={{ paddingLeft: '0' }} title={`$${params?.value}`} />;
    },
  },
  {
    field: 'created_at',
    headerName: 'Date Requested',
    flex: 1.1,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle style={{ paddingLeft: '0' }} title={headerName} />;
    },
    renderCell: (params) => {
      return <TableCell style={{ paddingLeft: '0' }} title={params?.value} />;
    },
  },
  {
    field: 'source_type',
    headerName: 'Payment Method',
    flex: 1,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle style={{ paddingLeft: '0' }} title={headerName} />;
    },
    renderCell: (params) => {
      return <TableCell style={{ paddingLeft: '0' }} title={params?.value} />;
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 0.7,
    renderCell: (params) => {
      return params.value;
    },
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle style={{ paddingLeft: '0' }} title={headerName} />;
    },
  },
];
