import { createSlice } from '@reduxjs/toolkit';

import dataStructure from 'constants/options';

import {
  getCatalogById,
  getCatalogByUUID,
  getCatalogList,
  getCatalogVersionsById,
  getCatalogListItemUsage,
  getShareDCatalogDataById,
  getCurrentSectionVersions,
} from './thunks';

import type { TInitialState } from './types';

const initialState: TInitialState = {
  catalogAsc: true,
  catalogOffset: 0,
  catalogLimit: 5,
  itemUsageDataLimit: 5,
  itemUsageDataOffset: 0,
  catalogListError: null,
  sharedCatalogById: null,
  versionsByIdLoad: false,
  itemUsageDataError: null,
  currentCatalogById: null,
  itemUsageDataLoad: false,
  catalogListLoading: false,
  catalogList: dataStructure,
  itemUsageData: dataStructure,
  currentSectionVersions: null,
  currentCatalogByIdLoad: false,
  currentCatalogByIdError: null,
  currentCatalogByUUIDLoad: false,
  currentSectionVersionsLoad: false,
  currentSectionVersionsError: null,
  currentCatalogVersions: dataStructure,
};

const catalogSlice = createSlice({
  name: 'catalogSlice',
  reducers: {
    setPage(state, action) {
      state.catalogOffset = (action.payload - 1) * state.catalogLimit;
    },
    setLimit(state, action) {
      state.catalogLimit = action.payload;
    },
    setItemUsagePage(state, action) {
      state.itemUsageDataOffset = (action.payload - 1) * state.itemUsageDataLimit;
    },
    setItemUsageLimit(state, action) {
      state.itemUsageDataLimit = action.payload;
    },
  },
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getCatalogList.pending, (state) => {
        state.catalogListLoading = true;
        state.catalogListError = null;
      })
      .addCase(getCatalogList.fulfilled, (state, action) => {
        state.catalogList = action.payload;
        state.catalogListLoading = false;
        state.catalogAsc = !state.catalogAsc;
      })
      .addCase(getCurrentSectionVersions.pending, (state) => {
        state.currentSectionVersionsLoad = true;
        state.currentSectionVersionsError = null;
      })
      .addCase(getCurrentSectionVersions.fulfilled, (state, action) => {
        state.currentSectionVersions = action.payload;
        state.currentSectionVersionsError = null;
        state.currentSectionVersionsLoad = false;
      })
      .addCase(getCurrentSectionVersions.rejected, (state, action) => {
        state.currentSectionVersionsLoad = false;
        state.currentSectionVersions = null;

        state.currentSectionVersionsError = action.error.message as string;
      })
      .addCase(getCatalogVersionsById.fulfilled, (state, action) => {
        state.currentCatalogVersions = action.payload;
        state.versionsByIdLoad = false;
      })
      .addCase(getCatalogVersionsById.pending, (state) => {
        state.versionsByIdLoad = true;
        state.currentCatalogVersions = dataStructure;
      })
      .addCase(getCatalogVersionsById.rejected, (state) => {
        state.versionsByIdLoad = false;
      })
      .addCase(getCatalogList.rejected, (state, action) => {
        state.catalogListLoading = false;
        state.catalogListError = action.error.message as string;
      })
      .addCase(getCatalogById.fulfilled, (state, action) => {
        state.currentCatalogById = action.payload;
        state.currentCatalogByIdLoad = false;
        state.currentCatalogByIdError = null;
      })
      .addCase(getCatalogById.rejected, (state, action) => {
        state.currentCatalogByIdLoad = false;
        state.currentCatalogByIdError = action.error.message as string;
      })
      .addCase(getCatalogById.pending, (state) => {
        state.currentCatalogById = null;
        state.currentCatalogByIdLoad = true;
        state.currentCatalogByIdError = null;
      })
      .addCase(getCatalogByUUID.rejected, (state, action) => {
        state.currentCatalogByUUIDLoad = false;
        state.currentCatalogByIdError = action.error.message as string;
      })
      .addCase(getCatalogByUUID.pending, (state) => {
        state.currentCatalogById = null;
        state.currentCatalogByUUIDLoad = true;
        state.currentCatalogByIdError = null;
      })
      .addCase(getCatalogByUUID.fulfilled, (state) => {
        state.currentCatalogByUUIDLoad = false;
        state.currentCatalogByIdError = null;
      })
      .addCase(getCatalogListItemUsage.fulfilled, (state, action) => {
        state.itemUsageData = action.payload;
        state.itemUsageDataLoad = false;
        state.itemUsageDataError = null;
      })
      .addCase(getCatalogListItemUsage.rejected, (state, action) => {
        state.itemUsageDataLoad = false;
        state.itemUsageDataError = action.error.message as string;
      })
      .addCase(getCatalogListItemUsage.pending, (state) => {
        state.itemUsageDataLoad = true;
        state.itemUsageDataError = null;
      })
      .addCase(getShareDCatalogDataById.fulfilled, (state, action) => {
        state.sharedCatalogById = action.payload;
      });
  },
});

export default catalogSlice.reducer;

export const { setPage, setLimit, setItemUsagePage, setItemUsageLimit } = catalogSlice.actions;
