import { createSlice } from '@reduxjs/toolkit';

import dataStructure from 'constants/options';

import { TInitialState } from './types';
import { getAllCoupons, getCouponByCatalogId, getCouponById } from './thunks';

const initialState: TInitialState = {
  coupon: null,
  couponError: null,
  allCouponsLimit: 9,
  allCouponsOffset: 0,
  couponLoading: false,
  allCouponsError: null,
  allCouponsLoading: false,
  allCoupons: dataStructure,
  couponForCatalogError: null,
  couponForCatalogLoad: false,
  couponForCatalog: dataStructure,
};

const discountSlice = createSlice({
  name: 'discountSlice',
  reducers: {},
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getAllCoupons.pending, (state) => {
        state.allCouponsLoading = true;
        state.allCouponsError = null;
      })
      .addCase(getAllCoupons.fulfilled, (state, action) => {
        state.allCoupons = action.payload;

        state.allCouponsLoading = false;
      })
      .addCase(getAllCoupons.rejected, (state, action) => {
        state.allCouponsLoading = false;
        state.allCoupons = dataStructure;
        state.allCouponsError = action.payload as string;
      })
      .addCase(getCouponByCatalogId.pending, (state) => {
        state.couponForCatalogLoad = true;
        state.couponForCatalogError = null;
      })
      .addCase(getCouponByCatalogId.fulfilled, (state, action) => {
        state.couponForCatalog = action.payload;
        state.couponForCatalogLoad = false;
      })
      .addCase(getCouponByCatalogId.rejected, (state, action) => {
        state.couponForCatalog = dataStructure;
        state.couponForCatalogLoad = false;
        state.couponForCatalogError = action.payload as string;
      })
      .addCase(getCouponById.pending, (state) => {
        state.couponLoading = true;
        state.couponError = null;
      })
      .addCase(getCouponById.fulfilled, (state, action) => {
        state.coupon = action.payload;
        state.couponLoading = false;
      })
      .addCase(getCouponById.rejected, (state, action) => {
        state.couponLoading = true;
        state.coupon = null;
        state.couponError = action.payload as string;
      });
  },
});

export default discountSlice.reducer;
