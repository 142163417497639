import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { AxiosError } from 'axios';

import {
  CustomBreadcrumbs,
  CustomTypography,
  ControlledInput,
  Button,
  ControlledSelect,
  ControlledFormPicker,
  UpdateTask,
} from 'components';
import { Colors, FontNames, Routes } from 'types';
import { useAppDispatch, useAppSelector } from 'hooks';
import { TOption } from 'components/shared/Select/types';
import { currentWorkOrderByIdSelector } from 'store/slices/workOrderSlice/selectors';
import {
  getCustomerById,
  getOrganizationMembers,
  getWorkOrderById,
  updateTask,
  getAllTasksByIdForUpdate,
} from 'store/thunks';
import {
  TTaskUpdateBody,
  TWorkOrderTask,
  TWorkOrderUpdate,
} from 'store/slices/workOrderSlice/types';
import { BrowserStorageKeys, BrowserStorageService } from 'services';
import { updateWorkOrder } from 'store/thunks';
import { currentCustomerSelector } from 'store/slices/customersSlices/selectors';
import { setTasksLimit, setTasksPage } from 'store/slices/workOrderSlice';
import {
  userAllRolesLoadingSelector,
  userPermissionsSelector,
} from 'store/slices/authSlice/selectors';

import { breadCrumbOptions } from './utils';
import styles from './EditWorkOrder.module.scss';

const currentOrganization = BrowserStorageService.get(BrowserStorageKeys.CurrentOrganizationId);

const EditWorkOrder = () => {
  const { id } = useParams();

  const {
    control,
    handleSubmit,
    reset,
    watch,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm<any>();

  const currentWorkOrder = useAppSelector(currentWorkOrderByIdSelector);
  const { isAccessToEditWorkOrder } = useAppSelector(userPermissionsSelector);
  const userAllRolesLoading = useAppSelector(userAllRolesLoadingSelector);

  const currentCustomer = useAppSelector(currentCustomerSelector);
  const dispatch = useAppDispatch();

  const [workOrderData, setWorkOrderData] = useState(currentWorkOrder);
  const [tasks, setTasks] = useState<TWorkOrderTask[]>([]);
  const [maxId, setMaxId] = useState(0);
  const [employeeOptions, setEmployeeOptions] = useState<TOption[]>([]);
  const [managerOptions, setManagerOptions] = useState<TOption[]>([]);

  const navigate = useNavigate();
  const navigateToViewWorkOrder = async () => {
    dispatch(setTasksLimit(5));
    dispatch(setTasksPage(1));
    navigate(`/work-flow/work-order/view/${Number(id)}`);
  };

  const getMembers = async () => {
    const response = await dispatch(
      getOrganizationMembers({ org_id: Number(currentOrganization), limit: 100 }),
    );

    if (response.payload) {
      const { members } = response.payload;
      const employeeOptions = members
        .filter(
          (member: any) => member.designation === 'employee' || member.designation === 'owner',
        )
        .map((employee: any) => ({
          id: employee.user_id,
          value: employee.name,
          label: employee.name,
        }));

      const managerOptions = members
        .filter((member: any) => member.designation === 'manager' || member.designation === 'owner')
        .map((manager: any) => ({
          id: manager.user_id,
          value: manager.name,
          label: manager.name,
        }));

      setEmployeeOptions(employeeOptions);
      setManagerOptions(managerOptions);
    }
  };

  const fetchData = async () => {
    try {
      await getMembers(); // Fetch members
      await dispatch(getWorkOrderById(Number(id))); // Fetch work order
      const tasksResponse = await dispatch(getAllTasksByIdForUpdate(Number(id))); // Fetch tasks
      const tasksData = tasksResponse.payload?.data || [];

      if (tasksData.length > 0) {
        const maxId = Math.max(...tasksData.map((item: { id: any }) => item.id));
        setMaxId(maxId); // Set tempId after fetching tasks
        setTasks(tasksData); // Set tasks
      }

      reset();
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  useEffect(() => {
    if (currentWorkOrder) {
      dispatch(getCustomerById(currentWorkOrder.customer_id));
      setWorkOrderData(currentWorkOrder);
    }
  }, [dispatch, currentWorkOrder]);

  const watchResult = watch();

  const { wo_end_date, wo_start_date } = watchResult;

  const isFormValid =
    Number(wo_end_date?.toString().length) && Number(wo_start_date?.toString().length);

  const handleCreateData: SubmitHandler<FieldValues> = async (data) => {
    const supervisor = managerOptions.find((emp) => emp.label === data.Supervisor);
    if (workOrderData && supervisor?.id) {
      const WO_Data: TWorkOrderUpdate = {
        customer_id: workOrderData?.customer_id,
        supervisor_id: supervisor?.id,
        start_date: new Date(data?.wo_start_date).toISOString().split('T')[0],
        end_date: new Date(data?.wo_end_date).toISOString().split('T')[0],
        notes: data?.notes,
        estimate_id: workOrderData?.estimate_id,
        id: Number(id),
        status: workOrderData?.status,
      };
      await dispatch(updateWorkOrder(WO_Data));

      const tasks_data: TTaskUpdateBody[] = data?.tasks?.map(
        (task: {
          task: {
            id: any;
            technician_id: any;
            start_date: any;
            end_date: any;
            start_time: any;
            daily_hours: any;
            work_Order_id: any;
            status: any;
            created_at: any;
            updated_at: any;
            created_by: any;
            description: any;
            employee_name: any;
            sequence: any;
          };
        }) => {
          const taskId = task.task.id;

          if (taskId > maxId) {
            return {
              start_time: task.task.start_time,
              technician_id: task.task.employee_name.id,
              description: task.task.description,
              start_date: new Date(task.task.start_date).toISOString().split('T')[0],
              end_date: new Date(task.task.end_date).toISOString().split('T')[0],
              daily_hours: task.task.daily_hours,
              work_order_id: Number(id),
              sequence: task.task.sequence,
              status: task.task.status,
            };
          } else {
            return {
              start_time: task.task.start_time,
              technician_id: task.task.employee_name.id,
              description: task.task.description,
              start_date: new Date(task.task.start_date).toISOString().split('T')[0],
              end_date: new Date(task.task.end_date).toISOString().split('T')[0],
              daily_hours: task.task.daily_hours,
              work_order_id: Number(id),
              sequence: task.task.sequence,
              status: task.task.status,
              id: task.task.id,
            };
          }
        },
      );
      await dispatch(updateTask(tasks_data));
    }
    navigateToViewWorkOrder();
  };

  if (!isAccessToEditWorkOrder && !userAllRolesLoading) {
    return <Navigate replace to={Routes.ActivityFeed} />;
  }

  return (
    <Box>
      <form onSubmit={handleSubmit(handleCreateData)} className={styles.container}>
        <Box className={styles.container}>
          <CustomBreadcrumbs options={breadCrumbOptions} />
          <Box className={styles.container__head}>
            <CustomTypography className={styles.container__head__title}>
              Work Order {workOrderData?.id}
            </CustomTypography>
          </Box>
          <Box>
            <CustomTypography className={styles.container__head__body}>
              {currentCustomer?.address}, {currentCustomer?.city}
            </CustomTypography>
            <CustomTypography className={styles.container__head__body}>
              {currentCustomer?.state} {currentCustomer?.zip}
            </CustomTypography>
          </Box>
          <Box className={styles.estimateBox}>
            <CustomTypography className={styles.estimateText}>
              Estimate: {workOrderData?.estimate_name}
            </CustomTypography>
          </Box>
          <Box className={styles.customerInfoContainer}>
            <Box className={styles.customerInfoBox}>
              <CustomTypography className={styles.customerText}>
                Customer Name: {currentCustomer?.name}
              </CustomTypography>
            </Box>
            <Box className={styles.customerInfoBox}>
              <CustomTypography className={styles.customerText}>
                Customer Phone: {currentCustomer?.phone_number}
              </CustomTypography>
            </Box>
          </Box>
          <Box className={styles.customerInfoContainer}>
            <Box className={styles.customerInfoBox}>
              <CustomTypography className={styles.customerText}>
                Customer Email: {currentCustomer?.email}
              </CustomTypography>
            </Box>
            <Box className={styles.customerInfoBox}>
              <CustomTypography className={styles.customerText}>
                Customer Address: {currentCustomer?.address}, {currentCustomer?.city},{' '}
                {currentCustomer?.state} {currentCustomer?.zip}
              </CustomTypography>
            </Box>
          </Box>
          {!currentWorkOrder && (
            <>
              <Box className={styles.card__items}>
                <ControlledFormPicker
                  disablePast={false}
                  padding='0 8px'
                  label='WO Start Date'
                  name='wo_start_date'
                  control={control}
                  // error={specificError}
                  backgroundColor={Colors.WHITE}
                  className={styles.container__picker}
                  // helperText={specificErrorMessage || specificError?.message}
                />
                <ControlledFormPicker
                  // value='12/10/2025'
                  padding='0 8px'
                  label='WO End Date'
                  name='wo_end_date'
                  control={control}
                  // error={specificError}
                  backgroundColor={Colors.WHITE}
                  className={styles.container__picker}
                  // helperText={specificErrorMessage || specificError?.message}
                />
              </Box>
              <Box className={styles.container__content}>
                <div className={styles.container__content__item}>
                  <ControlledInput
                    name='notes'
                    label={'Notes'}
                    control={control}
                    // maxWidth='1000px'
                    rows={3}
                    required={false}
                    borderColor={Colors.SOFT_SILVER}
                    backgroundColor={Colors.FROST_WHITE}
                    // fontFamily={FontNames.CIRCULAR_REG}
                    placeholder={'Notes'}
                    borderRadius='5px'
                    // labelColor={Colors.ENIGMATIC_MIDNIGHT}
                    // error={errors[name as keyof TWorkOrderFormValues]}
                    // helperText={errors[name as keyof TWorkOrderFormValues]?.message}
                  />
                </div>
              </Box>
              <Box className={styles.container__content}>
                <div className={styles.container__content__subitem}>
                  <ControlledSelect
                    name='Supervisor'
                    haveLabel={true}
                    label='Supervisor'
                    backgroundColor={Colors.LIGHT_SILVER}
                    options={managerOptions}
                    control={control}
                    required={true}
                    //errors={!!specificError}
                  />
                </div>
              </Box>
            </>
          )}
          {currentWorkOrder && (
            <>
              <Box className={styles.card__items}>
                <ControlledFormPicker
                  defaultValue={currentWorkOrder?.start_date}
                  disablePast={false}
                  padding='0 8px'
                  label='WO Start Date'
                  name='wo_start_date'
                  control={control}
                  // error={specificError}
                  backgroundColor={Colors.WHITE}
                  className={styles.container__picker}
                  // helperText={specificErrorMessage || specificError?.message}
                />
                <ControlledFormPicker
                  // value='12/10/2025'
                  defaultValue={currentWorkOrder?.end_date}
                  padding='0 8px'
                  label='WO End Date'
                  name='wo_end_date'
                  control={control}
                  // error={specificError}
                  backgroundColor={Colors.WHITE}
                  className={styles.container__picker}
                  // helperText={specificErrorMessage || specificError?.message}
                />
              </Box>
              <Box className={styles.container__content}>
                <div className={styles.container__content__item}>
                  <ControlledInput
                    name='notes'
                    label={'Notes'}
                    control={control}
                    // maxWidth='1000px'
                    defaultValue={currentWorkOrder?.notes}
                    rows={3}
                    required={false}
                    borderColor={Colors.SOFT_SILVER}
                    backgroundColor={Colors.FROST_WHITE}
                    // fontFamily={FontNames.CIRCULAR_REG}
                    placeholder={'Notes'}
                    borderRadius='5px'
                    // labelColor={Colors.ENIGMATIC_MIDNIGHT}
                    // error={errors[name as keyof TWorkOrderFormValues]}
                    // helperText={errors[name as keyof TWorkOrderFormValues]?.message}
                  />
                </div>
              </Box>
              <Box className={styles.container__content}>
                <div className={styles.container__content__subitem}>
                  <ControlledSelect
                    name='Supervisor'
                    haveLabel={true}
                    label='Supervisor'
                    backgroundColor={Colors.LIGHT_SILVER}
                    options={managerOptions}
                    control={control}
                    required={true}
                    //errors={!!specificError}
                    defaultValue={currentWorkOrder?.supervisor_name}
                  />
                </div>
              </Box>
            </>
          )}
          <Box>
            <UpdateTask
              errors={errors}
              control={control}
              getValues={getValues}
              employees={employeeOptions}
              tasks={tasks}
              // isLoading={generalLoading}
            />
          </Box>

          <Box className={styles.container__footer}>
            <Button
              type='submit'
              color='white'
              maxWidth='180px'
              minWidth='68px'
              padding='11px 8px'
              borderRadius='5px'
              backgroundColor={Colors.SAPPHIRE}
              fontFamily={FontNames.CIRCULAR_BOLD_MAX}
              className={styles.container__content__submit}
              disabled={!isFormValid || isSubmitting}
            >
              Update
            </Button>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default EditWorkOrder;
