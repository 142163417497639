import { GridColDef } from '@mui/x-data-grid';

import HeaderTitle from 'components/tables/EmployeeTable/HeaderTitle';
import { FontNames } from 'types';

export const columns: GridColDef[] = [
  {
    field: 'item',
    headerName: 'Item',
    flex: 1,

    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return (
        <HeaderTitle
          title={headerName}
          withSortIcons={false}
          style={{ padding: 0 }}
          fontFamily={FontNames.CIRCULAR_BOLD}
        />
      );
    },
    renderCell: (params) => {
      return params?.value;
    },
  },

  {
    field: 'description',
    headerName: 'Description',
    flex: 1,

    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle withSortIcons={false} title={headerName} style={{ padding: 0 }} />;
    },
    renderCell: (params) => {
      return params?.value;
    },
  },
  {
    field: 'quantity',
    headerName: 'Quantity',
    flex: 1,

    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle withSortIcons={false} title={headerName} style={{ padding: 0 }} />;
    },
    renderCell: (params) => {
      return params?.value;
    },
  },

  {
    field: 'options',
    headerName: '',
    width: 80,
    renderCell: (params) => {
      return params.value;
    },
  },
];
