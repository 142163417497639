import { Box } from '@mui/material';
import { FC } from 'react';

import { useAppSelector } from 'hooks';
import SectionHead from 'components/views/Drawers/CatalogCreateConfigure/SectionHead';
import { currentSectionVersionsSelector } from 'store/slices/catalogSlice/selectors';
import { CustomSkeleton } from 'components';

import TemplateBlock from './TemplateBlock';
import styles from './TemplatesView.module.scss';

import type { TTemplatesViewProps } from './types';

const TemplatesView: FC<TTemplatesViewProps> = ({ isLoading }) => {
  const versions = useAppSelector(currentSectionVersionsSelector);

  const templateItemsEstimate = versions?.templates?.map((template, idx) => {
    if (template?.type === 'agreements') {
      return <TemplateBlock fileName='Agreement Template' template={template} key={idx} />;
    }
  });

  const templateItemsAgreements = versions?.templates?.map((template, idx) => {
    if (template?.type === 'estimate') {
      return <TemplateBlock fileName='Estimate Template' template={template} key={idx} />;
    }
  });

  const initialSkeletons = Array.from({ length: 2 })?.map((_, idx) => (
    <CustomSkeleton borderRadius='4px' height={130} width='100%' key={idx} />
  ));

  return (
    <Box className={styles.container}>
      <SectionHead
        withUnderlinedTitle
        title='Templates:'
        subtitle={`(These templates are used while creating estimates and agreements for customers.)`}
      />
      <Box className={styles.container__content}>
        {isLoading ? (
          initialSkeletons
        ) : (
          <>
            <Box className={styles.container__content__item}>
              {templateItemsAgreements?.length ? templateItemsAgreements : 'No Templates'}
            </Box>
            <Box className={styles.container__content__item}>
              {templateItemsEstimate?.length ? templateItemsEstimate : 'No Templates'}
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default TemplatesView;
