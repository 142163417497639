import { Box } from '@mui/material';
import { FC } from 'react';
import { useController } from 'react-hook-form';

import { TextFrame } from 'components/shared';
import ControlledInput from 'components/views/ControlledInput';
import { Colors } from 'types';

import styles from './ProfitMarginItem.module.scss';
import { TProfitMarginItemProps } from './types';

const ProfitMarginItem: FC<TProfitMarginItemProps> = ({ control, name, sectionName, idx }) => {
  const {
    formState: { errors },
  } = useController({ name: name as any, control });

  const Errors = errors as any;

  return (
    <Box className={styles.container}>
      <TextFrame
        width='100%'
        padding='17px 14px'
        borderRadius='5px'
        content={sectionName}
        backgroundColor={Colors.FROST_WHITE}
        border={`1px solid ${Colors.SOFT_SILVER}`}
      />

      <ControlledInput
        name={name}
        control={control}
        borderRadius='5px'
        placeholder='Profit margin'
        borderColor={Colors.SOFT_SILVER}
        error={Errors?.profit_margin?.[idx]}
        backGroundColor={Colors.FROST_WHITE}
        helperText={Errors?.profit_margin?.[idx]?.margin?.message as string}
      />
    </Box>
  );
};

export default ProfitMarginItem;
