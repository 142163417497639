export function sortByKey<T>(
  array: T[] | null | undefined,
  key: keyof T,
  descending = true,
): T[] | null | undefined {
  if (!array) {
    return array;
  }

  if (!Array.isArray(array)) {
    throw new TypeError('sortByKey expects an array as the first argument');
  }

  if (key) {
    return [...array]?.sort((a, b) => {
      const valueA = a[key as keyof T];
      const valueB = b[key as keyof T];

      let comparison = 0;
      if (valueA > valueB) {
        comparison = 1;
      } else if (valueA < valueB) {
        comparison = -1;
      }

      return descending ? -comparison : comparison;
    });
  } else {
    return array;
  }
}
