import { FC, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { Box, FormHelperText, Stack } from '@mui/material';

import { ArrowDown } from 'assets/icons';
import { Button, CustomTypography } from 'components';
import { useAppDispatch, useAppSelector, useLoadMore, useOnClickOutside } from 'hooks';
import {
  catalogListSelector,
  catalogListLoadingSelector,
} from 'store/slices/catalogSlice/selectors';
import { getCatalogList, getCurrentSectionVersions } from 'store/slices/catalogSlice/thunks';
import { catalogApi } from 'api';
import { Colors } from 'types';

import styles from './SelectCatalogDropDown.module.scss';

import type { TCatalogReturnType } from 'store/slices/catalogSlice/types';
import type { TSelectCatalogDropDownProps, TCatalog } from './types';

const SelectCatalogDropDown: FC<TSelectCatalogDropDownProps> = ({
  helperText,
  currentCatalog,
  setCurrentCatalog,
}) => {
  const dispatch = useAppDispatch();

  const ref = useRef<HTMLDivElement | null>(null);

  const catalogList = useAppSelector(catalogListSelector);
  const allCatalogsLoad = useAppSelector(catalogListLoadingSelector);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { data, loadMore, noLoad, isLoad } = useLoadMore({
    callback: catalogApi.getCatalogListRequest,
  });

  useEffect(() => {
    dispatch(getCatalogList({ limit: 1, offset: 0 }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [catalogList.data.length]);

  const handleClick = () => catalogList?.total_count !== 0 && setIsOpen(!isOpen);

  const changeCurrentCatalog = async ({ id, name, created, version_id }: TCatalog) => {
    const response = await dispatch(getCurrentSectionVersions(version_id as number)).unwrap();

    setCurrentCatalog({ id, name, created, sections: response.sections, version_id });
    setIsOpen(!isOpen);
  };

  const dataOfCatalogs = data as TCatalogReturnType;

  const renderItems = dataOfCatalogs?.data?.map((item) => (
    <Box
      key={item.id}
      style={{
        pointerEvents: !item.latest_version_id ? 'none' : 'unset',
        opacity: !item.latest_version_id ? 0.2 : 1,
      }}
      className={styles.container__dropdown__item}
      onClick={() =>
        changeCurrentCatalog({
          id: item.id,
          name: item.name,
          created: item.created_by,
          version_id: item.latest_version_id,
        })
      }
    >
      {item.name}
    </Box>
  ));

  useOnClickOutside(ref, () => setIsOpen(false));

  const textClasses = classNames(styles.container__select__text, {
    [styles.container__select__text_disable]: !currentCatalog?.name,
  });

  return (
    <Box className={styles.container} ref={ref}>
      <Box className={styles.container__select} onClick={handleClick}>
        <CustomTypography className={textClasses}>
          {currentCatalog?.name ? currentCatalog?.name : 'Select Catalog:*'}
        </CustomTypography>
        <ArrowDown
          className={classNames(styles.container__select__icon, {
            [styles.container__select__icon__active]: isOpen,
          })}
        />
      </Box>
      {!allCatalogsLoad && helperText && (
        <FormHelperText sx={{ color: 'red', marginTop: '3px', marginLeft: '14px !important' }}>
          {helperText}
        </FormHelperText>
      )}
      {isOpen && (
        <Box className={styles.container__dropdown}>
          {renderItems}
          {!noLoad && (
            <Stack justifyContent='center' alignItems='center' padding='5px 0'>
              <Button
                color={Colors.WHITE}
                backgroundColor={Colors.SAPPHIRE}
                inLoading={isLoad}
                onClick={loadMore}
              >
                Load More
              </Button>
            </Stack>
          )}
        </Box>
      )}
    </Box>
  );
};

export default SelectCatalogDropDown;
