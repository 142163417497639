import { useState } from 'react';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';

import { VoidCallback } from 'types';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import { userDataSelector } from 'store/slices/authSlice/selectors';
import { BrowserStorageKeys, BrowserStorageService } from 'services';
import { sendOrganizationResourcesRequest } from 'api/organizations';
import { PaymentMethodUpdateToastMessages } from 'constants/ToastMessages';
import { setDefaultPaymentMethod } from 'store/slices/subscriptionSlice/thunks';
import { isSubscriptionFree } from 'store/slices/subscriptionSlice/selectors';

import type { TUseStripePaymentProps } from './types';

const subscriptionIdFromStorage = BrowserStorageService.get(BrowserStorageKeys.SubscriptionId, {
  session: true,
});

const useStripePayment = (options: TUseStripePaymentProps) => {
  const stripe = useStripe();
  const userInfo = useAppSelector(userDataSelector);
  const haveFreeSubscribe = useAppSelector(isSubscriptionFree);

  const dispatch = useAppDispatch();
  const elements = useElements();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>('');

  const handleSubmit = async (
    client_secret?: string,
    orgId?: number,
    callback?: VoidCallback,
    subscriptionSecret?: string,
    subscriptionId?: string,
  ) => {
    const id = toast.loading(PaymentMethodUpdateToastMessages.PAYMENT_METHOD_UPDATE_START);

    try {
      const setupSecret = options?.clientSecret || client_secret;

      if (!stripe || !elements) {
        return;
      }

      setLoading(true);

      const cardElement = elements.getElement(CardElement);

      const cardDetails = {
        card: cardElement,
      };

      if (cardDetails.card) {
        const { error: paymentMethodError } = await stripe.createPaymentMethod({
          type: 'card',
          card: cardDetails.card,

          billing_details: {
            name: options.name,
            address: {
              country: options.country,
              line1: options.street,

              state: options.state,
              postal_code: options.postal_code,
            },
            email: userInfo?.email,
            phone: options.phone,
          },
        });

        const confirmCardSetupResponse = await stripe.confirmCardSetup(setupSecret as string, {
          payment_method: {
            card: cardDetails.card,
            billing_details: {
              name: options.name,
              address: {
                country: options.country,
                line1: options.street,

                state: options.state,
                postal_code: options.postal_code,
              },
              phone: options.phone,
            },
          },
        });

        if (confirmCardSetupResponse?.error) {
          setError(confirmCardSetupResponse?.error?.message);

          toast.update(id, {
            render: confirmCardSetupResponse?.error?.message,
            type: 'error',
            isLoading: false,
            autoClose: 3000,
          });
        }

        if (confirmCardSetupResponse && haveFreeSubscribe && !confirmCardSetupResponse?.error) {
          await dispatch(
            setDefaultPaymentMethod({
              org_id: (orgId as number) || Number(options?.orgId),
              payment_method_id: confirmCardSetupResponse?.setupIntent?.payment_method as string,
            }),
          );

          await sendOrganizationResourcesRequest(
            Number(orgId),
            String(subscriptionId) || String(subscriptionIdFromStorage),
          );

          toast.update(id, {
            render: PaymentMethodUpdateToastMessages.PAYMENT_METHOD_UPDATE_SUCCESS,
            type: 'success',
            isLoading: false,
            autoClose: 3000,
          });

          callback?.();
        }

        if (
          options?.forUpdate &&
          confirmCardSetupResponse &&
          !confirmCardSetupResponse?.error &&
          !haveFreeSubscribe
        ) {
          await dispatch(
            setDefaultPaymentMethod({
              org_id: (orgId as number) || Number(options?.orgId),
              payment_method_id: confirmCardSetupResponse?.setupIntent?.payment_method as string,
            }),
          );

          toast.update(id, {
            render: PaymentMethodUpdateToastMessages.PAYMENT_METHOD_UPDATE_SUCCESS,
            type: 'success',
            isLoading: false,
            autoClose: 3000,
          });
        }

        const newToken = BrowserStorageService.get(BrowserStorageKeys.subscriptionClientSecret);

        if (!options?.forUpdate && !haveFreeSubscribe) {
          const response = await stripe.confirmCardPayment(newToken as string, {
            payment_method: {
              card: cardDetails.card,
              billing_details: {
                name: options.name,
                address: {
                  country: options.country,
                  state: options.state,
                  line1: options.street,
                  postal_code: options.postal_code,
                },
                phone: options.phone,
              },
            },
          });

          if (response && !confirmCardSetupResponse?.error) {
            setError(null);

            await dispatch(
              setDefaultPaymentMethod({
                org_id: orgId as number,
                payment_method_id: response?.paymentIntent?.payment_method as string,
              }),
            );

            await sendOrganizationResourcesRequest(
              Number(orgId),
              String(subscriptionId) || String(subscriptionIdFromStorage),
            );

            toast.update(id, {
              render: PaymentMethodUpdateToastMessages.PAYMENT_METHOD_UPDATE_SUCCESS,
              type: 'success',
              isLoading: false,
              autoClose: 3000,
            });

            callback?.();
          }
        }

        if (paymentMethodError?.message) {
          setError(paymentMethodError.message);
          setLoading(false);

          return;
        }
      }
    } catch (error) {
      const Error = error as AxiosError;
      toast.update(id, {
        render: PaymentMethodUpdateToastMessages.PAYMENT_METHOD_UPDATE_FAILURE,
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });

      throw Error;
    }
  };

  return { handleSubmit, loading, error, stripe, elements };
};

export default useStripePayment;
