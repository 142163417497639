import { GridColDef } from '@mui/x-data-grid';

import { TableCell } from 'components';
import HeaderTitle from 'components/tables/EmployeeTable/HeaderTitle';

export const columns: GridColDef[] = [
  {
    flex: 2,
    field: 'name',
    headerName: 'Item #',
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle title={headerName} style={{ paddingLeft: '0' }} />;
    },

    renderCell: (params) => {
      return <TableCell title={params?.value} />;
    },
  },

  {
    flex: 1,
    field: 'price',
    headerName: 'Unit Price',
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle title={headerName} style={{ paddingLeft: '0' }} />;
    },
    renderCell: (params) => {
      return <TableCell title={params?.value} />;
    },
  },

  {
    flex: 1,
    field: 'qty',
    headerName: 'Qty',
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle title={headerName} style={{ paddingLeft: '0' }} />;
    },
    renderCell: (params) => {
      return <TableCell title={params?.value} />;
    },
  },

  {
    flex: 1,
    field: 'amount',
    headerName: 'Amount',
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle title={headerName} style={{ paddingLeft: '0' }} />;
    },
    renderCell: (params) => {
      return <TableCell title={params?.value} />;
    },
  },
];
