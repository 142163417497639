import { Id } from 'react-toastify';

import { ConvertedData } from 'containers/ProcessForm/types';
import { TInvoiceHistoryReturnType } from 'types/global/invoice';
import { TPaymentMethods } from 'types/global/paymentMethods';
import { TSubscriptionDetails } from 'types/global/subscriptions';

export enum SubscriptionPlans {
  BASIC = 'basic',
  PRO_MONTHLY = 'pro_monthly',
  PRO_YEARLY = 'pro_yearly',
  BUSINESS_MONTHLY = 'business_monthly',
  BUSINESS_YEARLY = 'business_yearly',
  ENTERPRISE_MONTHLY = 'enterprise_monthly',
  ENTERPRISE_YEARLY = 'enterprise_yearly',
}

export type TInitialState = {
  currentSubscriptionPlan: null;
  userSubscriptionsLoader: boolean;
  invoicesHistory: TInvoiceHistoryReturnType | null;
  invoicesHistoryLoader: boolean;
  invoicesHistoryError: null | Error | string;
  currentSubscriptionPlanLoading: boolean;
  allPaymentMethods: TPaymentMethods | null;
  freeSubscriptionTrial: boolean;
  allPaymentMethodsLoader: boolean;
  allPaymentMethodsError: null | Error | string;
  defaultPaymentMethod: string;
  defaultPaymentMethodLoader: boolean;
  defaultPaymentMethodError: null | Error | string;
  userSubscriptions: TSubscriptionDetails | null;
  userSubscriptionsTrial: TSubscriptionDetails | null;
  userSubscriptionsError: null | Error | string;
  currentSubscriptionPlanError: null | Error | string;
};

export type TCreateSubscription = {
  org_id: number;
  subscription: SubscriptionPlans;
} & Partial<{
  free: boolean;
}>;

export type TCreatePaymentIntent = {
  org_id: number;
  amount: number;
} & Partial<{
  payment_method_id: string;
}>;

export type TUserSubscriptionTypes = {
  org_id: number;
  subscription_status: UserSubscriptionStatus;
};

export enum UserSubscriptionStatus {
  ALL = 'all',
  ENDED = 'ended',
  PAUSED = 'paused',
  ACTIVE = 'active',
  UNPAID = 'unpaid',
  TRIALING = 'trialing',
  PAST_DUE = 'past_due',
  CANCELED = 'canceled',
  INCOMPLETE = 'incomplete',
}

export type TUpdateBillingAddress = {
  country: string;
  postal_code: string;
  state: string;
} & Partial<{
  city: string;
  line1: string;
  line2: string;
}>;

export type TUpdateBillingDetails = {
  name: string;
  email: string;
  phone: string;
  org_id: number;
  payment_method_id: string;
  address: TUpdateBillingAddress;
};

export type TSetDefaultPaymentMethod = {
  payment_method_id: string;
  org_id: number;
};

export type TUpdateSubscriptionParams = {
  org_id: number;
  subscription_id: string;
  subscription: SubscriptionType;
} & Partial<{
  toast: Id;
}>;

export enum SubscriptionType {
  BASIC = 'basic',
  PRO_MONTHLY = 'pro_monthly',
  PRO_YEARLY = 'pro_yearly',
  BUSINESS_MONTHLY = 'business_monthly',
  BUSINESS_YEARLY = 'business_yearly',
  ENTERPRISE_MONTHLY = 'enterprise_monthly',
  ENTERPRISE_YEARLY = 'enterprise_yearly',
}

export type TUpdateSubscriptionResponse = {
  type: string;
  data: {
    subscription_id: string;
    client_secret: string;
  };
};

export type TStripeAccountTypes = {
  org_id: number;
  data: ConvertedData;
};
