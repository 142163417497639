import { AxiosError } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { rfqsApi } from 'api';

import { TRfqsParams } from './types';

export const getRfqs = createAsyncThunk(
  'requisitionSlice/getRfqs',
  async (options: TRfqsParams) => {
    try {
      const response = await rfqsApi.getRfqsRequest(options);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);
