import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { GridColDef } from '@mui/x-data-grid';
import { Box, CircularProgress } from '@mui/material';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector, usePdfDownloadLink } from 'hooks';
import {
  estimateDiscountSelector,
  estimateItemSelector,
} from 'store/slices/estimatesSlice/selectors';
import {
  deleteEstimate,
  getEstimateByUuid,
  sendEstimateToCustomer,
} from 'store/slices/estimatesSlice/thunks';
import {
  Button,
  CustomTypography,
  CustomBreadcrumbs,
  EstimateInfoView,
  ScrollLayout,
  PortalDropDown,
  CustomDataGrid,
} from 'components';
import { getCurrentDateTime } from 'utils';
import { Colors, ImgUrlFolders, Routes } from 'types';
import { convertToMMDDYYYY } from 'utils/formatDate';
import DeletePopup from 'components/views/DeletePopup';
import { getCustomerById } from 'store/slices/customersSlices/thunks';
import { getCurrentSectionVersions, sendAgreementToCustomer } from 'store/thunks';
import {
  userAllRolesLoadingSelector,
  userPermissionsSelector,
} from 'store/slices/authSlice/selectors';
import { generateInventoryItemNumber } from 'utils/generateInventoryItemNumber';
import { currentCustomerSelector } from 'store/slices/customersSlices/selectors';
import { currentSectionVersionsSelector } from 'store/slices/catalogSlice/selectors';
import { ArrowCircleDown, ArrowDown, DownloadPdfIcon, SendEmailIcon } from 'assets/icons';
import BomEstimateFooter from 'containers/BomEstimateItem/BomEstimateFooter';

import { paymentScheduleData } from './utils';
import {
  billingInfoFromCustomerCreator,
  customerInfoFromCustomerCreator,
} from './GenerateEstimate.utils';
import styles from './GenerateEstimate.module.scss';
import { breadCrumbOptions, columns } from './utils';
import { TBillingInfoFromCustomerReturn, TCustomerInfoFromCustomerCreatorReturn } from './types';

import type { TGeneralInfo } from 'components/shared/EstimateForms/types';

const GenerateEstimate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams();

  const selectedEstimate = useAppSelector(estimateItemSelector);
  const {
    isAccessToEstimatesEditor,
    isAccessToCatalogsEditor,
    isAccessToCustomerEditor,
    isAccessToInventoryEditor,
  } = useAppSelector(userPermissionsSelector);

  const haveFullAccess =
    isAccessToEstimatesEditor &&
    isAccessToCatalogsEditor &&
    isAccessToCustomerEditor &&
    isAccessToInventoryEditor;

  const discount = useAppSelector(estimateDiscountSelector);
  const versions = useAppSelector(currentSectionVersionsSelector);
  const userRolesLoading = useAppSelector(userAllRolesLoadingSelector);

  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);

  const [collapseName, setCollapseName] = useState<string | null>(null);
  const [collapseSchedule, setCollapseSchedule] = useState<boolean>(false);

  const handleScheduleCollapse = () => {
    setCollapseSchedule(!collapseSchedule);
  };

  const collapseClasses = classNames(styles.container__content__info__item__info__title__icon, {
    [styles.container__content__info__item__info__title__icon_open]: collapseSchedule,
  });

  let totalAmount = 0;

  useEffect(() => {
    if (selectedEstimate?.customer_id) {
      dispatch(getCustomerById(selectedEstimate?.customer_id as number));
    }
    // eslint-disable-next-line prettier/prettier, react-hooks/exhaustive-deps
  }, [selectedEstimate?.customer_id]);

  useEffect(() => {
    if (id) {
      const getEstimate = async () => {
        const response = await dispatch(getEstimateByUuid(id));

        !response.payload && navigateToEstimates();
      };

      getEstimate();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const navigateToEstimates = () => navigate('/work-flow/bom-estimate');

  const navigateToEditEstimate = () =>
    navigate(`/work-flow/bom-estimate/estimate/${selectedEstimate?.uuid}`);

  const onDelete = async () => {
    if (selectedEstimate) {
      await dispatch(deleteEstimate(selectedEstimate.id));
      navigateToEstimates();
      togglePopup();
    }
  };
  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const formattedData = convertToMMDDYYYY(getCurrentDateTime(selectedEstimate?.created_at));

  const currentCustomer = useAppSelector(currentCustomerSelector);

  const customerInfoFromCustomer = customerInfoFromCustomerCreator(currentCustomer);

  const billingInfoFromCustomer = billingInfoFromCustomerCreator(currentCustomer);

  const customerInformation = selectedEstimate?.customer_info || customerInfoFromCustomer;
  const billingInformation = selectedEstimate?.bill_to || billingInfoFromCustomer;
  const generalInformation = selectedEstimate?.general_info;

  const { fetchDataAndDownload: fetchDataAndDownloadEstimate } = usePdfDownloadLink({
    fileUrl: selectedEstimate?.estimate_media_url_id as string,
    folder: ImgUrlFolders.DOCUMENT,
    withToastMessages: true,
  });

  const { fetchDataAndDownload: fetchDataAndDownloadAgreement } = usePdfDownloadLink({
    fileUrl: selectedEstimate?.agreement_media_url_id as string,
    folder: ImgUrlFolders.DOCUMENT,
    withToastMessages: true,
  });

  const publishEstimate = () => {
    selectedEstimate?.id && dispatch(sendEstimateToCustomer(selectedEstimate?.id));
  };

  const publishAgreement = () => {
    selectedEstimate?.id && dispatch(sendAgreementToCustomer(selectedEstimate?.id));
  };

  const dropdownItems = (title: string) => [
    {
      id: 1,
      icon: <DownloadPdfIcon />,
      name: 'Download PDF',
      action: title === 'estimate' ? fetchDataAndDownloadEstimate : fetchDataAndDownloadAgreement,
    },
    {
      id: 2,
      icon: <SendEmailIcon />,
      name: 'Send to Customer',
      action: title === 'estimate' ? publishEstimate : publishAgreement,
    },
  ];

  const renderColumns: GridColDef[] = columns.map((column) => ({
    ...column,
    hideSortIcons: true,
    disableColumnMenu: true,
    disableColumnSelector: true,
  }));

  const renderSections = selectedEstimate?.sections?.map((section) => {
    let totalSectionAmount = 0;

    const renderRows = section.items.map((el) => {
      const itemId = !isNaN(el.part_id as number) && generateInventoryItemNumber(el.id);

      const priceValue = Number(el?.price);

      totalSectionAmount += priceValue * Number(el.qty_required);

      return {
        id: el.id,
        item: itemId ? `#${itemId}` : '--',
        description: el.description || '--',
        price: el?.price || '0',
        quantity: el.qty_required,
        amount: Number(el.amount).toFixed(2) || '0',
        options: <Box />,
      };
    });

    totalAmount += totalSectionAmount;

    const isOpen = collapseName === `${section.section_name}/${section?.id}`;

    const collapseClasses = classNames(styles.container__content__info__item__info__title__icon, {
      [styles.container__content__info__item__info__title__icon_open]: isOpen,
    });

    const handleCollapseName = (name: string) => {
      if (collapseName === name) {
        setCollapseName(null);
      } else {
        setCollapseName(name);
      }
    };

    return (
      <Box key={section?.id} className={styles.container__content__info__item__info}>
        <Box
          className={styles.container__content__info__item__info__title}
          onClick={() => handleCollapseName(`${section.section_name}/${section?.id}`)}
        >
          <ArrowCircleDown width={24} height={24} className={collapseClasses} />
          {section.section_name}
        </Box>
        {isOpen && (
          <Box className={styles.container__content__info__item__info__content}>
            <ScrollLayout>
              <CustomDataGrid
                rowHeight={44}
                headerHeight={44}
                rows={renderRows}
                columns={renderColumns}
              />
            </ScrollLayout>
          </Box>
        )}
      </Box>
    );
  });

  const renderOptions = breadCrumbOptions({
    name: selectedEstimate?.name as string,
    id: String(id),
  });

  const totalAmountFormatted = totalAmount.toFixed(2);

  const formattedSchedule = versions?.payment_schedule.schedule.stages.map((el) => {
    return {
      percent: `${el.percentage_complete}%`,
      amount: `$ ${(Number(totalAmountFormatted) * el.percentage_complete) / 100} /-`,
    };
  });

  const aggregatedData = paymentScheduleData.map((header) => {
    const values = formattedSchedule?.map((item: any) => item[header.value.toLowerCase()]); // Get the values for the specific header

    return {
      title: header.title,
      id: header.id,
      values,
    };
  });

  const renderPaymentScheduleItem = aggregatedData.map(({ title, id, values }) => {
    const renderItems = values?.map((item, index) => (
      <CustomTypography
        key={index}
        className={styles.container__content__info__item__info__schedule__item__subtitle}
      >
        {item}
      </CustomTypography>
    ));

    return (
      <Box key={id} className={styles.container__content__info__item__info__schedule__item}>
        <CustomTypography
          className={styles.container__content__info__item__info__schedule__item__title}
        >
          {title}
        </CustomTypography>

        {renderItems}
      </Box>
    );
  });

  const renderPaymentSchedule = (
    <>
      <Box className={styles.container__content__info__item__info}>
        <Box
          className={styles.container__content__info__item__info__title}
          onClick={handleScheduleCollapse}
        >
          <ArrowCircleDown width={24} height={24} className={collapseClasses} />
          Payment Schedule
        </Box>
        {collapseSchedule && (
          <Box className={styles.container__content__info__item__info__schedule}>
            {renderPaymentScheduleItem}
          </Box>
        )}
      </Box>
    </>
  );

  useEffect(() => {
    selectedEstimate?.catalog_versions_id &&
      dispatch(getCurrentSectionVersions(selectedEstimate?.catalog_versions_id as number));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEstimate?.catalog_versions_id]);

  const taxValue =
    ((Number(totalAmountFormatted) * Number(currentCustomer?.tax)) / 100).toFixed(2) || 0;

  const totalPrice = (
    (Number(totalAmountFormatted) - discount.discount || Number(totalAmountFormatted)) +
    Number(taxValue)
  ).toFixed(2);

  if (!haveFullAccess && !userRolesLoading) {
    return <Navigate to={Routes.ActivityFeed} replace />;
  }

  return (
    <>
      <Box className={styles.container}>
        {!selectedEstimate ? (
          <Box className={styles.container__loading}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Box className={styles.container__header}>
              <Box className={styles.container__header__routing}>
                <CustomBreadcrumbs options={renderOptions} />
              </Box>
            </Box>
            <Box className={styles.container__content}>
              <Box className={styles.container__content__about}>
                <Box className={styles.container__content__about__info}>
                  <CustomTypography className={styles.container__content__about__info__title}>
                    {selectedEstimate?.name || 'Estimate Name'}
                  </CustomTypography>
                  <Box className={styles.container__content__about__info__details}>
                    <CustomTypography
                      className={styles.container__content__about__info__details__text}
                    >
                      Created By: {selectedEstimate?.created_by_name}
                    </CustomTypography>
                    <CustomTypography
                      className={styles.container__content__about__info__details__text}
                    >
                      Created Date: {formattedData}
                    </CustomTypography>
                    <CustomTypography
                      className={styles.container__content__about__info__details__text}
                    >
                      Version Number: {selectedEstimate?.version_no}
                    </CustomTypography>
                    <CustomTypography
                      className={styles.container__content__about__info__details__text}
                    >
                      Customer: {selectedEstimate?.customer_name}
                    </CustomTypography>
                  </Box>
                </Box>
                <Box className={styles.container__content__about__buttons}>
                  <Box className={styles.container__content__about__buttons__group}>
                    <Box className={styles.container__content__about__buttons__group__item}>
                      <PortalDropDown
                        title={
                          <Button
                            color={Colors.WHITE}
                            backgroundColor={Colors.SAPPHIRE}
                            minWidth='158px'
                            endIcon={
                              <ArrowDown
                                width={20}
                                height={20}
                                className={styles.container__content__about__buttons__send__icon}
                              />
                            }
                            disabled={!haveFullAccess}
                            className={styles.container__content__about__buttons__send}
                          >
                            Share Estimate
                          </Button>
                        }
                        options={dropdownItems('estimate')}
                      />

                      {selectedEstimate.estimate_last_send && (
                        <CustomTypography
                          className={styles.container__content__about__buttons__group__item__text}
                        >
                          Last Send: {convertToMMDDYYYY(selectedEstimate.estimate_last_send)}
                        </CustomTypography>
                      )}
                    </Box>
                    <Box className={styles.container__content__about__buttons__group__item}>
                      <PortalDropDown
                        title={
                          <Button
                            color={Colors.WHITE}
                            backgroundColor={Colors.SAPPHIRE}
                            maxWidth='174px'
                            endIcon={
                              <ArrowDown
                                width={20}
                                height={20}
                                className={styles.container__content__about__buttons__send__icon}
                              />
                            }
                            disabled={!haveFullAccess}
                            className={styles.container__content__about__buttons__send}
                          >
                            Share Agreement
                          </Button>
                        }
                        options={dropdownItems('agreement')}
                      />

                      {selectedEstimate.agreement_last_send && (
                        <CustomTypography
                          className={styles.container__content__about__buttons__group__item__text}
                        >
                          Last Send: {convertToMMDDYYYY(selectedEstimate.agreement_last_send)}
                        </CustomTypography>
                      )}
                    </Box>
                  </Box>
                  <Box className={styles.container__content__about__buttons__group}>
                    <Button
                      minWidth='64px'
                      color={Colors.SAPPHIRE}
                      onClick={togglePopup}
                      backgroundColor={Colors.PALE_BLUE}
                      disabled={!haveFullAccess}
                      className={styles.container__content__about__buttons__item}
                    >
                      Delete
                    </Button>
                    <Button
                      minWidth='64px'
                      color={Colors.SAPPHIRE}
                      backgroundColor={Colors.PALE_BLUE}
                      onClick={navigateToEditEstimate}
                      disabled={!haveFullAccess}
                      className={styles.container__content__about__buttons__item}
                    >
                      Edit
                    </Button>
                  </Box>
                </Box>
              </Box>
              {selectedEstimate && (
                <Box>
                  <Box className={styles.container__content__info}>
                    <Box className={styles.container__content__info__item}>
                      <EstimateInfoView
                        title='Customer Information'
                        options={customerInformation as TCustomerInfoFromCustomerCreatorReturn}
                      />
                    </Box>
                    <Box className={styles.container__content__info__item}>
                      <EstimateInfoView
                        title='Billing Information'
                        options={billingInformation as TBillingInfoFromCustomerReturn}
                      />
                    </Box>
                    {generalInformation && (
                      <Box className={styles.container__content__info__item}>
                        <EstimateInfoView
                          title='General Information'
                          options={generalInformation as TGeneralInfo}
                        />
                      </Box>
                    )}
                    <Box className={styles.container__content__info__item}>{renderSections}</Box>
                    <Box className={styles.container__content__info__item}>
                      {renderPaymentSchedule}
                    </Box>
                  </Box>
                  <BomEstimateFooter
                    forView
                    totalPrice={totalPrice}
                    taxValue={taxValue}
                    totalAmountFormatted={totalAmountFormatted}
                  />
                </Box>
              )}
            </Box>
          </>
        )}
        <DeletePopup
          withBody
          isOpen={isPopupOpen}
          onClose={togglePopup}
          onDelete={onDelete}
          title='Delete Confirmation'
          body='This will delete the estimate. Please confirm.'
        />
      </Box>
    </>
  );
};

export default GenerateEstimate;
