import { TPlanDurationPrices, TSubscriptionPlan } from 'types/global/durationTypes';

export const subscriptionPlansNew: TSubscriptionPlan[] = [
  {
    id: 1,
    title: 'Professional Plan',
    options: [
      'Estimates and Agreements',
      'Invoice Automation',
      'Employee Activities Monitoring',
      'Work Orders and Scheduling',
      'Live Stream Coordination',
      'Inventory Management',
      'Employee Management',
      'Enhanced Team Collaboration',
      'Supports up to 5 users',
    ],
    durations: [
      { id: 1, duration: 'Monthly', price: 24.99 },
      { id: 2, duration: 'Yearly', price: 19.99 },
    ],
    monthlyPrice: '24.99',
    yearlyPrice: '19.99',
  },
  {
    id: 2,
    title: `Business Plan`,
    options: [
      'All features in Professional Plan',
      'Gen AI powered Reminders',
      'Gen AI powered Estimates',
      'Gen AI powered Work Orders',
      'Gen AI powered Employee Scheduling',
      'Supports up to 50 users',
    ],
    durations: [
      { id: 1, duration: 'Monthly', price: 29.99 },
      { id: 2, duration: 'Yearly', price: 24.99 },
    ],
    monthlyPrice: '29.99',
    yearlyPrice: '24.99',
  },
  {
    id: 3,
    title: 'Enterprise Grid',
    options: [
      'All In Business Plan',
      'Support for 100+ Employees',
      `24/7 Phone Support`,
      `Unlimited Document Storage`,
    ],
    durations: [
      { id: 1, duration: 'Monthly', price: 75 },
      { id: 2, duration: 'Yearly', price: 810 },
    ],
    monthlyPrice: 'Contact us',
    yearlyPrice: 'Contact us',
  },
];

const subscriptionPlans = {
  proPlan: [
    'Estimates and Agreements',
    'Invoice Automation',
    'Employee Activities Monitoring',
    'Work Orders and Scheduling',
    'Live Stream Coordination',
    'Inventory Management',
    'Employee Management',
    'Enhanced Team Collaboration',
    'Supports up to 5 users',
  ],
  BusinessPlan: [
    'All features in Professional Plan',
    'Gen AI powered Reminders',
    'Gen AI powered Estimates',
    'Gen AI powered Work Orders',
    'Gen AI powered Employee Scheduling',
    'Supports up to 50 users',
  ],
  enterpriseGrid: [
    'All In Business Plan',
    'Support for 100+ Employees',
    `24/7 Phone Support`,
    `Unlimited Document Storage`,
  ],
  durationsPro: [
    { id: 1, duration: 'Monthly', price: 24.99 },
    { id: 2, duration: 'Yearly', price: 19.99 },
  ] as TPlanDurationPrices[],
  durationsBussiness: [
    { id: 1, duration: 'Monthly', price: 29.99 },
    { id: 2, duration: 'Yearly', price: 24.99 },
  ] as TPlanDurationPrices[],
  durationsEnterprise: [
    { id: 1, duration: 'Monthly', price: 75 },
    { id: 2, duration: 'Yearly', price: 810 },
  ] as TPlanDurationPrices[],
};

export default subscriptionPlans;
