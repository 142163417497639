import { useEffect, useState } from 'react';
import bowser from 'bowser';

const useBrowserVersion = () => {
  const [browserVersion, setBrowserVersion] = useState<string | null>(null);
  const [isVersionLow, setIsVersionLow] = useState<boolean>(false);

  useEffect(() => {
    const browser = bowser.getParser(window.navigator.userAgent);
    const versionString = browser?.getBrowserVersion();

    if (versionString) {
      setBrowserVersion(versionString);

      const versionParts = versionString.split('.').map(Number);
      const majorVersion = versionParts[0];
      const minorVersion = versionParts[1];

      if (majorVersion < 16 || (majorVersion === 16 && minorVersion < 4)) {
        setIsVersionLow(true);
      } else {
        setIsVersionLow(false);
      }
    }
  }, []);

  return { browserVersion, isVersionLow };
};

export default useBrowserVersion;
