import { FC, useState } from 'react';
import { isSafari } from 'react-device-detect';
import { noop } from 'lodash';

import { useVideoData } from 'hooks';
import { ImageBlank } from 'assets/images';
import useMediaStreaming from 'hooks/useMedia';
import { CustomSkeleton } from 'components/shared';

import ImgWithDefault from '../ImgWithDefault';

import type { TPreloadedImgProps } from './types';

const PreloadedImg: FC<TPreloadedImgProps> = ({
  imgId,
  border,
  style,
  videoRef,
  folderName,
  imgStyles,
  borderRadius,
  width = '100%',
  loading = true,
  height = '100%',
  forVideo = false,
  controls = false,
  withCashing = true,
}) => {
  const [isLoadedVideo, setIsVideoLoaded] = useState<boolean>(false);

  const { mediaSource, isLoaded } = useMediaStreaming({
    folderName,
    imgId: imgId as string,
    withCashing,
  });

  const { mediaSource: mediaSourceVideo, isLoaded: isVideLoaded } = useVideoData(
    folderName,
    imgId as string,
  );

  const loaderType = forVideo ? isVideLoaded : isLoaded;
  const dataType = forVideo ? mediaSourceVideo?.url : mediaSource;

  const handleVideoDataLoaded = () => {
    setIsVideoLoaded(true);
  };

  return (
    <>
      {loaderType && loading && (
        <CustomSkeleton height={height || 'inherit'} width={width || 'inherit'} />
      )}
      {dataType && !loaderType && (
        <>
          {forVideo ? (
            <>
              {!isLoadedVideo && (
                <div style={{ position: 'absolute', width: '100%', height: '100%' }}>
                  <CustomSkeleton height={height || 'inherit'} width={width || 'inherit'} />
                </div>
              )}

              <video
                ref={videoRef}
                playsInline
                src={dataType}
                controls={controls}
                // poster={mediaSourceThumb as string}
                onProgress={isSafari ? handleVideoDataLoaded : noop}
                onLoadedData={!isSafari ? handleVideoDataLoaded : noop}
              />
            </>
          ) : (
            <ImgWithDefault
              imgStyles={imgStyles}
              fallback={ImageBlank}
              src={dataType}
              style={{ ...style, width, height, border, borderRadius }}
            />
          )}
        </>
      )}
    </>
  );
};

export default PreloadedImg;
