import * as yup from 'yup';

export const schema = yup
  .object()
  .shape({
    email: yup
      .string()
      .trim()
      .lowercase()
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        'Enter a valid email address',
      )
      .nullable(),
    phone: yup
      .string()
      .test('is-valid-mobile', 'Phone Number must be a 10-digit number', function (value) {
        const { path, createError } = this;
        if (value && value.length > 0) {
          if (!/^\(\d{3}\) \d{3}-\d{4}$/.test(value)) {
            return createError({
              path,
              message: 'Phone Number must be a 10-digit number',
            });
          }
        }
        return true;
      })
      .nullable(),
    customer: yup.string().trim().required('Customer/Technician is required'),
  })
  .test('either-email-or-phone', 'Either Email or Phone is required', function (values) {
    const { email, phone } = values;
    if (!email && !phone) {
      return false;
    }
    return true;
  });
