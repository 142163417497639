import { FC, useState } from 'react';
import Hamburger from 'hamburger-react';

import { PortalDropDown } from 'components';

import styles from './HeaderDropDown.module.scss';
import { THeaderDropDown } from './types';

const HeaderDropDown: FC<THeaderDropDown> = ({ options }) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const openDropDown = () => setIsExpanded(!isExpanded);

  return (
    <div className={styles.container}>
      <PortalDropDown
        getDropDownState={setIsExpanded}
        title={<Hamburger toggled={isExpanded} size={18} onToggle={openDropDown} distance='sm' />}
        options={options}
      />
      <div></div>
    </div>
  );
};

export default HeaderDropDown;
