import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumbs, styled, Chip, Box, Skeleton } from '@mui/material';

import { ArrowRightNavigate } from 'assets/icons';
import { useCustomTheme } from 'infrastructure/Theming/CustomThemeProvider';

import { TCustomBreadcrumbsProps } from './types';
import { breadCrumbBoxStyles, breadCrumbContainer, breadCrumbsStyles } from './styles';

const CustomBreadcrumbs: FC<TCustomBreadcrumbsProps> = ({ options }) => {
  const theme = useCustomTheme();
  const StyledBreadcrumb = styled(Chip)(() => {
    return {
      color: theme?.colors.sapphire,
      cursor: 'pointer',

      '.MuiBreadcrumbs-separator': {
        margin: 0,
      },

      '.MuiChip-label': {
        padding: 0,
      },

      '& .css-1wuw8dw-MuiBreadcrumbs-separator': {
        margin: 0,
      },
    };
  }) as typeof Chip;

  const StyledSeparator = styled(ArrowRightNavigate)(() => {
    return {
      margin: '0',
      cursor: 'auto',
    };
  }) as typeof ArrowRightNavigate;

  const breadCrumbs = options?.map((option, idx) => {
    if (idx !== options?.length - 1) {
      return (
        <Link to={option?.goTo || '#'} key={idx}>
          <StyledBreadcrumb sx={breadCrumbsStyles} label={option?.label} />
        </Link>
      );
    } else {
      return (
        <Box key={idx} sx={breadCrumbBoxStyles}>
          {!option?.label || option?.label === 'undefined' ? (
            <Skeleton height={20} width={100} />
          ) : (
            <StyledBreadcrumb sx={breadCrumbContainer} label={option?.label} />
          )}
        </Box>
      );
    }
  });

  return (
    <Breadcrumbs separator={<StyledSeparator />} aria-label='breadcrumb'>
      {breadCrumbs}
    </Breadcrumbs>
  );
};

export default CustomBreadcrumbs;
