import { createSlice } from '@reduxjs/toolkit';

import { TInitialState } from './types';
import { getAllRoles } from './thunks';

const initialState: TInitialState = {
  allRolesLoading: false,
  allRolesError: null,
  allRoles: [],
};

const rolesSlice = createSlice({
  name: 'rolesSlice',
  reducers: {},
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getAllRoles.pending, (state) => {
        state.allRolesLoading = true;
        state.allRolesError = null;
      })
      .addCase(getAllRoles.fulfilled, (state, action) => {
        state.allRolesLoading = false;
        state.allRolesError = null;
        state.allRoles = action.payload;
      })
      .addCase(getAllRoles.rejected, (state, action) => {
        state.allRolesLoading = false;
        state.allRolesError = action.payload as string;
      });
  },
});

export default rolesSlice.reducer;
