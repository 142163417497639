export enum ActivityFeedToastMessages {
  POST_CREATION = 'A Post creation',
  POST_CREATION_SUCCESS = 'The Post was created successfully',
  POST_CREATION_FAILURE = 'Failed to create a post',
  POST_EDIT_SUCCESS = 'The Post was edited successfully',
  POST_EDIT_FAILURE = 'Failed to edit the post',
  POST_REMOVAL_SUCCESS = 'The Post was removed successfully',
  POST_REMOVAL_FAILURE = 'Failed to remove the post',
  POST_EDIT_START = 'The post is being edited',
  POST_REMOVAL_START = 'The post is being removed',
}

export enum UploadMessages {
  IMAGE_SIZE_EXCEEDED = 'Image size exceeds the 5MB limit. Please reduce the file size and try again.',
  VIDEO_SIZE_EXCEEDED = 'Video size exceeds the 10GB limit. Please compress or reduce the file size and try again.',
  LOGO_SIZE_EXCEEDED = 'Logo size exceeds the 200KB limit. Please reduce the file size and try again.',
}

export enum NoticeToastMessages {
  NOTICE_CREATION = ' A Notice creation',
  NOTICE_CREATION_SUCCESS = 'The Notice was created successfully',
  NOTICE_CREATION_FAILURE = 'Failed to create a notice',
  NOTICE_EDIT_SUCCESS = 'The Notice was edited successfully',
  NOTICE_EDIT_FAILURE = 'Failed to edit the notice',
  NOTICE_EDIT_START = 'The notice is being edited',
  NOTICE_REMOVAL_SUCCESS = 'The Notice  was removed successfully',
  NOTICE_REMOVAL_FAILURE = 'Failed to remove the notice',
  NOTICE_REMOVAL_START = 'The notice is being removed',
}

export enum BillingUpdateToastMessages {
  BILLING_UPDATE_SUCCESS = 'The Billing details were updated successfully',
  BILLING_UPDATE_FAILURE = 'Failed to update the billing details',
  BILLING_UPDATE_START = 'The Billing details are being updated',
}

export enum StripeAccountUpdateToastMessages {
  STRIPE_ACCOUNT_SUCCESS = 'Your stripe account will be created',
  STRIPE_ACCOUNT_FAILURE = 'Failed to create stripe account',
  STRIPE_ACCOUNT_START = 'The stripe account is being created',
}

export enum DiscountToastMessages {
  // Discount removal messages
  DISCOUNT_REMOVAL_SUCCESS = 'Discount removed successfully.',
  DISCOUNT_REMOVAL_FAILURE = 'Failed to remove the discount. Please try again.',
  DISCOUNT_REMOVAL_START = 'Removing discount in progress...',

  // Discount edit messages
  DISCOUNT_EDIT_SUCCESS = 'Discount details edited successfully.',
  DISCOUNT_EDIT_FAILURE = 'Failed to edit discount details. Please try again.',
  DISCOUNT_EDIT_START = 'Editing discount details in progress...',

  // Discount creation messages
  DISCOUNT_CREATE_SUCCESS = 'Discount details created successfully.',
  DISCOUNT_CREATE_FAILURE = 'Failed to create discount details. Please try again.',
  DISCOUNT_CREATE_START = 'Creating discount details in progress...',
}

export enum SignInToastMessages {
  SIGNIN_SUCCESS = 'Welcome back! You are now signed in.',
  SIGNIN_FAILURE = 'Oops! Unable to sign in. Please check your credentials and try again.',
  SIGNIN_START = 'Signing you in. Please wait...',
}

export enum PurchaseRequisitionToastMessagesNew {
  CREATE_SUCCESS = 'Purchase requisition was created successfully',
  CREATE_FAILURE = 'Failed to create the purchase requisition',
  CREATE_START = 'Creating the purchase requisition',
  UPDATE_SUCCESS = 'Purchase requisition was updated successfully',
  UPDATE_FAILURE = 'Failed to update the purchase requisition',
  UPDATE_START = 'Updating the purchase requisition',
}

export enum SubscriptionUpdateToastMessages {
  SUBSCRIPTION_UPDATE_SUCCESS = 'The Subscription plan was updated successfully.',
  SUBSCRIPTION_UPDATE_FAILURE = 'Failed to update the Subscription plan',
  SUBSCRIPTION_UPDATE_START = 'The Subscription plan is being updated',
}

export enum PaymentMethodUpdateToastMessages {
  PAYMENT_METHOD_UPDATE_SUCCESS = 'The Payment method was confirmed successfully',
  PAYMENT_METHOD_UPDATE_FAILURE = 'Failed to confirm the Payment method',
  PAYMENT_METHOD_UPDATE_START = 'The Payment method is being confirmed',
}

export enum TeamToastMessages {
  TEAM_CREATION = 'A Team creation',
  TEAM_CREATION_SUCCESS = 'The Team was created successfully',
  TEAM_CREATION_FAILURE = 'Failed to create a Team',
  TEAM_EDIT_SUCCESS = 'The Team was edited successfully',
  TEAM_EDIT_FAILURE = 'Failed to edit the Team',
  TEAM_EDIT_START = 'The Team is being edited',
  TEAM_REMOVAL_SUCCESS = 'The Team  was removed successfully',
  TEAM_REMOVAL_FAILURE = 'Failed to remove the Team',
  TEAM_REMOVAL_START = 'The Team is being removed',
}

export enum InventoryItemToastMessages {
  INVENTORY_ITEM_CREATION = 'An Inventory item creation',
  INVENTORY_ITEM_CREATION_SUCCESS = 'The Inventory item was created successfully',
  INVENTORY_ITEM_CREATION_FAILURE = 'Failed to create an Inventory item',
  INVENTORY_ITEM_EDIT_SUCCESS = 'The Inventory item was edited successfully',
  INVENTORY_ITEM_EDIT_FAILURE = 'Failed to edit the Inventory item',
  INVENTORY_ITEM_REMOVAL_SUCCESS = 'The Inventory item was removed successfully',
  INVENTORY_ITEM_REMOVAL_FAILURE = 'Failed to remove the Inventory item',
  INVENTORY_ITEM_EDIT_START = 'The Inventory item is being edited',
  INVENTORY_ITEM_EDIT_APPROVED = 'The Inventory item edit was approved',
  INVENTORY_ITEM_EDIT_DECLINED = 'The Inventory item edit was declined',
  INVENTORY_ITEM_REMOVAL_START = 'The inventory item is being removed',
}

export enum OrganizationMemberToastMessages {
  ORGANIZATION_MEMBER_REMOVAL_START = 'The organization member is being removed',
  ORGANIZATION_MEMBER_REMOVAL_SUCCESS = 'The Organization member was removed successfully',
  ORGANIZATION_MEMBER_REMOVAL_FAILURE = 'Failed to remove the organization member',
  INVITATION_SEND_START = 'The invitation request is being sent',
  INVITATION_SEND_SUCCESS = 'The Invitation request was sent successfully',
  INVITATION_SEND_FAIL = 'The Invitation request sending failed',
  MEMBER_UPDATE_START = 'The Member is being updated',
  MEMBER_UPDATE_SUCCESS = 'The Member  was updated successfully',
  MEMBER_UPDATE_FAIL = 'Failed to update the member',
}

export enum ProfileInformationUpdateToastMessages {
  PROFILE_UPDATE_START = 'The profile information is being updated',
  PROFILE_UPDATE_SUCCESS = 'The Profile information was updated successfully',
  PROFILE_UPDATE_FAILURE = 'Failed to update the profile information.',
}

export enum OrganizationInformationUpdateToastMessages {
  ORGANIZATION_UPDATE_START = 'The organization information is being updated',
  ORGANIZATION_UPDATE_SUCCESS = 'The Organization information  was updated successfully',
  ORGANIZATION_UPDATE_FAILURE = 'Failed to update  the Organization information',
}

export enum AccountInformationUpdateToastMessages {
  ACCOUNT_UPDATE_START = 'Account is under reviewing ',
  ACCOUNT_UPDATE_SUCCESS = 'Account is currently under review',
  ACCOUNT_UPDATE_FAILURE = 'Failed to create Account',
}

export enum VendorToastMessages {
  VENDOR_CREATION = 'A Vendor creation',
  VENDOR_CREATION_SUCCESS = ' The Vendor was created successfully',
  VENDOR_CREATION_FAILURE = 'Failed to create the Vendor.',
  VENDOR_EDIT_SUCCESS = 'The Vendor  was edited successfully',
  VENDOR_EDIT_FAILURE = 'Failed to edit the Vendor',
  VENDOR_EDIT_START = 'Edit the Vendor',
  VENDOR_REMOVAL_SUCCESS = 'The Vendor was removed successfully',
  VENDOR_REMOVAL_FAILURE = 'Failed to remove the Vendor',
  VENDOR_REMOVAL_START = 'The Vendor is being removed',
}

export enum CatalogToastMessages {
  CATALOG_CREATION = 'A Catalog creation',
  CATALOG_CREATION_SUCCESS = 'The Catalog was created successfully',
  CATALOG_CREATION_FAILURE = 'Failed to create a Catalog',
  CATALOG_EDIT_SUCCESS = 'The Catalog was edited successfully',
  CATALOG_EDIT_FAILURE = 'Failed to edit the Catalog',
  CATALOG_EDIT_START = 'The Catalog is being edited',
  CATALOG_REMOVAL_SUCCESS = 'The Catalog  was removed successfully',
  CATALOG_REMOVAL_FAILURE = 'Failed to remove the Catalog',
  CATALOG_REMOVAL_START = 'The Catalog is being removed',
  EXCEL_REMOVAL_START = 'The Template is being removed',
  EXCEL_CREATION = 'A Template creation',
  EXCEL_REMOVAL_SUCCESS = 'The Template was removed successfully',
  EXCEL_REMOVAL_FAILURE = 'Failed to remove the Template',
  EXCEL_CREATION_SUCCESS = ' The Template was created successfully',
  EXCEL_CREATION_FAILURE = 'Failed to create the Template.',
  DEFAULT_EXCEL_CREATION_SUCCESS = ' Default Template was updated successfully',
  DEFAULT_EXCEL_CREATION_FAILURE = ' Failed to update Default Template',
}

export enum ReportSendingToastMessages {
  REPORT_SENDING_SUCCESS = 'The Report was sent successfully',
  REPORT_SENDING_FAILURE = 'Failed to send the report',
  REPORT_SENDING_START = 'The report is being sent',
}

export enum PdfDownloadToastMessages {
  PDF_DOWNLOAD_SUCCESS = 'PDF ready for download',
  PDF_DOWNLOAD_FAILURE = 'Failed to download the PDF',
  PDF_DOWNLOAD_IN_PROGRESS = 'PDF download in progress. Please wait...',
}

export enum PdfViewToastMessages {
  PDF_VIEW_SUCCESS = 'PDF opened successfully',
  PDF_VIEW_FAILURE = 'Failed to open the PDF',
  PDF_VIEW_IN_PROGRESS = 'Opening PDF. Please wait...',
}

export enum XLSXDownloadToastMessages {
  XLSX_DOWNLOAD_SUCCESS = 'Excel ready for download',
  XLSX_DOWNLOAD_FAILURE = 'Failed to download the Excel',
  XLSX_DOWNLOAD_IN_PROGRESS = 'Excel download in progress. Please wait...',
}

export enum XLSXViewToastMessages {
  XLSX_VIEW_SUCCESS = 'Excel opened successfully',
  XLSX_VIEW_FAILURE = 'Failed to open the Excel',
  XLSX_VIEW_IN_PROGRESS = 'Opening Excel. Please wait...',
}

export enum FileDownloadToastMessages {
  FILE_DOWNLOAD_SUCCESS = 'File ready for download',
  FILE_DOWNLOAD_FAILURE = 'Failed to download the file',
  FILE_DOWNLOAD_IN_PROGRESS = 'File download in progress. Please wait...',
}

export enum VideoRecordingToastMessages {
  VIDEO_RECORDING_SUCCESS = 'Video recording has been successfully created',
  VIDEO_RECORDING_FAILURE = 'Failed to create the video recording',
  VIDEO_RECORDING_IN_PROGRESS = 'Recorded video creation is in progress. Please wait...',
}

export enum MediaPermissionsErrorMessage {
  SystemPermissionDenied = 'System permission denied for media. Please enable the required permissions in your device settings.',
  UserPermissionDenied = 'You denied media permissions. Please enable the required permissions in your device settings.',
  CouldNotStartVideoSource = 'Could not start the video source. Please check and enable the required permissions in your device settings.',
  UnhandledError = 'Unhandled media permissions error. Please check and enable the required permissions in your device settings.',
}

export enum PurchaseOrderItemUpdateToastMessages {
  ITEM_UPDATE_SUCCESS = 'The Purchase order item was updated successfully',
  ITEM_UPDATE_FAILURE = 'Failed to update the Purchase order item',
  ITEM_UPDATE_START = 'The Purchase order item is being updated',
}

export enum PurchaseOrderReceiveToastMessages {
  ITEM_RECEIVE_SUCCESS = 'The Purchase order item received successfully',
  ITEM_RECEIVE_FAILURE = 'Failed to receive the Purchase order item',
  ITEM_RECEIVE_START = 'The Purchase order item is being received',
}

export enum PurchaseOrderSubmitToastMessages {
  ITEM_SUBMIT_SUCCESS = 'The Purchase order item submitted successfully',
  ITEM_SUBMIT_FAILURE = 'Failed to submit the Purchase order item',
  ITEM_SUBMIT_START = 'The Purchase order item is being submitted',
}

export enum PermissionUpdateToastMessages {
  PERMISSION_CREATE = 'The Permission was assigned successfully',
  PERMISSION_FAILURE = 'Failed to assign the permission',
  PERMISSION_START = 'The Permission assignment started successfully',
  PERMISSION_REMOVE = 'The Permission removal started.',
  PERMISSION_REMOVE_FAILURE = 'The Permission removal failed',
  PERMISSION_REMOVE_SUCCESS = 'The Permission was removed successfully',
}

export enum InvitedUserUpdateToastMessages {
  USER_UPDATE_START = 'The user status is being updated',
  USER_UPDATE_FAILURE = 'Failed to update the user status',
  USER_UPDATE_SUCCESS = 'The user status was updated successfully',
}

export enum DeleteInvitedUserToastMessages {
  USER_DELETE_START = 'Invited user is being deleted',
  USER_DELETE_FAILURE = 'Failed to delete the user',
  USER_DELETE_SUCCESS = 'The user was deleted successfully',
}

export enum ResendEmailToastMessages {
  RESEND_START = 'Invitation is being resent to user',
  RESEND_FAILURE = 'Failed to resent email to user',
}

export enum PurchaseOrderItemDeleteToastMessages {
  ITEM_DELETE_SUCCESS = 'The Purchase order item  was deleted successfully',
  ITEM_DELETE_FAILURE = 'Failed to delete the Purchase order item',
  ITEM_DELETE_START = 'The Purchase order item is being deleted',
}

export enum PurchaseRequisitionToastMessages {
  REQUISITION_DELETE_SUCCESS = 'The Requisition was deleted successfully',
  REQUISITION_DELETE_FAILURE = 'Failed to delete the requisition',
  REQUISITION_DELETE_START = 'The requisition is being deleted',
  REQUISITION_ITEM_DELETE_SUCCESS = 'The Requisition item was deleted successfully',
  REQUISITION_ITEM_DELETE_FAILURE = 'Failed to delete the requisition item',
  REQUISITION_ITEM_DELETE_START = 'The requisition item is being deleted',
  REQUISITION_CLONE_SUCCESS = 'The Requisition was cloned successfully',
  REQUISITION_CLONE_FAILURE = 'Failed to clone the requisition',
  REQUISITION_CLONE_START = 'The requisition is being cloned',
  REQUISITION_STATUS_UPDATE_SUCCESS = 'The Requisition status was updated successfully',
  REQUISITION_STATUS_UPDATE_FAILURE = 'Failed to update the requisition status',
  REQUISITION_STATUS_UPDATE_START = 'The requisition status is being updated',
}

export enum PurchaseOrderToastMessages {
  PURCHASE_ORDER_CREATE_START = 'Creating a Purchase order',
  PURCHASE_ORDER_CREATE_SUCCESS = 'The Purchase order was created successfully',
  PURCHASE_ORDER_CREATE_FAILURE = 'Failed to create the  Purchase order',
}

export enum EstimateToastMessages {
  ESTIMATE_CREATE_START = 'Creating  an estimate',
  ESTIMATE_CREATE_SUCCESS = 'The Estimate  was created successfully',
  ESTIMATE_CREATE_FAILURE = 'Failed to create the estimate',
  ESTIMATE_GENERATION_START = 'Generating an estimate',
  ESTIMATE_GENERATION_SUCCESS = 'The Estimate was generated successfully',
  ESTIMATE_GENERATION_FAILURE = 'Failed to generate the estimate',
  ESTIMATE_REMOVE_START = 'Initiating estimate removal process...',
  ESTIMATE_REMOVE_SUCCESS = 'The estimate was successfully removed.',
  ESTIMATE_REMOVE_FAILURE = 'Failed to remove the estimate. Please try again later.',
  ESTIMATE_SECTION_DELETE_START = 'The estimate section is being deleted',
  ESTIMATE_SECTION_DELETE_SUCCESS = 'The Estimate section  was deleted successfully',
  ESTIMATE_SECTION_DELETE_FAILURE = 'Failed to delete the estimate section.',
  ESTIMATE_SECTION_ITEM_DELETE_START = 'The estimate section item is being deleted',
  ESTIMATE_SECTION_ITEM_DELETE_SUCCESS = 'The Estimate section item was deleted successfully',
  ESTIMATE_SECTION_ITEM_DELETE_FAILURE = 'Failed to delete the estimate section item',
  ESTIMATE_ITEM_PUBLISH_START = 'The estimate item is being published',
  ESTIMATE_ITEM_PUBLISH_FAILURE = 'Failed to publish the estimate item',
  ESTIMATE_UPDATE_START = 'Updating  an estimate',
  ESTIMATE_UPDATE_SUCCESS = 'The Estimate  was updated successfully',
  ESTIMATE_UPDATE_FAILURE = 'Failed to update the estimate',
  ESTIMATE_SENDCUSTOMER_FAILED = 'Estimate under creation',
}

export enum AgreementToastMessages {
  AGREEMENT_CREATION = 'An Agreement creation',
  AGREEMENT_CREATION_SUCCESS = 'The Agreement was created successfully',
  AGREEMENT_CREATION_FAILURE = 'Failed to create the Agreement',
  AGREEMENT_EDIT_SUCCESS = 'The Agreement was edited successfully',
  AGREEMENT_EDIT_FAILURE = 'Failed to edit the Agreement',
  AGREEMENT_EDIT_START = 'The Agreement is being edited',
  AGREEMENT_REMOVAL_SUCCESS = 'The Agreement was removed successfully',
  AGREEMENT_REMOVAL_FAILURE = 'Failed to remove the Agreement',
  AGREEMENT_REMOVAL_START = 'The Agreement is being removed',
  AGREEMENT_ITEM_PUBLISH_START = 'The agreement is being published',
  AGREEMENT_ITEM_PUBLISH_FAILURE = 'Failed to publish the agreement',
  AGREEMENT_SENDCUSTOMER_FAILED = 'Agreement under creation',
}

export enum LiveStreamMessages {
  LIVESTREAM_CREATION = 'A Live Stream creation',
  LIVESTREAM_CREATION_SUCCESS = 'The Live Stream was created successfully',
  LIVESTREAM_CREATION_FAILURE = 'Failed to create the live Stream ',
}

export enum LiveStreamEndToastMessages {
  STREAM_END_SUCCESS = 'The Live stream ended successfully',
  STREAM_END_FAILURE = 'Failed to end the live stream.',
  STREAM_END_START = 'The live stream is ending',
}

export enum LiveStreamRemoveToastMessages {
  STREAM_REMOVE_SUCCESS = 'The Live stream removed successfully',
  STREAM_REMOVE_FAILURE = 'Failed to remove the live stream.',
  STREAM_REMOVE_START = 'The live stream is removing',
}

export enum ScreenshotToastMessages {
  SCREENSHOT_SUCCESS = 'Media captured successfully',
  SCREENSHOT_FAILURE = 'Failed to capture media',
  SCREENSHOT_PROCESSING = 'Processing media capture...',
}

export enum WorkOrderUpdateToastMessages {
  WORK_ORDER_UPDATE_SUCCESS = 'The Work order was updated successfully',
  WORK_ORDER_UPDATE_FAILURE = 'Failed to update the work order',
  WORK_ORDER_UPDATE_START = 'The work order is being updated',
}

export enum WorkOrderToastMessages {
  WORK_ORDER_CREATE_START = 'Creating a work order',
  WORK_ORDER_CREATION = 'A Work Order creation',
  WORK_ORDER_CREATION_SUCCESS = 'The Work Order was created successfully',
  WORK_ORDER_CREATION_FAILURE = 'Failed to create the Work Order',
  WORK_ORDER_EDIT_SUCCESS = 'The Work Order was edited successfully',
  WORK_ORDER_EDIT_FAILURE = 'Failed to edit the Work Order',
  WORK_ORDER_EDIT_START = 'The Work Order is being edited',
  WORK_ORDER_ARCHIVE_SUCCESS = 'Work Order was archived successfully',
  WORK_ORDER_ARCHIVE_FAILURE = 'Failed to archive Work Order',
  WORK_ORDER_ARCHIVE_START = 'The Work Order is being archived',
  WORK_ORDER_UPDATE_START = 'The Work Order is being updated',
  WORK_ORDER_UPDATE_SUCCESS = 'The Work Order was updated successfully',
  WORK_ORDER_UPDATE_FAILURE = 'Failed to Update the Work Order',
  TASK_DELETE_START = 'The Task is being Deleted',
  TASK_DELETE_SUCCESS = 'The Task was deleted successfully',
  TASK_DELETE_FAILURE = 'Failed to delete the Task',
  TASK_UPDATE_START = 'The Task is being updated',
  TASK_UPDATE_SUCCESS = 'The Task was updated successfully',
  TASK_UPDATE_FAILURE = 'Failed to update the Task',
}

export enum CustomerCreationToastMessages {
  CUSTOMER_CREATION_SUCCESS = 'The Customer was created successfully',
  CUSTOMER_CREATION_FAILURE = 'Failed to create the customer',
  CUSTOMER_CREATION_START = 'The customer is being created',
}

export enum TechnicalMembersRemovedToastMessages {
  REMOVAL_SUCCESS = 'The Technical members were removed successfully',
  REMOVAL_FAILURE = 'Failed to remove the technical members',
  REMOVAL_START = 'The technical members are being removed',
}

export enum CustomerRemovedToastMessages {
  REMOVAL_SUCCESS = 'The Customer was removed successfully',
  REMOVAL_FAILURE = 'Failed to remove the customer',
  REMOVAL_START = 'The customer is being removed',
}

export enum InvoicesToastMessages {
  INVOICE_CREATION = 'An Invoice creation',
  INVOICE_CREATION_SUCCESS = 'The Invoice was created successfully',
  INVOICE_CREATION_FAILURE = 'Failed to create the Invoice',
  INVOICE_EDIT_SUCCESS = 'The Invoice was edited successfully',
  INVOICE_EDIT_FAILURE = 'Failed to edit the Invoice',
  INVOICE_EDIT_START = 'The Invoice is being edited',
  INVOICE_REMOVAL_SUCCESS = 'The Invoice was removed successfully',
  INVOICE_REMOVAL_FAILURE = 'Failed to remove the Invoice',
  INVOICE_REMOVAL_START = 'The Invoice is being removed',
  INVOICE_PUBLISH_FAILURE = 'Failed to send the invoice to customer',
  INVOICE_PUBLISH_START = 'The Invoice is being sent to customer',
}

export enum CustomerUpdateToastMessages {
  UPDATE_SUCCESS = 'The Customer was updated successfully',
  UPDATE_FAILURE = 'Failed to update the customer',
  UPDATE_START = 'The customer is being updated',
}

export enum DocumentsToastMessages {
  UPLOAD_SUCCESS = 'The Document was uploaded successfully',
  UPLOAD_FAILURE = 'Failed to upload the Document',
  UPLOAD_START = 'The Document is being uploaded',
  DOCUMENT_REMOVAL_SUCCESS = 'The Document was removed successfully',
  DOCUMENT_REMOVAL_FAILURE = 'Failed to remove the Document',
  DOCUMENT_REMOVAL_START = 'The Document is being removed',
}

export enum CatalogShareToastMessages {
  CATALOG_SHARE_SUCCESS = 'The access granted successfully',
  CATALOG_SHARE_FAILURE = 'Failed to grant the access to the Catalog',
  CATALOG_ACCESS_START = 'The access is being granted to then Catalog',
  CATALOG_ACCESS_REMOVED_FAILED = 'Failed to remove the access to the Catalog',
  CATALOG_ACCESS_REMOVED_START = 'The access to the catalog is being removed',
  CATALOG_ACCESS_REMOVED_SUCCESS = 'The access to the Catalog was removed successfully',
}

export enum ProfitMarginToastMessages {
  PROFIT_MARGIN_UPDATE_START = 'The Profit margin is being updated',
  PROFIT_MARGIN_UPDATE_SUCCESS = 'The Profit margin was updated successfully',
  PROFIT_MARGIN_UPDATE_FAILURE = 'The Profit margin failed',
}

export enum ScheduleDetailsUpdateToastMessages {
  SCHEDULE_DETAILS_UPDATE_SUCCESS = 'The Schedule details were updated successfully',
  SCHEDULE_DETAILS_UPDATE_FAILURE = 'Failed to update the Schedule details',
  SCHEDULE_DETAILS_UPDATE_START = 'The schedule details are being updated',
}

export enum LIVE_STREAM_INVITATION {
  INVITATION_SEND_START = 'The invitation request is being sent',
  INVITATION_SEND_SUCCESS = 'The Invitation request was sent successfully',
  INVITATION_SEND_FAIL = 'The Invitation request sending failed',
}

export enum LIVE_STREAM_NOTE {
  LIVE_STREAM_NOTE_SEND_START = 'The live stream note is being sent',
  LIVE_STREAM_NOTE_SEND_SUCCESS = 'The live stream note  was sent successfully',
  LIVE_STREAM_NOTE_SEND_FAIL = 'The live stream note  sending failed',
}

export enum DemoBookingToastMessages {
  BOOKING_SUCCESS = 'Demo booking was successful',
  BOOKING_FAILURE = 'Failed to book a demo',
  BOOKING_IN_PROGRESS = 'Booking a demo in progress',
}
