import styles from './LiveStreamJoin.module.scss';

const Dots = () => (
  <div className={styles.bouncing}>
    <div className={styles.bouncing__loader}></div>
    <div className={styles.bouncing__loader}></div>
    <div className={styles.bouncing__loader}></div>
    <div className={styles.bouncing__loader}></div>
  </div>
);

export default Dots;
