import { FC } from 'react';
import { Box } from '@mui/material';
import { useFieldArray } from 'react-hook-form';
import { GridColDef } from '@mui/x-data-grid';
import { v4 } from 'uuid';

import CustomDataGrid from 'components/views/CustomDataGrid';
import {
  Button,
  CustomSkeleton,
  PortalDropDown,
  ScrollLayout,
  TableControlledRowItem,
} from 'components';
import { Colors, FontNames } from 'types';
import { FilledPlusIcon, MoreIcon, TrashIcon } from 'assets/icons';
import { useAppDispatch } from 'hooks';
import { removeCouponById } from 'store/slices/discountSlice/thunks';
import HeaderTitle from 'components/tables/EmployeeTable/HeaderTitle';
import { EmptyTitles } from 'constants/EmptyTitles';
import { HourOptions, TimeOptions } from 'constants/States';

import SectionHead from '../Drawers/CatalogCreateConfigure/SectionHead';

import { TtasksSectionProps } from './types';

const AddTask: FC<TtasksSectionProps> = ({ control, isLoading = false, getValues, employees }) => {
  const dispatch = useAppDispatch();

  const { append, remove } = useFieldArray({ control, name: 'tasks' });

  const handleAddTaskClick = () => {
    append({
      task: {
        id: v4(),
        end_date: new Date(),
        tech_name: '',
        start_time: '',
        start_date: new Date(),
        daily_hours: null,
        task_description: '',
        sequence: 1,
      },
    });
  };

  const handleRemoveDiscount = (idx: number, id: number) => {
    if (id) {
      dispatch(removeCouponById(id));
    }

    remove(idx);
  };

  const getIndexByRow = (arr: any[], id: string | number) => {
    return arr ? arr.findIndex((r) => r.task?.id === id) : -1;
  };

  const columns: GridColDef[] = [
    {
      field: 'Name',
      headerName: 'Tech Name',
      flex: 0.7,

      renderHeader: (params) => {
        const headerName = params?.colDef?.headerName || '';
        return (
          <HeaderTitle
            withSortIcons={false}
            title={headerName}
            style={{ padding: 0 }}
            fontFamily={FontNames.CIRCULAR_BOLD}
          />
        );
      },
      renderCell: (params) => {
        const index = getIndexByRow(getValues('tasks'), params.id);

        if (index === -1) {
          return null;
        }
        return (
          <TableControlledRowItem
            placeholder='Name'
            control={control}
            required
            inputOptions={employees}
            componentType='autocomplete'
            inputOptionsName='label'
            inputClassName='name'
            isExisted={false}
            name={`tasks.${[index]}.task.tech_name`}
            // error={!!errors?.tasks?.[index]?.task?.coupon_code}
          />
        );
      },
    },
    {
      field: 'Description',
      headerName: 'Description',
      flex: 1,

      renderHeader: (params) => {
        const headerName = params?.colDef?.headerName || '';
        return (
          <HeaderTitle
            withSortIcons={false}
            title={headerName}
            style={{ padding: 0 }}
            fontFamily={FontNames.CIRCULAR_BOLD}
          />
        );
      },
      renderCell: (params) => {
        const index = getIndexByRow(getValues('tasks'), params.id);

        if (index === -1) {
          return null;
        }

        return (
          <TableControlledRowItem
            placeholder='Description'
            componentType='text'
            control={control}
            isExisted={false}
            enableSpaceKeyDown={true}
            name={`tasks.${[index]}.task.task_description`}
            // error={!!errors?.tasks?.[index]?.task?.coupon_code}
          />
        );
      },
    },
    {
      field: 'sequence',
      headerName: 'Sequence',
      flex: 0.4,

      renderHeader: (params) => {
        const headerName = params?.colDef?.headerName || '';
        return (
          <HeaderTitle
            withSortIcons={false}
            title={headerName}
            style={{ padding: 0 }}
            fontFamily={FontNames.CIRCULAR_BOLD}
          />
        );
      },
      renderCell: (params) => {
        const index = getIndexByRow(getValues('tasks'), params.id);

        if (index === -1) {
          return null;
        }

        return (
          <TableControlledRowItem
            placeholder='Sequence'
            control={control}
            isExisted={false}
            name={`tasks.${[index]}.task.sequence`}
            // error={!!errors?.tasks?.[index]?.task?.coupon_code}
          />
        );
      },
    },
    {
      field: 'Start Time',
      headerName: 'Start Time',
      flex: 0.5,

      renderHeader: (params) => {
        const headerName = params?.colDef?.headerName || '';
        return <HeaderTitle withSortIcons={false} title={headerName} style={{ padding: 0 }} />;
      },
      renderCell: (params) => {
        const index = getIndexByRow(getValues('tasks'), params.id);

        if (index === -1) {
          return null;
        }

        return (
          <TableControlledRowItem
            control={control}
            isExisted={false}
            required
            placeholder='Start Time'
            componentType='select'
            options={TimeOptions}
            name={`tasks.${[index]}.task.start_time`}
            // error={!!errors?.tasks?.[index]?.task?.discount_percent}
          />
        );
      },
    },
    {
      field: 'start',
      headerName: 'Start Date',
      flex: 0.6,

      renderHeader: (params) => {
        const headerName = params?.colDef?.headerName || '';
        return <HeaderTitle withSortIcons={false} title={headerName} style={{ padding: 0 }} />;
      },
      renderCell: (params) => {
        const index = getIndexByRow(getValues('tasks'), params.id);

        if (index === -1) {
          return null;
        }

        return (
          <TableControlledRowItem
            control={control}
            componentType='picker'
            placeholder='Start Date'
            isExisted={false}
            name={`tasks.${[index]}.task.start_date`}
            // error={!!errors?.tasks?.[index]?.task?.start_date}
          />
        );
      },
    },
    {
      field: 'end',
      headerName: 'End Date',
      flex: 0.6,

      renderHeader: (params) => {
        const headerName = params?.colDef?.headerName || '';
        return <HeaderTitle withSortIcons={false} title={headerName} style={{ padding: 0 }} />;
      },
      renderCell: (params) => {
        const index = getIndexByRow(getValues('tasks'), params.id);

        if (index === -1) {
          return null;
        }

        return (
          <TableControlledRowItem
            control={control}
            value={null}
            isExisted={false}
            componentType='picker'
            placeholder='End Date'
            name={`tasks.${[index]}.task.end_date`}
            // error={!!errors?.tasks?.[index]?.task?.end_date}
          />
        );
      },
    },
    {
      field: 'daily_hours',
      headerName: 'Daily Hours',
      flex: 0.45,
      renderHeader: (params) => {
        const headerName = params?.colDef?.headerName || '';
        return <HeaderTitle withSortIcons={false} title={headerName} style={{ padding: 0 }} />;
      },
      renderCell: (params) => {
        const index = getIndexByRow(getValues('tasks'), params.id);

        if (index === -1) {
          return null;
        }

        return (
          <TableControlledRowItem
            control={control}
            componentType='select'
            placeholder='Daily Hours'
            isExisted={false}
            options={HourOptions}
            required
            // selectDefaultValue='daily_hours'
            name={`tasks.${[index]}.task.daily_hours`}
            // error={!!errors?.tasks?.[index]?.task?.coupon_status}
          />
        );
      },
    },
    {
      field: 'options',
      headerName: '',
      width: 80,
      renderCell: (params) => {
        const index = getIndexByRow(getValues('tasks'), params.id);

        if (index === -1) {
          return null;
        }

        return (
          <PortalDropDown
            title={<MoreIcon />}
            options={[
              {
                id: 1,
                name: 'Delete',
                action: () => handleRemoveDiscount(index, Number(params.id)),
                icon: <TrashIcon />,
              },
            ]}
          />
        );
      },
    },
  ];

  const renderColumns: GridColDef[] = columns.map((column) => ({
    ...column,
    sortable: false,
    hideSortIcons: true,
    disableColumnMenu: true,
    disableColumnSelector: true,
  }));

  const cloned = getValues('tasks')?.map(({ task }) => ({
    id: task?.id,
    tech_name: task?.tech_name,
    task_description: task?.task_description,
    start_time: task?.start_time,
    start: task?.start_date,
    end: task?.end_date,
    daily_hours: task?.daily_hours,
    sequence: task?.sequence,
  }));

  return (
    <Box>
      <SectionHead title='tasks' subtitle={`(Assign tasks to employees.)`} />
      <Box>
        {isLoading ? (
          <CustomSkeleton width='100%' height={80} />
        ) : (
          <ScrollLayout>
            <CustomDataGrid
              rowHeight={70}
              columns={renderColumns}
              rows={cloned || []}
              getRowId={(row) => row?.id}
              emptyTitle={EmptyTitles.WorkOrder_Task}
            />
          </ScrollLayout>
        )}
      </Box>
      <Box>
        <Button
          color={Colors.BLACK}
          isUppercase={false}
          startIcon={<FilledPlusIcon />}
          fontFamily={FontNames.CIRCULAR_REG}
          onClick={handleAddTaskClick}
        >
          Add New Task
        </Button>
      </Box>
    </Box>
  );
};

export default AddTask;
