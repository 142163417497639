import { FC } from 'react';
import { Box } from '@mui/material';
import { useLocation } from 'react-router-dom';

import Header from 'components/views/Header';
import { Navbar } from 'components/shared';
import { Routes } from 'types';
import { TabNavigator } from 'components/views';

import { TLargeLayoutProps } from './types';
import styles from './LargeLayout.module.scss';

const LargeLayout: FC<TLargeLayoutProps> = ({
  children,
  tabNavigatorTitle,
  withTabNavigator,
  tabNavigatorOptions,
}) => {
  const { pathname } = useLocation();

  const renderedCondition =
    pathname === Routes.Home ||
    pathname === Routes.ProcessForm ||
    pathname === Routes.OrganizationsList ||
    pathname === Routes.SignIn ||
    pathname === Routes.SignUp ||
    children.type.name === 'Error';

  return (
    <>
      {renderedCondition ? (
        children
      ) : (
        <Box className={styles.container}>
          <div className={styles.container__divider} />

          <Box className={styles.container__main}>
            <Header
              withLogo
              onOpenNavbar={function (): void {
                throw new Error('Function not implemented.');
              }}
            />
            <Box className={styles.container__content}>
              <Navbar forLargeScreens />
              <Box className={styles.container__content__child}>
                {withTabNavigator && tabNavigatorOptions && tabNavigatorTitle && (
                  <TabNavigator
                    options={tabNavigatorOptions}
                    tabNavigatorTitle={tabNavigatorTitle}
                  />
                )}
                <>{children}</>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default LargeLayout;
