import { FC, useEffect } from 'react';
import { Box, Drawer, Stack } from '@mui/material';
import classNames from 'classnames';

import { BorderSquareIcon } from 'assets/icons';
import { Button, CustomTypography } from 'components';
import { Colors } from 'types';
import { useAppDispatch } from 'hooks';
import { setDrawerState } from 'store/slices/optionsSlice';

import styles from './Drawer.module.scss';

import type { TDrawerProps } from './types';

const CustomDrawer: FC<TDrawerProps> = ({
  open,
  title,
  anchor,
  onClose,
  children,
  closeIcon,
  onClearClose,
  handleSubmit,
  submitBtnText,
  footer = true,
  update = false,
  containerClasses,
  submitBtnDisabled,
  backDrop = false,
}) => {
  const dispatch = useAppDispatch();

  const backDropStyles = {
    '& .css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop': {
      backgroundColor: 'transparent !important',
    },
  };

  const containerHeadClasses = classNames(styles.container, containerClasses);

  const buttonTitle = update ? 'UPDATE' : 'SAVE';

  useEffect(() => {
    dispatch(setDrawerState(open));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Drawer anchor={anchor} open={open} onClose={onClose} sx={backDrop ? backDropStyles : null}>
      <div className={containerHeadClasses}>
        <Stack direction='row-reverse' alignItems='center'>
          <>
            {closeIcon && (
              <BorderSquareIcon
                color='inherit'
                aria-label='close'
                onClick={onClearClose}
                className={styles.container__close}
              >
                <BorderSquareIcon width={24} height={24} />
              </BorderSquareIcon>
            )}
          </>
          {title && (
            <CustomTypography className={styles.container__title}>{title}</CustomTypography>
          )}
        </Stack>

        <div role='presentation'>{children}</div>

        {footer && (
          <div className={styles.container__button}>
            {submitBtnText ? (
              <Button
                maxWidth='100%'
                padding='20px 16px'
                color={Colors.WHITE}
                borderRadius='10px'
                onClick={handleSubmit}
                disabled={submitBtnDisabled}
                backgroundColor={Colors.SAPPHIRE}
              >
                {submitBtnText}
              </Button>
            ) : (
              <Box className={styles.container__button__options}>
                <Button
                  variant='text'
                  color={Colors.SAPPHIRE}
                  onClick={onClose}
                  className={styles.container__button__options__cancel}
                >
                  CANCEL
                </Button>
                <Button
                  onClick={handleSubmit}
                  backgroundColor={Colors.PALE_BLUE}
                  disabled={submitBtnDisabled}
                  className={styles.container__button__options__submit}
                >
                  {buttonTitle}
                </Button>
              </Box>
            )}
          </div>
        )}
      </div>
    </Drawer>
  );
};

export default CustomDrawer;
