import { FC, useState } from 'react';
import { Box, Stack } from '@mui/material';
import { useFieldArray } from 'react-hook-form';
import classNames from 'classnames';

import { useAppSelector, useWindowSize } from 'hooks';
import { Colors, FontNames } from 'types';
import { FilledPlusIcon } from 'assets/icons';
import CustomTabs from 'components/views/CustomTabs';
import { Button, CustomSkeleton } from 'components';
import { allPartsSelector } from 'store/slices/inventorySlice/selectors';

import { breakPoints } from '../../../../../../constants';

import { TCatalogVersionsProps } from './types';
import styles from './CatalogVersions.module.scss';
import CatalogTabPanels from './CatalogTabPanels';
import { addNewSectionOptions } from './CatalogVersions.utils';

const CatalogVersions: FC<TCatalogVersionsProps> = ({ control, errors, isLoading, getValues }) => {
  const [activeIndex, setActiveIndex] = useState<number>(0);

  const partItemsData = useAppSelector(allPartsSelector);

  const isDisabled = partItemsData.total_count === 0;

  const { width } = useWindowSize();

  const getActiveIndex = (index: number) => setActiveIndex(index);

  const newSectionItems = addNewSectionOptions(activeIndex);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'sections',
  });

  const tabItems = fields?.map((item, idx) => (
    <CatalogTabPanels
      idx={idx}
      key={item?.id}
      remove={remove}
      errors={errors}
      control={control}
      getValues={getValues}
      label={`Section ${idx + 1}`}
      name={`sections.${idx}.name`}
      getActiveIndex={getActiveIndex}
    />
  ));

  const containerClasses = classNames(styles.container, {
    [styles.container_disable]: isDisabled,
  });

  return (
    <Box className={containerClasses}>
      <Box className={styles.container__head}>
        {isLoading ? (
          <Stack gap='10px'>
            <CustomSkeleton height={36} width='100%' />
            <CustomSkeleton height={50} width='100%' />
          </Stack>
        ) : (
          <CustomTabs
            isEditable
            isDisabled={isDisabled}
            addNewTabElement={
              <Button
                color={Colors.BLACK}
                startIcon={<FilledPlusIcon />}
                fontFamily={FontNames.CIRCULAR_REG}
                className={styles.container__head__add}
                disabled={partItemsData.total_count === 0}
                onClick={() =>
                  append({ ...newSectionItems, name: `Section ${fields?.length + 1}` })
                }
              >
                {Number(width) > breakPoints.TABLET_L ? 'ADD NEW SECTION' : ''}
              </Button>
            }
          >
            {tabItems}
          </CustomTabs>
        )}
      </Box>
      {isDisabled && (
        <p className={styles.container__message}>
          You need to create inventories for the catalog creation process.
        </p>
      )}
    </Box>
  );
};

export default CatalogVersions;
