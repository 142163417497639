import { createSlice } from '@reduxjs/toolkit';

import { getAllSchedules } from './thunks';

import type { TInitialState } from './types';

const initialState: TInitialState = {
  allSchedulesLimit: 100,
  allSchedulesOffset: 0,
  allSchedulesLoading: false,
  allSchedulesError: null,
  allSchedules: [],
};

const scheduleSlice = createSlice({
  name: 'scheduleSlice',
  reducers: {
    setPage(state, action) {
      state.allSchedulesOffset = (action.payload - 1) * state.allSchedulesLimit;
    },
    setLimit(state, action) {
      state.allSchedulesLimit = action.payload;
    },
  },
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getAllSchedules.pending, (state) => {
        state.allSchedulesLoading = true;
        state.allSchedulesError = null;
      })
      .addCase(getAllSchedules.fulfilled, (state, action) => {
        state.allSchedulesLoading = false;
        state.allSchedules = action.payload;
        state.allSchedulesError = null;
      })
      .addCase(getAllSchedules.rejected, (state, action) => {
        state.allSchedulesLoading = false;
        state.allSchedulesError = action.error.message as string;
      });
  },
});

export default scheduleSlice.reducer;

export const { setPage, setLimit } = scheduleSlice.actions;
