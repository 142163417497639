import { FC, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';

import { CameraIcon } from 'assets/icons';
import { useAppDispatch, useAppSelector, useInput, useWindowSize } from 'hooks';
import {
  Button,
  DrawerLayout,
  ControlledInput,
  ControlledSelect,
  CompanyFileInput,
  ControlledAutocomplete,
} from 'components';
import {
  createPart,
  getPartById,
  getVendorList,
  createPartSnapShot,
  findVendor,
} from 'store/slices/inventorySlice/thunks';
import { createPartSchema } from 'constants/Schemas';
import { checkDataChanges, createFormData } from 'utils';
import { TPart } from 'store/slices/inventorySlice/types';
import {
  currentPartByIdSelector,
  foundedVendorsSelector,
  vendorListSelector,
} from 'store/slices/inventorySlice/selectors';
import inventoryFormValuesGenerator from 'utils/formsInitialValues/inventoryForm';
import { Colors } from 'types';

import { breakPoints } from '../../../../constants';

import styles from './AddNewInventoryDrawer.module.scss';
import { inputOptions } from './utils';

import type { TOption } from 'components/shared/Select/types';
import type { TAddNewInventoryDrawerProps, TInventoryForm } from './types';

const AddNewInventoryDrawer: FC<TAddNewInventoryDrawerProps> = ({
  open,
  file,
  getFile,
  getImgSrc,
  handleClose,
  currentImageSrc,
  inEditMode = false,
}) => {
  const dispatch = useAppDispatch();
  const { width } = useWindowSize();

  const itemData = useAppSelector(currentPartByIdSelector);
  const allVendors = useAppSelector(vendorListSelector);
  const foundedVendors = useAppSelector(foundedVendorsSelector);

  const [isImageRemoved, setIsImageRemoved] = useState<boolean>(false);

  const getImageRemovedState = (isRemoved: boolean) => setIsImageRemoved(isRemoved);

  const { debouncedValue, handleChange, value } = useInput();

  useEffect(() => {
    if (debouncedValue?.trim()?.length) {
      dispatch(findVendor({ query: debouncedValue }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  useEffect(() => {
    dispatch(getVendorList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const drawerWidth =
    Number(width) <= breakPoints.TABLET_L && Number(width) > breakPoints?.TABLET_M
      ? 600
      : Number(width) <= breakPoints?.TABLET_M
      ? '100%'
      : 700;

  const title = inEditMode ? 'Edit an Inventory Item' : 'Add New Inventory Item';

  const defaultValues = inventoryFormValuesGenerator(inEditMode, itemData as TPart);

  const {
    handleSubmit,
    control,
    reset,

    formState: { errors, isSubmitting, isDirty },
  } = useForm({
    resolver: yupResolver(createPartSchema),
    values: defaultValues,
    mode: 'all',
  });

  const vendorOptions = foundedVendors?.data?.map((item) => ({
    id: item?.id,
    name: item?.name,
  }));

  const somethingHasChanged = isDirty || !!file || isImageRemoved;

  const renderedInputs = inputOptions.map((option) => {
    const { type, ...rest } = option;

    if (type === 'select') {
      return (
        <Box key={rest?.name} className={styles.container__grid__item}>
          <ControlledSelect
            name={rest?.name}
            defaultValue=''
            control={control}
            label={rest?.label}
            padding={rest?.padding}
            border={rest?.border}
            labelColor={rest?.labelColor}
            borderRadius={rest?.borderRadius}
            options={rest?.options as TOption[]}
            backgroundColor={rest?.backgroundColor}
            errors={!!errors[rest?.name as keyof TInventoryForm]}
            helperText={errors[rest?.name as keyof TInventoryForm]?.message}
          />
        </Box>
      );
    } else {
      if (rest?.name === 'preferred_vendor') {
        return (
          <Box key={rest?.name} className={styles.container__grid__item}>
            <ControlledAutocomplete
              key={rest?.name}
              isSingleControlled
              singleKeyName='name'
              inputValue={value}
              name={rest.name}
              rows={rest?.rows}
              optionsName='name'
              control={control}
              label={rest.label}
              padding={rest.padding}
              required={rest.required}
              options={vendorOptions}
              multiline={rest?.multiline}
              labelColor={rest?.labelColor}
              handleInputChange={handleChange}
              borderColor={rest.borderColor}
              borderRadius={rest.borderRadius}
              backgroundColor={rest?.backgroundColor}
              readonly={allVendors?.total_count === 0}
              disabled={allVendors?.total_count === 0}
              placeholder={rest?.placeholder as string}
              errors={!!errors[rest?.name as keyof TInventoryForm]}
              helperText={errors[rest?.name as keyof TInventoryForm]?.message}
            />
          </Box>
        );
      } else {
        return (
          <Box key={rest?.name} className={styles.container__grid__item}>
            <ControlledInput
              key={rest?.name}
              name={rest.name}
              rows={rest?.rows}
              control={control}
              label={rest.label}
              padding={rest.padding}
              disabled={rest?.disable}
              required={rest.required}
              multiline={rest?.multiline}
              fontFamily={rest?.fontFamily}
              borderColor={rest.borderColor}
              labelColor={rest?.labelColor}
              borderRadius={rest.borderRadius}
              rulesMessage={rest?.rulesMessage}
              backgroundColor={rest?.backgroundColor}
              placeholder={rest?.placeholder as string}
              error={!!errors[rest?.name as keyof TInventoryForm]}
              helperText={errors[rest?.name as keyof TInventoryForm]?.message}
            />
          </Box>
        );
      }
    }
  });

  const handleCreateData: SubmitHandler<FieldValues> = async (data) => {
    const { mixedUpFormData, changed } = checkDataChanges(data, defaultValues);
    const newCreatedData = createFormData(mixedUpFormData);

    if (changed?.preferred_vendor) {
      newCreatedData.append('preferred_vendor', data?.preferred_vendor?.name);
    }

    if (file) {
      newCreatedData.append('image', file);
    }

    if (data) {
      if (inEditMode) {
        newCreatedData.append('part_id', String(itemData?.id));
      }
    }

    if (!inEditMode) {
      const response = await dispatch(createPart(newCreatedData));

      if (response?.meta?.requestStatus === 'fulfilled') {
        handleClose();
        reset();
      }
    } else {
      if (isImageRemoved) {
        newCreatedData.append('remove_image', 'true');
      } else {
        newCreatedData.append('remove_image', 'false');
      }

      const response = await dispatch(createPartSnapShot(newCreatedData));

      if (response?.meta?.requestStatus === 'fulfilled') {
        handleClose();
        reset();
        dispatch(getPartById(itemData?.id as number));
        getFile?.(null);
      }
    }

    getFile?.(null);
  };

  const onDrawerClose = () => {
    handleClose();
    setIsImageRemoved(false);
  };

  const onDrawerCloseClear = () => {
    handleClose();
    reset();
    getImgSrc?.(null);
    getFile?.(null);
    setIsImageRemoved(false);
  };

  const buttonTitle = inEditMode ? 'Save' : 'Add';

  return (
    <DrawerLayout
      open={open}
      padding='40px'
      inCenter={false}
      headerTitle={title}
      width={drawerWidth}
      onClose={onDrawerClose}
      onCloseReset={onDrawerCloseClear}
      titleClassName={styles.container__title}
    >
      <form className={styles.container} onSubmit={handleSubmit(handleCreateData)}>
        <Box className={styles.container__head}>
          <CompanyFileInput
            reversed
            title='Image'
            maxWidth='100%'
            getFile={getFile}
            blockMaxWidth='200px'
            borderColor={Colors.SOFT_SILVER}
            icon={<CameraIcon />}
            getImgSrc={getImgSrc}
            backgroundColor={Colors.LIGHT_SILVER}
            imgSrc={inEditMode ? currentImageSrc : null}
            getSetIsRemovedState={getImageRemovedState}
            containerClassName={styles.container__head__box}
            containerBoxClassName={styles.container__head__box__content}
          />
        </Box>
        <Box className={styles.container__grid}>{renderedInputs}</Box>

        <Box className={styles.container__submit}>
          <Button
            type='button'
            maxWidth='68px'
            color={Colors.SAPPHIRE}
            minWidth='68px'
            borderRadius='5px'
            padding='12px 16px'
            isUppercase={false}
            backgroundColor={Colors.PALE_BLUE}
            onClick={onDrawerClose}
          >
            Cancel
          </Button>
          <Button
            color='white'
            type='submit'
            maxWidth='61px'
            minWidth='61px'
            borderRadius='5px'
            padding='12px 16px'
            isUppercase={false}
            backgroundColor={Colors.SAPPHIRE}
            disabled={!somethingHasChanged || isSubmitting}
          >
            {buttonTitle}
          </Button>
        </Box>
      </form>
    </DrawerLayout>
  );
};

export default AddNewInventoryDrawer;
