import styles from './FromTopToBottom.module.scss';

const FromLeftLine = () => (
  <svg
    width='880'
    height='301'
    viewBox='0 0 880 301'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M8 0.55957L8.00001 140.476C8.00001 144.894 11.5817 148.476 16 148.476L864 148.476C868.418 148.476 872 152.057 872 156.476L872 292.56'
      stroke='#D2E1F9'
      strokeWidth='8'
      className={styles.path}
    >
      <animate
        attributeName='stroke-dasharray'
        values='0 1700;1700 0'
        dur='4s'
        repeatCount='indefinite'
      />
    </path>
    <path
      className={styles.circle_web}
      d='M880 292.56C880 288.141 876.418 284.56 872 284.56C867.582 284.56 864 288.141 864 292.56C864 296.978 867.582 300.56 872 300.56C876.418 300.56 880 296.978 880 292.56Z'
      fill='#D2E1F9'
    />
  </svg>
);

export default FromLeftLine;
