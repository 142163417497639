import { client, endpoints } from 'api';
import { TNewUserRole, TRemoveMember } from 'store/slices/authSlice/types';
import {
  TTag,
  TTeamTag,
  TUpdateTeam,
  TCreateTeam,
  TGetUserTeamsParams,
} from 'store/slices/teamsSlice/types';

export const updateTeamRequest = (params: TUpdateTeam) =>
  client.put(endpoints.TeamsService.updateTeams(), { ...params });

export const createTeamRequest = (team: TCreateTeam) =>
  client.post(endpoints.TeamsService.createTeams(), { team });

export const getUserTeamsRequest = (options: TGetUserTeamsParams) =>
  client.get(endpoints.TeamsService.getUserTeams(), {
    params: { ...options },
  });

export const getTeamsByIdRequest = (team_id: number) =>
  client.get(endpoints.TeamsService.getTeamsById(team_id));

export const deleteTeamRequest = (team_id: number) =>
  client.delete(endpoints.TeamsService.deleteTeam(team_id));

export const addMemberRequest = (options: TNewUserRole) =>
  client.post(endpoints.TeamsService.addMember(), {
    new_user_role: options,
  });

export const removeMemberRequest = (options: TRemoveMember) =>
  client.post(endpoints.TeamsService.removeMember(), options);

export const postListMembersRequest = (team_id: number) =>
  client.post(endpoints.TeamsService.postListMembers(team_id));

export const getTagsRequest = () =>
  client.get(endpoints.TeamsService.getTags(), {
    params: {
      limit: 100,
      offset: 0,
    },
  });

export const updateTagsRequest = (tag: TTag) =>
  client.put(endpoints.TeamsService.updateTags(), { tag });

export const createTagsRequest = (tag: TTag) =>
  client.post(endpoints.TeamsService.createTags(), { tags: [tag] });

export const deleteTagRequest = (id: number) => client.delete(endpoints.TeamsService.deleteTag(id));

export const addTeamTagsRequest = (tag: TTeamTag) =>
  client.post(endpoints.TeamsService.creteTeamTags(), { tag: tag });

export const deleteTeamTagRequest = (params: TTeamTag) =>
  client.delete(endpoints.TeamsService.deleteTeamTags(), { params: { ...params } });
