import { getValue } from '../getValue';

import { TVendorCreateFormValuesGeneratorParams } from './types';

const vendorCreateFormValuesGenerator = ({
  inEditMode,
  currentVendor,
}: TVendorCreateFormValuesGeneratorParams) => ({
  zip: getValue(inEditMode, currentVendor?.zip),
  name: getValue(inEditMode, currentVendor?.name),
  city: getValue(inEditMode, currentVendor?.city),
  state: getValue(inEditMode, currentVendor?.state),
  email: getValue(inEditMode, currentVendor?.email),
  phone: getValue(inEditMode, currentVendor?.phone),
  address: getValue(inEditMode, currentVendor?.address),
  vendor_link: getValue(inEditMode, currentVendor?.vendor_link),
  contact_person: getValue(inEditMode, currentVendor?.contact_person),
});

export default vendorCreateFormValuesGenerator;
