import { AxiosError } from 'axios';
import { saveAs } from 'file-saver';
import { toast } from 'react-toastify';
import { useState } from 'react';

import { client } from 'api';
import { PdfDownloadToastMessages, PdfViewToastMessages } from 'constants/ToastMessages';

import type { TUsePdfDownloadParams } from './types';

const usePdfDownload = ({ fileUrl, folder, withToastMessages = false }: TUsePdfDownloadParams) => {
  const [isStarted, setIsStarted] = useState<boolean>(false);

  const getThePdfFile = async (fileFromParam?: string, toastMessages = true) => {
    let start;
    const url = fileUrl || fileFromParam;

    if (withToastMessages && toastMessages) {
      start = toast.loading(PdfDownloadToastMessages.PDF_DOWNLOAD_IN_PROGRESS);
    }
    try {
      setIsStarted(true);
      const response = await client.get(`/media-api/sh/media/${folder}/${url}`, {
        responseType: 'arraybuffer',
      });

      if (start && withToastMessages && toastMessages) {
        toast.update(start, {
          render: PdfDownloadToastMessages.PDF_DOWNLOAD_SUCCESS,
          type: 'success',
          isLoading: false,
          autoClose: 3000,
        });
      }
      setIsStarted(false);

      return response.data;
    } catch (error) {
      if (start && withToastMessages && toastMessages) {
        toast.update(start, {
          render: PdfDownloadToastMessages.PDF_DOWNLOAD_FAILURE,
          type: 'error',
          isLoading: false,
          autoClose: 3000,
        });
      }
      setIsStarted(false);

      const Error = error as AxiosError;
      throw Error;
    } finally {
      setIsStarted(false);
    }
  };

  const openPdfInNewTab = async (testUrl?: string, toastMessages = false) => {
    const start = toast.loading(PdfViewToastMessages.PDF_VIEW_IN_PROGRESS);
    try {
      const pdfData = await getThePdfFile(testUrl, toastMessages);
      const blob = new Blob([pdfData], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);
      window.open(url, '_blank');

      toast.update(start, {
        render: PdfViewToastMessages.PDF_VIEW_SUCCESS,
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });
    } catch (error) {
      toast.update(start, {
        render: PdfViewToastMessages.PDF_VIEW_FAILURE,
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });

      const Error = error as AxiosError;
      throw Error;
    }
  };

  const downloadPdf = async (pdfData: any, fileName: string) => {
    const blob = new Blob([pdfData], { type: 'application/pdf' });
    saveAs(blob, fileName?.endsWith('.pdf') ? fileName : `${fileName}.pdf` || `file.pdf`);
  };

  const fetchDataAndDownload = async (url?: string) => {
    try {
      const pdfData = await getThePdfFile(url);
      downloadPdf(pdfData, fileUrl);
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  };

  return { fetchDataAndDownload, isStarted, openPdfInNewTab };
};

export default usePdfDownload;
