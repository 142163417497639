import { Box, SelectChangeEvent } from '@mui/material';
import { FC, useEffect, useState } from 'react';

import { CustomSkeleton, Select, TextFrame } from 'components';
import SectionHead from 'components/views/Drawers/CatalogCreateConfigure/SectionHead';
import { useAppSelector } from 'hooks';
import { catalogsSliceSelector } from 'store/slices/catalogSlice/selectors';
import { Colors } from 'types';

import styles from './PaymentView.module.scss';
import { durationOptions } from './PaymentView.utils';
import PaymentViewTable from './PaymentViewTable';
import { PaymentDurations, TPaymentViewProps } from './types';

const PaymentView: FC<TPaymentViewProps> = ({ isLoading }) => {
  const { currentSectionVersions } = useAppSelector(catalogsSliceSelector);

  const duration = currentSectionVersions?.payment_schedule?.estimated_duration;

  const [calculatedValue, setCalculatedValue] = useState<number>(Number(duration) || 1);

  useEffect(() => {
    setCalculatedValue(Number(duration));
  }, [duration]);

  const [selectedValue, setSelectedValue] = useState<PaymentDurations>(PaymentDurations.DAYS);

  const handleChange = (event: SelectChangeEvent) => {
    const selectedDuration = event.target.value as PaymentDurations;
    setSelectedValue(selectedDuration);

    if (currentSectionVersions?.payment_schedule?.estimated_duration) {
      let newValue = currentSectionVersions.payment_schedule.estimated_duration;

      if (selectedDuration === PaymentDurations.MONTHS) {
        newValue = newValue / 30;
      } else if (selectedDuration === PaymentDurations.WEEKS) {
        newValue = newValue / 7;
      }

      setCalculatedValue(Math.floor(newValue));
    }
  };

  const initialSkeletons = Array.from({ length: 2 })?.map((_, idx) => (
    <CustomSkeleton key={idx} height={50} borderRadius='4px' width='100%' />
  ));

  return (
    <Box className={styles.container}>
      <SectionHead
        withUnderlinedTitle
        title='Payment Schedule:'
        subtitle={`(This payment schedule is used by linked estimates to determine the invoice schedule.)`}
      />
      <Box className={styles.container__content}>
        {isLoading ? (
          initialSkeletons
        ) : (
          <>
            <TextFrame
              width='100%'
              borderRadius='5px'
              padding='19.5px 16px'
              border='1px solid #EDEFF1'
              backgroundColor={Colors.LIGHT_SILVER}
              content={`Estimate Duration: ${calculatedValue}`}
            />
            <div style={{ width: '100%' }}>
              <Select
                label='Duration'
                multiple={false}
                borderRadius='5px'
                value={selectedValue}
                onChange={handleChange}
                border='1px solid #EDEFF1'
                options={durationOptions}
                backgroundColor={Colors.LIGHT_SILVER}
              />
            </div>
          </>
        )}
      </Box>
      {isLoading ? (
        <CustomSkeleton height={100} borderRadius='4px' width='100%' />
      ) : (
        <PaymentViewTable />
      )}
    </Box>
  );
};

export default PaymentView;
