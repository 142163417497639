import {
  FC,
  useRef,
  useState,
  useEffect,
  ChangeEvent,
  useCallback,
  BaseSyntheticEvent,
} from 'react';
import { Box, IconButton, Menu, MenuItem } from '@mui/material';
import { GridColDef, GridEventListener, GridRowParams } from '@mui/x-data-grid';
import { Navigate } from 'react-router-dom';
import classNames from 'classnames';

import { useAppSelector, useAppDispatch, useOnClickOutside, useRefreshClearState } from 'hooks';
import {
  invoicesSelector,
  invoicesLimitSelector,
  invoicesOffsetSelector,
  invoiceAscSelector,
} from 'store/slices/invoicesSlice/selectors';
import { setLimit, setPage } from 'store/slices/invoicesSlice';
import { convertToMMDDYYYY } from 'utils/formatDate';
import { SortByEnums, TInvoice } from 'store/slices/invoicesSlice/types';
import { generateInventoryItemNumber } from 'utils/generateInventoryItemNumber';
import {
  deleteInvoice,
  getAllInvoices,
  publishInvoiceItem,
} from 'store/slices/invoicesSlice/thunks';
import { DownloadSquareIcon, MoreIcon, TrashIcon } from 'assets/icons';
import {
  StatusInfo,
  DeletePopup,
  ScrollLayout,
  ShareDropDown,
  CustomDataGrid,
  InvoiceViewDrawer,
  ShowAfterAccessView,
  DynamicPaginationControl,
} from 'components';
import { ImgUrlFolders, Routes } from 'types';
import usePdfDownload from 'hooks/usePDFDownload';
import { StatusEnums } from 'components/shared/StatusInfo/types';
import {
  userAllRolesLoadingSelector,
  userPermissionsSelector,
} from 'store/slices/authSlice/selectors';

import InvoiceHeader from './InvoiceHeader';
import { invoicesColumns } from './utils';
import { sxMenuShareStyles, sxMenuStyles } from './styles';
import styles from './Invoice.module.scss';

const Invoice: FC = () => {
  const dispatch = useAppDispatch();

  const menuRef = useRef<HTMLDivElement | null>(null);

  const invoices = useAppSelector(invoicesSelector);
  const invoiceAsc = useAppSelector(invoiceAscSelector);
  const invoicesLimit = useAppSelector(invoicesLimitSelector);
  const invoicesOffset = useAppSelector(invoicesOffsetSelector);
  const userRolesLoad = useAppSelector(userAllRolesLoadingSelector);
  const { isAccessToInvoices } = useAppSelector(userPermissionsSelector);

  useRefreshClearState();

  const renderedInvoice = invoices;

  const [anchorEl, setAnchorEl] = useState(null);
  //Todo
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isEditDetails, setIsEditDetails] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isCreateDrawer, setIsCreateDrawer] = useState<boolean>(false);

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [isShareOpen, setIsShareOpen] = useState<boolean>(false);
  const [selectedItemId, setSelectedItemId] = useState<number | null>(null);
  const [selectedItem, setSelectedItem] = useState<TInvoice | null>(null);

  const [isViewDetails, setIsViewDetails] = useState<boolean>(false);
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const [newSortBy, setNewSortBy] = useState<SortByEnums | null>(null);

  const handleColumnHeaderClick = (column: GridColDef) => {
    const sortValue = column.field === 'number' ? SortByEnums.ID : (column.field as SortByEnums);

    setNewSortBy(sortValue);

    dispatch(
      getAllInvoices({
        asc: invoiceAsc,
        sort_by: sortValue,
        limit: invoicesLimit,
        offset: invoicesOffset,
      }),
    );
  };

  const openDrawer = () => setIsCreateDrawer(true);

  const closeViewDetails = () => setIsViewDetails(false);
  const togglePopup = () => setIsPopupOpen(!isPopupOpen);

  const handleClose = () => {
    if (!isShareOpen) {
      setAnchorEl(null);
    }

    setIsShareOpen(false);
  };

  useOnClickOutside(menuRef, handleClose);

  useEffect(() => {
    dispatch(getAllInvoices({ limit: invoicesLimit, offset: invoicesOffset }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoicesOffset, invoicesLimit]);

  const handlePageChange = useCallback((_event: unknown, newPage: number) => {
    setCurrentPage(newPage);
    dispatch(setPage(newPage + 1));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderColumns: GridColDef[] = invoicesColumns.map((column) => {
    const isColumnSorted = newSortBy === column.field;

    const headerClasses = classNames(
      { [styles.activeSortHeader]: !invoiceAsc },
      {
        [styles.activeSortHeader_asc]: invoiceAsc,
      },
    );

    return {
      ...column,
      sortable: false,
      hideSortIcons: true,
      disableColumnMenu: true,
      disableColumnSelector: true,
      headerClassName: isColumnSorted ? headerClasses : '',
    };
  });

  const handleDeleteInvoice = async () => {
    await dispatch(deleteInvoice(selectedItemId as number));
    togglePopup();
    setAnchorEl(null);
  };

  const handleMoreClick = (event: BaseSyntheticEvent, id: number, el: TInvoice) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
    setSelectedItem(el);
    setSelectedItemId(id);
  };

  const publishInvoice = () => dispatch(publishInvoiceItem(selectedItemId as number));

  const { fetchDataAndDownload } = usePdfDownload({
    fileUrl: selectedItem?.media_url_id as string,
    folder: ImgUrlFolders.DOCUMENT,
  });

  const dropdownItems = [
    {
      icon: <TrashIcon />,
      label: 'Delete',
      paramsAction: togglePopup,
    },
    {
      icon: <DownloadSquareIcon style={{ transform: 'rotate(90deg)' }} />,
      label: 'Email Invoice To Customer',
      paramsAction: publishInvoice,
    },
    {
      icon: <DownloadSquareIcon />,
      label: 'Download PDF',
      paramsAction: fetchDataAndDownload,
    },
  ];
  const renderMenuItem = dropdownItems.map((item, idx) => {
    const onClick = () => {
      selectedItemId && item.paramsAction();
      handleClose();
    };

    return (
      <MenuItem disabled={!isAccessToInvoices} key={idx} onClick={onClick}>
        {item.icon}
        {item.label}
      </MenuItem>
    );
  });

  const handleEvent: GridEventListener<'rowClick'> = (params: GridRowParams, event) => {
    event.preventDefault();
    setSelectedItemId(params.id as number);
    setIsViewDetails(true);
  };

  const handleRowsPerPageChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    handlePageChange(null, 0);
    dispatch(setLimit(event.target.value));
  };

  const renderRows = renderedInvoice?.data?.map((el: TInvoice) => {
    const renderNumber = generateInventoryItemNumber(el.id);
    const formatDueDate = el.due_date ? convertToMMDDYYYY(el.due_date) : null;
    const formatIssueDate = el.issue_date ? convertToMMDDYYYY(el.issue_date) : null;

    return {
      id: el.id,
      number: `#${renderNumber}` || '--',
      customer_name: el.customer_name || '--',
      issue_date: formatIssueDate || '--',
      estimate_name: el.estimate_name || '--',
      due_date: formatDueDate || '--',
      total_amount: `$${el.total_amount}.00` || '--',
      status: <StatusInfo status={el.status as StatusEnums} title={el.status} />,
      options: (
        <IconButton
          sx={{ padding: '3px', margin: '0 auto' }}
          onClick={(event) => handleMoreClick(event, el.id, el)}
        >
          <MoreIcon className={styles.container__icon} />
        </IconButton>
      ),
    };
  });

  const totalRows = invoices.total_count;

  const renderMenuClasses = isShareOpen ? sxMenuShareStyles : sxMenuStyles;

  useEffect(() => {
    if (!totalRows && currentPage) {
      setCurrentPage(currentPage - 1);
      handlePageChange(null, currentPage - 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalRows]);

  if (!isAccessToInvoices && !userRolesLoad) {
    return <Navigate replace to={Routes.ActivityFeed} />;
  }

  return (
    <>
      <Box className={styles.container}>
        <InvoiceHeader createNewDrawer={openDrawer} />
        <ShowAfterAccessView emptyMessageTitle='Invoices' isShow={isAccessToInvoices}>
          <>
            <Box className={styles.container__content}>
              <ScrollLayout>
                <CustomDataGrid
                  rowHeight={64}
                  headerHeight={64}
                  columns={renderColumns}
                  rows={renderRows}
                  emptyTitle='No Invoices added yet'
                  onRowClick={handleEvent}
                  onColumnHeaderClick={handleColumnHeaderClick}
                />
              </ScrollLayout>
            </Box>
            <Menu
              ref={menuRef}
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              sx={renderMenuClasses}
            >
              {isShareOpen ? <ShareDropDown setIsOpen={setIsShareOpen} /> : renderMenuItem}
            </Menu>
          </>
          {!!totalRows && (
            <Box className={styles.container__footer}>
              <DynamicPaginationControl
                count={totalRows}
                page={currentPage}
                rowsPerPage={invoicesLimit}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
              />
            </Box>
          )}
        </ShowAfterAccessView>
      </Box>
      <InvoiceViewDrawer open={isViewDetails} handleClose={closeViewDetails} id={selectedItemId} />

      <DeletePopup
        withBody
        isOpen={isPopupOpen}
        onClose={togglePopup}
        title='Delete Confirmation'
        onDelete={handleDeleteInvoice}
        body='This will delete the invoice. Please confirm.'
      />
    </>
  );
};

export default Invoice;
