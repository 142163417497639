import { FC } from 'react';
import { Box } from '@mui/material';

import ControlledInput from 'components/views/ControlledInput';
import NumberControlledInput from 'components/views/NumberControlledInput';
import { Colors, FontNames } from 'types';

import styles from './ContactInfoList.module.scss';

import type { TContactInfoListProps } from './types';

const ContactInfoList: FC<TContactInfoListProps> = ({ control, errors, idx, name }) => (
  <Box className={styles.container}>
    <Box className={styles.container__content}>
      <Box className={styles.container__item}>
        <ControlledInput
          required
          label='Full Name:'
          control={control}
          borderRadius='8px'
          name={`${name}.name`}
          borderColor={Colors.SOFT_SILVER}
          placeholder='Full Name:'
          backgroundColor={Colors.FROST_WHITE}
          error={!!errors?.[idx]?.name}
          fontFamily={FontNames.CIRCULAR_REG}
          labelColor={Colors.ENIGMATIC_MIDNIGHT}
          helperText={errors?.[idx]?.name?.message}
        />
        <NumberControlledInput
          required
          control={control}
          borderRadius='8px'
          borderColor={Colors.SOFT_SILVER}
          label='Phone Number 1:'
          backgroundColor={Colors.FROST_WHITE}
          fontFamily={FontNames.CIRCULAR_REG}
          placeholder='Phone Number 1:'
          name={`${name}.phone_number_1`}
          error={!!errors?.[idx]?.phone_number_1}
          labelColor={Colors.ENIGMATIC_MIDNIGHT}
          helperText={errors?.[idx]?.phone_number_1?.message}
        />
      </Box>
      <Box className={styles.container__item}>
        <ControlledInput
          required
          control={control}
          label='Email:'
          placeholder='Email:'
          borderColor={Colors.SOFT_SILVER}
          borderRadius='8px'
          name={`${name}.email`}
          backgroundColor={Colors.FROST_WHITE}
          fontFamily={FontNames.CIRCULAR_REG}
          error={!!errors?.[idx]?.email}
          labelColor={Colors.ENIGMATIC_MIDNIGHT}
          helperText={errors?.[idx]?.email?.message}
        />
        <NumberControlledInput
          control={control}
          borderColor={Colors.SOFT_SILVER}
          label='Phone Number 2:'
          backgroundColor={Colors.FROST_WHITE}
          borderRadius='8px'
          placeholder='Phone Number 2:'
          fontFamily={FontNames.CIRCULAR_REG}
          name={`${name}.phone_number_2`}
          error={!!errors?.[idx]?.phone_number_2}
          labelColor={Colors.ENIGMATIC_MIDNIGHT}
          helperText={errors?.[idx]?.phone_number_2?.message}
        />
      </Box>
      <Box className={styles.container__item}>
        <ControlledInput
          required
          label='State:'
          control={control}
          placeholder='State:'
          borderColor={Colors.SOFT_SILVER}
          borderRadius='8px'
          name={`${name}.state`}
          backgroundColor={Colors.FROST_WHITE}
          fontFamily={FontNames.CIRCULAR_REG}
          error={!!errors?.[idx]?.state}
          labelColor={Colors.ENIGMATIC_MIDNIGHT}
          helperText={errors?.[idx]?.state?.message}
        />
        <ControlledInput
          required
          control={control}
          label='Address:'
          borderColor={Colors.SOFT_SILVER}
          placeholder='Address:'
          borderRadius='8px'
          name={`${name}.address`}
          backgroundColor={Colors.FROST_WHITE}
          fontFamily={FontNames.CIRCULAR_REG}
          error={!!errors?.[idx]?.address}
          labelColor={Colors.ENIGMATIC_MIDNIGHT}
          helperText={errors?.[idx]?.address?.message}
        />
      </Box>
      <Box className={styles.container__item}>
        <ControlledInput
          required
          control={control}
          label='City:'
          placeholder='City:'
          borderColor={Colors.SOFT_SILVER}
          borderRadius='8px'
          name={`${name}.city`}
          backgroundColor={Colors.FROST_WHITE}
          fontFamily={FontNames.CIRCULAR_REG}
          error={!!errors?.[idx]?.city}
          labelColor={Colors.ENIGMATIC_MIDNIGHT}
          helperText={errors?.[idx]?.city?.message}
        />
        <ControlledInput
          required
          label='Zip:'
          control={control}
          placeholder='Zip:'
          borderRadius='8px'
          name={`${name}.zip`}
          borderColor={Colors.SOFT_SILVER}
          backgroundColor={Colors.FROST_WHITE}
          error={!!errors?.[idx]?.zip}
          fontFamily={FontNames.CIRCULAR_REG}
          labelColor={Colors.ENIGMATIC_MIDNIGHT}
          helperText={errors?.[idx]?.zip?.message}
        />
      </Box>
    </Box>
  </Box>
);

export default ContactInfoList;
