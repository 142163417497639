import { FC, Fragment, useState } from 'react';
import { isNumber } from 'lodash';
import { Box, Stack } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

import { createCustomerSchema, createCustomerSchemaIndividual } from 'constants/Schemas';
import { useAppDispatch, useAppSelector, useWindowSize } from 'hooks';
import { currentCustomerSelector } from 'store/slices/customersSlices/selectors';
import { CustomTypography, DrawerLayout, Button } from 'components/shared';
import {
  createCustomer,
  updateCustomer,
  getCustomerById,
  updateBillingInfo,
  updateCustomerContact,
  createCustomerContact,
  createCustomerBillingInfo,
  deleteCustomerContactById,
  removeCustomerBillingInfo,
  getCustomersList,
} from 'store/slices/customersSlices/thunks';
import { FieldsList, NumberControlledInput, ControlledInput, ControlledSelect } from 'components';
import {
  TCustomerBilling,
  TCreateContactData,
  TUpdateCustomerContact,
  TUpdateBillingInfoData,
  TCreateNewBillingInfoData,
  TCreateCustomerWholeData,
} from 'store/slices/customersSlices/types';
import { Colors, FontNames, Routes, TFormSubmit } from 'types';
import breakPoints from 'constants/BreakPoints';

import BillingInfoList from './BillingInfoList';
import ContactInfoList from './ContactInfoList';
import styles from './CreateCustomDrawer.module.scss';
import { billingAppendOptions, contactAppendOptions, customerData, formData } from './utils';

import type {
  TBillingForm,
  TContactForm,
  TCustomerForm,
  TCustomerFormData,
  TContactFieldDataItem,
  TBillingFieldDataItem,
  TCreateCatalogDrawerProps,
} from './types';

const CreateCustomerDrawer: FC<TCreateCatalogDrawerProps> = ({
  open,
  handleClose,
  inEditMode = false,
}) => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { state } = useLocation();

  const currentCustomer = useAppSelector(currentCustomerSelector);

  const [typedValue, setTypedValue] = useState<string>('');

  const getValue = (inEditMode: boolean, value?: string | number) => {
    return inEditMode && value ? String(value) : '';
  };

  const { width } = useWindowSize();

  const drawerWidth =
    Number(width) <= breakPoints.TABLET_L && Number(width) > breakPoints?.TABLET_M
      ? 576
      : Number(width) <= breakPoints.TABLET_M && Number(width) >= breakPoints.MOBILE
      ? 400
      : Number(width) <= breakPoints.MOBILE
      ? 375
      : 888;

  const {
    reset,
    control,
    handleSubmit,
    watch,

    formState: { errors, isDirty, isSubmitting },
  } = useForm<TCustomerForm>({
    shouldFocusError: true,
    resolver:
      typedValue === 'organization'
        ? yupResolver(createCustomerSchema)
        : yupResolver(createCustomerSchemaIndividual),
    values: {
      customer: {
        name: getValue(inEditMode, currentCustomer?.name),
        website_url: getValue(inEditMode, currentCustomer?.website_url),
        email: getValue(inEditMode, currentCustomer?.email),
        phone_number: getValue(inEditMode, currentCustomer?.phone_number),
        address: getValue(inEditMode, currentCustomer?.address),
        state: getValue(inEditMode, currentCustomer?.state),
        city: getValue(inEditMode, currentCustomer?.city),
        zip: getValue(inEditMode, currentCustomer?.zip),
        notes: getValue(inEditMode, currentCustomer?.notes),
        type: getValue(inEditMode, currentCustomer?.type),
        contacts: inEditMode
          ? (currentCustomer?.contacts as TContactForm[])
          : [
              {
                zip: '',
                name: '',
                city: '',
                email: '',
                state: '',
                address: '',
                phone_number_1: '',
                phone_number_2: '',
              },
            ],
        billing: inEditMode
          ? (currentCustomer?.billing as TBillingForm[])
          : [
              {
                zip: '',
                city: '',
                email: '',
                state: '',
                bill_to: '',
                address: '',
                phone_number: '',
              },
            ],
      },
    },
  });

  const watchResult = watch();

  const customerDataOptions = watchResult?.customer;

  const filledItem = {
    zip: customerDataOptions?.zip || '',
    city: customerDataOptions?.city || '',
    email: customerDataOptions?.email || '',
    state: customerDataOptions?.state || '',
    bill_to: '',
    address: customerDataOptions?.address || '',
    phone_number: customerDataOptions?.phone_number || '',
  };

  const isIndividual = watch()?.customer?.type === 'organization';

  const elements = formData?.map(({ type, name, ...rest }, index) => {
    const splitStrings: string[] = name.split('.');

    const result: string[] = splitStrings?.slice(1);
    const errorItem: any = errors?.customer?.[result[0] as keyof TCustomerFormData];

    let element;
    switch (type) {
      case 'ControlledInput':
        element = (
          <ControlledInput
            name={name}
            control={control}
            required={rest.required}
            label={rest.label}
            helperText={errorItem?.message}
            withHelperText
            error={!!errorItem}
            borderColor={rest.borderColor}
            borderRadius={rest.borderRadius}
            backgroundColor={rest.backgroundColor}
            labelColor={Colors.ENIGMATIC_MIDNIGHT}
            placeholder={rest.placeholder as string}
            fontFamily={rest.fontFamily as FontNames}
          />
        );
        break;

      case 'NumberControlledInput':
        element = (
          <NumberControlledInput
            required
            name={name}
            control={control}
            label={rest.label}
            error={!!errorItem}
            helperText={errorItem?.message}
            borderColor={rest.borderColor}
            borderRadius={rest.borderRadius}
            backgroundColor={rest.backgroundColor}
            labelColor={Colors.ENIGMATIC_MIDNIGHT}
            placeholder={rest.placeholder as string}
            fontFamily={rest.fontFamily as FontNames}
          />
        );
        break;

      case 'ControlledSelect':
        element = (
          <ControlledSelect
            control={control}
            required
            name={name}
            errors={!!errorItem}
            border={rest.border}
            options={customerData}
            label={rest.defaultValue}
            setValue={setTypedValue}
            borderRadius={rest.borderRadius}
            backgroundColor={rest.backgroundColor}
            labelColor={Colors.ENIGMATIC_MIDNIGHT}
          />
        );
        break;

      default:
        element = null;
    }

    return <Fragment key={index}>{element}</Fragment>;
  });

  const handleCreateData: TFormSubmit = async (data) => {
    const filteredData =
      data?.customer?.type === 'organization'
        ? data
        : { ...data, customer: { ...data.customer, contacts: [] } };

    if (!inEditMode) {
      const response = await dispatch(createCustomer(filteredData as TCreateCustomerWholeData));
      if (response?.meta?.requestStatus === 'fulfilled') {
        if (state?.isOpenCreateDrawer) {
          navigate(Routes.BomEstimate, { state: { isOpenCreateDrawer: true } });
        }

        handleClose();
        reset();
      }
    } else {
      const updatedCustomerData = filteredData?.customer;

      await dispatch(
        updateCustomer({
          withToasts: true,
          id: currentCustomer?.id as number,
          ...updatedCustomerData,
        }),
      );

      const updatedContactData: TUpdateCustomerContact[] = filteredData?.customer?.contacts?.filter(
        (contact: TUpdateCustomerContact) => {
          if (isNumber(contact?.id)) {
            return contact;
          }
        },
      );

      const updatedBillingData: TUpdateBillingInfoData[] = filteredData?.customer?.billing?.filter(
        (billing: TUpdateBillingInfoData) => {
          if (isNumber(billing?.id)) {
            return billing;
          }
        },
      );

      const newContactData = filteredData?.customer?.contacts?.filter(
        (contact: TContactFieldDataItem) => {
          if (contact?.id === '') {
            return contact;
          }
        },
      );

      const newBillingData = filteredData?.customer?.billing?.filter(
        (billing: TBillingFieldDataItem) => {
          if (billing?.id === '') {
            return billing;
          }
        },
      );

      const removedBillingItems = currentCustomer?.billing?.filter(
        (item2) => !updatedBillingData?.some((item1) => item1.id === item2.id),
      );

      if (removedBillingItems?.length) {
        removedBillingItems?.forEach(async (billing: TCustomerBilling) => {
          await dispatch(removeCustomerBillingInfo(billing?.id));
        });
      }

      const removedContactItems = currentCustomer?.contacts?.filter(
        (item2) => !updatedContactData?.some((item1) => item1.id === item2.id),
      );

      if (removedContactItems?.length) {
        removedContactItems?.forEach(async (contact) => {
          await dispatch(deleteCustomerContactById(contact?.id));
        });
      }

      if (updatedContactData) {
        updatedContactData?.forEach(async (contact) => {
          await dispatch(updateCustomerContact(contact));
        });
      }

      if (updatedBillingData) {
        updatedBillingData?.forEach(async (billing: TUpdateBillingInfoData) => {
          await dispatch(updateBillingInfo(billing));
        });
      }

      if (newBillingData?.length) {
        newBillingData?.forEach(async (billing: TCreateNewBillingInfoData) => {
          await dispatch(
            createCustomerBillingInfo({ ...billing, customer_id: currentCustomer?.id as number }),
          );
        });
      }

      if (newContactData?.length) {
        newContactData?.forEach(async (contact: TCreateContactData) => {
          await dispatch(
            createCustomerContact({ ...contact, customer_id: currentCustomer?.id as number }),
          );
        });
      }

      await dispatch(getCustomerById(currentCustomer?.id as number));
      await dispatch(getCustomersList());

      handleClose();
      reset();
    }
  };

  const buttonTitle = inEditMode ? 'Save' : 'Save';
  const title = inEditMode ? 'Edit a Customer' : 'Add Customer';

  const onDrawerClose = () => {
    handleClose();
  };

  const onDrawerClearClose = () => {
    reset();
    handleClose();
  };

  return (
    <DrawerLayout
      open={open}
      headerTitle={title}
      inCenter={false}
      width={drawerWidth}
      onClose={onDrawerClose}
      onCloseReset={onDrawerClearClose}
      headerContainerClassName={styles.container__header}
      titleClassName={styles.container__header__title}
      subtitleClassName={styles.container__header__subtitle}
    >
      <form className={styles.container} onSubmit={handleSubmit(handleCreateData)}>
        <Box>
          <Box className={styles.container__grid}>{elements}</Box>

          <Box className={styles.container__item}>
            <ControlledInput
              rows={4}
              multiline
              control={control}
              borderRadius='8px'
              name='customer.notes'
              borderColor={Colors.SOFT_SILVER}
              label='Customer Notes'
              backgroundColor={Colors.FROST_WHITE}
              error={errors?.customer?.notes}
              fontFamily={FontNames.CIRCULAR_REG}
              placeholder='Write something here...'
              labelColor={Colors.ENIGMATIC_MIDNIGHT}
              helperText={errors?.customer?.notes?.message}
            />
          </Box>
        </Box>
        <Box sx={{ marginBottom: '40px' }} className={styles.container__billing}>
          <Box className={styles.container_title}>
            <Stack>
              <CustomTypography className={styles.container_title_subtitle}>
                Billing Information
              </CustomTypography>
            </Stack>
            <FieldsList
              withReplace
              control={control}
              withFirstEmptyState
              filledItem={filledItem}
              Component={BillingInfoList}
              fieldName='customer.billing'
              title='Add another billing Info'
              error={errors.customer?.billing}
              appendOptions={billingAppendOptions}
            />
          </Box>
        </Box>
        {isIndividual && (
          <Box sx={{ marginBottom: '40px' }}>
            <Box className={styles.container_title}>
              <CustomTypography className={styles.container_title_subtitle}>
                Point of Contact
              </CustomTypography>
            </Box>

            <FieldsList
              withReplace
              control={control}
              withFirstEmptyState
              filledItem={filledItem}
              title='Add another contact'
              Component={ContactInfoList}
              fieldName='customer.contacts'
              error={errors?.customer?.contacts}
              appendOptions={contactAppendOptions}
            />
          </Box>
        )}

        <Stack alignItems='flex-end' justifyContent='flex-end' direction='row' gap='24px'>
          <Button
            type='button'
            maxWidth='65px'
            borderRadius='4px'
            padding='12px 16px'
            isUppercase={false}
            onClick={onDrawerClose}
            color={Colors.SAPPHIRE}
            backgroundColor={Colors.PALE_BLUE}
          >
            Cancel
          </Button>
          <Button
            type='submit'
            color='white'
            maxWidth='65px'
            borderRadius='4px'
            padding='12px 16px'
            isUppercase={false}
            backgroundColor={Colors.SAPPHIRE}
            disabled={inEditMode ? !isDirty || isSubmitting : isSubmitting}
          >
            {buttonTitle}
          </Button>
        </Stack>
      </form>
    </DrawerLayout>
  );
};

export default CreateCustomerDrawer;
