export const menuStyles = {
  '& .MuiPaper-root': {
    width: '498px',
    height: '310px',
    borderRadius: '10px 10px 5px 5px',

    '& .MuiList-root ': {
      padding: '0 !important',
    },
  },
};

export const arrowStyle = (expanded: boolean) => ({
  transform: expanded ? 'rotate(180deg)' : 'none',
  transition: 'transform 0.3s',
  cursor: 'pointer',
  display: 'flex',
});
