import { useEffect, useState } from 'react';

import { clientCache } from 'api';
import { ImgUrlFolders } from 'types';

import { TVideoDataReturnType } from './types';

const useVideoData = (folderName: ImgUrlFolders, imgId: string) => {
  const [mediaSource, setMediaSource] = useState<TVideoDataReturnType | null>(null);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const localStorageKey = 'videoData';

  const isVideoFile =
    /\.(mp4|mov|avi|flv|wmv|mkv|webm|mpg|mpeg|vob|ogv|ogg|gifv|m4v|3gp|3g2|f4v|f4p|f4a|quicktime|f4b)$/i.test(
      imgId,
    );

  const fetchVideoData = async () => {
    if (!isVideoFile) return;

    setIsLoaded(true);
    try {
      const response = await clientCache.get(`/media-api/sh/media/${folderName}/${imgId}/url`);
      const data = response?.data;

      // Update the local storage to store a single object with all media keys
      const allMediaData = JSON.parse(localStorage.getItem(localStorageKey) as string) || {};
      allMediaData[`${folderName}/${imgId}`] = { data, timestamp: Date.now() };
      localStorage.setItem(localStorageKey, JSON.stringify(allMediaData));

      setMediaSource(data);
      setIsLoaded(false);
    } catch (error) {
      setIsLoaded(false);
    }
  };

  useEffect(() => {
    if (!isVideoFile) return;

    // Check if data is in local storage and hasn't expired
    const allMediaData = JSON.parse(localStorage.getItem(localStorageKey) as string) || {};
    const storedMediaData = allMediaData[`${folderName}/${imgId}`];

    const tenMinutesAgo = Date.now() - 10 * 60 * 1000;
    if (storedMediaData && storedMediaData.timestamp > tenMinutesAgo) {
      setMediaSource(storedMediaData.data);
      setIsLoaded(false);
    } else {
      fetchVideoData(); // Fetch if not in local storage or data is old
    }

    // Set up an interval to refetch data every 10 minutes
    const intervalId = setInterval(() => {
      fetchVideoData();
    }, 10 * 60 * 1000); // 10 minutes

    // Clear the interval on component unmount
    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folderName, imgId]);

  return {
    mediaSource,
    isLoaded,
  };
};

export default useVideoData;
