import * as yup from 'yup';

export const schema = yup.object().shape({
  customer_info: yup.object().shape({
    full_name: yup.string().trim().nullable(),
    email: yup
      .string()
      .trim()
      .lowercase()
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        'Enter a valid email address',
      )
      .nullable(),
    phone_number: yup.string().trim().nullable(),
    address: yup.string().trim().nullable(),
    city: yup.string().trim().nullable(),
    state: yup.string().trim().nullable(),
    zip: yup.string().trim().nullable(),
  }),

  general_info: yup
    .object()
    .shape({
      site_name: yup.string().trim().nullable(),
      site_address: yup.string().trim().nullable(),
      site_email: yup
        .string()
        .trim()
        .lowercase()
        .matches(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          'Enter a valid email address',
        )
        .nullable(),
      site_phone: yup.string().trim().nullable(),
      contract_term: yup.string().trim().nullable(),
      closing_probability: yup.string().trim().nullable(),
      close_date: yup.string().trim().nullable(),
      operate_date: yup.string().trim().nullable(),
    })
    .notRequired(),

  sections: yup.array().of(
    yup.object().shape({
      items: yup.array().of(
        yup.object().shape({
          part_id: yup.string().trim().required('Part ID is required'),
          qty_required: yup
            .number()
            .required('Quantity required is required')
            .positive('Quantity must be a positive number'),
          price: yup
            .number()
            .required('Price is required')
            .positive('Price must be a positive number'),
          part_name: yup.string().trim().required('Part name is required'),
          description: yup.string().trim(),
        }),
      ),
    }),
  ),
});
