import { useEffect, useState, useCallback } from 'react';

import { activitiesApi } from 'api';
import useAppDispatch from 'hooks/useAppDispatch';
import { getRepliesCount } from 'store/slices/optionsSlice';
import { TPostCommentReturnType } from 'store/slices/activitiesSlices/types';
import dataStructure from 'constants/options';

const usePostCommentReplies = (commentId: number) => {
  const dispatch = useAppDispatch();
  const [postCommentReplies, setPostCommentReplies] =
    useState<TPostCommentReturnType>(dataStructure);
  const [isLoad, setIsLoad] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const getPostCommentReplies = useCallback(
    async (id?: number) => {
      const sendedId = id || commentId;

      try {
        setIsLoad(true);
        const response = await activitiesApi.getPostCommentRepliesRequest(sendedId);
        setPostCommentReplies(response?.data);
        dispatch(getRepliesCount(response?.data?.data?.length));
        setIsLoad(false);
        setError(null);

        return response;
      } catch (error) {
        setIsLoad(false);

        setError(error as string);
      } finally {
        setIsLoad(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [commentId],
  );

  useEffect(() => {
    getPostCommentReplies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    error,
    isLoad,
    postCommentReplies,
    getPostCommentReplies,
  };
};

export default usePostCommentReplies;
