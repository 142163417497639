import { useState, type FC, useLayoutEffect } from 'react';
import classNames from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';
import { Step, StepConnector, StepIconProps, StepLabel, Stepper } from '@mui/material';

import { Routes } from 'types';
import { useAppSelector } from 'hooks';
import { StepCheckedIcon, StepperDefaultIcon } from 'assets/icons';
import { userPermissionsSelector } from 'store/slices/authSlice/selectors';
import { optionsSelector } from 'store/slices/optionsSlice/selectors';

import styles from '../Navbar.module.scss';

import { sxStyles } from './styles';

import type { TNavBarStepperProps } from './types';

const NavBarStepper: FC<TNavBarStepperProps> = ({
  onClose,
  setIsOpen,
  isDropdownOpen,
  isInDropDownMode,
}) => {
  const navigate = useNavigate();

  const { pathname } = useLocation();

  const userAccesses = useAppSelector(userPermissionsSelector);

  const { isShowTemplateButton } = useAppSelector(optionsSelector);

  const activeSteps: Record<string, number> = {
    [isShowTemplateButton ? Routes.BomPricingEstimate : Routes.BomEstimate]: 1,
    [Routes.WorkOrder]: 2,
    [Routes.Invoice]: 3,
  };

  const [activeStep, setActiveStep] = useState(activeSteps[pathname as keyof typeof activeSteps]);

  useLayoutEffect(() => {
    const activeStepIds = Object.values(activeSteps).find((el) => el === activeStep);

    activeStepIds && setActiveStep(activeStepIds);

    setActiveStep(activeSteps[pathname as keyof typeof activeSteps]);

    activeStepIds && setIsOpen?.(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const createWorkflowItems = () => {
    const updatedWorkFlowItems = [];

    if (userAccesses.isAccessToEstimatesEditor || userAccesses.isAccessToEstimatesViewer) {
      updatedWorkFlowItems.push({
        label: 'Estimate',
        path: isShowTemplateButton ? Routes.BomPricingEstimate : Routes.BomEstimate,
        id: 3,
      });
    }

    if (userAccesses.isAccessToWorkOrdersAndScheduling) {
      updatedWorkFlowItems.push({ label: 'Work Order', path: Routes.WorkOrder, id: 4 });
    }

    if (userAccesses.isAccessToInvoices) {
      updatedWorkFlowItems.push({ label: 'Invoice', path: Routes.Invoice, id: 5 });
    }

    return updatedWorkFlowItems;
  };

  const updatedWorkFlowItems = createWorkflowItems();

  const handleClick = (path: string) => {
    navigate(path);
    onClose && onClose();
  };

  const renderIcon = (props: StepIconProps) =>
    props.completed ? (
      <div className={styles.dropdown__checked}>
        <StepCheckedIcon />
      </div>
    ) : (
      <StepperDefaultIcon width={12} height={12} />
    );

  return isInDropDownMode ? (
    <div
      className={classNames(styles.dropdown, {
        [styles.dropdown__open]: isDropdownOpen,
      })}
    >
      <Stepper
        activeStep={activeStep}
        alternativeLabel={false}
        orientation='vertical'
        sx={sxStyles}
        connector={
          <StepConnector
            classes={{
              root: styles.connector,
              line: styles.connector__line,
              completed: styles.connector__line_active,
            }}
          />
        }
      >
        {updatedWorkFlowItems.map((item) => {
          return (
            <Step sx={sxStyles} key={item.label} onClick={() => handleClick(item.path)}>
              <StepLabel
                style={{
                  borderRadius: '24px 10px 10px 24px ',
                }}
                StepIconComponent={(props) => renderIcon(props)}
              >
                {item.label}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </div>
  ) : null;
};

export default NavBarStepper;
