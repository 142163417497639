import { FC, useEffect } from 'react';
import classNames from 'classnames';
import { Box, FormControlLabel, Radio, Stack } from '@mui/material';

import { useAppSelector } from 'hooks';
import { CardOptionCheckIcon } from 'assets/icons';
import { ListItem, PriceValueBox, CustomTypography } from 'components';
import {
  userSubscriptionsLoaderSelector,
  currentSubscriptionPlanLoadingSelector,
} from 'store/slices/subscriptionSlice/selectors';

import { checkBoxCheckedStyles } from './styles';
import styles from './SubscriptionPlanCard.module.scss';

import type { TSubscriptionPlanCardProps } from './types';

const SubscriptionPlanCard: FC<TSubscriptionPlanCardProps> = ({
  plan,
  isActive,
  duration,
  className,
  getCardOptions,
  activePlanTitle,
  setActiveDuration,
  activePlanInterval,
}) => {
  const userSubscriptionsLoader = useAppSelector(userSubscriptionsLoaderSelector);
  const createdSubscriptionPlan = useAppSelector(currentSubscriptionPlanLoadingSelector);

  const listItems = plan.options.map((listItem, idx) => (
    <ListItem isActive={isActive} key={idx} title={listItem} icon={<CardOptionCheckIcon />} />
  ));

  const activeCardClasses = classNames(styles.container, className, {
    [styles.container_active]: isActive,
  });

  useEffect(() => {
    if (!userSubscriptionsLoader && !createdSubscriptionPlan) {
      const matchingDuration = plan.durations.find(
        (duration) => duration.duration === activePlanInterval,
      );
      if (matchingDuration) {
        setActiveDuration?.(matchingDuration);
      }
    }
  }, [
    plan.durations,
    activePlanTitle,
    setActiveDuration,
    activePlanInterval,
    userSubscriptionsLoader,
    createdSubscriptionPlan,
  ]);

  const handleTitleChange = () => {
    if (duration) {
      getCardOptions?.({
        title: plan.title,
        duration: { ...duration, duration: duration?.duration, price: Number(cardPrice) },
      });
    }
  };

  const controlCheckBox = (
    <Radio checked={isActive} onChange={handleTitleChange} sx={{ display: 'none' }} />
  );

  let cardPrice: string;
  let price_duration;
  if (plan.title === 'Enterprise Grid') {
    price_duration = '';
    cardPrice =
      duration?.duration === 'Monthly' ? String(plan?.monthlyPrice) : String(plan?.yearlyPrice);
  } else {
    price_duration = 'User/Month';
    cardPrice =
      duration?.duration === 'Monthly'
        ? String(plan?.monthlyPrice) + '$' + ' /'
        : String(plan?.yearlyPrice) + '$' + ' /';
  }

  return (
    <Box className={activeCardClasses} onClick={handleTitleChange}>
      <Box>
        <Box className={styles.container__head}>
          <FormControlLabel
            label=''
            value={plan.title}
            control={controlCheckBox}
            sx={checkBoxCheckedStyles(isActive)}
            className={styles.container__head__label}
          />
          <CustomTypography className={styles.container__head__text}>{plan.title}</CustomTypography>
        </Box>

        <Stack className={styles.container__list} gap='8px'>
          {listItems}
        </Stack>
      </Box>

      <Box className={styles.container__price}>
        <PriceValueBox isActive={isActive} price={cardPrice} duration={price_duration as string} />
      </Box>
    </Box>
  );
};

export default SubscriptionPlanCard;
