import { FC, useEffect } from 'react';
import { Box } from '@mui/material';
import classNames from 'classnames';
import { Controller, useFieldArray } from 'react-hook-form';

import { useAppDispatch } from 'hooks';
import { PlusFilledIcon, PlusIcon } from 'assets/icons';
import { getAllRoles } from 'store/slices/rolesSlice/thunks';
import { ProcessFormTitle, FormLayout, CustomTypography } from 'components';

import styles from './InviteUsers.module.scss';
import { TInviteUsersProps } from './types';
import InviteUserItem from './InviteUserItem';

const InviteUsers: FC<TInviteUsersProps> = ({
  errors,
  control,
  withOutTitle = false,
  setIsInvitingYourself,
}) => {
  const dispatch = useAppDispatch();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'invitedUsers',
  });

  useEffect(() => {
    if (fields.length === 0) {
      append({ role: '', email: '', fullName: '', id: Math.random() });
    }
  }, [fields, append]);

  const removeUserInfo = (index: number) => {
    remove(index);
  };

  useEffect(() => {
    dispatch(getAllRoles());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const containerClasses = classNames(styles.container, {
    [styles.container__modal]: withOutTitle,
  });

  const invitedUserItems = fields.map((user, idx) => (
    <Box key={user.id} style={{ maxWidth: withOutTitle && idx !== 0 ? 834 : '100%' }}>
      <Controller
        key={user.id}
        control={control}
        name={`invitedUsers[${idx}]`}
        render={() => (
          <InviteUserItem
            idx={idx}
            control={control}
            errors={errors.invitedUsers?.[idx]}
            removeUserInfo={() => removeUserInfo(idx)}
            setIsInvitingYourself={setIsInvitingYourself}
            plusIcon={
              <PlusFilledIcon className={styles.container__plus} role='button' onClick={append} />
            }
          />
        )}
      />
    </Box>
  ));

  return (
    <Box className={containerClasses}>
      {!withOutTitle && <ProcessFormTitle lightSubtitle title='Invite Users' />}

      {!withOutTitle ? (
        <FormLayout withIcons className={styles.container__form}>
          {invitedUserItems}
          <Box className={styles.container__plus} role='button' onClick={append}>
            <PlusFilledIcon width={16} height={16} />
            <CustomTypography className={styles.container__plus__text}>Add User</CustomTypography>
          </Box>
        </FormLayout>
      ) : (
        <>
          {invitedUserItems}
          <Box>
            <PlusIcon
              width={16}
              height={16}
              role='button'
              onClick={append}
              className={styles.container__plus__new}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default InviteUsers;
