import { FC } from 'react';
import { Stack } from '@mui/material';
import classNames from 'classnames';

import { CustomTypography } from 'components';

import styles from './ProcessFormTitle.module.scss';

import type { TProcessFormTitleProps } from './types';

const ProcessFormTitle: FC<TProcessFormTitleProps> = ({
  title,
  subtitle,
  small = false,
  bottom = false,
  subtitleClassName,
  marginBottom = '4px',
  lightSubtitle = false,
}) => {
  const subtitleClasses = classNames(styles.container__subtitle, subtitleClassName, {
    [styles.container__subtitle_small]: small,
    [styles.container__subtitle_light]: lightSubtitle,
  });

  const containerClasses = classNames(styles.container, {
    [styles.container_bottom]: bottom,
  });

  return (
    <Stack className={containerClasses} spacing={0.5} sx={{ marginBottom }}>
      <CustomTypography className={styles.container__title}>{title}</CustomTypography>
      {subtitle && <CustomTypography className={subtitleClasses}>{subtitle}</CustomTypography>}
    </Stack>
  );
};

export default ProcessFormTitle;
