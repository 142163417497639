export const sxMenuStyles = {
  '& .MuiPaper-root': {
    borderRadius: '10px',
    border: '0.5px dashed #ccc)',
    background: '#fff',
    padding: '10px 8px',

    '& .MuiMenuItem-root ': {
      display: 'flex',
      gap: '8px',
    },
  },
};
