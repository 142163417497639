import { FC } from 'react';
import { Box } from '@mui/material';
import { MediaPlayer, MediaProvider, Track } from '@vidstack/react';
import { isSafari } from 'react-device-detect';
import classNames from 'classnames';

import { useVideoData } from 'hooks';
import { ImgUrlFolders } from 'types';
import '@vidstack/react/player/styles/default/theme.css';
import '@vidstack/react/player/styles/default/layouts/video.css';

import styles from './StreamVideoPlayer.module.scss';
import PlayerControls from './PlayerControls/PlayerControls';
import BufferingIndicator from './BufferingIndicator';

import type { TStreamVideoPlayerProps } from './types';

const StreamVideoPlayer: FC<TStreamVideoPlayerProps> = ({ stream }) => {
  const videoFromStream = stream?.media?.find(
    (media) => media?.file_type === 'mp4' || media?.file_type === 'webm',
  );

  const { mediaSource } = useVideoData(
    ImgUrlFolders.LIVE_STREAM,
    videoFromStream?.media_url_id as string,
  );

  const videoProviderClasses = classNames(styles.container__provider, {
    [styles.safari__provider]: isSafari,
  });

  return (
    <Box className={styles.container}>
      {mediaSource?.url && (
        <MediaPlayer src={mediaSource?.url} style={{ height: '100%' }} aspectRatio='1/1'>
          <MediaProvider style={{ position: 'relative' }} className={videoProviderClasses}>
            <BufferingIndicator />
            <Track src='/chapters.vtt' lang='en-US' kind='chapters' default />
            <PlayerControls />
          </MediaProvider>
        </MediaPlayer>
      )}
    </Box>
  );
};

export default StreamVideoPlayer;
