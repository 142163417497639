import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { RootState } from 'types';
import { catalogApi, estimatesApi } from 'api';
import { capitalizeFirstLetter } from 'utils/capitalizeText';
import { EstimateToastMessages, ProfitMarginToastMessages } from 'constants/ToastMessages';

import type { AxiosError } from 'axios';
import type {
  TEstimateBody,
  TEstimateOptions,
  TEstimateEditOptions,
  TEditProfitMarginData,
  TEstimateSectionOptions,
  TEditEstimateSectionById,
  TEstimateSectionItemOptions,
  TEstimateSectionItemWithBody,
  TEstimateFromTemplateOptions,
  TSendedEstimateSectionDataOptions,
  TSendedEstimateFromTemplateData,
  TUpdateEstimateFromTemplateData,
} from './types';

export const getEstimates = createAsyncThunk(
  'estimatesSlice/getEstimates',
  async (options: TEstimateOptions) => {
    try {
      const response = await estimatesApi.getAllEstimatesRequest(options);

      if (Array.isArray(response?.data) && response?.data?.length === 0) {
        return {
          data: [],
          total_count: 0,
        };
      }

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const getEstimatesFromTemplates = createAsyncThunk(
  'estimatesSlice/getEstimates',
  async (options: TEstimateFromTemplateOptions) => {
    try {
      const response = await estimatesApi.getAllEstimatesFromTemplateRequest(options);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const getEstimatesVersionsFromTemplates = createAsyncThunk(
  'estimatesSlice/getEstimates',
  async (uuid: string) => {
    try {
      const response = await estimatesApi.getEstimatesVersionFromTemplateRequest(uuid);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const createEstimate = createAsyncThunk(
  'estimatesSlice/createEstimate',
  async (body: TEstimateBody, { dispatch, getState }) => {
    const start = toast.loading(EstimateToastMessages.ESTIMATE_CREATE_START);

    const {
      estimates: { allEstimatesLimit, allEstimatesOffset },
    } = getState() as RootState;

    try {
      const response = await estimatesApi.createEstimateRequest(body);

      dispatch(getEstimates({ limit: allEstimatesLimit, offset: allEstimatesOffset }));

      toast.update(start, {
        render: EstimateToastMessages.ESTIMATE_CREATE_SUCCESS,
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      toast.update(start, {
        render: EstimateToastMessages.ESTIMATE_CREATE_FAILURE,
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });

      throw Error;
    }
  },
);

export const getEstimateById = createAsyncThunk(
  'estimatesSlice/getEstimateById',
  async (id: number) => {
    try {
      const response = await estimatesApi.getEstimateByIdRequest(id);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const getElementsById = createAsyncThunk(
  'estimatesSlice/getEstimateById',
  async (id: number) => {
    try {
      const response = await estimatesApi.getElementsByIdRequest(id);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const getEstimateByUUID = createAsyncThunk(
  'estimatesSlice/getEstimateById',
  async (uuid: string) => {
    try {
      const response = await estimatesApi.getEstimateByUUIDRequest(uuid);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const getAllTemplates = createAsyncThunk('estimatesSlice/getEstimateById', async () => {
  try {
    const response = await catalogApi.getAllExcelListRequest({ limit: 100, offset: 0, asc: true });

    return response.data;
  } catch (error) {
    const Error = error as AxiosError;
    throw Error;
  }
});

export const createEstimateFromTemplate = createAsyncThunk(
  'estimatesSlice/createEstimate',
  async ({ estimate_template_id, body }: TSendedEstimateFromTemplateData) => {
    const start = toast.loading(EstimateToastMessages.ESTIMATE_CREATE_START);

    try {
      const response = await estimatesApi.createEstimateRequestFromTemplate({
        estimate_template_id: estimate_template_id,
        body: body,
      });

      toast.update(start, {
        render: EstimateToastMessages.ESTIMATE_CREATE_SUCCESS,
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      toast.update(start, {
        render: EstimateToastMessages.ESTIMATE_CREATE_FAILURE,
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });

      throw Error;
    }
  },
);

export const updateEstimateFromTemplate = createAsyncThunk(
  'estimatesSlice/createEstimate',
  async ({ estimate_template_id, uuid, body }: TUpdateEstimateFromTemplateData) => {
    const start = toast.loading(EstimateToastMessages.ESTIMATE_UPDATE_START);

    try {
      const response = await estimatesApi.updateEstimateRequestFromTemplate({
        estimate_template_id: estimate_template_id,
        uuid: uuid,
        body: body,
      });

      toast.update(start, {
        render: EstimateToastMessages.ESTIMATE_UPDATE_SUCCESS,
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      toast.update(start, {
        render: EstimateToastMessages.ESTIMATE_UPDATE_FAILURE,
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });

      throw Error;
    }
  },
);

export const getEstimateByUuid = createAsyncThunk(
  'estimatesSlice/getEstimateByUuid',
  async (uuid: string) => {
    try {
      const response = await estimatesApi.getEstimateByUuidRequest(uuid);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const getEstimateSectionItems = createAsyncThunk(
  'estimatesSlice/getEstimateSectionItems',
  async ({ estimate_id, estimate_section_id }: TEstimateSectionOptions) => {
    try {
      const response = await estimatesApi.getEstimateSectionItemsRequest({
        estimate_id,
        estimate_section_id,
      });

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const deleteEstimateSection = createAsyncThunk(
  'estimatesSlice/deleteEstimateSection',
  async (options: TEstimateSectionOptions, { dispatch }) => {
    const start = toast.loading(EstimateToastMessages.ESTIMATE_SECTION_DELETE_START);

    try {
      const response = await estimatesApi.deleteEstimateSectionRequest(
        options.estimate_id,
        options.estimate_section_id,
      );
      dispatch(getEstimateById(options.estimate_id));
      toast.update(start, {
        render: EstimateToastMessages.ESTIMATE_SECTION_DELETE_SUCCESS,
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      toast.update(start, {
        render: EstimateToastMessages.ESTIMATE_SECTION_DELETE_FAILURE,
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });

      throw Error;
    }
  },
);

export const deleteEstimateItemSection = createAsyncThunk(
  'estimatesSlice/deleteEstimateSectionItem',
  async (options: TEstimateSectionItemOptions, { dispatch }) => {
    const start = toast.loading(EstimateToastMessages.ESTIMATE_SECTION_ITEM_DELETE_START);

    try {
      const response = await estimatesApi.deleteEstimateSectionItemRequest(
        options.estimate_id,
        options.estimate_section_id,
        options.item_id,
      );

      dispatch(getEstimateById(options.estimate_id));

      toast.update(start, {
        render: EstimateToastMessages.ESTIMATE_SECTION_ITEM_DELETE_SUCCESS,
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      toast.update(start, {
        render: EstimateToastMessages.ESTIMATE_SECTION_ITEM_DELETE_FAILURE,
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });

      throw Error;
    }
  },
);

export const createEstimateSection = createAsyncThunk(
  'estimatesSlice/createEstimateSection',
  async ({ id, body }: TSendedEstimateSectionDataOptions, { dispatch }) => {
    try {
      const response = await estimatesApi.createEstimateSectionRequest({ id, body });

      dispatch(getEstimateById(id as number));

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      throw Error;
    }
  },
);

export const deleteEstimate = createAsyncThunk(
  'estimatesSlice/deleteEstimate',
  async (id: number, { dispatch, getState }) => {
    const start = toast.loading(EstimateToastMessages.ESTIMATE_REMOVE_START);
    const {
      estimates: { allEstimatesLimit },
    } = getState() as RootState;

    try {
      const response = await estimatesApi.deleteEstimateRequest(id);

      dispatch(getEstimates({ limit: allEstimatesLimit, offset: 0 }));

      toast.update(start, {
        render: EstimateToastMessages.ESTIMATE_REMOVE_SUCCESS,
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      toast.update(start, {
        render: EstimateToastMessages.ESTIMATE_REMOVE_FAILURE,
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });

      throw Error;
    }
  },
);

export const editEstimate = createAsyncThunk(
  'estimatesSlice/editEstimate',
  async ({ id, body }: TEstimateEditOptions, { dispatch }) => {
    const start = toast.loading(EstimateToastMessages.ESTIMATE_GENERATION_START);
    try {
      const response = await estimatesApi.editEstimateRequest({ id, body });

      toast.update(start, {
        render: EstimateToastMessages.ESTIMATE_GENERATION_SUCCESS,
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });

      dispatch(getEstimateById(id));
      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      toast.update(start, {
        render: EstimateToastMessages.ESTIMATE_GENERATION_FAILURE,
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });

      throw Error;
    }
  },
);

export const createEstimateSectionItem = createAsyncThunk(
  'estimatesSlice/createEstimateSectionItem',
  async ({ estimate_id, estimate_section_id, body }: TEstimateSectionItemWithBody) => {
    try {
      const response = await estimatesApi.createEstimateSectionItemRequest(
        estimate_id,
        estimate_section_id,
        body,
      );

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const editEstimateSectionItemById = createAsyncThunk(
  'estimatesSlice/editEstimateSectionItemById',
  async (options: TEditEstimateSectionById, { dispatch }) => {
    try {
      const response = await estimatesApi.editEstimateSectionItem(options);

      dispatch(getEstimateById(options.estimate_id));
      dispatch(
        getEstimateSectionItems({
          estimate_id: options.estimate_id,
          estimate_section_id: options.estimate_section_id,
        }),
      );

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const editEstimateProfitMargin = createAsyncThunk(
  'estimatesSlice/editEstimateProfitMargin',
  async ({ estimate_id, estimate_section_id, body }: TEditProfitMarginData, { dispatch }) => {
    const start = toast.loading(ProfitMarginToastMessages.PROFIT_MARGIN_UPDATE_START);

    try {
      const response = await estimatesApi.editProfitMarginRequest(
        estimate_id,
        estimate_section_id,
        body,
      );
      toast.update(start, {
        render: ProfitMarginToastMessages.PROFIT_MARGIN_UPDATE_SUCCESS,
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });

      dispatch(getEstimateById(estimate_id));

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      toast.update(start, {
        render: ProfitMarginToastMessages.PROFIT_MARGIN_UPDATE_FAILURE,
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });

      throw Error;
    }
  },
);

export const sendEstimateToCustomer = createAsyncThunk(
  'estimatesSlice/sendEstimateToCustomer',
  async (estimate_id: number, { dispatch, getState }) => {
    const start = toast.loading(EstimateToastMessages.ESTIMATE_ITEM_PUBLISH_START);

    const {
      estimates: { allEstimatesLimit, allEstimatesOffset },
    } = getState() as RootState;

    try {
      const response = await estimatesApi.sendEstimateToCustomerRequest(estimate_id);

      toast.update(start, {
        render: capitalizeFirstLetter(response?.data?.status),
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });

      dispatch(getEstimateById(estimate_id));
      dispatch(getEstimates({ limit: allEstimatesLimit, offset: allEstimatesOffset }));

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      toast.update(start, {
        render: EstimateToastMessages.ESTIMATE_SENDCUSTOMER_FAILED,
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });

      throw Error;
    }
  },
);
