import { GridColDef } from '@mui/x-data-grid';

import { Routes } from 'types';
import { TableCell } from 'components';
import HeaderTitle from 'components/tables/EmployeeTable/HeaderTitle';

export const breadCrumbOptions = (name: string, id: string, section: string) => [
  { id: 1, label: 'Documents', goTo: Routes.Documents },
  { id: 2, label: name, goTo: `/documents/${name}/${id}` },
  { id: 3, label: section, goTo: Routes.DocumentsItemSection },
];

export const documentsColumns: GridColDef[] = [
  {
    field: 'documentName',
    headerName: 'Document',
    flex: 4,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle title={headerName} style={{ paddingLeft: '0' }} />;
    },
    renderCell: (params) => {
      return (
        <TableCell
          isTooltipDisabled
          title={params?.value}
          style={{ paddingLeft: '0', color: '#282828' }}
        />
      );
    },
  },
  {
    field: 'last_modified',
    headerName: 'Last Modified',
    flex: 1,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle title={headerName} style={{ paddingLeft: '0' }} />;
    },
    renderCell: (params) => {
      return <TableCell style={{ paddingLeft: '0', color: '#282828' }} title={params?.value} />;
    },
  },
  {
    field: 'size',
    headerName: 'Size',
    flex: 1,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle title={headerName} style={{ paddingLeft: '0' }} />;
    },
    renderCell: (params) => {
      return <TableCell style={{ paddingLeft: '0', color: '#282828' }} title={params?.value} />;
    },
  },

  {
    field: 'options',
    headerName: '',
    width: 72,
    renderCell: (params) => {
      return params.value;
    },
  },
];
