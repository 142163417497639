import { useEffect, useState } from 'react';

import useAppDispatch from 'hooks/useAppDispatch';
import { TPostLikedUserDataReturnTypeNew } from 'store/slices/activitiesSlices/types';
import { getAllPostLikedUsers } from 'store/slices/activitiesSlices/thunks';

const usePostLikedUsers = (id: number) => {
  const dispatch = useAppDispatch();
  const [data, setData] = useState<TPostLikedUserDataReturnTypeNew | null>(null);
  const [isLoad, setIsLoad] = useState<boolean>(false);

  const getPostLikedUsersData = async () => {
    setIsLoad(true);
    const data = await dispatch(getAllPostLikedUsers(id)).unwrap();
    if (data?.data?.length) {
      setData(data);
    } else {
      setData({
        data: [],
        total_likes: 0,
      });
    }
    setIsLoad(false);
  };

  useEffect(() => {
    if (id) {
      getPostLikedUsersData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    data,
    isLoad,
    getPostLikedUsersData,
  };
};

export default usePostLikedUsers;
