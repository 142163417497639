import { FC, useEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';
import classNames from 'classnames';
import { useInView } from 'framer-motion';
import { isMobileSafari } from 'react-device-detect';

import { PauseIcon, VideoPlayIcon } from 'assets/icons';
import PreloadedImg from 'components/views/PreloadedImg';
import { ImgUrlFolders } from 'types/global/imgUrlFolders';
import { useElementSize, useVideoTimeUpdate } from 'hooks';

import styles from './PostVideoPreview.module.scss';

import type { TPostVideoPreviewProps } from './types';

const PostVideoPreview: FC<TPostVideoPreviewProps> = ({ imgSrc, isActive = false }) => {
  const [isPlaying, setIsPlaying] = useState<boolean>(false);

  const videoRef = useRef<HTMLVideoElement | null>(null);

  const isInView = useInView(videoRef);

  const startPlaying = () => {
    videoRef.current?.play();
    setIsPlaying(true);
  };

  const stopPlaying = () => {
    videoRef.current?.pause();
    setIsPlaying(false);
  };

  useEffect(() => {
    if (!isInView || !isActive) {
      videoRef.current?.pause();
      setIsPlaying(false);
    }

    // Cleanup the event listener when the component unmounts
  }, [isInView, isActive]);

  const { currentTime, duration } = useVideoTimeUpdate(videoRef);

  useEffect(() => {
    if (videoRef.current) {
      const videoElement = videoRef.current;

      if (currentTime === duration) {
        videoElement.pause();
        setIsPlaying(false);
        videoElement.currentTime = 0;
      }
    }
  }, [currentTime, duration, videoRef]);

  const size = useElementSize(videoRef);

  const overlayClasses = classNames(styles.container__overlay, {
    [styles.container__overlay_disabled]: isMobileSafari,
  });

  return (
    <div className={styles.container}>
      <Box className={styles.container__controls}>
        {isPlaying ? <PauseIcon onClick={stopPlaying} /> : <VideoPlayIcon onClick={startPlaying} />}
      </Box>
      <Box
        sx={{
          height: size?.height || '100%',
          width: size?.width || '300px',
        }}
        className={overlayClasses}
      />
      <div
        style={{
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background: 'black',
        }}
      >
        <PreloadedImg
          forVideo
          imgId={imgSrc}
          videoRef={videoRef}
          folderName={ImgUrlFolders.ACTIVITY_FEED}
          controls={false}
        />
      </div>
    </div>
  );
};

export default PostVideoPreview;
