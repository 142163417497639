import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import { motion } from 'framer-motion';

import { pricing } from 'components/views/LandingPricing/constant';
import { Button, LandingPricing, TitleSubTitle, RevealBlock } from 'components';

import styles from './Pricing.module.scss';
import { timeOptions } from './constant';

const Pricing: React.FC = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [activeTime, setActiveTime] = useState<string>('Monthly');

  const handleSelectPlan = (idx: number) => {
    setSelectedIndex(idx);
  };

  const renderPricing = pricing.map((options, index) => (
    <motion.div
      key={index}
      role='button'
      whileTap={{ scale: 0.9 }}
      onClick={() => handleSelectPlan(index)}
      style={{ maxWidth: 318, width: '100%' }}
    >
      {/* <div > */}
      <LandingPricing
        key={options.header}
        options={options}
        isSelected={selectedIndex === index}
        isMonthly={activeTime === 'Monthly'}
      />
      {/* </div> */}
    </motion.div>
  ));

  const changeActiveTime = (time: string) => setActiveTime(time);

  const timeButtons = timeOptions?.map((time, idx) => {
    const timeClasses = classNames(styles.container_rect_monthly, {
      [styles.container_rect_yearly]: activeTime === time?.title,
    });

    return (
      <motion.div
        key={idx}
        whileTap={{ scale: 0.9 }}
        onClick={() => changeActiveTime(time?.title)}
        role='button'
      >
        <Button className={timeClasses}>{time?.title}</Button>
      </motion.div>
    );
  });

  const titleRef = useRef<HTMLDivElement | null>(null);
  const durationsRef = useRef<HTMLDivElement | null>(null);
  const pricesRef = useRef<HTMLDivElement | null>(null);

  return (
    <div className={styles.container}>
      <div className={styles.container_content}>
        <div className={styles.container_content_plan}>
          <RevealBlock renderedContentRef={titleRef}>
            <TitleSubTitle style={{ margin: 0 }} title='' subTitle='Plans' />
          </RevealBlock>
          <RevealBlock renderedContentRef={durationsRef}>
            <div className={styles.container_rect}>{timeButtons}</div>
          </RevealBlock>
          <RevealBlock renderedContentRef={pricesRef}>
            <div className={styles.container_content_plan_pricing}>{renderPricing}</div>
            {/* Do not remove from code */}
            {/* <Stack alignItems='flex-end' width='100%' padding='20px 0'>
              <Button
                onClick={() => navigate(Routes.SignUp)}
                isUppercase={false}
                textClassName={styles.container__free}
              >
                Free 30 Day Trial
              </Button>
            </Stack> */}
          </RevealBlock>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
