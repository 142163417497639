import { BlueCircleIcon } from 'assets/icons';

import type { TOption } from './types';

export const pricing: TOption[] = [
  {
    header: 'Professional Plan',
    yearly: '19.99$ /',
    monthly: '24.99$ /',
    content: [
      { id: 0, name: 'Estimates and Agreements', checked: true, Icon: <BlueCircleIcon /> },
      { id: 1, name: 'Invoice Automation', checked: true, Icon: <BlueCircleIcon /> },
      { id: 2, name: 'Employee Activities Monitoring', checked: true, Icon: <BlueCircleIcon /> },
      { id: 3, name: 'Work Orders and Scheduling', checked: true, Icon: <BlueCircleIcon /> },
      { id: 4, name: 'Live Stream Coordination', checked: true, Icon: <BlueCircleIcon /> },
      { id: 5, name: 'Inventory Management', checked: true, Icon: <BlueCircleIcon /> },
      { id: 6, name: 'Employee Management', checked: true, Icon: <BlueCircleIcon /> },
      { id: 7, name: 'Enhanced Team Collaboration', checked: true, Icon: <BlueCircleIcon /> },
      { id: 8, name: 'Supports up to 5 users', checked: true, Icon: <BlueCircleIcon /> },
    ],
  },
  {
    header: 'Business Plan',
    yearly: '24.99$ /',
    monthly: '29.99$ /',
    content: [
      { id: 0, name: 'All features in Professional Plan', checked: true, Icon: <BlueCircleIcon /> },
      { id: 1, name: 'Gen AI powered Reminders', checked: true, Icon: <BlueCircleIcon /> },
      { id: 3, name: 'Gen AI powered Estimates', checked: true, Icon: <BlueCircleIcon /> },
      { id: 2, name: 'Gen AI powered Work Orders', checked: true, Icon: <BlueCircleIcon /> },
      {
        id: 3,
        name: 'Gen AI powered Employee Scheduling',
        checked: true,
        Icon: <BlueCircleIcon />,
      },
      { id: 4, name: 'Supports up to 50 users', checked: true, Icon: <BlueCircleIcon /> },
    ],
  },
  {
    header: 'Enterprise Grid',
    yearly: 'Contact us',
    monthly: 'Contact us',
    content: [
      { id: 0, name: 'All In Business Plan', checked: true, Icon: <BlueCircleIcon /> },
      { id: 1, name: 'Support for 100+ Employees', checked: true, Icon: <BlueCircleIcon /> },
      { id: 2, name: '24/7 Phone Support', checked: true, Icon: <BlueCircleIcon /> },
      {
        id: 3,
        name: 'Enhanced Capacity Document Storage',
        checked: true,
        Icon: <BlueCircleIcon />,
      },
    ],
  },
];
