import styles from './FromTopToBottom.module.scss';

const FromTopToBottom = () => {
  return (
    <svg
      width='335'
      height='301'
      viewBox='0 0 335 301'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M176 0.55957 L176 292.56' stroke='#D2E1F9' strokeWidth='8'>
        <animate
          attributeName='stroke-dasharray'
          values='0 1700;1700 0'
          dur='4s'
          repeatCount='indefinite'
        ></animate>
        <animate
          attributeName='stroke-dashoffset'
          values='0 -1700;-1700 0'
          dur='4s'
          repeatCount='indefinite'
        ></animate>
      </path>
      <circle cx='176' cy='292.56' r='8' fill='#D2E1F9' className={styles.circle}></circle>
    </svg>
  );
};

export default FromTopToBottom;
