import { GridColDef } from '@mui/x-data-grid';

import { Routes } from 'types';
import { TableCell } from 'components';
import HeaderTitle from 'components/tables/EmployeeTable/HeaderTitle';
import { BillingInfo, CustomerInfo, GeneralInfo } from 'components/shared/EstimateForms';

import { TBreadCrumbsOptions } from './types';

export const columns: GridColDef[] = [
  {
    field: 'item',
    headerName: 'Item',
    flex: 1,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle title={headerName} />;
    },
    renderCell: (params) => {
      return <TableCell title={params?.value} style={{ color: '#667085' }} />;
    },
  },
  {
    field: 'description',
    headerName: 'Description',
    flex: 1.5,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle style={{ padding: '0' }} title={headerName} />;
    },
    renderCell: (params) => {
      return <TableCell title={params?.value} style={{ color: '#667085' }} />;
    },
  },
  {
    field: 'quantity',
    headerName: 'Quantity',
    flex: 1,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle style={{ padding: '0' }} title={headerName} />;
    },
    renderCell: (params) => {
      return <TableCell style={{ padding: '0', color: '#667085' }} title={params?.value} />;
    },
  },
  {
    field: 'price',
    headerName: 'Price',
    flex: 1,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle style={{ padding: '0' }} title={headerName} />;
    },
    renderCell: (params) => {
      return <TableCell style={{ padding: '0', color: '#667085' }} title={params?.value} />;
    },
  },
  {
    field: 'amount',
    headerName: 'Amount',
    flex: 1,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle style={{ padding: '0' }} title={headerName} />;
    },
    renderCell: (params) => {
      return <TableCell style={{ padding: '0', color: '#667085' }} title={params?.value} />;
    },
  },

  {
    field: 'options',
    headerName: '',
    width: 72,
    renderCell: (params) => {
      return params.value;
    },
  },
];
export const breadCrumbOptions = ({ id, name }: TBreadCrumbsOptions) => [
  { id: 1, label: 'Workflow', goTo: Routes.BomEstimate },
  { id: 2, label: 'Estimates', goTo: Routes.BomEstimate },
  {
    id: 3,
    label: name,
    goTo: `/work-flow/bom-estimate/estimate/${id}`,
  },
];

export const estimateInfoDetailMock = [
  {
    id: 1,
    title: 'Customer Information',
    Component: CustomerInfo,
  },
  {
    id: 2,
    title: 'Billing Information',
    Component: BillingInfo,
  },
  {
    id: 3,
    title: 'General Information',
    Component: GeneralInfo,
  },
];

export const defaultCustomerInfo = {
  bill_to: {
    bill_to: '',
    phone_number: '',
    address: '',
    city: '',
    state: '',
    zip: '',
  },
  phone: '',
  address: '',
  city: '',
  state: '',
  zip: '',
  general_info: '',
  customer_info: {
    full_name: '',
    email: '',
    phone_number: '',
    address: '',
    city: '',
    state: '',
    zip: '',
  },
};

export const paymentScheduleData = [
  { title: 'Work Completion %', id: 1, value: 'percent' },
  { title: 'Amount', id: 2, value: 'amount' },
];
