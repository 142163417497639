import { GridColDef } from '@mui/x-data-grid';

import { TableCell } from 'components';
import HeaderTitle from 'components/tables/EmployeeTable/HeaderTitle';

export const estimateColumns: GridColDef[] = [
  {
    field: 'Created',
    headerName: 'Created',
    flex: 1,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle title={headerName} style={{ paddingLeft: '0' }} />;
    },
    renderCell: (params) => {
      return <TableCell title={params?.value} style={{ padding: '0', color: '#667085' }} />;
    },
  },
  {
    field: 'site_name',
    headerName: 'Site Name',
    flex: 1.2,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle title={headerName} style={{ paddingLeft: '0' }} />;
    },
    renderCell: (params) => {
      return (
        <TableCell
          title={params?.value}
          style={{
            padding: '0',
            color: '#667085',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        />
      );
    },
  },
  {
    field: 'site_address',
    headerName: 'Site Address',
    flex: 1.3,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle title={headerName} style={{ paddingLeft: '0' }} />;
    },
    renderCell: (params) => {
      return (
        <TableCell
          title={params?.value}
          style={{
            padding: '0',
            color: '#667085',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        />
      );
    },
  },
  {
    field: 'site_type',
    headerName: 'Site Type',
    flex: 1.2,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle title={headerName} style={{ paddingLeft: '0' }} />;
    },
    renderCell: (params) => {
      return (
        <TableCell
          title={params?.value}
          style={{
            padding: '0',
            color: '#667085',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        />
      );
    },
  },
  {
    field: 'close_probability',
    headerName: 'Close Probability',
    flex: 1.5,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle title={headerName} style={{ paddingLeft: '0' }} />;
    },
    renderCell: (params) => {
      return (
        <TableCell
          title={params?.value}
          style={{
            padding: '0',
            color: '#667085',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        />
      );
    },
  },

  {
    field: 'close_date',
    headerName: 'Close Date',
    flex: 1.2,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle title={headerName} style={{ paddingLeft: '0' }} />;
    },
    renderCell: (params) => {
      return <TableCell title={params?.value} style={{ padding: '0', color: '#667085' }} />;
    },
  },
  {
    field: 'operate_date',
    headerName: 'Operate Date',
    flex: 1.2,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle title={headerName} style={{ paddingLeft: '0' }} />;
    },
    renderCell: (params) => {
      return <TableCell title={params?.value} style={{ padding: '0', color: '#667085' }} />;
    },
  },
  {
    field: 'version',
    headerName: 'Version',
    flex: 1.2,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle title={headerName} style={{ paddingLeft: '0' }} />;
    },
    renderCell: (params) => {
      return <TableCell title={params?.value} style={{ padding: '0', color: '#667085' }} />;
    },
  },
];

export const estimateVersionColumns: GridColDef[] = [
  {
    field: 'Created',
    headerName: 'Created',
    flex: 1,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle title={headerName} style={{ paddingLeft: '0' }} />;
    },
    renderCell: (params) => {
      return <TableCell title={params?.value} style={{ padding: '0', color: '#667085' }} />;
    },
  },
  {
    field: 'site_name',
    headerName: 'Site Name',
    flex: 1.2,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle title={headerName} style={{ paddingLeft: '0' }} />;
    },
    renderCell: (params) => {
      return (
        <TableCell
          title={params?.value}
          style={{
            padding: '0',
            color: '#667085',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        />
      );
    },
  },
  {
    field: 'close_date',
    headerName: 'Close Date',
    flex: 1.2,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle title={headerName} style={{ paddingLeft: '0' }} />;
    },
    renderCell: (params) => {
      return <TableCell title={params?.value} style={{ padding: '0', color: '#667085' }} />;
    },
  },
  {
    field: 'operate_date',
    headerName: 'Operate Date',
    flex: 1.2,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle title={headerName} style={{ paddingLeft: '0' }} />;
    },
    renderCell: (params) => {
      return <TableCell title={params?.value} style={{ padding: '0', color: '#667085' }} />;
    },
  },
  {
    field: 'version',
    headerName: 'Version',
    flex: 1.2,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle title={headerName} style={{ paddingLeft: '0' }} />;
    },
    renderCell: (params) => {
      return <TableCell title={params?.value} style={{ padding: '0', color: '#667085' }} />;
    },
  },
];
