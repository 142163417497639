import { createSlice } from '@reduxjs/toolkit';

import dataStructure from 'constants/options';

import { TInitialState } from './types';
import {
  getEstimateById,
  getEstimateByUuid,
  getEstimates,
  getEstimateSectionItems,
} from './thunks';

const discountData = {
  discount: 0,
  inputValue: '',
};

const initialState: TInitialState = {
  estimateItem: null,
  allEstimatesLimit: 5,
  allEstimatesAsc: true,
  allEstimatesOffset: 0,
  discount: discountData,
  estimateItemError: null,
  allEstimatesError: null,
  discountCouponError: null,
  estimateItemLoading: false,
  allEstimatesLoading: false,
  allEstimates: dataStructure,
  estimateSectionItemsLoader: false,
  allEstimatesFromTemplateLimit: 5,
  allEstimatesFromTemplateOffset: 0,
};

const estimateSlice = createSlice({
  name: 'estimateSlice',
  reducers: {
    setPage(state, action) {
      state.allEstimatesOffset = (action.payload - 1) * state.allEstimatesLimit;
    },
    setLimit(state, action) {
      state.allEstimatesLimit = action.payload;
    },
    setDiscount(state, action) {
      state.discount = action.payload;
      state.discountCouponError = null;
    },
    setDiscountError(state) {
      state.discountCouponError =
        "The coupon doesn't exist, or there may be an issue with the date or activation. Please double-check";
    },
    clearDiscountError(state) {
      state.discountCouponError = null;
    },
  },
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getEstimates.pending, (state) => {
        state.allEstimatesLoading = true;
        state.allEstimatesError = null;
      })
      .addCase(getEstimates.fulfilled, (state, action) => {
        state.allEstimatesLoading = false;
        state.allEstimates = action.payload;
        state.allEstimatesAsc = !state.allEstimatesAsc;
      })
      .addCase(getEstimates.rejected, (state, action) => {
        state.allEstimatesLoading = false;
        state.allEstimatesError = action.error.message as string;
      })
      .addCase(getEstimateSectionItems.pending, (state) => {
        state.estimateSectionItemsLoader = true;
      })
      .addCase(getEstimateSectionItems.fulfilled, (state) => {
        state.estimateSectionItemsLoader = false;
      })
      .addCase(getEstimateSectionItems.rejected, (state) => {
        state.estimateSectionItemsLoader = false;
      })
      .addCase(getEstimateByUuid.pending, (state) => {
        state.estimateItemLoading = true;
        state.estimateItemError = null;
      })
      .addCase(getEstimateByUuid.fulfilled, (state, action) => {
        state.estimateItemLoading = false;
        state.estimateItem = action.payload;
      })
      .addCase(getEstimateByUuid.rejected, (state, action) => {
        state.estimateItemLoading = false;
        state.estimateItemError = action.error.message as string;
      })
      .addCase(getEstimateById.pending, (state) => {
        state.estimateItemLoading = true;
        state.estimateItemError = null;
      })
      .addCase(getEstimateById.fulfilled, (state, action) => {
        state.estimateItemLoading = false;
        state.estimateItem = action.payload;
      })
      .addCase(getEstimateById.rejected, (state, action) => {
        state.estimateItemLoading = false;
        state.estimateItemError = action.error.message as string;
      });
  },
});

export default estimateSlice.reducer;

export const { setPage, setLimit, setDiscount, setDiscountError, clearDiscountError } =
  estimateSlice.actions;
