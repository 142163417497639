import { TextField } from '@mui/material';
import { FC } from 'react';
import { Controller, useController } from 'react-hook-form';

import styles from './ReportsTextField.module.scss';
import textFieldStyles from './options';
import { TReportsTextFieldProps } from './types';

const ReportsTextField: FC<TReportsTextFieldProps> = ({ control }) => {
  const {
    formState: { errors },
  } = useController({ name: 'report', control });

  return (
    <Controller
      control={control}
      name='report'
      render={({ field }) => (
        <TextField
          multiline
          error
          focused
          helperText={errors?.report?.message}
          rows={4.6}
          value={field?.value}
          variant='filled'
          onChange={field?.onChange}
          onBlur={field?.onBlur}
          sx={textFieldStyles}
          className={styles.container}
          label='Report something else?*'
          placeholder='Write Something Here...'
        />
      )}
    />
  );
};

export default ReportsTextField;
