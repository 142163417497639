import { GridColDef } from '@mui/x-data-grid';

import { TableCell } from 'components';
import HeaderTitle from 'components/tables/EmployeeTable/HeaderTitle';

export const estimateColumns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Estimate Name',
    flex: 1.2,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle title={headerName} style={{ paddingLeft: '0' }} />;
    },
    renderCell: (params) => {
      return <TableCell title={params?.value} style={{ padding: '0' }} />;
    },
  },
  {
    field: 'number',
    headerName: 'Estimate No',
    flex: 1,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle style={{ paddingLeft: '0' }} title={headerName} />;
    },
    renderCell: (params) => {
      return <TableCell title={params?.value} style={{ paddingLeft: '0' }} />;
    },
  },
  {
    field: 'customer_name',
    headerName: 'Customer',
    flex: 1,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle style={{ paddingLeft: '0' }} title={headerName} />;
    },
    renderCell: (params) => {
      return <TableCell style={{ padding: '0' }} title={params?.value} />;
    },
  },
  {
    field: 'created_by_name',
    headerName: 'Created By',
    flex: 1,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle style={{ paddingLeft: '0' }} title={headerName} />;
    },
    renderCell: (params) => {
      return <TableCell style={{ padding: '0' }} title={params?.value} />;
    },
  },
  {
    field: 'created_at',
    headerName: 'Created Date',
    flex: 1,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle style={{ paddingLeft: '0' }} title={headerName} />;
    },
    renderCell: (params) => {
      return <TableCell style={{ padding: '0' }} title={params?.value} />;
    },
  },

  {
    field: 'status',
    headerName: 'Status',
    flex: 0.7,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle title={headerName} style={{ paddingLeft: '0' }} />;
    },
    renderCell: (params) => {
      return params.value;
    },
  },

  {
    field: 'options',
    headerName: '',
    width: 50,
    renderCell: (params) => {
      return params.value;
    },
  },
];
