import { FC } from 'react';
import { Box } from '@mui/material';

import { ImgUrlFolders } from 'types';
import { CustomTypography } from 'components';
import PreloadedImg from 'components/views/PreloadedImg';

import styles from './CatalogCellCard.module.scss';

import type { TUserCardProps } from './types';

const CatalogCellCard: FC<TUserCardProps> = ({ img, userName, width = 40, height = 40 }) => {
  return (
    <Box className={styles.container}>
      <div style={{ minWidth: width, maxWidth: width }}>
        <PreloadedImg
          width={width}
          height={height}
          withCashing={false}
          border='1px solid #878787'
          imgId={img as string}
          folderName={ImgUrlFolders.CATALOG}
        />
      </div>

      <CustomTypography className={styles.container__name}>{userName}</CustomTypography>
    </Box>
  );
};

export default CatalogCellCard;
