import { FC } from 'react';
import Image from 'rc-image';

import { TLazyImageProps } from './types';

const LazyImage: FC<TLazyImageProps> = ({
  src,
  alt,
  width,
  height,
  objectFit = 'cover',
  ...rest
}) => <Image src={src} {...rest} alt={alt} width={width} height={height} style={{ objectFit }} />;

export default LazyImage;
