import { ChangeEvent, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { GridColDef, GridEventListener } from '@mui/x-data-grid';
import { Navigate, useNavigate } from 'react-router-dom';
import classNames from 'classnames';

import {
  Button,
  DeletePopup,
  ScrollLayout,
  PortalDropDown,
  CustomDataGrid,
  CustomTypography,
  CustomBreadcrumbs,
  DynamicPaginationControl,
  StatusInfo,
} from 'components';
import { Colors, FontNames, Routes } from 'types';
import { MoreIcon, NewPlusIcon } from 'assets/icons';
import { useAppDispatch, useAppSelector } from 'hooks';
import { setWorkOrderPage, setWorkOrderLimit } from 'store/slices/workOrderSlice';
import AddWorkOrder from 'containers/AddWorkOrder';
import { EmptyTitles } from 'constants/EmptyTitles';
import {
  userAllRolesLoadingSelector,
  userPermissionsSelector,
} from 'store/slices/authSlice/selectors';
import { PermissionMessages } from 'constants/PermissionMessages';
import {
  allWorkOrdersAsc,
  allWorkOrdersLimitSelector,
  allWorkOrdersList,
  allWorkOrdersOffsetSelector,
} from 'store/slices/workOrderSlice/selectors';
import { TWorkOrder, WorkOrdersSortBy } from 'store/slices/workOrderSlice/types';
import { getAllWorkOrders, removeWorkOrderById } from 'store/slices/workOrderSlice/thunks';
import { StatusEnums } from 'components/shared/StatusInfo/types';

import { dropDownOptionsCreator } from './WorkOrders';
import { breadCrumbsOptions, columns } from './utils';
import styles from './WorkOrders.module.scss';

const WorkOrder = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isAccessToCreateWorkOrder, isAccessToEditWorkOrder, isAccessToWorkOrdersAndScheduling } =
    useAppSelector(userPermissionsSelector);

  const userAllRolesLoading = useAppSelector(userAllRolesLoadingSelector);

  const allWorkOrders = useAppSelector(allWorkOrdersList);
  const allWorkOrderAsc = useAppSelector(allWorkOrdersAsc);
  const allWorkOrdersLimit = useAppSelector(allWorkOrdersLimitSelector);
  const allWorkOrdersOffset = useAppSelector(allWorkOrdersOffsetSelector);

  const [page, setPage] = useState<number>(1);
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false);
  const [isOpenEditDrawer, setIsOpenEditDrawer] = useState<boolean>(false);
  const [selectedWorkOrder, setselectedWorkOrder] = useState<TWorkOrder | null>(null);

  const [newSortBy, setNewSortBy] = useState<WorkOrdersSortBy | null>(null);

  const handleColumnHeaderClick = (column: GridColDef) => {
    setNewSortBy(column.field === 'id' ? WorkOrdersSortBy.ID : (column.field as WorkOrdersSortBy));

    const sortValue =
      column.field === 'number' ? WorkOrdersSortBy.ID : (column.field as WorkOrdersSortBy);

    dispatch(
      getAllWorkOrders({
        sort_by: sortValue || 'number',
        asc: allWorkOrderAsc,
        limit: allWorkOrdersLimit,
        offset: allWorkOrdersOffset,
      }),
    );
  };

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const renderColumns: GridColDef[] = columns.map((column) => {
    const isColumnSorted =
      column.field === 'number' ? newSortBy === WorkOrdersSortBy.ID : newSortBy === column.field;

    const headerClasses = classNames(
      { [styles.activeSortHeader]: !allWorkOrderAsc },
      {
        [styles.activeSortHeader_asc]: allWorkOrderAsc,
      },
    );

    return {
      ...column,
      sortable: false,
      hideSortIcons: true,
      disableColumnMenu: true,
      disableColumnSelector: true,
      headerClassName: isColumnSorted && column?.field ? headerClasses : '',
    };
  });

  const openDrawer = () => setIsOpenDrawer(true);

  const getSelectedItem = (item: TWorkOrder) => setselectedWorkOrder(item);

  const handlePageChange = (_event: unknown, newPage: number) => {
    setPage(newPage);
    dispatch(setWorkOrderPage(newPage + 1));
  };

  const handleEditVendor = async () => {
    await navigate(`/work-flow/work-order/edit/${selectedWorkOrder?.id as number}`);
  };

  const handleRemoveWorkOrder = async () => {
    await dispatch(removeWorkOrderById(selectedWorkOrder?.id as number));
    setselectedWorkOrder(null);
    setIsPopupOpen(false);
  };

  const handleRowsPerPageChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    handlePageChange(null, 0);
    dispatch(setWorkOrderLimit(event.target.value));
  };

  useEffect(() => {
    dispatch(getAllWorkOrders());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allWorkOrdersLimit, allWorkOrdersOffset]);

  const dropDownOptions = dropDownOptionsCreator({
    removeCallback: togglePopup,
    // createPOCallback: handleCreatePO,
    editCallback: handleEditVendor,
  });

  const renderRows = allWorkOrders?.data?.map((row) => {
    const renderStatus = row.status === 'completed' ? StatusEnums.COMPLETE : row.status;
    return {
      id: row.id,
      number: `V-${row?.id}`,
      customer_name: row?.customer_name,
      estimate_name: row.estimate_name,
      supervisor_name: row.supervisor_name,
      start_date: row.start_date,
      end_date: row.end_date,
      status: <StatusInfo status={renderStatus?.toLowerCase() as StatusEnums} title={row.status} />,
      options: isAccessToEditWorkOrder && (
        <PortalDropDown
          selectedItem={row}
          selectedId={row?.id}
          title={<MoreIcon />}
          options={dropDownOptions}
          getSelectedItem={getSelectedItem}
        />
      ),
    };
  });
  const goToRowPageGenerate = (id: string) => navigate(`/work-flow/work-order/view/${id}`);

  const handleEvent: GridEventListener<'rowClick'> = async (params) => {
    // if (haveAccess) {
    goToRowPageGenerate(params?.row?.id);
    // }
  };
  useEffect(() => {
    if (!allWorkOrders?.total_count && page) {
      setPage(page - 1);
      handlePageChange(null, page - 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allWorkOrders?.total_count]);

  if (!isAccessToWorkOrdersAndScheduling && !userAllRolesLoading) {
    return <Navigate replace to={Routes.ActivityFeed} />;
  }

  return (
    <>
      <Box className={styles.container}>
        <CustomBreadcrumbs options={breadCrumbsOptions} />
        <Box className={styles.container__head}>
          <CustomTypography className={styles.container__head__title}>Work Orders</CustomTypography>
          {isAccessToCreateWorkOrder && (
            <Button
              color='white'
              fontSize='14px'
              maxWidth='167px'
              borderRadius='5px'
              padding='10px 6px'
              isUppercase={false}
              tooltipMessage={
                !isAccessToCreateWorkOrder ? PermissionMessages.WorkOrderCreatingMessage : undefined
              }
              onClick={openDrawer}
              backgroundColor={Colors.SAPPHIRE}
              disabled={!isAccessToCreateWorkOrder}
              fontFamily={FontNames.CIRCULAR_REG}
              startIcon={<NewPlusIcon width={24} height={24} />}
              className={styles.container__header__create__button}
            >
              Create WorkOrder
            </Button>
          )}
        </Box>

        <Box className={styles.container__main}>
          <ScrollLayout>
            <CustomDataGrid
              rowHeight={64}
              headerHeight={44}
              rows={renderRows}
              columns={renderColumns}
              emptyTitle={EmptyTitles.WorkOrder}
              onColumnHeaderClick={handleColumnHeaderClick}
              onRowClick={handleEvent}
            />
          </ScrollLayout>
        </Box>
        <Box className={styles.container__footer}>
          {!!allWorkOrders?.data?.length && (
            <DynamicPaginationControl
              page={page}
              rowsPerPage={allWorkOrdersLimit}
              count={allWorkOrders?.total_count}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
            />
          )}
        </Box>
      </Box>
      <AddWorkOrder isOpen={isOpenDrawer} setIsOpen={setIsOpenDrawer} />
      <AddWorkOrder isOpen={isOpenEditDrawer} setIsOpen={setIsOpenEditDrawer} />

      <DeletePopup
        withBody
        isOpen={isPopupOpen}
        onClose={togglePopup}
        title='Archive Confirmation'
        onDelete={handleRemoveWorkOrder}
        body='Please confirm if you wants to archive Work Order.'
      />
    </>
  );
};

export default WorkOrder;
