import { useState } from 'react';
import { AxiosError } from 'axios';

import { createUserRoleRequest, deleteUserRoleRequest, getUsersByRoleRequest } from 'api/auth';
import { getAllRolesRequest } from 'api/roles';
import { BrowserStorageKeys, BrowserStorageService } from 'services';

import { TUseUserRolesByIdParams, TUseUserRolesDataItem, TUserRoleById } from './types';

const currentOrganization = BrowserStorageService.get(BrowserStorageKeys.CurrentOrganizationId);

const useUserRolesById = ({ user }: TUseUserRolesByIdParams) => {
  const [data, setData] = useState<TUseUserRolesDataItem[]>([]);
  const [dataRemovable, setDataRemovable] = useState<TUserRoleById[]>([]);
  const [isLoad, setIsLoad] = useState<boolean>(false);
  const [error, setError] = useState<AxiosError | null>(null);

  const getUserRoleById = async (id: number, email: string) => {
    try {
      setIsLoad(true);
      const response = await getAllRolesRequest('organization');

      await setTheData(response?.data, id, email);

      setData(response.data);

      setIsLoad(false);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      setError(Error);
      setIsLoad(false);
    }
  };

  const setTheData = async (data: TUseUserRolesDataItem[], id: number, email: string) => {
    try {
      const createdId = id as number;

      const test = [] as any[];
      let operationsExecuted = false;

      const promises = data?.map(async (role) => {
        if (role?.id === id || operationsExecuted) {
          return null;
        }

        const roleId = role?.id;
        const responseNew = await getUsersByRoleRequest(roleId);

        const removeAbelData = responseNew?.data as TUserRoleById[];

        if (removeAbelData?.length) {
          setDataRemovable((prev: TUserRoleById[]) =>
            [...prev, ...removeAbelData]?.filter((user) => user?.email === email),
          );

          test.push(...removeAbelData.filter((user) => user?.email === email));
        }

        return test;
      });

      await Promise.all(promises);

      if (test?.length && !operationsExecuted) {
        const dataRemovableIds = test?.map((role) => role?.id);
        await deleteUserRoleRequest(dataRemovableIds);

        await createUserRoleRequest([
          {
            role_id: createdId,
            user_id: user.id,
            org_id: Number(currentOrganization),
          },
        ]);

        operationsExecuted = true;
      }
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  };

  return {
    data,
    error,
    isLoad,
    dataRemovable,
    getUserRoleById,
  };
};

export default useUserRolesById;
