import { Box, Stack } from '@mui/material';
import { FC } from 'react';
import { FieldError, useFieldArray } from 'react-hook-form';

import { Button, CustomSkeleton } from 'components/shared';
import ControlledInput from 'components/views/ControlledInput';
import { FilledPlusIcon, MinusBlackIcon } from 'assets/icons';
import { Colors, FontNames } from 'types';
import { useAppDispatch } from 'hooks';
import { removeGeneralInformation } from 'store/thunks';
import ControlledSelect from 'components/views/ControlledSelect';

import SectionHead from '../../SectionHead';

import styles from './GeneralInformation.module.scss';
import { TGeneralInformationProps } from './types';
import { generalInfoOptions } from './utils';

const GeneralInformation: FC<TGeneralInformationProps> = ({
  control,
  errors,
  isLoading = false,
}) => {
  const dispatch = useAppDispatch();

  const { fields, append, remove } = useFieldArray({ control, name: 'generalInformation' });

  const handleRemoveInfo = async (idx: number, uniqueId: number) => {
    if (uniqueId) {
      await dispatch(removeGeneralInformation(uniqueId));
    }

    remove(idx);
  };

  const fieldItems = fields?.map((item, idx) => {
    const typeError = errors?.generalInformation?.[idx]?.type as FieldError;

    return (
      <Box key={item?.id} className={styles.container__content__item}>
        <Box className={styles.container__content__item}>
          <ControlledInput
            required
            label='Field Name'
            control={control}
            placeholder='Field Name:'
            borderRadius='8px'
            borderColor={Colors.SOFT_SILVER}
            fontFamily={FontNames.CIRCULAR_REG}
            backgroundColor={Colors.LIGHT_SILVER}
            labelColor={Colors.ENIGMATIC_MIDNIGHT}
            name={`generalInformation[${idx}].name`}
            helperText={errors?.generalInformation?.[idx]?.name?.message}
            error={errors?.generalInformation?.[idx]?.name}
          />
          <ControlledSelect
            control={control}
            label='Field Type:*'
            borderRadius='8px'
            errors={!!typeError}
            options={generalInfoOptions}
            helperText={typeError?.message}
            fontFamily={FontNames.CIRCULAR_REG}
            backgroundColor={Colors.LIGHT_SILVER}
            labelColor={Colors.ENIGMATIC_MIDNIGHT}
            name={`generalInformation[${idx}].type`}
            border={`1px solid ${Colors.SOFT_SILVER}`}
          />

          <ControlledInput
            required
            label='Field Value'
            control={control}
            borderRadius='8px'
            placeholder='Field Value:'
            borderColor={Colors.SOFT_SILVER}
            fontFamily={FontNames.CIRCULAR_REG}
            backgroundColor={Colors.LIGHT_SILVER}
            labelColor={Colors.ENIGMATIC_MIDNIGHT}
            name={`generalInformation[${idx}].value`}
            error={errors?.generalInformation?.[idx]?.value}
            helperText={errors?.generalInformation?.[idx]?.value?.message}
          />
        </Box>
        {idx !== 0 && (
          <MinusBlackIcon onClick={() => handleRemoveInfo(idx, Number(item?.uniqueId))} />
        )}
      </Box>
    );
  });

  const fieldItemsSkeletons = Array.from({ length: 2 })?.map((_, idx) => (
    <Stack key={idx} gap='24px' direction='row'>
      <CustomSkeleton width='100%' height={56} borderRadius='4px' />
      <CustomSkeleton width='100%' height={56} borderRadius='4px' />
    </Stack>
  ));

  return (
    <Box className={styles.container}>
      <SectionHead
        title='General Information:'
        subtitle={`(The general information fields appear on the estimate screen to capture custom
        information.)`}
      />

      <Box className={styles.container__content}>
        {isLoading ? fieldItemsSkeletons : fieldItems}
        <Button
          color={Colors.BLACK}
          startIcon={<FilledPlusIcon />}
          isUppercase={false}
          fontFamily={FontNames.CIRCULAR_REG}
          className={styles.container__head__add}
          onClick={() => append({ name: '', type: '', value: '' })}
        >
          Add New General Information
        </Button>
      </Box>
    </Box>
  );
};

export default GeneralInformation;
