import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

import { RootState } from 'types';
import { invitationApi } from 'api';
import { OrganizationMemberToastMessages } from 'constants/ToastMessages';

import { getInvitedUsers } from '../organizationsSlice/thunks';

import { TCreateInvitation } from './types';

export const createInvitation = createAsyncThunk(
  'invitationSlice/createInvitation',
  async (options: TCreateInvitation[], { dispatch, getState }) => {
    const start = toast.loading(OrganizationMemberToastMessages.INVITATION_SEND_START);

    const {
      organizations: { pendingUsersLimit, pendingUsersOffset },
    } = getState() as RootState;

    try {
      const response = await invitationApi.createInvitationRequest(options);

      toast.update(start, {
        render: OrganizationMemberToastMessages.INVITATION_SEND_SUCCESS,
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });
      dispatch(getInvitedUsers({ limit: pendingUsersLimit, offset: pendingUsersOffset }));

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      toast.update(start, {
        render: OrganizationMemberToastMessages.INVITATION_SEND_FAIL,
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });

      throw Error;
    }
  },
);
