import { BaseSyntheticEvent, FC, SyntheticEvent, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Menu, MenuItem } from '@mui/material';

import { useAppSelector } from 'hooks';
import { ImgUrlFolders } from 'types';
import { MoreIcon } from 'assets/icons';
import { CustomTypography } from 'components';
import DeletePopup from 'components/views/DeletePopup';
import PreloadedImg from 'components/views/PreloadedImg';
import { sxMenuShareStyles, sxMenuStyles } from 'containers/Catalog/styles';
import { userPermissionsSelector } from 'store/slices/authSlice/selectors';
import ShareCatalogDropDown from 'components/dropdowns/ShareCatalogDropDown';
import { generateInventoryItemNumber } from 'utils/generateInventoryItemNumber';

import { TCatalogCardProps } from './types';
import styles from './CatalogCard.module.scss';
import { menuDropDownItems } from './CatalogCard.utils';
import { deleteCatalogText } from './constants';

const CatalogCard: FC<TCatalogCardProps> = ({
  id,
  name,
  uuid,
  onDelete,
  created_at,
  created_by,
  image_url_id,
  seeCatalogInfo,
  number_of_items,
  openDrawerInEditMode,
}) => {
  const navigate = useNavigate();

  const { isAccessToCatalogsEditor, isAccessToInventoryEditor } =
    useAppSelector(userPermissionsSelector);

  const haveAccessForActions = isAccessToCatalogsEditor && isAccessToInventoryEditor;

  const [anchorEl, setAnchorEl] = useState(null);
  const [isShareOpen, setIsShareOpen] = useState<boolean>(false);
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const [removedUsersIds, setRemovedUsersIds] = useState<number[]>([]);
  const [selectedItemId, setSelectedItemId] = useState<number | null>(null);

  const menuRef = useRef<HTMLDivElement | null>(null);

  const date = new Date(created_at);

  const day = date.getUTCDate();
  const year = date.getUTCFullYear();
  const month = date.getUTCMonth() + 1;

  const formattedCreatedDate = `${day}/${month}/${year}`;
  const firstName = created_by?.split(' ');

  const renderMenuClasses = isShareOpen ? sxMenuShareStyles : sxMenuStyles;

  const handleClose = () => {
    if (!isShareOpen) {
      setAnchorEl(null);
    }

    setIsShareOpen(false);
  };

  const handleSeeInfo = (id: number) => {
    seeCatalogInfo(id);
  };

  const seeCatalogData = (uuid: string) => {
    navigate(`/product-info/catalog/${uuid}`);
  };

  const togglePopup = () => setIsPopupOpen(!isPopupOpen);

  const handleOpenDrawer = (id: number) => {
    openDrawerInEditMode?.(id);
    handleClose();
  };

  const dropdownItems = menuDropDownItems({
    removeAction: () => togglePopup(),
    editAction: () => handleOpenDrawer(id),
    shareAction: () => setIsShareOpen(true),
    seeDetailsAction: () => handleOpenDrawer?.(id),
  });

  const renderMenuItem = dropdownItems.map((item, idx) => {
    const onClick = () => {
      selectedItemId && item.paramsAction();
      !item.isNotClosable && handleClose();
    };

    return (
      <MenuItem key={idx} onClick={onClick}>
        {item.icon}
        {item.label}
      </MenuItem>
    );
  });

  const renderNumber = generateInventoryItemNumber(id as number);

  const stopPropagation = (event: SyntheticEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  const handleMoreClick = (event: BaseSyntheticEvent, id: number | null) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);

    setSelectedItemId(id);
  };

  return (
    <>
      <Box className={styles.container} onClick={() => seeCatalogData(uuid)}>
        <Box className={styles.container__wrapper}>
          <Box className={styles.container__wrapper__header} onClick={stopPropagation}>
            <CustomTypography className={styles.container__wrapper__header__date}>
              {formattedCreatedDate}
            </CustomTypography>
            {haveAccessForActions && (
              <MoreIcon onClick={(event: BaseSyntheticEvent) => handleMoreClick(event, id)} />
            )}
            <Menu
              ref={menuRef}
              anchorEl={anchorEl}
              onClose={handleClose}
              sx={renderMenuClasses}
              open={Boolean(anchorEl)}
            >
              {isShareOpen ? (
                <ShareCatalogDropDown
                  onClose={handleClose}
                  setIsOpen={setIsShareOpen}
                  removedUsersIds={removedUsersIds}
                  sharedId={selectedItemId as number}
                  setRemovedUsersIds={setRemovedUsersIds}
                />
              ) : (
                renderMenuItem
              )}
            </Menu>
          </Box>
          <Box className={styles.container__wrapper__content}>
            <PreloadedImg
              width={40}
              height={40}
              folderName={ImgUrlFolders.CATALOG}
              imgId={image_url_id}
            />

            <p
              className={styles.container__wrapper__content__name}
              role='button'
              onClick={() => handleSeeInfo(id)}
            >
              {name}
            </p>
            <CustomTypography className={styles.container__wrapper__content__number}>
              {renderNumber}
            </CustomTypography>
          </Box>
        </Box>
        <Box className={styles.container__footer}>
          <Box className={styles.container__footer__row}>
            <CustomTypography className={styles.container__footer__row__name}>
              No of items
            </CustomTypography>
            <CustomTypography className={styles.container__footer__row__name}>
              {number_of_items ? number_of_items : 0}
            </CustomTypography>
          </Box>
          <Box className={styles.container__footer__row}>
            <CustomTypography className={styles.container__footer__row__name}>
              Created by:
            </CustomTypography>
            <div
              className={styles.container__footer__row__name_slice}
              title={`${firstName?.[0]} ${firstName?.[1]}`}
            >
              {firstName ? firstName[0] : created_by || '--'}
            </div>
          </Box>
        </Box>
      </Box>
      <DeletePopup
        withBody
        onDelete={onDelete}
        onClose={togglePopup}
        isOpen={isPopupOpen}
        title='Delete Confirmation'
        body={deleteCatalogText}
      />
    </>
  );
};

export default CatalogCard;
