import type { RootState } from 'types';

export const estimatesSelector = (state: RootState) => state.estimates;

export const allEstimatesSelector = (state: RootState) => state.estimates.allEstimates;

export const allEstimatesLoadingSelector = (state: RootState) =>
  state.estimates.allEstimatesLoading;

export const allEstimatesErrorSelector = (state: RootState) => state.estimates.allEstimatesError;

export const allEstimatesLimitSelector = (state: RootState) => state.estimates.allEstimatesLimit;

export const allEstimatesAscSelector = (state: RootState) => state.estimates.allEstimatesAsc;

export const allEstimatesOffsetSelector = (state: RootState) => state.estimates.allEstimatesOffset;

export const estimateItemSelector = (state: RootState) => state.estimates.estimateItem;

export const estimateItemLoadingSelector = (state: RootState) =>
  state.estimates.estimateItemLoading;

export const estimateItemErrorSelector = (state: RootState) => state.estimates.estimateItemError;

export const estimateDiscountSelector = (state: RootState) => state.estimates.discount;

export const allEstimatesFromTemplateLimitSelector = (state: RootState) =>
  state.estimates.allEstimatesFromTemplateLimit;

export const allEstimatesFromTemplateOffsetSelector = (state: RootState) =>
  state.estimates.allEstimatesFromTemplateOffset;
