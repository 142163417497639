import { GridColDef } from '@mui/x-data-grid';

import { TableCell } from 'components';
import HeaderTitle from 'components/tables/EmployeeTable/HeaderTitle';

export const customerColumns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Name',
    flex: 1,

    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle style={{ padding: 0 }} title={headerName} />;
    },
    renderCell: (params) => {
      return <TableCell style={{ padding: 0 }} title={params?.value} isTooltipDisabled />;
    },
  },
  {
    field: 'email',
    headerName: 'Email',
    flex: 1,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle style={{ padding: '0' }} title={headerName} />;
    },
    renderCell: (params) => params.value,
  },
  {
    field: 'phone_number',
    headerName: 'Phone No',
    flex: 1,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle style={{ paddingLeft: '0' }} title={headerName} />;
    },
    renderCell: (params) => params.value,
  },
  {
    field: 'address',
    headerName: 'Address',
    flex: 1,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle style={{ paddingLeft: '0' }} title={headerName} />;
    },
    renderCell: (params) => {
      return <TableCell style={{ paddingLeft: '0' }} title={params?.value} />;
    },
  },
  {
    field: 'point_of_contact',
    headerName: 'Point Of Contact',
    flex: 1,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle style={{ paddingLeft: '0' }} title={headerName} />;
    },

    renderCell: (params) => <TableCell style={{ paddingLeft: '0' }} title={params.value} />,
  },
  {
    field: 'options',
    headerName: '',
    width: 80,
    renderCell: (params) => {
      return params.value;
    },
  },
];
