import { GridColDef } from '@mui/x-data-grid';

import { TableCell } from 'components';
import HeaderTitle from 'components/tables/EmployeeTable/HeaderTitle';
import { Routes } from 'types';

export const columns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Template Name',
    flex: 1,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle style={{ paddingLeft: '0' }} title={headerName} />;
    },
    renderCell: (params) => {
      return <TableCell style={{ paddingLeft: '0' }} title={params?.value} />;
    },
  },
  {
    field: 'version_tag',
    headerName: 'Version Tag',
    flex: 1,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle style={{ paddingLeft: '0' }} title={headerName} />;
    },
    renderCell: (params) => {
      return <TableCell style={{ paddingLeft: '0' }} title={params?.value} />;
    },
  },
  {
    field: 'created_by',
    headerName: 'Created by',
    flex: 1,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle style={{ paddingLeft: '0' }} title={headerName} />;
    },
    renderCell: (params) => {
      return <TableCell style={{ paddingLeft: '0' }} title={params?.value} />;
    },
  },
  {
    field: 'created_at',
    headerName: 'Created at',
    flex: 1,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle style={{ paddingLeft: '0' }} title={headerName} />;
    },
    renderCell: (params) => {
      return <TableCell style={{ paddingLeft: '0' }} title={params?.value} />;
    },
  },
  {
    field: 'options',
    headerName: '',
    width: 80,
    renderCell: (params) => {
      return params.value;
    },
  },
  {
    field: 'select_options',
    headerName: '',
    flex: 0.2,
    renderCell: (params) => {
      return params.value;
    },
  },
];

export const breadCrumbsOptions = [
  { id: 1, label: 'Inventory', goTo: Routes.Inventory },
  { id: 2, label: 'Pricing Template', goTo: Routes.ExcelTemplate },
];
