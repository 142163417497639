import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

import { Colors, FontNames, Routes } from 'types';
import { BookDemoModal, Button } from 'components';
import { CallNewIcon, LogoIcon } from 'assets/icons';

import styles from './LandingHeader.module.scss';
import HeaderDropDown from './HeaderDropDown/HeaderDropDown';

const LandingHeader = () => {
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const goToHomePage = () => navigate(Routes.Landing);
  const openSignInPage = () => navigate(Routes.SignIn);
  const openSignUpPage = () => navigate(Routes.SignUp);
  const openPricingPage = () => navigate(Routes.Pricing);

  const startBooking = () => setIsOpen(true);
  const endBooking = () => setIsOpen(false);

  const options = [
    { id: 1, name: 'Compare Plans', action: openPricingPage },
    { id: 2, name: 'Book a Demo', action: startBooking },
    { id: 3, name: 'Enroll', action: openSignUpPage },
    { id: 4, name: 'Login', action: openSignInPage },
  ];

  return (
    <>
      <div className={styles.container}>
        <div className={styles.container__content}>
          <button tabIndex={0} onClick={goToHomePage} className={styles.container__content__button}>
            <LogoIcon height={60} className={styles.container__content__icon} aria-label='logo' />
          </button>
          <div className={styles.container__links}>
            <Button
              fontFamily={FontNames.CIRCULAR_MEDIUM}
              className={styles.container__links__text}
              onClick={openPricingPage}
            >
              Compare Plans
            </Button>
            <span className={styles.container__links__divider}>|</span>

            <Button
              fontFamily={FontNames.CIRCULAR_MEDIUM}
              className={styles.container__links__text}
              onClick={startBooking}
            >
              Request Demo
            </Button>
            <span className={styles.container__links__divider}>|</span>
            <button className={styles.container__phone} tabIndex={0} aria-label='phon-number'>
              <CallNewIcon />
              <a href='tel:+703-456-7890'>703-456-7890</a>
            </button>
            <Button
              fontFamily={FontNames.CIRCULAR_MEDIUM}
              className={styles.container__links__signin}
              backgroundColor={Colors.SAPPHIRE}
              onClick={openSignInPage}
            >
              Login
            </Button>
          </div>
          <HeaderDropDown options={options} />
        </div>
      </div>

      <BookDemoModal isOpen={isOpen} onClose={endBooking} />
    </>
  );
};

export default LandingHeader;
