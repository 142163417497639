import { createSlice } from '@reduxjs/toolkit';

import dataStructure from 'constants/options';

import { TInitialState } from './types';
import { createTeam, getAllTags, getUserTeams } from './thunks';

const initialState: TInitialState = {
  allTeamsLimit: 5,
  allTeamsOffset: 0,
  allTeamsError: null,
  allTeamsLoading: false,
  allTeams: dataStructure,
  allTags: dataStructure,
  allTagsLoading: false,
  allTagsErrors: null,
};

const teamsSlice = createSlice({
  name: 'teamsSlice',
  reducers: {
    clearTeamsState: (state) => {
      state.allTeams = dataStructure;
    },
    setPage(state, action) {
      state.allTeamsOffset = (action.payload - 1) * state.allTeamsLimit;
    },
    setLimit(state, action) {
      state.allTeamsLimit = action.payload;
    },
  },
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getUserTeams.pending, (state) => {
        state.allTeamsError = null;
        state.allTeamsLoading = true;
      })
      .addCase(getUserTeams.fulfilled, (state, action) => {
        state.allTeamsError = null;
        state.allTeamsLoading = false;
        state.allTeams = action.payload;
      })
      .addCase(getUserTeams.rejected, (state, action) => {
        state.allTeamsLoading = false;
        state.allTeamsError = action.error.message as string;
      })
      .addCase(createTeam.fulfilled, (state) => {
        state.allTeamsError = null;
        state.allTeamsLoading = false;
      })
      .addCase(getAllTags.pending, (state) => {
        state.allTagsErrors = null;
        state.allTagsLoading = true;
      })
      .addCase(getAllTags.fulfilled, (state, action) => {
        state.allTagsErrors = null;
        state.allTagsLoading = false;
        state.allTags = action.payload;
      })
      .addCase(getAllTags.rejected, (state, action) => {
        state.allTagsLoading = false;
        state.allTagsErrors = action.error.message as string;
      });
  },
});

export const { setPage, setLimit, clearTeamsState } = teamsSlice.actions;

export default teamsSlice.reducer;
