import { FC } from 'react';
import { Box } from '@mui/material';
import { FieldErrors } from 'react-hook-form';

import { CompanyFileInput, ControlledInput, CustomSkeleton } from 'components';
import { CameraIcon } from 'assets/icons';
import { Colors, FontNames } from 'types';

import { TCatalogFormData } from '../../types';

import { TCatalogInfoProps } from './types';
import styles from './CatalogInfo.module.scss';
import { catalogInfoOptions } from './CatalogInfo.utils';

const CatalogInfo: FC<TCatalogInfoProps> = ({
  errors,
  imgUrl,
  control,
  getFile,
  getImgSrc,
  setIsDirty,
  isLoading = false,
  getSetIsRemovedFileState,
}) => {
  const catalogInfoFields = catalogInfoOptions.map(({ isInput, errorName, ...field }) => {
    if (isInput) {
      return (
        <ControlledInput
          {...field}
          key={field?.id}
          control={control}
          error={!!errors?.[errorName as keyof FieldErrors<TCatalogFormData>]}
        />
      );
    } else {
      return (
        <ControlledInput
          {...field}
          key={field?.id}
          control={control}
          multiline={true}
          rows={field?.rows}
          error={!!errors?.[errorName as keyof FieldErrors<TCatalogFormData>]}
        />
      );
    }
  });

  const textFieldSkeletons = Array.from({ length: 3 })?.map((_, idx) => (
    <CustomSkeleton key={idx} height={72} width='100%' borderRadius='4px' />
  ));

  return (
    <Box className={styles.container}>
      <Box className={styles.container__content}>
        {isLoading ? textFieldSkeletons : catalogInfoFields}
      </Box>
      {isLoading ? (
        <CustomSkeleton height={242} width='100%' borderRadius='4px' />
      ) : (
        <CompanyFileInput
          imgSrc={imgUrl}
          withController
          control={control}
          title='Image:'
          getFile={getFile}
          getImgSrc={getImgSrc}
          maxWidth='264px'
          setIsDirty={setIsDirty}
          icon={<CameraIcon />}
          withBottomBlock={false}
          containerMaxWidth='264px'
          borderColor={Colors.SOFT_SILVER}
          fontFamily={FontNames.CIRCULAR_REG}
          containerClassName={styles.container__file}
          getSetIsRemovedState={getSetIsRemovedFileState}
          containerBoxClassName={styles.container__file__content}
        />
      )}
    </Box>
  );
};

export default CatalogInfo;
