import { type FC, useEffect, useState, useCallback } from 'react';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { Colors, Routes } from 'types';
import { AddIcon } from 'assets/icons';
import { getCurrentUser } from 'store/thunks';
import { useAppDispatch, useAppSelector } from 'hooks';
import { setPage } from 'store/slices/requisitionSlices';
import { userPermissionsSelector } from 'store/slices/authSlice/selectors';
import { Button, CustomTypography, CustomBreadcrumbs } from 'components';
import { PermissionMessages } from 'constants/PermissionMessages';

import { breadCrumbOptions } from './utils';
import { TPurchaseRequisitionHeaderProps } from './types';
import styles from './PurchaseRequisitionHeader.module.scss';

const PurchaseRequisitionHeader: FC<TPurchaseRequisitionHeaderProps> = ({
  renderedRequisitions,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState<number>(0);

  const createNew = () => navigate(Routes.CreateRequisition);

  const { isAccessToInventoryEditor } = useAppSelector(userPermissionsSelector);

  useEffect(() => {
    dispatch(getCurrentUser());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!renderedRequisitions?.data?.length && currentPage) {
      setCurrentPage(currentPage - 1);
      handlePageChange(null, currentPage - 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [renderedRequisitions?.data?.length]);

  useEffect(() => {
    if (!renderedRequisitions?.data?.length && currentPage) {
      setCurrentPage(currentPage - 1);
      handlePageChange(null, currentPage - 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [renderedRequisitions?.data?.length]);

  const handlePageChange = useCallback((_event: unknown, newPage: number) => {
    setCurrentPage(newPage);
    dispatch(setPage(newPage + 1));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box className={styles.container}>
      <Box className={styles.container__routing}>
        <Box className={styles.container__routing__pages}>
          <CustomBreadcrumbs options={breadCrumbOptions} />
        </Box>
      </Box>
      <Box className={styles.container__main}>
        <CustomTypography className={styles.container__main__title}>
          Purchase Requisition
        </CustomTypography>

        <Button
          color='white'
          variant='contained'
          onClick={createNew}
          tooltipMessage={
            !isAccessToInventoryEditor
              ? PermissionMessages.RequisitionItemsCreationMessage
              : undefined
          }
          disabled={!isAccessToInventoryEditor}
          backgroundColor={Colors.SAPPHIRE}
          startIcon={<AddIcon width={20} height={20} />}
          className={styles.container__main__add}
        >
          Create Requisition
        </Button>
      </Box>
    </Box>
  );
};

export default PurchaseRequisitionHeader;
