export enum AccountSettingsNavbarItems {
  PLANS = 'plans',
  PROFILE = 'profile',
  BILLING = 'billing',
  VIDEOS = 'my videos',
  SETTINGS = 'notifications',
  EMPLOYEES = 'employees',
  ORGANIZATION = 'organization',
  PERMISSIONS = 'permissions',
  PAYMENTONBOARDING = 'Payment',
}

export enum MyItems {
  VIDEOS = 'my videos',
  ACTIVITY_FEED = 'my activity feed',
}

export type TAccountSettingsNavbarItems = {
  id: number;
  content: JSX.Element;
  title: AccountSettingsNavbarItems;
};
