import { Employees, Permissions } from 'components/views/AccountSettingsComponents';
import PurchaseRequisitionItem from 'containers/PurchaseRequisition/PurchaseRequisitionItem';
import Invoice from 'containers/Invoice';
import BomEstimateItem from 'containers/BomEstimateItem';
import BomEstimateviatemplateView from 'containers/BomEstimateviatemplateView';
import EditWorkOrder from 'containers/EditWorkOrder';

import { employeeManagementNavigateOptions } from '../constants';
import {
  Team,
  Error,
  SignIn,
  SignUp,
  Profile,
  Pricing,
  Landing,
  Catalog,
  Customer,
  WorkOrder,
  Vendors,
  Documents,
  Transactions,
  Inventory,
  LiveStream,
  BomEstimate,
  ProcessForm,
  Invitations,
  DocumentItem,
  ActivityFeed,
  PurchaseOrder,
  ExcelTemplate,
  LiveStreamJoin,
  LiveStreamPage,
  AccountSettings,
  GenerateEstimate,
  ViewWorkOrderForm,
  SignUpSuccessPage,
  CreateRequisition,
  OrganizationsList,
  PurchaseOrderItems,
  DocumentItemSection,
  CatalogSectionItems,
  CreateWorkOrderForm,
  PurchaseRequisition,
  BomEstimateviatemplate,
  BomEstimateviatemplateForm,
  UpdateBomEstimatetemplateForm,
  UpdateBomEstimateCostForm,
  Scheduling,
} from '../containers';
import { RoutesProps, Routes } from '../types';

const routes: RoutesProps[] = [
  {
    isLanding: false,
    withNavbar: false,
    path: Routes.Error,
    isProtected: true,
    component: <Error />,
    withLogoHeader: false,
  },
  {
    path: Routes.Invoice,
    withNavbar: true,
    isProtected: true,
    component: <Invoice />,
  },
  {
    isLanding: false,
    withNavbar: true,
    isProtected: true,
    withLogoHeader: false,
    withTabNavigator: true,
    component: <Employees />,
    path: Routes.EmployeeManagement,
    tabNavigatorTitle: 'Employee Management',
    tabNavigatorOptions: employeeManagementNavigateOptions,
  },
  {
    isLanding: false,
    withNavbar: true,
    path: Routes.Permissions,
    isProtected: true,
    withTabNavigator: true,
    tabNavigatorTitle: 'Employee Management',
    tabNavigatorOptions: employeeManagementNavigateOptions,
    component: <Permissions />,
    withLogoHeader: false,
  },
  {
    isLanding: false,
    withNavbar: true,
    isProtected: true,
    withLogoHeader: false,
    withTabNavigator: true,
    path: Routes.Invitations,
    component: <Invitations />,
    tabNavigatorTitle: 'Employee Management',
    tabNavigatorOptions: employeeManagementNavigateOptions,
  },
  {
    isLanding: false,
    withNavbar: false,
    isProtected: true,
    withLogoHeader: false,
    isProtectedWithSession: true,
    component: <OrganizationsList />,
    path: Routes.OrganizationsList,
  },
  {
    isLanding: false,
    withNavbar: false,
    isProtected: true,
    withLogoHeader: true,
    isProtectedWithSession: true,
    component: <SignUpSuccessPage />,
    path: Routes.SignUpSuccessPage,
  },

  {
    path: Routes.SignUp,
    component: <SignIn />,
    isProtected: false,
    withLogoHeader: true,
  },
  {
    path: Routes.CatalogSectionItems,
    component: <CatalogSectionItems />,
    isProtected: true,
    withLogoHeader: false,
    withNavbar: true,
  },
  {
    path: Routes.SignIn,
    withLogoHeader: true,
    isProtected: false,
    component: <SignUp />,
  },
  {
    path: Routes.ProcessForm,
    withLogoHeader: false,
    isProtected: true,
    isProtectedWithSession: true,
    component: <ProcessForm />,
  },

  {
    path: Routes.Profile,
    withLogoHeader: false,
    isProtected: true,
    withNavbar: true,
    component: <Profile />,
  },
  {
    path: Routes.PurchaseOrderItems,
    withLogoHeader: false,
    isProtected: true,
    withNavbar: true,
    component: <PurchaseOrderItems />,
  },
  {
    path: Routes.PurchaseRequisitionItem,
    withLogoHeader: false,
    isProtected: true,
    withNavbar: true,
    component: <PurchaseRequisitionItem />,
  },

  {
    path: Routes.AccountSettings,
    withLogoHeader: false,
    isProtected: true,
    withNavbar: true,
    component: <AccountSettings />,
  },

  {
    path: Routes.ActivityFeed,
    withNavbar: true,
    isProtected: true,
    component: <ActivityFeed />,
  },
  {
    path: Routes.Catalog,
    withNavbar: true,
    isProtected: true,
    component: <Catalog />,
  },

  {
    path: Routes.Customer,
    withNavbar: true,
    isProtected: true,
    component: <Customer />,
  },
  {
    path: Routes.Scheduling,
    withNavbar: true,
    isProtected: true,
    withLogoHeader: false,
    component: <Scheduling />,
  },
  {
    path: Routes.Documents,
    withNavbar: true,
    isProtected: true,
    withLogoHeader: false,
    component: <Documents />,
  },
  {
    path: Routes.Transactions,
    withNavbar: true,
    isProtected: true,
    component: <Transactions />,
  },
  {
    path: Routes.DocumentsItem,
    withNavbar: true,
    isProtected: true,
    withLogoHeader: false,
    component: <DocumentItem />,
  },

  {
    path: Routes.DocumentsItemSection,
    withNavbar: true,
    isProtected: true,
    withLogoHeader: false,
    component: <DocumentItemSection />,
  },
  {
    path: Routes.Team,
    withNavbar: true,
    isProtected: true,
    component: <Team />,
  },
  {
    path: Routes.Inventory,
    withNavbar: true,
    isProtected: true,
    component: <Inventory />,
  },
  {
    path: Routes.Vendors,
    withNavbar: true,
    isProtected: true,
    component: <Vendors />,
  },
  {
    path: Routes.LiveStream,
    withNavbar: true,
    isProtected: true,
    component: <LiveStream />,
  },
  {
    path: Routes.BomEstimate,
    withNavbar: true,
    isProtected: true,
    component: <BomEstimate />,
  },
  {
    path: Routes.BomPricingEstimate,
    withNavbar: true,
    isProtected: true,
    component: <BomEstimateviatemplate />,
  },
  {
    path: Routes.WorkOrder,
    withNavbar: true,
    isProtected: true,
    component: <WorkOrder />,
  },
  {
    path: Routes.CreateWorkOrder,
    withNavbar: true,
    isProtected: true,
    component: <CreateWorkOrderForm />,
  },
  {
    path: Routes.ViewWorkOrder,
    withNavbar: true,
    isProtected: true,
    component: <ViewWorkOrderForm />,
  },
  {
    path: Routes.EditWorkOrder,
    withNavbar: true,
    isProtected: true,
    component: <EditWorkOrder />,
  },
  {
    path: Routes.Requisition,
    withNavbar: true,
    isProtected: true,
    component: <PurchaseRequisition />,
  },
  {
    path: Routes.ExcelTemplate,
    withNavbar: true,
    isProtected: true,
    component: <ExcelTemplate />,
  },
  {
    path: Routes.EstimateViaTemplate,
    withNavbar: true,
    isProtected: true,
    component: <BomEstimateviatemplate />,
  },
  {
    path: Routes.EstimateTemplateForm,
    withNavbar: true,
    isProtected: true,
    component: <BomEstimateviatemplateForm />,
  },
  {
    path: Routes.UpdateEstimateTemplateForm,
    withNavbar: true,
    isProtected: true,
    component: <UpdateBomEstimatetemplateForm />,
  },
  {
    path: Routes.UpdateEstimateCostTemplateForm,
    withNavbar: true,
    isProtected: true,
    component: <UpdateBomEstimateCostForm />,
  },
  {
    path: Routes.EstimateView,
    withNavbar: true,
    isProtected: true,
    component: <BomEstimateviatemplateView />,
  },
  {
    path: Routes.CreateRequisition,
    withNavbar: true,
    isProtected: true,
    component: <CreateRequisition />,
  },
  {
    path: Routes.PurchaseOrder,
    withNavbar: true,
    isProtected: true,
    component: <PurchaseOrder />,
  },

  {
    path: Routes.LiveStreamJoin,
    withNavbar: true,
    isProtected: true,
    component: <LiveStreamJoin />,
  },
  {
    path: Routes.LiveStreamStart,
    withNavbar: true,
    isProtected: true,
    component: <LiveStreamPage />,
  },
  {
    path: Routes.EstimateItem,
    withLogoHeader: false,
    isProtected: true,
    withNavbar: true,
    component: <BomEstimateItem />,
  },
  {
    path: Routes.EstimateItemGenerate,
    withLogoHeader: false,
    isProtected: true,
    withNavbar: true,
    component: <GenerateEstimate />,
  },
  {
    path: Routes.Landing,
    isProtected: false,
    isLanding: true,
    component: <Landing />,
  },

  {
    path: Routes.Pricing,
    isProtected: false,
    isLanding: true,
    component: <Pricing />,
  },
];

export default routes;
