import { FC } from 'react';
import { Box } from '@mui/material';

import { ImgUrlFolders } from 'types';
import { CustomTypography, PortalDropDown } from 'components';
import { DownloadPdfIcon, MoreIcon, PdfIcon } from 'assets/icons';
import usePdfDownload from 'hooks/usePDFDownload';

import { TDocumentSectionCard } from './types';
import styles from './DocumentSectionCard.module.scss';

const DocumentSectionCard: FC<TDocumentSectionCard> = ({ document, link }) => {
  const { fetchDataAndDownload, isStarted } = usePdfDownload({
    fileUrl: link as string,
    folder: ImgUrlFolders.DOCUMENT,
    withToastMessages: true,
  });

  const options = [
    {
      id: 1,
      disabled: isStarted,
      icon: <DownloadPdfIcon />,
      name: 'Download',
      action: fetchDataAndDownload,
    },
  ];

  return (
    <Box className={styles.container}>
      <Box className={styles.container__content}>
        <PdfIcon width={32} height={32} />
        <CustomTypography className={styles.container__content__link}>{document}</CustomTypography>
      </Box>
      <PortalDropDown
        options={options}
        title={<MoreIcon className={styles.container__content__icon} />}
      />
    </Box>
  );
};

export default DocumentSectionCard;
