import { FC } from 'react';
import { Controller } from 'react-hook-form';

import { Select } from 'components';

import type { TTControlledSelectProps } from './types';

const ControlledSelect: FC<TTControlledSelectProps> = ({
  name,
  label,
  control,
  required,
  options,
  border,
  padding,
  icon,
  setValue,
  helperText,
  haveLabel,
  lineHeight,
  fontFamily,
  labelColor,
  borderRadius,
  labelClasses,
  defaultValue,
  backgroundColor,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, value }, formState: { errors } }) => {
        const handleOnChange = (event: any) => {
          onChange(event);
          setValue?.(event.target.value);
        };

        return (
          <Select
            showLabel
            icon={icon}
            label={label}
            value={value}
            border={border}
            maxWidth='100%'
            multiple={false}
            padding={padding}
            options={options}
            required={required}
            withLabel={haveLabel}
            onChange={handleOnChange}
            error={!!errors[name]}
            helperText={helperText}
            lineHeight={lineHeight}
            fontFamily={fontFamily}
            labelColor={labelColor}
            labelClasses={labelClasses}
            defaultValue={defaultValue}
            borderRadius={borderRadius}
            backgroundColor={backgroundColor}
          />
        );
      }}
    />
  );
};

export default ControlledSelect;
