import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

import { landingApi } from 'api';
import { DemoBookingToastMessages } from 'constants/ToastMessages';

import { TBookDemoOptions } from './types';

export const bookDemoSkyHawk = createAsyncThunk(
  'landingSlice/bookDemoSkyHawk',
  async (options: TBookDemoOptions) => {
    const start = toast.loading(DemoBookingToastMessages.BOOKING_IN_PROGRESS);
    try {
      const response = await landingApi.landingRequest(options);

      toast.update(start, {
        render: DemoBookingToastMessages.BOOKING_SUCCESS,
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });

      return response;
    } catch (error) {
      const Error = error as AxiosError;

      toast.update(start, {
        render: DemoBookingToastMessages.BOOKING_FAILURE,
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });

      throw Error;
    }
  },
);
