import { Colors } from 'types';

export type TStatusInfoProps = {
  title: string;
  status: StatusEnums;
} & Partial<{
  color: string | Colors;
}>;

export enum StatusEnums {
  PAID = 'paid',
  DRAFT = 'draft',
  ACTIVE = 'active',
  PENDING = 'pending',
  APPROVED = 'approved',
  INACTIVE = 'inactive',
  CANCELLED = 'cancelled',
  CONFIRMED = 'confirmed',
  COMPLETE = 'complete',
  SUBMITTED = 'submitted',
  SENT_TO_CUSTOMER = 'sent_to_customer',
}
