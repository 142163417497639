export const menuStyles = {
  '& .MuiPaper-root': {
    borderRadius: '10px',

    '@media (max-width:1024px)': {
      left: '150px !important',
    },
    '@media (max-width:900px)': {
      left: '410px !important',
    },
    '@media (max-width:850px)': {
      left: '300px !important',
    },
    '@media (max-width:720px)': {
      left: '180px !important',
    },
    '@media (max-width:610px)': {
      left: '60px !important',
    },
    '@media (max-width:500px)': {
      left: '20px !important',
    },
  },

  '& .MuiList-root': {
    paddingTop: '0px !important',
    paddingBottom: '0px !important',
  },
};
