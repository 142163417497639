import { FC } from 'react';
import { Box } from '@mui/material';
import classNames from 'classnames';

import styles from './QuantityStatus.module.scss';

import type { TQuantityStatusProps } from './types';

const QuantityStatus: FC<TQuantityStatusProps> = ({ quantity }) => {
  const statusClasses = classNames(styles.container__status);

  return (
    <Box className={styles.container}>
      <Box className={statusClasses}>{quantity}</Box>
    </Box>
  );
};

export default QuantityStatus;
