import { useEffect, useState } from 'react';

import { authApi } from 'api';
import { TUserDataByIdResponse } from 'store/slices/authSlice/types';

const useUserInfoById = (userId: number) => {
  const [userData, setUserData] = useState<TUserDataByIdResponse | null>(null);
  const [isLoad, setIsLoad] = useState<boolean>(false);
  const [error, setError] = useState<Error | string | null>(null);

  const getUserDataById = async () => {
    try {
      setIsLoad(true);
      const response = await authApi.getUserByIdRequest(userId);

      setUserData(response?.data);
      setIsLoad(false);
      setError(null);
    } catch (error) {
      setError(error as string);
    }
  };

  useEffect(() => {
    if (userId) {
      getUserDataById();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  return {
    userData,
    isLoad,
    error,
    getUserDataById,
  };
};

export default useUserInfoById;
