import type { RootState } from 'types';

export const allSchedulesList = (state: RootState) => state.scheduling.allSchedules;

export const allSchedulesLoadingSelector = (state: RootState) =>
  state.scheduling.allSchedulesLoading;

export const allSchedulesErrorSelector = (state: RootState) => state.scheduling.allSchedulesError;

export const allSchedulesLimitSelector = (state: RootState) => state.scheduling.allSchedulesLimit;

export const allSchedulesOffsetSelector = (state: RootState) => state.scheduling.allSchedulesOffset;
