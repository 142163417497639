import { GridColDef } from '@mui/x-data-grid';

import { Routes } from 'types';
import { TableCell } from 'components';
import HeaderTitle from 'components/tables/EmployeeTable/HeaderTitle';

export const columns: GridColDef[] = [
  {
    field: 'image',
    headerName: 'Image',
    flex: 0.7,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle style={{ paddingLeft: '0' }} title={headerName} withSortIcons={false} />;
    },
    renderCell: (params) => {
      return (
        <TableCell
          title={params.value}
          isTooltipDisabled={true}
          style={{ width: '80%', height: '90%', padding: '0 !important' }}
        />
      );
    },
  },
  {
    field: 'name',
    headerName: 'Item Name',
    flex: 1,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle withSortIcons={false} style={{ paddingLeft: '0' }} title={headerName} />;
    },
    renderCell: (params) => {
      return (
        <TableCell
          style={{ paddingLeft: '0', color: '#282828', minWidth: '87%' }}
          title={params?.value}
          isTooltipDisabled={true}
        />
      );
    },
  },
  {
    field: 'brand',
    headerName: 'Brand',
    flex: 1,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle withSortIcons={false} style={{ paddingLeft: '0' }} title={headerName} />;
    },
    renderCell: (params) => {
      return (
        <TableCell
          style={{ paddingLeft: '15px', color: '#282828' }}
          title={params?.value}
          isTooltipDisabled={true}
        />
      );
    },
  },
  {
    field: 'manufacturer',
    headerName: 'Manufacturer',
    flex: 1,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle withSortIcons={false} style={{ paddingLeft: '0' }} title={headerName} />;
    },
    renderCell: (params) => {
      return (
        <TableCell
          style={{ paddingLeft: '15px', color: '#282828' }}
          title={params?.value}
          isTooltipDisabled={true}
        />
      );
    },
  },
  {
    field: 'cost',
    headerName: 'Cost',
    flex: 0.5,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle withSortIcons={false} style={{ paddingLeft: '0' }} title={headerName} />;
    },
    renderCell: (params) => {
      return (
        <TableCell
          style={{ paddingLeft: '0', color: '#282828' }}
          title={params?.value}
          isTooltipDisabled={true}
        />
      );
    },
  },
  {
    field: 'quantity',
    headerName: 'Quantity',
    flex: 1.2,
    renderCell: (params) => {
      return (
        <TableCell
          style={{ paddingLeft: '0', color: '#282828' }}
          title={params?.value}
          isTooltipDisabled={true}
        />
      );
    },
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle withSortIcons={false} style={{ paddingLeft: '0' }} title={headerName} />;
    },
  },
  {
    field: 'options',
    headerName: '',
    width: 80,
    renderCell: (params) => {
      return params.value;
    },
  },
];

export const breadCrumbOptions = [
  { id: 1, label: 'Inventory', goTo: Routes.Inventory },
  { id: 2, label: 'Purchase Requisitions' as string, goTo: Routes.Requisition },
  { id: 3, label: 'New Requisition' as string, goTo: Routes.CreateRequisition },
];
