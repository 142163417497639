import { useState, useEffect, useRef } from 'react';
import { FFmpeg } from '@ffmpeg/ffmpeg';
import { fetchFile } from '@ffmpeg/util';
import { isFirefox } from 'react-device-detect';
import { RecordRTCPromisesHandler } from 'recordrtc';

import { LocalForageService } from 'services';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import { LocalForageKeys } from 'services/localForage/types';
import { uploadMediaToStream } from 'store/slices/liveStreamSlice/thunks';
import { myCurrentStreamSelector } from 'store/slices/liveStreamSlice/selector';
import { captureThumb } from 'utils';

import type { TUseRecordingProps } from './types';

const useRecording = ({
  time,
  stream,
  startTimer,
  stopTimer,
  restartTimer,
  pauseTimer,
  contentRef,
}: TUseRecordingProps) => {
  const dispatch = useAppDispatch();

  const currentStream = useAppSelector(myCurrentStreamSelector);

  const [isPaused, setIsPaused] = useState(false);

  const [isRecording, setIsRecording] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState<Blob[]>([]);
  const [isFileCreating, setIsFileCreating] = useState<boolean>(false);
  const [haveActiveRecord, setHaveActiveRecord] = useState<boolean>(false);
  const [recordedVideoFile, setRecordedVideoFile] = useState<File | null>(null);
  const [recordedVideoBlob, setRecordedVideoBlob] = useState<Blob | null>(null);

  const recorderRef = useRef<RecordRTCPromisesHandler | null>(null);
  const ffmpeg = new FFmpeg();

  useEffect(() => {
    if (stream) {
      const audioContext = new AudioContext();
      const audioSources = stream.getAudioTracks().map((track) => {
        const source = audioContext.createMediaStreamSource(new MediaStream([track]));
        return source;
      });

      const mixedOutput = audioContext.createMediaStreamDestination();
      audioSources.forEach((source) => source.connect(mixedOutput));

      const endedStream = [...mixedOutput.stream.getTracks(), ...stream.getVideoTracks()];

      const recorder = new RecordRTCPromisesHandler(new MediaStream(endedStream), {
        type: 'video',
        mimeType: 'video/webm',
      });

      recorderRef.current = recorder;
    }
  }, [stream]);

  const startRecording = async () => {
    startTimer();

    if (isPaused) {
      await recorderRef.current?.resumeRecording();
      startTimer();
      setIsPaused(false);
      setIsRecording(true);
    } else {
      await recorderRef.current?.startRecording();
      setHaveActiveRecord(true);
    }

    setIsRecording(true);
    setIsPaused(false);
  };

  const pauseRecording = async () => {
    if (isRecording) {
      pauseTimer();
      await recorderRef.current?.pauseRecording();
      setIsRecording(false);
      setIsPaused(true);
    }
  };

  const stopRecording = async () => {
    if (!!time && time !== '00:00:00') {
      await recorderRef.current?.stopRecording();

      const file = await recorderRef.current?.getBlob();

      restartTimer();
      stopTimer();

      if (file) {
        const newCreatedFile = await processVideoFile(file);
        setRecordedVideoBlob(file);
        await LocalForageService.set(LocalForageKeys.StreamVideoFiles, newCreatedFile);

        return newCreatedFile;
      }
    }
  };

  const processVideoFile = async (blob: Blob) => {
    setIsFileCreating(true);

    if (isFirefox) {
      const file = new File([blob], 'recorded-vidoe.mp4', { type: blob.type });

      return file;
    } else {
      if (!ffmpeg.loaded) {
        await ffmpeg.load();
      }

      await ffmpeg.writeFile('recording.webm', await fetchFile(blob));

      await ffmpeg.exec(['-i', 'recording.webm', '-c', 'copy', 'output.mp4']);

      const data = await ffmpeg.readFile('output.mp4');
      const processedFile = new File([data], `recorded-${new Date().toISOString()}.mp4`, {
        type: 'video/mp4',
      });

      if (processedFile) {
        setRecordedVideoFile(processedFile);
      }

      setIsFileCreating(false);

      return processedFile;
    }
  };

  const sendVideoToBackend = async (file?: Blob) => {
    const sendedFile = file || recordedVideoFile;

    if (sendedFile) {
      const formData = new FormData();

      formData.append('media', sendedFile);
      formData.append('media_types', 'recording');
      formData.append('livestream_id', String(currentStream?.livestream?.id));

      if (formData) {
        const response = await dispatch(uploadMediaToStream(formData));

        if (response?.meta?.requestStatus === 'fulfilled') {
          setRecordedChunks([]);
          setRecordedVideoFile(null);
          setRecordedVideoBlob(null);
        }

        return response;
      }
    }
  };

  const sendCapturedScreenCast = async () => {
    const capturedFile = await captureThumb(contentRef);

    if (capturedFile) {
      const formData = new FormData();

      formData.append('media', capturedFile);
      formData.append('media_types', 'screenshot');
      formData.append('livestream_id', String(currentStream?.livestream?.id));

      if (formData) {
        const response = await dispatch(uploadMediaToStream(formData));

        if (response?.meta?.requestStatus === 'fulfilled') {
          setRecordedChunks([]);
          setRecordedVideoFile(null);
          setRecordedVideoBlob(null);
        }

        return response;
      }
    }
  };

  useEffect(() => {
    if (time === '00:00:02') {
      sendCapturedScreenCast();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time]);

  return {
    isPaused,
    stopRecording,
    isRecording,
    isFileCreating,
    startRecording,
    recordedChunks,
    pauseRecording,
    haveActiveRecord,
    recordedVideoBlob,
    recordedVideoFile,
    sendVideoToBackend,
    sendCapturedScreenCast,
  };
};

export default useRecording;
