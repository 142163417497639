import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';

import { teamsApi } from 'api';

import type { TTeamDataSelect } from 'components/shared/Team/types';

const useTeamInfoById = (id: number) => {
  const [isLoad, setIsLoad] = useState<boolean>(false);
  const [data, setData] = useState<TTeamDataSelect | null>(null);
  const [error, setError] = useState<AxiosError | null>(null);

  const getTeamInfoById = async () => {
    try {
      setIsLoad(true);
      const response = await teamsApi.getTeamsByIdRequest(id);
      setData(response.data);
      setIsLoad(false);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      setError(Error);
      setIsLoad(false);
    } finally {
      setIsLoad(false);
    }
  };

  useEffect(() => {
    if (id) {
      getTeamInfoById();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return {
    isLoad,
    error,
    data,
  };
};

export default useTeamInfoById;
