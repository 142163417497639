import { FC, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';

import { AddIcon } from 'assets/icons';
import { useAppDispatch, useAppSelector } from 'hooks';
import { setPage } from 'store/slices/estimatesSlice';
import { Button, CustomBreadcrumbs, CustomTypography } from 'components';
import CreateEstimateViaTemplateDrawer from 'components/views/Drawers/CreateEstimateViaTemplateDrawer';
import { userAllRolesSelector } from 'store/slices/authSlice/selectors';

import styles from './BomEstimateHeader.module.scss';
import { TBomEstimateHeaderProps } from './types';
import { breadCrumbOptions } from './utils';

const BomEstimateHeader: FC<TBomEstimateHeaderProps> = ({ totalRows }) => {
  const { state } = useLocation();
  const dispatch = useAppDispatch();

  const userRoles = useAppSelector(userAllRolesSelector);

  const filteredRoles = userRoles.map((role) => role.role_id);

  const renderDisabled =
    !filteredRoles.includes(8) && !filteredRoles.includes(5) && !filteredRoles.includes(3);

  const [currentPage, setCurrentPage] = useState<number>(0);

  const comingFromCustomer = state?.isOpenCreateDrawer;

  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(comingFromCustomer || false);

  const openDrawer = () => setIsDrawerOpen(true);
  const closeDrawer = () => setIsDrawerOpen(false);
  const handlePageChange = (_event: unknown, newPage: number) => {
    setCurrentPage(newPage);
    dispatch(setPage(newPage + 1));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  useEffect(() => {
    if (!totalRows && currentPage) {
      setCurrentPage(currentPage - 1);
      handlePageChange(null, currentPage - 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalRows]);

  const renderClasses = classNames(styles.container__actions, {
    [styles.container__actions__between]: totalRows > 0,
  });

  return (
    <Box className={styles.container}>
      <CustomBreadcrumbs options={breadCrumbOptions} />
      <Box className={styles.container__content}>
        <Box className={styles.container__info}>
          <CustomTypography className={styles.container__info__title}>Estimates</CustomTypography>
        </Box>
        {/* {isCreationOfEstimatesAndAgreements && (
          <> */}
        <Box className={renderClasses}>
          <div />
          <Button
            color='white'
            onClick={openDrawer}
            backgroundColor='#2067DD'
            disabled={renderDisabled}
            className={styles.container__actions__create}
            startIcon={<AddIcon width={24} height={24} />}
          >
            Create Estimates
          </Button>
        </Box>
        <CreateEstimateViaTemplateDrawer open={isDrawerOpen} handleClose={closeDrawer} />
        {/* </>
        )} */}
      </Box>
    </Box>
  );
};

export default BomEstimateHeader;
