import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { searchApi } from 'api';

import { TFindInventoryParams, TSearchParams, TUserSearchParams } from './types';

export const findInventories = createAsyncThunk(
  'searchSlice/findInventories',
  async (options: TFindInventoryParams) => {
    try {
      const response = await searchApi.searchInventoryRequest(options);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const findCatalogs = createAsyncThunk(
  'searchSlice/findCatalogs',
  async (options: TSearchParams) => {
    try {
      const response = await searchApi.searchCatalogRequest(options);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const findEstimate = createAsyncThunk(
  'searchSlice/findEstimate',
  async (options: TSearchParams) => {
    try {
      const response = await searchApi.searchEstimateRequest(options);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const findPurchaseRequisition = createAsyncThunk(
  'searchSlice/findPurchaseRequisition',
  async (options: TSearchParams) => {
    try {
      const response = await searchApi.searchPurchaseRequisitionRequest(options);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const findPurchaseOrder = createAsyncThunk(
  'searchSlice/findPurchaseOrder',
  async (options: TSearchParams) => {
    try {
      const response = await searchApi.searchPurchaseOrderRequest(options);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const findAgreement = createAsyncThunk(
  'searchSlice/findAgreement',
  async (options: TSearchParams) => {
    try {
      const response = await searchApi.searchAgreementRequest(options);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const findWorkOrder = createAsyncThunk(
  'searchSlice/findWorkOrder',
  async (options: TSearchParams) => {
    try {
      const response = await searchApi.searchWorkOrderRequest(options);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const findCustomer = createAsyncThunk(
  'searchSlice/findCustomer',
  async (options: TSearchParams) => {
    try {
      const response = await searchApi.searchCustomerRequest(options);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const findInvoice = createAsyncThunk(
  'searchSlice/findInvoice',
  async (options: TSearchParams) => {
    try {
      const response = await searchApi.searchInvoiceRequest(options);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const findUser = createAsyncThunk(
  'searchSlice/findUser',
  async (options: TUserSearchParams) => {
    try {
      const response = await searchApi.searchUserRequest(options);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);
