import { getValue } from '../getValue';

import type { TPurchaseOrderFormValues } from './types';

const purchaseOrderFormValuesGenerator = ({
  inEditForm,
  currentOrder,
  editedItems,
}: TPurchaseOrderFormValues) => {
  return {
    description: getValue(inEditForm, currentOrder?.description),
    items: editedItems || [],
  };
};

export default purchaseOrderFormValuesGenerator;
