import { Theme } from '@mui/material';

export const ITEM_HEIGHT = 48;
export const ITEM_PADDING_TOP = 8;
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      marginTop: '8px',
    },
    sx: {
      '& .MuiList-root': {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
  },
};

export const getStyles = (name: string, personName: string[], theme: Theme) => {
  const isSelected = personName.indexOf(name) === -1;

  return {
    fontWeight: isSelected ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
    color: isSelected ? '#000' : '#fff',
    backgroundColor: isSelected ? '#fff' : '#2067DD !important',

    '&:hover': {
      background: '#D2E1F9',
    },
  };
};

export const sx = {
  '& .MuiSelect-icon': {
    right: '12px',
    top: 'calc(50% - 12px)',
  },

  pr: '16px',
  color: '#222',
  borderRadius: '10px',
  backgroundColor: '#F2F2F2',
};
