import { BaseSyntheticEvent, forwardRef, useEffect, useState } from 'react';
import { Autocomplete, TextField, UseAutocompleteProps } from '@mui/material';
import classNames from 'classnames';

import { TOption } from 'components/shared/Select/types';
import { ArrowDown, SquareDeleteIcon } from 'assets/icons';
import { Button, Chip, CustomTypography } from 'components';

import { sxStyles } from './styles';
import { TJobTypesCompleteProps } from './types';
import styles from './JobTypesComplete.module.scss';

const JobTypesComplete = forwardRef<HTMLDivElement, TJobTypesCompleteProps>(
  (
    {
      error,
      label,
      isBold,
      options,
      placeholder,
      activeValue,
      borderColor,
      onTagCreate,
      handleOnChange,
      selectedJobTypes,
      autocompleteOpen,
      setSelectedJobType,
      setAutocompleteOpen,
      backgroundColor = 'rgba(242, 242, 242, 1)',
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _,
  ) => {
    const [selectedOptions, setSelectedOptions] = useState<TOption[]>(activeValue || []);

    useEffect(() => {
      if (selectedJobTypes) {
        setSelectedOptions(selectedJobTypes);
      }
    }, [selectedJobTypes]);

    useEffect(() => {
      if (activeValue) {
        setSelectedOptions(activeValue);
      }
    }, [activeValue]);

    const handleChange: UseAutocompleteProps<TOption, true, false, false>['onChange'] = (
      _event,
      newValue,
    ) => {
      const val = newValue as TOption[];

      _event.stopPropagation();
      setSelectedOptions(val);
      handleOnChange(val as TOption[]);

      if (setSelectedJobType) {
        setSelectedJobType(val);
      }

      setAutocompleteOpen?.(false);
    };
    const handleAddTag = (event: BaseSyntheticEvent) => {
      event.stopPropagation();
      onTagCreate?.();
    };

    const handleChipDelete = (optionToDelete: TOption) => {
      const filteredOptions = selectedOptions?.filter((option) => option.id !== optionToDelete.id);

      setSelectedOptions(filteredOptions);
      setSelectedJobType?.(filteredOptions);
      handleOnChange(filteredOptions);
    };

    const renderOptionClasses = (option: TOption) => {
      return classNames(styles.create__text, {
        [styles.create__text__selected]: selectedOptions.find((el) => el.id === option.value),
      });
    };

    return (
      <Autocomplete
        sx={{
          ...sxStyles,
          backgroundColor: backgroundColor,
          borderColor: borderColor,
        }}
        getOptionDisabled={(option) => !option.value}
        multiple
        id='select-with-chips'
        popupIcon={<ArrowDown />}
        options={options}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => option.label || 'kkk'}
        value={selectedOptions}
        defaultValue={selectedOptions}
        onChange={handleChange}
        disableClearable
        onClose={(e, reason) => {
          if (reason === 'selectOption') return;
          setAutocompleteOpen?.(false);
        }}
        open={autocompleteOpen}
        onOpen={() => setAutocompleteOpen?.(true)}
        renderOption={(props, option) => {
          return (
            <li {...props} className={styles.create}>
              <CustomTypography className={renderOptionClasses(option)}>
                {option.label || ''}
              </CustomTypography>
              {(option === options[options.length - 1] || !options?.length) && (
                <Button onClick={handleAddTag} className={styles.create__button}>
                  Create New Tag
                </Button>
              )}
            </li>
          );
        }}
        noOptionsText={
          <Button onClick={handleAddTag} className={styles.create__button}>
            Create New Tag
          </Button>
        }
        renderTags={(value, getTagProps) =>
          value.map((option, index) => {
            const updatedJobType = options.find((el) => el.id === option.id);

            return (
              <Chip
                color={(updatedJobType?.color as string) || (option.color as string)}
                deleteIcon={<SquareDeleteIcon className={styles.chip} />}
                label={
                  updatedJobType?.label || option.label || option.name || updatedJobType?.name || ''
                }
                {...getTagProps({ index })}
                key={index}
                onDelete={() => handleChipDelete(updatedJobType || option)}
              />
            );
          })
        }
        renderInput={({ size, fullWidth, disabled, InputProps, inputProps, id }) => (
          <TextField
            sx={{
              '& .MuiFormLabel-root': {
                fontFamily: isBold
                  ? 'CircularStdRegular !important'
                  : 'CircularStdLight !important',
              },

              '& .MuiInputBase-root': {
                border: 'none !important',
              },

              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: borderColor || 'transparent',
                borderRadius: '10px',
              },
            }}
            id={id}
            error={error}
            size={size}
            variant='outlined'
            label={label}
            disabled={disabled}
            fullWidth={fullWidth}
            InputProps={InputProps}
            inputProps={inputProps}
            placeholder={!selectedOptions?.length && !selectedOptions?.length ? placeholder : ''}
          />
        )}
      />
    );
  },
);

export default JobTypesComplete;
