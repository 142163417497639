import { FC } from 'react';
import { Box } from '@mui/material';

import { useTeamInfoById } from 'hooks';
import { CustomTypography, CustomizedAvatar, ModalBoxHeader, ModalLayout } from 'components';

import styles from './TeamInfoModal.module.scss';

import type { TTeamInfoModalProps } from './types';

const TeamInfoModal: FC<TTeamInfoModalProps> = ({ onClose, isOpen, teamId }) => {
  const { data } = useTeamInfoById(teamId);

  if (!data) {
    return null;
  }

  const leads = data?.members?.filter((member) => member?.position === 'lead');
  const members = data?.members?.filter((member) => member?.position === 'member');

  const teamLeads = leads?.map((member) => (
    <CustomizedAvatar alt={'img'} key={member?.id} src={member?.profile_image_url_id} />
  ));

  const teamMembers = members?.map((member) => (
    <CustomizedAvatar alt={'img'} key={member?.id} src={member?.profile_image_url_id} />
  ));

  return (
    <ModalLayout isOpen={isOpen} onClose={onClose} borderRadius='24px'>
      <ModalBoxHeader title={data?.name as string} onClose={onClose} />
      <Box className={styles.container}>
        {teamLeads?.length ? (
          <Box className={styles.container__content}>
            <CustomTypography className={styles.container__content__title}>Leads:</CustomTypography>
            <Box className={styles.container__content__main}>{teamLeads}</Box>
          </Box>
        ) : (
          <CustomTypography
            className={styles.container__content__empty}
          >{`The team don't have leads`}</CustomTypography>
        )}
        {teamMembers?.length ? (
          <Box className={styles.container__content}>
            <CustomTypography className={styles.container__content__title}>
              Members:
            </CustomTypography>

            <Box className={styles.container__content__main}>{teamMembers}</Box>
          </Box>
        ) : (
          <CustomTypography
            className={styles.container__content__empty}
          >{`The team don't have members`}</CustomTypography>
        )}
      </Box>
    </ModalLayout>
  );
};

export default TeamInfoModal;
