const textFieldStyles = {
  '& .MuiInputLabel-root': {
    fontFamily: 'CircularStdLight',
    left: '3px',
    top: '6px',
    color: '#222',
  },

  '& .MuiFormHelperText-root': {
    color: 'red !important',
    margin: '0 !important',
    marginTop: '5px !important',
  },

  '& .MuiFilledInput-root': {
    '&:focused': {
      background: 'transparent !important',
    },
  },

  '& .MuiInputBase-input': {
    fontFamily: 'CircularStdLight',
    fontSize: '12px',
    marginTop: '8px',
  },

  '& .MuiFormLabel-root': {
    color: 'black !important',
  },

  '& .Mui-focused': {
    '&:hover': {
      backgroundColor: 'transparent !important',
    },

    '&:focused': {
      background: 'transparent !important',
    },
  },

  '& .MuiInputBase-root': {
    backgroundColor: 'transparent !important',
    paddingInline: 0,
    paddingBlock: '8px',

    '&:hover': {
      backgroundColor: 'transparent',
    },

    '&:focused': {
      backgroundColor: 'transparent !important',
    },

    '&:after': {
      display: 'none',
    },

    '&:before': {
      display: 'none',
    },
  },
};

export default textFieldStyles;
