import { FC, useRef, useState } from 'react';
import classNames from 'classnames';
import { Box, Stack } from '@mui/material';

import { ArrowDown } from 'assets/icons';
import { profileDropdownOptions } from 'constants/Profile';
import { userDataLoadingSelector, userDataSelector } from 'store/slices/authSlice/selectors';
import { CustomizedAvatar, CustomSkeleton, CustomTypography, Dropdown } from 'components';
import { useAppSelector, useOnClickOutside, useUserInfoById, useWindowSize } from 'hooks';

import { breakPoints } from '../../../constants';

import styles from './User.module.scss';

import type { TUserProps } from './types';

const User: FC<TUserProps> = ({ name }) => {
  const userInfo = useAppSelector(userDataSelector);
  const userInfoLoad = useAppSelector(userDataLoadingSelector);

  const { userData, isLoad } = useUserInfoById(userInfo?.id as number);

  const { width } = useWindowSize();

  const avatarSizes = Number(width) <= breakPoints.TABLET_L ? 24 : 45;

  const userImg = userInfo?.profile_image_url_id;

  const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false);

  const userRef = useRef<HTMLButtonElement | null>(null);

  const closeDropdown = () => setIsOpenMenu(false);
  const toggleDropdown = () => setIsOpenMenu(!isOpenMenu);

  useOnClickOutside(userRef, closeDropdown);

  const iconClasses = classNames(styles.container__user__icon, {
    [styles.container__user__icon_active]: isOpenMenu,
  });

  const menuClasses = classNames(styles.container__user__menu, {
    [styles.container__user__menu_active]: isOpenMenu,
  });

  const generatedName = name || userInfo?.name;

  const load = userInfoLoad || isLoad;

  return (
    <Box className={styles.container}>
      {load ? (
        <Stack direction='column' alignItems='flex-end' gap='5px'>
          <CustomSkeleton width={150} height={15} />
          <CustomSkeleton width={50} height={10} />
        </Stack>
      ) : (
        <Box className={styles.container__name}>
          {generatedName ? (
            <CustomTypography className={styles.container__name__title}>
              {generatedName}
            </CustomTypography>
          ) : (
            <CustomTypography className={styles.container__name__title}>Username</CustomTypography>
          )}
          <CustomTypography className={styles.container__name__subtitle}>
            {userData?.designation}
          </CustomTypography>
        </Box>
      )}

      <button
        onClick={toggleDropdown}
        role='button'
        ref={userRef}
        className={styles.container__user}
      >
        <ArrowDown className={iconClasses} />

        <CustomizedAvatar
          isLoad={userInfoLoad}
          width={avatarSizes}
          height={avatarSizes}
          src={userImg}
          alt='img'
          variant='square'
        />

        <Dropdown options={profileDropdownOptions} className={menuClasses} />
      </button>
    </Box>
  );
};

export default User;
