import { Box } from '@mui/material';
import { FC, useRef } from 'react';

import { EditIcon } from 'assets/icons';
import { TChangeInputEvent } from 'types/global/events';
import { UserImg } from 'assets/images';

import IconOutline from '../IconOutline';
import LazyImage from '../LazyImage';

import styles from './ImageUpload.module.scss';

import type { TImageUploadProps } from './types';

const ImageUpload: FC<TImageUploadProps> = ({ imageUrl, setImageUrl, getFile }) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileUpload = async (event: TChangeInputEvent) => {
    const file = event?.target?.files?.[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      const result = reader.result as string;
      setImageUrl(result);
    };

    if (file) {
      reader.readAsDataURL(file);
      getFile?.(file);
    }
  };

  const handleReplaceImage = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <Box className={styles.container}>
      <LazyImage
        height='100%'
        src={String(imageUrl)}
        fallback={UserImg}
        className={styles.container__avatar}
      />
      <label htmlFor='upload-image'>
        <Box className={styles.container__edit}>
          <IconOutline>
            <EditIcon onClick={handleReplaceImage} />
          </IconOutline>
        </Box>

        <input
          id='upload-image'
          hidden
          accept='.jpg,.jpeg,.png,.webp,.svg'
          type='file'
          onChange={handleFileUpload}
        />
      </label>
    </Box>
  );
};

export default ImageUpload;
