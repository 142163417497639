import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, FieldValues, SubmitHandler, useForm } from 'react-hook-form';

import {
  Button,
  ImageUpload,
  CustomSkeleton,
  CustomTextField,
  ControlledSelect,
  NumberControlledInput,
} from 'components';
import { stateOptions } from 'constants/States';
import { profileSettingsSchema } from 'constants/Schemas';
import { ImgUrlFolders } from 'types/global/imgUrlFolders';
import { profileFormGenerator, returnImgUrl } from 'utils';
import { useAppDispatch, useAppSelector, useWindowSize } from 'hooks';
import { TUpdateUser, TUserProfile } from 'store/slices/authSlice/types';
import {
  updateUser,
  getNewToken,
  updateImage,
  getCurrentUser,
} from 'store/slices/authSlice/authThunks';
import { userDataLoadingSelector, userDataSelector } from 'store/slices/authSlice/selectors';
import { Colors, FontNames } from 'types';

import { breakPoints } from '../../../../constants';

import styles from './Profile.module.scss';

const ProfileSettings = () => {
  const dispatch = useAppDispatch();

  const userInfo = useAppSelector(userDataSelector);
  const userInfoLoading = useAppSelector(userDataLoadingSelector);

  const { width } = useWindowSize();

  const currentImage = returnImgUrl(
    ImgUrlFolders.PROFILE_INFO,
    String(userInfo?.profile_image_url_id),
  );

  const [imageUrl, setImageUrl] = useState<string>(currentImage);

  const [file, setFile] = useState<File | null>(null);

  useEffect(() => {
    setImageUrl(currentImage);
  }, [currentImage, userInfo?.profile_image_url_id]);

  const getFile = (file: File) => setFile(file);

  const handleUpdateImg = async () => {
    const formImgData = new FormData();

    if (file) {
      formImgData.append('profile_image', file);
    }

    if (formImgData) {
      dispatch(updateImage(formImgData));
      setFile(null);
    }
  };

  const handleFormChange: SubmitHandler<FieldValues> = async (data) => {
    const promises = [];
    const updateProfile: TUpdateUser = {
      name: data.fullName,
      email: userInfo?.email as string,
      state: data.state,
      country: data.country,
      city: data.city,
      address: data.address,
      zip_code: data.zipcode,
      mobile_number: data.mobilePhone,
      office_number: data.workPhone,
    };

    const userInfoReq = await dispatch(updateUser(updateProfile));

    promises.push(userInfoReq);

    if (file) {
      const image = await handleUpdateImg();

      promises.push(image);
    }

    Promise.all(promises).then(() => {
      dispatch(getNewToken()).then((res) => {
        if (res) {
          dispatch(getCurrentUser());
          dispatch(getCurrentUser());
        }
      });
    });
  };

  const {
    handleSubmit: updateUserSubmit,
    control: updateUserControl,
    watch,
    formState: { errors: updateUserError, isSubmitting },
  } = useForm({
    resolver: yupResolver(profileSettingsSchema),
    values: profileFormGenerator(userInfo as TUserProfile),
    mode: 'all',
  });

  const formValues = watch();

  const formValuesChanged =
    JSON.stringify(formValues) !== JSON.stringify(updateUserControl._defaultValues);

  const skeletonSizes = Number(width) >= breakPoints.TABLET_M ? 150 : 100;

  return (
    <form className={styles.container} onSubmit={updateUserSubmit(handleFormChange)}>
      {userInfoLoading ? (
        <CustomSkeleton
          borderRadius='50%'
          width={skeletonSizes}
          height={skeletonSizes}
          minWidth={skeletonSizes}
          maxWidth={skeletonSizes}
        />
      ) : (
        <ImageUpload imageUrl={imageUrl} setImageUrl={setImageUrl} getFile={getFile} />
      )}

      {userInfoLoading ? (
        <Box className={styles.container__inputs}>
          <Box className={styles.container__inputs_grid}>
            <CustomSkeleton borderRadius='5px' height='53px' width='100%' />
            <CustomSkeleton borderRadius='5px' height='53px' width='100%' />
            <CustomSkeleton borderRadius='5px' height='53px' width='100%' />
            <CustomSkeleton borderRadius='5px' height='53px' width='100%' />
          </Box>
          <Box className={styles.container__inputs__address}>
            <CustomSkeleton borderRadius='5px' height='53px' width='100%' />
          </Box>
          <Box className={styles.container__inputs__address__details}>
            <CustomSkeleton borderRadius='5px' height='53px' width='100%' />
            <CustomSkeleton borderRadius='5px' height='53px' width='100%' />
            <CustomSkeleton borderRadius='5px' height='53px' width='100%' />
          </Box>
          <Box className={styles.container__submit}>
            <CustomSkeleton height='42px' width='100%' maxWidth='62px' borderRadius='4px' />
          </Box>
        </Box>
      ) : (
        <Box className={styles.container__inputs}>
          <Box className={styles.container__inputs_grid}>
            <Controller
              name='fullName'
              control={updateUserControl}
              rules={{ required: 'Full Name is required' }}
              render={({ field, formState: { errors } }) => (
                <CustomTextField
                  {...field}
                  required
                  borderRadius='4px'
                  label='Full Name:'
                  padding='15px 16px'
                  borderColor={Colors.SOFT_SILVER}
                  placeholder='Full Name:'
                  backGroundColor={Colors.LIGHT_SILVER}
                  fontFamily={FontNames.CIRCULAR_REG}
                  error={!!updateUserError.fullName}
                  labelColor={Colors.ENIGMATIC_MIDNIGHT}
                  helperText={errors?.fullName?.message}
                />
              )}
            />

            <Controller
              name='email'
              control={updateUserControl}
              rules={{ required: 'Email is required' }}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  disabled
                  required
                  readonly
                  type='email'
                  label='Email'
                  borderRadius='4px'
                  placeholder='Email'
                  padding='15px 16px'
                  borderColor={Colors.SOFT_SILVER}
                  backGroundColor={Colors.LIGHT_SILVER}
                  labelColor={Colors.ENIGMATIC_MIDNIGHT}
                  value={field.value as string}
                  fontFamily={FontNames.CIRCULAR_REG}
                />
              )}
            />

            <NumberControlledInput
              name='mobilePhone'
              padding='15px 16px'
              borderRadius='4px'
              label='Mobile Phone:'
              borderColor={Colors.SOFT_SILVER}
              backgroundColor={Colors.LIGHT_SILVER}
              placeholder='Mobile Phone:'
              control={updateUserControl}
              fontFamily={FontNames.CIRCULAR_REG}
              error={!!updateUserError.mobilePhone}
              labelColor={Colors.ENIGMATIC_MIDNIGHT}
            />

            <NumberControlledInput
              name='workPhone'
              padding='15px 16px'
              label='Work Phone:'
              borderRadius='4px'
              borderColor={Colors.SOFT_SILVER}
              placeholder='Work Phone:'
              backgroundColor={Colors.LIGHT_SILVER}
              control={updateUserControl}
              fontFamily={FontNames.CIRCULAR_REG}
              error={!!updateUserError.workPhone}
              labelColor={Colors.ENIGMATIC_MIDNIGHT}
            />
          </Box>

          <Box className={styles.container__inputs__address}>
            <Controller
              name='address'
              control={updateUserControl}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  label='Address:'
                  borderRadius='4px'
                  padding='15px 16px'
                  borderColor={Colors.SOFT_SILVER}
                  placeholder='Address:'
                  backGroundColor={Colors.LIGHT_SILVER}
                  value={field.value as string}
                  fontFamily={FontNames.CIRCULAR_REG}
                  error={!!updateUserError.address}
                  labelColor={Colors.ENIGMATIC_MIDNIGHT}
                />
              )}
            />
            <Box className={styles.container__inputs__address__details}>
              <Controller
                name='city'
                control={updateUserControl}
                render={({ field }) => (
                  <CustomTextField
                    {...field}
                    label='City:'
                    borderRadius='4px'
                    placeholder='City:'
                    padding='15px 16px'
                    borderColor={Colors.SOFT_SILVER}
                    backGroundColor={Colors.LIGHT_SILVER}
                    fontFamily={FontNames.CIRCULAR_REG}
                    value={field.value as string}
                    error={!!updateUserError.city}
                    labelColor={Colors.ENIGMATIC_MIDNIGHT}
                  />
                )}
              />
              <ControlledSelect
                name='state'
                label='State'
                borderRadius='4px'
                padding='15px 16px'
                options={stateOptions}
                backgroundColor={Colors.LIGHT_SILVER}
                border='1px solid #EDEFF1'
                control={updateUserControl}
                labelColor={Colors.ENIGMATIC_MIDNIGHT}
              />

              <Controller
                name='zipcode'
                control={updateUserControl}
                render={({ field }) => (
                  <CustomTextField
                    {...field}
                    label='Zip:'
                    borderRadius='4px'
                    placeholder='Zip:'
                    padding='15px 16px'
                    borderColor={Colors.SOFT_SILVER}
                    backGroundColor={Colors.LIGHT_SILVER}
                    fontFamily={FontNames.CIRCULAR_REG}
                    value={field.value as string}
                    labelColor={Colors.ENIGMATIC_MIDNIGHT}
                    error={!!updateUserError.zipcode as boolean}
                    helperText={updateUserError.zipcode?.message}
                  />
                )}
              />
            </Box>
          </Box>
          <Box className={styles.container__submit}>
            <Button
              disabled={(!formValuesChanged && !file) || isSubmitting}
              type='submit'
              maxWidth='62px'
              minWidth='62px'
              borderRadius='4px'
              variant='contained'
              padding='12px 16px'
              isUppercase={false}
            >
              Save
            </Button>
          </Box>
        </Box>
      )}
    </form>
  );
};

export default ProfileSettings;
