import { useRef, useState, type FC } from 'react';
import classNames from 'classnames';
import { Modal } from '@mui/material';
import { animated, useSpring } from 'react-spring';

import { useOnClickOutside } from 'hooks';
import { BorderSquareIcon } from 'assets/icons';
import { Button, CustomTypography } from 'components';
import { Colors } from 'types';

import styles from './DeletePopup.module.scss';

import type { TDeletePopupProps } from './types';

const DeletePopup: FC<TDeletePopupProps> = ({
  body,
  title,
  isOpen,
  onClose,
  onDelete,
  withBody = false,
}) => {
  const popupRef = useRef<HTMLDivElement | null>(null);

  const [isStarted, setIsStarted] = useState<boolean>(false);

  const springProps = useSpring({
    delay: 200,
    opacity: isOpen ? 1 : 0,
    top: isOpen ? '50%' : '-5%',
  });

  const onCloseProps = useSpring({
    delay: 200,
    opacity: !isStarted ? 1 : 0,
    top: !isStarted ? '50%' : '-5%',
  });

  const timedOnClose = () => {
    setIsStarted(true);

    setTimeout(() => {
      onClose();
      setIsStarted(false);
    }, 800);
  };

  const deleteButtonClasses = classNames(
    styles.container__content__actions__button,
    styles.container__content__actions__delete,
  );

  useOnClickOutside(popupRef, onClose);

  return (
    <Modal open={isOpen} onClose={timedOnClose}>
      <animated.div
        style={isStarted ? onCloseProps : springProps}
        className={styles.container}
        ref={popupRef}
      >
        <div className={styles.container__header}>
          <CustomTypography className={styles.container__header__title}>
            {!withBody ? `Are you sure you want to delete ${title}?` : title}
          </CustomTypography>

          <BorderSquareIcon onClick={timedOnClose} width={24} height={24} />
        </div>
        <div className={styles.container__content}>
          {withBody && body && <div className={styles.container__content__title}>{body}</div>}
          {!withBody ? (
            <div className={styles.container__content__actions}>
              <Button className={deleteButtonClasses} onClick={onDelete}>
                DELETE
              </Button>
            </div>
          ) : (
            <div className={styles.container__content__actions__center}>
              <Button
                color={Colors.GRAY}
                onClick={timedOnClose}
                isUppercase={false}
                borderRadius='5px'
                padding='11px 8px'
                backgroundColor={Colors.WHITE}
              >
                Cancel
              </Button>
              <Button
                onClick={onDelete}
                padding='11px 8px'
                borderRadius='5px'
                isUppercase={false}
                backgroundColor={Colors.PALE_BLUE}
              >
                Confirm
              </Button>
            </div>
          )}
        </div>
      </animated.div>
    </Modal>
  );
};

export default DeletePopup;
