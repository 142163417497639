export const getNestedError = (errors: any, name: any) => {
  const parts = name.split(/\.|\[|\]\.?/).filter(Boolean);

  let current = errors;
  for (const part of parts) {
    const index = isNaN(part) ? part : parseInt(part, 10);

    if (!isNaN(index)) {
      if (Array.isArray(current) && index < current.length) {
        current = current?.[index];
      } else {
        return undefined;
      }
    } else {
      if (current?.[part] === undefined) {
        return undefined; // Property does not exist
      }
      current = current[part];
    }
  }

  return current;
};
