import {
  TeamIcon,
  CustomerIcon,
  WorkFlowIcon,
  DocumentsIcon,
  SchedulingIcon,
  LiveStreamIcon,
  ProductInfoIcon,
  ActivityFeedIcon,
} from 'assets/icons';
import { Routes } from 'types';

import { TNavbarOptions } from './types';

export const dropdownOptions: TNavbarOptions[] = [
  {
    id: 0,
    content: 'Activity feed',
    Icon: <ActivityFeedIcon />,
    linkTo: Routes.ActivityFeed,
    isExpandable: true,
    expandableContent: [
      { id: 0, name: 'Work Order Schedule' },
      { id: 1, name: 'Work Order Schedule' },
      { id: 2, name: 'Work Order Schedule' },
    ],
  },
  { id: 1, content: 'Live stream', Icon: <LiveStreamIcon />, linkTo: Routes.LiveStream },
  {
    id: 2,
    content: 'Work flow',
    Icon: <WorkFlowIcon />,
    isInDropDownMode: true,
  },
  { id: 3, content: 'Product Info', Icon: <ProductInfoIcon />, linkTo: Routes.Inventory },
  {
    id: 4,
    content: 'Team',
    Icon: <TeamIcon />,
    linkTo: Routes.Team,
  },
  {
    id: 5,
    content: 'Customer',
    Icon: <CustomerIcon />,
    isExpandable: true,
    linkTo: Routes.Customer,
    expandableContent: [
      { id: 0, name: 'Work Order Schedule' },
      { id: 1, name: 'Work Order Schedule' },
      { id: 2, name: 'Work Order Schedule' },
    ],
  },
  {
    id: 6,
    content: 'Scheduling',
    Icon: <SchedulingIcon />,
    linkTo: Routes.Scheduling,
  },
  {
    id: 7,
    content: 'Documents',
    Icon: <DocumentsIcon />,
    linkTo: Routes.Documents,
  },
];
