import { FC } from 'react';

import { GalleryIcon, VideoPlayGreenIcon } from 'assets/icons';

import CustomTypography from '../Typography';

import styles from './PostActions.module.scss';
import { TPostActionsProps } from './types';

const PostActions: FC<TPostActionsProps> = ({ createPostModal }) => {
  return (
    <div className={styles.container}>
      <div className={styles.container_content}>
        <div className={styles.container_content_image} role='button' onClick={createPostModal}>
          <GalleryIcon />
          <CustomTypography>Image</CustomTypography>
        </div>
        <div className={styles.container_content_divider} />
        <div className={styles.container_content_video} role='button' onClick={createPostModal}>
          <VideoPlayGreenIcon />
          <CustomTypography>Video</CustomTypography>
        </div>
      </div>
    </div>
  );
};

export default PostActions;
