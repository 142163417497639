export type TStatusInfoProps = {
  title: string;
  status: StatusEnums;
};

export enum StatusEnums {
  ACTIVE = 'active',
  PENDING = 'pending',
  EXPIRED = 'expired',
  REVOKED = 'revoked',
  ACCEPTED = 'accepted',
  INACTIVE = 'inactive',
  DEACTIVATE = 'deactivate',
}
