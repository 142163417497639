import { useEffect, useState } from 'react';
import classNames from 'classnames';
import Hamburger from 'hamburger-react';
import { Box, Stack, Tab, Tabs } from '@mui/material';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import {
  Button,
  DeletePopup,
  ScrollLayout,
  CustomSkeleton,
  CustomTypography,
  CustomBreadcrumbs,
} from 'components';
import { Colors, FontNames, Routes } from 'types';
import { useAppDispatch, useAppSelector, useWindowSize } from 'hooks';
import {
  currentCatalogByIdSelector,
  currentSectionVersionsSelector,
  catalogsSliceSelector,
} from 'store/slices/catalogSlice/selectors';
import {
  deleteCatalogById,
  getCatalogById,
  getCatalogByUUID,
  getCurrentSectionVersions,
} from 'store/slices/catalogSlice/thunks';
import { couponsSelector } from 'store/slices/discountSlice/selectors';
import { getCouponByCatalogId } from 'store/slices/discountSlice/thunks';
import { deleteCatalogText } from 'components/views/Cards/CatalogCard/constants';
import CatalogCreateConfigure from 'components/views/Drawers/CatalogCreateConfigure';
import { userPermissionsSelector } from 'store/slices/authSlice/selectors';

import { breakPoints } from '../../constants';

import { headerButtonsCreator } from './utils';
import { sxStyles, tabContainerSx } from './styles';
import styles from './CatalogSectionItems.module.scss';
import { breadCrumbsCreator } from './CatalogSectionItems.utils';
import {
  PaymentView,
  DiscountsView,
  TemplatesView,
  CustomTabPanel,
  CatalogSectionTable,
  GeneralInformationView,
} from './SectionItemsViews';

const CatalogSectionItems = () => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const { id } = useParams();

  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const { isAccessToCatalogsEditor, isAccessToInventoryEditor } =
    useAppSelector(userPermissionsSelector);

  const haveAccessForEdits = isAccessToCatalogsEditor && isAccessToInventoryEditor;

  const versions = useAppSelector(currentSectionVersionsSelector);
  const currentCatalog = useAppSelector(currentCatalogByIdSelector);

  const {
    versionsByIdLoad,
    catalogListLoading,
    currentSectionVersionsLoad,
    currentCatalogByUUIDLoad,
  } = useAppSelector(catalogsSliceSelector);

  const { couponForCatalogLoad } = useAppSelector(couponsSelector);

  const generalLoading =
    isLoaded ||
    currentCatalogByUUIDLoad ||
    catalogListLoading ||
    versionsByIdLoad ||
    currentSectionVersionsLoad ||
    couponForCatalogLoad;

  const [value, setValue] = useState<number>(0);
  const [isErrorPage, setIsErrorPage] = useState<boolean>(false);
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const [isEditCatalog, setIsEditCatalog] = useState<boolean>(false);

  const { width } = useWindowSize();

  const breadCrumbsOptions = breadCrumbsCreator(String(currentCatalog?.name), String(id));

  const a11yProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };

  const tabs = versions?.sections?.map((tab, idx) => (
    <Tab sx={sxStyles} label={tab?.name} {...a11yProps(idx)} key={tab?.uuid} />
  ));

  const tabPanels = versions?.sections?.map((version, idx) => (
    <CustomTabPanel key={version?.uuid} value={value} index={idx}>
      <CatalogSectionTable data={version?.items} />
    </CustomTabPanel>
  ));

  const initialSkeletons = Array.from({ length: 5 })?.map((_, idx) => (
    <CustomSkeleton width={120} height={25} key={idx} />
  ));

  const closeEditDrawer = () => {
    setIsEditCatalog(false);
    setValue(0);
  };

  const onDelete = async () => {
    await dispatch(deleteCatalogById(currentCatalog?.id as number));
    togglePopup();
    navigate(Routes.Catalog);
  };

  const togglePopup = () => setIsPopupOpen(!isPopupOpen);

  const openEditDrawer = () => {
    setIsEditCatalog(true);
  };

  const [error, setError] = useState<any | null>(null);

  const getCurrentCatalogAndVersions = async () => {
    try {
      setIsLoaded(true);
      const firstResponse = await dispatch(getCatalogByUUID(id as string)).unwrap();

      const response = await dispatch(getCatalogById(Number(firstResponse?.id))).unwrap();
      if (response.id) {
        await dispatch(getCurrentSectionVersions(response?.latest_version_id as number));
        await dispatch(getCouponByCatalogId(response?.id as number));
        setIsLoaded(false);
      } else {
        setIsErrorPage(true);
        navigate(Routes.Error);
        setIsLoaded(false);
      }
    } catch (error) {
      setError(error);
      setIsLoaded(false);
    }
  };

  useEffect(() => {
    getCurrentCatalogAndVersions();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const headerButtons = headerButtonsCreator({
    editAction: openEditDrawer,
    removeAction: togglePopup,
  });

  const buttons = headerButtons?.map((button) => (
    <Button
      key={button.id}
      borderRadius='5px'
      variant='contained'
      disableColor={Colors.WHITE}
      onClick={button?.action}
      minWidth={button?.minWidth}
      fontFamily={FontNames.CIRCULAR_REG}
      maxWidth={button?.maxWidth as string}
      padding={Number(width) >= breakPoints.DESKTOP ? '11px 8px' : '4px'}
      color={Number(width) >= breakPoints.DESKTOP ? Colors.WHITE : 'black'}
      backgroundColor={Number(width) >= breakPoints.DESKTOP ? Colors.SAPPHIRE : 'transparent'}
    >
      {button?.name}
    </Button>
  ));

  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const toggleMenu = () => setIsExpanded(!isExpanded);

  const menuClasses = classNames(styles.container__head__search__controls, {
    [styles.container__head__search__controls_active]: isExpanded,
  });

  if (error) {
    return <Navigate to={Routes.Catalog} />;
  }

  return (
    <>
      {!isErrorPage && (
        <Box className={styles.container}>
          <Box>
            <Box className={styles.container__head}>
              <Box className={styles.container__head__navigation}>
                <CustomBreadcrumbs options={breadCrumbsOptions} />
              </Box>
              <Box className={styles.container__head__search}>
                {generalLoading ? (
                  <CustomSkeleton height={30} width='100px' />
                ) : (
                  <CustomTypography className={styles.container__head__search__name}>
                    {currentCatalog?.name}
                  </CustomTypography>
                )}

                {haveAccessForEdits && (
                  <Box position={'relative'}>
                    {Number(width) <= breakPoints.DESKTOP && (
                      <Hamburger size={25} onToggle={toggleMenu} />
                    )}
                    <Box className={menuClasses}>{buttons}</Box>
                  </Box>
                )}
              </Box>
              <Box className={styles.container__head__description}>
                {generalLoading ? (
                  <Stack gap='5px' direction='row' flexWrap='wrap'>
                    {initialSkeletons}
                  </Stack>
                ) : (
                  <>
                    <CustomTypography className={styles.container__head__description__title}>
                      Catalog No:#{currentCatalog?.id}
                    </CustomTypography>
                    <CustomTypography className={styles.container__head__description__title}>
                      Category: {currentCatalog?.category}
                    </CustomTypography>
                    <CustomTypography className={styles.container__head__description__title}>
                      Created by: {currentCatalog?.created_by}
                    </CustomTypography>
                  </>
                )}
              </Box>
            </Box>
            {generalLoading ? (
              <Stack gap='10px' margin='40px 0' direction='row' flexWrap='wrap'>
                {initialSkeletons}
              </Stack>
            ) : (
              <ScrollLayout withScrollStyles>
                <Box className={styles.container__navigation}>
                  <Tabs
                    value={value}
                    sx={tabContainerSx}
                    onChange={handleChange}
                    aria-label='basic tabs example'
                  >
                    {tabs}
                  </Tabs>
                </Box>
              </ScrollLayout>
            )}

            <Box className={styles.container__content}>
              {generalLoading ? <CustomSkeleton height={50} width='100%' /> : tabPanels}
            </Box>
          </Box>
          <TemplatesView isLoading={generalLoading} />
          <PaymentView isLoading={generalLoading} />
          <DiscountsView isLoading={generalLoading} />
          <GeneralInformationView isLoading={generalLoading} />
        </Box>
      )}
      <CatalogCreateConfigure inEditMode open={isEditCatalog} onClose={closeEditDrawer} />

      <DeletePopup
        withBody
        onDelete={onDelete}
        isOpen={isPopupOpen}
        onClose={togglePopup}
        body={deleteCatalogText}
        title='Delete Confirmation'
      />
    </>
  );
};

export default CatalogSectionItems;
