import React, { FC } from 'react';
import { Box } from '@mui/material';

import { CustomTypography } from 'components';

import { TCardUsedProps } from './types';
import styles from './CardUsed.module.scss';

const CardUsed: FC<TCardUsedProps> = ({ cardName, last$Numbers }) => (
  <Box className={styles.container}>
    <CustomTypography className={styles.container__card}>{cardName}</CustomTypography>
    <CustomTypography className={styles.container__last}>ending in {last$Numbers}</CustomTypography>
  </Box>
);

export default CardUsed;
