import { type FC, useEffect } from 'react';
import classNames from 'classnames';
import { Navigate } from 'react-router-dom';
import { GridColDef } from '@mui/x-data-grid';
import { Box, IconButton, Menu, MenuItem } from '@mui/material';

import {
  TableCell,
  EmptyTitle,
  ContactLink,
  ScrollLayout,
  CustomDataGrid,
  CustomTypography,
  InventoryCardNew,
  ShowAfterAccessView,
  DynamicPaginationControl,
} from 'components';
import { MoreIcon, FilledCheckedIcon, NewUncheckedIcon } from 'assets/icons';
import { useAppDispatch, useAppSelector, useInput, useMediaFetching } from 'hooks';
import { ImgUrlFolders, Routes } from 'types';
import { EmptyTitles } from 'constants/EmptyTitles';
import PreloadedImg from 'components/views/PreloadedImg';
import { findInventories, getAllParts } from 'store/thunks';
import { partsSelector } from 'store/slices/inventorySlice/selectors';
import { optionsSelector } from 'store/slices/optionsSlice/selectors';
import { PartsSortBy, TPart } from 'store/slices/inventorySlice/types';
import { fillSelectedIds, setInventoryParts } from 'store/slices/optionsSlice';
import { foundedInventoriesSelector } from 'store/slices/searchSlice/selectors';
import { generateInventoryItemNumber } from 'utils/generateInventoryItemNumber';
import { userPermissionsSelector, authSelector } from 'store/slices/authSlice/selectors';
import { BrowserStorageKeys, BrowserStorageService } from 'services';

import styles from './Inventory.module.scss';
import useInventory from './Inventory.utils';
import InventoryHeader from './InventoryHeader';
import { sxMenuShareStyles, sxMenuStyles } from './styles';
import InventoryDrawers from './InventoryDrawers/InventoryDrawers';
import { columns, dropdownItemInCatalogCreator, dropdownItemsCreator } from './utils';

const Inventory: FC = () => {
  const dispatch = useAppDispatch();

  const { isAccessToInventoryViewer, isAccessToInventoryEditor } =
    useAppSelector(userPermissionsSelector);

  const accessToInventories = isAccessToInventoryViewer || isAccessToInventoryEditor;

  const { allParts, currentPartById, allPartsLimit, allPartsOffset, allPartsLoading } =
    useAppSelector(partsSelector);

  const foundedParts = useAppSelector(foundedInventoriesSelector);

  const { selectedInventoryItem: selectedItems, selectedIds: selectedItemsIdsFromStore } =
    useAppSelector(optionsSelector);

  const { value, debouncedValue, handleChange } = useInput();

  const { userAllRoles, userRolesLoading } = useAppSelector(authSelector);

  const filteredRoles = userAllRoles.map((role) => role.role_id);

  const isViewer =
    !filteredRoles.includes(16) && !filteredRoles.includes(5) && !filteredRoles.includes(3);

  useEffect(() => {
    if (debouncedValue?.length) {
      dispatch(findInventories({ query: value }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue?.length, value]);

  const renderedParts = debouncedValue?.length > 0 ? foundedParts : allParts;

  const { mediaSource } = useMediaFetching({
    imgId: currentPartById?.image_url_id as string,
    folderName: ImgUrlFolders.INVENTORY,
  });

  const {
    file,
    page,
    getFile,
    anchorEl,
    hideInfo,
    showInfo,
    selectAll,
    newSortBy,
    onDelete,
    isCreatePo,
    isShowInfo,
    openDrawer,
    isNewDrawer,
    closeDrawer,
    handleClose,
    isPopupOpen,
    hideCreatePo,
    togglePopup,
    selectedPart,
    isShareOpen,
    isViewInGrid,
    isSelectedAll,
    startCreatePo,
    openEditDrawer,
    selectedItemId,
    setIsViewInGrid,
    handleMoreClick,
    currentImageSrc,
    closeEditDrawer,
    isEditInventory,
    getCurrentImgSrc,
    isVendorListOpen,
    isVendorDrawerOpen,
    openVendorDrawer,
    handleRemoveSelect,
    handlePageChange,
    setIsVendorListOpen,
    handleColumnHeaderClick,
    handleRowsPerPageChange,
    closeVendorListedDrawer,
  } = useInventory(mediaSource, renderedParts);

  const footerClasses = classNames(styles.container__footer, {
    [styles.container__footer_grid]: !isViewInGrid,
  });

  useEffect(() => {
    dispatch(getAllParts()).finally(() => handleRemoveSelect());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allPartsOffset, allPartsLimit]);

  useEffect(() => {
    dispatch(fillSelectedIds([]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onInventoryClickHandler = (inventory: TPart) => {
    if (selectedItemsIdsFromStore) {
      const selectedIds = selectedItemsIdsFromStore.includes(inventory.id)
        ? selectedItemsIdsFromStore?.filter((selected) => selected !== inventory.id)
        : [...selectedItemsIdsFromStore, inventory.id];
      dispatch(fillSelectedIds(selectedIds));

      const selectedRowsIds = selectedItems.map((row) => row.id);

      const newSelectedRows = selectedRowsIds.includes(inventory.id)
        ? selectedItems.filter((row) => row.id !== inventory.id)
        : [...selectedItems, inventory];

      dispatch(setInventoryParts(newSelectedRows));

      dispatch(setInventoryParts(newSelectedRows));
    }
  };

  const dropDownItems = dropdownItemsCreator({
    editCallback: openEditDrawer,
    removeCallback: togglePopup,
    seeDetailsCallback: showInfo,
  });

  const dropdownItemInCatalog = dropdownItemInCatalogCreator({
    editCallback: openEditDrawer,
    seeDetailsCallback: showInfo,
  });

  const dropdownItemsRendered = selectedPart?.in_catalog ? dropdownItemInCatalog : dropDownItems;

  const getDropdownItems = () => {
    const items = dropdownItemsRendered?.filter((item) => {
      if (item.label !== 'See Details') {
        return !isViewer;
      }

      return true;
    });

    return items;
  };

  const totalRows = allPartsLimit;

  const renderMenuItem = getDropdownItems().map((item, idx) => {
    const onClick = () => {
      selectedItemId && item.paramsAction(selectedItemId);
      handleClose();
    };

    return (
      <MenuItem key={idx} onClick={onClick}>
        {item.icon}
        {item.label}
      </MenuItem>
    );
  });

  const renderMenuClasses = isShareOpen ? sxMenuShareStyles : sxMenuStyles;

  const renderRows = allParts?.data?.map((row) => {
    const renderNumber = generateInventoryItemNumber(row.id);

    const renderCost = row.cost ? ` $${row.cost}.00` : '';

    return {
      id: row.id,
      image: (
        <TableCell
          isTooltipDisabled
          title={
            <PreloadedImg
              width={66}
              height={50}
              withCashing={false}
              imgId={row?.image_url_id}
              imgStyles={{ objectFit: 'contain' }}
              folderName={ImgUrlFolders.INVENTORY}
            />
          }
        />
      ),
      number: (
        <ContactLink
          tagName='text'
          value={`INV-${renderNumber}`}
          onClick={() => showInfo(row?.id)}
        />
      ),
      name: row.name,
      manufacturer: row?.manufacturer || '--',
      options: (
        <IconButton onClick={(event) => handleMoreClick(event, row as any)}>
          <MoreIcon />
        </IconButton>
      ),
      sku: row?.sku || '--',
      cost: `${renderCost}` || '--',
      qty: row?.qty || '--',
    };
  });

  const sortByAscFromStorage = BrowserStorageService.get(BrowserStorageKeys.InventorySortByAsc, {
    session: true,
  });

  const sortAscParsed = sortByAscFromStorage ? JSON.parse(sortByAscFromStorage) : false;

  const renderColumns: GridColDef[] = columns.map((column) => {
    const isColumnSorted =
      column.field !== 'check' &&
      (column.field === 'number' ? newSortBy === PartsSortBy.ID : newSortBy === column.field);

    const headerClasses = classNames(
      { [styles.activeSortHeader]: !sortAscParsed },
      {
        [styles.activeSortHeader_asc]: sortAscParsed,
      },
    );

    return {
      ...column,
      sortable: false,
      hideSortIcons: true,
      disableColumnMenu: true,
      disableColumnSelector: true,
      headerClassName: isColumnSorted ? headerClasses : '',
    };
  });

  const isDisabled = !allParts?.data?.length;

  const renderChecked = selectedItemsIdsFromStore?.filter((item) =>
    renderRows?.filter((el) => el.id === item),
  );

  const renderInventories = renderedParts?.data?.map((inventory) => {
    const renderNumber = generateInventoryItemNumber(inventory.id);
    const isSelectedItem = selectedItemsIdsFromStore?.includes(inventory.id);

    return (
      <InventoryCardNew
        id={inventory.id}
        key={inventory.id}
        showInfo={showInfo}
        inventory={inventory}
        onDelete={onDelete}
        cost={inventory.cost}
        checked={isSelectedItem}
        title={`INV-${renderNumber}`}
        openDrawerInEditMode={openEditDrawer}
        quantityLeft={Number(inventory?.qty) || 20}
        onClick={() => onInventoryClickHandler(inventory)}
        imageUrl={inventory.image_url_id as string}
      />
    );
  });

  if (!accessToInventories && !userRolesLoading) {
    return <Navigate replace={true} to={Routes.ActivityFeed} />;
  }

  return (
    <>
      <Box className={styles.container}>
        <InventoryHeader
          value={value}
          isDisabled={isDisabled}
          handleChange={handleChange}
          isViewInGrid={isViewInGrid}
          createNewDrawer={openDrawer}
          startCreatePo={startCreatePo}
          setIsViewInGrid={setIsViewInGrid}
          selection={selectedItemsIdsFromStore}
          openVendorDrawer={startCreatePo}
          setIsVendorListOpen={setIsVendorListOpen}
          isCheckedExist={!!selectedItemsIdsFromStore?.length}
        />
        {!!allParts?.data?.length && isAccessToInventoryEditor && (
          <Box className={styles.container__selection}>
            {!isViewInGrid && (
              <Box className={styles.container__select} onClick={selectAll}>
                {isSelectedAll ? <FilledCheckedIcon /> : <NewUncheckedIcon />}
                <CustomTypography className={styles.container__select__text}>
                  Select All
                </CustomTypography>
              </Box>
            )}
          </Box>
        )}

        <ShowAfterAccessView emptyMessageTitle='Inventories' isShow={accessToInventories}>
          {!allParts?.data?.length && !isViewInGrid ? (
            <EmptyTitle title='No parts added yet' loadingDone={allPartsLoading} />
          ) : (
            <Box
              className={classNames(styles.container__content, {
                [styles.container__content__grid]: isViewInGrid,
              })}
            >
              {isViewInGrid ? (
                <ScrollLayout>
                  <CustomDataGrid
                    rowHeight={70}
                    headerHeight={44}
                    rows={renderRows}
                    rowSelection={true}
                    columns={renderColumns}
                    columnHeaderHeight={22}
                    isLoading={allPartsLoading}
                    rowSelectionModel={renderChecked}
                    emptyTitle={EmptyTitles.Inventories}
                    checkboxSelection={isAccessToInventoryEditor}
                    onColumnHeaderClick={handleColumnHeaderClick}
                    onCellClick={(_, event) => event.stopPropagation()}
                    onRowSelectionModelChange={(newSelection) => {
                      const selectedIDs = new Set(newSelection);
                      const selectedRowData = allParts?.data?.filter((row) =>
                        selectedIDs.has(row.id),
                      );
                      dispatch(setInventoryParts(selectedRowData));

                      dispatch(fillSelectedIds(newSelection));
                    }}
                  />
                </ScrollLayout>
              ) : (
                renderInventories
              )}

              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                sx={renderMenuClasses}
              >
                {renderMenuItem}
              </Menu>
            </Box>
          )}
        </ShowAfterAccessView>
        {accessToInventories && (
          <Box className={footerClasses}>
            {!!allParts?.data?.length && (
              <DynamicPaginationControl
                page={page}
                rowsPerPage={totalRows}
                count={allParts?.total_count}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
              />
            )}
          </Box>
        )}
      </Box>

      <InventoryDrawers
        file={file}
        getFile={getFile}
        hideInfo={hideInfo}
        onDelete={onDelete}
        onClose={togglePopup}
        isCreatePo={isCreatePo}
        isShowInfo={isShowInfo}
        isNewDrawer={isNewDrawer}
        isPopupOpen={isPopupOpen}
        closeDrawer={closeDrawer}
        hideCreatePo={hideCreatePo}
        isEditInventory={isEditInventory}
        closeEditDrawer={closeEditDrawer}
        currentImageSrc={currentImageSrc}
        openVendorDrawer={openVendorDrawer}
        isVendorListOpen={isVendorListOpen}
        getCurrentImgSrc={getCurrentImgSrc}
        isVendorDrawerOpen={isVendorDrawerOpen}
        closeVendorListedDrawer={closeVendorListedDrawer}
      />
    </>
  );
};

export default Inventory;
