import { client, endpoints } from 'api';
import {
  TFindInventoryParams,
  TSearchParams,
  TUserSearchParams,
} from 'store/slices/searchSlice/types';

export const searchInventoryRequest = ({ query, limit, offset }: TFindInventoryParams) =>
  client.get(endpoints.SearchService.searchInventory(query), { params: { limit, offset } });

export const searchCatalogRequest = (options: TSearchParams) =>
  client.get(endpoints.SearchService.searchCatalog(), { params: { ...options } });

export const searchEstimateRequest = (options: TSearchParams) =>
  client.get(endpoints.SearchService.searchEstimate(options.query), { params: { ...options } });

export const searchPurchaseRequisitionRequest = (options: TSearchParams) =>
  client.get(endpoints.SearchService.searchPurchaseRequisition(), { params: { ...options } });

export const searchPurchaseOrderRequest = (options: TSearchParams) =>
  client.get(endpoints.SearchService.searchPurchaseOrder(), { params: { ...options } });

export const searchAgreementRequest = (options: TSearchParams) =>
  client.get(endpoints.SearchService.searchAgreement(), { params: { ...options } });

export const searchWorkOrderRequest = (options: TSearchParams) =>
  client.get(endpoints.SearchService.searchWorkOrder(), { params: { ...options } });

export const searchCustomerRequest = ({ limit, offset, query }: TSearchParams) =>
  client.get(endpoints.SearchService.searchCustomer(query), { params: { limit, offset } });

export const searchInvoiceRequest = (options: TSearchParams) =>
  client.get(endpoints.SearchService.searchInvoice(), { params: { ...options } });

export const searchUserRequest = (options: TUserSearchParams) =>
  client.get(endpoints.SearchService.searchUser(options?.query), { params: { ...options } });
