import { useEffect, useRef, useState } from 'react';
import { Modal, Stack } from '@mui/material';
import { v4 } from 'uuid';
import { toast } from 'react-toastify';
import { animated, useSpring } from 'react-spring';

import EmojiPicker from 'components/shared/EmojiPicker';
import { useAppDispatch, useOnClickOutside } from 'hooks';
import { Button, CustomTypography } from 'components/shared';
import { BorderSquareIcon, EmojiIcon, GalleryIcon, VideoPlayGreenIcon } from 'assets/icons';
import {
  clearAllPostMediaData,
  clearImgMarkers,
  setInPostMediaData,
  setModalState,
} from 'store/slices/optionsSlice';
import { TMediaUploadedTypes } from 'types/global/mediaUploadedTypes';
import { TChangeInputEvent } from 'types/global/events';
import { UploadMessages } from 'constants/ToastMessages';
import { Colors } from 'types';

import { sizeLimits } from '../../../../constants';

import styles from './CreatePostModal.module.scss';
import { TCreatePost } from './types';

const CreatePostModal: React.FC<TCreatePost> = ({
  open,
  onClose,
  children,
  onSubmit,
  openEmoji,
  isDisabled,
  hideEmoji,
  showEmojiBar,
  onCloseWithClear,
  handleEmojiSelect,
}) => {
  const dispatch = useAppDispatch();

  const [isStarted, setIsStarted] = useState<boolean>(false);

  const timedOnClose = () => {
    setIsStarted(true);

    setTimeout(() => {
      onClose();
      setIsStarted(false);
    }, 1000);
  };

  const timedOnCloseClear = () => {
    setIsStarted(true);

    setTimeout(() => {
      onCloseWithClear();
      setIsStarted(false);
    }, 1000);
  };

  const springProps = useSpring({
    delay: 200,
    opacity: open ? 1 : 0,
    top: open ? '50%' : '-5%',
  });

  const onCloseProps = useSpring({
    delay: 200,
    opacity: !isStarted ? 1 : 0,
    top: !isStarted ? '50%' : '-5%',
  });

  const ref = useRef<HTMLDivElement | null>(null);

  const handleSubmitModal = () => {
    onSubmit();
    timedOnClose();
  };

  const handleEmojiClose = () => {
    hideEmoji?.();
  };

  const [files, setFiles] = useState<TMediaUploadedTypes[]>([]);

  const handleCloseModal = () => {
    dispatch(clearAllPostMediaData());
    dispatch(clearImgMarkers());
    timedOnClose();
  };

  useOnClickOutside(ref, handleEmojiClose);

  const handleAddMedia = (event: TChangeInputEvent) => {
    const selectedFiles = event.target.files;

    if (selectedFiles && !selectedFiles[0]) return;
    if (selectedFiles) {
      const filesArray = Array.from(selectedFiles);

      filesArray.forEach((selectedFile) => {
        if (selectedFile?.type?.includes('img') && selectedFile?.size > sizeLimits.image) {
          toast.info(UploadMessages.IMAGE_SIZE_EXCEEDED);
        } else if (selectedFile?.type?.includes('video') && selectedFile?.size > sizeLimits.video) {
          toast.info(UploadMessages.VIDEO_SIZE_EXCEEDED);
        } else {
          const reader = new FileReader();
          reader.onload = (e: ProgressEvent<FileReader>) => {
            if (e.target) {
              const imgUrl = e.target.result as string;

              const uploadingFile = {
                id: v4(),
                imgUrl,
                fileType: selectedFile?.type,
                file: selectedFile,
              };

              dispatch(
                setInPostMediaData({
                  id: Math.random(),
                  file: uploadingFile?.file,
                  fileSrc: uploadingFile?.imgUrl,
                  tags: [],
                }),
              );

              setFiles([...files, uploadingFile]);
            }
          };
          reader.readAsDataURL(selectedFile);
        }
      });
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    event.target.value = null;
  };

  useEffect(() => {
    dispatch(setModalState(open));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Modal open={open} onClose={handleCloseModal}>
      <animated.div className={styles.container} style={isStarted ? onCloseProps : springProps}>
        <div className={styles.container_header}>
          <CustomTypography
            color={Colors.MIDNIGHT_CHARCOAL}
            className={styles.container_header__title}
          >
            Submit a Post
          </CustomTypography>
          <BorderSquareIcon width={24} height={24} onClick={timedOnCloseClear} />
        </div>
        <div className={styles.container_children}>{children}</div>
        <div className={styles.container_footer}>
          <div className={styles.container_footer_content}>
            <div ref={ref} onClick={showEmojiBar} className={styles.container_footer_content_video}>
              <EmojiIcon />
              <CustomTypography>Emoji</CustomTypography>
              {openEmoji && (
                <div className={styles.container_emoji}>
                  <EmojiPicker onEmojiSelect={handleEmojiSelect} />
                </div>
              )}
            </div>
            <div className={styles.container_footer_content_divider} />

            <Stack width='100%'>
              <label htmlFor='upload-image'>
                <div className={styles.container_footer_content_image}>
                  <GalleryIcon />
                  <CustomTypography>Image</CustomTypography>
                  <input
                    hidden
                    multiple
                    type='file'
                    id='upload-image'
                    onChange={handleAddMedia}
                    accept='.jpg,.jpeg,.png,.webp'
                  />
                </div>
              </label>
            </Stack>
            <div className={styles.container_footer_content_divider} />
            <Stack width='100%'>
              <label htmlFor='upload-video'>
                <div className={styles.container_footer_content_image}>
                  <VideoPlayGreenIcon />
                  <CustomTypography>Video</CustomTypography>
                  <input
                    hidden
                    multiple
                    type='file'
                    accept='video/*'
                    id='upload-video'
                    onChange={handleAddMedia}
                  />
                </div>
              </label>
            </Stack>
          </div>
          <div>
            <Button
              disabled={isDisabled}
              onClick={handleSubmitModal}
              className={styles.container_footer_content_button}
            >
              Post
            </Button>
          </div>
        </div>
      </animated.div>
    </Modal>
  );
};

export default CreatePostModal;
