import { GridColDef } from '@mui/x-data-grid';

import { Routes } from 'types';
import { StatusInfo, TableCell } from 'components';
import { StatusEnums } from 'components/shared/StatusInfo/types';
import HeaderTitle from 'components/tables/EmployeeTable/HeaderTitle';

export const estimateColumns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Estimate Name',
    flex: 1.2,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle title={headerName} style={{ paddingLeft: '0' }} />;
    },
    renderCell: (params) => {
      return <TableCell title={params?.value} style={{ padding: '0', color: '#667085' }} />;
    },
  },
  {
    field: 'number',
    headerName: 'Estimate No',
    flex: 1,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle style={{ paddingLeft: '0' }} title={headerName} />;
    },
    renderCell: (params) => {
      return <TableCell title={params?.value} style={{ paddingLeft: '0', color: '#2067DD' }} />;
    },
  },
  {
    field: 'customer_name',
    headerName: 'Customer',
    flex: 1,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle style={{ paddingLeft: '0' }} title={headerName} />;
    },
    renderCell: (params) => {
      return <TableCell style={{ padding: '0', color: '#667085' }} title={params?.value} />;
    },
  },
  {
    field: 'created_by_name',
    headerName: 'Created By',
    flex: 1,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle style={{ paddingLeft: '0' }} title={headerName} />;
    },
    renderCell: (params) => {
      return <TableCell style={{ padding: '0', color: '#667085' }} title={params?.value} />;
    },
  },
  {
    field: 'created_at',
    headerName: 'Created Date',
    flex: 1,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle style={{ paddingLeft: '0' }} title={headerName} />;
    },
    renderCell: (params) => {
      return <TableCell style={{ padding: '0', color: '#667085' }} title={params?.value} />;
    },
  },

  {
    field: 'status',
    headerName: 'Status',
    flex: 0.7,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle title={headerName} style={{ paddingLeft: '0' }} />;
    },
    renderCell: () => {
      return <StatusInfo status={StatusEnums.DRAFT} title={'Draft'} />;
    },
  },

  {
    field: 'options',
    headerName: '',
    width: 50,
    renderCell: (params) => {
      return params.value;
    },
  },
];

export const breadCrumbOptions = [
  { id: 1, label: 'Workflow', goTo: Routes.WorkFlow },
  { id: 2, label: 'Estimates', goTo: Routes.BomPricingEstimate },
  { id: 2, label: 'Update Estimates', goTo: Routes.UpdateEstimateTemplateForm },
];
