import { useState, useRef, useEffect } from 'react';
import classNames from 'classnames';

import { CustomTypography } from 'components';
import { useEllipsesPresent } from 'hooks';

import styles from './ExpandableTypo.module.scss';

import type { TExpandableTypo } from './types';

const ExpandableTypo: React.FC<TExpandableTypo> = ({ title, textColor = '#000', textStyles }) => {
  const [expanded, setExpanded] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);
  const titleRef = useRef<HTMLParagraphElement | null>(null);

  const isEllipsesPresent = useEllipsesPresent(titleRef);

  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const ref = contentRef.current;
    if (ref) {
      const height = ref.clientHeight;
      setContentHeight(height);
    }
  }, [title]);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const titleClasses = classNames(styles.container_typo, {
    [styles.container_typo_expanded]: expanded,
  });

  return (
    <div className={styles.container}>
      <div ref={contentRef}>
        <p ref={titleRef} className={titleClasses} style={{ color: textColor, ...textStyles }}>
          {title}
        </p>
      </div>
      <CustomTypography
        className={styles.container_expand}
        onClick={toggleExpand}
        style={{
          display: contentHeight > 2 * 18 && isEllipsesPresent ? 'block' : 'none',
          color: textColor,
          ...textStyles,
        }}
      >
        {!expanded ? 'READ MORE...' : 'SHOW LESS'}
      </CustomTypography>
    </div>
  );
};

export default ExpandableTypo;
