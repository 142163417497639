import { FC, useState } from 'react';
import { Box } from '@mui/material';
import classNames from 'classnames';

import { timeDiffer } from 'utils';
import { useAppSelector, useUserInfoById } from 'hooks';
import { CustomTypography, ItemVersionInformation } from 'components';
import { generateInventoryItemNumber } from 'utils/generateInventoryItemNumber';
import { currentPartByIdSelector } from 'store/slices/inventorySlice/selectors';

import styles from './HistoryDetails.module.scss';

import type { THistoryDetailsProps } from './types';

const HistoryDetails: FC<THistoryDetailsProps> = ({ history }) => {
  const [isSeeDetails, setIsSeeDetails] = useState<boolean>(false);

  const currentItemData = useAppSelector(currentPartByIdSelector);

  const { userData } = useUserInfoById(history?.updated_by as number);

  const seeDetails = () => setIsSeeDetails(true);
  const hideDetails = () => setIsSeeDetails(false);

  const time = timeDiffer(history?.updated_at as string);

  const containerClasses = classNames(styles.container, {
    [styles.container_start]: history?.status === 'approved',
  });

  const renderNumber = generateInventoryItemNumber(currentItemData?.id as number);

  return (
    <>
      <Box className={containerClasses}>
        <Box className={styles.container__info}>
          <CustomTypography className={styles.container__info__title}>
            {userData?.name} Updated Item (INV-{renderNumber})
          </CustomTypography>
          <CustomTypography className={styles.container__info__view} onClick={seeDetails}>
            View Details
          </CustomTypography>
          <CustomTypography className={styles.container__info__time}>{time}</CustomTypography>
        </Box>
      </Box>
      <ItemVersionInformation history={history} open={isSeeDetails} onClose={hideDetails} />
    </>
  );
};

export default HistoryDetails;
