import React, { Suspense, lazy } from 'react';

import { TEmptyTitleProps } from 'components/shared/EmptyTitle/types';

import { TLazyLoadWithTimeoutProps } from './types';

const LazyLoadWithTimeout: React.FC<TLazyLoadWithTimeoutProps<TEmptyTitleProps>> = ({
  loader,
  timeout,
  componentProps,
  fallback = <div>Loading...</div>,
}) => {
  const LazyLoadedComponent = lazy(() => {
    return new Promise<{ default: React.ComponentType<any> }>((resolve) => {
      setTimeout(() => {
        resolve(loader(componentProps as TEmptyTitleProps));
      }, timeout);
    });
  });

  return (
    <Suspense fallback={fallback}>
      <LazyLoadedComponent {...componentProps} />
    </Suspense>
  );
};

export default LazyLoadWithTimeout;
