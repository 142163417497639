import { EmployeeManagementIcon, LogoutIcon, SettingsIcon } from 'assets/icons';
import { TDropdown } from 'components/dropdowns/Dropdown/types';
import { Routes } from 'types';

export const profileDropdownOptions: TDropdown[] = [
  { id: 0, content: 'Account Settings', Icon: <SettingsIcon />, path: Routes.AccountSettings },
  {
    id: 1,
    content: 'Employee Management',
    Icon: <EmployeeManagementIcon />,
    path: Routes.EmployeeManagement,
  },
  { id: 2, content: 'Log Out', Icon: <LogoutIcon />, path: Routes.Landing },
];
