import { FC } from 'react';
import classNames from 'classnames';

import styles from './ScrollLayout.module.scss';

import type { TScrollLayoutProps } from './types';

const ScrollLayout: FC<TScrollLayoutProps> = ({
  width,
  height,
  maxWidth,
  children,
  direction = 'x',
  withScrollStyles = false,
}) => {
  const containerClasses = classNames(styles.container, {
    [styles.container_y]: direction === 'y',
    [styles.container_scroll]: withScrollStyles,
  });

  return (
    <div className={containerClasses} style={{ height, width, maxWidth }}>
      {children}
    </div>
  );
};

export default ScrollLayout;
