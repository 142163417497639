import { FC, useEffect } from 'react';
import { Box } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { useFieldArray } from 'react-hook-form';

import {
  Button,
  ScrollLayout,
  CustomDataGrid,
  PortalDropDown,
  TableControlledRowItem,
  ControlledAutocomplete,
} from 'components';
import { FilledPlusIcon, MoreIcon, TrashIcon } from 'assets/icons';
import { Colors, FontNames } from 'types';
import { useAppDispatch, useAppSelector, useInput } from 'hooks';
import { EmptyTitles } from 'constants/EmptyTitles';
import { findInventories, getAllParts } from 'store/thunks';
import { foundedInventoriesSelector } from 'store/slices/searchSlice/selectors';
import { PartsSortBy } from 'store/slices/inventorySlice/types';

import { addNewSectionItemOptions } from '../CatalogVersions.utils';

import { columns } from './NestedItems.utils';

import type { TNestedItemsProps } from '../types';
const NestedItems: FC<TNestedItemsProps> = ({ control, idx, errors }) => {
  const dispatch = useAppDispatch();
  const { debouncedValue, handleChange } = useInput();

  const foundedInventories = useAppSelector(foundedInventoriesSelector);

  const {
    fields: itemsFields,
    append: itemsAppend,
    remove: itemRemove,
  } = useFieldArray({
    control,
    name: `sections.${idx}.items`,
  });
  const inventoryOptions = foundedInventories?.data?.map((item) => ({
    part_id: item?.id,
    part_name: item?.name,
    qty_required: item?.qty,
    description: item?.description,
  }));
  useEffect(() => {
    if (debouncedValue?.trim()?.length) {
      dispatch(findInventories({ query: debouncedValue, limit: 100 }));
    }
  }, [debouncedValue, dispatch]);

  useEffect(() => {
    dispatch(getAllParts({ offset: 0, limit: 1, asc: false, sort_by: PartsSortBy.NAME }));
  }, [dispatch]);
  const handleItemRemove = (idx: number) => {
    itemRemove(idx);
  };

  const renderedList = itemsFields?.map((row: any, itemIdx) => ({
    id: row?.id,

    item: (
      <ControlledAutocomplete
        name={`sections[${idx}].items[${itemIdx}]`}
        required
        variant='standard'
        control={control}
        borderRadius='10px'
        inputValue={row?.part_name}
        optionsName='part_name'
        placeholder='Item Name:*'
        errors={!!errors?.sections?.[idx]?.items?.[itemIdx]?.part_name}
        options={inventoryOptions}
        handleInputChange={handleChange}
        backgroundColor={Colors.FROST_WHITE}
      />
    ),

    description: (
      <TableControlledRowItem
        required
        isChangeable
        control={control}
        keyName='description'
        value={row?.description}
        componentType='autocomplete'
        inputOptions={inventoryOptions}
        inputOptionsName='description'
        placeholder='Description'
        isExisted={row?.hasBefore}
        disabled={foundedInventories?.total_count === 0}
        name={`sections[${idx}].items[${itemIdx}]`}
        error={!!errors?.sections?.[idx]?.items?.[itemIdx]?.description}
      />
    ),

    quantity: (
      <TableControlledRowItem
        required
        asText
        control={control}
        isSingleControlled
        placeholder='Quantity'
        keyName='qty_required'
        value={row?.qty_required}
        isExisted={row?.hasBefore}
        componentType='autocomplete'
        inputOptionsName='qty_required'
        name={`sections[${idx}].items[${itemIdx}]`}
        disabled={foundedInventories?.total_count === 0}
        error={errors?.sections?.[idx]?.items?.[itemIdx]?.qty_required?.message}
      />
    ),

    options: itemIdx !== 0 && (
      <PortalDropDown
        title={<MoreIcon />}
        options={[
          {
            id: 2,
            name: 'Delete',
            disabled: foundedInventories?.total_count === 0,
            action: () => handleItemRemove(itemIdx),
            icon: <TrashIcon />,
          },
        ]}
      />
    ),
  }));

  const renderColumns: GridColDef[] = columns.map((column) => ({
    ...column,
    sortable: false,
    hideSortIcons: true,
    disableColumnMenu: true,
    disableColumnSelector: true,
  }));

  return (
    <Box sx={{ marginBottom: '24px' }}>
      <ScrollLayout>
        <CustomDataGrid
          emptyTitle={EmptyTitles.Catalog_Versions}
          rows={renderedList}
          columns={renderColumns}
        />
      </ScrollLayout>

      <Button
        color={Colors.BLACK}
        isUppercase={false}
        startIcon={<FilledPlusIcon />}
        fontFamily={FontNames.CIRCULAR_REG}
        disabled={foundedInventories?.total_count === 0}
        onClick={() => itemsAppend(addNewSectionItemOptions)}
      >
        Add new item
      </Button>
    </Box>
  );
};

export default NestedItems;
