import { Box, Menu, MenuItem, Tooltip } from '@mui/material';
import React, { BaseSyntheticEvent, useRef, useState } from 'react';

import { BadgeIcon, EditIconOutLined, HideIcon, MoreIcon, TrashIcon } from 'assets/icons';
import {
  Chip,
  EditTeamModal,
  ViewTeamDrawer,
  CustomTypography,
  CustomizedAvatar,
} from 'components';
import { useAppSelector } from 'hooks';
import DeletePopup from 'components/views/DeletePopup';
import { userAllRolesSelector } from 'store/slices/authSlice/selectors';

import { TTeamInfo, TTeamProps } from './types';
import styles from './TeamCard.module.scss';
import { sxMenuStyles } from './styles';

const TeamCard: React.FC<TTeamProps> = ({
  item,
  team,
  setTeam,
  handleEditTeam,
  filteredMembers,
  handleDeleteTeam,
}) => {
  const menuRef = useRef<HTMLDivElement | null>(null);

  const userRoles = useAppSelector(userAllRolesSelector);

  const filteredRoles = userRoles.map((role) => role.role_id);

  const isViewer =
    !filteredRoles.includes(18) && !filteredRoles.includes(5) && !filteredRoles.includes(3);

  const [anchorEl, setAnchorEl] = useState(null);
  const [viewTeam, setViewTeam] = useState<boolean>(false);
  const [isMoreOpen, setIsMoreOpen] = useState<boolean>(false);
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const [editTeamDrawer, setEditDrawer] = useState<boolean>(false);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const [value, setValue] = useState<TTeamInfo>({
    name: item.name,
    description: item.description,
  });

  const closeDrawer = () => {
    setEditDrawer(false);
    setViewTeam(false);
  };

  const openDrawer = () => setEditDrawer(true);
  const openViewTeam = () => setViewTeam(true);

  const onDelete = () => {
    handleDeleteTeam(item.id);
    togglePopup();
  };

  const handleClose = () => setAnchorEl(null);

  const handleMoreClick = (event: BaseSyntheticEvent) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClick = () => setIsMoreOpen(true);

  const dropdownItems = [
    {
      icon: <HideIcon />,
      label: 'View Details',
      paramsAction: openViewTeam,
    },
    {
      icon: <EditIconOutLined />,
      label: 'Edit',
      paramsAction: openDrawer,
    },
    {
      icon: <TrashIcon />,
      label: 'Delete',
      paramsAction: togglePopup,
    },
  ];

  const renderMenuItem = dropdownItems.map((el, idx) => {
    const onClick = () => {
      item.id && el.paramsAction();
      handleClose();
    };

    return (
      <MenuItem key={idx} onClick={onClick}>
        {el.icon}
        {el.label}
      </MenuItem>
    );
  });

  const uniqueIds = new Set();

  const filteredTags = item?.tags?.filter((item) => {
    if (!uniqueIds.has(item.id)) {
      uniqueIds.add(item.id);
      return true;
    }
    return false;
  });

  const renderTags = filteredTags?.map(({ name, id, color }) => (
    <Box key={id} className={styles.container__chip__item}>
      <Chip color={color} label={name} />
    </Box>
  ));

  const renderMenuClasses = sxMenuStyles;

  const renderTeamMembers = item?.members
    .slice(0, !isMoreOpen ? 4 : item.members.length)
    .map(({ id, role_id, profile_image_url_id, position }) => (
      <CustomizedAvatar
        key={id}
        withBorder
        alt='User Image'
        role_id={role_id}
        src={profile_image_url_id}
        className={styles.container__users__item}
        borderWidth={position === 'member' ? 1 : 3}
        borderColor={position === 'member' ? 'white' : '#FC0'}
      />
    ));

  const renderMenuItemsBasedOnAccess = () => {
    if (!isViewer) {
      return renderMenuItem;
    } else {
      const viewDetailsItem = renderMenuItem.find(
        (el) => el.props.children[length - 1] === 'View Details',
      );
      return viewDetailsItem ? [viewDetailsItem] : [];
    }
  };

  const teamCardRenderer = (
    <Box className={styles.container}>
      <Box className={styles.container__head}>
        <CustomTypography className={styles.container__head__title}>{item.name}</CustomTypography>

        <MoreIcon onClick={handleMoreClick} className={styles.container__head__icon} />
      </Box>

      <Box className={styles.container__chip}>
        <BadgeIcon />
        {renderTags}
      </Box>
      <Tooltip
        componentsProps={{
          tooltip: {
            sx: {
              maxHeight: '200px',
              overflowY: 'auto',
              padding: '12px',
              zIndex: 9999,
              fontFamily: 'CircularStdRegular',
              fontSize: '12px',
            },
          },
        }}
        title={item.description}
        placement='top-start'
      >
        <div className={styles.container__description}>{item.description}</div>
      </Tooltip>

      <Box className={styles.container__users}>
        {renderTeamMembers}
        {item?.members?.length > 4 && !isMoreOpen && (
          <Box className={styles.container__users__plus} onClick={handleClick}>
            +{Number(item?.members?.length) - 4}
          </Box>
        )}
      </Box>
    </Box>
  );

  return (
    <React.Fragment>
      {teamCardRenderer}
      <EditTeamModal
        team={team}
        item={item}
        value={value}
        setTeam={setTeam}
        setValue={setValue}
        open={editTeamDrawer}
        closeDrawer={closeDrawer}
        setViewTeam={setViewTeam}
        handleEditTeam={handleEditTeam}
        filteredMembers={filteredMembers}
      />

      <ViewTeamDrawer
        item={item}
        team={team}
        open={viewTeam}
        setTeam={setTeam}
        onClose={closeDrawer}
        setViewTeam={setViewTeam}
        closeDrawer={closeDrawer}
        onClearClose={closeDrawer}
        filteredMembers={filteredMembers}
      />
      <Menu
        ref={menuRef}
        anchorEl={anchorEl}
        onClose={handleClose}
        sx={renderMenuClasses}
        open={Boolean(anchorEl)}
      >
        {renderMenuItemsBasedOnAccess()}
      </Menu>
      <DeletePopup
        withBody
        onDelete={onDelete}
        isOpen={isPopupOpen}
        onClose={togglePopup}
        title='Delete Confirmation'
        body='This will delete the team. Please confirm.'
      />
    </React.Fragment>
  );
};

export default TeamCard;
