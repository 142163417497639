import { FC } from 'react';

import { PostCloseIcon } from 'assets/icons';
import { useAppDispatch, useWindowSize } from 'hooks';
import { updatePostMediaData } from 'store/slices/optionsSlice';

import { breakPoints } from '../../../constants';

import styles from './PostMediaElement.module.scss';

import type { TPostMediaElementProps } from './types';

const PostMediaElement: FC<TPostMediaElementProps> = ({
  media,
  mediaData,
  width = 150,
  height = 150,
}) => {
  const dispatch = useAppDispatch();

  const isImage = media?.file?.type?.startsWith('image');

  const { width: clientWidth } = useWindowSize();

  const elementWidth = Number(clientWidth) <= breakPoints.TABLET_L ? 100 : width;
  const elementHeight = Number(clientWidth) <= breakPoints.TABLET_L ? 100 : height;

  const deleteImage = () => {
    const filteredData = mediaData?.filter((el) => el.id !== media.id);

    dispatch(updatePostMediaData(filteredData));
  };

  return (
    <div>
      <div className={styles.container}>
        <PostCloseIcon className={styles.container__icon} onClick={deleteImage} />
        {isImage ? (
          <img width={elementWidth} height={elementHeight} src={media?.fileSrc} alt='media' />
        ) : (
          <video autoPlay width={elementWidth} height={elementHeight} controls>
            <source src={media?.fileSrc} type='video/mp4'></source>
          </video>
        )}
      </div>
    </div>
  );
};

export default PostMediaElement;
