import { useState, useEffect } from 'react';

import { ImageBlank } from 'assets/images';

type TUseImageLoadingProps = {
  imageUrl: string;
};

type TUseImageLoadingResult = {
  imageLoaded: boolean;
  imageError: boolean;
};

const useImageLoading = ({ imageUrl }: TUseImageLoadingProps): TUseImageLoadingResult => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [imageError, setImageError] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = imageUrl;

    const handleImageLoad = () => {
      setImageLoaded(true);
    };

    const handleImageError = () => {
      setImageError(true);
      setImageLoaded(false);
      img.src = ImageBlank;
    };

    if (img.complete) {
      handleImageLoad();
    } else {
      img.onload = handleImageLoad;
      img.onerror = handleImageError;
    }

    return () => {
      img.onload = null;
      img.onerror = null;
    };
  }, [imageUrl]);

  return { imageLoaded, imageError };
};

export default useImageLoading;
