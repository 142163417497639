import { FC } from 'react';

import { CreateLiveAddNoteModal, DrawerLayout } from 'components/shared';
import { useWindowSize } from 'hooks';

import { breakPoints } from '../../../../constants';

import { TNotesDrawer } from './types';

const NotesDrawer: FC<TNotesDrawer> = ({ open, onClose, contentRef }) => {
  const { width } = useWindowSize();

  const drawerWidth =
    Number(width) <= breakPoints.TABLET_L && Number(width) > breakPoints?.TABLET_M
      ? 600
      : Number(width) <= breakPoints.TABLET_M && Number(width) >= breakPoints.MOBILE
      ? 476
      : Number(width) <= breakPoints.MOBILE
      ? 375
      : 888;

  return (
    <DrawerLayout
      width={drawerWidth}
      open={open}
      onClose={onClose}
      headerTitle=''
      withHeader={false}
      padding='0'
    >
      <CreateLiveAddNoteModal
        open={open}
        onClose={onClose}
        onCancel={onClose}
        submitBtnText='SAVE'
        contentRef={contentRef}
        cancelBtnText='CANCEL'
      />
    </DrawerLayout>
  );
};

export default NotesDrawer;
