import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Box, CircularProgress } from '@mui/material';
import { Navigate } from 'react-router-dom';

import {
  TeamCard,
  EmptyTitle,
  NewTeamModal,
  ShowAfterAccessView,
  DynamicPaginationControl,
} from 'components';
import { useAppDispatch, useAppSelector } from 'hooks';
import { TOrganizationMember } from 'store/slices/organizationsSlice/types';
import { getOrganizationMembers } from 'store/slices/organizationsSlice/thunks';
import { getUserTeams, updateUserTeam, removeUserTeam } from 'store/slices/teamsSlice/thunks';
import { TUpdateTeam } from 'store/slices/teamsSlice/types';
import {
  allTeamsSelector,
  allTeamsLimitSelector,
  allTeamsOffsetSelector,
} from 'store/slices/teamsSlice/selectors';
import { clearTeamsState, setLimit, setPage } from 'store/slices/teamsSlice';
import {
  userAllRolesLoadingSelector,
  userPermissionsSelector,
} from 'store/slices/authSlice/selectors';
import { BrowserStorageKeys, BrowserStorageService } from 'services';
import { Routes } from 'types';

import styles from './Team.module.scss';
import { initialTeamState } from './constant';
import TeamHead from './TeamHead';

import type { TTeam } from 'components/views/Modals/NewTeamModal/types';

const currentOrganization = BrowserStorageService.get(BrowserStorageKeys.CurrentOrganizationId);

const Team: React.FC = () => {
  const dispatch = useAppDispatch();

  const teamsData = useAppSelector(allTeamsSelector);
  const teamsDataLimit = useAppSelector(allTeamsLimitSelector);
  const teamsDataOffset = useAppSelector(allTeamsOffsetSelector);
  const userRolesLoading = useAppSelector(userAllRolesLoadingSelector);

  const { isAccessToTeamsViewer, isAccessToTeamsEditor } = useAppSelector(userPermissionsSelector);

  const haveAccessToTeams = isAccessToTeamsViewer || isAccessToTeamsEditor;

  const [currentPage, setCurrentPage] = useState(0);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [team, setTeam] = useState<TTeam>(initialTeamState);
  const [members, setMembers] = useState<TOrganizationMember[] | []>([]);

  const showLoading = teamsData === null || teamsData?.total_count === 0;

  const handleOpenDrawer = () => {
    setOpenDrawer(true);
  };

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };

  const getMembers = async () => {
    const response = await dispatch(
      getOrganizationMembers({ org_id: Number(currentOrganization), limit: 100 }),
    );

    setMembers(response.payload?.members);
  };

  const getUserTeamsRequest = useCallback(async () => {
    await dispatch(getUserTeams({ org_id: Number(currentOrganization) }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getMembers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(clearTeamsState());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getUserTeamsRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrganization, teamsDataLimit, teamsDataOffset]);

  const handleDeleteItem = useCallback((id: number) => {
    dispatch(
      removeUserTeam({
        org_id: Number(currentOrganization),
        team_id: id,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEditTeam = useCallback((options: TUpdateTeam) => {
    dispatch(updateUserTeam(options));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDrawerClose = () => {
    handleCloseDrawer();
  };

  const renderTeamsCard = teamsData?.data?.map((item) => (
    <TeamCard
      item={item}
      team={team}
      key={item.id}
      setTeam={setTeam}
      filteredMembers={members}
      handleEditTeam={handleEditTeam}
      handleDeleteTeam={handleDeleteItem}
    />
  ));

  const handlePageChange = useCallback((_event: unknown, newPage: number) => {
    setCurrentPage(newPage);
    dispatch(setPage(newPage + 1));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRowsPerPageChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    handlePageChange(null, 0);
    dispatch(setLimit(event.target.value));
  };

  useEffect(() => {
    if (!teamsData?.total_count && currentPage) {
      setCurrentPage(currentPage - 1);
      handlePageChange(null, currentPage - 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamsData?.total_count]);

  if (!haveAccessToTeams && !userRolesLoading) {
    return <Navigate replace={true} to={Routes.ActivityFeed} />;
  }

  return (
    <div className={styles.container}>
      <TeamHead disabled={!isAccessToTeamsEditor} onClick={handleOpenDrawer} />

      <ShowAfterAccessView emptyMessageTitle='Teams' isShow={haveAccessToTeams}>
        {showLoading ? (
          <div className={styles.container_loading}>
            <CircularProgress />
          </div>
        ) : teamsData?.total_count > 0 ? (
          <div className={styles.container_content}>{renderTeamsCard}</div>
        ) : (
          <EmptyTitle title='No teams added yet' />
        )}
        {!!teamsData?.total_count && (
          <Box className={styles.container_footer}>
            <DynamicPaginationControl
              page={currentPage}
              onPageChange={handlePageChange}
              rowsPerPage={teamsDataLimit}
              count={teamsData?.total_count}
              onRowsPerPageChange={handleRowsPerPageChange}
            />
          </Box>
        )}

        <NewTeamModal
          team={team}
          setTeam={setTeam}
          open={openDrawer}
          members={members}
          onClose={onDrawerClose}
        />
      </ShowAfterAccessView>
    </div>
  );
};

export default Team;
