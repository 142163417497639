import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { LogoIcon } from 'assets/icons';
import { Routes } from 'types';

import styles from './LogoHeader.module.scss';

const LogoHeader = () => {
  const navigate = useNavigate();

  const goToHome = () => navigate(Routes.Landing);

  return (
    <Box className={styles.container}>
      <LogoIcon onClick={goToHome} />
    </Box>
  );
};

export default LogoHeader;
