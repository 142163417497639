import { useEffect } from 'react';

import { BrowserStorageKeys, BrowserStorageService } from 'services';

const useRefreshClearState = () => {
  const handleBeforeUnload = () => {
    window.history.replaceState({}, document.title, window.location.pathname);
    BrowserStorageService.remove(BrowserStorageKeys.sortingTeamIds, { session: true });
    BrowserStorageService.remove(BrowserStorageKeys.sortingSource, { session: true });
    BrowserStorageService.remove(BrowserStorageKeys.activeSortedValues, { session: true });
  };

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  return {
    handleBeforeUnload,
  };
};

export default useRefreshClearState;
