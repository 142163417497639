import React, { useState, useRef, useEffect, useMemo } from 'react';
import { FormControl, FormGroup } from '@mui/material';

import { CustomTypography } from 'components';
import { BlackArrow, FilterIcon } from 'assets/icons';
import { getUserTeams } from 'store/slices/teamsSlice/thunks';
import { allTeamsSelector } from 'store/slices/teamsSlice/selectors';
import { getAllActivities } from 'store/slices/activitiesSlices/thunks';
import { useAppDispatch, useAppSelector, useOnClickOutside } from 'hooks';
import { BrowserStorageKeys, BrowserStorageService } from 'services';

import styles from './PostFilter.module.scss';
import { TPostFilterStates } from './types';
import PostFilterItem from './PostFilterItem';

const PostFilter: React.FC = () => {
  const dispatch = useAppDispatch();

  const userTeams = useAppSelector(allTeamsSelector);
  const currentOrganization = BrowserStorageService.get(BrowserStorageKeys.CurrentOrganizationId);
  const currentActiveValues = BrowserStorageService.get(BrowserStorageKeys.activeSortedValues, {
    session: true,
  });

  const teamSource = BrowserStorageService.get(BrowserStorageKeys.sortingSource, {
    session: true,
  });

  const dropdownRef = useRef<null>(null);
  const [expanded, setExpanded] = useState(false);

  const teamValues = useMemo(
    () =>
      userTeams?.data?.map((team) => ({
        id: team?.id,
        value: team?.name,
        checked: false,
      })) || [],
    [userTeams],
  );

  const newData = useMemo(() => [{ id: 0, value: 'All Activities', checked: true }], []);

  const renderedData = useMemo(() => {
    return teamSource
      ? currentActiveValues && typeof currentActiveValues === 'string'
        ? JSON.parse(currentActiveValues)
        : [...newData, ...teamValues]
      : [...newData, ...teamValues];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newData, teamValues]);

  const [activeValues, setActiveValues] = useState<TPostFilterStates[]>(renderedData);

  useEffect(() => {
    if (currentOrganization) {
      dispatch(getUserTeams({ org_id: Number(currentOrganization), offset: 0, limit: 50 }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrganization]);

  useEffect(() => {
    setActiveValues(renderedData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [renderedData]);

  const handleButtonClick = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  const closeDropdown = () => {
    setExpanded(false);
  };

  const arrowStyle = {
    transform: expanded ? 'rotate(180deg)' : 'none',
    transition: 'transform 0.3s',
    cursor: 'pointer',
    display: 'flex',
  };

  const handleGetActiveValue = (value: TPostFilterStates) => {
    let newDataValues = activeValues.map((item) => {
      if (item?.id === value?.id) {
        return { ...item, checked: value?.checked };
      }
      return item;
    });

    if (value.id === 0 && value.checked) {
      newDataValues = newDataValues.map((item) => ({
        ...item,
        checked: item.id === 0 ? true : false,
      }));
      BrowserStorageService.remove(BrowserStorageKeys.sortingTeamIds, { session: true });
      BrowserStorageService.remove(BrowserStorageKeys.sortingSource, { session: true });
      dispatch(getAllActivities({ limit: 50, offset: 0 }));
      closeDropdown();
    } else {
      newDataValues = newDataValues.map((item) => ({
        ...item,
        checked: item.id === 0 ? false : item.checked,
      }));

      const sortingTeamIds = newDataValues
        ?.slice(1, activeValues?.length)
        ?.map((item) => {
          if (item?.checked) {
            return item?.id;
          }
        })
        ?.join(',');

      const values = BrowserStorageService.get(BrowserStorageKeys.sortingTeamIds, {
        session: true,
      });

      if (values) {
        BrowserStorageService.update(BrowserStorageKeys.sortingTeamIds, sortingTeamIds, {
          session: true,
        });
      } else {
        BrowserStorageService.set(BrowserStorageKeys.sortingTeamIds, sortingTeamIds, {
          session: true,
        });
      }

      BrowserStorageService.set(BrowserStorageKeys.sortingTeamIds, sortingTeamIds, {
        session: true,
      });

      BrowserStorageService.set(BrowserStorageKeys.sortingSource, 'team', {
        session: true,
      });

      dispatch(
        getAllActivities({
          team_ids: sortingTeamIds,
          source: 'team',
          limit: 10,
          offset: 0,
        }),
      );
    }

    if (teamSource) {
      BrowserStorageService.set(
        BrowserStorageKeys.activeSortedValues,
        JSON.stringify(newDataValues),
        { session: true },
      );
    } else {
      BrowserStorageService.update(
        BrowserStorageKeys.activeSortedValues,
        JSON.stringify(newDataValues),
        { session: true },
      );
    }

    setActiveValues(newDataValues);
  };

  const checkIfNothingToCheck = activeValues?.every((item) => !item?.checked);

  useEffect(() => {
    if (checkIfNothingToCheck) {
      const updatedValues = [...activeValues];
      updatedValues[0].checked = true;
      setActiveValues(updatedValues);
      BrowserStorageService.remove(BrowserStorageKeys.sortingTeamIds, { session: true });
      BrowserStorageService.remove(BrowserStorageKeys.sortingSource, { session: true });
      BrowserStorageService.remove(BrowserStorageKeys.activeSortedValues, { session: true });
      dispatch(getAllActivities({ limit: 10, offset: 0 }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkIfNothingToCheck]);

  const contentRenderer = activeValues?.map((item) => (
    <PostFilterItem
      key={item?.id}
      id={item?.id}
      value={item}
      checked={item?.checked}
      onChange={handleGetActiveValue}
    />
  ));

  useOnClickOutside(dropdownRef, closeDropdown);

  return (
    <div className={styles.container}>
      <div className={styles.container__head} style={{ maxWidth: 'fit-content' }} ref={dropdownRef}>
        <div className={styles.container_content} onClick={handleButtonClick}>
          <FilterIcon />
          <CustomTypography className={styles.container__title}>Filter</CustomTypography>
          <div style={arrowStyle}>
            <BlackArrow />
          </div>
        </div>
        {expanded && (
          <div className={styles.container_absolute}>
            <FormControl>
              <FormGroup>{contentRenderer}</FormGroup>
            </FormControl>
          </div>
        )}
      </div>
    </div>
  );
};

export default PostFilter;
