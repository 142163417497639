/* eslint-disable @typescript-eslint/no-unused-vars */
import { ChangeEvent, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';

import {
  Button,
  DeletePopup,
  ScrollLayout,
  PortalDropDown,
  CustomDataGrid,
  CustomTypography,
  CustomBreadcrumbs,
  DynamicPaginationControl,
} from 'components';
import { FontNames } from 'types';
import { MoreIcon, NewPlusIcon } from 'assets/icons';
import { useAppDispatch, useAppSelector } from 'hooks';
import { setExcelLimit, setExcelPage } from 'store/slices/inventorySlice';
import { getExcelById, removeExcelById, getExcelList, setDefaultExcelById } from 'store/thunks';
import {
  excelListLimitSelector,
  excelListOffsetSelector,
  excelListSelector,
  excelListAscSelector,
} from 'store/slices/inventorySlice/selectors';
import { GetExcelListParamsSort, TExcel } from 'store/slices/inventorySlice/types';
import { EmptyTitles } from 'constants/EmptyTitles';
import AddExcelDrawer from 'containers/Inventory/ExcelList/AddVendor';

import styles from './Vendors.module.scss';
import { breadCrumbsOptions, columns } from './utils';
import { dropDownOptionsCreator } from './Vendors.utils';

const ExcelPricingTemplate = () => {
  const dispatch = useAppDispatch();

  const allExcel = useAppSelector(excelListSelector);
  const allExcelAsc = useAppSelector(excelListAscSelector);
  const allExcelLimit = useAppSelector(excelListLimitSelector);
  const allExcelOffset = useAppSelector(excelListOffsetSelector);

  const [page, setPage] = useState<number>(1);
  const [, setIsCreatePo] = useState<boolean>(false);
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false);
  const [isOpenEditDrawer, setIsOpenEditDrawer] = useState<boolean>(false);
  const [selectedVendor, setSelectedVendor] = useState<TExcel | null>(null);

  const [newSortBy, setNewSortBy] = useState<GetExcelListParamsSort | null>(null);

  const handleColumnHeaderClick = (column: GridColDef) => {
    setNewSortBy(
      column.field === 'number'
        ? GetExcelListParamsSort.ID
        : (column.field as GetExcelListParamsSort),
    );

    dispatch(
      getExcelList({
        sort_by: newSortBy,
        asc: allExcelAsc,
        limit: allExcelLimit,
        offset: allExcelOffset,
      }),
    );
  };

  const UpdatetogglePopup = async () => {
    await dispatch(setDefaultExcelById(selectedRow || (0 as number)));
    setSelectedRow(null);
  };

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const renderColumns: GridColDef[] = columns.map((column) => {
    const isColumnSorted =
      column.field === 'number'
        ? newSortBy === GetExcelListParamsSort.ID
        : newSortBy === column.field;
    return {
      ...column,
      sortable: false,
      hideSortIcons: true,
      disableColumnMenu: true,
      disableColumnSelector: true,
      headerClassName: isColumnSorted ? styles.activeSortHeader : '',
    };
  });

  const openDrawer = () => setIsOpenDrawer(true);

  const getSelectedItem = (item: TExcel) => setSelectedVendor(item);

  const handlePageChange = (_event: unknown, newPage: number) => {
    setPage(newPage);
    dispatch(setExcelPage(newPage + 1));
  };

  const handleEditVendor = async () => {
    await dispatch(getExcelById(selectedVendor?.id as number));
    setIsOpenEditDrawer(true);
  };

  const handleRemoveVendor = async () => {
    await dispatch(removeExcelById(selectedVendor?.id as number));
    setSelectedVendor(null);
    setIsPopupOpen(false);
  };

  const handleCreatePO = async () => {
    setIsCreatePo(true);
  };

  const handleRowsPerPageChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    handlePageChange(null, 0);
    dispatch(setExcelLimit(event.target.value));
  };

  useEffect(() => {
    dispatch(getExcelList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allExcelLimit, allExcelOffset]);

  const dropDownOptions = dropDownOptionsCreator({
    removeCallback: togglePopup,
  });
  const [selectedRow, setSelectedRow] = useState(null);
  const renderRows = allExcel?.data?.map((row) => {
    return {
      id: row.id,
      name: row?.name,
      version_tag: row.version_tag,
      created_by: row.creator_name,
      created_at: row.created_at.substring(0, 10),
      options: (
        <PortalDropDown
          selectedItem={row}
          selectedId={row?.id}
          title={<MoreIcon />}
          options={dropDownOptions}
          getSelectedItem={getSelectedItem}
        />
      ),
    };
  });
  useEffect(() => {
    if (!allExcel?.total_count && page) {
      setPage(page - 1);
      handlePageChange(null, page - 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allExcel?.total_count]);

  const handleRowSelection = (selection: string | any[]) => {
    if (selection.length > 0) {
      setSelectedRow(selection[0]);
    } else {
      setSelectedRow(null);
    }
  };
  return (
    <>
      <Box className={styles.container}>
        <CustomBreadcrumbs options={breadCrumbsOptions} />
        <Box className={styles.container__head}>
          <CustomTypography className={styles.container__head__title}>
            Pricing Templates
          </CustomTypography>
          <Button
            color='#2067DD'
            borderRadius='5px'
            padding='10px 16px'
            disableColor='#fff'
            onClick={UpdatetogglePopup}
            backgroundColor='#D2E1F9'
            fontFamily={FontNames.CIRCULAR_REG}
            className={styles.container__head__create__button}
            disabled={selectedRow === null}
          >
            Set As Default
          </Button>
          <Button
            color='white'
            fontSize='14px'
            borderRadius='5px'
            padding='10px 6px'
            onClick={openDrawer}
            backgroundColor='#2067DD'
            fontFamily={FontNames.CIRCULAR_REG}
            startIcon={<NewPlusIcon width={24} height={24} />}
            className={styles.container__head__create__button}
          >
            Upload Template
          </Button>
        </Box>

        <Box className={styles.container__main}>
          <ScrollLayout>
            <CustomDataGrid
              rowHeight={64}
              headerHeight={44}
              rows={renderRows}
              checkboxSelection
              rowSelectionModel={selectedRow !== null ? [selectedRow] : []}
              rowSelection={true}
              columns={renderColumns}
              emptyTitle={EmptyTitles.Templates}
              onColumnHeaderClick={handleColumnHeaderClick}
              onRowSelectionModelChange={handleRowSelection}
              disableColumnMenu={true}
            />
          </ScrollLayout>
        </Box>
        <Box className={styles.container__footer}>
          {!!allExcel?.data?.length && (
            <DynamicPaginationControl
              page={page}
              rowsPerPage={allExcelLimit}
              count={allExcel?.total_count}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
            />
          )}
        </Box>
      </Box>
      <AddExcelDrawer isOpen={isOpenDrawer} setIsOpen={setIsOpenDrawer} />
      <AddExcelDrawer isOpen={isOpenEditDrawer} setIsOpen={setIsOpenEditDrawer} inEditMode />
      <DeletePopup
        withBody
        isOpen={isPopupOpen}
        onClose={togglePopup}
        title='Decline Confirmation'
        onDelete={handleRemoveVendor}
        body='Please confirm if you wants to delete the template.'
      />
    </>
  );
};

export default ExcelPricingTemplate;
