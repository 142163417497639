import { BillingInfo, CustomerInfo, GeneralInfo } from 'components/shared/EstimateForms';

export const estimateInfoDetailMock = [
  {
    id: 1,
    title: 'Customer Information',
    Component: CustomerInfo,
  },
  {
    id: 2,
    title: 'Billing Information',
    Component: BillingInfo,
  },
  {
    id: 3,
    title: 'General Information',
    Component: GeneralInfo,
  },
];

export const defaultCustomerInfo = {
  bill_to: {
    bill_to: '',
    phone_number: '',
    address: '',
    city: '',
    state: '',
    zip: '',
  },
  phone: '',
  address: '',
  city: '',
  state: '',
  zip: '',
  general_info: '',
  customer_info: {
    full_name: '',
    email: '',
    phone_number: '',
    address: '',
    city: '',
    state: '',
    zip: '',
  },
};

export const paymentScheduleData = [
  { title: 'Work Completion %', id: 1, value: 'percent' },
  { title: 'Amount', id: 2, value: 'amount' },
];
