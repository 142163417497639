import { FC } from 'react';
import { Box, Stack } from '@mui/material';
import classNames from 'classnames';

import { ImgUrlFolders } from 'types';
import { DefaultImg } from 'assets/images';
import { ClockLiveIcon } from 'assets/icons';
import { createMarkup, timeDiffer } from 'utils';
import { CustomTypography } from 'components/shared';
import PreloadedImg from 'components/views/PreloadedImg';

import styles from './StreamNote.module.scss';

import type { TStreamNoteProps } from '../types';

const StreamNote: FC<TStreamNoteProps> = ({ note, isLast = false }) => {
  const noteMediaElements = note?.media?.map((media) => {
    if (media?.file_type === 'video') {
      return <video key={media?.id} src={DefaultImg} />;
    } else {
      return (
        <PreloadedImg
          width={150}
          height={150}
          key={media?.id}
          borderRadius='10px'
          folderName={ImgUrlFolders.LIVE_STREAM}
          imgId={media?.media_url_id}
        />
      );
    }
  });

  const containerClasses = classNames(styles.container, { [styles.container_last]: isLast });

  return (
    <Box className={containerClasses}>
      <Box className={styles.container__content}>
        <Box className={styles.container__content__head}>
          <ClockLiveIcon />
          <CustomTypography className={styles.container__content__time}>
            {timeDiffer(note?.created_at)}
          </CustomTypography>
        </Box>
        <Stack direction='column' gap='10px'>
          <CustomTypography
            className={styles.container__content__text}
            dangerouslySetInnerHTML={createMarkup(note?.content as string)}
          ></CustomTypography>

          {!!note?.media?.length && (
            <Box className={styles.container__main}>{noteMediaElements}</Box>
          )}
        </Stack>
      </Box>
    </Box>
  );
};

export default StreamNote;
