import { FC } from 'react';

import { DrawerLayout, EditUserInfoModal } from 'components';
import { useWindowSize } from 'hooks';

import { breakPoints } from '../../../../constants';

import type { TEditUserDrawerProps } from './types';

const EditUserDrawer: FC<TEditUserDrawerProps> = ({ open, handleClose, user }) => {
  const { width } = useWindowSize();

  const drawerWidth =
    Number(width) <= breakPoints.TABLET_L && Number(width) > breakPoints?.TABLET_M
      ? 576
      : Number(width) <= breakPoints.TABLET_M && Number(width) >= breakPoints.MOBILE
      ? 458
      : Number(width) <= breakPoints.MOBILE
      ? 375
      : 975;

  return (
    <DrawerLayout
      open={open}
      padding='40px'
      inCenter={false}
      width={drawerWidth}
      onClose={handleClose}
      headerTitle='Edit User Info'
    >
      <EditUserInfoModal user={user} onClose={handleClose} />
    </DrawerLayout>
  );
};

export default EditUserDrawer;
