import React, { FC, useEffect, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { createFormData } from 'utils';
import { Button, DrawerLayout } from 'components';
import {
  useWindowSize,
  useAppDispatch,
  useAppSelector,
  useMediaFetching,
  useFileInputChanges,
} from 'hooks';
import {
  getAllParts,
  createCatalog,
  updateCatalog,
  getCatalogList,
  getCatalogById,
  createCatalogVersion,
  addGeneralInformation,
  updateCatalogTemplate,
  createCatalogTemplate,
  updateGeneralInformation,
} from 'store/thunks';
import { Colors, ImgUrlFolders } from 'types';
import { couponsSelector } from 'store/slices/discountSlice/selectors';
import { catalogsSliceSelector } from 'store/slices/catalogSlice/selectors';
import {
  createCoupon,
  getCouponByCatalogId,
  updateCoupon,
} from 'store/slices/discountSlice/thunks';
import {
  TCatalog,
  TVersionByVersionId,
  TGeneralInformationResponse,
} from 'store/slices/catalogSlice/types';
import breakPoints from 'constants/BreakPoints';
import checkDataValueChanges from 'utils/checkDataValueChanges';
import { CatalogToastMessages } from 'constants/ToastMessages';

import { validationSchema } from './schema';
import styles from './CatalogCreateConfigure.module.scss';
import { defaultValuesCreator } from './CatalogCreateConfigure.utils';
import {
  Templates,
  CatalogInfo,
  CatalogVersions,
  DiscountSection,
  PaymentSchedule,
  GeneralInformation,
} from './Sections';

import type { TCatalogCreateConfigureProps, TCatalogFormData } from './types';

const CatalogCreateConfigure: FC<TCatalogCreateConfigureProps> = ({
  open,
  onClose,
  inEditMode = false,
}) => {
  const dispatch = useAppDispatch();
  const { width } = useWindowSize();

  const {
    catalogLimit,
    catalogOffset,
    versionsByIdLoad,
    catalogListLoading,
    currentSectionVersionsLoad,
  } = useAppSelector(catalogsSliceSelector);

  const { couponForCatalogLoad } = useAppSelector(couponsSelector);

  const generalLoading =
    catalogListLoading || versionsByIdLoad || currentSectionVersionsLoad || couponForCatalogLoad;

  const { currentCatalogById, currentSectionVersions } = useAppSelector(catalogsSliceSelector);

  const { couponForCatalog } = useAppSelector(couponsSelector);

  const { mediaSource } = useMediaFetching({
    folderName: ImgUrlFolders.CATALOG,
    imgId: currentCatalogById?.image_url_id as string,
  });

  const sendedValue = currentCatalogById?.image_url_id
    ? mediaSource
    : currentCatalogById?.image_url_id;

  const [agreementsTemplateFile, setAgreementsTemplateFile] = useState<File | null>(null);
  const [estimateTemplateFile, setEstimateTemplateFile] = useState<File | null>(null);

  const {
    file,
    fileSrc,
    getImgFile,
    isFileDirty,
    isFileRemoved,
    setIsFileDirty,
    getCurrentImgSrc,
    getSetIsRemovedState,
  } = useFileInputChanges(sendedValue || '');

  const memoizedValues = useMemo(
    () =>
      defaultValuesCreator({
        couponForCatalog,
        currentCatalogById: currentCatalogById as TCatalog,
        currentSectionVersions: currentSectionVersions as TVersionByVersionId,
        inEditMode,
      }),
    [couponForCatalog, currentCatalogById, currentSectionVersions, inEditMode],
  );

  const {
    control,
    reset,
    getValues,
    formState: { errors, isSubmitting, dirtyFields, isDirty },
    handleSubmit,
  } = useForm<TCatalogFormData>({
    values: memoizedValues,
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  const { name, description, category, sections, payment_schedule, discounts, templates } =
    dirtyFields;

  const haveChangedDiscount = discounts?.length
    ? discounts?.some((coupon) => coupon?.coupon?.coupon_code)
    : false;

  const catalogGeneralDataChanged = name || description || category || isFileRemoved || isFileDirty;

  const estimateTemplateChange = templates?.[0]?.media_url_id;
  const agreementTemplateChange = templates?.[1]?.media_url_id;

  const needToCreateNewVersion =
    sections || payment_schedule || estimateTemplateChange || agreementTemplateChange;

  const handleCloseReset = () => {
    onClose();
    reset();
  };

  const discountsDefaultValues = couponForCatalog?.data?.map((item) => ({
    id: item?.id,
    uniqueId: item?.id,
    end_date: new Date(item?.end_date as string),
    start_date: new Date(item?.start_date as string),
    coupon_code: item?.coupon_code as string,
    coupon_status: item?.coupon_status as string,
    discount_percent: item?.discount_percent,
  }));

  useEffect(() => {
    dispatch(getAllParts());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFormSubmit = async (data: TCatalogFormData) => {
    if (!inEditMode) {
      const newData = createFormData({
        name: data?.name,
        category: data.category,
        description: data.description,
      });
      if (file) {
        newData.append('image', file);
      }
      const response = await dispatch(createCatalog(newData)).unwrap();
      // General Information Create
      if (response?.id) {
        data?.generalInformation?.forEach(async ({ name, type, value }) => {
          await dispatch(
            addGeneralInformation({
              general_info: {
                name,
                type,
                value,
                catalog_id: response?.id,
              },
            }),
          );
        });

        // Template Creation

        const templatesArray: number[] = [];
        if (estimateTemplateFile) {
          const data = new FormData();
          data.append('Template', estimateTemplateFile);
          data.append('type', 'estimate');
          data.append('catalog_id', response?.id);

          const estimateTemplateResponse = await dispatch(createCatalogTemplate(data)).unwrap();
          if (estimateTemplateResponse) {
            templatesArray.push(estimateTemplateResponse?.id);
          }
        }
        if (agreementsTemplateFile) {
          const data = new FormData();
          data.append('Template', agreementsTemplateFile);
          data.append('type', 'agreements');
          data.append('catalog_id', response?.id);

          const agreementTemplateResponse = await dispatch(createCatalogTemplate(data)).unwrap();
          if (agreementTemplateResponse) {
            templatesArray.push(agreementTemplateResponse?.id);
          }
        }

        // Version  Create
        await dispatch(
          createCatalogVersion({
            sections: data.sections,
            description: data?.description,
            catalog_id: response?.id,
            templates: templatesArray,
            work_schedule: [],
            previous_version_uuid: String(currentCatalogById?.latest_version_id) || '',
            payment_schedule: {
              description: data.payment_schedule?.description,
              schedule: data.payment_schedule?.schedule,
              estimated_duration: data?.payment_schedule?.estimated_duration,
            },
          }),
        );

        // Discount Create
        if (data?.discounts?.length && haveChangedDiscount) {
          const filteredData = data?.discounts?.filter((coupon) => {
            if (coupon?.coupon?.coupon_code?.trim()?.length) {
              return coupon;
            }
          });

          filteredData?.forEach(async ({ coupon }) => {
            await dispatch(createCoupon({ ...coupon, catalog_id: response?.id }));
          });
        }

        handleCloseReset();
        getImgFile(null);
        setEstimateTemplateFile(null);
        setAgreementsTemplateFile(null);
      }
    } else {
      //After catalog update
      const promises = [];
      // Template Update
      const start = toast.loading(CatalogToastMessages.CATALOG_EDIT_START);

      if (estimateTemplateChange && estimateTemplateFile) {
        const estimateTemplate = data?.templates?.find((template) => template?.type === 'estimate');

        if (estimateTemplate) {
          const data = new FormData();

          data.append('Template', estimateTemplateFile);
          data.append('template_id', String(estimateTemplate?.id));

          const response = await dispatch(updateCatalogTemplate(data));
          promises.push(response);
        }
      }

      if (agreementTemplateChange && agreementsTemplateFile) {
        const agreementTemplate = data?.templates?.find(
          (template) => template?.type === 'agreements',
        );

        if (agreementTemplate) {
          const data = new FormData();

          data.append('Template', agreementsTemplateFile);
          data.append('template_id', String(agreementTemplate?.id));
          const response = await dispatch(updateCatalogTemplate(data));
          promises.push(response);
        }
      }

      if (catalogGeneralDataChanged) {
        const newData = createFormData({
          name: data?.name,
          category: data.category,
          description: data.description,
        });

        if (file) {
          newData.append('image', file);
        }

        newData.append('id', String(currentCatalogById?.id));
        if (isFileRemoved) {
          newData.append('remove_image', 'true');
        } else {
          newData.append('remove_image', 'false');
        }

        const response = await dispatch(updateCatalog(newData)).unwrap();

        promises.push(response);

        if (response) {
          if (needToCreateNewVersion) {
            const versionsResponse = await dispatch(
              createCatalogVersion({
                sections: data.sections,
                description: data?.description,
                catalog_id: response?.id,
                templates:
                  (currentSectionVersions?.templates?.map(
                    (template) => template?.id,
                  ) as number[]) || [],
                work_schedule: [],
                previous_version_uuid: String(response?.latest_version_id),
                payment_schedule: {
                  description: data.payment_schedule?.description,
                  schedule: data.payment_schedule?.schedule,
                  estimated_duration: data?.payment_schedule?.estimated_duration,
                },
              }),
            );

            promises.push(versionsResponse);
          }

          const newItems = data?.generalInformation?.filter((item) => !item?.uniqueId);
          if (newItems?.length) {
            const itemsResponse = newItems?.forEach(async ({ type, name, value }) => {
              await dispatch(
                addGeneralInformation({
                  general_info: {
                    catalog_id: response?.id as number,
                    type,
                    name,
                    value,
                  },
                }),
              );
            });

            promises.push(itemsResponse);
          }

          if (discounts) {
            const newItems = data?.discounts
              ?.filter((discount) => !discount?.coupon?.uniqueId)
              ?.filter((discount) => Number(discount?.coupon?.coupon_code?.trim()?.length) >= 1);

            const newItemsResponse = newItems?.forEach(async ({ coupon }) => {
              await dispatch(createCoupon({ ...coupon, catalog_id: response?.id }));
            });

            promises.push(newItemsResponse);
          }
        }

        handleCloseReset();
        getImgFile(null);
        setEstimateTemplateFile(null);
        setAgreementsTemplateFile(null);
      }
      // GeneralInformation Update

      const updatedItems = checkDataValueChanges({
        data: currentCatalogById?.general_info as TGeneralInformationResponse[],
        watched: data?.generalInformation as TGeneralInformationResponse[],
        itemName: 'uniqueId',
        checkedName: 'id',
      });

      if (updatedItems?.length) {
        const updatedItemsResponse = updatedItems?.forEach(async ({ type, name, value, id }) => {
          await dispatch(
            updateGeneralInformation({
              id: id,
              type,
              name,
              value,
              catalogId: currentCatalogById?.id,
            }),
          );
        });

        promises.push(updatedItemsResponse);
      }

      if (!catalogGeneralDataChanged && currentCatalogById) {
        const newItems = data?.generalInformation?.filter((item) => !item?.uniqueId);

        // GeneralInformation Update(Add)

        if (newItems?.length) {
          const newItemsResponse = newItems?.forEach(async ({ type, name, value }) => {
            await dispatch(
              addGeneralInformation({
                general_info: {
                  catalog_id: currentCatalogById?.id as number,
                  type,
                  name,
                  value,
                },
              }),
            );
          });

          promises.push(newItemsResponse);
        }

        if (needToCreateNewVersion) {
          const response = await dispatch(
            createCatalogVersion({
              sections: data.sections,
              description: data?.description,
              catalog_id: currentCatalogById?.id,
              templates:
                (currentSectionVersions?.templates?.map((template) => template?.id) as number[]) ||
                [],
              work_schedule: [],
              previous_version_uuid: String(currentCatalogById?.latest_version_id),
              payment_schedule: {
                description: data.payment_schedule?.description,
                schedule: data.payment_schedule?.schedule,
                estimated_duration: data?.payment_schedule?.estimated_duration,
              },
            }),
          );

          // Updated Versions

          promises.push(response);
        }

        if (discounts) {
          const newItems = data?.discounts?.filter(
            (discount) =>
              !discount?.coupon?.uniqueId && discount?.coupon?.coupon_code?.trim()?.length >= 1,
          );
          const discountsResponse = newItems?.forEach(async ({ coupon }) => {
            await dispatch(createCoupon({ ...coupon, catalog_id: currentCatalogById?.id }));
          });

          promises.push(discountsResponse);
        }

        const newCouponValues = data?.discounts?.map((item) => ({
          ...item?.coupon,
          id: item?.coupon?.uniqueId,
        }));

        const updatedItems = checkDataValueChanges({
          data: discountsDefaultValues,
          watched: newCouponValues as any,
          itemName: 'uniqueId',
          checkedName: 'id',
        });

        if (updatedItems?.length) {
          const updatedItemsResponse = updatedItems.forEach(
            async ({
              coupon_code,
              coupon_status,
              end_date,
              start_date,
              discount_percent,
              uniqueId,
            }) => {
              await dispatch(
                updateCoupon({
                  end_date,
                  start_date,
                  coupon_code,
                  id: uniqueId,
                  coupon_status,
                  discount_percent,
                }),
              );
            },
          );

          promises.push(updatedItemsResponse);
        }

        handleCloseReset();
        getImgFile(null);
        setEstimateTemplateFile(null);
        setAgreementsTemplateFile(null);
      }

      Promise.all(promises)
        .then(() => {
          // All promises resolved successfully
          toast.update(start, {
            render: CatalogToastMessages.CATALOG_EDIT_SUCCESS,
            type: 'success',
            isLoading: false,
            autoClose: 3000,
          });
          dispatch(getCouponByCatalogId(currentCatalogById?.id as number));
          dispatch(getCatalogById(currentCatalogById?.id as number));
        })
        .catch(() => {
          // Handle the case where any of the promises rejected
          const start = toast.loading('Error updating templates');
          toast.update(start, {
            render: CatalogToastMessages.CATALOG_EDIT_FAILURE,
            type: 'error',
            isLoading: false,
            autoClose: 3000,
          });
        })
        .finally(() => {
          dispatch(getCatalogList({ limit: catalogLimit, offset: catalogOffset }));

          handleCloseReset();
          getImgFile(null);
          setIsFileDirty(false);
          setEstimateTemplateFile(null);
          setAgreementsTemplateFile(null);
        });
    }
  };

  const headerTitle = inEditMode ? 'Edit Catalog' : 'Create Catalog';

  const drawerWidth =
    Number(width) > breakPoints.TABLET_L
      ? 810
      : Number(width) >= breakPoints.TABLET_M
      ? 500
      : Number(width) <= breakPoints.MOBILE_M
      ? '100vw'
      : '100vw';

  return (
    <DrawerLayout
      open={open}
      width={drawerWidth}
      padding='20px'
      inCenter={false}
      onClose={onClose}
      onCloseReset={handleCloseReset}
      headerTitle={headerTitle}
    >
      <form
        onSubmit={handleSubmit(handleFormSubmit)}
        onKeyDown={(event: React.KeyboardEvent<HTMLFormElement>) => {
          if (event.key === 'Enter') {
            event.preventDefault();
          }
        }}
      >
        <CatalogInfo
          errors={errors}
          control={control}
          getFile={getImgFile}
          isLoading={generalLoading}
          setIsDirty={setIsFileDirty}
          getImgSrc={getCurrentImgSrc}
          imgUrl={inEditMode ? fileSrc : null}
          getSetIsRemovedFileState={getSetIsRemovedState}
        />
        <GeneralInformation isLoading={generalLoading} control={control} errors={errors} />
        <CatalogVersions
          getValues={getValues}
          isLoading={generalLoading}
          control={control}
          errors={errors}
        />
        <Templates
          getAgreementFile={setAgreementsTemplateFile}
          getEstimateFile={setEstimateTemplateFile}
          errors={errors}
          control={control}
          isLoading={generalLoading}
        />
        <PaymentSchedule
          getValues={getValues}
          isLoading={generalLoading}
          control={control}
          errors={errors}
        />
        <DiscountSection
          errors={errors}
          control={control}
          getValues={getValues}
          isLoading={generalLoading}
        />
        <Box className={styles.container__footer}>
          <Button
            type='button'
            maxWidth='68px'
            color={Colors.SAPPHIRE}
            minWidth='68px'
            borderRadius='5px'
            padding='12px 16px'
            onClick={onClose}
            isUppercase={false}
            backgroundColor={Colors.PALE_BLUE}
          >
            Cancel
          </Button>
          <Button
            id='catalogForm'
            type='submit'
            color='white'
            maxWidth='61px'
            minWidth='61px'
            borderRadius='5px'
            padding='12px 16px'
            isUppercase={false}
            backgroundColor={Colors.SAPPHIRE}
            disabled={isFileDirty ? false : !isDirty || isSubmitting}
          >
            Save
          </Button>
        </Box>
      </form>
    </DrawerLayout>
  );
};

export default CatalogCreateConfigure;
