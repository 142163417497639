import { useCallback, useEffect, useRef, useState } from 'react';

import { TUseStreamSocketDataProps } from './types';

const useStreamSocketData = (url: string) => {
  const socketRef = useRef<WebSocket | null>(null);
  const [isSocketFinished, setIsSocketFinished] = useState(false);
  const [socketData, setSocketData] = useState<TUseStreamSocketDataProps[] | null>([]);

  const sendMessage = useCallback((message: string) => {
    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      socketRef.current.send(message);
    }
  }, []);

  const connectWebSocket = useCallback(() => {
    if (!url) return;

    if (url) {
      if (socketRef.current) {
        socketRef.current.close();
      }

      socketRef.current = new WebSocket(url);

      socketRef.current.onmessage = (event: MessageEvent) => {
        const messageData: TUseStreamSocketDataProps = JSON.parse(event.data);

        setSocketData((prev) => {
          if (prev && messageData) {
            return [...(prev || []), { ...messageData }];
          }
          return prev;
        });
      };

      socketRef.current.onclose = () => {
        setIsSocketFinished(true); // Set the boolean to true when the socket is closed
      };
    }
  }, [url]);

  const disconnectWebSocket = useCallback(() => {
    if (socketRef.current) {
      socketRef.current.close();
      socketRef.current = null;
    }
  }, []);

  useEffect(() => {
    if (url) {
      connectWebSocket();
    }

    return () => {
      disconnectWebSocket();
    };
  }, [connectWebSocket, disconnectWebSocket, url]);

  return { socketData, sendMessage, disconnectWebSocket, connectWebSocket, isSocketFinished };
};

export default useStreamSocketData;
