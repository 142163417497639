import { BaseSyntheticEvent, ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { GridColDef } from '@mui/x-data-grid';
import { Box, IconButton, Menu, MenuItem, Stack } from '@mui/material';
import classNames from 'classnames';

import {
  useInput,
  useAppSelector,
  useAppDispatch,
  useOnClickOutside,
  useRefreshClearState,
} from 'hooks';
import {
  customersSelector,
  customersLimitSelector,
  customersOffsetSelector,
  customersLoadingSelector,
  customersAscSelector,
} from 'store/slices/customersSlices/selectors';
import {
  deleteCustomer,
  getCustomerById,
  getCustomersList,
} from 'store/slices/customersSlices/thunks';
import {
  ScrollLayout,
  CustomDataGrid,
  CustomTypography,
  ShowAfterAccessView,
  CustomersViewDrawer,
  CreateCustomerDrawer,
  DynamicPaginationControl,
  ContactLink,
} from 'components';
import DeletePopup from 'components/views/DeletePopup';
import { setLimit, setPage } from 'store/slices/customersSlices';
import ShareDropDown from 'components/dropdowns/ShareDropDown';
import { SortByEnums, TCustomer } from 'store/slices/customersSlices/types';
import { findCustomer } from 'store/slices/searchSlice/thunks';
import { EditIconOutLined, MoreIcon, TrashIcon } from 'assets/icons';
import {
  userAllRolesLoadingSelector,
  userPermissionsSelector,
} from 'store/slices/authSlice/selectors';
import { foundedCustomerSelector } from 'store/slices/searchSlice/selectors';
import { EmptyTitles } from 'constants/EmptyTitles';
import { Routes } from 'types';

import { customerColumns } from './utils';
import styles from './Customer.module.scss';
import CustomerHeader from './CustomerHeader';
import { sxMenuStyles } from './styles';

const Customer: React.FC = () => {
  const dispatch = useAppDispatch();

  const { state } = useLocation();

  const customers = useAppSelector(customersSelector);
  const customersLoading = useAppSelector(customersLoadingSelector);
  const customersLimit = useAppSelector(customersLimitSelector);
  const customersOffset = useAppSelector(customersOffsetSelector);
  const customersAsc = useAppSelector(customersAscSelector);
  const foundedCustomers = useAppSelector(foundedCustomerSelector);
  const userAllRolesLoading = useAppSelector(userAllRolesLoadingSelector);
  const { isAccessToCustomerEditor, isAccessToCustomerViewer } =
    useAppSelector(userPermissionsSelector);

  const haveAccessToCustomers = isAccessToCustomerEditor || isAccessToCustomerViewer;

  useRefreshClearState();

  const { value, debouncedValue, handleChange } = useInput();

  const renderedCustomers = debouncedValue?.length > 0 ? foundedCustomers : customers;

  const [anchorEl, setAnchorEl] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);

  const [isShowInfo, setIsShowInfo] = useState<boolean>(false);
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const [isShareOpen, setIsShareOpen] = useState<boolean>(false);
  const [isInEditModeView, setIsInEditModeView] = useState<boolean>(false);
  const [selectedItemId, setSelectedItemId] = useState<number | null>(null);
  const [openDrawer, setOpenDrawer] = useState<boolean>(state?.isOpenCreateDrawer || false);
  const [newSortBy, setNewSortBy] = useState<SortByEnums | null>(null);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
    setAnchorEl(null);
  };

  const menuRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (debouncedValue?.length > 0) {
      dispatch(findCustomer({ query: debouncedValue }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  const handleClose = () => {
    if (!isShareOpen) {
      setAnchorEl(null);
    }

    setIsShareOpen(false);
  };

  useOnClickOutside(menuRef, handleClose);

  const openViewDrawer = () => setIsShowInfo(true);
  const closeViewDrawer = () => setIsShowInfo(false);
  const openCustomerDrawer = () => setOpenDrawer(true);
  const closeCustomerDrawer = () => setOpenDrawer(false);
  const openEditViewDrawer = () => setIsInEditModeView(true);
  const closeEditViewDrawer = () => setIsInEditModeView(false);

  useEffect(() => {
    dispatch(getCustomersList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customersOffset, customersLimit]);

  const handleColumnHeaderClick = (column: GridColDef) => {
    setNewSortBy(column.field as SortByEnums);

    dispatch(
      getCustomersList({
        sort_by: column.field as SortByEnums,
        asc: customersAsc,
        limit: customersLimit,
        offset: customersOffset,
      }),
    );
  };

  const handleMoreClick = (event: BaseSyntheticEvent, id: number) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);

    setSelectedItemId(id);
  };

  const onDelete = async () => {
    await dispatch(deleteCustomer(selectedItemId as number));
    togglePopup();
  };

  const handlePageChange = useCallback((_event: unknown, newPage: number) => {
    setCurrentPage(newPage);
    dispatch(setPage(newPage + 1));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRowsPerPageChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCurrentPage(0);
    handlePageChange(null, 0);
    dispatch(setLimit(event.target.value));
  };

  const dropdownItems = [
    {
      icon: <EditIconOutLined />,
      label: 'Edit',
      paramsAction: (id: number) => {
        openEditViewDrawer();
        dispatch(getCustomerById(id));
      },
    },
    {
      icon: <TrashIcon />,
      label: 'Delete',
      paramsAction: togglePopup,
    },
  ];

  const renderMenuItem = dropdownItems.map((item, idx) => {
    const onClick = () => {
      selectedItemId && item.paramsAction(selectedItemId);
      if (item?.label !== 'Share') {
        handleClose();
      }
    };

    return (
      <MenuItem key={idx} onClick={onClick}>
        {item.icon}
        {item.label}
      </MenuItem>
    );
  });

  const renderColumns: GridColDef[] = customerColumns.map((column) => {
    const isColumnSorted = newSortBy === column.field;

    const headerClasses = classNames(
      { [styles.activeSortHeader]: !customersAsc },
      {
        [styles.activeSortHeader_asc]: customersAsc,
      },
    );

    return {
      ...column,
      sortable: false,
      hideSortIcons: true,
      disableColumnMenu: true,
      disableColumnSelector: true,
      headerClassName: isColumnSorted ? headerClasses : '',
    };
  });

  const renderRows = renderedCustomers?.data?.map((el: TCustomer) => {
    const contactItems = el?.contacts?.map((contact, idx) => {
      if (idx === 0) {
        return (
          <Stack gap='2px' key={contact?.id}>
            <CustomTypography onClick={(event) => event.stopPropagation()}>
              {contact?.name}
            </CustomTypography>
            <ContactLink
              to='tel'
              value={contact?.phone_number_1}
              className={styles.container_link_new}
            />
          </Stack>
        );
      }
    });

    return {
      id: el.id,
      name:
        <CustomTypography className={styles.container_link}>{el?.name}</CustomTypography> || '--',
      email:
        (
          <Stack gap='2px'>
            <ContactLink to='mailto' value={el.email} className={styles.container_link} />
          </Stack>
        ) || '--',
      phone_number:
        (
          <Stack gap='2px'>
            <ContactLink to='tel' value={el?.phone_number} className={styles.container_link} />
          </Stack>
        ) || '--',
      address: el?.address || '--',
      point_of_contact: contactItems || '--',
      options: isAccessToCustomerEditor && (
        <IconButton sx={{ padding: '3px' }} onClick={(event) => handleMoreClick(event, el.id)}>
          <MoreIcon />
        </IconButton>
      ),
    };
  });

  useEffect(() => {
    if (!customers?.total_count && currentPage) {
      setCurrentPage(currentPage - 1);
      handlePageChange(null, currentPage - 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customers?.total_count]);

  if (!userAllRolesLoading && !haveAccessToCustomers) {
    return <Navigate replace to={Routes.ActivityFeed} />;
  }

  return (
    <>
      <Box className={styles.container}>
        <CustomerHeader
          value={value}
          onChange={handleChange}
          createNewDrawer={openCustomerDrawer}
        />

        <ShowAfterAccessView emptyMessageTitle='Customers' isShow={haveAccessToCustomers}>
          <Box className={styles.container_content}>
            <ScrollLayout>
              <CustomDataGrid
                isLoading={customersLoading}
                columns={renderColumns}
                rows={renderRows}
                emptyTitle={EmptyTitles.Customers}
                onRowClick={async (params, event) => {
                  event.stopPropagation();

                  await dispatch(getCustomerById(params?.id as number));
                  openViewDrawer();
                }}
                onColumnHeaderClick={handleColumnHeaderClick}
              />
            </ScrollLayout>
          </Box>
          <Menu
            sx={sxMenuStyles}
            ref={menuRef}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {isShareOpen ? <ShareDropDown setIsOpen={setIsShareOpen} /> : renderMenuItem}
          </Menu>

          <CreateCustomerDrawer open={openDrawer} handleClose={closeCustomerDrawer} />
          <CreateCustomerDrawer
            inEditMode
            open={isInEditModeView}
            handleClose={closeEditViewDrawer}
          />
          {!!customers?.total_count && (
            <Box className={styles.container__footer}>
              <DynamicPaginationControl
                page={currentPage}
                onPageChange={handlePageChange}
                rowsPerPage={customersLimit}
                count={customers?.total_count}
                onRowsPerPageChange={handleRowsPerPageChange}
              />
            </Box>
          )}
        </ShowAfterAccessView>
        <DeletePopup
          withBody
          isOpen={isPopupOpen}
          onClose={togglePopup}
          onDelete={onDelete}
          title='Delete confirmation'
          body='Are you sure you want to delete the customer'
        />
      </Box>
      <CustomersViewDrawer
        onOpen={openEditViewDrawer}
        open={isShowInfo}
        onClose={closeViewDrawer}
      />
    </>
  );
};

export default Customer;
