import { GridColDef } from '@mui/x-data-grid';
import { FC } from 'react';

import { generateInventoryItemNumber } from 'utils/generateInventoryItemNumber';
import { CustomDataGrid, ScrollLayout } from 'components';
import { EmptyTitles } from 'constants/EmptyTitles';

import { columns } from './utils';
import { TPurchaseOrderItemsTableProps } from './types';

const PurchaseOrderItemsTable: FC<TPurchaseOrderItemsTableProps> = ({ data, onOpen }) => {
  const renderRows = data?.map((row) => {
    const renderNumber = generateInventoryItemNumber(Number(row?.part_id));

    return {
      id: row?.id,
      itemName: row.name,
      description: row.description,
      image: row.image_url_id || '',
      quantity: row?.quantity || '--',
      itemNumber: `INV-${renderNumber}`,
      unit_cost: row?.unit_price || '0',
      amount: row.quantity * row.unit_price || '0',
    };
  });

  const renderColumns: GridColDef[] = columns.map((column) => ({
    ...column,
    hideSortIcons: true,
    disableColumnMenu: true,
    disableColumnSelector: true,
    sortable: column.field !== 'image',
  }));

  return (
    <ScrollLayout>
      <CustomDataGrid
        rowHeight={64}
        headerHeight={52}
        rows={renderRows}
        onRowClick={onOpen}
        columns={renderColumns}
        emptyTitle={EmptyTitles.Purchase_Order_Items}
      />
    </ScrollLayout>
  );
};

export default PurchaseOrderItemsTable;
