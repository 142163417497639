import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Stack } from '@mui/material';
import { useGoogleLogin } from '@react-oauth/google';
import { toast } from 'react-toastify';
import localforage from 'localforage';

import { returnImgUrl } from 'utils';
import { GoogleIcon } from 'assets/icons';
import { ImageBlank } from 'assets/images';
import { ImgUrlFolders, Routes } from 'types';
import { loginOAuth } from 'store/slices/authSlice/authThunks';
import { useAppDispatch, useUserOrganizationsList } from 'hooks';
import { BrowserStorageKeys, BrowserStorageService } from 'services';
import { TUserProfile, TokenProvider } from 'store/slices/authSlice/types';
import { ProcessFormTitle, CustomTypography, ImgWithDefault } from 'components';

import styles from './SignUp.module.scss';

const SignUp = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { getAllOrganizations, loginDependentInOrganization } = useUserOrganizationsList();
  const [organizationName, setOrganizationName] = useState<string>('');

  const currentOrganizationId = BrowserStorageService.get(BrowserStorageKeys.CurrentOrganizationId);
  const currentOrganizationName = BrowserStorageService.get(
    BrowserStorageKeys.InvitedOrganizationName,
    { session: true },
  );

  const [invitationToken, setInvitationToken] = useState<string>('');
  const [organizationId, setOrganizationId] = useState<number | null>(null);
  const [mediaUrlId, setMediaUrlId] = useState<string>('');

  useEffect(() => {
    if (!invitationToken) {
      localStorage.clear();
      sessionStorage.clear();
      localforage.clear();
    }
  }, [invitationToken]);

  const getParameterByName = (name: string, url: string) => {
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
    const results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  };

  const getParameterByNameNoReplace = (name: string, url: string) => {
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
    const results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2]);
  };

  useEffect(() => {
    const invitationToken = getParameterByName('invitation_token', location.search);
    const invitationTokenOrgId = getParameterByName('org_id', location.search);
    const invitedOrganizationName = getParameterByName('org_name', location.search);
    const mediaUrl = getParameterByNameNoReplace('media_url_id', location.search);

    if (invitationToken && invitationTokenOrgId && invitedOrganizationName) {
      BrowserStorageService.set(BrowserStorageKeys.InvitationToken, invitationToken, {
        session: true,
      });

      BrowserStorageService.set(
        BrowserStorageKeys.InvitedOrganizationName,
        invitedOrganizationName,
        {
          session: true,
        },
      );

      if (invitedOrganizationName) {
        setOrganizationName(invitedOrganizationName);
      }

      if (invitationTokenOrgId) {
        setOrganizationId(Number(invitationTokenOrgId));
        BrowserStorageService.set(BrowserStorageKeys.CurrentOrganizationId, invitationTokenOrgId);
      }

      setInvitationToken(invitationToken);
      setMediaUrlId(mediaUrl as string);
    } else {
      setInvitationToken('');
      BrowserStorageService.remove(BrowserStorageKeys.InvitationToken, {
        session: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  const goToSignIn = () => navigate(Routes.SignIn);

  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      BrowserStorageService.set(BrowserStorageKeys.GoogleToken, tokenResponse.access_token, {
        session: true,
      });

      if (!invitationToken) {
        const sendedData = new FormData();

        sendedData.append('oauth_token', String(tokenResponse.access_token));
        sendedData.append('oauth_provider', TokenProvider.GOOGLE);

        const response: TUserProfile = await dispatch(loginOAuth(sendedData)).unwrap();

        if (response.token && response.is_first_time_user && response.is_first_time_user === true) {
          navigate(Routes.ProcessForm);
        } else {
          const response = await getAllOrganizations().then((res) =>
            res?.data?.filter(
              (organization) =>
                organization?.resource_status !== 'pending' &&
                organization?.resource_status !== 'failed',
            ),
          );

          if (!response?.length) {
            navigate(Routes.ProcessForm);
            return;
          }

          if (response && response?.length > 1) {
            navigate(Routes.OrganizationsList);
          } else {
            const firstOrganizationStatus = response?.[0]?.resource_status;

            if (firstOrganizationStatus === 'pending' || firstOrganizationStatus === 'creating') {
              toast.info('You have organization in pending state please wait');
            } else if (firstOrganizationStatus === 'failed') {
              toast.info('Your organization creation failed please try again');

              navigate(Routes.ProcessForm);
              return;
            } else {
              loginDependentInOrganization(Number(response?.[0]?.id), tokenResponse.access_token);
            }
          }
        }
      } else {
        const sendedData = new FormData();

        const sendedOrganizationId = String(organizationId) || String(currentOrganizationId);

        sendedData.append('oauth_token', String(tokenResponse.access_token));
        sendedData.append('oauth_provider', TokenProvider.GOOGLE);
        sendedData.append('invitation_token', invitationToken);
        sendedData.append('org_id', sendedOrganizationId);

        const data = await dispatch(loginOAuth(sendedData));

        const response = data.payload as TUserProfile;

        if (
          response &&
          response.token &&
          response.is_first_time_user &&
          response?.is_invited_user
        ) {
          BrowserStorageService.set(
            BrowserStorageKeys.AccessToken,
            JSON.stringify(response?.token?.access_token),
          );
          BrowserStorageService.set(
            BrowserStorageKeys.RefreshToken,
            JSON.stringify(response?.token?.refresh_token),
          );

          navigate(Routes.ActivityFeed);
        }
      }
    },
  });

  const name = currentOrganizationName || organizationName;

  const processFormTitle = name ? `Join (${name})` : 'Create Account';

  const processSubtitle = !name
    ? `"Welcome To Our Platform! Let's Get Started By
              Creating Your Account."`
    : '';

  return (
    <Box className={styles.container}>
      <div className={styles.container__main}>
        {!!mediaUrlId?.length && (
          <ImgWithDefault
            src={returnImgUrl(ImgUrlFolders.PROFILE_INFO, mediaUrlId)}
            fallback={ImageBlank}
            containerClassName={styles.container__img}
          />
        )}
        <ProcessFormTitle
          subtitle={processSubtitle}
          title={processFormTitle}
          marginBottom='20px'
          subtitleClassName={styles.container__head__subtitle}
        />
        <Stack direction='column' spacing={2} className={styles.container__footer}>
          <Button
            startIcon={<GoogleIcon />}
            className={styles.container__footer__btn}
            onClick={() => login()}
          >
            SIGNUP WITH GOOGLE
          </Button>
        </Stack>
      </div>

      <Box className={styles.container__enroll}>
        <CustomTypography className={styles.container__enroll__text}>
          Already have an account?
        </CustomTypography>
        <Button onClick={goToSignIn} className={styles.container__enroll__link}>
          Login
        </Button>
      </Box>
    </Box>
  );
};

export default SignUp;
