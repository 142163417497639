import { Routes } from 'types';
import { TRouteAccesses } from 'types/global/routeAccsessValue';

const checkRouteValidity = (accesses: TRouteAccesses, link: string) => {
  if (link) {
    switch (link) {
      case Routes.LiveStream:
        return accesses?.isAccessToLivestream;
      case Routes.Team:
        return accesses?.isAccessToTeamsViewer || accesses?.isAccessToTeamsEditor;
      case Routes.Customer:
        return accesses?.isAccessToCustomerViewer || accesses?.isAccessToCustomerEditor;
      case Routes.Catalog:
        return accesses?.isAccessToCatalogsViewer || accesses?.isAccessToCatalogsEditor;
      case Routes.Inventory:
        return accesses?.isAccessToInventoryViewer || accesses?.isAccessToInventoryEditor;
      case Routes.Invoice:
        return accesses?.isAccessToInvoices;
      case Routes.BomEstimate:
        return accesses?.isAccessToEstimatesEditor || accesses?.isAccessToEstimatesViewer;
      case Routes.Documents:
        return accesses?.isAccessToDocumentationAndReports;
      case Routes.WorkOrder:
        return accesses?.isAccessToWorkOrdersAndScheduling;
      default:
        return true;
    }
  } else {
    return true;
  }
};

export default checkRouteValidity;
