import { Routes } from 'types';

const employeeManagementNavigateOptions = [
  {
    id: 1,
    label: 'Employees',
    goTo: Routes.EmployeeManagement,
  },
  {
    id: 2,
    label: 'Invitations',
    goTo: Routes.Invitations,
  },
  {
    id: 3,
    label: 'Permissions',
    goTo: Routes.Permissions,
  },
];

export default employeeManagementNavigateOptions;
