import { FC, useEffect, useRef } from 'react';
import { Box } from '@mui/material';
import classNames from 'classnames';

import { ImgUrlFolders } from 'types';
import { MoreIcon } from 'assets/icons';
import { getLiveStreamNotices } from 'store/thunks';
import { useAppDispatch, useAppSelector, useFileDownload } from 'hooks';
import { CustomTypography, EmptyTitle, PortalDropDown } from 'components';
import { liveStreamSelector } from 'store/slices/liveStreamSlice/selector';

import StreamNote from './StreamNote/StreamNote';
import styles from './StreamVideoNotes.module.scss';
import { dropDownActionsCreator } from './StreamVideoNotes.utils';

import type { TStreamVideoNotesProps } from './types';

const StreamVideoNotes: FC<TStreamVideoNotesProps> = ({ id, stream, openDetailsDrawer }) => {
  const dispatch = useAppDispatch();

  const { liveStreamNotices, liveStreamNoticesLoading } = useAppSelector(liveStreamSelector);

  const contentRef = useRef<HTMLDivElement | null>(null);

  const fileUrl = stream?.notes_url_id;
  const haveVideo = stream?.media?.[0]?.media_url_id;

  const { fetchDataAndDownload } = useFileDownload({
    fileUrl: fileUrl as string,
    folder: ImgUrlFolders.LIVE_STREAM,
  });

  useEffect(() => {
    dispatch(getLiveStreamNotices({ id }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const noticeItem = liveStreamNotices?.data?.map((note) => (
    <StreamNote key={note?.id} note={note} />
  ));

  const scrollClasses = classNames(styles.container__content, styles.container__content_scroll);
  const containerClasses = classNames(styles.container, { [styles.container__all]: !haveVideo });

  const handleOpen = () => {
    openDetailsDrawer();
  };

  const dropdownOptions = dropDownActionsCreator({
    withNotes: !!stream?.notes_url_id?.length,
    downloadAction: () => fetchDataAndDownload(),
    openMembersAction: handleOpen,
  });

  return (
    <>
      <Box className={containerClasses}>
        <Box className={styles.container__head}>
          <CustomTypography className={styles.container__head__title}>
            {stream?.title}
          </CustomTypography>
          <PortalDropDown title={<MoreIcon />} options={dropdownOptions} />
        </Box>
        <Box className={styles.container__content__main}>
          <Box className={scrollClasses} ref={contentRef}>
            {!noticeItem?.length ? (
              <EmptyTitle title='No Notices Added Yet' loadingDone={liveStreamNoticesLoading} />
            ) : (
              noticeItem
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default StreamVideoNotes;
