import { FC, useRef } from 'react';

import { useElementSize, useWindowSize } from 'hooks';
import breakPoints from 'constants/BreakPoints';

import styles from './CornerBox.module.scss';

import type { TCornerBoxProps } from './types';

const CornerBox: FC<TCornerBoxProps> = ({ children }) => {
  const elementRef = useRef<HTMLDivElement | null>(null);

  const elementSize = useElementSize(elementRef);
  const { width } = useWindowSize();

  const height = `${Number(elementSize?.height) - 30}px`;

  return (
    <>
      {Number(width) >= breakPoints.DESKTOP ? (
        <div className={styles.container} ref={elementRef}>
          {children}
          <svg
            className={styles.container__corner}
            width='92'
            height={height}
            viewBox={`0 0 92 ${height}`}
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path d='M0 0H91.5C79.5 0 0 53.5 0 153V0Z' fill='#1A53B2' height={height} />
          </svg>
        </div>
      ) : (
        children
      )}
    </>
  );
};

export default CornerBox;
