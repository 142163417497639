import { FC, useState } from 'react';
import { GridColDef } from '@mui/x-data-grid';
import classNames from 'classnames';

import { useAppSelector } from 'hooks';
import { ImgUrlFolders } from 'types/global/imgUrlFolders';
import {
  currentCatalogByIdLoadSelector,
  currentSectionVersionsLoadSelector,
} from 'store/slices/catalogSlice/selectors';
import PreloadedImg from 'components/views/PreloadedImg';
import { CustomDataGrid, CustomSkeleton, ScrollLayout, TableCell } from 'components';
import { EmptyTitles } from 'constants/EmptyTitles';

import { columns } from './utils';
import { TCatalogSectionTableProps } from './types';
import styles from './CatalogSectionTable.module.scss';

const CatalogSectionTable: FC<TCatalogSectionTableProps> = ({ data }) => {
  const currentCatalogLoad = useAppSelector(currentCatalogByIdLoadSelector);
  const currentCatalogVersionsLoad = useAppSelector(currentSectionVersionsLoadSelector);

  const [asc, setAsc] = useState<boolean>(false);
  const [newSortBy, setNewSortBy] = useState<string | null>(null);

  const load = currentCatalogLoad || currentCatalogVersionsLoad;

  const renderRows = data?.map((row, idx) => ({
    id: `${row?.part_id}/${idx}`,
    itemName: row?.part_name || '--',
    image: (
      <TableCell
        isTooltipDisabled
        title={
          <PreloadedImg
            folderName={ImgUrlFolders.INVENTORY}
            imgId={row?.image_url_id as string}
            width={66}
            height={50}
          />
        }
      />
    ),
    description: row?.description || '--',
    quantity: row?.qty_required || '--',
  }));

  const renderColumns: GridColDef[] = columns.map((column) => {
    const isColumnSorted = column.field ? newSortBy === column.field : 'description';

    const headerClasses = classNames(
      { [styles.activeSortHeader]: !asc },
      {
        [styles.activeSortHeader_asc]: asc,
      },
    );

    return {
      ...column,
      hideSortIcons: true,
      sortable: true,
      disableColumnMenu: true,
      disableColumnSelector: true,
      headerClassName: isColumnSorted ? headerClasses : '',
    };
  });

  const handleColumnHeaderClick = (column: GridColDef) => {
    setNewSortBy(column.field);
    setAsc((prev) => !prev);
  };

  return (
    <>
      {load ? (
        <CustomSkeleton width='100%' height={100} />
      ) : (
        <ScrollLayout>
          <CustomDataGrid
            isLoading={load}
            rowHeight={54}
            columns={renderColumns}
            rows={renderRows || []}
            onColumnHeaderClick={handleColumnHeaderClick}
            emptyTitle={EmptyTitles.Catalog_Versions}
            sortModel={[{ field: newSortBy || 'description', sort: asc ? 'asc' : 'desc' }]}
          />
        </ScrollLayout>
      )}
    </>
  );
};

export default CatalogSectionTable;
