export enum SortByEnums {
  ID = 'id',
  CUSTOMER = 'customer_name',
  AMOUNT = 'amount',
  STATUS = 'status',
  CREATED = 'created_at',
  SOURCE = 'source_type',
}

export type TTransactionParams = Partial<{
  asc: boolean;
  limit: number;
  offset: number;
  sort_by: SortByEnums | null;
}>;

export type TTransaction = Partial<{
  id: number;
  customer: string;
  amount: number;
  created_at: string;
  source_type: string;
  status: string;
}>;

export type TExcel = Partial<{
  id: number;
  customer: string;
  amount: number;
  created_at: string;
  source_type: string;
  status: string;
}>;

export type TAllTransactionsReturnType = {
  data: TExcel[];
  total_count: number;
};

export type TInitialState = {
  allTransactionsAsc: boolean;
  allTransactionsLimit: number;
  allTransactionsOffset: number;
  allTransactionsLoading: boolean;
  allTransactionsError: Error | null | string;
  allTransactions: [string, any][];
};
