import { FC } from 'react';

import type { TTexFrameProps } from './types';

const TextFrame: FC<TTexFrameProps> = ({
  border,
  width,
  className,
  content,
  borderRadius,
  textFontSize,
  letterSpacing,
  backgroundColor,
  padding = '10px',
  cursor = 'default',
  textColor = 'black',
  fontFamily = 'CircularStdLight',
}) => (
  <div
    style={{ backgroundColor, border, borderRadius, cursor, padding, width }}
    className={className}
  >
    <p
      style={{
        fontFamily,
        letterSpacing,
        color: textColor,
        fontSize: textFontSize,
      }}
    >
      {content}
    </p>
  </div>
);

export default TextFrame;
