import { FC } from 'react';
import { Box } from '@mui/material';

import { CustomTypography, CustomizedAvatar } from 'components';
import { Colors } from 'types';

import styles from './UserCard.module.scss';

import type { TUserCardProps } from './types';

const UserCard: FC<TUserCardProps> = ({ userImg, userName, variant, width = 40, height = 40 }) => (
  <Box className={styles.container}>
    <CustomizedAvatar
      withBorder
      alt={'img'}
      width={width}
      height={height}
      src={userImg}
      variant={variant && variant}
      borderColor={Colors.SILVER_SLATE}
      className={styles.container__img}
    />
    <CustomTypography className={styles.container__name}>{userName}</CustomTypography>
  </Box>
);

export default UserCard;
