import { Box, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import {
  MediaPermissionsError,
  MediaPermissionsErrorType,
  requestMediaPermissions,
} from 'mic-check';

import { Colors, Routes } from 'types';
import { Button, CustomTypography, DeletePopup } from 'components';
import { CallIcon } from 'assets/icons';
import { deleteLiveStream, getMyCurrentStream } from 'store/thunks';
import { BrowserStorageKeys, BrowserStorageService } from 'services';
import { myCurrentStreamSelector } from 'store/slices/liveStreamSlice/selector';
import { useAppDispatch, useAppSelector, useStreamSocketData } from 'hooks';
import { MediaPermissionsErrorMessage } from 'constants/ToastMessages';
import { liveStreamApi } from 'api';
import {
  userAllRolesLoadingSelector,
  userPermissionsSelector,
} from 'store/slices/authSlice/selectors';

import Dots from './Dots';
import styles from './LiveStreamJoin.module.scss';

const LiveStreamJoin = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const myCurrentStream = useAppSelector(myCurrentStreamSelector);

  const userRolesLoad = useAppSelector(userAllRolesLoadingSelector);
  const { isAccessToLivestream } = useAppSelector(userPermissionsSelector);

  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false);

  useEffect(() => {
    dispatch(getMyCurrentStream());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const liveStreamToken = BrowserStorageService.get(BrowserStorageKeys.currentLiveStreamToken);

  const liveStreamId = BrowserStorageService.get(BrowserStorageKeys.currentLiveStreamId, {
    session: true,
  });

  const endStreamNotStarted = async () => {
    const response = await dispatch(
      deleteLiveStream({
        id: Number(liveStreamId || myCurrentStream?.livestream?.id),
        fromJoinPage: true,
      }),
    );

    if (response?.meta?.requestStatus === 'fulfilled') {
      await dispatch(getMyCurrentStream()).unwrap();
      BrowserStorageService.remove(BrowserStorageKeys.currentLiveStreamId, { session: true });
      BrowserStorageService.remove(BrowserStorageKeys.currentLiveStreamUUID, { session: true });

      BrowserStorageService.remove(BrowserStorageKeys.currentLiveStreamToken);
      navigate(Routes.LiveStream);
    }
  };

  const link =
    process.env.REACT_APP_SOCKET_BASE_URL &&
    `${process.env.REACT_APP_SOCKET_BASE_URL}/websocket?token=${
      liveStreamToken || myCurrentStream?.token
    }`;

  const { socketData, connectWebSocket, disconnectWebSocket } = useStreamSocketData(link as string);

  useEffect(() => {
    const disconnectTimeout = setTimeout(() => {
      disconnectWebSocket();
    }, 2000);

    const reconnectInterval = setInterval(() => {
      connectWebSocket();
    }, 3000);

    return () => {
      clearTimeout(disconnectTimeout);
      clearInterval(reconnectInterval);
    };
  }, [connectWebSocket, disconnectWebSocket]);

  const findExistedUser = socketData?.some(
    (data) => data?.event === 'user_info' && data?.data?.length > 10,
  );

  useEffect(() => {
    if (findExistedUser) {
      disconnectWebSocket();
      requestMediaPermissions({ audio: true, video: false })
        .then(() => {
          return liveStreamApi
            .getMyCurrentStreamRequest()
            .then((res) => navigate(`/live-stream/start/${res.data.livestream.uuid}`));
        })
        .catch((err: MediaPermissionsError) => {
          const { type } = err;
          if (type === MediaPermissionsErrorType.SystemPermissionDenied) {
            alert(MediaPermissionsErrorMessage.SystemPermissionDenied);
          } else if (type === MediaPermissionsErrorType.UserPermissionDenied) {
            alert(MediaPermissionsErrorMessage.UserPermissionDenied);
          } else if (type === MediaPermissionsErrorType.CouldNotStartVideoSource) {
            alert(MediaPermissionsErrorMessage.CouldNotStartVideoSource);
          } else {
            alert(MediaPermissionsErrorMessage.UnhandledError);
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [findExistedUser]);

  useEffect(() => {
    if (showTooltip) {
      setInterval(() => {
        setShowTooltip(false);
      }, 2000);
    }
  });

  if (!isAccessToLivestream && !userRolesLoad) {
    return <Navigate replace to={Routes.ActivityFeed} />;
  }

  return (
    <>
      <Box className={styles.container}>
        <Box className={styles.container__top}>Live Stream</Box>
        <Box className={styles.container__header}>
          <Box className={styles.container__header__copy}>
            <Stack direction='row' alignItems='center' gap='10px'>
              <Button
                color='white'
                maxWidth='80px'
                padding='12px 8px'
                borderRadius='4px'
                variant='contained'
                isUppercase={false}
                backgroundColor={Colors.SAPPHIRE}
                onClick={() => setIsOpenDrawer(true)}
                className={styles.container__header__copy__button}
              >
                Cancel
              </Button>
            </Stack>
          </Box>
        </Box>
        <Box className={styles.container__connecting}>
          <CallIcon width={40} height={40} />
          <CustomTypography className={styles.container__connecting__text}>
            Waiting On your Invitee To Join
          </CustomTypography>

          <Dots />

          <Box className={styles.container__connecting__button} padding='11px 12px'>
            Connecting...
          </Box>
        </Box>
      </Box>
      <DeletePopup
        withBody
        isOpen={isOpenDrawer}
        title='End Stream'
        onDelete={endStreamNotStarted}
        onClose={() => setIsOpenDrawer(false)}
        body='Please confirm to end the live stream.'
      />
    </>
  );
};

export default LiveStreamJoin;
