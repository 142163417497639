import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

import { teamsApi } from 'api';
import { RootState } from 'types';
import dataStructure from 'constants/options';
import { TeamToastMessages } from 'constants/ToastMessages';
import { capitalizeFirstLetter } from 'utils';

import { TNewUserRole, TRemoveMember } from '../authSlice/types';

import {
  TCreateTeam,
  TGetUserTeamsParams,
  TTag,
  TTeamTag,
  TUpdateTeam,
  TUserTeamsRemove,
} from './types';

export const getAllTags = createAsyncThunk('teamsSlices/getAllTags', async () => {
  try {
    const { data } = await teamsApi.getTagsRequest();

    return data;
  } catch (error) {
    const Error = error as AxiosError;

    if (Error.response?.status === 404) {
      return dataStructure;
    } else {
      throw Error;
    }
  }
});

export const createTeam = createAsyncThunk(
  'teamsSlices/createTeam',
  async (options: TCreateTeam, { dispatch }) => {
    const id = toast.loading(TeamToastMessages.TEAM_CREATION);

    try {
      const response = await teamsApi.createTeamRequest(options);

      toast.update(id, {
        render: TeamToastMessages.TEAM_CREATION_SUCCESS,
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });

      return response.data.data;
    } catch (error) {
      const Error = error as AxiosError;

      const haveErrorMessage =
        !!Error.response &&
        typeof Error?.response?.data === 'string' &&
        !!Error?.response?.data?.length;

      const message = haveErrorMessage
        ? capitalizeFirstLetter(Error?.response?.data as string)
        : TeamToastMessages.TEAM_CREATION_FAILURE;

      toast.update(id, {
        render: message as string,
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });

      throw Error;
    } finally {
      await dispatch(getUserTeams({ org_id: options.org_id }));
    }
  },
);

export const getUserTeams = createAsyncThunk(
  'teamsSlices/getUserTeams',
  async (options: TGetUserTeamsParams, { getState }) => {
    const {
      teams: { allTeamsLimit, allTeamsOffset },
    } = getState() as RootState;

    const { limit = allTeamsLimit, offset = allTeamsOffset, org_id } = options ?? {};

    try {
      const response = await teamsApi.getUserTeamsRequest({ limit, offset, org_id });
      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const removeUserTeam = createAsyncThunk(
  'teamsSlices/removeUserTeam',
  async (options: TUserTeamsRemove, { dispatch }) => {
    const id = toast.loading(TeamToastMessages.TEAM_REMOVAL_START);

    try {
      const response = await teamsApi.deleteTeamRequest(options?.team_id);

      toast.update(id, {
        render: TeamToastMessages.TEAM_REMOVAL_SUCCESS,
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      toast.update(id, {
        render: TeamToastMessages.TEAM_REMOVAL_FAILURE,
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });

      throw Error;
    } finally {
      await dispatch(getUserTeams({ org_id: options?.org_id }));
    }
  },
);

export const updateUserTeam = createAsyncThunk(
  'teamsSlices/updateUserTeam',
  async (options: TUpdateTeam, { dispatch }) => {
    const id = toast.loading(TeamToastMessages.TEAM_EDIT_START);

    try {
      const response = await teamsApi.updateTeamRequest(options);

      toast.update(id, {
        render: TeamToastMessages.TEAM_EDIT_SUCCESS,
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      toast.update(id, {
        render: (Error.response?.data as string) || 'Failed to update the team',
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });

      throw Error;
    } finally {
      await dispatch(getUserTeams({ org_id: options?.org_id as number }));
    }
  },
);

export const createTags = createAsyncThunk(
  'teamsSlices/createTags',
  async (tag: TTag, { dispatch }) => {
    try {
      const response = await teamsApi.createTagsRequest(tag);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    } finally {
      await dispatch(getAllTags());
    }
  },
);

export const createTeamTag = createAsyncThunk(
  'teamSlices/createTeamTags',
  async (tag: TTeamTag) => {
    try {
      const response = await teamsApi.addTeamTagsRequest(tag);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const deleteTeamTag = createAsyncThunk('teamSlices/deleteTeamTag', async (tag: TTeamTag) => {
  try {
    const response = await teamsApi.deleteTeamTagRequest(tag);

    return response.data;
  } catch (error) {
    const Error = error as AxiosError;
    throw Error;
  }
});

export const updateTags = createAsyncThunk(
  'teamsSlices/updateTag',
  async (tag: TTag, { dispatch }) => {
    try {
      const response = await teamsApi.updateTagsRequest(tag);

      dispatch(getAllTags());

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const getTeamsById = createAsyncThunk('teamSlices/getTeamById', async (id: number) => {
  try {
    const response = await teamsApi.getTeamsByIdRequest(id);

    return response.data;
  } catch (error) {
    const Error = error as AxiosError;
    throw Error;
  }
});

export const addMembers = createAsyncThunk(
  'teamSlices/addMembers',
  async (options: TNewUserRole, { dispatch, getState }) => {
    const {
      teams: { allTeamsLimit, allTeamsOffset },
    } = getState() as RootState;

    try {
      const response = await teamsApi.addMemberRequest(options);

      dispatch(
        getUserTeams({ limit: allTeamsLimit, offset: allTeamsOffset, org_id: options.org_id }),
      );

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const removeMember = createAsyncThunk(
  'teamSlices/removeMember',
  async (options: TRemoveMember, { dispatch, getState }) => {
    const {
      teams: { allTeamsLimit, allTeamsOffset },
    } = getState() as RootState;

    try {
      const response = await teamsApi.removeMemberRequest(options);

      dispatch(
        getUserTeams({ limit: allTeamsLimit, offset: allTeamsOffset, org_id: options.org_id }),
      );

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const deleteTag = createAsyncThunk(
  'teamSlices/deleteTag',
  async (id: number, { dispatch }) => {
    try {
      const response = await teamsApi.deleteTagRequest(id);

      dispatch(getAllTags());

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);
