import { GridColDef } from '@mui/x-data-grid';

import { EditIconOutLined, EyeViewIcon, TrashIcon } from 'assets/icons';
import { QuantityStatus, TableCell } from 'components';
import HeaderTitle from 'components/tables/EmployeeTable/HeaderTitle';

import { TDropDownCatalogOptionsParams, TDropDownOptionsParams } from './types';

export const columns: GridColDef[] = [
  {
    flex: 1,
    field: 'image',
    headerName: 'Image',
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle style={{ paddingLeft: '0' }} withSortIcons={false} title={headerName} />;
    },

    renderCell: (params) => params.value,
  },
  {
    flex: 1.5,
    field: 'number',
    headerName: 'Item Number',
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle style={{ paddingLeft: '0' }} title={headerName} />;
    },

    renderCell: (params) => {
      return <TableCell isTooltipDisabled title={params.value} />;
    },
  },
  {
    field: 'name',
    headerName: 'Name',
    flex: 1,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle style={{ paddingLeft: '0' }} title={headerName} />;
    },
    renderCell: (params) => {
      return <TableCell title={params?.value} />;
    },
  },
  {
    field: 'sku',
    headerName: 'UPC/SKU',
    flex: 1.2,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle style={{ paddingLeft: '0' }} title={headerName} />;
    },
    renderCell: (params) => {
      return <TableCell title={params?.value} />;
    },
  },
  {
    field: 'manufacturer',
    headerName: 'Manufacturer',
    flex: 1.5,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle style={{ paddingLeft: '0' }} title={headerName} />;
    },
    renderCell: (params) => {
      return <TableCell title={params?.value} />;
    },
  },
  {
    field: 'cost',
    headerName: 'Cost',
    flex: 1,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle style={{ paddingLeft: '0' }} title={headerName} />;
    },
    renderCell: (params) => {
      return <TableCell title={params?.value} />;
    },
  },
  {
    field: 'qty',
    headerName: 'Quantity',
    flex: 1.2,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle style={{ paddingLeft: '0' }} title={headerName} />;
    },
    renderCell: (params) => {
      return <TableCell title={<QuantityStatus quantity={params?.value} />} />;
    },
  },

  {
    field: 'options',
    headerName: '',
    flex: 0.2,
    renderCell: (params) => {
      return params.value;
    },
  },
];

export const dropdownItemsCreator = ({
  editCallback,
  removeCallback,
  seeDetailsCallback,
}: TDropDownOptionsParams) => [
  {
    icon: <EditIconOutLined />,
    label: 'Edit',
    paramsAction: (id: number) => editCallback(id),
  },

  {
    icon: <TrashIcon />,
    label: 'Delete',
    paramsAction: removeCallback,
  },

  {
    icon: <EyeViewIcon />,
    label: 'See Details',
    paramsAction: (id: number) => seeDetailsCallback(id),
  },
];

export const dropdownItemInCatalogCreator = ({
  editCallback,
  seeDetailsCallback,
}: TDropDownCatalogOptionsParams) => [
  {
    icon: <EditIconOutLined />,
    label: 'Edit',
    paramsAction: (id: number) => editCallback(id),
  },

  {
    icon: <EyeViewIcon />,
    label: 'See Details',
    paramsAction: (id: number) => seeDetailsCallback(id),
  },
];
