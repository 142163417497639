import { RootState } from 'types';

export const currentWorkOrderByIdSelector = (state: RootState) =>
  state.workorders.currentWorkOrderById;

export const currentWorkOrderByIdLoadSelector = (state: RootState) =>
  state.workorders.currentWorkOrderByIdLoading;

export const allWorkOrdersList = (state: RootState) => state.workorders.allWorkOrdersList;

export const allWorkOrdersAsc = (state: RootState) => state.workorders.allWorkOrdersAsc;

export const allWorkOrdersLoadingSelector = (state: RootState) =>
  state.workorders.allWorkOrdersLoading;

export const allWorkOrdersErrorSelector = (state: RootState) => state.workorders.allWorkOrdersError;

export const allWorkOrdersLimitSelector = (state: RootState) => state.workorders.allWorkOrdersLimit;

export const allWorkOrdersOffsetSelector = (state: RootState) =>
  state.workorders.allWorkOrdersOffset;

export const allTasksList = (state: RootState) => state.workorders.allTasksList;

export const allTasksAsc = (state: RootState) => state.workorders.allTasksAsc;

export const allTasksLimitSelector = (state: RootState) => state.workorders.allTasksLimit;

export const allTasksLoadingSelector = (state: RootState) => state.workorders.allTasksLoading;

export const allTasksErrorSelector = (state: RootState) => state.workorders.allTasksError;

export const allTasksOffsetSelector = (state: RootState) => state.workorders.allTasksOffset;

export const allTasksListForUpdate = (state: RootState) => state.workorders.allTasksListForUpdate;

export const currentTaskByIdSelector = (state: RootState) => state.workorders.currentTaskById;
