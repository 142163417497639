import { FC } from 'react';
import { Box } from '@mui/material';

import { CustomBreadcrumbs, CustomTypography } from 'components';
import { BrowserStorageKeys, BrowserStorageService } from 'services';
import { ActiveCardsIcon, CircumIcon, DisableTableIcon, GridViewIcon } from 'assets/icons';

import styles from './DocumentsHeader.module.scss';

import type { TDocumentsHeaderProps } from './types';

const DocumentsHeader: FC<TDocumentsHeaderProps> = ({
  isViewInGrid,
  setIsViewInGrid,
  breadCrumbOptions,
}) => {
  const handleClickGrid = (value: boolean) => {
    setIsViewInGrid(value);
    BrowserStorageService.set(BrowserStorageKeys.DocumentsView, String(value), { session: true });
  };

  return (
    <Box className={styles.container}>
      <Box className={styles.container__header}>
        {breadCrumbOptions && <CustomBreadcrumbs options={breadCrumbOptions} />}
        <Box className={styles.container__header__content}>
          <CustomTypography className={styles.container__header__content__title}>
            Documents
          </CustomTypography>
          <Box className={styles.container__header__content__icon}>
            {isViewInGrid ? (
              <DisableTableIcon onClick={() => handleClickGrid(false)} />
            ) : (
              <GridViewIcon />
            )}
            {isViewInGrid ? (
              <ActiveCardsIcon />
            ) : (
              <CircumIcon onClick={() => handleClickGrid(true)} />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default DocumentsHeader;
