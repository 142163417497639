import { createSlice } from '@reduxjs/toolkit';

import dataStructure from 'constants/options';

import { getAllInvoices, getInvoiceById } from './thunks';

import type { TInitialState } from './types';

const initialState: TInitialState = {
  invoiceLimit: 5,
  invoiceAsc: true,
  invoiceOffset: 0,
  invoicesError: null,
  invoicesLoading: false,
  invoices: dataStructure,
  currentInvoiceById: null,
  currentInvoiceByIdLoad: false,
  currentInvoiceByIdError: null,
};

const invoicesSlice = createSlice({
  name: 'invoicesSlice',
  reducers: {
    setPage(state, action) {
      state.invoiceOffset = (action.payload - 1) * state.invoiceLimit;
    },
    setLimit(state, action) {
      state.invoiceLimit = action.payload;
    },
  },
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getAllInvoices.pending, (state) => {
        state.invoicesLoading = true;
        state.invoicesError = null;
      })
      .addCase(getAllInvoices.fulfilled, (state, action) => {
        state.invoices = action.payload;
        state.invoicesLoading = false;
        state.invoiceAsc = !state.invoiceAsc;
      })

      .addCase(getAllInvoices.rejected, (state, action) => {
        state.invoicesLoading = false;
        state.invoicesError = action.error.message as string;
      })
      .addCase(getInvoiceById.fulfilled, (state, action) => {
        state.currentInvoiceById = action.payload;
        state.currentInvoiceByIdLoad = false;
        state.currentInvoiceByIdError = null;
      })
      .addCase(getInvoiceById.rejected, (state, action) => {
        state.currentInvoiceByIdLoad = false;
        state.currentInvoiceByIdError = action.error.message as string;
      })
      .addCase(getInvoiceById.pending, (state) => {
        state.currentInvoiceById = null;
        state.currentInvoiceByIdLoad = true;
        state.currentInvoiceByIdError = null;
      });
  },
});

export default invoicesSlice.reducer;

export const { setPage, setLimit } = invoicesSlice.actions;
