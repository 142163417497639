import { Colors, FontNames } from 'types';

import type { TCatalogInfoOption } from './types';

export const catalogInfoOptions: TCatalogInfoOption[] = [
  {
    id: 1,
    name: 'name',
    isInput: true,
    required: true,
    errorName: 'name',
    borderRadius: '8px',
    label: 'Catalog name:',
    placeholder: 'Catalog name:',
    borderColor: Colors.SOFT_SILVER,
    fontFamily: FontNames.CIRCULAR_REG,
    backgroundColor: Colors.LIGHT_SILVER,
    labelColor: Colors.ENIGMATIC_MIDNIGHT,
  },
  {
    id: 2,
    isInput: true,
    required: true,
    name: 'category',
    borderRadius: '8px',
    errorName: 'category',
    label: 'Category:',
    placeholder: 'Category:',
    borderColor: Colors.SOFT_SILVER,
    fontFamily: FontNames.CIRCULAR_REG,
    backgroundColor: Colors.LIGHT_SILVER,
    labelColor: Colors.ENIGMATIC_MIDNIGHT,
  },
  {
    id: 3,
    isInput: false,
    required: false,
    borderRadius: '8px',
    rows: 3.5,
    multiline: true,
    name: 'description',
    errorName: 'description',
    borderColor: Colors.SOFT_SILVER,
    placeholder: 'Description:(Optional)',
    label: 'Description:(Optional)',
    backgroundColor: Colors.LIGHT_SILVER,
    fontFamily: FontNames.CIRCULAR_REG,
    labelColor: Colors.ENIGMATIC_MIDNIGHT,
  },
];
