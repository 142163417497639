import { FC } from 'react';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';

import { Colors } from 'types';
import { useAppSelector } from 'hooks';
import { breadCrumbOptions } from 'containers/GenerateEstimate/utils';
import { Button, CustomBreadcrumbs, CustomSkeleton, CustomTypography } from 'components';
import { estimatesSelector } from 'store/slices/estimatesSlice/selectors';
import { generateInventoryItemNumber } from 'utils/generateInventoryItemNumber';

import styles from '../BomEstimateItem.module.scss';

import type { TBomEstimateItemHeaderProps } from './types';

const BomEstimateItemHeader: FC<TBomEstimateItemHeaderProps> = ({ openProfitMarginDrawer }) => {
  const { id } = useParams();

  const { estimateItem, estimateItemLoading } = useAppSelector(estimatesSelector);

  const renderOptions = breadCrumbOptions({
    name: estimateItem?.name as string,
    id: String(id),
  });

  const renderEstimateNumber = estimateItem?.id && generateInventoryItemNumber(estimateItem?.id);

  return (
    <Box className={styles.container__main__header}>
      <Box>
        <CustomBreadcrumbs options={renderOptions} />
      </Box>
      <Box className={styles.container__main__header__info}>
        {estimateItemLoading ? (
          <CustomSkeleton height={20} width={100} />
        ) : (
          <CustomTypography className={styles.container__main__header__title}>
            {estimateItem?.name}
          </CustomTypography>
        )}
        {estimateItemLoading ? (
          <>
            <CustomSkeleton height={20} width={100} />
          </>
        ) : (
          <CustomTypography className={styles.container__main__header__subtitle}>
            Estimate #{renderEstimateNumber}
          </CustomTypography>
        )}
      </Box>
      <Box className={styles.container__main__header__margin}>
        <Button
          padding='11px 8px'
          color={Colors.WHITE}
          backgroundColor={Colors.SAPPHIRE}
          onClick={openProfitMarginDrawer}
          className={styles.container__main__header__margin__button}
        >
          Profit Margin
        </Button>
      </Box>
    </Box>
  );
};

export default BomEstimateItemHeader;
