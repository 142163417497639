import { useEffect } from 'react';
import { noop } from 'lodash';
import { Box, Stack } from '@mui/material';
import { Controller, useFieldArray } from 'react-hook-form';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { PlusIcon } from 'assets/icons';
import { CustomTypography } from 'components';

import styles from './FieldsList.module.scss';

import type { TFieldsListProps } from './types';

type FieldValue = {
  [key: string]: string | number;
};

const FieldsList = <K, G>({
  error,
  control,
  Component,
  fieldName,
  filledItem,
  appendOptions,
  withReplace = false,
  title = 'Add Another Technician',
}: TFieldsListProps<K, G>) => {
  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: fieldName,
  });

  const fieldItems = fields?.map((field, idx) => (
    <Controller
      key={field.id}
      control={control}
      name={`${fieldName}[${idx}]`}
      render={({ field }) => (
        <Component
          idx={idx}
          onAdd={noop}
          field={field}
          errors={error}
          key={field.name}
          control={control}
          fields={fields as G[]}
          onRemove={() => remove(idx)}
          name={`${fieldName}[${idx}]`}
        />
      )}
    />
  ));

  const fieldValues: FieldValue = fields?.reduce((result: FieldValue, value) => {
    for (const key of Object.keys(value)) {
      result[key] = '';
    }
    return result;
  }, {});

  const handleAppendSection = () => {
    if (!appendOptions) {
      append(fieldValues);
    } else {
      append(appendOptions);
    }
  };

  useEffect(() => {
    if (!fields?.length) {
      handleAppendSection();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields?.length]);

  const handleAddNewTech = () =>
    append({
      tech: {
        user_id: '',
        technician_name: '',
        technician_phone: '',
      },
    });

  const fillTopValues = () => {
    if (Array.isArray(fields) && fields.length > 0) {
      const updatedValue = [
        {
          ...fields[0],
          ...filledItem,
        },
        ...fields.slice(1),
      ];

      replace(updatedValue);
    }
  };

  return (
    <Box className={styles.container}>
      {withReplace && (
        <Stack
          onClick={fillTopValues}
          margin='10px 0'
          direction='row'
          alignItems='center'
          gap='5px'
          className={styles.container__copy}
        >
          <ContentCopyIcon />
          Copy from above
        </Stack>
      )}
      {fieldItems}
      {(fieldName === 'members' || fieldName === 'customer.billing') && (
        <Box
          className={styles.container__add}
          onClick={fieldName === 'members' ? handleAddNewTech : handleAppendSection}
        >
          <PlusIcon width={24} height={24} />

          <CustomTypography className={styles.container__add__text}>{title}</CustomTypography>
        </Box>
      )}
    </Box>
  );
};

export default FieldsList;
