import { GridColDef } from '@mui/x-data-grid';

import { FontNames } from 'types';
import HeaderTitle from 'components/tables/EmployeeTable/HeaderTitle';

export const columns: GridColDef[] = [
  {
    field: 'code',
    headerName: 'Coupon Code',
    flex: 1,

    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return (
        <HeaderTitle
          title={headerName}
          style={{ padding: 0 }}
          fontFamily={FontNames.CIRCULAR_BOLD}
        />
      );
    },
    renderCell: (params) => {
      return params?.value;
    },
  },

  {
    field: 'discount',
    headerName: 'Discount',
    flex: 0.8,

    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle title={headerName} style={{ padding: 0 }} />;
    },
    renderCell: (params) => {
      return params?.value;
    },
  },
  {
    field: 'start',
    headerName: 'Start Date',
    flex: 1,

    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle title={headerName} style={{ padding: 0 }} />;
    },
    renderCell: (params) => {
      return params?.value;
    },
  },
  {
    field: 'end',
    headerName: 'End Date',
    flex: 1,

    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle title={headerName} style={{ padding: 0 }} />;
    },
    renderCell: (params) => {
      return params?.value;
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 1,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle title={headerName} style={{ padding: 0 }} />;
    },
    renderCell: (params) => {
      return params.value;
    },
  },
];
