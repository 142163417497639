import { BaseSyntheticEvent, ChangeEvent, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { GridEventListener } from '@mui/x-data-grid';

import { useAppDispatch } from 'hooks';
import { setLimit, setPage as setCatalogPage } from 'store/slices/catalogSlice';
import {
  deleteCatalogById,
  getAllParts,
  getCatalogById,
  getCatalogVersionsById,
  getCurrentSectionVersions,
} from 'store/thunks';
import { getCouponByCatalogId } from 'store/slices/discountSlice/thunks';
import { BrowserStorageKeys, BrowserStorageService } from 'services';

const activeView = BrowserStorageService.get(BrowserStorageKeys.CatalogsView, { session: true });

export const useCatalog = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const view = activeView ? JSON.parse(activeView) : true;

  const [page, setPage] = useState<number>(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isSeeInfo, setIsSeeInfo] = useState<boolean>(false);
  const [isShareOpen, setIsShareOpen] = useState<boolean>(false);
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const [isViewInGrid, setIsViewInGrid] = useState<boolean>(view);
  const [isEditCatalog, setIsEditCatalog] = useState<boolean>(false);
  const [removedUsersIds, setRemovedUsersIds] = useState<number[]>([]);
  const [selectedItemId, setSelectedItemId] = useState<number | null>(null);
  const [isNewDrawer, setIsNewDrawer] = useState<boolean>(state?.isOpenCreateDrawer || false);

  const handlePageChange = (_: unknown, newPage: number) => {
    setPage(newPage);
    dispatch(setCatalogPage(newPage + 1));
  };

  const hideCatalogInfo = () => setIsSeeInfo(false);

  const openDrawer = () => setIsNewDrawer(true);

  const closeDrawer = () => setIsNewDrawer(false);

  const closeEditDrawer = () => setIsEditCatalog(false);

  const togglePopup = () => setIsPopupOpen(!isPopupOpen);

  const onDelete = async () => {
    await dispatch(deleteCatalogById(selectedItemId as number));
    togglePopup();

    if (page > 0) {
      setPage((prev) => prev - 1);
    }
  };

  useEffect(() => {
    if (activeView) {
      setIsViewInGrid(view);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openEditDrawer = async (id: number) => {
    setIsEditCatalog(true);

    const response = await dispatch(getCatalogById(id)).unwrap();
    await dispatch(getCatalogVersionsById(id));
    await dispatch(getCurrentSectionVersions(response?.latest_version_id));
    await dispatch(getCouponByCatalogId(id));
    dispatch(getAllParts());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const handleMoreClick = (event: BaseSyntheticEvent, id: number | null) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);

    setSelectedItemId(id);
  };

  const handleClose = () => {
    if (!isShareOpen) {
      setAnchorEl(null);
    }

    setIsShareOpen(false);
  };

  const seeCatalogInfo = async (id: number) => {
    await dispatch(getCatalogById(id));
    setIsSeeInfo(true);
  };

  const handleRowsPerPageChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    handlePageChange(null, 0);
    dispatch(setLimit(event.target.value));
  };

  const seeCatalogData = (id: string) => {
    navigate(`/product-info/catalog/${id}`);
  };

  const handleEvent: GridEventListener<'rowClick'> = (params) => {
    seeCatalogData(params?.row?.uuid);
  };

  return {
    page,
    anchorEl,
    isSeeInfo,
    onDelete,
    setPage,
    togglePopup,
    isPopupOpen,
    openDrawer,
    closeDrawer,
    handleEvent,
    isNewDrawer,
    isShareOpen,
    handleClose,
    isViewInGrid,
    isEditCatalog,
    selectedItemId,
    setIsShareOpen,
    seeCatalogData,
    openEditDrawer,
    closeEditDrawer,
    seeCatalogInfo,
    handleMoreClick,
    hideCatalogInfo,
    removedUsersIds,
    setIsViewInGrid,
    handlePageChange,
    setRemovedUsersIds,
    handleRowsPerPageChange,
  };
};
