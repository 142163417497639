import { FC } from 'react';
import { Box } from '@mui/material';
import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useAppDispatch, useWindowSize } from 'hooks';
import { DrawerLayout, Button } from 'components';
import { editEstimateProfitMargin } from 'store/slices/estimatesSlice/thunks';
import { Colors, type TFormSubmit } from 'types';

import { breakPoints } from '../../../../constants';

import styles from './ProfitMarginDrawer.module.scss';
import ProfitMarginItem from './ProfitMarginItem/ProfitMarginItem';
import { schema } from './schema';

import type { TFormValues, TProfitMarginProps } from './types';

const ProfitMarginDrawer: FC<TProfitMarginProps> = ({ sections, isOpen, onClose }) => {
  const dispatch = useAppDispatch();

  const defaultValues = sections?.map((item) => ({
    name: item?.section_name,
    margin: item?.profit_margin,
    section_id: item?.id,
    uuid: item?.section_uuid,
    estimate_id: item?.estimate_id,
  }));

  const {
    reset,
    control,

    handleSubmit,
    formState: { isDirty, isSubmitting },
  } = useForm<TFormValues>({
    mode: 'all',
    resolver: yupResolver(schema),
    values: {
      ['profit_margin']: defaultValues,
    },
  });

  const { fields } = useFieldArray({
    control,
    name: 'profit_margin',
  });

  const { width } = useWindowSize();

  const drawerWidth = Number(width) >= breakPoints.WIDE ? 743 : '100%';

  const handleEditProfitMargin: TFormSubmit = (data: any) => {
    const numberedData = data?.profit_margin?.map(
      ({ estimate_id, margin, name, section_id, uuid }: any) => ({
        estimate_id: estimate_id,
        margin: Number(margin),
        name,
        section_id,
        uuid,
      }),
    );

    const filteredData = numberedData?.filter(
      ({ margin: margin1 }: any) =>
        !defaultValues?.some(({ margin: margin2 }: any) => Number(margin1) === Number(margin2)),
    );

    filteredData?.forEach((item: any) => {
      const sendedData = {
        estimate_id: item.section_id,
        estimate_section_id: item.estimate_id,
        body: {
          id: Number(item.section_id),
          profit_margin: Number(item?.margin),
        },
      };

      dispatch(editEstimateProfitMargin(sendedData));
    });

    onClose();
  };

  const onDrawerClose = () => {
    reset();
    onClose();
  };

  const renderFields = fields?.map((item, idx) => (
    <ProfitMarginItem
      idx={idx}
      key={item?.id}
      control={control}
      sectionName={item?.name}
      name={`profit_margin[${idx}].margin`}
    />
  ));

  return (
    <DrawerLayout
      open={isOpen}
      inCenter={false}
      width={drawerWidth}
      onClose={onDrawerClose}
      headerTitle='Profit Margin'
      titleClassName={styles.container__title}
    >
      <form className={styles.container} onSubmit={handleSubmit(handleEditProfitMargin)}>
        <Box className={styles.container__content}>{renderFields}</Box>
        <Box className={styles.container__content__footer}>
          <Button
            type='button'
            minWidth='65px'
            color={Colors.SAPPHIRE}
            variant='contained'
            backgroundColor={Colors.PALE_BLUE}
            className={styles.container__content__footer__button}
          >
            Cancel
          </Button>
          <Button
            color={Colors.WHITE}
            type='submit'
            minWidth='78px'
            variant='contained'
            backgroundColor={Colors.SAPPHIRE}
            disabled={!isDirty || isSubmitting}
            className={styles.container__content__footer__button}
          >
            Generate
          </Button>
        </Box>
      </form>
    </DrawerLayout>
  );
};

export default ProfitMarginDrawer;
