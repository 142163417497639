import ProfileSettings from 'components/views/AccountSettingsComponents/Profile';
import { Settings, Plans, Billing, Organization } from 'components/views/AccountSettingsComponents';
import { TOption } from 'components/shared/Select/types';
import PaymentOnboardingContainer from 'components/views/AccountSettingsComponents/PaymentOnboarding/PaymentOnbaordingConatiner/index';

import { AccountSettingsNavbarItems, TAccountSettingsNavbarItems } from './types';

export const navbarItemsOwner: TAccountSettingsNavbarItems[] = [
  { id: 1, title: AccountSettingsNavbarItems.PROFILE, content: <ProfileSettings /> },
  { id: 3, title: AccountSettingsNavbarItems.SETTINGS, content: <Settings /> },
  { id: 8, title: AccountSettingsNavbarItems.ORGANIZATION, content: <Organization /> },
  {
    id: 9,
    title: AccountSettingsNavbarItems.PAYMENTONBOARDING,
    content: <PaymentOnboardingContainer />,
  },
];

export const navbarItemsOwnerCreated: TAccountSettingsNavbarItems[] = [
  { id: 1, title: AccountSettingsNavbarItems.PROFILE, content: <ProfileSettings /> },
  { id: 3, title: AccountSettingsNavbarItems.SETTINGS, content: <Settings /> },
  { id: 5, title: AccountSettingsNavbarItems.PLANS, content: <Plans /> },
  { id: 6, title: AccountSettingsNavbarItems.BILLING, content: <Billing /> },
  { id: 8, title: AccountSettingsNavbarItems.ORGANIZATION, content: <Organization /> },
  {
    id: 9,
    title: AccountSettingsNavbarItems.PAYMENTONBOARDING,
    content: <PaymentOnboardingContainer />,
  },
];

export const navbarItemsManager: TAccountSettingsNavbarItems[] = [
  { id: 1, title: AccountSettingsNavbarItems.PROFILE, content: <ProfileSettings /> },
  { id: 3, title: AccountSettingsNavbarItems.SETTINGS, content: <Settings /> },
];

export const navbarItemsManagerEmployees: TAccountSettingsNavbarItems[] = [
  { id: 1, title: AccountSettingsNavbarItems.PROFILE, content: <ProfileSettings /> },
  { id: 3, title: AccountSettingsNavbarItems.SETTINGS, content: <Settings /> },
];

export const navbarItems: TAccountSettingsNavbarItems[] = [
  { id: 1, title: AccountSettingsNavbarItems.PROFILE, content: <ProfileSettings /> },
  { id: 3, title: AccountSettingsNavbarItems.SETTINGS, content: <Settings /> },
];

export const navbarItemsEmployees: TAccountSettingsNavbarItems[] = [
  { id: 1, title: AccountSettingsNavbarItems.PROFILE, content: <ProfileSettings /> },
  { id: 3, title: AccountSettingsNavbarItems.SETTINGS, content: <Settings /> },
];

export const accountHolderOptions: TOption[] = [
  { value: 'company', label: 'Company' },
  { value: 'individual', label: 'Individual' },
];
