import { client } from 'api/client';
import { endpoints } from 'api/endpoints';

export const getAllRolesRequest = (type: string) =>
  client.get(endpoints.RolesService.getAllRoles(), {
    params: {
      type,
      limit: 20,
      offset: 0,
    },
  });
