import { FC } from 'react';
import { Box } from '@mui/material';

import { CreatePost, CustomSkeleton, LazyLoadWithTimeout, Post, PostFilter } from 'components';
import { useAppSelector } from 'hooks';
import { activitiesSliceSelector } from 'store/slices/activitiesSlices/selector';
import DefaultLazyFallBack from 'components/shared/DefaultLazyFallback';

import styles from '../ActivityFeed.module.scss';
import PostsLoadMore from '../LoadMoreButton/PostsLoadMore';

import { TPostsSectionProps } from './types';

const LAZY_TIMEOUT = 2000;

const PostsSection: FC<TPostsSectionProps> = ({
  currentList,
  loadingState,
  changePage,
  currentListLoad,
  inTabView = false,
}) => {
  const { activitiesLoading: activityDataLoading, activities } =
    useAppSelector(activitiesSliceSelector);

  const postRenderer = currentList?.map((post) => {
    return !post?.is_hidden && <Post key={post.id} post={post} />;
  });

  const isShowLoadMoreButton =
    currentList?.length < activities?.total_count && !activityDataLoading && !!postRenderer?.length;

  const initialSkeletonsList = Array.from({ length: 3 })?.map((_, idx) => (
    <CustomSkeleton height={200} borderRadius='10px' key={idx} />
  ));

  const lazyLoadComponentProps = {
    title: 'No Posts created yet',
    loadingDone: activityDataLoading,
  };

  const renderHeader = inTabView ? (
    <>
      <Box className={styles.container_activity_head}>
        <CreatePost />
      </Box>

      <Box className={styles.container_activity_filter}>
        <PostFilter />
      </Box>
    </>
  ) : (
    <>
      <CreatePost />
      <PostFilter />
    </>
  );

  return (
    <Box className={styles.container_activity}>
      {renderHeader}
      {postRenderer?.length ? (
        postRenderer
      ) : (
        <LazyLoadWithTimeout
          timeout={LAZY_TIMEOUT}
          fallback={<DefaultLazyFallBack />}
          componentProps={lazyLoadComponentProps}
          loader={() => import('../../../components/shared/EmptyTitle')}
        />
      )}

      {loadingState && initialSkeletonsList}
      {isShowLoadMoreButton && (
        <PostsLoadMore changePage={changePage} currentListLoad={currentListLoad} />
      )}
    </Box>
  );
};

export default PostsSection;
