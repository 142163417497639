import { AxiosError } from 'axios';

export enum TokenProvider {
  GOOGLE = 'google',
  MICROSOFT = 'microsoft',
}

export type TOAuthParams = {
  oauth_token: string;
  oauth_provider: TokenProvider;
} & Partial<{
  invitation_token: string;
}>;

export type TUpdateUser = {
  name: string;
  email: string;
  state: string;
  city: string;
  address: string;
  country: string;
  zip_code: string;
  mobile_number: string;
  office_number: string;
};

export type TNewUser = {
  email: string;
};

export type TUser = {
  new_user: TNewUser;
};

export type TPermission = {
  org_id: number;
  role_id: number;
  user_id: number;
  team_id: number | null;
};

export type TInitialState = {
  users: TUser[];
  loading: boolean;
  searchName: string;
  roles: TNewRole[];
  foundUsers: TUser[];
  userRolesLoading: boolean;
  userDataLoading: boolean;
  userAllRoles: TUserDefinedRole[];
  allRolesLoading: boolean;
  error: null | Error | string;
  usersByRoles: TUserByRole[];
  currentUserLoading: boolean;
  userData: TUserProfile | null;
  userDataError: null | Error | string;
  currentUserById: TUserProfile | null;
  currentUserError: string | Error | null;
  permissions: TPermission[] | null;
  currentSingleUserById: TUserProfile | null;
  refreshTOkenError: null | Error | string | AxiosError;
  notificationSetting: TUserNotificationSettings | null;
};

export type TPagination = Partial<{
  limit: number;
  offset: number;
}>;

export type TNewRole = {
  role: string;
  is_public: boolean;
};

export type TNewUserRole = {
  role_id: number;
  user_id: number;
  org_id: number;
};

export type TUserDefinedRole = {
  id: number;
  role: string;
  role_id: number;
  user_id: number;
} & Partial<{
  org_id: number;
  team_id: number;
}>;

export type TRemoveMember = {
  user_id: number;
  org_id: number;
  team_id: number;
};

export type TUpdateInvitationStatus = {
  id: number;
  status: 'pending' | 'accepted' | 'rejected' | 'revoked';
};

export type TUpdateUserSettings = {
  id: number;
  email_notification: boolean;
  sms_notification: boolean;
  // in_app_notification: boolean;
};

export type TSearch = {
  query: string;
  limit: number;
  offset: number;
} & Partial<{
  distance: number;
}>;

export type TUserProfile = {
  id: number;
  email: string;
  name: string;
  token: TToken;
  is_active: boolean;
  created_at: string;
  city: string | null;
  updated_at: string;
  designation: string;
  state: string | null;
  country: string | null;
  is_superuser: boolean;
  address: string | null;
  oauth_provider: string;
  zip_code: string | null;
  user_roles: TUserRoles[];
  is_invited_user: boolean;
  mobile_number: null | string;
  office_number: null | string;
  profile_image_url_id: string;
  is_first_time_user: boolean;
};

export type TUsersReturnType = {
  data: TUserDataByIdResponse[];
  total_count: number;
};

export type TUserDataByIdResponse = {
  email: string;
  name: string;
  is_active: boolean;
  is_superuser: boolean;
  created_at: string | Date;
  updated_at: string | Date;
  id: number;
  oauth_provider: string;
  mobile_number: number | null;
  office_number: number | null;
  designation: string;
  profile_image_url_id: string;
  address: string | null;
  state: string | null;
  city: string | null;
  country: string | null;
  zip_code: string;
  is_invited_user: boolean;
  is_first_time_user: boolean;
};

export type TToken = {
  access_token: string;
  refresh_token: string;
  token_type: string;
  access_token_expiry: string;
  refresh_token_expiry: string;
};

export type TUserRoles = {
  role_id: number;
  user_id: number;
  org_id: number | null;
  team_id: number | null;
  created_at: string;
  updated_at: string;
  role: string;
  id: number;
  media_url_id: string | null;
};

export type TUserNotificationSettings = {
  id: number;
  email_notification: boolean;
  sms_notification: boolean;
  in_app_notification: boolean;
};

export type TUserByRole = {
  id: number;
  name: string;
  email: string;
  org_id: number;
  user_id: number;
  role_id: number;
  team_id: null | number;
  profile_image_url_id: string;
  mobile_number: null | number;
  office_number: null | number;
};
