import {
  ColorSystemOptions,
  experimental_extendTheme as extendTheme,
  useColorScheme,
} from '@mui/material/styles';

import {
  Theme as CustomTheme,
  ThemeColors as CustomThemeColors,
  ThemeMode as CustomThemeMode,
} from './CustomTheme';

declare module '@mui/material/styles' {
  interface Palette {
    primaryText?: Palette['primary'];
    neutral?: Palette['primary'];
    neutralOpacity40?: Palette['primary'];
    whiteOpacity60?: Palette['primary'];
  }
  interface PaletteOptions {
    primaryText?: PaletteOptions['primary'];
    neutral?: PaletteOptions['primary'];
    neutralOpacity40?: PaletteOptions['primary'];
    whiteOpacity60?: PaletteOptions['primary'];
  }
  interface BreakpointOverrides {
    xs?: true;
    sm?: false;
    md?: true;
    lg?: false;
    xl?: false;
    mobile: true;
    tablet: true;
  }
}

export const createMuiTheme = (customTheme: CustomTheme) => {
  return extendTheme({
    breakpoints: {
      values: {
        mobile: 0, //* tablet layout starts when mobile width is reached
        tablet: customTheme.breakpoints.mobile,
        xs: customTheme.breakpoints.tabletM,
        md: customTheme.breakpoints.desktop,
      },
    },
    colorSchemes: {
      light: {
        palette: generateMuiPalette(customTheme.colors['light']),
      },
    },
  });
};

export const convertToMuiMode = (mode: CustomThemeMode): MuiMode => {
  let muiMode: MuiMode = null;
  if (mode) {
    switch (mode) {
      case 'light': {
        muiMode = 'light';
        break;
      }
    }
  }

  return muiMode;
};

type MuiMode = Parameters<ReturnType<typeof useColorScheme>['setMode']>[0];

const generateMuiPalette = (themeColors: CustomThemeColors): ColorSystemOptions['palette'] => {
  let palette = {} as ColorSystemOptions['palette'];
  for (const color in themeColors) {
    palette = {
      ...palette,
      [color]: {
        main: themeColors[color as keyof CustomThemeColors],
      },
    };
  }

  return palette;
};
