export const sxMenuStyles = {
  '& .MuiPaper-root': {
    borderRadius: '10px',
    border: '0.5px dashed #C4C7CB',
    background: '#fff',
    padding: '12px',
  },

  '& .MuiList-root': {
    paddingTop: 0,
    paddingBottom: 0,
  },

  '& .MuiTouchRipple-root': {
    minHeigh: 'unset',
  },

  '& .MuiMenuItem-root ': {
    display: 'flex',
    gap: '8px',
    minHeight: 'unset !important',
    fontSize: '14px',
    padding: '12px 12px 9px 12px',
    color: 'rgba(34, 34, 34, 0.90)',
    lineHeight: 'normal',
    letterSpacing: '0.035px',
    fontFamily: 'CircularStdRegular',
  },
};
