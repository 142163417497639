import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { Box } from '@mui/material';
import { AxiosError } from 'axios';

import { useAppDispatch } from 'hooks';
import {
  GeneralInfo,
  BuildingConstruction,
  WifiCoverage,
  Equipment,
  NetworkConfig,
  AdditionConfig,
} from 'assets/icons';
import { TEstimateFromTemplateBody } from 'store/slices/estimatesSlice/types';
import { createEstimateFromTemplate } from 'store/slices/estimatesSlice/thunks';
import { Button, ControlledInput, ControlledSelect, CustomBreadcrumbs } from 'components';
import { Colors, FontNames, Routes } from 'types';
import { getElementsById } from 'store/slices/estimatesSlice/thunks';

import { breadCrumbOptions } from './utils';
import styles from './BomEstimate.module.scss';
import { TField, TSection } from './types';

import type { TVendorFormValues } from 'containers/Inventory/ExcelList/AddEstimateViaTemplate/types';
import type { TOption } from 'components/shared/Select/types';

const BomEstimateviatemplateForm = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;

  const Template_ID = state?.Template_ID;
  const CustomerID = state?.CustomerID;
  const estimateName = state?.estimateName;
  const description = state?.description;
  const estimate_version_tag = state?.estimate_version_tag;
  const [mypayload, setmyPayload] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dispatch(getElementsById(Template_ID || 0));
        const data = await response.payload;
        setmyPayload(data);
      } catch (error) {
        const Error = error as AxiosError;
        throw Error;
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Template_ID]);

  const handleCreateData: SubmitHandler<FieldValues> = async (data: TVendorFormValues) => {
    const jsonData: Record<string, Record<string, string | number | boolean>> = {};

    if (data['Wi-Fi 6 Required?']) {
      data['Wi-Fi 6 Required? (802.11AX)'] = data['Wi-Fi 6 Required?'];
      delete data['Wi-Fi 6 Required?'];
    }
    if (data['Outdoor Area Qty (1 per 150-200 Radius area)']) {
      data["Outdoor Area Qty (1 per 150-200' Radius area)"] =
        data['Outdoor Area Qty (1 per 150-200 Radius area)'];
      delete data['Outdoor Area Qty (1 per 150-200 Radius area)'];
    }
    mypayload?.forEach((section: TSection) => {
      const sectionData: Record<string, string | number | boolean> = {};

      section.fields.forEach((field: TField) => {
        let value = data[field.name];

        // Set empty value as default based on field type if value is undefined
        if (value === undefined || value === null || value === '') {
          switch (field.type) {
            case 'number':
              value = 0;
              break;
            case 'text':
              value = '';
              break;
            case 'enum':
              value = field.values[0] || '';
              break;
            case 'boolean':
              value = 'No';
              break;
            default:
              value = '';
              break;
          }
        }
        const correctedFieldName =
          field.name === 'Outdoor Area Qty (1 per 150-200 Radius area)'
            ? "Outdoor Area Qty (1 per 150-200' Radius area)"
            : field.name;

        sectionData[correctedFieldName] = value;
      });
      jsonData[section.section] = sectionData;

      jsonData['estimate_version_tag'] = estimate_version_tag;
      jsonData['estimate_name'] = estimateName;
      jsonData['customer_id'] = CustomerID;
      jsonData['description'] = description;
    });
    const body: TEstimateFromTemplateBody = {
      name: estimateName,
      description: description,
      customer_id: CustomerID,
      data: jsonData,
    };

    await dispatch(createEstimateFromTemplate({ estimate_template_id: Template_ID, body: body }));
    navigate(Routes.BomPricingEstimate);
    reset();
  };

  const onDrawerClose = () => {
    navigate(Routes.BomPricingEstimate);
    reset();
  };
  const {
    reset,
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<TVendorFormValues>();

  const inputFields = mypayload?.flatMap((section: TSection) => {
    let iconComponent = null;
    switch (section.section) {
      case 'GENERAL INFO':
        iconComponent = <GeneralInfo className={styles.icon} />;
        break;
      case 'BUILDING CONSTRUCTION':
        iconComponent = <BuildingConstruction className={styles.icon} />;
        break;
      case 'WIFI COVERAGE':
        iconComponent = <WifiCoverage className={styles.icon} />;
        break;
      case 'EQUIPMENT CABLING':
        iconComponent = <Equipment className={styles.icon} />;
        break;
      case 'NETWORK CONFIGURATION':
        iconComponent = <NetworkConfig className={styles.icon} />;
        break;
      case 'ADDITIONAL INFO':
        iconComponent = <AdditionConfig className={styles.icon} />;
        break;
      default:
        // If no match, set a default icon or leave it as null
        iconComponent = null;
        break;
    }
    return (
      <div key={section.section} className={styles.container__content__item}>
        {iconComponent}
        <h1 className={styles.container__content__item}>{section.section}</h1>
        {section.fields.map((field: TField) => {
          switch (field.type) {
            case 'number':
              return (
                <span
                  key={`${section.section}-${field.name}`}
                  className={styles.container__content__item}
                >
                  <ControlledInput
                    name={
                      field.name === "Outdoor Area Qty (1 per 150-200' Radius area)"
                        ? 'Outdoor Area Qty (1 per 150-200 Radius area)'
                        : field.name
                    }
                    label={field.name}
                    control={control}
                    required={false}
                    borderColor={Colors.SOFT_SILVER}
                    fontFamily={FontNames.CIRCULAR_REG}
                    placeholder={field.name}
                    borderRadius='5px'
                    labelColor={Colors.ENIGMATIC_MIDNIGHT}
                  />
                </span>
              );
            case 'text':
              return (
                <span
                  key={`${section.section}-${field.name}`}
                  className={styles.container__content__item}
                >
                  <ControlledInput
                    name={field.name}
                    label={field.name}
                    control={control}
                    required={false}
                    borderColor={Colors.SOFT_SILVER}
                    fontFamily={FontNames.CIRCULAR_REG}
                    placeholder={field.name}
                    borderRadius='5px'
                    labelColor={Colors.ENIGMATIC_MIDNIGHT}
                  />
                </span>
              );
            case 'enum': {
              const fieldOptions: TOption[] = field.values.map((item, index) => ({
                id: index + 1,
                value: item,
                label: String(item),
              }));
              return (
                <span className={styles.container__content__item}>
                  <ControlledSelect
                    name={field.name}
                    label={field.name}
                    defaultValue=''
                    control={control}
                    required={true}
                    options={fieldOptions}
                    fontFamily={FontNames.CIRCULAR_REG}
                    border='1px solid #EDEFF1'
                    borderRadius='5px'
                    labelColor={Colors.ENIGMATIC_MIDNIGHT}
                  />
                </span>
              );
            }
            case 'boolean':
              return (
                <span className={styles.container__content__item}>
                  <ControlledSelect
                    name={
                      field.name === 'Wi-Fi 6 Required? (802.11AX)'
                        ? 'Wi-Fi 6 Required?'
                        : field.name
                    }
                    label={field.name}
                    defaultValue=''
                    control={control}
                    required={false}
                    options={[
                      { id: 1, value: 'Yes', label: 'Yes' },
                      { id: 2, value: 'No', label: 'No' },
                    ]}
                    fontFamily={FontNames.CIRCULAR_REG}
                    border='1px solid #EDEFF1'
                    borderRadius='5px'
                    labelColor={Colors.ENIGMATIC_MIDNIGHT}
                  />
                </span>
              );
            default:
              return null;
          }
        })}
      </div>
    );
  });

  return (
    <div className={styles.pageContainer}>
      {/* Header */}
      <CustomBreadcrumbs options={breadCrumbOptions} />
      <header className={styles.header}>
        <h6>
          <strong>Create Estimate: {estimateName}</strong>
        </h6>
        <h5>
          <strong>{estimate_version_tag}</strong>
        </h5>
      </header>

      {/* Form Content */}
      <form onSubmit={handleSubmit(handleCreateData)} className={styles.container}>
        <Box sx={{ flex: '1 1 auto' }}>
          <Box className={styles.container__content}>{inputFields}</Box>
        </Box>
        <Box className={styles.container__footer}>
          <Button
            type='submit'
            color='white'
            maxWidth='180px'
            minWidth='68px'
            padding='11px 8px'
            borderRadius='5px'
            backgroundColor={Colors.SAPPHIRE}
            fontFamily={FontNames.CIRCULAR_BOLD_MAX}
            disabled={isSubmitting}
            className={styles.container__content__submit}
          >
            Generate Estimate
          </Button>
          <Button
            type='button'
            maxWidth='180px'
            padding='11px 8px'
            minWidth='68px'
            borderRadius='5px'
            onClick={onDrawerClose}
            color={Colors.SAPPHIRE}
            backgroundColor={Colors.PALE_BLUE}
            fontFamily={FontNames.CIRCULAR_BOLD}
            className={styles.container__content__submit}
          >
            Cancel
          </Button>
        </Box>
      </form>
    </div>
  );
};

export default BomEstimateviatemplateForm;
