import { GridColDef } from '@mui/x-data-grid';

import { ImageBlank } from 'assets/images';
import { ImgWithDefault, TableCell } from 'components';
import HeaderTitle from 'components/tables/EmployeeTable/HeaderTitle';
import PreloadedImg from 'components/views/PreloadedImg';
import { ImgUrlFolders } from 'types';

export const columns: GridColDef[] = [
  {
    field: 'image',
    headerName: '',
    flex: 0.6,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle style={{ display: 'none' }} title={headerName} />;
    },
    renderCell: (params) => (
      <TableCell
        isTooltipDisabled
        title={
          params.value ? (
            <PreloadedImg folderName={ImgUrlFolders.INVENTORY} imgId={params.value} />
          ) : (
            <ImgWithDefault
              src={ImageBlank}
              fallback={ImageBlank}
              style={{ paddingLeft: 0, maxWidth: 64, width: 64, height: 50 }}
            />
          )
        }
      />
    ),
  },
  {
    field: 'itemName',
    headerName: 'Item Name',
    flex: 1,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle style={{ paddingLeft: '0' }} title={headerName} />;
    },
    renderCell: (params) => {
      return <TableCell style={{ paddingLeft: '0', color: '#282828' }} title={params?.value} />;
    },
  },

  {
    field: 'itemNumber',
    headerName: 'Inv ID',
    flex: 1,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle style={{ paddingLeft: '0' }} title={headerName} />;
    },
    renderCell: (params) => {
      return <TableCell style={{ paddingLeft: '0', color: '#282828' }} title={params?.value} />;
    },
  },

  {
    field: 'description',
    headerName: 'Description',
    flex: 2,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle style={{ paddingLeft: '0' }} title={headerName} />;
    },
    renderCell: (params) => {
      return (
        <TableCell
          style={{ paddingLeft: '0', width: '100%', maxWidth: '100%', color: '#282828' }}
          title={params?.value}
        />
      );
    },
  },

  {
    field: 'unit_cost',
    headerName: 'Unit cost',
    flex: 1,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle style={{ paddingLeft: '0' }} title={headerName} />;
    },
    renderCell: (params) => {
      return <TableCell style={{ paddingLeft: '0', color: '#282828' }} title={params?.value} />;
    },
  },
  {
    field: 'quantity',
    headerName: 'Quantity',
    flex: 1,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle style={{ paddingLeft: '0' }} title={headerName} />;
    },
    renderCell: (params) => {
      return <TableCell style={{ paddingLeft: '0', color: '#282828' }} title={params?.value} />;
    },
  },
  {
    field: 'amount',
    headerName: 'Amount',
    flex: 1,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle style={{ paddingLeft: '0' }} title={headerName} />;
    },
    renderCell: (params) => {
      return <TableCell style={{ paddingLeft: '0', color: '#282828' }} title={params?.value} />;
    },
  },
];
