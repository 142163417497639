import { FC, useEffect, useState } from 'react';
import { Box } from '@mui/material';

import {
  Button,
  EmptyTitle,
  DrawerLayout,
  CustomSkeleton,
  CustomTypography,
  ItemUsageDrawer,
  TextFrame,
} from 'components';
import { useAppDispatch, useAppSelector, useWindowSize } from 'hooks';
import {
  currentPartByIdSelector,
  partHistoryDataSelector,
  openedSnapshotDataSelector,
  currentPartByIdLoadSelector,
} from 'store/slices/inventorySlice/selectors';
import { Colors, FontNames } from 'types';
import PreloadedImg from 'components/views/PreloadedImg';
import { ImgUrlFolders } from 'types/global/imgUrlFolders';
import { getPartHistory } from 'store/slices/inventorySlice/thunks';
import { getCatalogListItemUsage } from 'store/slices/catalogSlice/thunks';
import {
  itemUsageDataLimitSelector,
  itemUsageDataOffsetSelector,
  itemUsageDataSelector,
} from 'store/slices/catalogSlice/selectors';
import { generateInventoryItemNumber } from 'utils/generateInventoryItemNumber';

import { breakPoints } from '../../../../constants';

import { TInventoryDetailsDrawerProps } from './types';
import styles from './InventoryDetailsDrawer.module.scss';
import HistoryDetails from './HistoryDetails';

const InventoryDetailsDrawer: FC<TInventoryDetailsDrawerProps> = ({ open, onClose }) => {
  const dispatch = useAppDispatch();

  const history = useAppSelector(partHistoryDataSelector);
  const itemUsageData = useAppSelector(itemUsageDataSelector);
  const currentItemData = useAppSelector(currentPartByIdSelector);
  const openedHistory = useAppSelector(openedSnapshotDataSelector);
  const currentItemDataLoad = useAppSelector(currentPartByIdLoadSelector);

  const itemUsageLimit = useAppSelector(itemUsageDataLimitSelector);
  const itemUsageOffset = useAppSelector(itemUsageDataOffsetSelector);

  const hasHistory = history?.data?.length > 1 || openedHistory?.data?.length;

  const { width } = useWindowSize();

  useEffect(() => {
    if (currentItemData?.id) {
      dispatch(
        getCatalogListItemUsage({
          limit: itemUsageLimit,
          offset: itemUsageOffset,
          item_id: currentItemData?.id,
        }),
      );
      dispatch(getPartHistory(currentItemData?.id as number));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentItemData?.id, itemUsageLimit, itemUsageOffset]);

  const [isSeeItemUsage, setIsSeeItemUsage] = useState<boolean>(false);

  const drawerWidth = Number(width) <= breakPoints.TABLET ? 350 : 472;

  const historyElements = history?.data?.map(
    (historyData, idx) =>
      idx !== history?.data?.length - 1 && (
        <HistoryDetails history={historyData} key={historyData?.id} />
      ),
  );

  const filteredKeys = [
    'manufacturer',
    'cost',
    'qty',
    'item_location',
    'brand',
    'preferred_vendor',
  ];

  const newArray = filteredKeys.map((key, index) => {
    let label = key;
    let value: string | number | Date | boolean =
      currentItemData?.[key as keyof typeof currentItemData] || '';

    if (key === 'qty') {
      label = 'quantity';
    }

    if (key === 'item_location') {
      label = 'item location';
    }

    if (key === 'preferred_vendor') {
      label = 'preferred vendor';
    }

    if (key === 'vendor') {
      value = String(currentItemData?.preferred_vendor);
    }

    return {
      label,
      value,
      id: index,
    };
  });

  const renderedOptions = newArray?.map((item) => (
    <Box className={styles.container__content__main} key={item?.id}>
      <CustomTypography className={styles.container__content__main__title}>
        {item?.label}
      </CustomTypography>
      {currentItemDataLoad ? (
        <CustomSkeleton height={10} width='100px' />
      ) : (
        <CustomTypography className={styles.container__content__main__subtitle}>
          {(!!item?.value && String(item?.value)) || '--'}
        </CustomTypography>
      )}
    </Box>
  ));

  const seeItemUsage = () => setIsSeeItemUsage(true);
  const hideItemUsage = () => setIsSeeItemUsage(false);

  const renderNumber = generateInventoryItemNumber(currentItemData?.id as number);

  const handleClose = () => {
    onClose();
  };

  return (
    <>
      <DrawerLayout width={drawerWidth} open={open} onClose={handleClose} headerTitle=''>
        <Box className={styles.container}>
          <Box className={styles.container__head}>
            <Box className={styles.container__head__left}>
              <CustomTypography className={styles.container__head__left__title}>
                Item Information
              </CustomTypography>
              <CustomTypography className={styles.container__head__left__subtitle}>
                <span className={styles.container__head__left__subtitle__title}>Item Number:</span>
                <span className={styles.container__head__left__subtitle__name}>
                  INV-{renderNumber}
                </span>
              </CustomTypography>
            </Box>
            <Box className={styles.container__head__right}>
              <Button
                borderRadius='5px'
                padding='12.5px 10px'
                onClick={seeItemUsage}
                backgroundColor={Colors.PALE_BLUE}
                disabled={!itemUsageData?.data?.length}
                className={styles.container__head__right__usage}
              >
                ITEM USAGE
              </Button>
            </Box>
          </Box>
          <CustomTypography className={styles.section__title}>Item Properties</CustomTypography>

          <>
            <Box className={styles.container__content}>
              <Box className={styles.container__content__head}>
                <CustomTypography className={styles.container__content__head__item}>
                  Property
                </CustomTypography>
                <CustomTypography className={styles.container__content__head__item}>
                  Value
                </CustomTypography>
              </Box>
              {renderedOptions}
            </Box>
            <Box className={styles.container__description}>
              <Box className={styles.container__description__img}>
                <PreloadedImg
                  imgStyles={{ objectFit: 'contain' }}
                  height={205}
                  folderName={ImgUrlFolders.INVENTORY}
                  imgId={currentItemData?.image_url_id as string}
                />
              </Box>
              <CustomTypography className={styles.container__description__title}>
                Description
              </CustomTypography>

              {currentItemData?.description && currentItemData?.description !== 'null' ? (
                <TextFrame
                  padding='12px'
                  borderRadius='5px'
                  border='0.5px solid #ccc'
                  backgroundColor={Colors.PLATINUM_GRAY}
                  textColor={Colors.SHADOWED_SLATE}
                  fontFamily={FontNames.CIRCULAR_REG}
                  content={currentItemData?.description}
                />
              ) : (
                'Items does not have description'
              )}
            </Box>
            <Box className={styles.container__history}>
              {!!hasHistory && (
                <CustomTypography className={styles.section__title__history}>
                  History
                </CustomTypography>
              )}
              <Box className={styles.container__history__content}>
                {hasHistory ? historyElements : <EmptyTitle title='Item does not have a history' />}
              </Box>
            </Box>
          </>
        </Box>
      </DrawerLayout>
      <ItemUsageDrawer
        open={isSeeItemUsage}
        onClose={hideItemUsage}
        itemNumber={`INV - ${renderNumber || 1}`}
        itemName={currentItemData?.name as string}
      />
    </>
  );
};

export default InventoryDetailsDrawer;
