import { FC, useEffect } from 'react';
import { LazyMotion, domAnimation, motion, useAnimation, useInView } from 'framer-motion';

import { useWindowSize } from 'hooks';

import { breakPoints } from '../../../constants';

import { TRevealBlockProps } from './types';

const RevealBlock: FC<TRevealBlockProps> = ({
  children,
  renderedContentRef,
  style,
  transition = { duration: 0.5, delay: 0.25 },
  variant = { visible: { opacity: 1, y: 0 }, hidden: { opacity: 0, y: 100 } },
}) => {
  const isInView = useInView(renderedContentRef);

  const { width } = useWindowSize();

  const defaultVariant =
    Number(width) > breakPoints.TABLET_L
      ? variant
      : { visible: { opacity: 1, y: 0 }, hidden: { opacity: 0, y: 0 } };

  const mainControls = useAnimation();

  useEffect(() => {
    if (isInView) {
      mainControls.start('visible');
    } else {
      mainControls.start('hidden');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInView]);

  return (
    <LazyMotion features={domAnimation}>
      <div ref={renderedContentRef} style={{ ...style, position: 'relative', overflow: 'hidden' }}>
        <motion.div
          initial='hidden'
          animate={mainControls}
          transition={transition}
          variants={defaultVariant}
          exit={{ opacity: 0 }}
        >
          {children}
        </motion.div>
      </div>
    </LazyMotion>
  );
};

export default RevealBlock;
