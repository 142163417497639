export const sxStyles = {
  fontFamily: 'CircularStdRegular',
  fontSize: '14px',
  lineHeight: 'normal',
  letterSpacing: '0.035px',
  minHeight: '26px !important',
  maxHeight: '26px !important',
  textTransform: 'capitalize',

  '& .MuiButtonBase-root': {
    minWidth: '88px',
    color: 'black !important',
  },
};

export const tabContainerSx = {
  minHeight: '30px !important',

  '& .MuiButtonBase-root': {
    color: 'black !important',
  },

  '& .Mui-selected': {
    color: '#2067DD !important',
  },
};
