import { BaseSyntheticEvent, FC, useLayoutEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';
import { ColorResult } from '@uiw/react-color';

import { DeletePopup } from 'components';
import { deleteJobType, deleteTag } from 'store/thunks';
import { useAppDispatch, useOnClickOutside } from 'hooks';

import { CreateNewActivity } from '..';

import { defaultJobTypeValues } from './utils';

import type { TActivityDropDown, TUpdateActivityParams } from './types';

const ActivityDropDown: FC<TActivityDropDown> = ({
  title,
  addTag,
  className,
  setAddTag,
  setAddMore,
  selectedJobTypes,
  setSelectedJobType,
}) => {
  const dispatch = useAppDispatch();

  const onClose = () => {
    setAddTag?.(false);
    setAddMore?.(false);
  };

  const [editTag, setEditTag] = useState<TUpdateActivityParams>(defaultJobTypeValues);
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedId, setSelectedId] = useState<number | null>(null);

  const boxRef = useRef<HTMLDivElement | null>(null);
  const editBoxRef = useRef<HTMLDivElement | null>(null);

  const [currentRef, setCurrentRef] = useState(boxRef);

  const onPopupClose = () => {
    setIsPopupOpen(false);

    setCurrentRef(boxRef);
  };

  const deleteActivity = async (event: BaseSyntheticEvent) => {
    event.stopPropagation();

    if (selectedId) {
      const response =
        title === 'Job Type'
          ? await dispatch(deleteJobType(selectedId)).unwrap()
          : await dispatch(deleteTag(selectedId)).unwrap();

      const filteredTypes = selectedJobTypes?.filter((type) => type.id !== response.id);

      setSelectedJobType?.(filteredTypes || []);
      onPopupClose();
    }
  };

  useOnClickOutside(boxRef, onClose);

  useOnClickOutside(editBoxRef, () => setEditTag(defaultJobTypeValues));

  useLayoutEffect(() => {
    setAddTag?.(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Box ref={currentRef} className={className}>
        {addTag && (
          <CreateNewActivity
            className={className}
            setAddMore={setAddMore}
            setAddTag={setAddTag}
            title={`Create ${title}`}
          />
        )}
        {editTag.id && (
          <div ref={editBoxRef}>
            <CreateNewActivity
              className={className}
              title={`Update ${title}`}
              currentTypeId={editTag.id}
              currentTypeTitle={editTag?.title}
              setAddTag={setAddTag}
              setAddMore={setAddMore}
              currentTypeColor={editTag?.color as string | ColorResult}
            />
          </div>
        )}
      </Box>
      <DeletePopup
        title='this Job Type'
        isOpen={isPopupOpen}
        onClose={onPopupClose}
        onDelete={deleteActivity}
      />
    </>
  );
};

export default ActivityDropDown;
