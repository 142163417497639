import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { rolesApi } from 'api';

export const getAllRoles = createAsyncThunk(
  'rolesSlice/getAllRoles',
  async (type: string | undefined) => {
    try {
      const response = await rolesApi.getAllRolesRequest(type as string);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      throw Error;
    }
  },
);
