import React from 'react';
import { Box } from '@mui/material';

import { LogoFooter } from 'assets/icons';
import { CustomTypography } from 'components';

import styles from './LandingFooter.module.scss';

import type { TLandingFooterProps } from './types';

const LandingFooter: React.FC<TLandingFooterProps> = () => (
  <Box className={styles.container}>
    <Box className={styles.container_content}>
      <Box className={styles.container_content_foot}>
        <Box className={styles.container_content_foot_first}>
          <button
            className={styles.container_content_foot_first_logo}
            tabIndex={0}
            aria-label='landing-footer-logo'
          >
            <LogoFooter />
          </button>
          <Box className={styles.container_content_foot_round}>
            <CustomTypography>
              Copyright © 2024 | CrewSync LLC CrewSync.AI | All rights reserved
            </CustomTypography>
          </Box>
        </Box>
      </Box>
    </Box>
  </Box>
);

export default LandingFooter;
