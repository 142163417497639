import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

import { customerApi } from 'api';
import {
  CustomerUpdateToastMessages,
  CustomerRemovedToastMessages,
  CustomerCreationToastMessages,
} from 'constants/ToastMessages';
import { RootState } from 'types';
import dataStructure from 'constants/options';

import {
  TCustomersParams,
  TCreateContactData,
  TUpdateCustomerData,
  TUpdateCustomerContact,
  TUpdateBillingInfoData,
  TCreateCustomerWholeData,
  TCreateNewBillingInfoData,
} from './types';

export const getCustomersList = createAsyncThunk(
  'customerSlice/getCustomersList',
  async (options: TCustomersParams | undefined, { getState }) => {
    const {
      customers: { customersLimit, customersOffset },
    } = getState() as RootState;

    const { limit = customersLimit, offset = customersOffset, sort_by, asc } = options ?? {};

    try {
      const response = await customerApi.getAllCustomersRequest({ limit, offset, sort_by, asc });

      if (Array.isArray(response?.data) && !response?.data?.length) {
        return dataStructure;
      }

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const createCustomer = createAsyncThunk(
  'customerSlice/createCustomer',
  async (body: TCreateCustomerWholeData, { dispatch, getState }) => {
    const start = toast.loading(CustomerCreationToastMessages.CUSTOMER_CREATION_START);

    const {
      customers: { customersLimit, customersOffset },
    } = getState() as RootState;

    try {
      const response = await customerApi.createAllCustomersRequest(body);

      toast.update(start, {
        render: CustomerCreationToastMessages.CUSTOMER_CREATION_SUCCESS,
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });

      dispatch(getCustomersList({ offset: customersOffset, limit: customersLimit }));

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      toast.update(start, {
        render: CustomerCreationToastMessages.CUSTOMER_CREATION_FAILURE,
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });

      throw Error;
    }
  },
);

export const updateCustomer = createAsyncThunk(
  'customerSlice/updateCustomer',
  async (body: TUpdateCustomerData) => {
    const { withToasts, ...rest } = body;

    let start;

    if (withToasts) {
      start = toast.loading(CustomerUpdateToastMessages.UPDATE_START);
    }

    try {
      const response = await customerApi.updateCustomerRequest(rest);

      if (withToasts && start) {
        toast.update(start, {
          render: CustomerUpdateToastMessages.UPDATE_SUCCESS,
          type: 'success',
          isLoading: false,
          autoClose: 3000,
        });
      }

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      if (withToasts && start) {
        toast.update(start, {
          render: CustomerUpdateToastMessages.UPDATE_FAILURE,
          type: 'error',
          isLoading: false,
          autoClose: 3000,
        });
      }

      throw Error;
    }
  },
);

export const deleteCustomer = createAsyncThunk(
  'customerSlice/deleteCustomer',
  async (id: number, { dispatch, getState }) => {
    const start = toast.loading(CustomerRemovedToastMessages.REMOVAL_START);

    const {
      customers: { customersLimit, customersOffset },
    } = getState() as RootState;

    try {
      const response = await customerApi.deleteCustomerByIdRequest(id);

      toast.update(start, {
        render: CustomerRemovedToastMessages.REMOVAL_SUCCESS,
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });

      dispatch(getCustomersList({ limit: customersLimit, offset: customersOffset }));

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      toast.update(start, {
        render: CustomerRemovedToastMessages.REMOVAL_FAILURE,
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });

      throw Error;
    }
  },
);

export const getCustomerById = createAsyncThunk(
  'customerSlice/getCustomerById',
  async (id: number) => {
    try {
      const response = await customerApi.getCustomerByIdRequest(id);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const getCustomerContactsById = createAsyncThunk(
  'customerSlice/getCustomerById',
  async (id: number) => {
    try {
      const response = await customerApi.getCustomerContactByIdRequest(id);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const updateCustomerContact = createAsyncThunk(
  'customerSlice/updateCustomerContact',
  async (options: TUpdateCustomerContact) => {
    try {
      const response = await customerApi.updateCustomerContactRequest(options);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const createCustomerContact = createAsyncThunk(
  'customerSlice/creayeCustomerContact',
  async (options: TCreateContactData) => {
    try {
      const response = await customerApi.createCustomerContactRequest(options);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const getCustomerContactById = createAsyncThunk(
  'customerSlice/getCustomerContactById',
  async (id: number) => {
    try {
      const response = await customerApi.getContactsByCustomerIdRequest(id);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const deleteCustomerContactById = createAsyncThunk(
  'customerSlice/deleteCustomerContactById',
  async (id: number) => {
    try {
      const response = await customerApi.deleteCustomerContactByIdRequest(id);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const updateBillingInfo = createAsyncThunk(
  'customerSlice/updateBillingInfo',
  async (options: TUpdateBillingInfoData) => {
    try {
      const response = await customerApi.updateBillingInfoRequest(options);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const createCustomerBillingInfo = createAsyncThunk(
  'customerSlice/createCustomerBillingInfo',
  async (options: TCreateNewBillingInfoData) => {
    try {
      const response = await customerApi.createBillingInfoRequest(options);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const removeCustomerBillingInfo = createAsyncThunk(
  'customerSlice/removeCustomerBillingInfo',
  async (id: number) => {
    try {
      const response = await customerApi.removeBillingInfoByIdRequest(id);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);
