export enum UserRole {
  AllowInviteUsers = 'Allow ability to Invite users',
  CatalogEditor = 'Catalog Editor',
  CatalogViewer = 'Catalog Viewer',
  CustomersEditor = 'Customers editor',
  CustomersViewer = 'Customers Viewer',
  DocumentsViewer = 'Documents Viewer',
  InventoryEditor = 'Inventory Editor',
  InventoryViewer = 'Inventory Viewer',
  NoticeBoardEditor = 'Notice Board Editor',
  TeamsEditor = 'Teams Editor',
  TeamsViewer = 'Teams Viewer',
  EstimatesEditor = 'Estimates Editor',
  EstimatesViewer = 'Estimates Viewer',
  LiveStreams = 'Livestream User',
  InvoiceViewer = 'Invoice Viewer',
  TransactionHistoryViewer = 'Transaction History Viewer',
  WorkOrderCreate = 'Create Work Order',
  WorkOrderEdit = 'Edit Work Order',
  WOandScheduling = 'Work Order and Scheduling',
}
