import { GridColDef } from '@mui/x-data-grid';

import { TableCell } from 'components';
import HeaderTitle from 'components/tables/EmployeeTable/HeaderTitle';
import { EditIconOutLined, ShareIcon, TrashIcon } from 'assets/icons';

import type { TCatalogDropDownOptionsParams } from './types';

export const catalogColumns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Catalog Name',
    flex: 1,

    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle style={{ padding: 0 }} title={headerName} />;
    },

    renderCell: (params) => {
      return <TableCell title={params?.value} />;
    },
  },
  {
    field: 'version',
    headerName: 'Version',
    flex: 1,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle style={{ padding: 0 }} title={headerName} />;
    },
    renderCell: (params) => {
      return <TableCell title={params?.value} />;
    },
  },
  {
    field: 'description',
    headerName: 'Description',
    flex: 1,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle style={{ padding: 0 }} title={headerName} />;
    },
    renderCell: (params) => {
      return <TableCell title={params?.value} />;
    },
  },
  {
    field: 'category',
    headerName: 'Category',
    flex: 1,

    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle style={{ padding: 0 }} title={headerName} />;
    },
    renderCell: (params) => {
      return <TableCell title={params?.value} />;
    },
  },

  {
    field: 'items',
    headerName: 'No Of Items',
    flex: 1,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle style={{ padding: 0 }} title={headerName} />;
    },
    renderCell: (params) => {
      return <TableCell title={params?.value} />;
    },
  },

  {
    field: 'options',
    headerName: '',
    width: 80,
    renderCell: (params) => {
      return params.value;
    },
  },
];

export const tooltipText =
  'Send this to your owner or manager to request items to add to inventory';

export const dropdownOptionsCreator = ({
  editAction,
  removeAction,
  shareAction,
}: TCatalogDropDownOptionsParams) => [
  {
    label: 'Edit',
    icon: <EditIconOutLined />,
    paramsAction: () => editAction(),
  },
  {
    label: 'Delete',
    icon: <TrashIcon />,
    paramsAction: removeAction,
  },
  {
    label: 'Share',
    icon: <ShareIcon />,
    isNotClosable: true,
    paramsAction: shareAction,
  },
];
