import { FC } from 'react';
import { Box } from '@mui/material';
import classNames from 'classnames';

import Button from '../Button';

import styles from './DurationTabs.module.scss';

import type { TPlanDurationPrices } from 'types/global/durationTypes';
import type { TDurationTabsProps } from './types';

const DurationTabs: FC<TDurationTabsProps> = ({
  durations,
  selectedPlan,
  activeDuration,
  activeCardForSend,
  setActiveCardForSend,
  changeActiveDuration,
}) => {
  const durationButtons = durations?.map((duration, idx) => {
    const buttonClasses = classNames(styles.container__button, {
      [styles.container__button_active]: activeDuration?.duration === duration.duration,
    });

    const handleChangeActiveValue = (
      event: React.SyntheticEvent<HTMLButtonElement>,
      duration: TPlanDurationPrices,
    ) => {
      event.stopPropagation();
      changeActiveDuration?.(duration as TPlanDurationPrices);

      const renderedPrice =
        duration.duration === 'Monthly' ? selectedPlan?.monthlyPrice : selectedPlan?.yearlyPrice;

      setActiveCardForSend?.({
        ...activeCardForSend,
        duration: { ...duration, price: renderedPrice } as TPlanDurationPrices | string,
      });
    };

    return (
      <Button
        textClassName={styles.container__button__text}
        className={buttonClasses}
        key={idx}
        onClick={(event: React.SyntheticEvent<HTMLButtonElement>) =>
          handleChangeActiveValue(event, duration)
        }
      >
        {duration.duration}
      </Button>
    );
  });

  return (
    <Box className={styles.container}>
      <Box className={styles.container__border}>{durationButtons}</Box>
    </Box>
  );
};

export default DurationTabs;
