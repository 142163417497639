import { client } from 'api/client';
import { endpoints } from 'api/endpoints';
import {
  TCreatePaymentIntent,
  TCreateSubscription,
  TSetDefaultPaymentMethod,
  TUpdateBillingDetails,
  TUpdateSubscriptionParams,
  TUserSubscriptionTypes,
} from 'store/slices/subscriptionSlice/types';

export const createSubscriptionRequest = ({
  org_id,
  subscription,
  free = false,
}: TCreateSubscription) => {
  const sendedValue = free
    ? { org_id, subscription, trial_period_days: 30 }
    : { org_id, subscription };

  return client.post(endpoints.SubscriptionsService.createSubscription(), null, {
    params: sendedValue,
  });
};

export const createPaymentIntentRequest = (options: TCreatePaymentIntent) =>
  client.post(endpoints.SubscriptionsService.createPaymentIntent(), null, {
    params: { ...options },
  });

export const setupPaymentIntentRequest = (org_id: number) =>
  client.post(endpoints.SubscriptionsService.setupPaymentIntent(), null, {
    params: { org_id },
  });

export const getUserSubscriptionsRequest = (options: TUserSubscriptionTypes) =>
  client.get(endpoints.SubscriptionsService.getUserSubscriptions(), { params: { ...options } });

export const CreateStripeAccountRequest = (body: FormData) => {
  const options = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };
  return client.post(endpoints.SubscriptionsService.CreateStripeAccount(), body, options);
};

export const getPaymentMethodsRequest = (org_id: number) =>
  client.get(endpoints.SubscriptionsService.getPaymentMethods(), { params: { org_id } });

export const getDefaultPaymentMethodsRequest = (org_id: number) =>
  client.get(endpoints.SubscriptionsService.getDefaultPaymentMethod(), { params: { org_id } });

export const setDefaultPaymentMethodRequest = (options: TSetDefaultPaymentMethod) =>
  client.put(endpoints.SubscriptionsService.setDefaultPaymentMethod(), null, {
    params: { ...options },
  });

export const updateBillingDetailsRequest = (options: TUpdateBillingDetails) =>
  client.put(endpoints.SubscriptionsService.updateBillingDetails(), options);

export const getInvoiceHistoryRequest = (org_id: number) =>
  client.get(endpoints.SubscriptionsService.getInvoiceHistory(), { params: { org_id } });

export const updateSubscriptionRequest = (params: TUpdateSubscriptionParams) =>
  client.put(endpoints.SubscriptionsService.updateSubscription(), null, { params: { ...params } });
