import {
  Controls,
  FullscreenButton,
  MuteButton,
  PIPButton,
  Time,
  useMediaState,
} from '@vidstack/react';
import { PlayButton } from '@vidstack/react';
import {
  FullscreenExitIcon,
  FullscreenIcon,
  MuteIcon,
  PauseIcon,
  PictureInPictureExitIcon,
  PictureInPictureIcon,
  PlayIcon,
  VolumeHighIcon,
  VolumeLowIcon,
} from '@vidstack/react/icons';

import './PlayerControls.scss';
import TimeSliderCustom from '../TimeSlider';

const PlayerControls = () => {
  const isPaused = useMediaState('paused');

  const volume = useMediaState('volume'),
    isMuted = useMediaState('muted');

  const isActive = useMediaState('pictureInPicture');

  const isActiveFullScreen = useMediaState('fullscreen');

  return (
    <Controls.Root className='vds-controls'>
      <Controls.Group className={'vds-controls-group'}></Controls.Group>
      <div className={'vds-controls-spacer'} />
      <div className={'vds-controls-spacer'} />
      <div className={'vds-controls-spacer'} />
      <div className={'vds-controls-spacer'} />
      <div className={'vds-controls-spacer'} />
      <div className={'vds-controls-spacer'} />
      <div className={'vds-controls-spacer'} />
      <Controls.Group className='vds-controls-group'>
        <TimeSliderCustom />
      </Controls.Group>
      <div className='vds-controls-spacer' />
      <Controls.Group className='vds-controls-group-last'>
        <div className='vds-controls-group-last-left'>
          <PlayButton className='vds-button'>
            {isPaused ? (
              <PlayIcon className='play-icon vds-icon' />
            ) : (
              <PauseIcon className='pause-icon vds-icon' />
            )}
          </PlayButton>
          <MuteButton className='vds-button'>
            {isMuted || volume == 0 ? (
              <MuteIcon className='mute-icon vds-icon' />
            ) : volume < 0.5 ? (
              <VolumeLowIcon className='volume-low-icon vds-icon' />
            ) : (
              <VolumeHighIcon className='volume-high-icon vds-icon' />
            )}
          </MuteButton>
          <div className='vds-time-group'>
            <Time className='vds-time' type='current' />
            <div className='vds-time-divider'>/</div>
            <Time className='vds-time' type='duration' />
          </div>
        </div>

        <div className='vds-controls-group-last-right'>
          <PIPButton className='vds-button'>
            {!isActive ? (
              <PictureInPictureIcon className='pip-enter-icon vds-icon' />
            ) : (
              <PictureInPictureExitIcon className='pip-exit-icon vds-icon' />
            )}
          </PIPButton>
          <FullscreenButton className='vds-button'>
            {!isActiveFullScreen ? (
              <FullscreenIcon className='fs-enter-icon vds-icon' />
            ) : (
              <FullscreenExitIcon className='fs-exit-icon vds-icon' />
            )}
          </FullscreenButton>
        </div>
      </Controls.Group>
    </Controls.Root>
  );
};

export default PlayerControls;
