import { FC, useEffect } from 'react';
import { animated, useSpring } from 'react-spring';
import { Modal } from '@mui/material';

import { useAppDispatch } from 'hooks';
import { setModalState } from 'store/slices/optionsSlice';

import styles from './ModalLayout.module.scss';
import { TModalLayoutProps } from './types';

const ModalLayout: FC<TModalLayoutProps> = ({
  isOpen,
  height,
  onClose,
  children,
  borderRadius,
  width = 400,
  isClosed = false,
  lowPriority = true,
}) => {
  const dispatch = useAppDispatch();

  const springProps = useSpring({
    delay: 200,
    opacity: isOpen ? 1 : 0,
    top: isOpen ? '50%' : '-5%',
    width: '100%',
    maxWidth: width,
    height,
    borderRadius,
  });

  const onCloseProps = useSpring({
    delay: 200,
    opacity: !isClosed ? 1 : 0,
    top: !isClosed ? '50%' : '-5%',
    width: '100%',
    maxWidth: width,
    height,
    borderRadius,
  });

  useEffect(() => {
    dispatch(setModalState(isOpen));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <Modal
      closeAfterTransition
      open={isOpen}
      onClose={onClose}
      sx={{
        zIndex: lowPriority ? `1119 !important` : undefined,
      }}
    >
      <animated.div className={styles.container} style={isClosed ? onCloseProps : springProps}>
        {children}
      </animated.div>
    </Modal>
  );
};

export default ModalLayout;
