import { EditIconOutLined, TrashIcon } from 'assets/icons';

import type { TDropDownOptionsParams } from './types';

export const dropDownOptionsCreator = ({
  removeCallback,
  editCallback,
}: TDropDownOptionsParams) => [
  {
    id: 1,
    name: 'Edit',
    icon: <EditIconOutLined />,
    action: editCallback,
  },
  {
    id: 2,
    name: 'Archive',
    icon: <TrashIcon />,
    action: removeCallback,
  },
];
