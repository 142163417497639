import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Box } from '@mui/material';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';

import { Routes } from 'types';
import { ArrowFilled } from 'assets/icons';

import CustomTypography from '../Typography';

import NavBarStepper from './NavBarStepper';
import styles from './Navbar.module.scss';

import type { TNavbar } from './types';

const NavbarOption: React.FC<TNavbar> = ({ option, selected, linkTo, onClose, isRouteActive }) => {
  const { pathname } = useLocation();

  const navigate = useNavigate();

  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  const [expandable, setExpandable] = useState<boolean>(false);

  const isInCatalogPage = option.content === 'Product Info' && pathname === Routes.Catalog;

  const isInWorkFlowPage =
    option.content === 'Work flow' &&
    (pathname === Routes.BomEstimate ||
      pathname === Routes.Agreement ||
      pathname === Routes.WorkOrder ||
      pathname === Routes.BomPricingEstimate ||
      pathname === Routes.Invoice);

  useEffect(() => {
    if (!isInWorkFlowPage) {
      setIsDropdownOpen(false);
    }
  }, [isInWorkFlowPage]);

  useEffect(() => {
    if (!selected && expandable) {
      setExpandable(false);
    }
  }, [expandable, selected]);

  const boxClasses = classNames(styles.container__box, {
    [styles.container__box_selected]: selected,
    [styles.container__box_disable]: !isRouteActive,
  });

  const handleNavigate = () => {
    setIsDropdownOpen(!isDropdownOpen);
    navigate(Routes.BomEstimate);
  };

  const handleItemClick = () => {
    option.content === 'Work flow' ? handleNavigate() : onClose?.();
  };

  const itemClasses = classNames(styles.container__box_item, {
    [styles.container__box_item_active]: isDropdownOpen,
  });

  return (
    <>
      {isRouteActive ? (
        <>
          <NavLink
            style={{ width: '100%' }}
            to={linkTo}
            onClick={handleItemClick}
            className={({ isActive }) =>
              classNames(styles.link, {
                [styles.link_active]:
                  (!option?.isInDropDownMode && isActive) || isInCatalogPage || isInWorkFlowPage,
                [styles.link_active_reports]: isActive && linkTo === Routes.Reports,
              })
            }
          >
            <div
              className={`${styles.container__box} ${
                selected ? styles.container__box_selected : ''
              }`}
            >
              <div
                className={`${styles.container__box_icon} ${
                  selected ? styles.container__box_icon_active : ''
                }`}
              >
                {option.Icon}
              </div>
              <div className={itemClasses}>
                <CustomTypography fontSize={14} className={styles.container__box_item_title}>
                  {option.content}
                </CustomTypography>
                {option.isInDropDownMode && (
                  <ArrowFilled className={styles.container__box_item_icon} />
                )}
              </div>
            </div>
          </NavLink>
          <NavBarStepper
            onClose={onClose}
            isDropdownOpen={isDropdownOpen}
            isInDropDownMode={option?.isInDropDownMode}
            setIsOpen={setIsDropdownOpen}
          />
        </>
      ) : (
        <Box style={{ width: '100%', opacity: '0.3', cursor: 'not-allowed' }}>
          <div className={boxClasses}>
            <div
              className={`${styles.container__box_icon} ${
                selected ? styles.container__box_icon_active : ''
              }`}
            >
              {option.Icon}
            </div>
            <div className={styles.container__box_item}>
              <CustomTypography fontSize={14} className={styles.container__box_item_title}>
                {option.content}
              </CustomTypography>
            </div>
          </div>
        </Box>
      )}
    </>
  );
};

export default NavbarOption;
