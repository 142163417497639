import { client } from 'api/client';
import { endpoints } from 'api/endpoints';
import {
  TParams,
  TUpdateNotice,
  TCreateNotice,
  TUpdateActivity,
  TLikePostComment,
  TUpdatePostComment,
  TPostCommentCreate,
  TCreateActivityFlag,
  TUsersActivitiesRequest,
} from 'store/slices/activitiesSlices/types';

export const createActivitiesRequest = (body: FormData) => {
  const options = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return client.post(endpoints.ActivityService.createActivities(), body, options);
};

export const getAllActivitiesRequest = (params: TParams) =>
  client.get(endpoints.ActivityService.getActivities(), {
    params,
  });

export const getFilterActivitiesRequest = (org_id: number) =>
  client.get(endpoints.ActivityService.getFilterActivities(org_id));

export const likeActivityPostRequest = (id: number) =>
  client.post(endpoints.ActivityService.postActivityLike(id));

export const removeActivityLikeRequest = (id: number) =>
  client.delete(endpoints.ActivityService.removeActivityLike(id));

export const likeActivityPostCommentRequest = (options: TLikePostComment) =>
  client.post(endpoints.ActivityService.likePostComment(options?.comment_id), null, {
    params: { ...options },
  });

export const removeActivityCommentLikeRequest = (id: number) =>
  client.delete(endpoints.ActivityService.removePostCommentLike(id));

export const removeActivityCommentRequest = (comment_id: number) =>
  client.delete(endpoints.ActivityService.removePostComment(comment_id));

export const getAllPostCommentsRequest = (id: number, limit: number, offset: number) =>
  client.get(endpoints.ActivityService.getActivityComment(id), {
    params: { limit, offset },
  });

export const createPostCommentRequest = (data: TPostCommentCreate) => {
  const { media, ...restData } = data;

  const options = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    params: {
      ...restData,
    },
  };

  return client.post(
    endpoints.ActivityService.createActivityComment(data?.activity_type_id),
    media,
    options,
  );
};

export const updateActivity = (options: TUpdateActivity) =>
  client.put(endpoints.ActivityService.updateActivityById(options.id), options);

export const deleteActivity = (activity_feed_id: number) =>
  client.delete(endpoints.ActivityService.deleteActivityById(activity_feed_id));

export const getAllNotices = (params: TParams) =>
  client.get(endpoints.ActivityService.getNotices(), {
    params,
  });

export const updateNotice = (options: TUpdateNotice) =>
  client.put(endpoints.ActivityService.updateNotice(), options);

export const createNotice = (options: TCreateNotice) =>
  client.post(endpoints.ActivityService.createNotice(), options);

export const getNoticeById = (notice_id: number) =>
  client.get(endpoints.ActivityService.getNoticeById(notice_id));

export const removeNoticeById = (notice_id: number) =>
  client.delete(endpoints.ActivityService.deleteNoticeById(notice_id));

export const getPostCommentRepliesRequest = (comment_id: number) =>
  client.get(endpoints.ActivityService.getActivityReplies(comment_id));

export const getPostCommentByIdRequest = (comment_id: number) =>
  client.get(endpoints.ActivityService.getPostCommentById(comment_id));

export const updatePostCommentRequest = (options: TUpdatePostComment) =>
  client.put(endpoints.ActivityService.updatePostComment(options.id), null, {
    params: { ...options },
  });

export const createActivityFlagRequest = (options: TCreateActivityFlag) =>
  client.post(endpoints.ActivityService.createActivityFlag(), { ...options });

export const hideActivityFeedRequest = (id: number) =>
  client.post(endpoints.ActivityService.hidePost(id));

export const getAllHiddenActivityFeedRequest = () =>
  client.get(endpoints.ActivityService.getAllHiddenActivityFeeds(), {
    params: { limit: 100, offset: 0 },
  });

export const unhideActivityFeedRequest = (id: number) =>
  client.delete(endpoints.ActivityService.removeHiddenActivity(id));

export const getHiddenActivityFeedByIdRequest = (id: number) =>
  client.get(endpoints.ActivityService.getHiddenActivityById(id));

export const getActivitiesByUserIdRequest = (options: TUsersActivitiesRequest) =>
  client.get(endpoints.ActivityService.getActivityByUserId(options.user_id), {
    params: { limit: options?.limit, offset: options.offset },
  });

export const getActivityByActivityFeedByIdRequest = (activity_feed_id: number) =>
  client.get(endpoints.ActivityService.getActivityById(activity_feed_id));

export const getActivityFeedPostLikesRequest = (activity_feed_id: number) =>
  client.get(endpoints.ActivityService.postActivityLike(activity_feed_id));

export const genAiQuery = (query: string) =>
  client.get(endpoints.ActivityService.genAi(), { params: { query } });
