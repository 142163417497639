import { Box, Checkbox } from '@mui/material';
import { useState, type FC } from 'react';

import { useAppSelector } from 'hooks';
import { Button, CustomTextField, CustomTypography } from 'components';
import { ArrowDown, CheckedIcon, SearchFilterIcon, UncheckedIcon } from 'assets/icons';
import { organizationMembersSelector } from 'store/slices/organizationsSlice/selectors';
import { Colors } from 'types';

import styles from './ShareDropDown.module.scss';

import type { TShareDropDownProps } from './types';

const ShareDropDown: FC<TShareDropDownProps> = ({ onSubmit }) => {
  const organizationUsers = useAppSelector(organizationMembersSelector);

  const [selectedUsersList, setSelectedUsersList] = useState<number[]>([]);

  const handleCheckboxChange = (userId: number) => {
    setSelectedUsersList((prevSelectedUsersList) => {
      if (prevSelectedUsersList.includes(userId)) {
        return prevSelectedUsersList.filter((id) => id !== userId);
      } else {
        return [...prevSelectedUsersList, userId];
      }
    });
  };

  const activeSelectedUsers = selectedUsersList?.filter((item) => !!item);

  const renderSelectList = organizationUsers?.map(
    (item, idx) =>
      item?.user_id && (
        <Box key={idx} className={styles.container__content__list__user}>
          <Checkbox
            icon={<UncheckedIcon />}
            checkedIcon={<CheckedIcon />}
            checked={selectedUsersList.includes(item?.user_id)}
            onChange={() => handleCheckboxChange(item?.user_id as number)}
          />
          <CustomTypography className={styles.container__content__list__user__name}>
            {item?.name}
          </CustomTypography>
        </Box>
      ),
  );

  return (
    <Box className={styles.container}>
      <Box className={styles.container__header}>
        <CustomTypography className={styles.container__header__title}>
          Select Employee
        </CustomTypography>
        <ArrowDown className={styles.container__header__icon} />
      </Box>
      <Box className={styles.container__content}>
        <CustomTextField
          backGroundColor={Colors.WHITE}
          borderColor={Colors.GRAY}
          padding='10px 8px '
          borderRadius='5px'
          placeholder='“Search"'
          rightIcon={<SearchFilterIcon />}
          className={styles.container__content__search}
        />
        <Box className={styles.container__content__list}>{renderSelectList}</Box>
        <Box className={styles.container__content__buttons}>
          <Button
            maxWidth='80px'
            color='white'
            type='submit'
            padding='11px 16px'
            backgroundColor={Colors.SAPPHIRE}
            onClick={() => onSubmit?.(activeSelectedUsers)}
          >
            Send
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ShareDropDown;
