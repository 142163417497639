import { TrashIcon } from 'assets/icons';

import type { TDropDownOptionsParams } from './types';

export const dropDownOptionsCreator = ({ removeCallback }: TDropDownOptionsParams) => [
  {
    id: 1,
    name: 'Delete',
    icon: <TrashIcon />,
    action: removeCallback,
  },
];
