import React, { useEffect, useState, FC, useRef } from 'react';
import classNames from 'classnames';

import {
  CommentForm,
  ExpandableTypo,
  OptionsDropDown,
  CustomizedAvatar,
  CustomTypography,
} from 'components';
import { timeDiffer } from 'utils';
import { TChangeInputEvent } from 'types/global/events';
import { userDataSelector } from 'store/slices/authSlice/selectors';
import { useAppDispatch, useAppSelector, usePostCommentReplies } from 'hooks';
import { postCommentCreate, updatePostComment } from 'store/slices/activitiesSlices/thunks';

import CommentsFooter from './CommentsFooter/CommentsFooter';
import styles from './Comment.module.scss';

import type { TComment } from './types';
import type { VoidCallback } from 'types';
import type { TEmoji } from '../EmojiPicker/types';

const Comment: FC<TComment> = ({
  id,
  comment,
  isShowLoads,
  getComments,
  parentPostId,
  isShowReplies,
  refetchReplies,
  setIsShowReplies,
  parentCoordinates,
  handleRemoveComment,
}) => {
  const dispatch = useAppDispatch();

  const userInfo = useAppSelector(userDataSelector);
  const elemRef = useRef<HTMLDivElement | null>(null);

  const { postCommentReplies, getPostCommentReplies, isLoad } = usePostCommentReplies(comment?.id);

  useEffect(() => {
    getPostCommentReplies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetchReplies]);

  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [commentInput, setCommentInput] = useState<string>('');
  const [isStartReply, setIsStartReply] = useState<boolean>(false);
  const [isShowChildReplies, setIsShowChildReplies] = useState<boolean>(true);
  const [commentContent, setCommentContent] = useState<string>(comment?.content);
  const [repliesCount, setRepliesCount] = useState<number>(postCommentReplies?.total_count);

  useEffect(() => {
    setRepliesCount(postCommentReplies?.total_count);
  }, [postCommentReplies?.total_count]);

  const addRepliesCount = () => setRepliesCount((prev) => prev + 1);

  useEffect(() => {
    setCommentContent(comment?.content);
  }, [comment?.content]);

  const handleCommentChange = (event: TChangeInputEvent) => setCommentContent(event.target.value);

  const handleInputChange = (e: TChangeInputEvent) => {
    setCommentInput(e.target.value);
  };

  const startEdit = () => {
    setIsEditable(true);

    if (!isEditable) {
      elemRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const addComment = async (event: React.SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (commentInput?.trim() !== '') {
      const response = await dispatch(
        postCommentCreate({
          content: commentInput,
          activity_type_id: comment?.id,
          activity_type: 'comment',
        }),
      );

      addRepliesCount();

      setCommentInput('');

      if (response.meta.requestStatus === 'fulfilled' && !isLoad) {
        setIsStartReply(false);
      }

      if (response?.payload?.activity_type === 'comment') {
        setIsShowReplies(true);
        getPostCommentReplies();
      }
    }
  };

  const handleCommentEdit = async (event: React.SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (commentContent?.trim() !== '') {
      const updatedComment = await dispatch(
        updatePostComment({ id: comment?.id, content: commentContent }),
      ).unwrap();

      setCommentContent(updatedComment?.content);

      setIsEditable(false);
    }
  };

  const replyComments = postCommentReplies?.data?.map((comment) => {
    return (
      <div key={comment?.id}>
        <Comment
          id={`${comment?.id}`}
          comment={comment}
          getComments={getComments}
          isShowLoads={isShowLoads}
          parentPostId={parentPostId}
          refetchReplies={refetchReplies}
          isShowReplies={isShowChildReplies}
          parentCoordinates={parentCoordinates}
          setIsShowReplies={setIsShowChildReplies}
          handleRemoveComment={handleRemoveComment}
        />
      </div>
    );
  });

  const replyClasses = classNames(styles.container, {
    [styles.container_reply]: comment?.activity_type === 'comment',
  });

  const commentClasses = classNames(styles.container_comment, {
    [styles.container_comment_reply]: !false,
  });

  const replyComment = () => {
    setIsStartReply(!isStartReply);
  };

  const toggleReplies = () => {
    setIsShowReplies(!isShowReplies);
    getPostCommentReplies();
  };

  const handleEmojiSelect = (emoji: TEmoji, replyCommentElem?: HTMLInputElement) => {
    if (replyCommentElem) {
      const cursor = replyCommentElem.selectionStart || 0;

      const text = commentInput.slice(0, cursor) + emoji.native + commentInput.slice(cursor);
      setCommentInput(text);

      const newCursor = cursor + emoji.native.length;
      setTimeout(() => replyCommentElem.setSelectionRange(newCursor, newCursor), 10);
      replyCommentElem.focus();
    } else {
      setCommentInput((prevComment) => prevComment + emoji.native);
    }
  };

  const handleEmojiSelectEdit = (emoji: TEmoji, commentEdit?: HTMLInputElement) => {
    if (commentEdit) {
      const cursor = commentEdit.selectionStart || 0;

      const text = commentContent.slice(0, cursor) + emoji.native + commentContent.slice(cursor);
      setCommentContent(text);

      const newCursor = cursor + emoji.native.length;
      setTimeout(() => commentEdit.setSelectionRange(newCursor, newCursor), 10);
      commentEdit.focus();
    } else {
      setCommentContent((prevComment) => prevComment + emoji.native);
    }
  };

  return (
    <div className={styles.wrapper} ref={elemRef}>
      <div className={replyClasses}>
        <div className={styles.container_avatar}>
          <CustomizedAvatar
            alt='alt'
            width={45}
            height={45}
            className={styles.container_avatar_icon}
            src={comment?.profile_image_url_id as string}
          />
        </div>
        <div className={styles.container_comment_parent}>
          <div className={commentClasses}>
            <div className={styles.container_comment_head}>
              <div className={styles.container_comment_head_title}>
                <CustomTypography className={styles.container_comment_head_title_name}>
                  {comment?.name}
                </CustomTypography>
                <CustomTypography className={styles.container_comment_head_title_role}>
                  {comment?.user_designation}
                </CustomTypography>
              </div>
              <div className={styles.container_comment_head_options}>
                <CustomTypography>{timeDiffer(comment?.created_at as string)}</CustomTypography>
                {userInfo?.id === comment?.user_id && (
                  <OptionsDropDown
                    isClosed={isEditable}
                    handleEdit={startEdit}
                    handleRemove={() => {
                      handleRemoveComment?.(comment);
                    }}
                  />
                )}
              </div>
            </div>
            {isEditable ? (
              <CommentForm
                forComments
                autoFocus
                topMargin={0}
                inEditMode
                id={id as string}
                addComment={handleCommentEdit}
                commentInput={commentContent}
                handleInputChange={handleCommentChange}
                handleEmojiSelect={handleEmojiSelectEdit}
              />
            ) : (
              <ExpandableTypo title={commentContent as string} />
            )}
          </div>
          <CommentsFooter
            comment={comment}
            replyCount={repliesCount}
            replyComment={replyComment}
            toggleReplies={toggleReplies}
            isRepliesOpened={isShowReplies}
            parentPostId={Number(parentPostId)}
            getComments={getComments as VoidCallback}
            getPostCommentReplies={getPostCommentReplies}
            haveReplies={!!postCommentReplies?.data?.length}
          />
        </div>
      </div>
      {isStartReply && (
        <div style={{ margin: '10px 0 10px 59px' }}>
          <CommentForm
            forComments
            topMargin={0}
            autoFocus
            id={id as string}
            addComment={addComment}
            commentInput={commentInput}
            handleInputChange={handleInputChange}
            handleEmojiSelect={handleEmojiSelect}
          />
        </div>
      )}
      {isShowReplies ? <div className={styles.wrapper__replies}>{replyComments}</div> : null}
    </div>
  );
};

export default Comment;
