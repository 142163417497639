import { useEffect, useState } from 'react';

const useFileInputChanges = (sendedValue: string | null) => {
  const [file, setFile] = useState<File | null>(null);
  const [isFileDirty, setIsFileDirty] = useState<boolean>(false);
  const [isFileRemoved, setIsFileRemoved] = useState<boolean>(false);
  const [fileSrc, setFileSrc] = useState<string | null>(sendedValue as string);

  const getSetIsRemovedState = (isRemoved: boolean) => {
    setIsFileRemoved(isRemoved);
  };

  const getCurrentImgSrc = (src: string | null) => {
    setFileSrc(src);
  };

  const getImgFile = (file: File | null) => setFile(file);

  useEffect(() => {
    setFileSrc(sendedValue as string);
  }, [sendedValue]);

  return {
    file,
    fileSrc,
    getImgFile,
    isFileDirty,
    isFileRemoved,
    setIsFileDirty,
    getCurrentImgSrc,
    getSetIsRemovedState,
  };
};

export default useFileInputChanges;
