import { FC } from 'react';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { Button, CustomBreadcrumbs } from 'components';
import { Colors } from 'types';

import { breadCrumbOptions } from './utils';
import { TBomEstimateHeaderProps } from './types';
import styles from './BomEstimateHeader.module.scss';

const BomEstimateHeader: FC<TBomEstimateHeaderProps> = ({ uuid }) => {
  const navigate = useNavigate();
  const openDrawer = () => {
    navigate(`/work-flow/update-estimate/form/${uuid}`);
  };

  const openCostMarginDrawer = () => {
    navigate(`/work-flow/update-estimate/form/cost-margin/${uuid}`);
  };

  return (
    <Box className={styles.container}>
      <CustomBreadcrumbs options={breadCrumbOptions} />
      <Box className={styles.container__content}>
        <Button
          color='white'
          backgroundColor={Colors.SAPPHIRE}
          disabled={false}
          className={styles.container__content__create__button}
        >
          PDF
        </Button>
        <Button
          color='white'
          backgroundColor={Colors.SAPPHIRE}
          disabled={false}
          className={styles.container__content__create__button}
        >
          XLS
        </Button>
        <Button
          color='white'
          backgroundColor={Colors.SAPPHIRE}
          disabled={false}
          className={styles.container__content__create__button}
        >
          Doc
        </Button>
        <Button
          color='white'
          backgroundColor={Colors.SAPPHIRE}
          disabled={false}
          className={styles.container__content__create__button}
        >
          Email
        </Button>
        <Button
          color='white'
          onClick={openDrawer}
          backgroundColor={Colors.SAPPHIRE}
          disabled={false}
          className={styles.container__content__create__button}
        >
          Edit
        </Button>
        <Button
          color='white'
          onClick={openCostMarginDrawer}
          backgroundColor={Colors.SAPPHIRE}
          disabled={false}
          className={styles.container__content__create__button}
        >
          Cost Margins
        </Button>
      </Box>
    </Box>
  );
};

export default BomEstimateHeader;
