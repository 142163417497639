import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { TFileMedia, TImageMarkers, TInitialState } from './types';

const initialState: TInitialState = {
  imgMarkers: [],
  selectedIds: [],
  repliesCount: 0,
  postMediasData: [],
  isModalOpened: false,
  isDrawerOpened: false,
  isMarkersOpened: false,
  selectedInventoryItem: [],
  isShowTemplateButton: false,
  isShowOrganizationLogoLoad: true,
};

const optionsSlice = createSlice({
  name: 'invitationSlice',
  reducers: {
    toggleModal: (state) => {
      state.isModalOpened = !state.isModalOpened;
    },
    getRepliesCount: (state, action) => {
      state.repliesCount = action.payload;
    },
    showTemplateButton: (state) => {
      state.isShowTemplateButton = true;
    },
    setOrganizationLoadValue: (state, action) => {
      state.isShowOrganizationLogoLoad = action.payload;
    },
    hideTemplateButton: (state) => {
      state.isShowTemplateButton = false;
    },
    setModalState: (state, action) => {
      state.isModalOpened = action.payload;
    },
    setDrawerState: (state, action) => {
      state.isDrawerOpened = action.payload;
    },
    setInPostMediaData: (state, action: PayloadAction<TFileMedia>) => {
      state.postMediasData.push(action.payload);
    },
    setInImageMarkers: (state, action: PayloadAction<TImageMarkers[]>) => {
      state.imgMarkers = action.payload;
    },
    clearImgMarkers: (state) => {
      state.imgMarkers = [];
    },
    removeFromPostMediaData: (state, action) => {
      state.postMediasData?.filter((post) => {
        return post?.id !== action.payload;
      });
    },
    clearAllPostMediaData: (state) => {
      state.postMediasData = [];
    },
    getMarkersOpenedState: (state, action: PayloadAction<boolean>) => {
      state.isMarkersOpened = action.payload;
    },
    updatePostMediaData: (state, action: PayloadAction<TFileMedia[]>) => {
      state.postMediasData = action.payload;
    },
    setInventoryParts: (state, action) => {
      state.selectedInventoryItem = action.payload;
    },

    removeFromItem: (state, action) => {
      const indexToRemove = action.payload;

      const removedItemId = state.selectedInventoryItem[indexToRemove]?.id;
      state.selectedIds = state.selectedIds.filter((item) => item !== removedItemId);

      state.selectedInventoryItem = state.selectedInventoryItem.filter(
        (item) => item?.id !== removedItemId,
      );
    },

    addInItem: (state, action) => {
      state.selectedInventoryItem = [...state.selectedInventoryItem, action.payload];
    },

    fillSelectedIds: (state, action) => {
      state.selectedIds = action.payload;
    },
  },
  initialState,
});

export const {
  addInItem,
  setModalState,
  removeFromItem,
  setDrawerState,
  clearImgMarkers,
  getRepliesCount,
  fillSelectedIds,
  setInImageMarkers,
  setInventoryParts,
  setInPostMediaData,
  showTemplateButton,
  hideTemplateButton,
  updatePostMediaData,
  getMarkersOpenedState,
  clearAllPostMediaData,
  removeFromPostMediaData,
  setOrganizationLoadValue,
  toggleModal,
} = optionsSlice.actions;

export default optionsSlice.reducer;
