import { FC, useState } from 'react';
import { Box } from '@mui/material';

import breakPoints from 'constants/BreakPoints';
import { useAppSelector, useWindowSize } from 'hooks';
import { userPermissionsSelector } from 'store/slices/authSlice/selectors';
import { currentCustomerSelector } from 'store/slices/customersSlices/selectors';
import {
  Button,
  DrawerLayout,
  ScrollLayout,
  CustomTypography,
  CreateCustomerDrawer,
} from 'components';
import { Colors } from 'types';

import styles from './CustomersViewDrawer.module.scss';
import CustomersViewInfo from './CustomersViewInfo';
import BillingInformationView from './BillingInformationView';
import ContactsInformationView from './ContactsInformationView';

import type { TCustomersViewDrawerProps } from './types';

const CustomersViewDrawer: FC<TCustomersViewDrawerProps> = ({ onClose, open }) => {
  const currentCustomer = useAppSelector(currentCustomerSelector);

  const [inEditMode, setInEditMode] = useState<boolean>(false);

  const { isAccessToCustomerEditor } = useAppSelector(userPermissionsSelector);

  const { width } = useWindowSize();

  const drawerWidth =
    Number(width) <= breakPoints.TABLET_L && Number(width) > breakPoints?.TABLET_M
      ? 600
      : Number(width) <= breakPoints?.TABLET_M
      ? 370
      : 895;

  const billingsInfoItems = currentCustomer?.billing?.map((billing) => (
    <BillingInformationView key={billing?.id} billing={billing} />
  ));

  const contactsInfoItems = currentCustomer?.contacts?.map((contact) => (
    <ContactsInformationView key={contact?.id} contact={contact} />
  ));

  const handleCloseEdit = () => {
    setInEditMode(false);
  };

  const handleOpenEdit = () => {
    setInEditMode(true);
    onClose();
  };

  return (
    <>
      <DrawerLayout
        open={open}
        padding='40px'
        inCenter={false}
        onClose={onClose}
        width={drawerWidth}
        titleClassName={styles.container__head__title}
        headerTitle='Customer Information'
      >
        <Box className={styles.container}>
          <Box className={styles.container__content}>
            <Box className={styles.container__content__first}>
              <Box className={styles.container__content__first__item}>
                <CustomersViewInfo
                  title='Customer Name'
                  subtitle={currentCustomer?.name as string}
                />
                <CustomersViewInfo
                  title='Company Link'
                  isClickable
                  subtitle={currentCustomer?.website_url as string}
                />
              </Box>
              <Box className={styles.container__content__first__item}>
                <CustomersViewInfo title='Email' subtitle={currentCustomer?.email as string} />
                <CustomersViewInfo
                  title='Phone Number'
                  subtitle={currentCustomer?.phone_number as string}
                />
              </Box>
              <Box className={styles.container__content__first__item}>
                <CustomersViewInfo title='Address' subtitle={currentCustomer?.address as string} />
                <CustomersViewInfo title='City' subtitle={currentCustomer?.city as string} />
              </Box>
              <Box className={styles.container__content__first__item}>
                <CustomersViewInfo title='State' subtitle={currentCustomer?.state as string} />
                <CustomersViewInfo title='Zip' subtitle={currentCustomer?.zip as string} />
              </Box>
              <Box className={styles.container__content__first__item}>
                <CustomersViewInfo
                  title='Customer Type'
                  subtitle={currentCustomer?.type as string}
                />
              </Box>
            </Box>
            <Box className={styles.container__content__second}>
              <CustomTypography className={styles.container__content__second__title}>
                Customers Note:
              </CustomTypography>
              <CustomTypography className={styles.container__content__second__content}>
                {currentCustomer?.notes as string}
              </CustomTypography>
            </Box>
            <Box className={styles.container__content__first}>
              <CustomTypography className={styles.container__head__subtitle}>
                Billing Information
              </CustomTypography>
              <ScrollLayout direction='y' height={320} withScrollStyles>
                {currentCustomer?.billing?.length ? billingsInfoItems : 'No data for billings'}
              </ScrollLayout>
            </Box>
            {currentCustomer?.contacts?.length && (
              <Box className={styles.container__content__first}>
                <CustomTypography className={styles.container__head__subtitle}>
                  Point of Contact
                </CustomTypography>
                <ScrollLayout direction='y' height={320} withScrollStyles>
                  {currentCustomer?.contacts?.length ? contactsInfoItems : 'No data for contacts'}
                </ScrollLayout>
              </Box>
            )}
          </Box>
          <Box className={styles.container__footer}>
            <Button
              type='submit'
              color='white'
              maxWidth='65px'
              padding='12px 16px'
              onClick={handleOpenEdit}
              backgroundColor={Colors.SAPPHIRE}
              disabled={!isAccessToCustomerEditor}
            >
              Edit
            </Button>
          </Box>
        </Box>
      </DrawerLayout>
      <CreateCustomerDrawer inEditMode open={inEditMode} handleClose={handleCloseEdit} />
    </>
  );
};

export default CustomersViewDrawer;
