export function isNullOrWhiteSpace(str?: string): boolean {
  return str == null || str.length === 0 || str.trim() === '';
}

export function isNullOrEmpty(str?: string | null): boolean {
  return str == null || str === '';
}

export function areBothNullOrEqual(str1?: string | null, str2?: string | null): boolean {
  return str1 === str2 || (isNullOrEmpty(str1) && isNullOrEmpty(str2));
}

export function tryParseInt(str?: string | null): number | undefined {
  let result: number | undefined = undefined;
  if (str && !isNaN(parseInt(str))) {
    result = parseInt(str);
  }
  return result;
}

export function tryParseBoolean(str?: string | null): boolean | undefined {
  let result: boolean | undefined = undefined;
  if (str === 'true') {
    result = true;
  } else if (str === 'false') {
    result = false;
  }
  return result;
}
