import { useEffect, type FC, useState, BaseSyntheticEvent, useRef, ChangeEvent } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { Box, IconButton, Menu, MenuItem } from '@mui/material';
import { GridColDef, GridEventListener } from '@mui/x-data-grid';
import classNames from 'classnames';

import { setLimit, setPage } from 'store/slices/estimatesSlice';
import { useAppDispatch, useAppSelector, usePdfDownloadLink, useRefreshClearState } from 'hooks';
import {
  allEstimatesSelector,
  allEstimatesAscSelector,
  allEstimatesLimitSelector,
  allEstimatesOffsetSelector,
  allEstimatesLoadingSelector,
} from 'store/slices/estimatesSlice/selectors';
import { DownloadSquareIcon, EditIconOutLined, MoreIcon, TrashIcon } from 'assets/icons';
import { SortByEnums, TEstimate } from 'store/slices/estimatesSlice/types';
import { generateInventoryItemNumber } from 'utils/generateInventoryItemNumber';
import {
  getEstimates,
  deleteEstimate,
  sendEstimateToCustomer,
} from 'store/slices/estimatesSlice/thunks';
import { convertToMMDDYYYY } from 'utils/formatDate';
import DeletePopup from 'components/views/DeletePopup';
import ShareCatalogDropDown from 'components/dropdowns/ShareCatalogDropDown';
import {
  StatusInfo,
  ScrollLayout,
  CustomDataGrid,
  ShowAfterAccessView,
  DynamicPaginationControl,
} from 'components';
import { EmptyTitles } from 'constants/EmptyTitles';
import { StatusEnums } from 'components/shared/StatusInfo/types';
import {
  userAllRolesLoadingSelector,
  userAllRolesSelector,
  userPermissionsSelector,
} from 'store/slices/authSlice/selectors';
import { ImgUrlFolders, Routes } from 'types';
import { sendAgreementToCustomer } from 'store/thunks';

import { estimateColumns } from './utils';
import BomEstimateHeader from './BomEstimateHeader';
import { sxMenuShareStyles, sxMenuStyles } from './styles';
import styles from './BomEstimate.module.scss';

const BomEstimate: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const menuRef = useRef<HTMLDivElement | null>(null);

  const {
    isAccessToEstimatesEditor,
    isAccessToCatalogsEditor,
    isAccessToInventoryEditor,
    isAccessToEstimatesViewer,
    isAccessToCustomerEditor,
  } = useAppSelector(userPermissionsSelector);

  const haveAccess =
    isAccessToEstimatesEditor &&
    isAccessToCatalogsEditor &&
    isAccessToInventoryEditor &&
    isAccessToCustomerEditor;

  const estimateList = useAppSelector(allEstimatesSelector);
  const estimateListLoading = useAppSelector(allEstimatesLoadingSelector);
  const estimateLimit = useAppSelector(allEstimatesLimitSelector);

  const estimateOffset = useAppSelector(allEstimatesOffsetSelector);
  const estimateAsc = useAppSelector(allEstimatesAscSelector);
  const userRolesLoading = useAppSelector(userAllRolesLoadingSelector);

  const renderedEstimates = estimateList;

  const [anchorEl, setAnchorEl] = useState(null);
  const [isShareOpen, setIsShareOpen] = useState<boolean>(false);
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const [removedUsersIds, setRemovedUsersIds] = useState<number[]>([]);
  const [selectedItemId, setSelectedItemId] = useState<number | null>(null);
  const [newSortBy, setNewSortBy] = useState<SortByEnums | null>(null);

  const handleColumnHeaderClick = (column: GridColDef) => {
    const sortByValue = column.field === 'number' ? SortByEnums.ID : (column.field as SortByEnums);

    setNewSortBy(sortByValue);

    dispatch(
      getEstimates({
        sort_by: sortByValue,
        asc: estimateAsc,
        limit: estimateLimit,
        offset: estimateOffset,
      }),
    );
  };

  const userRoles = useAppSelector(userAllRolesSelector);

  const filteredRoles = userRoles.map((role) => role.role_id);

  const isViewer =
    !filteredRoles.includes(8) && !filteredRoles.includes(5) && !filteredRoles.includes(3);

  useRefreshClearState();

  useEffect(() => {
    dispatch(getEstimates({ limit: estimateLimit, offset: estimateOffset }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [estimateLimit, estimateOffset]);

  const goToRowPage = (id: string) => navigate(`/work-flow/bom-estimate/estimate/${id}`);

  const goToRowPageGenerate = (id: string) =>
    navigate(`/work-flow/bom-estimate/estimate/${id}/generate`);

  const handleEvent: GridEventListener<'rowClick'> = async (params) => {
    if (haveAccess) {
      goToRowPageGenerate(params?.row?.uuid);
    }
  };

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const handleMoreClick = (event: BaseSyntheticEvent, id: number | null) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);

    setSelectedItemId(id);
  };

  const handleClose = () => {
    if (!isShareOpen) {
      setAnchorEl(null);
    }

    setIsShareOpen(false);
  };

  const onDelete = async () => {
    await dispatch(deleteEstimate(selectedItemId as number));

    if (currentPage > 0) {
      setCurrentPage((prev) => prev - 1);
    }

    togglePopup();
  };

  const handlePageChange = (_event: unknown, newPage: number) => {
    setCurrentPage(newPage);
    dispatch(setPage(newPage + 1));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const handleRowsPerPageChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCurrentPage(0);
    handlePageChange(null, 0);
    dispatch(setLimit(event.target.value));
  };

  const renderColumns: GridColDef[] = estimateColumns.map((column) => {
    const isColumnSorted =
      column.field === 'number' ? newSortBy === SortByEnums.ID : newSortBy === column.field;

    const headerClasses = classNames(
      { [styles.activeSortHeader]: !estimateAsc },
      {
        [styles.activeSortHeader_asc]: estimateAsc,
      },
    );

    return {
      ...column,
      sortable: false,
      hideSortIcons: true,
      disableColumnMenu: true,
      disableColumnSelector: true,
      headerClassName: isColumnSorted ? headerClasses : '',
    };
  });

  const renderRows = renderedEstimates?.data?.map((el: TEstimate) => {
    const renderNumber = generateInventoryItemNumber(el.id);
    const renderDate = convertToMMDDYYYY(el.created_at);

    const renderStatus = el.status === 'estimate sent' ? StatusEnums.APPROVED : el.status;

    return {
      id: el.id,
      name: el.name,
      uuid: el.uuid,
      margin: '--',
      status: <StatusInfo status={renderStatus?.toLowerCase() as StatusEnums} title={el.status} />,
      created_at: renderDate || '--',
      customer_name: el.customer_name || '--',
      created_by_name: el.created_by_name || '--',
      number: `Est-${renderNumber}` || '--',
      options: haveAccess && (
        <IconButton
          sx={{ padding: '3px' }}
          onClick={(event) => handleMoreClick(event, el.id)}
          className={styles.container__icon}
        >
          <MoreIcon />
        </IconButton>
      ),
    };
  });

  const publishEstimate = () => {
    selectedItemId && dispatch(sendEstimateToCustomer(selectedItemId));
  };

  const publishAgreement = () => {
    selectedItemId && dispatch(sendAgreementToCustomer(selectedItemId));
  };

  const [selectedItem, setSelectedItem] = useState<TEstimate | null>(null);

  const { fetchDataAndDownload } = usePdfDownloadLink({
    fileUrl: selectedItem?.agreement_media_url_id as string,
    folder: ImgUrlFolders.DOCUMENT,
    withToastMessages: true,
  });

  const dropdownItems = [
    {
      icon: <EditIconOutLined />,
      label: 'Edit',
      paramsAction: (id: number) => {
        const selectedItem = estimateList?.data?.find((el) => el.id === id);
        goToRowPage(selectedItem?.uuid as string);
      },
    },
    {
      icon: <TrashIcon />,
      label: 'Delete',
      paramsAction: () => togglePopup(),
    },
    {
      label: 'Download Agreement',
      icon: <DownloadSquareIcon />,
      paramsAction: async () => {
        if (selectedItemId) {
          const selectedEstimate = estimateList?.data?.find((el) => el.id === selectedItemId);
          if (selectedEstimate) {
            setSelectedItem(selectedEstimate);
          }

          fetchDataAndDownload(selectedEstimate?.agreement_media_url_id as string);
        }
      },
    },
    {
      label: 'Send Agr To Customer',
      icon: <DownloadSquareIcon style={{ transform: 'rotate(90deg)' }} />,
      paramsAction: publishAgreement,
    },
    {
      label: 'Send Est To Customer',
      icon: <DownloadSquareIcon style={{ transform: 'rotate(90deg)' }} />,
      paramsAction: () => publishEstimate(),
    },
  ];

  const getDropdownItems = () => {
    const items = dropdownItems.filter((item) => {
      if (item.label !== 'Download Agreement') {
        return !isViewer;
      }

      return true;
    });

    return items;
  };

  const renderMenuItem = getDropdownItems().map((item, idx) => {
    const onClick = () => {
      selectedItemId && item?.paramsAction(selectedItemId);
      handleClose();
    };

    return (
      <MenuItem key={idx} onClick={onClick}>
        {item.icon}
        {item.label}
      </MenuItem>
    );
  });

  const totalRows = renderedEstimates.total_count;
  const renderMenuClasses = isShareOpen ? sxMenuShareStyles : sxMenuStyles;
  const [currentPage, setCurrentPage] = useState<number>(0);

  const haveAccessShow = isAccessToEstimatesEditor || isAccessToEstimatesViewer;

  if (!haveAccessShow && !userRolesLoading) {
    return <Navigate replace to={Routes.ActivityFeed} />;
  }

  return (
    <Box className={styles.container}>
      <BomEstimateHeader totalRows={totalRows} />
      <ShowAfterAccessView emptyMessageTitle='Estimates' isShow={haveAccessShow}>
        <Box className={styles.container__grid}>
          <ScrollLayout>
            <CustomDataGrid
              rowHeight={76}
              headerHeight={52}
              rows={renderRows}
              isAllSelection={false}
              columns={renderColumns}
              isLoading={estimateListLoading}
              emptyTitle={EmptyTitles.Estimates}
              onColumnHeaderClick={handleColumnHeaderClick}
              onRowClick={haveAccess ? handleEvent : undefined}
            />
          </ScrollLayout>
          {totalRows !== 0 && (
            <Box padding='20px 0'>
              <DynamicPaginationControl
                count={totalRows}
                page={currentPage}
                rowsPerPage={estimateLimit}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
              />
            </Box>
          )}
        </Box>
        <Menu
          ref={menuRef}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          sx={renderMenuClasses}
        >
          {isShareOpen ? (
            <ShareCatalogDropDown
              onClose={handleClose}
              setIsOpen={setIsShareOpen}
              removedUsersIds={removedUsersIds}
              sharedId={Number(selectedItemId)}
              setRemovedUsersIds={setRemovedUsersIds}
            />
          ) : (
            renderMenuItem
          )}
        </Menu>
      </ShowAfterAccessView>

      <DeletePopup
        withBody
        onDelete={onDelete}
        isOpen={isPopupOpen}
        onClose={togglePopup}
        title='Delete Confirmation'
        body='This will delete the estimate. Please confirm.'
      />
    </Box>
  );
};

export default BomEstimate;
