/* eslint-disable no-prototype-builtins */
import React, { FC, useState } from 'react';
import { Box } from '@mui/material';
import classNames from 'classnames';

import { CustomTypography, EmptyTitle } from 'components/shared';
import { useAppSelector } from 'hooks';
import { estimateItemLoadingSelector } from 'store/slices/estimatesSlice/selectors';
import { TGeneralInfo } from 'components/shared/EstimateForms/types';
import {
  TBillingInfoFromCustomerReturn,
  TCustomerInfoFromCustomerCreatorReturn,
} from 'containers/GenerateEstimate/types';
import { ArrowCircleDown } from 'assets/icons';

import styles from './EstimateInfoView.module.scss';
import { TEstimateInfoViewProps } from './types';

type OptionItem =
  | keyof TGeneralInfo
  | keyof TBillingInfoFromCustomerReturn
  | keyof TCustomerInfoFromCustomerCreatorReturn;

const EstimateInfoView: FC<TEstimateInfoViewProps> = ({ title, options }) => {
  const isLoading = useAppSelector(estimateItemLoadingSelector);

  const [collapse, setIsCollapse] = useState(false);

  const handleCollapse = () => {
    setIsCollapse(!collapse);
  };

  const optionTitles = options ? (Object.keys(options) as OptionItem[]) : [];

  const optionItems = optionTitles.map((el: OptionItem) => {
    const customizedItem = el.split('_').join(' ');

    return (
      <Box key={el} className={styles.container__content__item}>
        <CustomTypography className={styles.container__content__item__title}>
          {customizedItem}:
        </CustomTypography>
        <CustomTypography className={styles.container__content__item__subtitle}>
          {(options as Record<OptionItem, string>)[el]}
        </CustomTypography>
      </Box>
    );
  });

  const notHaveValue = optionTitles?.every((option) => !option?.length);

  const iconClasses = classNames(styles.container__title__icon, {
    [styles.container__title__icon_open]: collapse,
  });

  return (
    <Box className={styles.container}>
      <button onClick={handleCollapse} className={styles.container__title}>
        <ArrowCircleDown width={24} height={24} className={iconClasses} />
        {title}
      </button>
      {notHaveValue ? (
        <EmptyTitle loadingDone={isLoading} title={`No ${title} added yet`} />
      ) : (
        <React.Fragment>
          {collapse && <Box className={styles.container__content}>{optionItems}</Box>}
        </React.Fragment>
      )}
    </Box>
  );
};

export default EstimateInfoView;
