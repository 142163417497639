import { FC, useState } from 'react';
import { Box } from '@mui/material';

import { ContentOptions, NoticeBoard } from 'components';
import { useAppDispatch } from 'hooks';
import { changeNoticePage } from 'store/slices/activitiesSlices';

import { ActivityFeedContent } from '../types';
import PostsSection from '../PostsSection/PostsSection';
import styles from '../ActivityFeed.module.scss';

import type { TTabsSectionProps } from './types';

const TabsSection: FC<TTabsSectionProps> = ({
  changePage,
  currentList,
  loadingState,
  currentListLoad,
}) => {
  const dispatch = useAppDispatch();
  const [content, setContent] = useState<string>(ActivityFeedContent.POSTS);

  const getContent = (content: string) => {
    setContent(content);
    dispatch(changeNoticePage(0));
  };

  return (
    <>
      <Box className={styles.container__tabs}>
        <ContentOptions
          title='Activity Feed'
          getActiveTab={getContent}
          firstTab={ActivityFeedContent.POSTS}
          secondTab={ActivityFeedContent.NOTICES}
        />
      </Box>

      {content === ActivityFeedContent.POSTS ? (
        <PostsSection
          inTabView
          currentList={currentList}
          changePage={changePage}
          loadingState={loadingState}
          currentListLoad={currentListLoad}
        />
      ) : (
        <Box className={styles.container_notice}>
          <NoticeBoard />
        </Box>
      )}
    </>
  );
};

export default TabsSection;
