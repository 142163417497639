export enum Colors {
  GRAY = '#ccc',
  BLACK = '#000000',
  WHITE = '#ffffff',
  SNOW_WHITE = '#fff',
  STALE_GRAY = '#999',
  DANGER = '#ff0000',
  PRIMARY = '#faffff',
  TERTIARY = '#fbfcfe',
  PALE_BLUE = '#d2e1f9',
  SAPPHIRE = '#2067dd',
  MINT_GREEN = '#47c272',
  FROST_BLUE = '#f0f7ff',
  OBSIDIAN_AURA = '#555',
  PURE_WHITE = '#fafafa',
  PASTEL_SKY = '#d2e1f9',
  LIGHT_GRAY = '#cccccc',
  PALE_SILVER = '#e6e6e6',
  MIDNIGHT_BLACK = '#222',
  FROST_WHITE = '#fcfcfc',
  SOFT_SILVER = '#EDEFF1',
  DEEP_INDIGO = '#455168',
  CRIMSON_RED = '#d32f2f',
  LIGHT_SILVER = '#F8F8F8',
  COTTON_CLOUD = '#f7f7f7',
  STORMY_SLATE = '#757780',
  SILVER_SLATE = '#878787',
  GRAPHITE_GRAY = '#545454',
  GUNMETAL_GRAY = '#4d4d4d',
  PLATINUM_GRAY = '#f2f2f2',
  SHADOW_SLATE = '#22222280',
  CELESTIAL_TEAL = '#00777f',
  TABLE_CELL_GRAY = '#838383',
  SHADOWED_SLATE = '#222222b3',
  DEEP_SLATE_GRAY = '#707070',
  MIDNIGHT_CHARCOAL = '#222222',
  MIDNIGHT_BLACKISH = '#282828',
  ENIGMATIC_MIDNIGHT = '#667085',
  DEEP_CHARCOAL_BLUE = '#222222b2',
  LIGHT_GRAYISH_SILVER = '#EBEBEB',
}
