import { FC } from 'react';
import classNames from 'classnames';
import { Controller } from 'react-hook-form';
import { DatePicker } from '@mui/x-date-pickers';

import { CalendarIconBlue } from 'assets/icons';
import { ErrorMessage } from 'components/shared';

import styles from './ControlledFormPicker.module.scss';

import type { TControlledFormPickerProps } from './types';

const ControlledFormPicker: FC<TControlledFormPickerProps> = ({
  name,
  error,
  label,
  value,
  padding,
  control,
  disabled,
  className,
  borderColor,
  borderRadius,
  defaultValue,
  helperText,
  backgroundColor,
  likeInput = false,
  disablePast = true,
  calendarIcon = CalendarIconBlue,
}) => {
  const containerClasses = classNames(
    styles.container,
    className,
    { [styles.container__gray]: likeInput },
    {
      [styles.container__errors]: !!error?.[name]?.message || helperText,
    },
  );

  return (
    <Controller
      name={name}
      control={control}
      disabled={disabled}
      defaultValue={defaultValue}
      render={({ field }) => {
        return (
          <DatePicker
            disablePast={disablePast}
            sx={{
              padding,
              borderColor: borderColor,
              borderRadius: borderRadius,
              backgroundColor: backgroundColor,
            }}
            slotProps={{
              textField: {
                FormHelperTextProps: {
                  component: () => (
                    <ErrorMessage
                      style={{
                        left: -20,
                        top: '50%',
                        transform: 'translateY(-50%)',
                      }}
                      message={helperText as string}
                    />
                  ),
                },
                helperText: helperText,
              },
            }}
            slots={{
              openPickerIcon: calendarIcon,
            }}
            {...field}
            value={value ? value : field.value ? new Date(field.value) : null}
            label={label}
            className={containerClasses}
            onChange={(date) => {
              value = '';
              field.onChange(date);
            }}
          />
        );
      }}
    />
  );
};

export default ControlledFormPicker;
