import { Box } from '@mui/material';
import { FC } from 'react';

import { LoadButton } from 'components';
import { Colors } from 'types';

import styles from '../ActivityFeed.module.scss';

import { TPostsLoadMoreProps } from './types';

const PostsLoadMore: FC<TPostsLoadMoreProps> = ({ changePage, currentListLoad }) => (
  <Box className={styles.container_activity__load}>
    <LoadButton
      color='white'
      onClick={changePage}
      inLoad={currentListLoad}
      backgroundColor={Colors.SAPPHIRE}
    >
      Load More
    </LoadButton>
  </Box>
);

export default PostsLoadMore;
