import { FC, useEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';

import { ImgUrlFolders } from 'types';
import { deleteLiveStream } from 'store/thunks';
import { formatDuration, timeDiffer } from 'utils';
import { changePreviousLives } from 'store/slices/liveStreamSlice';
import { MoreIcon, PlayLiveIcon, StreamNotesIcon } from 'assets/icons';
import { BlackBackgroundImg, LiveStreamPlaceholder } from 'assets/images';
import {
  useVideoData,
  useAppDispatch,
  useMediaFetching,
  useOnClickOutside,
  useVideoTimeUpdate,
} from 'hooks';
import {
  DeletePopup,
  CustomSkeleton,
  PortalDropDown,
  CustomTypography,
  LiveStreamDetailsDrawer,
} from 'components';

import { PlayerView } from '../Modals';

import Preview from './Preview';
import styles from './PastStreamCard.module.scss';
import { dropDownOptionsCreator } from './PastStreamCard.utils';

import type { TPastStreamCardProps } from './types';

const PastStreamCard: FC<TPastStreamCardProps> = ({ stream, goToPreviousPage }) => {
  const dispatch = useAppDispatch();

  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const [isPlayerView, setIsPlayerView] = useState<boolean>(false);
  const [isOpenMembers, setIsOpenMembers] = useState<boolean>(false);

  const videoRef = useRef<HTMLVideoElement | null>(null);
  const menuRef = useRef<HTMLDivElement | null>(null);
  const { currentTime, duration, isLoadedMetadata } = useVideoTimeUpdate(videoRef);

  const thumbFromMedia = stream?.media?.find((media) => media?.file_type === 'png');

  const { mediaSource: thumbSource } = useMediaFetching({
    imgId: thumbFromMedia?.media_url_id || '',
    folderName: ImgUrlFolders.LIVE_STREAM,
  });

  useEffect(() => {
    if (videoRef.current) {
      const videoElement = videoRef.current;

      if (currentTime === duration) {
        videoElement.pause();
        videoElement.currentTime = 0;
      }
    }
  }, [currentTime, duration, videoRef]);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const stopVideo = () => {
    if (videoRef.current) {
      const videoElement = videoRef.current;

      videoElement.pause();
      videoElement.currentTime = 0;
    }
  };

  useOnClickOutside(menuRef, stopVideo);

  const handleMembersCallback = () => {
    setIsOpenMembers(true);
    if (videoRef.current) {
      const videoElement = videoRef.current;

      videoElement.pause();
      videoElement.currentTime = 0;
    }
  };

  const handleNotesCallback = () => {
    setIsPlayerView(true);
    if (videoRef.current) {
      const videoElement = videoRef.current;

      videoElement.pause();
      videoElement.currentTime = 0;
    }
  };

  const dropDownOptions = dropDownOptionsCreator({
    removeCallback: togglePopup,
    membersCallBack: () => handleMembersCallback(),
    notesCallBack: () => handleNotesCallback(),
  });

  const handleRemoveStream = () => {
    goToPreviousPage();
    dispatch(deleteLiveStream({ id: stream?.id }));
    dispatch(changePreviousLives(1));
  };

  const videoLink = stream?.media?.find(
    (media) => media?.file_type === 'mp4' || media?.file_type === 'webm',
  );

  const { mediaSource } = useVideoData(
    ImgUrlFolders.LIVE_STREAM,
    videoLink?.media_url_id as string,
  );

  const onPopupClose = () => {
    setIsPopupOpen(false);
  };
  const showPreview = true;

  const thumbVideoImg = thumbSource && thumbSource !== 'empty' ? thumbSource : undefined;

  return (
    <>
      <Box className={styles.container} ref={menuRef}>
        {showPreview ? (
          <>
            <Box className={styles.container__relate}>
              <Box className={styles.container__video}>
                {videoLink ? (
                  <>
                    <video
                      ref={videoRef}
                      src={mediaSource?.url}
                      style={{ visibility: 'hidden', opacity: 0, display: 'none' }}
                    />
                    <img src={thumbVideoImg || BlackBackgroundImg} alt='thumb' />
                  </>
                ) : (
                  <img src={LiveStreamPlaceholder} />
                )}
                <Box className={styles.container__video__opacity}>
                  <Box className={styles.container__video__opacity__top}>
                    <Box style={{ padding: '3px' }} onClick={handleNotesCallback}>
                      <StreamNotesIcon style={{ cursor: 'pointer' }} width={24} height={24} />
                    </Box>
                    <PortalDropDown title={<MoreIcon />} options={dropDownOptions} />
                  </Box>
                  {videoLink && (
                    <>
                      <Box className={styles.container__video__opacity__center}>
                        <Box
                          className={styles.container__video__opacity__play}
                          role='button'
                          onClick={() => setIsPlayerView(true)}
                        >
                          <PlayLiveIcon width={24} height={24} />
                        </Box>
                      </Box>
                      <Box className={styles.container__video__opacity__bottom}>
                        <CustomTypography
                          className={styles.container__video__opacity__bottom__text}
                        >
                          {videoLink?.duration ? (
                            formatDuration(videoLink?.duration)
                          ) : isLoadedMetadata ? (
                            <CustomSkeleton width='100px' height='15px' />
                          ) : (
                            formatDuration(Math.floor(duration || 0))
                          )}
                        </CustomTypography>
                        <CustomTypography
                          className={styles.container__video__opacity__bottom__text}
                        >
                          {stream?.members?.length} People
                        </CustomTypography>
                      </Box>
                    </>
                  )}
                </Box>
              </Box>
            </Box>
            <Box className={styles.container__description}>
              {stream?.title} {timeDiffer(stream?.created_at as string)}
            </Box>
          </>
        ) : (
          <Preview />
        )}
      </Box>

      <PlayerView
        stream={stream}
        poster={thumbVideoImg}
        isOpen={isPlayerView}
        isMembersOpened={isOpenMembers}
        mediaSrc={mediaSource?.url as string}
        onClose={() => setIsPlayerView(false)}
        openDetailsDrawer={() => setIsOpenMembers(true)}
      />
      <LiveStreamDetailsDrawer
        id={stream?.id}
        members={stream.members || []}
        open={isOpenMembers}
        handleClose={() => setIsOpenMembers(false)}
      />
      <DeletePopup
        withBody
        isOpen={isPopupOpen}
        onClose={onPopupClose}
        title='Delete Confirmation'
        body='Please confirm to remove the live stream.'
        onDelete={() => handleRemoveStream()}
      />
    </>
  );
};

export default PastStreamCard;
