import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { getUserPermissions } from 'utils';
import { UserRole } from 'types/global/userRoles';

export const userDataSelector = (state: RootState) => state.auth.userData;
export const authSelector = (state: RootState) => state.auth;

export const refreshTokenErrorSelector = (state: RootState) => state.auth.refreshTOkenError;

export const userPermissions = (state: RootState) => state.auth.permissions;

export const userDataLoadingSelector = (state: RootState) => state.auth.userDataLoading;

export const userDataByIdSelector = (state: RootState) => state.auth.currentUserById;

export const notificationsSetting = (state: RootState) => state.auth.notificationSetting;

export const usersByRolesSelector = (state: RootState) => state.auth.usersByRoles;

export const userAllRolesSelector = (state: RootState) => state.auth.userAllRoles;

export const userAllRolesLoadingSelector = (state: RootState) => state.auth.userRolesLoading;

export const filteredUserRolesSelector = createSelector([userAllRolesSelector], (userAllRoles) => {
  return userAllRoles.filter((userRole) => {
    return Object.values(UserRole).includes(userRole.role as UserRole);
  });
});

export const userDesignationSelector = (state: RootState) =>
  state.auth.currentSingleUserById?.designation;

export const userPermissionsSelector = createSelector(
  [filteredUserRolesSelector, userDesignationSelector],
  (filteredUserRoles, userDesignationSelector) => {
    return getUserPermissions(filteredUserRoles, userDesignationSelector as string);
  },
);
